<template>
  <div class="v-overlay v-overlay--active theme--dark" style="z-index: 201;">
    <div class="v-overlay__scrim" style="opacity: 0.46; background-color: rgb(33, 33, 33); border-color: rgb(33, 33, 33);"></div>
    <div class="v-overlay__content"></div>
    <div role="document" tabindex="0" class="v-dialog__content v-dialog__content--active" style="z-index: 202;">
      <div class="v-dialog v-dialog--active" style="transform-origin: center center; max-width: 290px;">
        <v-card>
          <v-card-title class="headline">
            Logout Warning
          </v-card-title>
          <v-card-text>
            Your login session is about to expire. Click below if you want to stay logged in.<br />
            <div class="text-center">
              Logging out in: <h2 v-bind:class="[ logoutCountdown <= 10 ? 'count-warning' : '' ]">{{logoutCountdown}}</h2>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn block
                   @click="stayLoggedIn()"
                   class="tfb-button background-red">
              Stay Logged In
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'IdleNotification',
    data() {
      return {
        timer: 60000,
      };
    },
    computed: {
      logoutCountdown() {
        return this.timer / 1000;
      },
    },
    created() {
      const timerId = setInterval(() => {
        this.timer -= 1000;
        if (!this.isAppIdle) {
          clearInterval(timerId);
        }

        if (this.timer < 1) {
          clearInterval(timerId);
          if (this.$route.name !== 'policyholderLogin') {
            // Logout user
            this.$store.dispatch('app/setIsLoading', true);

            // Clear the access page
            this.$store.dispatch('app/setAccessPage', null);

            this.$store.dispatch('member/doLogout')
              .then(() => {
                this.$store.dispatch('app/setIsLoading', false);
                // Go to home page
                this.$router.push({ name: 'policyholderLogin' });
              })
              .catch((error) => {
                this.$store.dispatch('app/setIsLoading', false);
                this.$store.dispatch('app/setErrorMessage', error.toString());
              });
          }
        }
      }, 1000);
    },
    methods: {
      stayLoggedIn() {
        this.isAppIdle = false;
        this.timer = 60000;
      },
    },
  };
</script>
<style lang="scss">

  .count-warning {
    color: $tfb-red;
  }
</style>
