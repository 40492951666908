<template>
  <div class="row-header">
    <v-container fluid
                 class="tfb-container pa-0">
      <v-row no-gutters
             justify="end"
             align-content="end">
        <v-col align-self="center"
               cols="12"
               cols-sm="6"
               md="auto"
               order="4"
               order-lg="1"
               order-md="1">
          <span>
            <a @click.stop="lenderdock = true"
               title="Banks, Lenders & Financial Institutions"
               class="row-header-link">Banks, Lenders & Financial Institutions</a>
          </span>
        </v-col>
        <v-col align-self="center"
               cols="6"
               md="auto"
               order="3"
               order-lg="1"
               order-md="1">
          <span>
            <v-divider class="mx-4 nav-divider hidden-sm-and-down"
                       vertical></v-divider>
            <a href="https://texasheritageforliving.com"
               hreflang="en-us"
               title="Blog"
               rel="noopener"
               target="_blank"
               class="row-header-link">Blog</a>
          </span>
        </v-col>
        <v-col align-self="center"
               cols="6"
               md="auto"
               order="3"
               order-lg="1"
               order-md="1">
          <span>
            <v-divider class="mx-4 nav-divider hidden-sm-and-down"
                       vertical></v-divider>
            <a href="https://texasfarmbureau.org/membership/member-benefits/"
               hreflang="en-us"
               title="Member Benefits"
               rel="noopener"
               target="_blank"
               class="row-header-link">Member Benefits</a>
          </span>
        </v-col>
        <v-col align-self="center"
               cols="12"
               md="auto"
               order="3"
               order-lg="1"
               order-md="1">
          <span>
            <v-divider class="mx-4 nav-divider hidden-sm-and-down"
                       vertical></v-divider>
            <a href="https://tfbhp.com"
               hreflang="en-us"
               title="TFB Health Plans"
               rel="noopener"
               target="_blank"
               class="row-header-link">TFB Health Plans</a>
          </span>
        </v-col>
        <v-col align-self="center"
               cols="6"
               md="auto"
               order="2"
               order-lg="1"
               order-md="1">
          <span>
            <v-divider class="mx-4 nav-divider hidden-sm-and-down"
                       vertical></v-divider>
            <router-link :to="{ name: 'countyLookup' }"
                         hreflang="en-us"
                         class="row-header-link">Find an Agent</router-link>
          </span>
        </v-col>
        <v-col align-self="center"
               cols="6"
               md="auto"
               order="2"
               order-lg="1"
               order-md="1">
          <span>
            <v-divider class="mx-4 nav-divider hidden-sm-and-down"
                       vertical></v-divider>
            <router-link :to="{ name: 'paymentOptions' }"
                         hreflang="en-us"
                         class="row-header-link">Make a Payment</router-link>
          </span>
        </v-col>
        <v-col align-self="center"
               class="hidden-sm-and-down"
               md="auto"
               order="3">
          <v-divider class="mx-4 nav-divider"
                     vertical></v-divider>
          <v-icon color="white"
                  @click="showSearch"
                  aria-label="Search">mdi-magnify</v-icon>
        </v-col>
        <v-col cols="12"
               order="1"
               order-lg="3"
               order-md="3"
               align-self="end"
               v-show="searchVisible"
               class="px-2">
          <v-form @submit.stop.prevent="submitSearch">
            <v-text-field single-line
                          dense
                          dark
                          type="search"
                          v-model="searchText"
                          v-on:keyup="submitSearch"
                          color="white"
                          prepend-icon="mdi-magnify"
                          aria-label="Search Text"></v-text-field>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <lender-dock></lender-dock>
  </div>
</template>
<script>
  import { sync } from 'vuex-pathify';
  import LenderDock from '@/views/content/LenderDock.vue';

  export default {
    name: 'QuickLinksHeaderNavigation',
    components: {
      LenderDock,
    },
    data() {
      return {
        searchText: '',
      };
    },
    computed: {
      searchVisible: sync('app/searchVisible'),
      lenderdock: sync('app/lenderdock'),
    },
    methods: {
      showSearch() {
        if (this.searchVisible) {
          this.searchVisible = false;
        } else {
          this.searchVisible = true;
        }
      },
      submitSearch(e) {
        if (e.keyCode === 13) {
          this.searchVisible = false;
          // Submit the search
          this.$router.push({ name: 'searchResults', query: { term: this.searchText, start: 0 } });
        }
      },
    },
    mounted() {
      this.searchVisible = this.$vuetify.breakpoint.smAndDown;
    },
  };
</script>
<style lang="scss">

  .row-header {
    background-color: $tfb-darkest-blue;
    color: $white;
    text-align: center;
  }

  hr.nav-divider {
    background-color: $white;
    border-color: rgba(255, 255, 255, 1);
    color: $white;
    min-height: 10px;
  }

  .row-header-link {
    color: $white !important;
    text-align: center;
    font-size: .8em;
    vertical-align: middle;
    padding-bottom: 2px;
  }

  a.row-header-link,
  a:visited.row-header-link {
    text-decoration: none;
  }

  a:active.row-header-link,
  a:hover.row-header-link {
    text-decoration: underline;
  }
</style>
