<template>
  <v-container class="service-card-container">
    <v-row>
      <v-col v-for="service in serviceItems"
             v-bind:key="service.id">
        <v-card class="service-card mx-auto">
          <v-card-text class="tfb-service-card-text">
            <p class="align-center">
              <br />
              <img v-bind:src="imageRootPath +'policy-icons/' +
                   service.ServiceType + '-blue.png'"
                   class="tfb-service-icon"
                   aria-hidden="true"
                   v-bind:alt="service.Title + ' Insurance'" />
              <br /><br />
              <span class="tfb-title-text-smaller text-uppercase text-darkest-blue">
                {{ service.Title }}
                <br />Insurance
              </span>
              <br /><br />
              <span class="tfb-title-text">
                {{ service.TagLine }}<br /><br />
              </span>
            </p>
          </v-card-text>
          <v-card-actions class="tfb-card-footer text-center">
            <v-hover>
              <v-btn slot-scope="{ hover }"
                     :class="`${hover? 'tfb-service-button-hover': ''} tfb-service-button mx-auto`"
                     outlined
                     :to="{ name: 'insurance', params: { type: service.ServiceType }}"
                     hreflang="en-us">Learn More</v-btn>
            </v-hover>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'ServicesCards',
    mixins: [tfbHelperMixin],
    data() {
      return {
        serviceItems: null,
      };
    },
    created() {
      this.fetchData();
    },
    methods: {
      fetchData() {
        this.serviceItems = null;
        this.$store.dispatch('services/getServices', { sTfbId: this.trackingId })
          .then((response) => {
            this.serviceItems = response;
          });
      },
    },
  };
</script>
<style lang="scss">

  .service-card-container {
    margin-top: 20px;
    margin-bottom: 20px;
    min-height: 277px;
  }
  .service-card {
    height: 300px;
  }
  .tfb-service-icon {
    max-height: 50px;
  }
  .tfb-service-card-text {
    height: 248px;
  }
  .tfb-service-button {
    font-size: 0.75rem !important;
    font-weight: 700;
    font-family: 'BS-book', $body-font-family;
    white-space: normal !important;
    color: $tfb-blue !important;
  }
  .tfb-service-button-hover {
    background-color: $tfb-blue !important;
    color: $white !important;
    border-color: $tfb-blue !important;
    text-decoration: none !important;
  }
</style>
