<template>
  <v-container fluid
               class="pa-0"
               id="meet-agents">
    <v-row>
      <v-col class="text-center">
        <h2 class="tfb-title-text-large text-darkest-blue">
          Meet Our Agents
        </h2>
      </v-col>
    </v-row>
    <v-row dense
           no-gutters>
      <v-col cols="12"
             md="8">
        <agent-map :markers="markers"
               :info-window-open="true"></agent-map>
      </v-col>
        <v-col cols="12"
               md="4">
          <v-container fluid
                       id="agent-listing-detail"
                       class="pa-0">
            <v-form @submit.stop.prevent="onSubmit">
              <v-row no-gutters
                     class="row-agents-gray py-7">
                <v-col>
                  <v-text-field id="agent-search-zip"
                                v-model="searchAgentZipCode"
                                type="number"
                                minlength="5"
                                maxlength="5"
                                required
                                dense
                                filled
                                background-color="white"
                                hide-details="auto"
                                placeholder="Your ZIP Code"
                                hint="Your ZIP Code">
                    <template v-slot:append-outer>
                      <v-btn type="submit"
                             :disabled="formDisabled"
                             class="tfb-button-attached-dense background-red">Search</v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <v-row no-gutters
                   class="find-agents-listing-gray px-3">
              <v-col>
                <div class="mb-2">
                  <span v-if="agents.length > 0">Displaying {{agents.length}} results from {{agentZipCode}}</span>
                  <span v-if="!agentLoading && agents.length <= 0 && agentZipCode !== '' && agentZipCode !== null">
                    Unable to locate agents in {{agentZipCode}}.
                  </span>
                  <span v-if="!agentLoading && (agentZipCode === '' || agentZipCode === null)">
                    Enter your ZIP code to locate agents in your area.
                  </span>
                </div>
                <v-skeleton-loader :loading="agentLoading"
                                   type="list-item-avatar-three-line@3"
                                   class="mx-auto">
                  <agent-cards :agent-listing="agents"></agent-cards>
                </v-skeleton-loader>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
    </v-row>
  </v-container>
</template>
<script>
  /* eslint no-underscore-dangle: 0 */
  import _ from 'lodash';
  import {
    defaultMarkerIcon, secondaryMarkerIcon, defaultMarker,
  } from '@/mixins/googleSettings';
  import tfbHelperMixin from '@/mixins/helpers';
  import tfbDataMixin from '@/mixins/tfbData';
  import AgentCards from './AgentCards.vue';
  import AgentMap from './AgentMap.vue';

  export default {
    name: 'MeetAgents',
    mixins: [tfbHelperMixin, tfbDataMixin],
    data() {
      return {
        formDisabled: false,
        markers: [],
        searchAgentZipCode: '',
        agentLoading: false,
        agents: [],
      };
    },
    components: {
      AgentCards,
      AgentMap,
    },
    computed: {
      agentZipCode() {
        return this.$store.state.location.zipCode;
      },
    },
    mounted() {
      try {
        this.$store.dispatch('location/setLocation').then(() => {
          if (this.$store.state.location.latitude) {
            this.markers.push({
              infoText: 'Your Current Location',
              position: {
                lat: Number(this.$store.state.location.latitude),
                lng: Number(this.$store.state.location.longitude),
              },
              icon: secondaryMarkerIcon,
            });
          } else {
            this.markers.push(defaultMarker);
          }

          if (this.$store.state.location.zipCode) {
            this.searchAgentZipCode = this.$store.state.location.zipCode;
            this.onSubmit();
          }
        });
      } catch (e) {
        this.$store.dispatch('app/logToULS', {
          logLevel: 'Error',
          logMessage: e,
          fileName: 'MeetAgents.vue',
          methodName: 'mounted-Catch',
          trackerId: this.trackingId,
        });
      }
    },
    methods: {
      searchByCurrentLocation() {
        // console.log('in searchByCurrentLocation');
        try {
          this.$store.dispatch('location/setLocation').then(() => {
            if (this.$store.state.location.latitude) {
              this.markers.push({
                infoText: 'Your Current Location',
                position: {
                  lat: Number(this.$store.state.location.latitude),
                  lng: Number(this.$store.state.location.longitude),
                },
                icon: secondaryMarkerIcon,
              });
            } else {
              this.markers.push(defaultMarker);
            }

            if (this.$store.state.location.zipCode) {
              this.searchAgentZipCode = this.$store.state.location.zipCode;
              this.onSubmit();
            }
          });
        } catch (e) {
          this.$store.dispatch('app/logToULS', {
            logLevel: 'Error',
            logMessage: e,
            fileName: 'MeetAgents.vue',
            methodName: 'searchByCurrentLocation-Catch',
            trackerId: this.trackingId,
          });
        }
      },
      onSubmit() {
        // Disable the search button
        this.formDisabled = true;

        // Reset the Google Map markers
        this.markers = [];
        this.markers.push(defaultMarker);

        // Empty the agent listing
        this.agents = [];

        // Set the agent listing loading indicator
        this.agentLoading = true;

        // Save the zip code to the store
        this.$store.commit('location/SET_ZIPCODE', this.searchAgentZipCode);

        // Get the county associated with this zip code
        this.$store.dispatch('location/getAddress', { sAddress: this.searchAgentZipCode })
          .then((response) => {
            if (response.data.results && response.data.results.length > 0) {
              for (let c = 0; c < response.data.results.length; c += 1) {
                // eslint-disable-next-line prefer-destructuring
                const location = response.data.results[c].geometry.location;
                this.markers.pop();
                this.markers.push({
                  infoText: 'Your Current Location',
                  position: {
                    lat: Number(location.lat),
                    lng: Number(location.lng),
                  },
                  icon: secondaryMarkerIcon,
                });
                this.fetchOfficesByLatLong(location.lat, location.lng);
              }
            } else {
              this.agentLoading = false;
              this.formDisabled = false;
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/logToULS', {
              logLevel: 'Error',
              logMessage: error,
              fileName: 'MeetAgents.vue',
              methodName: 'getAddress-Catch',
              trackerId: this.trackingId,
            });
            this.agentLoading = false;
            this.formDisabled = false;
          });
      },
      fetchOfficesByLatLong(latitude, longitude) {
        // Get the offices in this county
        this.$store.dispatch('agentcounty/getOfficesByLatLong', {
          sLatitude: latitude, sLongitude: longitude, sRadius: '25', sTfbId: this.trackingId,
        })
          .then((response) => {
            if (response && response.length > 0) {
              // Empty the agent listing again
              this.agents = [];

              // Loop through the returned offices
              for (let c = 0; c < response.length; c += 1) {
                const countyOffice = response[c];

                // Add the county office to the map
                this.markers.push({
                  infoText: countyOffice.officeMapInfoBox,
                  position: {
                    lat: Number(countyOffice.latitude),
                    lng: Number(countyOffice.longitude),
                  },
                  icon: defaultMarkerIcon,
                });

                // Find all the agents in the office
                this.fetchAgentsByCountyBranch(countyOffice.name, countyOffice.branchCode, countyOffice.distance);
              }
            } else {
              // No offices were found
              // Set the agent listing loading indicator
              this.agentLoading = false;
              this.formDisabled = false;
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
            // Set the agent listing loading indicator
            this.agentLoading = false;
            this.formDisabled = false;
          });
      },
      fetchAgentsByCountyBranch(countyName, branchCode, distance) {
        // Find all the agents in the office
        this.$store.dispatch('agentcounty/getAgentsByOffice', { sCountyName: countyName, sBranchCode: branchCode, sTfbId: this.trackingId })
          .then((agentResponse) => {
            if (agentResponse && agentResponse.data && agentResponse.data._agents) {
              // Loop through the returned agents
              for (let a = 0; a < agentResponse.data._agents.length; a += 1) {
                const agent = agentResponse.data._agents[a];

                // Get the agent details
                this.$store.dispatch('agentcounty/getAgentDetails', { sAgentCode: agent._agentCode, sDistance: distance, sTfbId: this.trackingId })
                  .then((detailResponse) => {
                    if (detailResponse) {
                      // Add the agent to the listing
                      this.agents.push(detailResponse);
                    }

                    this.agents = _.uniqBy(this.agents, 'code');
                    this.agents = _.orderBy(this.agents, ['distance'], ['asc']);
                  })
                  .catch((error) => {
                    this.$store.dispatch('app/setErrorMessage', error.toString());
                    // Set the agent listing loading indicator
                    this.agentLoading = false;
                    this.formDisabled = false;
                  });
              }
            }

            this.agents = _.uniqBy(this.agents, 'code');
            this.agents = _.orderBy(this.agents, ['distance'], ['asc']);

            // Set the agent listing loading indicator
            this.agentLoading = false;
            this.formDisabled = false;
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
            // Set the agent listing loading indicator
            this.agentLoading = false;
            this.formDisabled = false;
          });
      },
    },
  };
</script>
<style lang="scss">

  .google-map {
    width: 100%;
    height: 100%;
  }

  .row-agents-gray {
    background-color: $tfb-gray !important;
    color: $white;
    text-align: center;
    font-size: small;
    vertical-align: middle;
    padding: 15px;
  }

  .row-agents-listing-gray {
    background-color: $white !important;
    color: $black;
    text-align: center;
    font-size: .8em;
    vertical-align: middle;
    padding: 5px;
    height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
</style>
