<template>
  <v-container fluid
               class="tfb-smaller-container tfb-component-container">
    <v-row>
      <v-col cols="12">
        <v-container fluid
                     class="drop-shadow background-white px-md-10 pb-5">
          <v-row>
            <v-col cols="12">
              <span class="tfb-title-text-small text-uppercase">
                Change Password
              </span>
            </v-col>
          </v-row>
          <v-row v-if="passwordSuccess">
            <v-col cols="12"
                   class="py-0">
              <v-alert type="success">
                You have successfully changed your account password.
              </v-alert>
            </v-col>
          </v-row>
          <ValidationObserver ref="obv" v-slot="{ handleSubmit }">
            <v-form role="form"
                    @submit.stop.prevent="handleSubmit(updatePassword)"
                    v-if="!passwordSuccess">
              <v-row>
                <v-col cols="12">
                  <text-input rules="required|confirmed:confirmation|min:8|max:25"
                              minlength="8"
                              maxlength="25"
                              label="New Password"
                              v-model="passWord"
                              type="password"
                          autocomplete="new-password"
                              :disabled="formDisabled"></text-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <text-input rules="required|min:8|max:25"
                              minlength="8"
                              maxlength="25"
                              label="Confirm New Password"
                              v-model="confirmPassWord"
                              type="password"
                          autocomplete="new-password"
                              :disabled="formDisabled"
                              vid="confirmation"></text-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"
                       sm="6"
                       md="4">
                  <v-btn block
                         :disabled="formDisabled"
                         type="submit"
                         class="tfb-button background-red">Submit</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
          <v-overlay absolute
                     :value="saveOverlay">
            <v-progress-circular indeterminate
                                 size="64"></v-progress-circular>
          </v-overlay>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import { ValidationObserver } from 'vee-validate';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'PasswordChanges',
    mixins: [tfbHelperMixin],
    components: {
      ValidationObserver,
    },
    data() {
      return {
        saveOverlay: false,
        formDisabled: false,
        currentPassWord: '',
        passWord: '',
        confirmPassWord: '',
        passwordSuccess: false,
      };
    },
    computed: {
      userName() {
        try {
          return this.$store.getters['member/userName'];
        } catch (error) {
          return '';
        }
      },
    },
    methods: {
      updatePassword() {
        this.passwordSuccess = false;
        this.saveOverlay = true;
        dispatch('member/updatePassword', { sUserName: this.userName, sNewPassword: this.passWord, sTfbId: this.trackingId })
          .then((updateResponse) => {
            if (updateResponse && updateResponse.indexOf('Your password has been updated') >= 0) {
              // Update was successful
              this.passwordSuccess = true;
              this.saveOverlay = false;
            } else {
              dispatch('app/setErrorMessage', updateResponse);
              this.saveOverlay = false;
            }
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error);
            this.saveOverlay = false;
          });
      },
    },
    mounted() {
      if (!this.isMemberRole) {
        // Not the member, don't allow changes
        this.formDisabled = true;
      }
    },
  };
</script>
<style lang="scss">

</style>
