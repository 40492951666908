<template>
  <v-container fluid
               class="mobile-app-banner mt-16 pa-0">
    <v-container class="pa-0">
      <v-row no-gutters>
        <v-col cols="12"
               sm="6"
               class="mobile-app-banner-text pa-4 my-auto">
          <h2 class="mobile-app-banner-header-text">
            Download Our New App
          </h2>
          <p>Secure access to ID cards, key policy info, and more</p>
          <mobile-app-links></mobile-app-links>
        </v-col>
        <v-col cols="12"
               sm="6"
               id="mobile-app-banner"
               class="mobile-app-banner-image">
          <v-img v-bind:src="imageRootPath +'mobile-app-single.png'"
                 class="mx-auto"
                 height="300"
                 contain
                 v-if="$vuetify.breakpoint.mdAndDown">
          </v-img>
          <v-img v-bind:src="imageRootPath +'mobile-app-multiple.png'"
                 class="mx-auto"
                 height="250"
                 contain
                 v-else>
          </v-img>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
  import MobileAppLinks from '@/views/layout/components/MobileAppLinks.vue';

  export default {
    name: 'MobileAppBanner',
    components: {
      MobileAppLinks,
    },
    computed: {
      imageRootPath() {
        return `${process.env.VUE_APP_ROOT_URI}/publishingimages/`;
      },
    },
  };
</script>
<style lang="scss">
  .mobile-app-banner {
    z-index: 997;
    background: rgba(255,255,255,1);
    background: -moz-linear-gradient(left, rgba(255,255,255,1) 30%, rgba(202,226,241,1) 85%, rgba(202,226,241,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(30%, rgba(255,255,255,1)), color-stop(85%, rgba(202,226,241,1)), color-stop(100%, rgba(202,226,241,1)));
    background: -webkit-linear-gradient(left, rgba(255,255,255,1) 30%, rgba(202,226,241,1) 85%, rgba(202,226,241,1) 100%);
    background: -o-linear-gradient(left, rgba(255,255,255,1) 30%, rgba(202,226,241,1) 85%, rgba(202,226,241,1) 100%);
    background: -ms-linear-gradient(left, rgba(255,255,255,1) 30%, rgba(202,226,241,1) 85%, rgba(202,226,241,1) 100%);
    background: linear-gradient(to right, rgba(255,255,255,1) 30%, rgba(202,226,241,1) 85%, rgba(202,226,241,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#cae2f1', GradientType=1 );
  }

  .mobile-app-banner-text {
    color: $tfb-darker-blue;
    font-family: 'BS-light', $body-font-family;
    padding: 20px;
  }

  .mobile-app-banner-header-text {
    font-size: 36px;
  }

  .mobile-app-banner-detail-text {
    font-size: 16px;
  }

  .mobile-app-banner-mobile-links {
    background: $white;
  }
</style>
