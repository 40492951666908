<template>
  <keep-alive>
    <ValidationProvider tag="div"
                        :bails="false"
                        :rules="rules"
                        :name="name || $attrs.label"
                        :vid="vid"
                        v-slot="{ errors, valid, required }">
      <v-radio-group v-model="innerValue"
                     class="float-right no-margin-radio"
                     hide-details="auto"
                     row
                     v-bind="$attrs"
                     v-on="$listeners"
                     :error-messages="errors"
                     :success="valid">
        <v-radio v-for="option in options"
                 :key="option.Id"
                 :label="option.DisplayText"
                 v-bind:value="option.Value">
        </v-radio>
        <template v-slot:append>
          <v-icon v-if="required">mdi-asterisk</v-icon>
        </template>
      </v-radio-group>
    </ValidationProvider>
  </keep-alive>
</template>
<script>
  import { ValidationProvider } from 'vee-validate';

  export default {
    name: 'RadioGroupInput',
    components: {
      ValidationProvider,
    },
    props: {
      vid: {
        type: String,
        default: undefined,
      },
      name: {
        type: String,
        default: undefined,
      },
      rules: {
        type: [Object, String],
        default: '',
      },
      value: {
        type: null,
      },
      options: {
        type: Array,
      },
    },
    data() {
      return {
        innerValue: '',
      };
    },
    watch: {
      // Handles internal model changes.
      innerValue(value) {
        this.$emit('input', value);
      },
      // Handles external model changes.
      value(newVal) {
        this.innerValue = newVal;
      },
    },
    created() {
      if (this.value) {
        this.innerValue = this.value;
      }
    },
  };
</script>
