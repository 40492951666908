<template>
  <div id="agentListing">
    <h1 class="tfb-title-text-large text-center">Agent Index</h1>
    <v-container fluid>
      <v-row v-if="!agentLoading && agentList.length > 0">
        <v-col cols="12"
               v-for="agent in agentList"
               v-bind:key="agent.id">
          <a :href="agent.agentUrl"
             hreflang="en-us"
             :aria-label="agent.agentName">{{agent.agentUrl}}</a>
        </v-col>
      </v-row>
      <v-row v-if="!agentLoading && agentList.length <= 0">
        No Agents Available!
      </v-row>
      <v-row v-if="agentLoading">
        <v-col cols="12"
             v-for="i in 3"
             :key="i">
          <v-skeleton-loader class="mx-auto"
                             max-width="300"
                             type="list-item"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  /* eslint no-underscore-dangle: 0 */
  import tfbHelperMixin from '@/mixins/helpers';
  import tfbMetaMixin from '@/mixins/metaInfo';

  export default {
    name: 'AgentListing',
    mixins: [tfbHelperMixin, tfbMetaMixin],
    data() {
      return {
        agentLoading: true,
        agentList: [],
      };
    },
    computed: {
      metaPageTitle() {
        return 'Insurance Agent Index';
      },
      metaDescription() {
        return 'Find an agent near you with Texas Farm Bureau Insurance\'s agent index.';
      },
      metaUrl() {
        return `https://${window.location.host}/insurance/agent/listing`;
      },
    },
    methods: {
      loadAgents() {
        this.agentLoading = true;
        this.$store.dispatch('agentcounty/getAgentList', { sTfbId: this.trackingId })
          .then((foundAgents) => {
            if (foundAgents !== null && foundAgents.data !== null && foundAgents.data.length > 0) {
              for (let a = 0; a < foundAgents.data.length; a += 1) {
                const agent = foundAgents.data[a];
                const countyName = this.replaceAll(agent._countyName, ' ', '-').toLowerCase();
                const agentName = this.replaceAll(this.replaceAll(this.replaceAll(agent._agentName, ' ', '-'), '.', ''), ',', '').toLowerCase();

                this.agentList.push({
                  id: a,
                  agentUrl: `https://${window.location.host}/insurance/agent/${countyName}/${agentName}/${agent._agentCode}`,
                  agentName: agent._agentName,
                });
              }
            }
            this.agentLoading = false;
          })
          .catch(() => {
            this.agentLoading = false;
          });
      },
    },
    created() {
      this.loadAgents();
    },
  };
</script>
<style lang="scss">

</style>
