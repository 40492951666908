<template>
  <v-sheet elevation="4"
           class="pa-4">
    <!-- Violation Date -->
    <v-row>
      <v-col cols="12">
        <v-menu ref="violationDateMenu"
                v-model="violationDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px">
          <template v-slot:activator="{ on }">
            <keep-alive>
              <ValidationProvider tag="div"
                                  :bails="false"
                                  rules="required"
                                  name="Violation Date"
                                  v-slot="{ errors, valid }">
                <v-text-field outlined
                              required
                              hide-details="auto"
                              :error-messages="errors"
                              :success="valid"
                              v-model="violationDateFormatted"
                              label="Violation Date"
                              @blur="violation.ViolationDate = parseDate(violationDateFormatted)"
                              v-on="on">
                  <template v-slot:append>
                    <v-icon>mdi-calendar</v-icon>
                    <v-icon>mdi-asterisk</v-icon>
                  </template>
                </v-text-field>
              </ValidationProvider>
            </keep-alive>
          </template>
          <v-date-picker v-model="violation.ViolationDate"
                         color="background-blue"
                         no-title
                         :min="minIncidentDate"
                         :max="maxIncidentDate"
                         @input="violationDateMenu = false"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <!-- Violation Type -->
    <v-row>
      <v-col cols="12">
        <select-input rules="required"
                      label="Which option best describes this violation?"
                      name="violation type"
                      :items="violationTypeOptions"
                      item-text="ViolationTypeText"
                      item-value="ViolationTypeId"
                      v-model="violation.ViolationTypeCode"></select-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn v-on:click="$emit('remove-violation', violation.Id)"
               class="float-right tfb-button-min background-red">
          Remove Violation
        </v-btn><br />
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
  import { subYears } from 'date-fns';
  import { ValidationProvider } from 'vee-validate';
  import tfbHelperMixin from '@/mixins/helpers';
/* eslint object-shorthand: 0 */

  export default {
    name: 'RateQuoteViolation',
    props: {
      violation: {
        type: Object,
        required: true,
      },
    },
    mixins: [tfbHelperMixin],
    components: {
      ValidationProvider,
    },
    data() {
      return {
        violationTypeOptions: null,
        violationDateFormatted: this.formatDate(this.violation.ViolationDate),
        violationDateMenu: false,
      };
    },
    computed: {
      minIncidentDate() {
        return this.parseDate(subYears(new Date(), 3).toISOString());
      },
      maxIncidentDate() {
        return this.parseDate(new Date().toISOString());
      },
    },
    methods: {
      getViolationTypes() {
        this.$store.dispatch('quotes/getViolationTypes', { sTfbId: this.trackingId })
          .then((violationTypeResponse) => {
            this.violationTypeOptions = violationTypeResponse;
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
    },
    watch: {
      'violation.ViolationDate'(val) {
        this.violationDateFormatted = this.formatDate(val);
      },
    },
    mounted() {
      this.getViolationTypes();
    },
  };
</script>
<style lang="scss">

</style>
