<template>
  <div id="tfb-home">
    <div class="tfb-header-photo"
         :style="getHeaderBackgroundImage(headerImage)" />
    <services-tabs></services-tabs>
    <!-- Mobile App Banner -->
    <mobile-app-card v-if="showMobileAppCard"></mobile-app-card>
    <div class="my-12 text-center">
      <h1 class="tfb-title-text-large text-darkest-blue">
        We Are Texas Farm Bureau Insurance
      </h1>
      <h2 class="tfb-title-text text-darkest-blue">
        Your friendly neighborhood insurance agency.
        Since 1952.
      </h2>
    </div>
    <jd-power-banner banner-type="image"></jd-power-banner>
    <meet-agents></meet-agents>
    <services-cards></services-cards>
    <videos :video-array="homeVideos"></videos>
    <blog-feed></blog-feed>
    <script type="application/ld+json">
      {
      "@context": "https://schema.org/",
      "@type": "Organization",
      "name": "Texas Farm Bureau Insurance Companies",
      "url": "https://www.txfb-ins.com",
      "image": "https://www.txfb-ins.com/publishingimages/tfb-logo.jpg",
      "contactPoint": {
      "@type": "ContactPoint",
      "Telephone": "+1-800-772-6535",
      "contactType": "customer service"
      },
      "description": "Serving Texans for over 50 years, Texas Farm Bureau Insurance offers home, auto, life, and farm coverage so you can focus on the moments worth covering.",
      "address": {
      "@type": "PostalAddress",
      "addressLocality": "Waco, Texas",
      "postalCode": "76710",
      "streetAddress": "7420 Fish Pond Road"
      },
      "sameAs": [
      "https://www.facebook.com/texasfarmbureauinsurance/",
      "https://twitter.com/TXFBInsurance",
      "https://www.instagram.com/texasheritageforliving/",
      "https://www.youtube.com/channel/UCAAiaNAyH5VlC1yC7sL_M3A",
      "https://www.pinterest.com/THFLmagazine/"
      ]
      }
    </script>
  </div>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import JdPowerBanner from '@/views/layout/components/JDPowerBanner.vue';
  import MeetAgents from '@/views/agents/components/MeetAgents.vue';
  import ServicesCards from '@/views/services/components/ServicesCards.vue';
  import ServicesTabs from '@/views/services/components/ServicesTabs.vue';
  import Videos from '@/views/content/components/Video.vue';
  import BlogFeed from '@/views/blog/components/BlogFeed.vue';
  import MobileAppCard from '@/views/layout/components/MobileAppCard.vue';
  import tfbMetaMixin from '@/mixins/metaInfo';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'homeHeader',
    mixins: [tfbMetaMixin, tfbHelperMixin],
    data() {
      return {
        serviceItems: null,
        homeVideos: [],
      };
    },
    computed: {
      metaPageTitle() {
        return 'Home';
      },
      headerImage() {
        return `${process.env.VUE_APP_ROOT_URI}/publishingimages/default-rural-banner.jpg`;
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}`;
      },
      showMobileAppCard() {
        return this.$store.state.app.mobileAppDisplay === 'Y';
      },
    },
    components: {
      JdPowerBanner,
      MeetAgents,
      ServicesCards,
      ServicesTabs,
      Videos,
      BlogFeed,
      MobileAppCard,
    },
    created() {
      dispatch('app/clearErrorMessage');
      this.fetchData();
    },
    methods: {
      fetchData() {
        this.$store.dispatch('app/getVideosByPage', { sPage: 'default', sTfbId: this.trackingId })
          .then((response) => {
            this.homeVideos = response;
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error.toString());
          });
      },
    },
  };
</script>
<style lang="scss">

</style>
