<template>
  <div id="policyholder-login"
       class="background-darker-blue tfbins-login">
    <v-container class="tfbins-login-box">
      <h1 class="tfb-title-text-medium text-uppercase">Log In</h1>
      <ValidationObserver ref="obv" v-slot="{ handleSubmit }">
        <v-form @submit.stop.prevent="handleSubmit(logIn)">
          <v-row>
            <v-col cols="12">
              <text-input rules="required|min:4|max:60|all_allowed"
                          minlength="4"
                          maxlength="60"
                          label="Username/Email Address"
                          autocomplete="username"
                          v-model="userName"></text-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <text-input rules="required|min:4|max:25"
                          minlength="4"
                          maxlength="25"
                          label="Password"
                          v-model="passWord"
                          autocomplete="current-password"
                          type="password"></text-input>
            </v-col>
          </v-row>
          <v-row v-if="captchaEnabled === 'Y'">
            <v-col cols="12">
              <span role="alert"
                    v-show="!hasCaptcha"
                    class="text-darkest-red">Please validate you are not a bot.</span>
              <vue-recaptcha ref="recaptcha"
                             @verify="onVerify"
                             @expired="onExpired"
                             :sitekey="googleReCaptchaKey"></vue-recaptcha>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn block
                     :disabled="formDisabled"
                     type="submit"
                     class="tfb-button background-red">Log In</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
      <v-row>
        <v-col cols="12">
          <router-link :to="{ name: 'policyholderRegister'}"
                       hreflang="en-us">Register</router-link> |
          <router-link :to="{ name: 'policyholderForgotPassword'}"
                       hreflang="en-us">Forgot Password</router-link>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import { ValidationObserver } from 'vee-validate';
  import VueRecaptcha from 'vue-recaptcha';
  import tfbMetaMixin from '@/mixins/metaInfo';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'PolicyHolderLogin',
    mixins: [tfbMetaMixin, tfbHelperMixin],
    components: {
      ValidationObserver,
      VueRecaptcha,
    },
    data() {
      return {
        userName: '',
        passWord: '',
        rememberLogin: '',
        formDisabled: false,
        hasCaptcha: true,
        captchaText: '',
      };
    },
    computed: {
      metaPageTitle() {
        return 'Log In | PolicyHolder';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/policyholder/login`;
      },
      captchaEnabled() {
        return this.$store.state.app.loginCaptchaEnabled;
      },
      googleReCaptchaKey() {
        return process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY;
      },
    },
    async mounted() {
      this.$store.dispatch('app/clearErrorMessage');
      await this.$store.dispatch('app/doApplicationCheck', { sApplicationName: 'Policyholder', sTfbId: this.trackingId })
        .then((returnedStatus) => {
          if (returnedStatus === '') {
            if (this.isLoggedIn) {
              // Redirect where needed
              this.doRedirect();
            } else {
              // See if this is an SSO session
              const includedToken = this.$route.query.access_token;

              if (includedToken && includedToken !== '') {
                // We have a token, see if we can log in
                this.$store.dispatch('member/doSsoLogin', { sAccessToken: includedToken, sTfbId: this.trackingId })
                  .then((response) => {
                    this.$store.dispatch('app/logToULS', {
                      logLevel: 'Info',
                      logMessage: response,
                      fileName: 'Login.vue',
                      methodName: 'doSsoLogin-Success',
                      trackerId: this.trackingId,
                    });

                    if (response === 'invalid_grant') {
                      this.$store.dispatch('app/setErrorMessage', 'Username/Password Not Found');
                    }

                    if (response !== '') {
                      this.$store.dispatch('app/setErrorMessage', response);
                    } else {
                      // Redirect where needed
                      this.doRedirect();
                    }
                  })
                  .catch((error) => {
                    this.$store.dispatch('app/setErrorMessage', error.toString());
                    this.$store.dispatch('app/logToULS', {
                      logLevel: 'Error',
                      logMessage: error,
                      fileName: 'Login.vue',
                      methodName: 'doSsoLogin-Catch',
                      trackerId: this.trackingId,
                    });
                  });
              }
              this.$store.dispatch('app/setIsLoading', false);
            }
          } else {
            this.$store.dispatch('app/setErrorMessage', returnedStatus);
            this.$store.dispatch('app/setIsLoading', false);
            this.formDisabled = true;
          }
        })
        .catch((error) => {
          this.$store.dispatch('app/setErrorMessage', error);
          this.$store.dispatch('app/setIsLoading', false);
        });
    },
    methods: {
      onCaptchaSubmit() {
        this.hasCaptcha = true;
        this.$refs.invisibleRecaptcha.execute();
      },
      onVerify(response) {
        this.hasCaptcha = true;
        this.captchaText = response;
      },
      onExpired() {
        this.hasCaptcha = false;
        this.captchaText = '';
        this.$refs.recaptcha.reset();
      },
      async logIn() {
        if (this.captchaEnabled === 'Y' && (!this.hasCaptcha || this.captchaText === '')) {
          this.onExpired();
          this.$store.dispatch('app/setIsLoading', false);
          return;
        }

        this.$store.dispatch('app/setIsLoading', true);
        this.$store.dispatch('app/clearErrorMessage');
        this.$store.dispatch('app/logToULS', {
          logLevel: 'Info',
          logMessage: this.userName,
          fileName: 'Login.vue',
          methodName: 'doLogin-Start',
          trackerId: this.trackingId,
        });
        if (this.captchaEnabled !== 'Y') {
          this.captchaText = 'kQMSVtPlYECzM1IX8WibEg';
        }
        await this.$store.dispatch('member/doLogin', {
          sUserName: this.userName,
          sPassword: this.passWord,
          sCatchaText: this.captchaText,
          sTfbId: this.trackingId,
        })
          .then((response) => {
            this.$store.dispatch('app/logToULS', {
              logLevel: 'Info',
              logMessage: response,
              fileName: 'Login.vue',
              methodName: 'doLogin-Response',
              trackerId: this.trackingId,
            });

            if (response === 'invalid_grant') {
              this.$store.dispatch('app/setErrorMessage', 'Username/Password Not Found');
              this.$store.dispatch('app/setIsLoading', false);
            }

            if (response !== '') {
              this.$store.dispatch('app/setErrorMessage', response);
              this.$store.dispatch('app/setIsLoading', false);
            } else {
              // Redirect where needed
              this.doRedirect();
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
            this.$store.dispatch('app/setIsLoading', false);
            this.$store.dispatch('app/logToULS', {
              logLevel: 'Error',
              logMessage: error,
              fileName: 'Login.vue',
              methodName: 'doLogin-Catch',
              trackerId: this.trackingId,
            });
          });
      },
    },
    created() {
      this.$store.dispatch('app/setIsLoading', true);
      this.$store.dispatch('app/clearErrorMessage');
    },
  };
</script>
<style lang="scss">

  .tfbins-login {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .tfbins-login-box {
    background: $white;
    padding: 20px 50px;
    max-width: 500px;
    color: $tfb-darkest-blue;
  }
</style>
