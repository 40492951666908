<template>
  <div class="mt-2 pb-4">
    <v-row>
      <v-col cols="12"
             class="py-1">
        <h3 class="tfb-title-text-small text-blue text-uppercase">Underlying Location Counties</h3>
      </v-col>
    </v-row>
    <v-container fluid
                 v-if="locations && locations.length > 0">
      <v-row v-for="location in locations"
             v-bind:key="location.CountyCode">
        <v-col cols="12"
               class="py-0">
          {{location.CountyCode}} - {{location.CountyName}}
        </v-col>
      </v-row>
    </v-container>
    <v-row v-else>
      <v-col cols="12"
             class="py-0 px-6">
        None
      </v-col>
    </v-row>
  </div>
</template>
<script>
  export default {
    name: 'PolicyDetailsUnderlyingLocations',
    props: {
      locations: {
        type: Array,
        required: true,
      },
    },
  };
</script>
<style lang="scss">

</style>
