<template>
  <div>
    <span v-if="discount.LongDescription && discount.LongDescription !== '' && discount.LongDescription !== null">
      <v-chip pill
              class="discount-button"
              @click.stop="serviceDiscountModal = true">
        <v-avatar left
                  class="discount-button-icon">
          <img v-bind:src="imageRootPath +'policy-icons/' + discount.IconFileName"
               id="discount-icon"
               aria-hidden="true"
               v-bind:alt="discount.Title"
               v-if="discount.IconFileName && discount.IconFileName !== '' && discount.IconFileName !== null"/>
        </v-avatar>
        {{ discount.Title }}
      </v-chip>
    </span>
    <span v-else>
      <v-chip pill
              class="discount-button">
        <v-avatar left
                  class="discount-button-icon">
          <img v-bind:src="imageRootPath +'policy-icons/' + discount.IconFileName"
               id="discount-icon"
               aria-hidden="true"
               v-bind:alt="discount.Title" />
        </v-avatar>
        {{ discount.Title }}
      </v-chip>
    </span>
    <v-dialog v-model="serviceDiscountModal"
              max-width="600px">
      <v-card>
        <v-card-title class="background-darker-blue">
          <h3 class="tfb-title-text-small">{{discount.Title}}</h3>
        </v-card-title>
        <v-card-text>
          <v-container class="tfb-component-container">
            <v-row>
              <v-col cols="12"
                     class="py-5">
                <span v-html="discount.LongDescription"></span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'DiscountItem',
    props: {
      discount: {
        type: Object,
        required: true,
      },
    },
    mixins: [tfbHelperMixin],
    data() {
      return {
        serviceDiscountModal: false,
      };
    },
    computed: {
      imageRootPath() {
        return `${process.env.VUE_APP_ROOT_URI}/publishingimages/`;
      },
    },
  };
</script>
<style lang="scss">

</style>
