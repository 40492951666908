<template>
  <div class="mt-2 pb-4">
    <v-row>
      <v-col cols="12"
             class="py-1">
        <h3 class="tfb-title-text-small text-blue text-uppercase">Underlying Liability Policies</h3>
      </v-col>
    </v-row>
    <v-container fluid>
      <v-row>
        <v-col cols="12"
               class="py-0">
          <h4 class="tfb-title-text-smaller font-weight-bold">Personal / Farm Auto Liability or Commercial Auto Liability</h4>
        </v-col>
      </v-row>
      <v-row v-if="autoPolicies && autoPolicies.length > 0">
        <v-col cols="12"
               v-for="(policy, index) in autoPolicies"
               v-bind:key="policy.IncludedPolicyNumber"
               class="pt-0 pb-2">
          <v-container fluid
                       class="tfb-smallest-container">
            <v-row>
              <v-col cols="12"
                     md="6"
                     class="py-0">
                <label class="font-weight-bold">Carrier Name:</label>
              </v-col>
              <v-col cols="12"
                     md="6"
                     class="py-0 text-right text-md-left">
                {{policy.CarrierName}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                     md="6"
                     class="py-0">
                <label class="font-weight-bold">Policy Number:</label>
              </v-col>
              <v-col cols="12"
                     md="6"
                     class="py-0 text-right text-md-left">
                {{policy.IncludedPolicyNumber}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                     md="6"
                     class="py-0">
                <label class="font-weight-bold">Effective Date:</label>
              </v-col>
              <v-col cols="12"
                     md="6"
                     class="py-0 text-right text-md-left">
                {{formatDate(policy.IncludedPolicyEffectiveDate)}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                     md="6"
                     class="py-0">
                <label class="font-weight-bold">Expiration Date:</label>
              </v-col>
              <v-col cols="12"
                     md="6"
                     class="py-0 text-right text-md-left">
                {{formatDate(policy.IncludedPolicyExpirationDate)}}
              </v-col>
            </v-row>
            <v-row v-if="policy.CombinedSingleLimit && policy.CombinedSingleLimit !== null && policy.CombinedSingleLimit !== '0'">
              <v-col cols="12"
                     md="6"
                     class="py-0">
                <label class="font-weight-bold">Combined Single Limit:</label>
              </v-col>
              <v-col cols="12"
                     md="6"
                     class="py-0 text-right text-md-left">
                {{formatMoney(policy.CombinedSingleLimit, 0)}}
              </v-col>
            </v-row>
            <v-row v-if="policy.BodilyInjuryPerOccurrence && policy.BodilyInjuryPerOccurrence !== null && policy.BodilyInjuryPerOccurrence !== '0'">
              <v-col cols="12"
                     md="6"
                     class="py-0">
                <label class="font-weight-bold">Bodily Injury Per Occurrence Limit:</label>
              </v-col>
              <v-col cols="12"
                     md="6"
                     class="py-0 text-right text-md-left">
                {{formatMoney(policy.BodilyInjuryPerOccurrence, 0)}}
              </v-col>
            </v-row>
            <v-row v-if="policy.BodilyInjuryPerPerson && policy.BodilyInjuryPerPerson !== null && policy.BodilyInjuryPerPerson !== '0'">
              <v-col cols="12"
                     md="6"
                     class="py-0">
                <label class="font-weight-bold">Bodily Injury Per Person Limit:</label>
              </v-col>
              <v-col cols="12"
                     md="6"
                     class="py-0 text-right text-md-left">
                {{formatMoney(policy.BodilyInjuryPerPerson, 0)}}
              </v-col>
            </v-row>
            <v-row v-if="policy.PropertyDamagePerOccurrence && policy.PropertyDamagePerOccurrence !== null && policy.PropertyDamagePerOccurrence !== '0'">
              <v-col cols="12"
                     md="6"
                     class="py-0">
                <label class="font-weight-bold">Property Damage Limit:</label>
              </v-col>
              <v-col cols="12"
                     md="6"
                     class="py-0 text-right text-md-left">
                {{formatMoney(policy.PropertyDamagePerOccurrence, 0)}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                     class="d-block d-lg-none"
                     v-if="index != autoPolicies.length - 1"><hr /></v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12"
               class="py-0 px-6">
          None
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"
               class="py-0">
          <h4 class="tfb-title-text-smaller font-weight-bold">Personal / Farm Liability or Commercial Liability</h4>
        </v-col>
      </v-row>
      <v-row v-if="propertyPolicies && propertyPolicies.length > 0">
        <v-col cols="12"
               v-for="(policy, index) in propertyPolicies"
               v-bind:key="policy.IncludedPolicyNumber"
               class="pt-0 pb-2">
          <v-container fluid
                       class="tfb-smallest-container">
            <v-row>
              <v-col cols="12"
                     md="6"
                     class="py-0">
                <label class="font-weight-bold">Carrier Name:</label>
              </v-col>
              <v-col cols="12"
                     md="6"
                     class="py-0 text-right text-md-left">
                {{policy.CarrierName}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                     md="6"
                     class="py-0">
                <label class="font-weight-bold">Policy Number:</label>
              </v-col>
              <v-col cols="12"
                     md="6"
                     class="py-0 text-right text-md-left">
                {{policy.IncludedPolicyNumber}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                     md="6"
                     class="py-0">
                <label class="font-weight-bold">Effective Date:</label>
              </v-col>
              <v-col cols="12"
                     md="6"
                     class="py-0 text-right text-md-left">
                {{formatDate(policy.IncludedPolicyEffectiveDate)}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                     md="6"
                     class="py-0">
                <label class="font-weight-bold">Expiration Date:</label>
              </v-col>
              <v-col cols="12"
                     md="6"
                     class="py-0 text-right text-md-left">
                {{formatDate(policy.IncludedPolicyExpirationDate)}}
              </v-col>
            </v-row>
            <v-row v-if="policy.OccurrenceLimit && policy.OccurrenceLimit !== null && policy.OccurrenceLimit !== '0'">
              <v-col cols="12"
                     md="6"
                     class="py-0">
                <label class="font-weight-bold">Each Occurrence Limit:</label>
              </v-col>
              <v-col cols="12"
                     md="6"
                     class="py-0 text-right text-md-left">
                {{formatMoney(policy.OccurrenceLimit, 0)}}
              </v-col>
            </v-row>
            <v-row v-if="policy.AggregationLimit && policy.AggregationLimit !== null && policy.AggregationLimit !== '0'">
              <v-col cols="12"
                     md="6"
                     class="py-0">
                <label class="font-weight-bold">General Aggregate Limit:</label>
              </v-col>
              <v-col cols="12"
                     md="6"
                     class="py-0 text-right text-md-left">
                {{formatMoney(policy.AggregationLimit, 0)}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                     class="d-block d-lg-none"
                     v-if="index != propertyPolicies.length - 1"><hr /></v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12"
               class="py-0 px-6">
          None
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'PolicyDetailsUnderlyingPolicies',
    mixins: [tfbHelperMixin],
    props: {
      autoPolicies: {
        type: Array,
        required: true,
      },
      propertyPolicies: {
        type: Array,
        required: true,
      },
    },
  };
</script>
<style lang="scss">

</style>
