import Vue from 'vue';
import Vuex from 'vuex';
import pathify from 'vuex-pathify';
import app from './modules/app';
import agentcounty from './modules/agentcounty';
import blog from './modules/blog';
import claims from './modules/claims';
import documents from './modules/documents';
import location from './modules/location';
import member from './modules/member';
import payments from './modules/payments';
import policy from './modules/policy';
import quotes from './modules/quotes';
import services from './modules/services';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [pathify.plugin],
  modules: {
    app,
    agentcounty,
    blog,
    claims,
    documents,
    location,
    member,
    payments,
    policy,
    quotes,
    services,
  },
});
