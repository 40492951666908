<template>
  <v-container fluid
               class="tfb-container">
    <h1 class="tfb-title-text-medium text-darker-blue text-uppercase">
      Premium Finance Payments
    </h1>
    <v-row>
      <v-col cols="12">
        <v-container fluid
                     class="drop-shadow tfb-smaller-container background-white">
          <div v-if="!showForm && !showReview && !showFinal">
            <v-row>
              <v-col cols="12">
                <h2 class="tfb-title-text-small text-darker-blue text-uppercase">
                  Terms &amp; Conditions
                </h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                     class="text-body-2 py-0">
                <p>
                  Before using the Electronic Payment System, please review these Terms & Conditions which constitute
                  an agreement between you and Texas Farm Bureau Mutual Insurance Company, Texas Farm Bureau Premium
                  Finance Company, Texas Farm Bureau Underwriters Texas Farm Bureau Casualty Insurance Company, and
                  Farm Bureau County Mutual Insurance Company of Texas (&ldquo;we&rdquo;, &ldquo;our&rdquo;, &ldquo;us&rdquo;, &ldquo;the Companies&rdquo;) and describe
                  both the benefits and restrictions on using this service to pay your insurance premiums. Please print
                  and retain for future reference. These Terms & Conditions may be updated from time to time.
                </p>
                <p>
                  Your premium payment will be deducted from your checking account. One time transfers are available online through this site.
                  <u>Automatic recurring payments are not currently available.</u>
                  You can use a valid checking account from any recognized financial institution (bank, securities firm, etc.) in the country.
                  Check with your financial institution to make sure it accepts electronic debits from the account you choose and to determine
                  if your financial institution will charge you any fees for this service. Since some financial institutions use unique bank
                  routing numbers and account numbers for electronic payments, it would be a good idea to verify your routing number and
                  account number with your financial institution before initiating a transaction. Each time you initiate a transaction,
                  you authorize us or our agent to draw a check, draft or automated clearing house (ACH) debit in your name on your account.
                  This amount will be payable to us or our agent in the amount of the transaction and be deemed as a payment to us. We will
                  present this check, draft or ACH to your financial institution for immediate payment. We reserve the right to stop any ACH
                  transaction based upon a previous return from your financial institution, which includes, but is not limited to, invalid
                  bank account, account closed or unauthorized transactions.
                </p>
                <p>
                  You acknowledge that the Companies reserve the right to terminate your use of online payments at any time and for any reason.
                  You agree that by no means will you attempt to gain unauthorized access to this site, other accounts, computer systems or
                  networks connected to the hosting server. You agree to notify the Companies immediately of any unauthorized use of your
                  account or any other breach of security. You agree that you will not deface, alter or interfere with the pages on this
                  web site or the underlying software code, nor will you engage in any activity that will cause disruption of service to any
                  member or interfere with a member&rsquo;s use of this site. You agree to choose a password when registering. You are responsible
                  for the confidentiality of your password and account information. You are fully responsible for all activities that occur
                  using your password and account information. The Companies will not be liable for any loss that you may incur as a result
                  of someone else using your password or account information, either with or without your knowledge. Also, you may not use
                  anyone else&rsquo;s password at any time. You agree not to use the Electronic Payment System and service for illegal purposes
                  or for the transmission of material that is unlawful, harassing, libelous (untrue and damaging to others), invasive of
                  another&rsquo;s privacy, abusive, threatening or obscene, or that infringes the rights of other.
                </p>
                <p>
                  If you agree to the terms and conditions as stated above, please enter your membership number and
                  click &ldquo;Submit&rdquo;. If you do not agree or do not wish to proceed, please click &ldquo;Cancel&rdquo;.
                </p>
              </v-col>
            </v-row>
                <ValidationObserver ref="obv"
                                    v-slot="{ handleSubmit }">
                  <v-form ref="verifyForm"
                          @submit.stop.prevent="handleSubmit(acceptTerms)">
                    <v-row>
                      <v-col cols="12">
                        <text-input :rules="memberRules"
                                    label="Member Number"
                                    autocapitalize="characters"
                                    minlength="4"
                                    maxlength="6"
                                    v-model="verifyForm.memberNumber"></text-input>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-btn block
                               @click="cancelPayment()"
                               class="tfb-button background-red">Cancel</v-btn>
                      </v-col>
                      <v-col cols="6">
                        <v-btn block
                               :disabled="formDisabled"
                               type="submit"
                               class="tfb-button background-red">Submit</v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </ValidationObserver>
          </div>
          <div v-if="showForm">
            <v-row>
              <v-col cols="12">
                <h2 class="tfb-title-text-small text-darker-blue text-uppercase">
                  Make a Payment
                </h2>
              </v-col>
            </v-row>
            <!-- Display pre-filled form -->
            <ValidationObserver ref="obvpay" v-slot="{ handleSubmit }">
              <v-form ref="paymentForm"
                      @submit.stop.prevent="handleSubmit(authPayment)">
                <v-row>
                  <v-col cols="12">
                    <text-input rules="required|routing_number|confirmed:routing|min:4|max:17"
                                minlength="4"
                                maxlength="17"
                                label="Bank Routing Number"
                                v-model="paymentForm.routingNumber"
                                @blur="getBankName()"></text-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <text-input rules="required|min:4|max:17"
                                minlength="4"
                                maxlength="17"
                                label="Confirm Bank Routing Number"
                                v-model="paymentForm.confirmRoutingNumber"
                                vid="routing"></text-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <text-input rules="required"
                                label="Bank Name"
                                disabled
                                v-model="bankName"></text-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <text-input rules="required|confirmed:account|min:4|max:17"
                                minlength="4"
                                maxlength="17"
                                label="Bank Account Number"
                                v-model="paymentForm.accountNumber"></text-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <text-input rules="required|min:4|max:17"
                                minlength="4"
                                maxlength="17"
                                label="Confirm Bank Account Number"
                                v-model="paymentForm.confirmAccountNumber"
                                vid="account"></text-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <select-input rules="required"
                                  label="Payment Type"
                                  :items="paymentTypeOptions"
                                  item-text="DisplayText"
                                  item-value="Value"
                                  v-model="paymentType"
                                  @change="paymentTypeChange()"></select-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <text-input rules="required|min:1|max:10|decimal"
                                label="Payment Amount"
                                type="number"
                                prefix="mdi-currency-usd"
                                :disabled="amountDisabled"
                                v-model="paymentForm.paymentAmount"></text-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-btn block
                           @click="cancelPayment()"
                           class="tfb-button background-red">Cancel</v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn block
                           :disabled="formDisabled"
                           type="submit"
                           class="tfb-button background-red">Submit</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </ValidationObserver>
          </div>
          <div v-if="showReview">
            <v-row>
              <v-col cols="12">
                <h2 class="tfb-title-text-small text-darker-blue text-uppercase">
                  Authorization
                </h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span class="tfb-title-text-smaller">Please review the entered transaction information.
                  <span class="tfb-title-text-smaller font-weight-bold">Your payment is not complete until you click the &ldquo;Approve&rdquo; button below.</span>
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <label class="font-weight-bold">Payment Date:</label> {{formatDate(paymentDate)}}<br />
                <label class="font-weight-bold">Contract Number:</label> {{contractNumber}}<br />
                <label class="font-weight-bold">Payment Amount:</label> {{formatMoney(paymentForm.paymentAmount, 2)}}<br />
                <label class="font-weight-bold">Routing Number:</label> {{paymentForm.routingNumber}}<br />
                <label class="font-weight-bold">Account Number:</label> {{paymentForm.accountNumber}}<br />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                By clicking &ldquo;Approve&rdquo;, I understand the payment cannot be revoked and that the listed amount
                will be drafted from the listed account as a one-time transaction.
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-btn block
                       @click="cancelPayment()"
                       class="tfb-button background-red">Cancel</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn block
                       @click="makePayment()"
                       class="tfb-button background-red">Approve</v-btn>
              </v-col>
            </v-row>
          </div>
          <div v-if="showFinal">
            <v-row>
              <v-col cols="12">
                <h2 class="tfb-title-text-small text-darker-blue text-uppercase">
                  Confirmation
                </h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span class="tfb-title-text-small">Your payment has been received.</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span class="tfb-title-text-smaller">
                  Your confirmation number is <span class="text-red font-weight-bold">{{confirmationNumber}}</span>
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <label class="font-weight-bold">Payment Date:</label> {{formatDate(paymentDate)}}<br />
                <label class="font-weight-bold">Contract Number:</label> {{contractNumber}}<br />
                <label class="font-weight-bold">Confirmation Number:</label> {{confirmationNumber}}<br />
                <label class="font-weight-bold">Payment Amount:</label> {{formatMoney(paymentForm.paymentAmount, 2)}}<br />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-btn block
                       @click="printConfirmation()"
                       class="tfb-button background-red">Print</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn block
                       @click="cancelPayment()"
                       class="tfb-button background-red">Return to Account Summary</v-btn>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  /* eslint object-shorthand: 0 */
  import { dispatch } from 'vuex-pathify';
  import { ValidationObserver } from 'vee-validate';
  import tfbMetaInfo from '@/mixins/metaInfo';
  import tfbHelperMixin from '@/mixins/helpers';
  import { insuredAccessOnlyErrorMessage } from '@/mixins/errorMessages';
  /* eslint object-shorthand: 0 */

  export default {
    name: 'PremiumFinancePayments',
    mixins: [tfbMetaInfo, tfbHelperMixin],
    components: {
      ValidationObserver,
    },
    data() {
      return {
        formDisabled: false,
        bankName: null,
        showForm: false,
        showReview: false,
        showFinal: false,
        paymentType: 'C',
        amountDisabled: true,
        contractNumber: null,
        paymentForm: {
          routingNumber: null,
          confirmRoutingNumber: null,
          accountNumber: null,
          confirmAccountNumber: null,
          paymentAmount: null,
        },
        verifyForm: {
          memberNumber: null,
        },
        memberRules: {
          required: true,
          min: 4,
          max: 6,
          is: '',
        },
        confirmationNumber: null,
        paymentDate: null,
        paymentTypeOptions: [{ Value: 'C', DisplayText: 'Current Payment' }, { Value: 'O', DisplayText: 'Other Payment' }],
      };
    },
    computed: {
      metaPageTitle() {
        return 'Premium Finance Payments | PolicyHolder';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/policyholder/payments/premium-finance`;
      },
      emailAddress() {
        try {
          return this.$store.getters['member/emailAddress'];
        } catch (error) {
          return '';
        }
      },
      memberNumber() {
        try {
          return this.$store.getters['member/memberNumber'];
        } catch (error) {
          return '';
        }
      },
      pfPaymentRequest() {
        try {
          return this.$store.getters['payments/pfPaymentRequest'];
        } catch (error) {
          return null;
        }
      },
    },
    methods: {
      getBankName() {
        dispatch('app/clearErrorMessage');
        dispatch('payments/validateRoutingNumber', {
          sRoutingNumber: this.paymentForm.routingNumber, sTfbId: this.trackingId,
        })
          .then((validationResult) => {
            this.bankName = validationResult;
            if (!validationResult || validationResult === '') {
              this.paymentForm.routingNumber = null;
            }
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error.toString());
          });
      },
      paymentTypeChange() {
        if (this.paymentType === 'O' || (this.paymentType === 'C' && this.pfPaymentRequest.MinimumAmountDue === null)) {
          this.paymentForm.paymentAmount = '0.00';
          this.amountDisabled = false;
        } else {
          this.paymentForm.paymentAmount = this.pfPaymentRequest.MinimumAmountDue;
          this.amountDisabled = true;
        }
      },
      cancelPayment() {
        // Clear the request
        this.$store.commit('payments/SET_PF_PAYMENT_REQUEST', null);
        // Go back to Summary screen
        this.$router.push({ name: 'policyholderAccountSummary' });
      },
      acceptTerms() {
        // Accepted the terms, so show the payment form
        this.showForm = true;
      },
      authPayment() {
        dispatch('app/clearErrorMessage');
        this.paymentDate = new Date().toISOString();
        this.showFinal = false;
        this.showReview = true;
        this.showForm = false;
      },
      makePayment() {
        dispatch('app/clearErrorMessage');
        dispatch('app/setIsLoading', true);
        this.$store.dispatch('app/logToULS', {
          logLevel: 'Info',
          logMessage: `${this.pfPaymentRequest.ContractNumber}-${this.memberNumber}-${this.paymentForm.paymentAmount}`,
          fileName: 'PremiumFinancePayment.vue',
          methodName: 'makePayment-Start',
          trackerId: this.trackingId,
        });
        dispatch('payments/makePremiumFinancePayment', {
          sAccountNumber: this.paymentForm.accountNumber,
          sContractNumber: this.pfPaymentRequest.ContractNumber,
          sMemberNumber: this.memberNumber,
          sPaymentAmount: this.paymentForm.paymentAmount,
          sRoutingNumber: this.paymentForm.routingNumber,
          sTfbId: this.trackingId,
        })
          .then((paymentResult) => {
            this.$store.dispatch('app/logToULS', {
              logLevel: 'Info',
              logMessage: paymentResult,
              fileName: 'PremiumFinancePayment.vue',
              methodName: 'makePremiumFinancePayment-Response',
              trackerId: this.trackingId,
            });
            if (paymentResult.ConfirmationNumber && paymentResult.ConfirmationNumber !== '') {
              this.confirmationNumber = paymentResult.ConfirmationNumber;
              this.paymentDate = new Date().toISOString();
              this.showFinal = true;
              this.showReview = false;
              this.showForm = false;

              // Clear the request
              this.$store.commit('payments/SET_PF_PAYMENT_REQUEST', null);
              dispatch('app/setIsLoading', false);
            } else {
              dispatch('app/setErrorMessage', paymentResult.Result);
              dispatch('app/setIsLoading', false);
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/logToULS', {
              logLevel: 'Error',
              logMessage: error,
              fileName: 'PremiumFinancePayment.vue',
              methodName: 'makePayment-Catch',
              trackerId: this.trackingId,
            });
            dispatch('app/setErrorMessage', error.toString());
            dispatch('app/setIsLoading', false);
          });
      },
      printConfirmation() {
        // TODO: Print confirmation
        window.print();
      },
    },
    watch: {
      'verifyForm.memberNumber'(val) {
        this.verifyForm.memberNumber = val.toUpperCase();
      },
    },
    mounted() {
      dispatch('app/clearErrorMessage');
      dispatch('app/setIsLoading', true);
      dispatch('app/doApplicationCheck', { sApplicationName: 'Payments', sTfbId: this.trackingId })
        .then((returnedStatus) => {
          if (returnedStatus === '') {
            if (!this.isLoggedIn) {
              // Invalid login - go back to login screen
              this.$router.push({ name: 'policyholderLogin' });
            } else if (!this.isMemberRole) {
              // Not the member, don't allow payment
              this.$store.dispatch('app/setErrorMessage', insuredAccessOnlyErrorMessage);
              this.$router.push({ name: 'policyholderAccountSummary' });
            } else if (this.pfPaymentRequest === null) {
              // No request values, push back to Summary screen
              this.$router.push({ name: 'policyholderAccountSummary' });
            } else {
              this.memberRules.is = this.memberNumber;
              this.contractNumber = this.pfPaymentRequest.ContractNumber;
              this.paymentForm.paymentAmount = this.pfPaymentRequest.MinimumAmountDue;
              this.amountDisabled = true;
            }
            dispatch('app/setIsLoading', false);
          } else {
            dispatch('app/setErrorMessage', returnedStatus);
            dispatch('app/setIsLoading', false);
            this.formDisabled = true;
          }
        })
        .catch((error) => {
          dispatch('app/setErrorMessage', error.toString());
          dispatch('app/setIsLoading', false);
        });
    },
  };
</script>
<style lang="scss">

</style>
