<template>
  <v-container fluid
               class="tfb-smaller-container tfb-component-container">
    <v-row>
      <v-col cols="12">
        <v-container fluid
                     class="drop-shadow background-white px-10 pb-5">
          <v-row>
            <v-col cols="12">
              <span class="tfb-title-text-small text-uppercase">
                Verify Email Address
              </span>
            </v-col>
          </v-row>
          <v-row v-if="verificationSuccess">
            <v-col cols="12">
              <v-alert type="success"
                       v-if="verificationSuccess">
                Thank you! Your email address has been verified. You may need to log in again to see this reflected on your account.
              </v-alert>
              <p>
                <router-link :to="{ name: 'policyholderAccountSummary'}"
                             hreflang="en-us">Go to Account Summary</router-link></p>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import tfbMetaMixin from '@/mixins/metaInfo';

  export default {
    name: 'EmailVerificationComplete',
    mixins: [tfbMetaMixin],
    data() {
      return {
        verificationSuccess: false,
      };
    },
    computed: {
      metaPageTitle() {
        return 'Verify Email Address | PolicyHolder';
      },
      validationCode() {
        try {
          return this.$route.query.i;
        } catch (error) {
          return null;
        }
      },
    },
    mounted() {
      dispatch('app/clearErrorMessage');
      dispatch('app/setIsLoading', true);

      if (this.validationCode && this.validationCode !== null) {
        let multipleIndicator = false;

        if (this.validationCode.substring(0, 1) === 'M' || this.validationCode.substring(0, 1) === 'A') {
          multipleIndicator = true;
        }
        dispatch('member/doEmailVerification', { sValidationCode: this.validationCode, sMultipleIndicator: multipleIndicator, sTfbId: this.trackingId })
          .then(() => {
            // Update was successful
            this.verificationSuccess = true;
            dispatch('app/setIsLoading', false);
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error);
            dispatch('app/setIsLoading', false);
          });
      } else {
        // No token, so redirect to home page
        this.$router.push({ name: 'home' });
      }
    },
  };
</script>
<style lang="scss">

</style>
