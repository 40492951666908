<template>
  <div id="county-details">
    <div itemscope
         itemtype="http://schema.org/InsuranceAgency"
         class="background-darker-blue tfb-county-details">
      <v-container class="tfb-smaller-container">
        <v-row v-if="countyOfficeName && countyOfficeName !== ''">
          <v-col cols="12"
                 align-self="center">
            <h1 class="tfb-title-text-medium text-uppercase text-blue"
                itemprop="name">
              {{displayPageTitle}}
            </h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12"
                 md="6"
                 align-self="start">
            <v-skeleton-loader :loading="countyLoading"
                               type="article"
                               class="mx-auto">
              <v-container v-if="countyOffice && countyOffice !== null">
                <v-row>
                  <v-col cols="12"
                         sm="5">
                    <span class="tfb-header-text-small text-uppercase font-weight-bold">
                      Office
                    </span><br />
                    <a v-bind:href="countyOffice.phoneNumber | linkFormatPhone"
                       itemprop="telephone"
                       class="county-agent-links">
                      {{countyOffice.phoneNumber | formatPhone}}
                    </a><br /><br />
                    <span class="tfb-header-text-small text-uppercase font-weight-bold"
                          v-if="countyOffice.faxNumber && countyOffice.faxNumber !== ''">
                      Fax<br />
                    </span>
                    <a v-bind:href="countyOffice.faxNumber | linkFormatPhone"
                       itemprop="faxNumber"
                       class="county-agent-links">
                      {{countyOffice.faxNumber | formatPhone}}
                    </a><br />
                  </v-col>
                  <v-col cols="12"
                         sm="7"
                         align-self="center">
                    <span class="tfb-header-text-small text-uppercase font-weight-bold">
                      Mailing Address
                    </span><br />
                    <div itemprop="address"
                         itemscope
                         itemtype="http://schema.org/PostalAddress"
                         v-if="countyOffice.mailingAddressDisplay && countyOffice.mailingAddressDisplay !== null">
                      <span v-html="countyOffice.mailingAddressDisplay"></span><br />
                      <br />
                    </div>
                    <span class="tfb-header-text-small text-uppercase font-weight-bold">
                      Physical Address
                    </span><br />
                    <div itemprop="address"
                         itemscope
                         itemtype="http://schema.org/PostalAddress"
                         v-if="countyOffice.physicalAddressDisplay && countyOffice.physicalAddressDisplay !== null">
                      <span v-html="countyOffice.physicalAddressDisplay"></span>
                      <v-icon class="mdi-18px tfb-county-icon">mdi-map-marker</v-icon><a v-bind:href="countyOffice.mapUrl"
                         hreflang="en-us"
                         class="county-agent-links">Directions</a><br />
                      <script v-if="siFiTracking"
                              defer
                              async
                              type="application/javascript"
                              :src='`https://tag.simpli.fi/sifitag/${siFiTracking}`'></script>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
              <div v-else>
                <span class="tfb-title-text-small text-blue">
                  Unable to locate office information
                </span>
              </div>
            </v-skeleton-loader>
          </v-col>
          <v-col cols="12"
                 md="6"
                 align-self="start">
            <h2 class="tfb-title-text-small text-uppercase text-blue">
              Agents
            </h2>
            <v-container>
              <v-skeleton-loader :loading="agentLoading"
                                 type="text"
                                 class="mx-auto">
                <v-row no-gutters
                       v-if="sortedAgents.length > 0">
                  <v-col cols="12"
                         v-for="agent in sortedAgents"
                         v-bind:key="agent.code"
                         itemprop="employee"
                         itemscope
                         itemtype="http://schema.org/employee">
                    <router-link :to="{ name: 'insuranceAgentFull', params: { id: agent.code, county: replaceAll(agent.county, ' ', '-').toLowerCase(),
                                 name: replaceAll(replaceAll(replaceAll(agent.name, ' ', '-'), '.', ''), ',', '').toLowerCase() } }"
                                 class="county-agent-links"
                                 itemprop="url"
                                 hreflang="en-us">
                      <span itemprop="name">{{agent.name}}</span>
                    </router-link>
                    <span v-if="agent.designation === 'Agency Manager'">
                      &mdash; <span itemprop="jobTitle">{{agent.designation}}</span>
                    </span>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12">
                    No active agents found for this office
                  </v-col>
                </v-row>
              </v-skeleton-loader>
            </v-container>
          </v-col>
        </v-row>
        <v-row v-if="countyOfficeName && countyOfficeName !== '' && countySummary && countySummary !== null">
          <v-col cols="12">
            <span itemprop="description"
                  class="county-agent-summary"
                  v-html="countySummary"></span>
          </v-col>
        </v-row>
      </v-container>
      <div v-if="countyOfficeName && countyOfficeName !== ''">
        <blog :header="`Resources from ${countyOfficeName} County`"
              sub-header=""
              :keywords="blogKeywords"></blog>
      </div>
    </div>
    <v-container fluid
                 class="px-0">
      <v-row no-gutters>
        <v-col cols="12"
               md="6">
          <faqs :faq-keyword="`${countyOfficeName} County`"
                :faq-title="`Frequently Asked Questions (FAQs) about Texas Farm Bureau Insurance in ${countyOfficeName} County`"></faqs>
        </v-col>
        <v-col cols="12"
               md="6">
          <agent-map :markers="markers"
                     :info-window-open="true"></agent-map>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  /* eslint no-underscore-dangle: 0 */
  /* eslint no-nested-ternary: 0 */
  /* eslint no-param-reassign: 0 */
  import Faqs from '@/views/content/components/FAQs.vue';
  import Blog from '@/views/blog/components/BlogCards.vue';
  import { defaultMarkerIcon } from '@/mixins/googleSettings';
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbMetaMixin from '@/mixins/metaInfo';
  import tfbHelperMixin from '@/mixins/helpers';
  import AgentMap from './components/AgentMap.vue';

  export default {
    name: 'CountyDetails',
    mixins: [tfbDataMixin, tfbMetaMixin, tfbHelperMixin],
    components: {
      Blog,
      Faqs,
      AgentMap,
    },
    data() {
      return {
        countyOfficeName: '',
        seoTitle: null,
        seoDescription: null,
        seoKeywords: null,
        pageTitle: null,
        agentLoading: true,
        countyLoading: true,
        countyOffice: null,
        countySummary: null,
        siFiTracking: null,
        agentList: [],
        markers: [],
      };
    },
    computed: {
      metaDescription() {
        if (this.seoDescription && this.seoDescription !== null) {
          return this.seoDescription;
        }

        if (this.countyOffice) {
          return `Learn more about the Texas Farm Bureau Insurance office in ${this.countyOffice.name} County`;
        }

        return 'Learn more about this Texas Farm Bureau Insurance office';
      },
      metaKeywords() {
        if (this.seoKeywords && this.seoKeywords !== null) {
          return this.seoKeywords;
        }

        return this.defaultMetaKeywords;
      },
      metaPageTitle() {
        return this.seoTitle ? this.seoTitle : (this.countyOffice ? `${this.countyOffice.name} County` : this.siteName);
      },
      metaUrl() {
        let pageUrl = `${process.env.VUE_APP_ROOT_URI}/county/details/${this.$route.params.county}`;

        if (this.$route.params.branch) {
          pageUrl += `/${this.$route.params.branch}`;
        }

        return pageUrl;
      },
      displayPageTitle() {
        return this.pageTitle ? this.pageTitle : (this.countyOffice ? `Office Serving ${this.countyOffice.name} County` : this.siteName);
      },
      blogKeywords() {
        return [`${this.replaceAll(this.$route.params.county, '-', '')} County`, (this.countyOffice ? `${this.countyOffice.name.countyName} County` : 'county')];
      },
      sortedAgents() {
        function surnameName(a, b) {
          if (a.designation < b.designation) return -1;
          if (a.designation > b.designation) return 1;
          if (a.lastName < b.lastName) return -1;
          if (a.lastName > b.lastName) return 1;
          if (a.firstName < b.firstName) return -1;
          if (a.firstName > b.firstName) return 1;
          return 0;
        }
        // return this.agentList.sort(surnameName); // sorts in-place
        return [...this.agentList].sort(surnameName); // shallow clone + sort
      },
    },
    methods: {
      getCountyInformation(countyName, branchCode) {
        this.$store.dispatch('agentcounty/getOfficesByCounty', { sCountyName: countyName, sBranchCode: branchCode, sTfbId: this.trackingId })
          .then((response) => {
            if (response && response.length > 0) {
              // eslint-disable-next-line prefer-destructuring
              this.countyOffice = response[0];
              this.countyOfficeName = this.countyOffice.name;

              // Add the county office to the map
              this.markers.push(
                {
                  infoText: this.countyOffice.officeMapInfoBox,
                  position: {
                    lat: Number(this.countyOffice.latitude),
                    lng: Number(this.countyOffice.longitude),
                  },
                  icon: defaultMarkerIcon,
                },
              );
              this.countyLoading = false;

              this.$store.dispatch('agentcounty/getAgentsByOffice', { sCountyName: countyName, sBranchCode: branchCode, sTfbId: this.trackingId })
                .then((agentResponse) => {
                  if (agentResponse && agentResponse.data && agentResponse.data._agents) {
                    for (let a = 0; a < agentResponse.data._agents.length; a += 1) {
                      const agent = agentResponse.data._agents[a];

                      // TODO: Get county/branch from getAgentDetails
                      this.$store.dispatch('agentcounty/getAgentDetails', { sAgentCode: agent._agentCode, sDistance: 0, sTfbId: this.trackingId })
                        .then((detailResponse) => {
                          if (detailResponse) {
                            detailResponse.county = countyName;
                            detailResponse.branchCode = branchCode;
                            this.agentList.push(detailResponse);
                            this.agentList.sort();
                          }
                          this.agentLoading = false;
                        })
                        .catch((error) => {
                          this.$store.dispatch('app/setErrorMessage', error.toString());
                          this.agentLoading = false;
                        });
                    }
                  }
                })
                .catch((error) => {
                  this.$store.dispatch('app/setErrorMessage', error.toString());
                  this.agentLoading = false;
                });

              this.$store.dispatch('app/getCountyDetails', { sCountyName: countyName, sBranchCode: branchCode, sTfbId: this.trackingId })
                .then((listResponse) => {
                  if (listResponse && listResponse.CountySummary) {
                    if (listResponse.CountySummary) {
                      // For some reason, SharePoint won't let you have "tel:" links in HTML fields (it will strip them out)
                      //  To work around this, click-to-call links are setup as such: http://tfbtel.(phonenumber)
                      //  We then replace "http://tfbtel." with the appropriate "tel:"
                      const telephoneReplace = /(http:\/\/tfbtel\.)/igm;
                      const telephoneReplaceAlt = /(http&#58;\/\/tfbtel\.)/igm;
                      this.countySummary = listResponse.CountySummary.replace(telephoneReplace, 'tel:').replace(telephoneReplaceAlt, 'tel:');
                    }
                    if (listResponse.SeoDescription) {
                      this.metaDescription = listResponse.SeoDescription;
                    }
                    if (listResponse.SeoTitle) {
                      this.seoTitle = listResponse.SeoTitle;
                    }
                    if (listResponse.SeoDescription) {
                      this.seoDescription = listResponse.SeoDescription;
                    }
                    if (listResponse.SeoKeywords1) {
                      this.seoKeywords = listResponse.SeoKeywords1;
                    }
                    if (listResponse.HeaderText) {
                      this.pageTitle = listResponse.HeaderText;
                    }
                    if (listResponse.SiFiTag) {
                      this.siFiTracking = listResponse.SiFiTag;
                    }
                  }
                })
                .catch(() => {
                  // Do nothing
                });
              this.$store.dispatch('app/setIsLoading', false);
            } else {
              this.countyLoading = false;
              this.agentLoading = false;
              this.$store.dispatch('app/setIsLoading', false);
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
            this.agentLoading = false;
            this.countyLoading = false;
            this.$store.dispatch('app/setIsLoading', false);
          });
      },
    },
    mounted() {
      this.$store.dispatch('app/clearErrorMessage');
      this.$store.dispatch('app/setIsLoading', true);

      if (this.$route.params.county === 'undefined') {
        this.$router.push({ name: 'countyLookup' });
      }
      this.getCountyInformation(this.$route.params.county, this.$route.params.branch);
    },
  };
</script>
<style lang="scss">

  .tfb-county-details {
    padding-top: 25px;
    color: $white;
  }

    .google-map {
      min-height: 500px;
      max-height: 1000px;
      width: 100%;
    }

  a.county-agent-links,
  a:visited.county-agent-links,
  .county-agent-summary a,
  .county-agent-summary a:visited {
    color: $white;
    text-decoration: underline;
  }

  a:hover.county-agent-links,
  .county-agent-summary a:hover{
    color: $white;
    text-decoration: none;
  }

  .county-agent-summary h2 {
    font-family: 'BS-light', $body-font-family;
    font-weight: 400;
    font-style: normal;
    font-size: 28px;
  }
  .tfb-county-icon.theme--light.v-icon {
    color: $white;
    text-decoration: none !important;
    margin: 0px 5px;
  }
</style>
