import { make } from 'vuex-pathify';
import axios from 'axios';
import helpers from '../../mixins/helpers';
/* eslint no-underscore-dangle: 0 */

// Vue.http.options.credentials = true;

// Vue.http.options.xhr = {
//  withCredentials: true,
// };
// Vue.http.options.emulateJSON = true;
// Vue.http.options.emulateHTTP = true;
// Vue.http.options.crossOrigin = true;

// Vue.http.headers.common['Access-Control-Allow-Origin'] = '*';
// Vue.http.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
// Vue.http.headers.common['Accept'] = 'application/json, text/plain, */*';
// Vue.http.headers.common['Access-Control-Allow-Headers'] =
// 'Origin, Accept, Content-Type, Authorization, Access-Control-Allow-Origin';

const agentLookupServiceUrl = `${process.env.VUE_APP_WEB_URI}/services/tfbic.services.restagent.lookup/rest_agentlookup.svc`;
const countyLookupServiceUrl = `${process.env.VUE_APP_WEB_URI}/services/tfbic.services.restcounty.lookup/rest_countylookup.svc`;

const config = {
  crossdomain: true,
  // withCredentials: true,
  // headers: { 'Access-Control-Allow-Origin': '*', Accept: 'application/json;' },
  // headers: { 'Access-Control-Allow-Origin': '*' },
  // headers: { Accept: 'application/json;' },
};

function agentTransform(agentData, context, agentDistance, tfbId) {
  try {
    if (agentData) {
      const agentName = ((!agentData._preferredName || agentData._preferredName === '') ? (`${agentData._firstName} ${agentData._lastName}`) : (`${agentData._preferredName} ${agentData._lastName}`));
      const agentMailingAddress = helpers.methods.buildAddress(agentData._mailingAddress, agentData._mailingAddress2, null,
        agentData._mailingCity, agentData._mailingState, agentData._mailingZip, agentData._mailingZip4, null);
      const agentPhysicalAddress = helpers.methods.buildAddress(agentData._physicalAddress, agentData._physicalAddress2, null,
        agentData._physicalCity, agentData._physicalState, agentData._physicalZip, agentData._physicalZip4, null);
      const officeMapUrl = helpers.methods.buildMapAddress(agentData._physicalAddress, agentData._physicalAddress2, null,
        agentData._physicalCity, agentData._physicalState, agentData._physicalZip, agentData._physicalZip4, null);
      const agentInfoBox = helpers.methods.buildAgentInfoBox(agentName, agentData._physicalAddress, agentData._physicalAddress2, agentData._physicalCity,
        agentData._physicalState, agentData._physicalZip);

      return {
        aboutAgent: agentData._aboutText,
        code: agentData._agentCode,
        startDate: agentData._agentSinceDate,
        emailAddress: agentData._emailAddress.toLowerCase(),
        errorMessage: agentData._errorMessage,
        faxNumber: helpers.methods.removePhoneFormatting(agentData._faxNumber),
        firstName: agentData._firstName,
        lastName: agentData._lastName,
        name: agentName,
        distance: agentDistance,
        county: '',
        branchCode: '',
        countyName: '',
        agentUrl: '',
        mailingAddress: {
          addressLineOne: agentData._mailingAddress,
          addressLineTwo: agentData._mailingAddress2,
          addressLineThree: '',
          addressCity: agentData._mailingCity,
          addressState: agentData._mailingState,
          addressZipCode: agentData._mailingZip,
          addressZipCode4: agentData._mailingZip4,
          addressZipCode2: '',
          addressLatitude: agentData._mailingLatitude,
          addressLongitude: agentData._mailingLongitude,
        },
        mailingAddressDisplay: agentMailingAddress,
        latitude: agentData._physicalLatitude,
        longitude: agentData._physicalLongitude,
        phoneNumber: helpers.methods.removePhoneFormatting(agentData._phoneNumber),
        photo: `${process.env.VUE_APP_ROOT_URI}/agentphotos/${agentData._agentCode}.jpg`,
        physicalAddress: {
          addressLineOne: agentData._physicalAddress,
          addressLineTwo: agentData._physicalAddress2,
          addressLineThree: '',
          addressCity: agentData._physicalCity,
          addressState: agentData._physicalState,
          addressZipCode: agentData._physicalZip,
          addressZipCode4: agentData._physicalZip4,
          addressZipCode2: '',
          addressLatitude: agentData._physicalLatitude,
          addressLongitude: agentData._physicalLongitude,
        },
        physicalAddressDisplay: agentPhysicalAddress,
        mapUrl: officeMapUrl,
        preferredName: agentData._preferredName,
        designation: agentData._titleDesignation,
        agentMapInfoBox: agentInfoBox,
      };
    }

    return agentData;
  } catch (error) {
    context.dispatch('app/logToULS', {
      logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'agentcounty.js', methodName: 'agentTransform-Catch', trackerId: tfbId,
    }, { root: true });
    return agentData;
  }
}

function dsmTransform(dsmData, context, tfbId) {
  try {
    if (dsmData) {
      const dsmName = ((!dsmData._preferredName || dsmData._preferredName === '') ? (`${dsmData._firstName} ${dsmData._lastName}`) : (`${dsmData._preferredName} ${dsmData._lastName}`));

      return {
        code: dsmData._agentCode,
        emailAddress: dsmData._emailAddress,
        errorMessage: dsmData._errorMessage,
        firstName: dsmData._firstName,
        lastName: dsmData._lastName,
        name: dsmName,
        phoneNumber: helpers.methods.removePhoneFormatting(dsmData._phoneNumber),
        photo: `${process.env.VUE_APP_ROOT_URI}/agentphotos/${dsmData._agentCode}.jpg`,
        preferredName: dsmData._preferredName,
        designation: dsmData._designation,
      };
    }

    return dsmData;
  } catch (error) {
    context.dispatch('app/logToULS', {
      logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'agentcounty.js', methodName: 'dsmTransform-Catch', trackerId: tfbId,
    }, { root: true });
    return dsmData;
  }
}

/**
 * officeTransform
 * @param {object} data - JSON object of office data
 * @param {string} latitude - latitude of the office
 * @param {string} longitude - longitude of the office
 * @returns {object} - JSON object of formatted office data
 */
function officeTransform(officeData, context, latitude, longitude, tfbId) {
  try {
    const returnOffice = [];

    if (officeData) {
      if (officeData._errorMessage === null || officeData._errorMessage === '') {
        if (officeData._countyOffice) {
          for (let i = 0; i < officeData._countyOffice.length; i += 1) {
            const office = officeData._countyOffice[i];
            if (office) {
              let officeDistance = 0;
              const officeMailingAddress = helpers.methods.buildAddress(office._mailingStreetAddress, office._mailingStreetAddress2, null,
                office._mailingCity, office._mailingState, office._mailingZip, office._mailingZip4, null);
              const officePhysicalAddress = helpers.methods.buildAddress(office._physicalStreetAddress, office._physicalStreetAddress2, null,
                office._physicalCity, office._physicalState, office._physicalZip, office._physicalZip4, null);
              const officeMapUrl = helpers.methods.buildMapAddress(office._physicalStreetAddress, office._physicalStreetAddress2, null,
                office._physicalCity, office._physicalState, office._physicalZip, office._physicalZip4, null);
              const officeInfoBox = helpers.methods.buildOfficeInfoBox(`${office._name} Location`, office._name, office._branchCode,
                office._physicalStreetAddress, office._physicalStreetAddress2, office._physicalCity, office._physicalState, office._physicalZip);

              if (latitude !== null && longitude !== null) {
                officeDistance = (3959 * Math.acos(Math.cos(latitude * Math.PI / 180)
                  * Math.cos(office._latitude * Math.PI / 180) * Math.cos((office._longitude * Math.PI / 180)
                    - (longitude * Math.PI / 180)) + Math.sin(latitude * Math.PI / 180) * Math.sin(office._latitude * Math.PI / 180)));
              }

              const officeListing = {
                name: office._name,
                branchCode: office._branchCode,
                distance: officeDistance,
                latitude: office._latitude,
                longitude: office._longitude,
                officeNumber: office._officeNumber,
                salesDistrict: office._salesDistrict,
                faxNumber: helpers.methods.removePhoneFormatting(office._faxNumber),
                phoneNumber: helpers.methods.removePhoneFormatting(office._phoneNumber),
                mailingAddress: {
                  addressLineOne: office._mailingStreetAddress,
                  addressLineTwo: office._mailingStreetAddress2,
                  addressLineThree: '',
                  addressCity: office._mailingCity,
                  addressState: office._mailingState,
                  addressZipCode: office._mailingZip,
                  addressZipCode4: office._mailingZip4,
                  addressZipCode2: '',
                  addressLatitude: office._mailingLatitude,
                  addressLongitude: office._mailingLongitude,
                },
                mailingAddressDisplay: officeMailingAddress,
                physicalAddress: {
                  addressLineOne: office._physicalStreetAddress,
                  addressLineTwo: office._physicalStreetAddress2,
                  addressLineThree: '',
                  addressCity: office._physicalCity,
                  addressState: office._physicalState,
                  addressZipCode: office._physicalZip,
                  addressZipCode4: office._physicalZip4,
                  addressZipCode2: '',
                  addressLatitude: office.physicalLatitude,
                  addressLongitude: office._physicalLongitude,
                },
                physicalAddressDisplay: officePhysicalAddress,
                mapUrl: officeMapUrl,
                officeMapInfoBox: officeInfoBox,
              };

              returnOffice.push(officeListing);
            }
          }

          return returnOffice;
        }
      }
    }
    return officeData;
  } catch (error) {
    context.dispatch('app/logToULS', {
      logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'agentcounty.js', methodName: 'officeTransform-Catch', trackerId: tfbId,
    }, { root: true });
    return officeData;
  }
}

// state
const state = {
  aboutAgent: '',
  code: '',
  startDate: '',
  emailAddress: '',
  errorMessage: null,
  faxNumber: '',
  firstName: '',
  lastName: '',
  name: '',
  mailingAddress: {
    addressLineOne: '',
    addressLineTwo: '',
    addressLineThree: '',
    addressCity: '',
    addressState: '',
    addressZipCode: '',
    addressZipCode4: '',
    addressZipCode2: '',
    addressLatitude: '',
    addressLongitude: '',
  },
  phoneNumber: '',
  photo: '',
  physicalAddress: {
    addressLineOne: '',
    addressLineTwo: '',
    addressLineThree: '',
    addressCity: '',
    addressState: '',
    addressZipCode: '',
    addressZipCode4: '',
    addressZipCode2: '',
    addressLatitude: '',
    addressLongitude: '',
  },
  preferredName: '',
  designation: '',
};

// mutations
const mutations = {
  ...make.mutations(state),
};

// getters
const getters = {
  ...make.getters(state),
};

// actions
const actions = {
  getAgentList(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${agentLookupServiceUrl}/agents`, config)
        .then((agentResponse) => {
          resolve(agentResponse);
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'agentcounty.js', methodName: 'getAgentList-Catch', trackerId: '',
          }, { root: true });
          reject(error);
        });
    });
  },
  getAgentsByOffice(context, sRequest) {
    let serviceUrl = `${agentLookupServiceUrl}/agentsbycounty?county=${helpers.methods.replaceAll(sRequest.sCountyName, '-', ' ')}&branch=`;
    if (sRequest.sBranchCode && sRequest.sBranchCode !== '') {
      serviceUrl += `${sRequest.sBranchCode}`;
    }
    return new Promise((resolve, reject) => {
      axios
        .get(serviceUrl, config)
        .then((countyResponse) => {
          resolve(countyResponse);
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'agentcounty.js', methodName: 'getAgentsByOffice-Catch', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  async getAgentCodeByEmail(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${agentLookupServiceUrl}/agent/email/${sRequest.sAgentEmail}`,
          config)
        .then((agentResponse) => {
          if (agentResponse && agentResponse.data) {
            resolve(agentResponse.data);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'agentcounty.js', methodName: 'getAgentCodeByEmail-Catch', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  async getAgentCodeByMember(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${agentLookupServiceUrl}/agent/membernumber/${sRequest.sMemberNumber}`,
          config)
        .then((agentResponse) => {
          if (agentResponse && agentResponse.data) {
            resolve(agentResponse.data);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'agentcounty.js', methodName: 'getAgentCodeByMember-Catch', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  async getAgentDetails(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${agentLookupServiceUrl}/agentwlatlong/code/${sRequest.sAgentCode}`,
          config)
        .then((agentResponse) => {
          if (agentResponse && agentResponse.data) {
            const agentDetails = agentTransform(agentResponse.data, context, sRequest.sDistance, sRequest.sTfbId);
            if (agentDetails._errorMessage && (agentDetails._errorMessage !== null || agentDetails._errorMessage !== '')) {
              agentDetails.code = sRequest.sAgentCode;
              agentDetails.photo = `${process.env.VUE_APP_ROOT_URI}/agentphotos/9999-.jpg`;
              resolve(agentDetails);
            } else {
              // TODO: Get county/branch from first service
              context.dispatch('getAgentList', {
                sTfbId: sRequest.sTfbId,
              })
                .then((foundAgents) => {
                  if (foundAgents !== null && foundAgents.data !== null && foundAgents.data.length > 0) {
                      for (let a = 0; a < foundAgents.data.length; a += 1) {
                        const agent = foundAgents.data[a];
                        if (agent._agentCode === sRequest.sAgentCode) {
                          const countyName = helpers.methods.replaceAll(agent._countyName, ' ', '-').toLowerCase();
                          const agentName = helpers.methods.replaceAll(helpers.methods.replaceAll(helpers.methods.replaceAll(agent._agentName, ' ', '-'), '.', ''), ',', '').toLowerCase();
                          agentDetails.agentUrl = `https://${window.location.host}/insurance/agent/${countyName}/${agentName}/${agent._agentCode}`;
                          agentDetails.countyName = agent._countyName;
                          resolve(agentDetails);
                        }
                      }
                    resolve(agentDetails);
                  } else {
                    resolve(agentDetails);
                  }
                })
                .catch((error) => {
                  context.dispatch('app/logToULS', {
                    logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'agentcounty.js', methodName: 'getAgentDetails.getAgentList-Catch', trackerId: sRequest.sTfbId,
                  }, { root: true });
                  resolve(agentDetails);
                });
            }
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'agentcounty.js', methodName: 'getAgentDetails-Catch', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  async getCountyList(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${countyLookupServiceUrl}/county`,
          config)
        .then((countyResponse) => {
          resolve(countyResponse);
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'agentcounty.js', methodName: 'getCountyList-Catch', trackerId: '',
          }, { root: true });
          reject(error);
        });
    });
  },
  getOfficesByLatLong(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${countyLookupServiceUrl}/county/office/${sRequest.sLatitude}/${sRequest.sLongitude}/${sRequest.sRadius}`,
          config)
        .then((officeResponse) => {
          if (officeResponse && officeResponse.data) {
            resolve(officeTransform(officeResponse.data, context, sRequest.sLatitude, sRequest.sLongitude, sRequest.sTfbId));
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'agentcounty.js', methodName: 'getOfficesByLatLong-Catch', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getOfficesByCounty(context, sRequest) {
    let serviceUrl = `${countyLookupServiceUrl}/county/${helpers.methods.replaceAll(sRequest.sCountyName, '-', ' ')}`;

    if (sRequest.sBranchCode && sRequest.sBranchCode !== '') {
      serviceUrl += `/branch/${sRequest.sBranchCode}`;
    }

    return new Promise((resolve, reject) => {
      axios
        .get(serviceUrl, config)
        .then((officeResponse) => {
          if (officeResponse && officeResponse.data) {
            resolve(officeTransform(officeResponse.data, context, null, null, sRequest.sTfbId));
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'agentcounty.js', methodName: 'getOfficesByCounty-Catch', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  async getSalesManager(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${agentLookupServiceUrl}/dsm/county/${sRequest.sCountyName}`,
          config)
        .then((dsmResponse) => {
          if (dsmResponse && dsmResponse.data) {
            resolve(dsmTransform(dsmResponse.data, context, sRequest.sTfbId));
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'agentcounty.js', methodName: 'getSalesManager-Catch', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
