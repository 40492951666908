<template>
  <div>
    <v-row>
      <v-col cols="12"
             class="py-1">
        <h3 class="tfb-title-text-small text-blue text-uppercase">Locations</h3>
      </v-col>
    </v-row>
    <v-container fluid
                 class="pt-0"
                 v-if="locations && locations.length > 0">
      <v-row v-for="location in locations"
             v-bind:key="location.LocationNumber">
        <v-col cols="12">
          <span v-if="location.LocationBuiltDescription !== null && location.LocationBuiltDescription !== ''">
            {{location.LocationBuiltDescription}}
          </span>
          <span v-else>
            {{location.CountyName}}
          </span>
          <v-expansion-panels accordion
                              flat
                              class="tfb-section py-0">
            <v-expansion-panel>
              <v-expansion-panel-header class="pa-0">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="12"
                         sm="5">
                    <template v-slot:actions>
                      <v-icon>$expand</v-icon>
                    </template>
                    <h3 class="text-blue font-weight-bold">Coverage Information</h3>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0 background-lightest-gray">
                <v-container>
                  <v-row v-if="location.OccurrenceLimit && location.OccurrenceLimit !== null && location.OccurrenceLimit !== ''">
                    <v-col cols="12"
                           sm="8"
                           class="py-0"><label class="font-weight-bold">Each Occurrence Limit (CSL):</label></v-col>
                    <v-col cols="12"
                           sm="4"
                           class="py-0 text-right text-sm-left">{{formatMoney(location.OccurrenceLimit, 0)}}</v-col>
                    <v-col cols="12"
                           class="py-0 d-block d-sm-none d-md-none d-lg-none d-xl-none"><hr /></v-col>
                  </v-row>
                  <v-row v-if="location.AggregateLimit && location.AggregateLimit !== null && location.AggregateLimit !== ''">
                    <v-col cols="12"
                           sm="8"
                           class="py-0"><label class="font-weight-bold">General Aggregate Limit (CSL):</label></v-col>
                    <v-col cols="12"
                           sm="4"
                           class="py-0 text-right text-sm-left">{{formatMoney(location.AggregateLimit, 0)}}</v-col>
                    <v-col cols="12"
                           class="py-0 d-block d-sm-none d-md-none d-lg-none d-xl-none"><hr /></v-col>
                  </v-row>
                  <!--<v-row v-if="location.PersonalAdvertisingInjuryLimit && location.PersonalAdvertisingInjuryLimit !== null && location.PersonalAdvertisingInjuryLimit !== ''">
                    <v-col cols="12"
                           sm="8"
                           class="py-0"><label class="font-weight-bold">Personal and Advertising Injury Limit:</label></v-col>
                    <v-col cols="12"
                           sm="4"
                           class="py-0 text-right text-sm-left">{{formatMoney(location.PersonalAdvertisingInjuryLimit, 0)}}</v-col>
                    <v-col cols="12"
                           class="py-0 d-block d-sm-none d-md-none d-lg-none d-xl-none"><hr /></v-col>
                  </v-row>
                  <v-row v-if="location.MedicalLimit && location.MedicalLimit !== null && location.MedicalLimit !== ''">
                    <v-col cols="12"
                           sm="8"
                           class="py-0"><label class="font-weight-bold">Medical Payment Limit:</label></v-col>
                    <v-col cols="12"
                           sm="4"
                           class="py-0 text-right text-sm-left">{{formatMoney(location.MedicalLimit, 0)}}</v-col>
                    <v-col cols="12"
                           class="py-0 d-block d-sm-none d-md-none d-lg-none d-xl-none"><hr /></v-col>
                  </v-row>-->
                </v-container>
                <!--<div v-if="location.Dwellings && location.Dwellings.length > 0">
                  <v-container v-for="dwelling in location.Dwellings"
                               v-bind:key="dwelling.DwellingNumber">
                    <v-row>
                      <v-col cols="12">
                        <span class="tfb-title-text-smallest font-weight-bold">{{dwelling.DwellingBuiltDescription}}</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <span class="font-italic">{{dwelling.DwellingOccupancy}}</span>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>-->
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="12"
               class="d-block d-md-none d-lg-none d-xl-none"><hr /></v-col>
      </v-row>
    </v-container>
    <v-row v-else>
      <v-col cols="12"
             class="py-0 px-6">
        None
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'FarmLiabilityLocations',
    props: {
      locations: {
        type: Array,
        required: true,
      },
    },
    mixins: [tfbHelperMixin],
  };
  </script>
<style lang="scss">

</style>
