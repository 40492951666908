<template>
  <v-container fluid
               class="tfb-container">
    <span class="tfb-title-text-medium text-darker-blue text-uppercase">Account Bill</span>
    <v-row>
      <v-col cols="12">
        <v-container fluid
                     class="drop-shadow background-white tfb-smaller-container">
          <div v-if="!showReview && !showConfirmation">
            <v-row>
              <v-col cols="12">
                <p>
                  In order to enroll in Texas Farm Bureau Insurance Account Bill, please read the <a href="/consumerdocuments/accountbilltermsandconditions.pdf"
                                                                                                     id="account-bill-terms"
                                                                                                     target="_blank"
                                                                                                     title="Account Bill Terms and Conditions">Terms and Conditions</a>,
                  then complete and submit this Authorization and Agreement for Account Bill.
                  <i>
                    You must continue to pay any billing statements you receive by mail or
                    email until your Account Bill application is completed and accepted and you receive your first Account Bill statement.
                  </i>
                </p>
              </v-col>
            </v-row>
            <v-row v-if="policyListLoading">
              <v-col cols="12">
                <v-skeleton-loader :loading="policyListLoading"
                                   type="table"
                                   class="mx-auto">
                </v-skeleton-loader>
              </v-col>
            </v-row>
            <ValidationObserver ref="obvenr" v-slot="{ handleSubmit }">
              <v-simple-table dense
                              v-if="!policyListLoading">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Enroll</th>
                      <th>Policy Type</th>
                      <th>Policy Number</th>
                    </tr>
                  </thead>
                  <ValidationProvider tag="tbody" name="policy" rules="required|min_length:2" v-slot="{ errors }">
                    <tr v-for="policy in accountBillEligible"
                        :key="policy.PolicyNumber">
                      <td>
                        <v-checkbox v-model="enrollForm.accountBillArray"
                                    :value="policy" />
                      </td>
                      <td><span v-html="policy.PolicyDescription"></span></td>
                      <td>{{policy.PolicyNumber}}</td>
                    </tr>
                    <tr><td colspan="3" class="v-messages theme--light error--text">{{ errors[0] }}</td></tr>
                  </ValidationProvider>
                </template>
              </v-simple-table>
              <required-fields-row></required-fields-row>
              <v-form ref="enrollForm"
                      @submit.stop.prevent="handleSubmit(reviewAccountBillChanges)">
                <!-- First Name -->
                <v-row>
                  <v-col cols="12">
                    <text-input rules="required|name|min:2|max:25"
                                minlength="2"
                                maxlength="25"
                                label="First Name"
                                v-model="enrollForm.firstName"
                                autocomplete="shipping given-name"
                                autocapitalize="words"></text-input>
                  </v-col>
                </v-row>
                <!-- Last Name -->
                <v-row>
                  <v-col cols="12">
                    <text-input rules="required|name|min:2|max:25"
                                minlength="2"
                                maxlength="25"
                                label="Last Name"
                                v-model="enrollForm.lastName"
                                autocomplete="shipping family-name"
                                autocapitalize="words"></text-input>
                  </v-col>
                </v-row>
                <!-- Address Line One -->
                <v-row>
                  <v-col cols="12">
                    <text-input rules="required|min:2|max:250"
                                minlength="2"
                                maxlength="250"
                                label="Address Line 1"
                                v-model="enrollForm.addressLine1"
                                autocomplete="shipping address-line1"
                                autocapitalize="words"></text-input>
                  </v-col>
                </v-row>
                <!-- Address Line Two -->
                <v-row>
                  <v-col cols="12">
                    <text-input rules="min:2|max:250"
                                minlength="2"
                                maxlength="250"
                                label="Address Line 2"
                                v-model="enrollForm.addressLine2"
                                autocomplete="shipping address-line2"
                                autocapitalize="words"></text-input>
                  </v-col>
                </v-row>
                <!-- City -->
                <v-row>
                  <v-col cols="12">
                    <text-input rules="required|min:2|max:250"
                                minlength="2"
                                maxlength="250"
                                label="City"
                                v-model="enrollForm.city"
                                autocomplete="shipping address-level2"
                                autocapitalize="words"></text-input>
                  </v-col>
                </v-row>
                <!-- State -->
                <v-row>
                  <v-col cols="12">
                    <select-input rules="required|alpha"
                                  label="State"
                                  :items="stateList"
                                  item-text="StateName"
                                  item-value="StateCode"
                                  v-model="enrollForm.state"></select-input>
                  </v-col>
                </v-row>
                <!-- County -->
                <v-row>
                  <v-col cols="12">
                    <select-input rules="required"
                                  label="County"
                                  :items="countyOptions"
                                  item-text="_countyName"
                                  item-value="_countyName"
                                  v-model="enrollForm.county"></select-input>
                  </v-col>
                </v-row>
                <!-- Zip Code -->
                <v-row>
                  <v-col cols="12">
                    <text-input rules="required|numeric|min:5|max:5"
                                minlength="5"
                                maxlength="5"
                                label="ZIP Code"
                                v-model="enrollForm.zipCode"
                                type="number"
                                autocomplete="shipping postal-code"></text-input>
                  </v-col>
                </v-row>
                <!-- Draft Day -->
                <v-row>
                  <v-col cols="12">
                    <select-input rules="required|numeric"
                                  label="Requested Draft Day"
                                  :items="draftDayList"
                                  v-model="enrollForm.draftDay"></select-input>
                  </v-col>
                </v-row>
                <!-- Assurancy Pay Enrollment -->
                <v-row>
                  <v-col cols="12"
                         md="8">
                    <label class="font-weight-bold">Would you like to sign up for AssurancePay&reg;?</label>
                  </v-col>
                  <v-col cols="12"
                         md="4">
                    <radio-group-input rules="required"
                                       name="AssurancePay enrollment"
                                       :options="yesNoOptions"
                                       v-model="enrollForm.assurancePayFlag"
                                       vid="recur"></radio-group-input>
                  </v-col>
                </v-row>
                <div v-if="enrollForm.assurancePayFlag === 'Y'">
                  <v-row>
                    <v-col cols="12">
                      <text-input rules="required_if:recur,Y|min:4|max:60"
                                  minlength="4"
                                  maxlength="60"
                                  label="Name on Bank Account"
                                  v-model="enrollForm.accountName"></text-input>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <text-input rules="routing_number|required_if:recur,Y|numeric|min:4|max:10"
                                  minlength="4"
                                  maxlength="10"
                                  label="Bank Routing Number"
                                  @blur="getBankName()"
                                  type="number"
                                  v-model="enrollForm.routingNumber"></text-input>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <text-input rules="required_if:recur,Y"
                                  label="Bank Name"
                                  disabled
                                  v-model="enrollForm.bankName"></text-input>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <text-input rules="required_if:recur,Y|confirmed:account|min:4|max:17"
                                  minlength="4"
                                  maxlength="17"
                                  label="Bank Account Number"
                                  v-model="enrollForm.accountNumber"></text-input>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <text-input rules="required_if:recur,Y|min:4|max:17"
                                  minlength="4"
                                  maxlength="17"
                                  label="Confirm Bank Account Number"
                                  v-model="enrollForm.confirmAccountNumber"
                                  vid="account"></text-input>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <select-input rules="required_if:recur,Y"
                                    label="Bank Account Type"
                                    :items="accountTypeOptions"
                                    item-text="DisplayText"
                                    item-value="Value"
                                    return-object
                                    v-model="enrollForm.accountType"></select-input>
                    </v-col>
                  </v-row>
                </div>
                <v-row>
                  <v-col cols="6">
                    <v-btn block
                           @click="cancelAccountBillChanges()"
                           class="tfb-button background-red">Cancel</v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn block
                           :disabled="formDisabled"
                           type="submit"
                           class="tfb-button background-red">Submit</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </ValidationObserver>
          </div>
          <div v-if="showReview">
            <v-row>
              <v-col cols="12">
                <p>
                  Please review the information below. Click the Submit button when you have verified the information captured is correct.
                  If the information below is not correct, please click on the Modify button to revise. Clicking Cancel will terminate your
                  request and return you to the Account Summary screen.
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-container fluid>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table dense
                                    disable-filtering
                                    disable-pagination
                                    disable-sort
                                    :headers="accountBillHeaders"
                                    hide-default-footer
                                    :items="enrollForm.accountBillArray"
                                    :items-per-page="10">
                        <template v-slot:top>
                          <div class="font-weight-bold">Policies to enroll:</div>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6"
                           class="py-0">
                      <label class="font-weight-bold">First Name</label>
                    </v-col>
                    <v-col cols="6"
                           class="py-0">
                      {{enrollForm.firstName}}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6"
                           class="py-0">
                      <label class="font-weight-bold">Last Name</label>
                    </v-col>
                    <v-col cols="6"
                           class="py-0">
                      {{enrollForm.lastName}}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6"
                           class="py-0">
                      <label class="font-weight-bold">Address Line 1</label>
                    </v-col>
                    <v-col cols="6"
                           class="py-0">
                      {{enrollForm.addressLine1}}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6"
                           class="py-0">
                      <label class="font-weight-bold">Address Line 2</label>
                    </v-col>
                    <v-col cols="6"
                           class="py-0">
                      {{enrollForm.addressLine2}}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6"
                           class="py-0">
                      <label class="font-weight-bold">City</label>
                    </v-col>
                    <v-col cols="6"
                           class="py-0">
                      {{enrollForm.city}}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6"
                           class="py-0">
                      <label class="font-weight-bold">State</label>
                    </v-col>
                    <v-col cols="6"
                           class="py-0">
                      {{enrollForm.state}}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6"
                           class="py-0">
                      <label class="font-weight-bold">County</label>
                    </v-col>
                    <v-col cols="6"
                           class="py-0">
                      {{enrollForm.county}}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6"
                           class="py-0">
                      <label class="font-weight-bold">ZIP Code</label>
                    </v-col>
                    <v-col cols="6"
                           class="py-0">
                      {{enrollForm.zipCode}}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6"
                           class="py-0">
                      <label class="font-weight-bold">Requested Payment Day</label>
                    </v-col>
                    <v-col cols="6"
                           class="py-0">
                      {{enrollForm.draftDay}}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6"
                           class="py-0">
                      <label class="font-weight-bold">Sign Up for AssurancePay&reg;?</label>
                    </v-col>
                    <v-col cols="6"
                           class="py-0">
                      {{enrollForm.assurancePayFlag}}
                    </v-col>
                  </v-row>
                  <div v-if="enrollForm.assurancePayFlag === 'Y'">
                    <v-row>
                      <v-col cols="6"
                             class="py-0">
                        <label class="font-weight-bold">Name on Bank Account</label>
                      </v-col>
                      <v-col cols="6"
                             class="py-0">
                        {{enrollForm.accountName}}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6"
                             class="py-0">
                        <label class="font-weight-bold">Bank Name</label>
                      </v-col>
                      <v-col cols="6"
                             class="py-0">
                        {{enrollForm.bankName}}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6"
                             class="py-0">
                        <label class="font-weight-bold">Bank Routing Number</label>
                      </v-col>
                      <v-col cols="6"
                             class="py-0">
                        {{enrollForm.MaskedRoutingNumber}}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6"
                             class="py-0">
                        <label class="font-weight-bold">Bank Account Number</label>
                      </v-col>
                      <v-col cols="6"
                             class="py-0">
                        {{enrollForm.MaskedAccountNumber}}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6"
                             class="py-0">
                        <label class="font-weight-bold">Type of Account</label>
                      </v-col>
                      <v-col cols="6"
                             class="py-0">
                        {{enrollForm.accountType.DisplayText}}
                      </v-col>
                    </v-row>
                  </div>
                </v-container>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn block
                       @click="modifyAccountBillChanges()"
                       class="tfb-button background-red">Modify</v-btn>
              </v-col>
            </v-row>
            <ValidationObserver ref="obvconf" v-slot="{ handleSubmit }">
              <v-form ref="reviewForm"
                      @submit.stop.prevent="handleSubmit(saveAccountBillChanges)">
                <keep-alive>
                  <ValidationProvider tag="div"
                                      :bails="false"
                                      rules="required"
                                      name="abconfirmation"
                                      v-slot="{ errors, valid, required }">
                    <v-checkbox v-model="reviewForm.confirmation"
                                :error-messages="errors"
                                :success="valid"
                                type="checkbox">
                      <template v-slot:label>
                        <span>
                          I have read and agree to the <a href="/consumerdocuments/accountbilltermsandconditions.pdf"
                                                          id="account-bill-terms"
                                                          target="_blank"
                                                          @click.stop
                                                          title="Account Bill Terms and Conditions">Terms and Conditions</a> for Account Bill.
                          I authorize Texas Farm Bureau Mutual Insurance Company, Texas Farm Bureau Underwriters, Texas Farm Bureau Casualty
                          Insurance Company, and/or Farm Bureau County Mutual Insurance Company of Texas (the &ldquo;Companies&rdquo;, &ldquo;us&rdquo;, or &ldquo;we&rdquo;)
                          to apply one payment to multiple policies of insurance. My one payment will be applied to premium owed on all of
                          the policies I list above and any applicable fees, and may be applied to any delinquent Texas Farm Bureau membership
                          dues, in accordance with the Terms and Conditions listed above.
                        </span>
                      </template>
                      <template v-slot:append>
                        <v-icon>mdi-asterisk</v-icon>
                      </template>
                    </v-checkbox>
                  </ValidationProvider>
                </keep-alive>
                <keep-alive v-if="enrollForm.assurancePayFlag === 'Y'">
                  <ValidationProvider tag="div"
                                      :bails="false"
                                      rules="required"
                                      name="apconfirmation"
                                      v-slot="{ errors, valid, required }">
                    <v-checkbox v-model="reviewForm.assuranceConfirmation"
                                :error-messages="errors"
                                :success="valid"
                                type="checkbox">
                      <template v-slot:label>
                        <span>
                          I have read and agree to the <a href="/consumerdocuments/recurring%20payments%20terms%20and%20conditions.pdf"
                                                          id="assurance-terms"
                                                          target="_blank"
                                                          @click.stop
                                                          title="AssurancePay Terms and Conditions">Terms and Conditions</a>
                          for AssurancePay&reg; Recurring Premium Payments via Electronic Funds Transfer (EFT.)
                          I authorize the Texas Farm Bureau Underwriters, Texas Farm Bureau Casualty Insurance Company, Farm Bureau County
                          Mutual Insurance Company of Texas, or Texas Farm Bureau Mutual Insurance Company, as the Companies issuing the
                          policy described above, to initiate through the designated financial entity the appropriate entries to transfer
                          premium payments. I understand I am responsible for paying any paper billings I may already have received or
                          receive prior to receiving an Account Bill statement.
                        </span>
                      </template>
                      <template v-slot:append>
                        <v-icon>mdi-asterisk</v-icon>
                      </template>
                    </v-checkbox>
                  </ValidationProvider>
                </keep-alive>
                <v-row>
                  <v-col cols="6">
                    <v-btn block
                           @click="cancelAccountBillChanges()"
                           class="tfb-button background-red">Cancel</v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn block
                           type="submit"
                           class="tfb-button background-red">Submit</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </ValidationObserver>
          </div>
          <div v-if="showConfirmation">
            <v-row>
              <v-col cols="12">
                <span class="tfb-title-text-small text-darker-blue text-uppercase">
                  Confirmation
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span class="tfb-title-text-smaller">Your request for Account Bill has been submitted for processing.</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                Thank you for choosing Texas Farm Bureau Insurance Companies.
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-btn block
                       @click="cancelAccountBillChanges()"
                       class="tfb-button background-red">Return to Account Summary</v-btn>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  /* eslint no-underscore-dangle: 0 */
  import { dispatch } from 'vuex-pathify';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import tfbMetaInfo from '@/mixins/metaInfo';
  import tfbHelperMixin from '@/mixins/helpers';
  import { insuredAccessOnlyErrorMessage } from '@/mixins/errorMessages';
  import RequiredFieldsRow from '@/views/layout/components/RequiredFieldsRow.vue';

  export default {
    name: 'ChangeAccountBill',
    data() {
      return {
        formDisabled: false,
        policyListLoading: false,
        bankLoading: false,
        showReview: false,
        showConfirmation: false,
        reviewForm: {},
        enrollForm: {
          accountBillArray: [],
          firstName: null,
          lastName: null,
          addressLine1: null,
          addressLine2: null,
          city: null,
          state: null,
          county: null,
          zipCode: null,
          draftDay: 15,
          assurancePayFlag: null,
          accountName: null,
          routingNumber: null,
          bankName: null,
          accountNumber: null,
          confirmAccountNumber: null,
          accountType: null,
        },
        countyOptions: [],
        stateList: [],
        accountTypeOptions: [{ Id: 0, DisplayText: 'Checking', Value: 'CH' }, { Id: 1, DisplayText: 'Savings', Value: 'SA' }],
        accountBillHeaders: [
          { text: 'Policy Type', align: 'start', value: 'PolicyDescription' },
          { text: 'Policy Number', value: 'PolicyNumber' },
        ],
        yesNoOptions: [{ Id: 0, DisplayText: 'Yes', Value: 'Y' }, { Id: 1, DisplayText: 'No', Value: 'N' }],
      };
    },
    mixins: [tfbMetaInfo, tfbHelperMixin],
    components: {
      ValidationObserver,
      ValidationProvider,
      RequiredFieldsRow,
    },
    computed: {
      metaPageTitle() {
        return 'Account Bill | PolicyHolder';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/policyholder/payments/account-bill/enroll`;
      },
      memberNumber() {
        try {
          return this.$store.getters['member/memberNumber'];
        } catch (error) {
          return '';
        }
      },
      accountBillEligible() {
        try {
          const eligiblePolicies = this.$store.getters['policy/accountSummary'];
          if (!eligiblePolicies || eligiblePolicies === null || eligiblePolicies.length <= 0) return [];
          return eligiblePolicies.filter(p => p.AccountBillEligibleFlag.toUpperCase().indexOf('Y') > -1);
        } catch (error) {
          return [];
        }
      },
      draftDayList() {
        const dayList = [];
        for (let i = 1; i <= 28; i += 1) {
          dayList.push(i);
        }

        return dayList;
      },
    },
    methods: {
      getCountyList() {
        this.$store.dispatch('agentcounty/getCountyList')
          .then((response) => {
            if (response && response.data && response.data._counties) {
              this.countyOptions = response.data._counties;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      },
      getStateList() {
        // Get the state information
        dispatch('app/getStateList', { sTfbId: this.trackingId })
          .then((stateListResponse) => {
            if (stateListResponse && stateListResponse.length > 0) {
              this.stateList = stateListResponse;
            }
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error.toString());
          });
      },
      getMemberInformation() {
        const includedToken = this.$route.query.access_token;
        this.$store.dispatch('member/validateLogin', { sToken: includedToken, sTfbId: this.trackingId })
          .then((loginValid) => {
            if (loginValid) {
              this.memberNumber = this.$store.getters['member/memberNumber'];
              this.email = this.$store.getters['member/emailAddress'];
              this.enrollForm.firstName = this.$store.getters['member/memberFirstName'];
              this.enrollForm.lastName = this.$store.getters['member/memberLastName'];

              const memberAddress = this.$store.getters['member/memberAddress'];
              if (memberAddress && memberAddress !== null) {
                this.enrollForm.addressLine1 = memberAddress.Address1;
                this.enrollForm.addressLine2 = memberAddress.Address2;
                this.enrollForm.city = memberAddress.City;
                this.enrollForm.state = memberAddress.State;
                this.enrollForm.zipCode = memberAddress.ZipCode;
              }
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
      getBankName() {
        this.bankLoading = true;
        dispatch('payments/validateRoutingNumber', {
          sRoutingNumber: this.enrollForm.routingNumber, sTfbId: this.trackingId,
        })
          .then((validationResult) => {
            this.enrollForm.bankName = validationResult;
            if (!validationResult || validationResult === '') {
              this.enrollForm.routingNumber = null;
            }
            this.bankLoading = false;
          })
          .catch((error) => {
            if (error.toString().indexOf('Request failed with status code 401') >= 0) {
              // Session has timed out, log out and go to login
              this.$store.dispatch('member/doLogout')
                .then(() => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$router.push({ name: 'policyholderLogin' });
                })
                .catch((accessError) => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$store.dispatch('app/setErrorMessage', accessError.toString());
                });
            }
            dispatch('app/setErrorMessage', error.toString());
            this.bankLoading = false;
          });
      },
      cancelAccountBillChanges() {
        // Go back to Summary screen
        this.$router.push({ name: 'policyholderAccountSummary' });
      },
      modifyAccountBillChanges() {
        this.showReview = false;
        this.showConfirmation = false;
      },
      reviewAccountBillChanges() {
        if (this.enrollForm.assurancePayFlag === 'Y') {
          this.enrollForm.MaskedAccountNumber = this.maskString(this.enrollForm.accountNumber, '5', 'X');
          this.enrollForm.MaskedRoutingNumber = this.maskString(this.enrollForm.routingNumber, '5', 'X');
        }

        this.showReview = true;
        this.showConfirmation = false;
      },
      saveAccountBillChanges() {
        dispatch('app/setIsLoading', true);
        this.enrollForm.memberNumber = this.memberNumber;
        this.enrollForm.sTfbId = this.trackingId;
        this.$store.dispatch('app/logToULS', {
          logLevel: 'Info',
          logMessage: JSON.stringify(this.enrollForm),
          fileName: 'ChangeAccountBill.vue',
          methodName: 'saveAccountBillChanges-Start',
          trackerId: this.trackingId,
        });
          dispatch('payments/manageAccountBill', this.enrollForm)
            .then((policyResult) => {
              dispatch('app/setIsLoading', false);
              if (policyResult) {
                this.showReview = false;
                this.showConfirmation = true;
              } else {
                dispatch('app/setErrorMessage', '');
              }
            })
            .catch((error) => {
              if (error.toString().indexOf('Request failed with status code 401') >= 0) {
                // Session has timed out, log out and go to login
                this.$store.dispatch('member/doLogout')
                  .then(() => {
                    dispatch('app/setIsLoading', false);
                    this.$router.push({ name: 'policyholderLogin' });
                  })
                  .catch((accessError) => {
                    dispatch('app/setIsLoading', false);
                    this.$store.dispatch('app/setErrorMessage', accessError.toString());
                  });
              }
              dispatch('app/setErrorMessage', error.toString());
              dispatch('app/setIsLoading', false);
            });
      },
    },
    mounted() {
      dispatch('app/clearErrorMessage');
      dispatch('app/setIsLoading', true);
      dispatch('app/doApplicationCheck', { sApplicationName: 'AccountBill', sTfbId: this.trackingId })
        .then((returnedStatus) => {
          if (returnedStatus === '') {
            if (!this.isLoggedIn) {
              // Invalid login - go back to login screen
              this.$router.push({ name: 'policyholderLogin' });
            } else if (!this.isMemberRole) {
              // Not the member, don't allow changes
              this.$store.dispatch('app/setErrorMessage', insuredAccessOnlyErrorMessage);
              this.$router.push({ name: 'policyholderAccountSummary' });
            } else {
              this.getCountyList();
              this.getStateList();
              this.getMemberInformation();
              dispatch('app/setIsLoading', false);
            }
          } else {
            dispatch('app/setErrorMessage', returnedStatus);
            dispatch('app/setIsLoading', false);
          }
        })
        .catch((error) => {
          dispatch('app/setErrorMessage', error.toString());
          dispatch('app/setIsLoading', false);
        });
    },
  };
</script>
<style lang="scss">
</style>
