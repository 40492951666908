<template>
  <div>
    <!-- Vehicle Information -->
    <v-row no-gutters>
      <v-col class="py-1">
        <h3 class="tfb-title-text-small text-blue text-uppercase">
          Vehicles
        </h3>
      </v-col>
    </v-row>
    <div v-if="vehicles"
         class="py-0 px-2">
      <div v-for="vehicle in vehicles"
                   v-bind:key="vehicle.VIN">
        <v-row>
          <v-col>
            <h4 class="tfb-title-text-smaller font-weight-bold text-uppercase">{{vehicle.Description}}</h4>
            <span class="font-italic"><label class="font-weight-bold">VIN:</label> {{vehicle.VIN}}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5"
                 sm="7"
                 class="py-1">
            <label class="font-weight-bold">Class Code:</label>
          </v-col>
          <v-col cols="7"
                 sm="5"
                 class="py-1 text-right text-sm-left">
            <span v-if="vehicle.ExtendedClassCode">
              {{vehicle.ExtendedClassCode}}
            </span>
            <span v-else>
              {{vehicle.ClassCode}}
            </span>
            <!--<v-icon small
                  @click="showPolicyDefinition('cc',vehicle.ClassCode,vehicle.ClassCode)">mdi-help</v-icon>-->
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="9"
                 sm="7"
                 class="py-1">
            <label class="font-weight-bold">Current Vehicle Premium:</label>
          </v-col>
          <v-col cols="3"
                 sm="5"
                 class="py-1 text-right text-sm-left">
            <span v-if="policyType === 'SM'">{{formatMoney(vehicle.TotalVehiclePremium, 0)}}</span>
            <span v-if="policyType === 'CA'">{{formatMoney(vehicle.TotalCoveragePremium, 0)}}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="7"
                 class="py-1">
            <label class="font-weight-bold">Garaging County:</label>
          </v-col>
          <v-col cols="5"
                 class="py-1 text-right text-sm-left">
            {{vehicle.GaragingCounty}}
          </v-col>
        </v-row>
        <v-expansion-panels accordion
                            flat
                            class="my-3">
          <v-expansion-panel>
            <v-expansion-panel-header class="px-0">
              <v-row no-gutters>
                <v-spacer></v-spacer>
                <v-col cols="12"
                       md="5">
                  <template v-slot:actions>
                    <v-icon>$expand</v-icon>
                  </template>
                  <h3 class="text-blue font-weight-bold">Coverage Information</h3>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-0 background-lightest-gray">
              <v-row>
                <v-col>
                  <h3 class="pt-5 tfb-title-text-smaller text-blue font-weight-bold">
                    Liability
                    <!--<v-icon small
                @click="showPolicyDefinition(policyType, 'Liability','')">mdi-help</v-icon>-->
                  </h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="vehicle.Coverages && vehicle.Coverages.length > 0"
                       class="py-0">
                  <v-row v-for="coverage in vehicle.Coverages"
                         v-bind:key="coverage.id">
                    <v-col cols="12"
                           md="8"
                           class="py-0"
                           v-if="coverage.Limit !== '0'">
                      <span v-if="policyType === 'SM'">{{coverage.CoverageTypeDescription}}</span>
                      <span v-if="policyType === 'CA'">{{coverage.Description}}</span>
                    </v-col>
                    <v-col cols="12"
                           md="4"
                           class="py-0 text-right text-md-left"
                           v-if="policyType === 'SM' && coverage.Limit !== '0'">
                      <span v-for="Description in coverage.LimitDescription"
                            v-bind:key="Description.id">
                        {{Description}}<br />
                      </span>
                    </v-col>
                    <v-col cols="12"
                           md="4"
                           class="py-0 text-right text-md-left"
                           v-if="policyType === 'CA' && coverage.Limit !== '0'">
                      {{coverage.Limit}}
                    </v-col>
                    <v-col cols="12"
                           class="d-block d-lg-none"><hr /></v-col>
                  </v-row>
                </v-col>
                <v-col v-else
                       class="py-0">None</v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h6 class="pt-5 tfb-title-text-smaller text-blue font-weight-bold">Endorsements</h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="vehicle.Endorsements && vehicle.Endorsements.length > 0"
                       class="py-0">
                  <v-row v-for="endorsement in vehicle.Endorsements"
                         v-bind:key="endorsement.Number">
                    <v-col cols="12"
                           sm="2"
                           class="py-0 d-none d-sm-block">
                      {{endorsement.Number}}
                    </v-col>
                    <v-col cols="12"
                           sm="10"
                           class="py-0">
                      {{endorsement.Description}}
                    </v-col>
                    <v-col cols="12"
                           class="d-block d-lg-none"><hr /></v-col>
                  </v-row>
                </v-col>
                <v-col v-else
                       class="py-0">None</v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h6 class="pt-5 tfb-title-text-smaller text-blue font-weight-bold">Discounts</h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="vehicle.Discounts && vehicle.Discounts.length > 0"
                       class="py-0">
                  <v-row v-for="discount in vehicle.Discounts"
                       v-bind:key="discount.DiscountType">
                    <v-col cols="12"
                           class="py-0">
                      {{discount.DiscountType}}
                    </v-col>
                    <v-col cols="12"
                           class="d-block d-lg-none"><hr /></v-col>
                  </v-row>
                </v-col>
                <v-col v-else
                       class="py-0">None</v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h6 class="pt-5 tfb-title-text-smaller text-blue font-weight-bold">Lien Holders</h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="vehicle.HasLossPayee !== 'NO' || (vehicle.LienHolders && vehicle.LienHolders.length !== 0)"
                       class="py-0">
                  <v-row v-for="mortgagee in vehicle.LienHolders"
                       v-bind:key="mortgagee.ObjectSequenceNumber">
                    <v-col cols="12"
                           class="pt-0">
                      <span class="font-weight-bold">{{mortgagee.Name}}</span><br />
                      <span v-if="mortgagee.AdditionalName && mortgagee.AdditionalName.length > 0">
                        <em>{{mortgagee.AdditionalName}}</em><br />
                      </span>
                      <span v-if="mortgagee.LoanNumber && mortgagee.LoanNumber.length > 0">
                        Loan Number: {{mortgagee.LoanNumber}}<br />
                      </span>
                      <span v-if="mortgagee.Loan && mortgagee.Loan.length > 0">
                        Loan Number: {{mortgagee.Loan}}<br />
                      </span>
                      <address>
                        {{mortgagee.LienHolderAddress}}
                      </address>
                    </v-col>
                    <v-col cols="12"
                           class="d-block d-lg-none"><hr /></v-col>
                  </v-row>
                </v-col>
                <v-col v-else
                       class="py-0">None</v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
    <div v-else
         class="py-0 px-2">
      No Vehicles Found
    </div>
  </div>
</template>
<script>
  /* eslint no-unused-vars: 0 */
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'PolicyDetailVehicles',
    props: {
      policyType: {
        type: String,
        required: true,
      },
      vehicles: {
        type: Array,
        required: true,
      },
    },
    mixins: [tfbHelperMixin],
    methods: {
      showPolicyDefinition(policyType, anchorText, newClassCode) {
        // console.log(`TODO: Fetching definition for ${policyType}-${anchorText}-${newClassCode}!`);
        // var definition = { "define": policyType + anchorText, "hasNewClass": ((newClassCode) ? (newClassCode !== '' ? true : false) : false) };
        // localStorageService.set('txfbDefine', definition);
        // $('#phPolicyDefinitions').modal();
      },
    },
  };
</script>
<style lang="scss">

</style>
