<template>
  <div class="row-footer no-print">
    <div class="tfb-footer-banner">
      <jd-power-banner banner-type="footer"></jd-power-banner>
      <img v-bind:src="`${imageRootPath}red-white-star-large.png`"
           alt="Texas Farm Bureau"
           class="star-footer-image"
           v-if="($vuetify.breakpoint.lgAndDown && !showJdPower) || $vuetify.breakpoint.lgAndUp">
    </div>
    <v-container fluid
                 class="tfb-container">
      <v-row class="row-footer-white">
        <v-col cols="12"
               md="4"
               lg="3">
          <img class="protect-image d-none d-md-block d-lg-block d-xl-block"
               alt="Texas Farm Bureau Insurance"
               id="tfb-logo-footer"
               v-bind:src="imageRootPath +'tfb-logo.jpg'" />
        </v-col>
        <v-col cols="12"
               md="8"
               lg="6">
          <v-row>
            <v-col cols="12"
                   sm="4"
                   class="text-center text-sm-left">
              <router-link :to="{ name: 'about'}"
                           hreflang="en-us"
                           title="Our Story"
                           class="footer-link">
                Our Story
              </router-link><br />
              <router-link :to="{ name: 'countyLookup'}"
                           hreflang="en-us"
                           title="Find an Agent"
                           class="footer-link">
                Find an Agent
              </router-link><br />
              <router-link :to="{ name: 'countyListing'}"
                           hreflang="en-us"
                           title="County Office Listing"
                           class="footer-link">
                County Office Listing
              </router-link><br />
              <router-link :to="{ name: 'careers'}"
                           hreflang="en-us"
                           title="Careers"
                           class="footer-link">
                Careers
              </router-link><br />
            </v-col>
            <v-col cols="12" sm="4" class="text-center text-sm-left">
              <router-link :to="{ name: 'claims'}"
                           hreflang="en-us"
                           title="Claims"
                           class="footer-link">
                Claims
              </router-link><br />
              <router-link :to="{ name: 'paymentOptions'}"
                           hreflang="en-us"
                           title="Make a Payment"
                           class="footer-link">
                Make a Payment
              </router-link><br />
              <router-link :to="{ name: 'policyholderLogin'}"
                           :hreflang="'en-us'"
                           title="Find Auto ID Card"
                           class="footer-link">
                Find Auto ID Card
              </router-link><br />
              <router-link :to="{ name: 'contact'}"
                           hreflang="en-us"
                           title="Contact Us"
                           class="footer-link">
                Contact Us
              </router-link><br />
            </v-col>
            <v-col cols="12"
                   sm="4"
                   class="text-center text-sm-left">
              <a href="https://www.farmbureau.bank/"
                 hreflang="en-us"
                 title="Farm Bureau Bank"
                 rel="noopener"
                 target="_blank"
                 class="footer-link">Farm Bureau Bank</a><br />
              <a href="https://texasfarmbureau.org/"
                 hreflang="en-us"
                 title="Texas Farm Bureau"
                 rel="noopener"
                 target="_blank"
                 class="footer-link">Texas Farm Bureau</a><br />
              <a href="https://tfbhp.com"
                 hreflang="en-us"
                 title="TFB Health Plans"
                 rel="noopener"
                 target="_blank"
                 class="footer-link">TFB Health Plans</a><br />
              <a href="https://www.multiplan.com/webcenter/portal/ProviderSearch"
                 hreflang="en-us"
                 title="PHCS"
                 rel="noopener"
                 target="_blank"
                 class="footer-link">PHCS</a>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12"
               lg="3"
               class="align-center">
          <v-btn class="tfb-button-min row-footer-button background-blue"
                 :to="{ name: 'countyLookup'}"
                 :hreflang="'en-us'"
                 :title="'Find an Agent'">Find an Agent</v-btn><br />
          <v-btn class="tfb-button-min row-footer-button background-red"
                 :to="{ name: 'quoteEntry', params: { type: 'auto' }}"
                 :hreflang="'en-us'"
                 :title="'Get a Quote'">Get a Quote</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-footer padless
              class="row-footer-gray"
              align="end"
              justify="center">
      <v-col cols="6"
             sm="3"
             lg="1"
             class="align-right">
        <router-link :to="{ name: 'privacy'}"
                     hreflang="en-us">
          Privacy
        </router-link>
      </v-col>
      <v-col cols="6"
             sm="3"
             lg="1"
             class="align-left text-sm-center align-md-right">
        <router-link :to="{ name: 'requiredNotices'}"
                     hreflang="en-us">
          Required Notices
        </router-link>
      </v-col>
      <v-col cols="6"
             sm="3"
             lg="1"
             class="align-right text-sm-center">
        <router-link :to="{ name: 'termsConditions'}"
                     hreflang="en-us">
          Terms &amp; Conditions
        </router-link>
      </v-col>
      <v-col cols="6"
             sm="3"
             lg="1"
             class="align-left">
        <a href="/style library/sitemap.xml"
           hreflang="en-us">
          Sitemap
        </a>
      </v-col>
      <v-col cols="12"
             lg="5"
             itemprop="copyrightYear">
        Coverage and discounts are subject to qualifications and policy terms and may vary by situation.<br />
        Copyright &copy; {{ year }} Texas Farm Bureau Mutual Insurance Company.
        All rights reserved.
      </v-col>
      <v-col cols="12"
             lg="3">
        <span v-for="link in socialMediaLinks"
              v-bind:key="link.id">
          <a v-bind:href="link.url"
             :title="link.alt"
             hreflang="en-us"
             rel="noopener"
             target="_blank">
            <v-icon class="tfb-social-icon">{{link.icon}}</v-icon>
          </a>
        </span>
        <mobile-app-links></mobile-app-links>
      </v-col>
    </v-footer>
  </div>
</template>
<script>
  import JdPowerBanner from '@/views/layout/components/JDPowerBanner.vue';
  import MobileAppLinks from '@/views/layout/components/MobileAppLinks.vue';

  export default {
    name: 'Footer',
    components: {
      JdPowerBanner,
      MobileAppLinks,
    },
    computed: {
      imageRootPath() {
        return `${process.env.VUE_APP_ROOT_URI}/publishingimages/`;
      },
      year() {
        return new Date().getFullYear();
      },
      showJdPower() {
        if (!this.$store.state.app.showJdPower) {
          this.$store.dispatch('app/setVisitor');
        }
        return this.$store.state.app.showJdPower;
      },
      socialMediaLinks() {
        return [
          {
            text: 'Facebook',
            url: 'https://www.facebook.com/texasfarmbureauinsurance/',
            icon: 'mdi-facebook',
            alt: 'Visit us on Facebook!',
          },
          {
            text: 'Twitter',
            url: 'https://twitter.com/TXFBInsurance',
            icon: 'mdi-twitter',
            alt: 'Visit us on Twitter!',
          },
          {
            text: 'Instagram',
            url: 'https://www.instagram.com/texasheritageforliving/',
            icon: 'mdi-instagram',
            alt: 'Visit us on Instagram!',
          },
          {
            text: 'YouTube',
            url: 'https://www.youtube.com/channel/UCAAiaNAyH5VlC1yC7sL_M3A',
            icon: 'mdi-youtube',
            alt: 'Visit us on YouTube!',
          },
          {
            text: 'Pinterest',
            url: 'https://www.pinterest.com/THFLmagazine/',
            icon: 'mdi-pinterest',
            alt: 'Visit us on Pinterest!',
          },
        ];
      },
    },
  };
</script>
<style lang="scss">

  .row-footer {
    bottom: 0;
    left: 0;
    /*top: -165px;*/
    position: relative;
  }

  .row-footer-white {
    color: $tfb-darker-gray;
    text-align: left;
    font-size: .8em;
    vertical-align: middle;
    padding: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .footer-link,
  .footer-link a:visited,
  .footer-link a {
    color: $tfb-darker-gray !important;
    font-family: 'BS-light', $body-font-family;
    font-size: large;
    font-weight: 300;
    vertical-align: middle;
    text-decoration: none;
    margin: 15px;
  }

    .footer-link a:hover {
      text-decoration: underline;
    }

  @media (min-width: 960px) {
    .footer-link {
      font-size: small;
      margin: 5px;
    }
  }

  #tfb-logo-footer {
    max-height: 85px;
  }

  .row-footer-gray {
    background-color: $tfb-gray !important;
    color: $white !important;
    text-align: center;
    font-size: .75em;
    vertical-align: middle;
    padding: 5px;
  }

    .row-footer-gray a:hover,
    .row-footer-gray a:focus {
      color: $tfb-darkest-blue;
      text-decoration: underline;
    }

    .row-footer-gray a:visited,
    .row-footer-gray a {
      color: $white;
      text-decoration: none !important;
    }

  .tfb-social-icon.theme--light.v-icon {
    color: $white;
    text-decoration: none !important;
    margin: 0px 5px;
  }

    .tfb-social-icon.theme--light.v-icon:hover,
    a:hover.tfb-social-icon.theme--light.v-icon {
      color: $tfb-darkest-blue;
      text-decoration: none !important;
    }

  .row-footer-button {
    margin: 5px;
  }

  .tfb-footer-banner {
    z-index: 998;
    bottom: 0;
    left: 0;
    background-color: $tfb-darker-red;
    width: 100%;
    height: 92px;
    align-content: center;
    text-align: center;
  }

  img.star-footer-image {
    margin-top: -149px;
    width: 175px;
    z-index: 999;
    float: right;
  }
</style>
