<template>
  <div>
    <v-container class="pt-5">
      <v-row>
        <v-col class="text-center">
          <h2 class="tfb-title-text-medium text-darkest-blue">
            How the Claims Process Works
          </h2>
        </v-col>
      </v-row>
      <v-row v-if="$vuetify.breakpoint.mdAndUp">
        <v-col>
          <div class="tfb-stepper">
            <div class="tfb-stepper-header">
              <div class="tfb-stepper-step">
                <span class="tfb-stepper-step-icon">
                  <img v-bind:src="imageRootPath +'icons/claim-step-1.png'"
                       alt="Step One"/>
                </span>
                <div class="tfb-stepper-step-label">
                  Once you report a claim, it will be assigned to an adjuster who will
                  review your insurance policy and coverages to determine what types
                  of damages are covered.
                </div>
              </div>
              <hr role="separator" aria-orientation="horizontal" class="tfb-step-divider" />
              <div class="tfb-stepper-step">
                <span class="tfb-stepper-step-icon">
                  <img v-bind:src="imageRootPath +'icons/claim-step-2.png'"
                       alt="Step Two" />
                </span>
                <div class="tfb-stepper-step-label">
                  We'll contact you to find out what
                  happened in the accident or caused the damage to your home.
                </div>
              </div>
              <hr role="separator" aria-orientation="horizontal" class="tfb-step-divider" />
              <div class="tfb-stepper-step">
                <span class="tfb-stepper-step-icon">
                  <img v-bind:src="imageRootPath +'icons/claim-step-3.png'"
                       alt="Step Three" />
                </span>
                <div class="tfb-stepper-step-label">
                  We'll inspect your vehicle or home and
                  provide you an estimate for the damages.
                </div>
              </div>
              <hr role="separator" aria-orientation="horizontal" class="tfb-step-divider" />
              <div class="tfb-stepper-step">
                <span class="tfb-stepper-step-icon">
                  <img v-bind:src="imageRootPath +'icons/claim-step-4.png'"
                       alt="Step Four" />
                </span>
                <div class="tfb-stepper-step-label">
                  We'll pay you for your damages less
                  your deductible and any applicable depreciation after we have
                  inspected your property.
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="$vuetify.breakpoint.smAndDown">
        <v-col>
          <div class="tfb-stepper tfb-stepper-vertical">
            <div class="tfb-stepper-step">
              <span class="tfb-stepper-step-icon">
                <img v-bind:src="imageRootPath +'icons/claim-step-1.png'"
                     alt="Step One" />
              </span>
              <div class="tfb-stepper-step-label">
                Once you report a claim, it will be assigned to an adjuster who will
                review your insurance policy and coverages to determine what types of
                damages are covered.
              </div>
            </div>
            <div class="tfb-stepper-content">
              <div class="tfb-stepper-wrapper"><br /></div>
            </div>
            <div class="tfb-stepper-step">
              <span class="tfb-stepper-step-icon">
                <img v-bind:src="imageRootPath +'icons/claim-step-2.png'"
                     alt="Step Two" />
              </span>
              <div class="tfb-stepper-step-label">
                We'll contact you to find out what happened in the accident or caused
                the damage to your home.
              </div>
            </div>
            <div class="tfb-stepper-content">
              <div class="tfb-stepper-wrapper"><br /></div>
            </div>
            <div class="tfb-stepper-step">
              <span class="tfb-stepper-step-icon">
                <img v-bind:src="imageRootPath +'icons/claim-step-3.png'"
                     alt="Step Three" />
              </span>
              <div class="tfb-stepper-step-label">
                We'll inspect your vehicle or home and provide you an estimate for
                the damages.
              </div>
            </div>
            <div class="tfb-stepper-content">
              <div class="tfb-stepper-wrapper"><br /></div>
            </div>
            <div class="tfb-stepper-step">
              <span class="tfb-stepper-step-icon">
                <img v-bind:src="imageRootPath +'icons/claim-step-4.png'"
                     alt="Step Four" />
              </span>
              <div class="tfb-stepper-step-label">
                We'll pay you for your damages less your deductible and any applicable
                depreciation after we have inspected your property.
              </div>
            </div>
            <div class="tfb-stepper-content">
              <div class="tfb-stepper-wrapper"><br /></div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';

export default {
    name: 'ClaimsProcess',
    mixins: [tfbHelperMixin],
};
</script>

<style lang="scss">
  .tfb-stepper {
    background: #FFFFFF;
    border-radius: 0px;
    overflow: hidden;
    position: relative;
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%);
  }

  .tfb-stepper-header {
    height: auto;
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  }

  .tfb-stepper .tfb-stepper-header .tfb-step-divider {
    border-color: rgba(0, 0, 0, 0.12);
  }

  .tfb-stepper-header .tfb-step-divider {
    margin: 50px -47px 0;
    align-self: flex-start;
  }

  .tfb-stepper-step {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-basis: 175px;
    display: flex;
    padding: 24px;
    position: relative;
  }

  .tfb-stepper-step-icon {
    margin-bottom: 11px;
    margin-left: 0;
    margin-right: 0;
    align-items: center;
    border-radius: 50%;
    display: inline-flex;
    font-size: 0.75rem;
    justify-content: center;
    height: 50px;
    min-width: 50px;
    width: 50px;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    /*background-color: #008ce1 !important;
    border-color: #008ce1 !important;*/
    color: #ffffff !important;
  }

  .tfb-stepper-step-label {
    color: #000000;
    text-align: left;
    transition: 0.3s cubic-bezier(0.4, 0, 0.6, 1);
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    line-height: 1;
  }

  .tfb-stepper:not(.tfb-stepper-vertical) .tfb-stepper-step-label {
    text-align: center;
    min-width: 200px;
  }

  .tfb-step-divider {
    border-color: rgba(0, 0, 0, 0.12);
    display: block;
    flex: 1 1 0px;
    max-width: 100%;
    height: 0px;
    max-height: 0px;
    border: solid;
    border-width: thin 0 0 0;
    transition: inherit;
  }

  .tfb-stepper-vertical {
    padding-bottom: 36px;
  }

    .tfb-stepper-vertical .tfb-stepper-step {
      padding: 24px 24px 16px;
      flex-direction: row;
    }

    .tfb-stepper-vertical .tfb-stepper-step-label {
      margin-top: -10px;
    }

    .tfb-stepper-vertical .tfb-stepper-step-icon {
      margin-right: 12px;
    }

    .tfb-stepper-vertical .tfb-stepper-content:not(:last-child) {
      border-left: 1px solid rgba(0, 0, 0, 0.12);
    }

  .tfb-stepper-content {
    margin: -8px -36px -16px 48px;
    padding: 16px 60px 16px 23px;
    width: auto;
    top: 0;
    flex: 1 0 auto;
  }

  .tfb-stepper-wrapper {
    overflow: hidden;
    transition: none;
    height: 0px;
  }

  @media only screen and (max-width: 959px) {
    .tfb-stepper:not(.tfb-stepper--vertical) .tfb-stepper-step-icon {
      margin-left: 0;
      margin-right: 0;
    }

    .tfb-stepper:not(.tfb-stepper-vertical) .tfb-stepper-step-label {
      display: none;
    }

    .tfb-stepper-vertical .tfb-stepper-step-label {
      margin-left: 10px;
    }
  }
</style>
