import Vue from 'vue';
import { make } from 'vuex-pathify';
import axios from 'axios';
import { genericErrorMessage, collisionDeductibleRentalErrorMessage, comprehensiveDeductibleRentalErrorMessage } from '../../mixins/errorMessages';
import helpers from '../../mixins/helpers';
/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const autoQuoteServiceUrl = `${process.env.VUE_APP_WEB_URI}/services/TFBIC.Services.RateQuote.RESTService`;

const quoteTokenName = process.env.VUE_APP_WEB_ENV !== 'PROD' ? `tfb_${process.env.VUE_APP_WEB_ENV}_quote_token` : 'tfb_quote_token';

const config = {
  withCredentials: true,
  headers: { Accept: 'application/json; odata=verbose', 'Content-Type': 'application/json; odata=verbose' },
};

function getDefaultState() {
  return {
    agreementNumber: '',
    subAgreementNumber: '',
    effectiveDate: null,
    quote: null,
    timeout: false,
    contact: null,
    email: null,
    hasVehicle: false,
    hasDriver: false,
    hasCoverage: false,
    maxPastEffectiveDayLimit: 30,
    maxFutureEffectiveDayLimit: 30,
  };
}

/**
 * quoteTransform
 * @param {object} data - JSON object of quote data
 * @returns {object} - JSON object of formatted quote data
 */
function quoteTransform(quoteData, context, tfbId) {
  try {
    const returnQuoteData = quoteData;

    if (quoteData) {
      if (quoteData.ValidationToken && quoteData.ValidationToken !== '') {
        Vue.$cookies.set(quoteTokenName, quoteData.ValidationToken, 0);
        returnQuoteData.ValidationToken = '';
      }


      if (quoteData.AgreementNumber && quoteData.AgreementNumber !== '') {
        context.commit('SET_AGREEMENT_NUMBER', quoteData.AgreementNumber);
      }

      if (quoteData.SubAgreementNumber && quoteData.SubAgreementNumber !== '') {
        context.commit('SET_SUB_AGREEMENT_NUMBER', quoteData.SubAgreementNumber);
      }

      if (quoteData.Quote && quoteData.Quote.EffectiveDate) {
        context.commit('SET_EFFECTIVE_DATE', quoteData.Quote.EffectiveDate);
       }

      if (returnQuoteData.Quote) {
        if (returnQuoteData.GaragingLocations) {
          for (let g = 0; g < returnQuoteData.GaragingLocations.length; g += 1) {
            const foundGaragingLocation = returnQuoteData.GaragingLocations[g];

            if (foundGaragingLocation) {
              if (foundGaragingLocation.ZipCode) {
                foundGaragingLocation.ZipCode = Number(foundGaragingLocation.ZipCode);
              }
              if (foundGaragingLocation.LocationCounty) {
                foundGaragingLocation.LocationCounty = helpers.methods.prefixPadding(foundGaragingLocation.LocationCounty, 3, '0');
              }
            }
          }
        }
      }

      if (returnQuoteData.Operators) {
        for (let o = 0; o < returnQuoteData.Operators.length; o += 1) {
          const foundOperator = returnQuoteData.Operators[o];
          if (foundOperator) {
            foundOperator.AgeReceivedLicense = Number(foundOperator.AgeReceivedLicense);

            if (foundOperator.OperatorStatusCode === undefined || foundOperator.OperatorStatusCode === 'A') {
              foundOperator.OperatorStatusIndicator = 'Y';
            } else if (foundOperator.OperatorStatusCode !== null && foundOperator.OperatorStatusCode !== '') {
              foundOperator.OperatorStatusIndicator = 'N';
            }

            if (foundOperator.Accidents && foundOperator.Accidents.length > 0) {
              foundOperator.HasAccident = 'Y';
              for (let a = 0; a < foundOperator.Accidents.length; a += 1) {
                const foundAccident = foundOperator.Accidents[a];

                foundAccident.Id = a;
                foundAccident.AccidentTypeCode = Number(foundAccident.AccidentTypeCode);
                foundAccident.AtFaultIndicator = (Boolean(foundAccident.AtFaultIndicator));
              }
            } else {
              foundOperator.HasAccident = 'N';
              foundOperator.Accidents = [];
            }

            if (foundOperator.Violations && foundOperator.Violations.length > 0) {
              foundOperator.HasViolation = 'Y';
              for (let v = 0; v < foundOperator.Violations.length; v += 1) {
                const foundViolation = foundOperator.Violations[v];

                foundViolation.Id = v;
                foundViolation.ViolationTypeCode = Number(foundViolation.ViolationTypeCode);
              }
            } else {
              foundOperator.HasViolation = 'N';
              foundOperator.Violations = [];
            }
          }
        }
      }

      if (returnQuoteData.Vehicles) {
        for (let v = 0; v < returnQuoteData.Vehicles.length; v += 1) {
          const foundVehicle = returnQuoteData.Vehicles[v];

          if (foundVehicle) {
            foundVehicle.AnnualMileage = Number(foundVehicle.AnnualMileage);
            foundVehicle.CostNew = Number(foundVehicle.CostNew);
            foundVehicle.DailyMileage = Number(foundVehicle.DailyMileage);
            foundVehicle.NumberOfOwnedAcres = Number(foundVehicle.NumberOfOwnedAcres);
            foundVehicle.NumberOfRentedAcres = Number(foundVehicle.NumberOfRentedAcres);
            foundVehicle.Make = foundVehicle.Make.replace('/', '_');

            if (foundVehicle.GaragingZipCode !== null && foundVehicle.GaragingZipCode !== '' && foundVehicle.GaragingZipCode !== '0') {
              foundVehicle.GaragingZipCode = Number(foundVehicle.GaragingZipCode);
            }

            if (helpers.methods.containsString(foundVehicle.VIN, '&') || helpers.methods.containsString(foundVehicle.VIN, '&amp;') || helpers.methods.containsString(foundVehicle.VIN, '&amp;amp;')) {
              // Empty out the VIN
              foundVehicle.VIN = '';
            }
          }
        }
      }
    }

    return returnQuoteData;
  } catch (error) {
    context.dispatch('app/logToULS', {
      logLevel: 'Error', logMessage: error, fileName: 'quote.js', methodName: 'quoteTransform', trackerId: tfbId,
    }, { root: true });
    return quoteData;
  }
}

// state
const state = getDefaultState();

// mutations
const mutations = {
  ...make.mutations(state),
  CLEAR_QUOTE(state) {
    sessionStorage.removeItem('tfb_quote');
    Object.assign(state, getDefaultState());
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_AGREEMENT_NUMBER(state, value) {
    state.agreementNumber = value;
  },
  SET_QUOTE(state, value) {
    const parsed = JSON.stringify(value);
    sessionStorage.setItem('tfb_quote', parsed);

    state.quote = value;
  },
  SET_QUOTE_EMAIL_ADDRESS(state, value) {
    const tfbQuote = sessionStorage.getItem('tfb_quote');
    let tfbParsedQuote = JSON.parse(tfbQuote);

    if (!state.quote || state.quote === null || state.quote.length === 0) {
      state.quote = tfbParsedQuote;
    }

    tfbParsedQuote.Quote.EmailAddress = value;
    tfbParsedQuote = JSON.stringify(tfbParsedQuote);
    sessionStorage.setItem('tfb_quote', tfbParsedQuote);

    state.quote.Quote.EmailAddress = value;
  },
  SET_TIMEOUT(state, value) {
    state.timeout = value;
  },
  SET_SUB_AGREEMENT_NUMBER(state, value) {
    state.subAgreementNumber = value;
  },
  SET_EFFECTIVE_DATE(state, value) {
    state.effectiveDate = value;
  },
  SET_EMAIL(state, value) {
    state.email = value;
  },
  SET_CONTACT(state, value) {
    state.contact = value;
  },
  SET_HAS_VEHICLE(state, value) {
    state.hasVehicle = value;
  },
  SET_HAS_DRIVER(state, value) {
    state.hasDriver = value;
  },
  SET_HAS_COVERAGE(state, value) {
    state.hasCoverage = value;
  },
  SET_QUOTE_COMPLETE(state, value) {
    state.quote.CompletedQuoteIndicator = value;
  },
};

// getters
const getters = {
  ...make.getters(state),
  agreementNumber(state) {
    if (!state.agreementNumber || state.agreementNumber === null || state.agreementNumber === '') {
      const tfbQuote = sessionStorage.getItem('tfb_quote');
      const tfbParsedQuote = JSON.parse(tfbQuote);

      if (tfbParsedQuote && tfbParsedQuote.Quote) {
        state.agreementNumber = tfbParsedQuote.Quote.AgreementNumber;
      }
    }

    return state.agreementNumber;
  },
  quote(state) {
    if (!state.quote || state.quote === null || state.quote.length === 0) {
      const tfbQuote = sessionStorage.getItem('tfb_quote');
      const tfbParsedQuote = JSON.parse(tfbQuote);

      state.quote = tfbParsedQuote;
    }

    return state.quote;
  },
  subAgreementNumber(state) {
    if (!state.subAgreementNumber || state.subAgreementNumber === null || state.subAgreementNumber === '') {
      const tfbQuote = sessionStorage.getItem('tfb_quote');
      const tfbParsedQuote = JSON.parse(tfbQuote);

      if (tfbParsedQuote && tfbParsedQuote.Quote) {
        state.subAgreementNumber = tfbParsedQuote.Quote.SubAgreementNumber;
      }
    }

    return state.subAgreementNumber;
  },
  effectiveDate(state) {
    if (!state.effectiveDate || state.effectiveDate === null || state.effectiveDate === '') {
      const tfbQuote = sessionStorage.getItem('tfb_quote');
      const tfbParsedQuote = JSON.parse(tfbQuote);

      if (tfbParsedQuote && tfbParsedQuote.Quote) {
        state.effectiveDate = tfbParsedQuote.Quote.EffectiveDate;
      }
    }

    return state.effectiveDate;
  },
};

// actions
const actions = {
  addNewDriver(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'quotes.js', methodName: 'addNewDriver-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;

      const driverAccidents = [];
      const driverViolations = [];

      // Build the Accidents object
      if (sRequest.sDriver.Accidents && sRequest.sDriver.Accidents.length > 0) {
        for (let a = 0; a < sRequest.sDriver.Accidents.length; a += 1) {
          const foundAccident = sRequest.sDriver.Accidents[a];

          driverAccidents.push({
            AccidentAmount: foundAccident.AccidentAmount || '',
            AccidentDate: foundAccident.AccidentDate ? helpers.methods.parseDate(foundAccident.AccidentDate) : '',
            AccidentTypeCode: foundAccident.AccidentTypeCode || '',
            AtFaultIndicator: foundAccident.AtFaultIndicator || false,
          });
        }
      }

      // Build the Violations object
      if (sRequest.sDriver.Violations && sRequest.sDriver.Violations.length > 0) {
        for (let v = 0; v < sRequest.sDriver.Violations.length; v += 1) {
          const foundViolation = sRequest.sDriver.Violations[v];

          driverViolations.push({
            ViolationDate: foundViolation.ViolationDate ? helpers.methods.parseDate(foundViolation.ViolationDate) : '',
            ViolationTypeCode: foundViolation.ViolationTypeCode || '',
          });
        }
      }

      const request = {
        AcademicAchievement: sRequest.sDriver.AcademicAchievement || 'N',
        Accidents: driverAccidents,
        AgreementNumber: sRequest.sQuote.AgreementNumber,
        ClaimCount: sRequest.sQuote.ClaimCount || '0',
        CurrentTFBPolicyholder: sRequest.sQuote.HasCurrentTFBPolicy || 'N',
        DateOfBirth: sRequest.sDriver.DateOfBirth ? helpers.methods.parseDate(sRequest.sDriver.DateOfBirth) : '',
        DefensiveDriving: sRequest.sDriver.DefensiveDriving || 'N',
        DriverTraining: sRequest.sDriver.DriverTraining || 'N',
        DriveNSaveEnrollment: sRequest.sDriver.DriveNSaveEnrollment || 'N',
        FirstName: sRequest.sDriver.FirstName,
        Gender: sRequest.sDriver.Gender || '',
        IncludeAsDI: sRequest.sDriver.IncludeAsDI || 'N',
        LastName: sRequest.sDriver.LastName,
        LicenseNumber: sRequest.sDriver.DriversLicenseNumber || '',
        LicenseState: sRequest.sDriver.DriversLicenseState || '',
        MaritalStatus: sRequest.sDriver.MaritalStatusCode || '',
        MemberNumber: sRequest.sDriver.MemberNumber || '',
        Occupation: sRequest.sDriver.OccupationCode || '25',
        PrimaryVehicle: sRequest.sDriver.VehicleNumber || '0',
        Relationship: sRequest.sDriver.RelationshipToInsuredCode || '13',
        Status: sRequest.sDriver.OperatorStatusCode || 'A',
        SubAgreementNumber: sRequest.sQuote.SubAgreementNumber,
        Violations: driverViolations,
      };
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: JSON.stringify(request), fileName: 'quotes.js', methodName: 'addNewDriver-SendRequest', trackerId: sRequest.sTfbId,
      }, { root: true });

      axios.post(`${autoQuoteServiceUrl}/driver/create`, request, config)
        .then((driverResult) => {
          if (driverResult && driverResult.data) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(driverResult.data), fileName: 'quotes.js', methodName: 'addNewDriver-Result', trackerId: sRequest.sTfbId,
            }, { root: true });
            const updateDriver = quoteTransform(driverResult.data, context, sRequest.sTfbId);
            context.commit('SET_QUOTE', updateDriver);
            if (driverResult.data.ErrorMessage === null || driverResult.data.ErrorMessage === '') {
              resolve(updateDriver);
            } else {
              reject(new Error(driverResult.data.ErrorMessage));
            }
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'addNewDriver-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (error.toString().indexOf('status code 401') >= 0 || error.toString().indexOf('status code 405') >= 0 || error.toString().indexOf('status code 500') >= 0) {
            reject(new Error(genericErrorMessage));
          } else {
            reject(error);
          }
        });
    });
  },
  addNewQuote(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'quotes.js', methodName: 'addNewQuote-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;

      const request = {
        Address: sRequest.sLocation.Address1,
        Address2: sRequest.sLocation.Address2,
        City: sRequest.sLocation.City,
        CountyCode: Number(sRequest.sLocation.LocationCounty),
        DateOfBirth: sRequest.sDriver.DateOfBirth,
        EffectiveDate: sRequest.sQuote.EffectiveDate,
        EmailAddress: sRequest.sQuote.EmailAddress,
        FirstName: sRequest.sDriver.FirstName,
        Gender: sRequest.sDriver.Gender,
        LastName: sRequest.sDriver.LastName,
        LicenseNumber: sRequest.sDriver.DriversLicenseNumber || '',
        LicenseState: sRequest.sDriver.DriversLicenseState || '',
        Password: sRequest.sPassword,
        PhoneNumber: sRequest.sQuote.PhoneNumber,
        QuoteType: (sRequest.sQuote.OwnershipCode || 'I'),
        State: 'TX',
        TrackingId: sRequest.sTfbId,
        Zip: Number(sRequest.sLocation.ZipCode),
      };
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'quotes.js', methodName: 'addNewQuote-SendRequest', trackerId: sRequest.sTfbId,
      }, { root: true });

      axios.post(`${autoQuoteServiceUrl}/quote/create`, request, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(quoteResult.data), fileName: 'quotes.js', methodName: 'addNewQuote-Result', trackerId: sRequest.sTfbId,
            }, { root: true });
            const newQuote = quoteTransform(quoteResult.data, context, sRequest.sTfbId);
            context.commit('SET_QUOTE', newQuote);
            context.commit('SET_AGREEMENT_NUMBER', newQuote.AgreementNumber);
            context.commit('SET_SUB_AGREEMENT_NUMBER', newQuote.SubAgreementNumber);
            if (quoteResult.data.ErrorMessage === null || quoteResult.data.ErrorMessage === '') {
              resolve(newQuote);
            } else {
              reject(new Error(quoteResult.data.ErrorMessage));
            }
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'addNewQuote-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  addNewVehicle(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'quotes.js', methodName: 'addNewVehicle-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;

      const request = {
        AgreementNumber: sRequest.sQuote.AgreementNumber,
        AnnualMileage: (sRequest.sVehicle.AnnualMileage || '0'),
        CostNew: (sRequest.sVehicle.CostNew || '0'),
        DailyMileage: (sRequest.sVehicle.DailyMileage || '0'),
        DaysPerWeekDriven: (sRequest.sVehicle.DaysPerWeekDriven || '0'),
        EngineSize: (sRequest.sVehicle.EngineSize || ''),
        GaragedOnFarm: (sRequest.sVehicle.GaragedOnFarm || 'N'),
        GaragingLocation: {
          LocationSequenceNumber: (sRequest.sVehicle.GaragingLocationSequenceNumber || '1'),
          Address1: (sRequest.sVehicle.GaragingLocation.Address1 || ''),
          Address2: (sRequest.sVehicle.GaragingLocation.Address2 || ''),
          City: (sRequest.sVehicle.GaragingLocation.City || ''),
          State: 'TX',
          LocationCounty: (sRequest.sVehicle.GaragingLocation.LocationCounty || ''),
          ZipCode: (sRequest.sVehicle.GaragingLocation.ZipCode || ''),
        },
        Make: (sRequest.sVehicle.Make ? sRequest.sVehicle.Make.replace('_', '/') : ''),
        Model: (sRequest.sVehicle.Model || ''),
        NumberOfOwnedAcres: (sRequest.sVehicle.NumberOfOwnedAcres || '0'),
        NumberOfRentedAcres: (sRequest.sVehicle.NumberOfRentedAcres || '0'),
        PassiveRestraintCode: (sRequest.sVehicle.PassiveRestraintCode || ''),
        PrimaryVehicleUse: (sRequest.sVehicle.PrimaryVehicleUse || ''),
        PrincipalOperator: (sRequest.sVehicle.PrincipalOperator || ''),
        SubAgreementNumber: sRequest.sQuote.SubAgreementNumber,
        TitledTo: (sRequest.sVehicle.TitledTo || ''),
        VIN: (sRequest.sVehicle.VIN || ''),
        VehicleBodyStyle: (sRequest.sVehicle.VehicleBodyStyle || ''),
        Year: (sRequest.sVehicle.Year || ''),
      };
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'quotes.js', methodName: 'addNewVehicle-SendRequest', trackerId: sRequest.sTfbId,
      }, { root: true });

      axios.post(`${autoQuoteServiceUrl}/vehicle/create`, request, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(quoteResult.data), fileName: 'quotes.js', methodName: 'addNewVehicle-Result', trackerId: sRequest.sTfbId,
            }, { root: true });
            const newQuote = quoteTransform(quoteResult.data, context, sRequest.sTfbId);
            context.commit('SET_QUOTE', newQuote);
            if (quoteResult.data.ErrorMessage === null || quoteResult.data.ErrorMessage === '') {
              resolve(newQuote);
            } else {
              reject(new Error(quoteResult.data.ErrorMessage));
            }
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'addNewVehicle-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  clearToken() {
    Vue.$cookies.remove(quoteTokenName);
  },
  deleteDriver(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'quotes.js', methodName: 'deleteDriver-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;

      axios.delete(`${autoQuoteServiceUrl}/driver/delete/${sRequest.sAgreementNumber}/${sRequest.sSubAgreementNumber}/${sRequest.sVehicleSequenceNumber}`, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(quoteResult.data), fileName: 'quotes.js', methodName: 'deleteDriver-Result', trackerId: sRequest.sTfbId,
            }, { root: true });
            const newQuote = quoteTransform(quoteResult.data, context, sRequest.sTfbId);
            context.commit('SET_QUOTE', newQuote);
            if (quoteResult.data.ErrorMessage === null || quoteResult.data.ErrorMessage === '') {
              resolve(newQuote);
            } else {
              reject(new Error(quoteResult.data.ErrorMessage));
            }
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'deleteDriver-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  deleteVehicle(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'quotes.js', methodName: 'deleteVehicle-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;

      axios.delete(`${autoQuoteServiceUrl}/vehicle/delete/${sRequest.sAgreementNumber}/${sRequest.sSubAgreementNumber}/${sRequest.sVehicleSequenceNumber}`, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(quoteResult.data), fileName: 'quotes.js', methodName: 'deleteVehicle-Result', trackerId: sRequest.sTfbId,
            }, { root: true });
            const newQuote = quoteTransform(quoteResult.data, context, sRequest.sTfbId);
            context.commit('SET_QUOTE', newQuote);
            if (quoteResult.data.ErrorMessage === null || quoteResult.data.ErrorMessage === '') {
              resolve(newQuote);
            } else {
              reject(new Error(quoteResult.data.ErrorMessage));
            }
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'deleteVehicle-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getAccidentTypes(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${autoQuoteServiceUrl}/lookup/accident/types`, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            resolve(quoteResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'getAccidentTypes-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getBodilyInjuryLimits(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${autoQuoteServiceUrl}/limits/bodily-injury/${sRequest.sEffectiveDate}`, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            resolve(quoteResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'getBodilyInjuryLimits-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getCollisionDeductibles(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${autoQuoteServiceUrl}/deductibles/collision/${sRequest.sEffectiveDate}`, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            resolve(quoteResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'getCollisionDeductibles-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getComprehensiveDeductibles(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${autoQuoteServiceUrl}/deductibles/comprehensive/${sRequest.sEffectiveDate}`, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            resolve(quoteResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'getComprehensiveDeductibles-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getCountyList(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${autoQuoteServiceUrl}/lookup/counties`, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            resolve(quoteResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'getCountyList-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getExistingQuote(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios.post(`${autoQuoteServiceUrl}/lookup/login`, {
        EmailAddress: sRequest.sEmailAddress,
        Password: sRequest.sPassword,
      }, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(quoteResult.data), fileName: 'quotes.js', methodName: 'getExistingQuote-Result', trackerId: sRequest.sTfbId,
            }, { root: true });

            if (quoteResult.data.ErrorMessage === null || quoteResult.data.ErrorMessage === '') {
              const existingQuote = quoteTransform(quoteResult.data, context, sRequest.sTfbId);
              context.commit('SET_QUOTE', existingQuote);
              resolve(existingQuote);
            } else {
              reject(new Error(quoteResult.data.ErrorMessage));
            }
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'getExistingQuote-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getLimitsByLevel(context, sRequest) {
    // return [
    //  { DisplayText: 'UMPD', Value: '50' },
    //  { DisplayText: 'UMBI', Value: '50/100' },
    //  { DisplayText: 'Rental', Value: '35/1050' },
    //  { DisplayText: 'Towing', Value: '120' },
    //  { DisplayText: 'CollDeduct', Value: '500' },
    //  { DisplayText: 'OTCDeduct', Value: '500' },
    //  { DisplayText: 'PIPMED', Value: '2500PIP' },
    //  { DisplayText: 'PD', Value: '50' },
    //  { DisplayText: 'BI', Value: '50/100' },
    //  { DisplayText: 'ADI', Value: '3' }];
     const coverageLevel = sRequest.sCoverageLevel ? sRequest.sCoverageLevel : 'Default';
     const effectiveDate = sRequest.sEffectiveDate ? helpers.methods.parseDate(sRequest.sEffectiveDate) : helpers.methods.parseDate(new Date().toISOString());
     return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${autoQuoteServiceUrl}/limits/${coverageLevel}/${effectiveDate}`, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            resolve(quoteResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'getLimitsByLevel-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
     });
  },
  setMaxFutureEffectiveDayLimit(context) {
    context.dispatch('app/getConfiguration', { sSection: 'Limit', sTitle: 'MaxFutureEffectiveDayLimit' }, { root: true })
      .then((response) => {
        if (response && response !== '') {
          context.commit('SET_MAX_FUTURE_EFFECTIVE_DAY_LIMIT', response);
        }
      });
  },
  setMaxPastEffectiveDayLimit(context) {
    context.dispatch('app/getConfiguration', { sSection: 'Limit', sTitle: 'MaxPastEffectiveDayLimit' }, { root: true })
      .then((response) => {
        if (response && response !== '') {
          context.commit('SET_MAX_PAST_EFFECTIVE_DAY_LIMIT', response);
        }
      });
  },
  getMinMaxAmount(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'quotes.js', methodName: 'getMinMaxAmount-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${autoQuoteServiceUrl}/premium/min-max/${sRequest.sAgreementNumber}/${sRequest.sSubAgreementNumber}/${sRequest.sCoverageLevel}`, config)
        .then((quoteResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(quoteResult), fileName: 'quotes.js', methodName: 'getMinMaxAmount-Result', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (quoteResult && quoteResult.data) {
            resolve(quoteResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'getMinMaxAmount-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getOccupations(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${autoQuoteServiceUrl}/lookup/occupations`, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            resolve(quoteResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'getOccupations-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getPIPMEDLimits(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${autoQuoteServiceUrl}/limits/pip-med/${sRequest.sEffectiveDate}`, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            resolve(quoteResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'getPIPMEDLimits-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getPrimaryUses(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${autoQuoteServiceUrl}/vehicle/uses`, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            resolve(quoteResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'getPrimaryUses-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getPropertyDamageLimits(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${autoQuoteServiceUrl}/limits/property-damage/${sRequest.sEffectiveDate}`, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            resolve(quoteResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'getPropertyDamageLimits-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getRateQuote(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${autoQuoteServiceUrl}/premium/${sRequest.sAgreementNumber}/${sRequest.sSubAgreementNumber}`, config)
        .then((quoteResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(quoteResult), fileName: 'quotes.js', methodName: 'getRateQuote-Result', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (quoteResult && quoteResult.data) {
            if (quoteResult.data.ErrorMessage && quoteResult.data.ErrorMessage !== '') {
              reject(new Error(quoteResult.data.ErrorMessage));
            } else {
              context.commit('SET_QUOTE_COMPLETE', 'Y');
              resolve(quoteResult.data);
            }
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'getRateQuote-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getFakeRateQuote() {
    return new Promise((resolve) => {
      resolve({
        ErrorMessage: '',
        TotalPremium: '5236',
        TotalQuote: '5238',
        MonthlyAmountDue: '872.67',
        MonthlyServiceDue: '5.00',
        TheftFee: '2.00',
        ADIPremium: '2',
        SR22FilingFee: '',
        Discounts: [
          'Claims Free',
          'Companion Policy',
        ],
        VehiclePremiums: [
          {
            VehicleSequenceNumber: '1',
            VehicleTotalPremium: '2610',
            BodilyInjuryPremium: '1234',
            PropertyDamagePremium: '234',
            UMBodilyDamagePremium: '2345',
            UMPropertyDamagePremium: '345',
            MedPIPPremium: '12',
            ComprehensivePremium: '150',
            CollisionPremium: '160',
            RentalPremium: '10',
            RoadsidePremium: '12',
          },
          {
            VehicleSequenceNumber: '2',
            VehicleTotalPremium: '2626',
            BodilyInjuryPremium: '2345',
            PropertyDamagePremium: '345',
            UMBodilyDamagePremium: '3456',
            UMPropertyDamagePremium: '456',
            MedPIPPremium: '14',
            ComprehensivePremium: '180',
            CollisionPremium: '170',
            RentalPremium: '',
            RoadsidePremium: '10',
          },
        ],
      });
    });
  },
  getRelationships(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${autoQuoteServiceUrl}/lookup/relationships`, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            resolve(quoteResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'getRelationships-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getRentalLimits(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${autoQuoteServiceUrl}/limits/rental/${sRequest.sEffectiveDate}`, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            resolve(quoteResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'getRentalLimits-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getSafetyOptions(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${autoQuoteServiceUrl}/vehicle/safety-options`, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            resolve(quoteResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'getSafetyOptions-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getRoadsideAssistanceLimits(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${autoQuoteServiceUrl}/limits/roadside-assistance/${sRequest.sEffectiveDate}`, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            resolve(quoteResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'getRoadsideAssistanceLimits-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getUninsuredBodilyInjuryLimits(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${autoQuoteServiceUrl}/limits/um-bodily-injury/${sRequest.sEffectiveDate}`, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            resolve(quoteResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'getUninsuredBodilyInjuryLimits-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getUninsuredPropertyDamageLimits(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${autoQuoteServiceUrl}/limits/um-property-damage/${sRequest.sEffectiveDate}`, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            resolve(quoteResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'getUninsuredPropertyDamageLimits-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getVehicleMakesByYear(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;

      const request = {
        AgreementNumber: sRequest.sAgreementNumber,
        SubAgreementNumber: sRequest.sSubAgreementNumber,
        VehicleYear: sRequest.sVehicleYear,
      };

      axios.post(`${autoQuoteServiceUrl}/vehicle/makes`, request, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            resolve(quoteResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'getVehicleMakesByYear-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getVehicleModelsByMakeYear(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;

      const request = {
        AgreementNumber: sRequest.sAgreementNumber,
        SubAgreementNumber: sRequest.sSubAgreementNumber,
        VehicleYear: sRequest.sVehicleYear,
        VehicleMake: sRequest.sVehicleMake,
      };

      axios.post(`${autoQuoteServiceUrl}/vehicle/models`, request, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            resolve(quoteResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'getVehicleModelsByMakeYear-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getVehicleBodyStylesByYearMakeModel(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;

      const request = {
        AgreementNumber: sRequest.sAgreementNumber,
        SubAgreementNumber: sRequest.sSubAgreementNumber,
        VehicleYear: sRequest.sVehicleYear,
        VehicleMake: sRequest.sVehicleMake,
        VehicleModel: sRequest.sVehicleModel,
      };

      axios.post(`${autoQuoteServiceUrl}/vehicle/body-styles`, request, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            resolve(quoteResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'getVehicleBodyStylesByYearMakeModel-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getVehicleYears(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;

      const request = {
        AgreementNumber: sRequest.sAgreementNumber,
        SubAgreementNumber: sRequest.sSubAgreementNumber,
      };

      axios.post(`${autoQuoteServiceUrl}/vehicle/years`, request, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            resolve(quoteResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'getVehicleYears-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getViolationTypes(context, sRequest) {
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${autoQuoteServiceUrl}/lookup/violation/types`, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            resolve(quoteResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'getViolationTypes-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getWebQuoteData(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'quotes.js', methodName: 'getWebQuoteData-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    if (state.quote && state.quote !== null) {
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: JSON.stringify(state.quote), fileName: 'quotes.js', methodName: 'getWebQuoteData-Stored', trackerId: sRequest.sTfbId,
      }, { root: true });
      return state.quote;
    }

    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${autoQuoteServiceUrl}/quote/${sRequest.sAgreementNumber}/${sRequest.sSubAgreementNumber}`, config)
        .then((quoteResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(quoteResult), fileName: 'quotes.js', methodName: 'getWebQuoteData-Result', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (quoteResult && quoteResult.data) {
            if (quoteResult.data.ErrorMessage === null || quoteResult.data.ErrorMessage === '') {
              const quoteData = quoteTransform(quoteResult.data, context, sRequest.sTfbId);
              context.commit('SET_QUOTE', quoteData);
              resolve(quoteData);
            } else {
              reject(new Error(quoteResult.data.ErrorMessage));
            }
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'getWebQuoteData-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  requestContact(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'quotes.js', methodName: 'requestContact-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.patch(`${autoQuoteServiceUrl}/quote/request-contact`, {
        AgreementNumber: sRequest.sAgreementNumber,
        ContactMethod: sRequest.sContactMethod || 'NONE',
        EmailAddress: sRequest.sEmailAddress || '',
        PhoneNumber: sRequest.sPhoneNumber || '',
        PreferredAgent: sRequest.sPreferredAgent || '',
        SendToConsumerIndicator: sRequest.sSendCopy === true ? 'Y' : 'N',
        SubAgreementNumber: sRequest.sSubAgreementNumber,
      }, config)
        .then((contactResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(contactResult), fileName: 'quotes.js', methodName: 'requestContact-Result', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (contactResult && contactResult.data) {
            // Save the added email address to the quote
            context.commit('SET_QUOTE_EMAIL_ADDRESS', sRequest.sEmailAddress);
            resolve(contactResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'requestContact-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  requestEmail(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'quotes.js', methodName: 'requestEmail-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.patch(`${autoQuoteServiceUrl}/quote/request-email`, {
        AgreementNumber: sRequest.sAgreementNumber,
        ContactMethod: sRequest.sContactMethod || 'NONE',
        EmailAddress: sRequest.sEmailAddress || '',
        PhoneNumber: sRequest.sPhoneNumber || '',
        PreferredAgent: sRequest.sPreferredAgent || '',
        SendToConsumerIndicator: 'Y',
        SubAgreementNumber: sRequest.sSubAgreementNumber,
      }, config)
        .then((contactResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(contactResult), fileName: 'quotes.js', methodName: 'requestEmail-Result', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (contactResult && contactResult.data) {
            // Save the added email address to the quote
            context.commit('SET_QUOTE_EMAIL_ADDRESS', sRequest.sEmailAddress);
            resolve(contactResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'requestEmail-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  requestToken(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios.post(`${autoQuoteServiceUrl}/lookup/request`, {
        EmailAddress: '',
        GoogleToken: sRequest.sQuoteToken,
        TrackingId: sRequest.sTfbId,
      }, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(quoteResult.data), fileName: 'quotes.js', methodName: 'requestToken-Result', trackerId: sRequest.sTfbId,
            }, { root: true });
            Vue.$cookies.set(quoteTokenName, quoteResult.data, 0);
            resolve(quoteResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'requestToken-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  updateDriver(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'quotes.js', methodName: 'updateDriver-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;

      const driverAccidents = [];
      const driverViolations = [];

      // Build the Accidents object
      if (sRequest.sDriver.Accidents && sRequest.sDriver.Accidents.length > 0) {
        for (let a = 0; a < sRequest.sDriver.Accidents.length; a += 1) {
          const foundAccident = sRequest.sDriver.Accidents[a];

          driverAccidents.push({
            AccidentAmount: foundAccident.AccidentAmount || '',
            AccidentDate: foundAccident.AccidentDate ? helpers.methods.parseDate(foundAccident.AccidentDate) : '',
            AccidentTypeCode: foundAccident.AccidentTypeCode || '',
            AtFaultIndicator: foundAccident.AtFaultIndicator || false,
          });
        }
      }

      // Build the Violations object
      if (sRequest.sDriver.Violations && sRequest.sDriver.Violations.length > 0) {
        for (let v = 0; v < sRequest.sDriver.Violations.length; v += 1) {
          const foundViolation = sRequest.sDriver.Violations[v];

          driverViolations.push({
            ViolationDate: foundViolation.ViolationDate ? helpers.methods.parseDate(foundViolation.ViolationDate) : '',
            ViolationTypeCode: foundViolation.ViolationTypeCode || '',
          });
        }
      }

      const request = {
        AcademicAchievement: sRequest.sDriver.AcademicAchievement || 'N',
        Accidents: driverAccidents,
        AgreementNumber: sRequest.sQuote.AgreementNumber,
        ClaimCount: sRequest.sQuote.ClaimCount || '0',
        CurrentTFBPolicyholder: sRequest.sQuote.HasCurrentTFBPolicy || 'N',
        DateOfBirth: sRequest.sDriver.DateOfBirth ? helpers.methods.parseDate(sRequest.sDriver.DateOfBirth) : '',
        DefensiveDriving: sRequest.sDriver.DefensiveDriving || 'N',
        DriveNSaveEnrollment: sRequest.sDriver.DriveNSaveEnrollment || 'N',
        DriverSequenceNumber: sRequest.sDriver.DriverSequenceNumber || '',
        DriverTraining: sRequest.sDriver.DriverTraining || 'N',
        FirstName: sRequest.sDriver.FirstName,
        Gender: sRequest.sDriver.Gender || '',
        IncludeAsDI: sRequest.sDriver.IncludeAsDI || 'N',
        LastName: sRequest.sDriver.LastName,
        LicenseNumber: sRequest.sDriver.DriversLicenseNumber || '',
        LicenseState: sRequest.sDriver.DriversLicenseState || '',
        MaritalStatus: sRequest.sDriver.MaritalStatusCode || '',
        MemberNumber: sRequest.sDriver.MemberNumber || '',
        Occupation: sRequest.sDriver.OccupationCode || '25',
        PrimaryVehicle: sRequest.sDriver.VehicleNumber || '0',
        Relationship: sRequest.sDriver.RelationshipToInsuredCode || '13',
        Status: sRequest.sDriver.OperatorStatusCode || 'A',
        SubAgreementNumber: sRequest.sQuote.SubAgreementNumber,
        Violations: driverViolations,
      };
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: JSON.stringify(request), fileName: 'quotes.js', methodName: 'updateDriver-SendRequest', trackerId: sRequest.sTfbId,
      }, { root: true });

      axios.patch(`${autoQuoteServiceUrl}/driver/update`, request, config)
        .then((driverResult) => {
          if (driverResult && driverResult.data) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(driverResult.data), fileName: 'quotes.js', methodName: 'updateDriver-Result', trackerId: sRequest.sTfbId,
            }, { root: true });
            const updateDriver = quoteTransform(driverResult.data, context, sRequest.sTfbId);
            context.commit('SET_QUOTE', updateDriver);
            if (driverResult.data.ErrorMessage === null || driverResult.data.ErrorMessage === '') {
              resolve(updateDriver);
            } else {
              reject(new Error(driverResult.data.ErrorMessage));
            }
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'updateDriver-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (error.toString().indexOf('status code 401') >= 0 || error.toString().indexOf('status code 405') >= 0 || error.toString().indexOf('status code 500') >= 0) {
            reject(new Error(genericErrorMessage));
          } else {
            reject(error);
          }
        });
    });
  },
  updateQuote(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'quotes.js', methodName: 'updateQuote-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;

      const request = {
        Address: sRequest.sLocation.Address1 || '',
        Address2: sRequest.sLocation.Address2 || '',
        AgreementNumber: !sRequest.sQuote.AgreementNumber || sRequest.sQuote.AgreementNumber === '' ? context.getters['quotes/agreementNumber'] : sRequest.sQuote.AgreementNumber,
        City: sRequest.sLocation.City || '',
        CountyCode: sRequest.sLocation.LocationCounty ? Number(sRequest.sLocation.LocationCounty) : '0',
        DateOfBirth: sRequest.sDriver.DateOfBirth ? helpers.methods.parseDate(sRequest.sDriver.DateOfBirth) : '',
        EffectiveDate: sRequest.sQuote.EffectiveDate ? helpers.methods.parseDate(sRequest.sQuote.EffectiveDate) : '',
        EmailAddress: sRequest.sQuote.EmailAddress || '',
        ExpirationDate: '',
        FirstName: sRequest.sDriver.FirstName || '',
        GaragingLocationIndicator: 'Y',
        Gender: sRequest.sDriver.Gender || '',
        LastName: sRequest.sDriver.LastName || '',
        LicenseNumber: sRequest.sDriver.DriversLicenseNumber || '',
        LicenseState: sRequest.sDriver.DriversLicenseState || '',
        PhoneNumber: sRequest.sQuote.PhoneNumber || '',
        QuoteType: (sRequest.sQuote.OwnershipCode || 'I'),
        State: sRequest.sQuote.State || '',
        SubAgreementNumber: sRequest.sQuote.SubAgreementNumber,
        Zip: sRequest.sLocation.ZipCode || '',
      };
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: JSON.stringify(request), fileName: 'quotes.js', methodName: 'updateQuote-SendRequest', trackerId: sRequest.sTfbId,
      }, { root: true });

      axios.patch(`${autoQuoteServiceUrl}/quote/update`, request, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(quoteResult.data), fileName: 'quotes.js', methodName: 'updateQuote-Result', trackerId: sRequest.sTfbId,
            }, { root: true });
            const updateQuote = quoteTransform(quoteResult.data, context, sRequest.sTfbId);
            context.commit('SET_QUOTE', updateQuote);
            if (quoteResult.data.ErrorMessage === null || quoteResult.data.ErrorMessage === '') {
              resolve(updateQuote);
            } else {
              reject(new Error(quoteResult.data.ErrorMessage));
            }
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'updateQuote-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (error.toString().indexOf('status code 401') >= 0 || error.toString().indexOf('status code 405') >= 0 || error.toString().indexOf('status code 500') >= 0) {
            reject(new Error(genericErrorMessage));
          } else {
            reject(error);
          }
        });
    });
  },
  updateQuoteCoverages(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'quotes.js', methodName: 'updateQuoteCoverages-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;

      const vehicleCoverages = [];

      for (let v = 0; v < sRequest.sVehicles.length; v += 1) {
        const foundVehicle = sRequest.sVehicles[v];

        foundVehicle.IncludeRoadsideAssistance = 'N';
        foundVehicle.IncludeRentalReimbursement = 'N';
        if (sRequest.sQuote.RentalReimbursementLimit !== '') {
          if (sRequest.sRental.indexOf(foundVehicle.VehicleSequenceNumber) !== -1) {
            foundVehicle.IncludeRentalReimbursement = 'Y';

            if (foundVehicle.CollisionDeductibleCode === '' || foundVehicle.CollisionDeductibleCode === null) {
              reject(new Error(collisionDeductibleRentalErrorMessage));
            }

            if (foundVehicle.ComprehensiveDeductibleCode === '' || foundVehicle.ComprehensiveDeductibleCode === null) {
              reject(new Error(comprehensiveDeductibleRentalErrorMessage));
            }
          }
        }

        if (sRequest.sQuote.RoadsideAssistanceLimit !== '') {
          if (sRequest.sRoadside.indexOf(foundVehicle.VehicleSequenceNumber) !== -1) {
            foundVehicle.IncludeRoadsideAssistance = 'Y';
          }
        }
        vehicleCoverages.push({
          CollisionDeductible: (foundVehicle.CollisionDeductibleCode || ''),
          ComprehensiveDeductible: (foundVehicle.ComprehensiveDeductibleCode || ''),
          HasRentalIndicator: (foundVehicle.IncludeRentalReimbursement || 'N'),
          HasRoadsideIndicator: (foundVehicle.IncludeRoadsideAssistance || 'N'),
          VehicleSequenceNumber: foundVehicle.VehicleSequenceNumber,
        });
      }

      const request = {
        AgreementNumber: sRequest.sQuote.AgreementNumber,
        SubAgreementNumber: sRequest.sQuote.SubAgreementNumber,
        SelectedCoverageLevel: sRequest.sQuote.SelectedCoverageLevel,
        BodilyInjuryLimit: (sRequest.sQuote.BodilyInjuryLimit || ''),
        PropertyDamageLimit: sRequest.sQuote.PropertyDamageLimit,
        MedPIPLimit: (sRequest.sQuote.MedicalPIPLimit || ''),
        UMBodilyInjuryLimit: sRequest.sQuote.UninsuredBodilyInjuryLimit,
        UMPropertyDamageLimit: sRequest.sQuote.UninsuredPropertyDamageLimit,
        RentalLimit: sRequest.sQuote.RentalReimbursementLimit,
        RoadsideLimit: sRequest.sQuote.RoadsideAssistanceLimit,
        VehicleCoverages: vehicleCoverages,
      };
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: JSON.stringify(request), fileName: 'quotes.js', methodName: 'updateQuoteCoverages-SendRequest', trackerId: sRequest.sTfbId,
      }, { root: true });

      axios.patch(`${autoQuoteServiceUrl}/quote/coverages/update`, request, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(quoteResult.data), fileName: 'quotes.js', methodName: 'updateQuoteCoverages-Result', trackerId: sRequest.sTfbId,
            }, { root: true });
            const updateQuote = quoteTransform(quoteResult.data, context, sRequest.sTfbId);
            context.commit('SET_QUOTE', updateQuote);
            if (quoteResult.data.ErrorMessage === null || quoteResult.data.ErrorMessage === '') {
              resolve(updateQuote);
            } else {
              reject(new Error(quoteResult.data.ErrorMessage));
            }
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'updateQuoteCoverages-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (error.toString().indexOf('status code 401') >= 0 || error.toString().indexOf('status code 405') >= 0 || error.toString().indexOf('status code 500') >= 0) {
            reject(new Error(genericErrorMessage));
          } else {
            reject(error);
          }
        });
    });
  },
  updateVehicle(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'quotes.js', methodName: 'updateVehicle-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(quoteTokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;

      const request = {
        AgreementNumber: sRequest.sQuote.AgreementNumber,
        AnnualMileage: (sRequest.sVehicle.AnnualMileage || '0'),
        DailyMileage: (sRequest.sVehicle.DailyMileage || '0'),
        DaysPerWeekDriven: (sRequest.sVehicle.DaysPerWeekDriven || '0'),
        EngineSize: (sRequest.sVehicle.EngineSize || ''),
        GaragedOnFarm: (sRequest.sVehicle.GaragedOnFarm || 'N'),
        GaragingLocation: {
          LocationSequenceNumber: (sRequest.sVehicle.GaragingLocationSequenceNumber || '1'),
          Address1: (sRequest.sVehicle.GaragingLocation.Address1 || ''),
          Address2: (sRequest.sVehicle.GaragingLocation.Address2 || ''),
          City: (sRequest.sVehicle.GaragingLocation.City || ''),
          State: 'TX',
          LocationCounty: (sRequest.sVehicle.GaragingLocation.LocationCounty || ''),
          ZipCode: (sRequest.sVehicle.GaragingLocation.ZipCode || ''),
        },
        Make: (sRequest.sVehicle.Make ? sRequest.sVehicle.Make.replace('_', '/') : ''),
        Model: (sRequest.sVehicle.Model || ''),
        NumberOfOwnedAcres: (sRequest.sVehicle.NumberOfOwnedAcres || '0'),
        NumberOfRentedAcres: (sRequest.sVehicle.NumberOfRentedAcres || '0'),
        PassiveRestraintCode: (sRequest.sVehicle.PassiveRestraintCode || ''),
        PrimaryVehicleUse: (sRequest.sVehicle.PrimaryVehicleUse || ''),
        PrincipalOperator: (sRequest.sVehicle.PrincipalOperator || ''),
        SubAgreementNumber: sRequest.sQuote.SubAgreementNumber,
        TitledTo: (sRequest.sVehicle.TitledTo || ''),
        VIN: (sRequest.sVehicle.VIN || ''),
        VehicleBodyStyle: (sRequest.sVehicle.VehicleBodyStyle || ''),
        Year: (sRequest.sVehicle.Year || ''),
        VehicleSequenceNumber: (sRequest.sVehicle.VehicleSequenceNumber || ''),
      };
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: JSON.stringify(request), fileName: 'quotes.js', methodName: 'updateVehicle-SendRequest', trackerId: sRequest.sTfbId,
      }, { root: true });

      axios.patch(`${autoQuoteServiceUrl}/vehicle/update`, request, config)
        .then((quoteResult) => {
          if (quoteResult && quoteResult.data) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(quoteResult.data), fileName: 'quotes.js', methodName: 'updateVehicle-Result', trackerId: sRequest.sTfbId,
            }, { root: true });
            const newQuote = quoteTransform(quoteResult.data, context, sRequest.sTfbId);
            context.commit('SET_QUOTE', newQuote);
            if (quoteResult.data.ErrorMessage === null || quoteResult.data.ErrorMessage === '') {
              resolve(newQuote);
            } else {
              reject(new Error(quoteResult.data.ErrorMessage));
            }
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'updateVehicle-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  validateVIN(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'quotes.js', methodName: 'validateVIN-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve) => {
      if (sRequest.sVIN && sRequest.sVIN !== null) {
        const ssoToken = Vue.$cookies.get(quoteTokenName);
        config.headers.Authorization = `Bearer ${ssoToken}`;
        axios.get(`${autoQuoteServiceUrl}/vehicle/vin/${sRequest.sVIN}/${context.getters.agreementNumber}/${context.getters.subAgreementNumber}`, config)
          .then((vinResult) => {
            if (vinResult && vinResult.data) {
              resolve(vinResult.data);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            context.dispatch('app/logToULS', {
              logLevel: 'Error', logMessage: error, fileName: 'quotes.js', methodName: 'validateVIN-Error', trackerId: sRequest.sTfbId,
            }, { root: true });
            resolve(false);
          });
      } else {
        resolve(true);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
