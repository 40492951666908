<template>
  <div>
    <v-btn v-if="coverage.LongDescription && coverage.LongDescription !== '' && coverage.LongDescription !== null"
           text
           @click.stop="optionalCoverageModal = true">{{coverage.Title}}</v-btn>
    <span v-else>{{coverage.Title}}</span>
    <v-dialog v-model="optionalCoverageModal"
              max-width="600px">
      <v-card>
        <v-card-title class="background-darker-blue">
          <h3 class="tfb-title-text-small">{{coverage.Title}}</h3>
        </v-card-title>
        <v-card-text>
          <v-container class="tfb-component-container">
            <v-row>
              <v-col cols="12"
                     class="py-5">
                <span v-html="coverage.LongDescription"></span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'OptionalCoverageItem',
    mixins: [tfbHelperMixin],
    data() {
      return {
        optionalCoverageModal: false,
      };
    },
    props: {
      coverage: {
        type: Object,
        required: true,
      },
    },
  };
</script>
<style lang="scss">

</style>
