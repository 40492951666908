<template>
  <div>
    <v-dialog v-if="!$vuetify.breakpoint.mobile"
              v-model="showDesktopCard"
              max-width="510"
              @click:outside="modalDismissed">
      <!--<v-container>
    <v-row no-gutters
           class="mobile-app-card rounded-lg">
      <v-col cols="12"
             class="mobile-app-card-image">
        <v-img v-bind:src="imageRootPath +'mobile-app-desktop-popup.jpg'"
               alt="Download Our New Mobile App"
               contain
               class="mx-auto"></v-img>
      </v-col>
      <v-col cols="12"
             class="mobile-app-card-mobile-links">
        <mobile-app-links></mobile-app-links>
      </v-col>
    </v-row>
  </v-container>-->
      <v-card>
        <v-img v-bind:src="imageRootPath +'mobile-app-desktop-popup.jpg'"
               alt="Download Our New Mobile App"
               class="align-end"
               height="424">
          <v-card-title class="mobile-app-card-desktop-links"><mobile-app-links></mobile-app-links></v-card-title>
        </v-img>
      </v-card>
    </v-dialog>
    <v-dialog v-if="$vuetify.breakpoint.mobile"
              v-model="showMobileCard"
              max-width="510"
              @click:outside="modalDismissed">
      <v-card>
        <v-img v-bind:src="imageRootPath +'mobile-app-mobile-popup.png'"
               alt="Download Our New Mobile App"
               class="align-end"
               height="424">
          <v-card-title class="mobile-app-card-mobile-links"><mobile-app-links></mobile-app-links></v-card-title>
        </v-img>
      </v-card>
      <!--<v-container>
    <v-row no-gutters
           class="mobile-app-card rounded-lg">
      <v-col cols="12"
             class="mobile-app-card-image">
        <v-img v-bind:src="imageRootPath +'mobile-app-mobile-popup.png'"
               alt="Download Our New Mobile App"
               contain
               class="mx-auto"></v-img>
      </v-col>
      <v-col cols="12"
             class="mobile-app-card-mobile-links">
        <mobile-app-links></mobile-app-links>
      </v-col>
    </v-row>
  </v-container>-->
    </v-dialog>
  </div>
</template>
<script>
  import MobileAppLinks from '@/views/layout/components/MobileAppLinks.vue';

  export default {
    name: 'MobileAppCard',
    components: {
      MobileAppLinks,
    },
    computed: {
      imageRootPath() {
        return `${process.env.VUE_APP_ROOT_URI}/publishingimages/`;
      },
    },
    data() {
      return {
        showDesktopCard: true,
        showMobileCard: true,
      };
    },
    methods: {
      modalDismissed() {
        // Hide the modal
        this.showDesktopCard = false;
        this.showMobileCard = false;

        // Set the cookie that they have dismissed the modal to avoid showing
        //  it to them repeatedly (unless they clear their cookies)
        this.$store.dispatch('app/setMobileAppDisplay');
      },
    },
  };
</script>
<style lang="scss">
  .mobile-app-card {
    z-index: 997;
    background: rgba(202,226,241,1);
    background: -moz-linear-gradient(top, rgba(202,226,241,1) 0%, rgba(255,255,255,1) 70%, rgba(255,255,255,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(202,226,241,1)), color-stop(70%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,1)));
    background: -webkit-linear-gradient(top, rgba(202,226,241,1) 0%, rgba(255,255,255,1) 70%, rgba(255,255,255,1) 100%);
    background: -o-linear-gradient(top, rgba(202,226,241,1) 0%, rgba(255,255,255,1) 70%, rgba(255,255,255,1) 100%);
    background: -ms-linear-gradient(top, rgba(202,226,241,1) 0%, rgba(255,255,255,1) 70%, rgba(255,255,255,1) 100%);
    background: linear-gradient(to bottom, rgba(202,226,241,1) 0%, rgba(255,255,255,1) 70%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cae2f1', endColorstr='#ffffff', GradientType=0 );
  }

  .mobile-app-card-text {
    color: $tfb-darker-blue;
    font-family: 'BS-light', $body-font-family;
    padding: 20px;
  }

  .mobile-app-card-header-text {
    font-size: 36px;
  }

  .mobile-app-card-detail-text {
    font-size: 16px;
  }

  .mobile-app-card-desktop-links {
    padding-bottom: 50px;
  }

  .mobile-app-card-mobile-links {
    /*background: $white;*/
    padding-bottom: 100px;
  }
</style>
