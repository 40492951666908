<template>
  <v-container fluid
               class="tfb-container pb-10">
    <h2 class="tfb-title-text-medium text-darker-blue text-uppercase">View All Claims</h2>
    <v-container class="drop-shadow background-white pa-sm-6 pa-md-12">
      <v-row>
        <v-col cols="12">
          <p class="text-center">
            Please note that if you have recently submitted a claim, it will not appear online until it is entered into the system by your Claims Representative.
          </p>
          <p class="text-center">
            If you need to speak with someone immediately, please call
            <a v-bind:href="claimsReportingCenter.phoneLink"
               v-bind:title="claimsReportingCenter.phoneDisplay"
               class="font-weight-bold">{{ claimsReportingCenter.phoneDisplay }}</a>.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <hr />
        </v-col>
      </v-row>
      <claim-list :claimListLoading="claimListLoading"
                  claimType="open"
                  :claimList="openClaimList"></claim-list>
      <v-row>
        <v-col cols="12">
          <hr />
        </v-col>
      </v-row>
      <claim-list :claimListLoading="claimListLoading"
                  claimType="closed"
                  :claimList="closedClaimList"></claim-list>
    </v-container>
  </v-container>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import tfbMetaInfo from '@/mixins/metaInfo';
  import tfbHelperMixin from '@/mixins/helpers';
  import tfbDataMixin from '@/mixins/tfbData';
  import ClaimList from './components/ViewClaimList.vue';

  export default {
    name: 'ViewClaims',
    mixins: [tfbMetaInfo, tfbHelperMixin, tfbDataMixin],
    components: {
      ClaimList,
    },
    data() {
      return {
        claimListLoading: true,
        openClaimList: [],
        closedClaimList: [],
        claimDetailLoading: false,
      };
    },
    computed: {
      metaPageTitle() {
        return 'View Claims | PolicyHolder';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/policyholder/claims`;
      },
      memberNumber() {
        try {
          return this.$store.getters['member/memberNumber'];
        } catch (error) {
          return '';
        }
      },
    },
    methods: {
      async getClaimList() {
        dispatch('claims/getClaimsByMember', { sMemberNumber: this.memberNumber, sTfbId: this.trackingId })
          .then((claimListResponse) => {
            if (claimListResponse) {
              this.openClaimList = this.$store.getters['claims/openClaims'];
              this.closedClaimList = this.$store.getters['claims/closedClaims'];
              this.claimListLoading = false;
            } else {
              dispatch('app/setErrorMessage', '');
              this.claimListLoading = false;
            }
          })
          .catch((error) => {
            if (error.toString().indexOf('Request failed with status code 401') >= 0) {
              // Session has timed out, log out and go to login
              this.$store.dispatch('member/doLogout')
                .then(() => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$router.push({ name: 'policyholderLogin' });
                })
                .catch((accessError) => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$store.dispatch('app/setErrorMessage', accessError.toString());
                });
            }
            dispatch('app/setErrorMessage', error.toString());
            this.claimListLoading = false;
          });
      },
    },
    mounted() {
      dispatch('app/clearErrorMessage');
      dispatch('app/setIsLoading', true);
      const includedToken = this.$route.query.access_token;
      dispatch('member/validateLogin', { sToken: includedToken, sTfbId: this.trackingId })
        .then((loginValid) => {
          if (loginValid) {
            if (this.doStatusCheck()) {
              this.getClaimList();
            }
            dispatch('app/setIsLoading', false);
          } else {
            this.$store.dispatch('app/setIsLoading', false);
            this.$router.push({ name: 'policyholderLogin' });
          }
        })
        .catch((error) => {
          dispatch('app/setErrorMessage', error.toString());
          dispatch('app/setIsLoading', false);
        });
    },
  };
</script>
<style lang="scss">

</style>
