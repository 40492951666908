<template>
  <div class="no-print">
    <mobile-navigation class="hidden-md-and-up"></mobile-navigation>
    <navigation class="hidden-sm-and-down"></navigation>
  </div>
</template>
<script>
import Navigation from './navigation/Navigation.vue';
import MobileNavigation from './navigation/MobileNavigation.vue';

export default {
  name: 'Header',
  components: {
    Navigation,
    MobileNavigation,
  },
};
</script>
