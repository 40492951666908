<template>
  <v-container fluid
               class="progress-sidebar no-print">
    <as-low-as></as-low-as>
    <v-expansion-panels accordion
                        multiple>
      <v-expansion-panel readonly>
        <v-expansion-panel-header disable-icon-rotate>
          <router-link :to="{ name: 'quoteAboutYou'}"
                       class="progress-header-link">About You</router-link>
          <template v-slot:actions>
            <v-icon v-if="agreementNumber === ''">mdi-close</v-icon>
            <v-icon v-else>mdi-check</v-icon>
          </template>
        </v-expansion-panel-header>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header disable-icon-rotate
                                  class="progress-header-link">
          Vehicles
          <template v-slot:actions>
            <v-icon v-if="hasVehicle">mdi-check</v-icon>
            <v-icon v-else>mdi-close</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row v-for="vehicle in quote.Vehicles"
                 :key="vehicle.VehicleSequenceNumber">
            <v-col cols="12">
              <v-icon>mdi-car</v-icon> <router-link :to="{ name: 'quoteVehicleId', params: { id: vehicle.VehicleSequenceNumber }}"
                                                    class="progress-link">{{vehicle.Description}}</router-link>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header disable-icon-rotate
                                  class="progress-header-link">
          Drivers
          <template v-slot:actions>
            <v-icon v-if="hasDriver">mdi-check</v-icon>
            <v-icon v-else>mdi-close</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row v-for="driver in quote.Operators"
                 :key="driver.DriverSequenceNumber">
            <v-col cols="12">
              <v-icon v-if="driver.Gender === 'F'">mdi-human-female</v-icon>
              <v-icon v-else-if="driver.Gender === 'M'">mdi-human-male</v-icon>
              <v-icon v-else>mdi-account</v-icon> <router-link :to="{ name: 'quoteDriverId', params: { id: driver.DriverSequenceNumber }}"
                                                               class="progress-link">{{driver.FirstName}}&nbsp;{{driver.LastName}}</router-link>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel readonly>
        <v-expansion-panel-header disable-icon-rotate>
          <router-link :to="{ name: 'quoteCoverage' }"
                       class="progress-header-link">Coverages</router-link>
          <template v-slot:actions>
            <v-icon v-if="hasCoverage">mdi-check</v-icon>
            <v-icon v-else>mdi-close</v-icon>
          </template>
        </v-expansion-panel-header>
      </v-expansion-panel>
      <v-expansion-panel readonly
                         v-if="isComplete">
        <v-expansion-panel-header hide-actions
                                  class="text-center">
          <router-link :to="{ name: 'quoteSummary' }"
                       class="progress-header-link">Summary</router-link>
        </v-expansion-panel-header>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';
  import AsLowAs from './AsLowAs.vue';

  export default {
    name: 'QuoteProgress',
    mixins: [tfbHelperMixin],
    components: {
      AsLowAs,
    },
    data() {
      return {
        isMobile: this.$vuetify.breakpoint.smAndDown,
      };
    },
    computed: {
      agreementNumber() {
        try {
          return this.$store.getters['quotes/agreementNumber'];
        } catch (error) {
          return '';
        }
      },
      quote() {
        try {
          return this.$store.getters['quotes/quote'];
        } catch (error) {
          return null;
        }
      },
      subAgreementNumber() {
        try {
          return this.$store.getters['quotes/subAgreementNumber'];
        } catch (error) {
          return '';
        }
      },
      hasVehicle() {
        if (this.quote && this.quote.Vehicles && this.quote.Vehicles.length > 0) {
          if (this.quote.Vehicles[0].VIN && this.quote.Vehicles[0].VIN !== '' && this.quote.Vehicles[0].VIN !== null) {
            return true;
          }
          if (this.quote.Vehicles[0].Year && this.quote.Vehicles[0].Year !== '' && this.quote.Vehicles[0].Year !== null) {
            return true;
          }
        }
        return false;
      },
      hasDriver() {
        if (this.quote && this.quote.Operators && this.quote.Operators.length > 0 && this.quote.Operators[0].DriversLicenseNumber
          && this.quote.Operators[0].DriversLicenseNumber !== '' && this.quote.Operators[0].DriversLicenseNumber !== null) {
          return true;
        }
        return false;
      },
      hasCoverage() {
        if (this.quote && this.quote.Quote && this.quote.Quote.SelectedCoverageLevel && this.quote.Quote.SelectedCoverageLevel !== null && this.quote.Quote.SelectedCoverageLevel !== '') {
          return true;
        }
        return false;
      },
      isComplete() {
        if (this.quote && this.quote.Quote && this.quote.Quote.CompletedQuoteIndicator === 'Y') {
          return true;
        }
        return false;
      },
    },
  };
</script>
<style lang="scss">

  .progress-sidebar {
    color: $black;
  }

  .progress-link {
    font-weight: 700;
  }

  .progress-header-link,
  .progress-header-link:visited {
    color: $black;
    font-weight: 700;
  }
</style>
