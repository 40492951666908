<template>
  <div>
    <div class="py-10 background-darker-blue align-center">
      <div class="my-12">
        <h1 class="tfb-title-text-large text-white">
          Careers
        </h1>
        <p class="tfb-title-text text-white">
          Texas Farm Bureau Insurance offers a wide variety of career opportunities in areas all
          across the state of Texas to serve 500,000+ customers.
        </p>
      </div>
      <div class="py-10 align-center"
           v-if="careerOpeningsUrl !== ''">
        <v-btn class="tfb-button background-red extra-padding"
               :href="careerOpeningsUrl"
               hreflang="en-us"
               target="_blank">Click here to view our career openings</v-btn>
      </div>
    </div>
    <div class="text-darkest-blue">
      <v-container fluid
                   v-bind:style="'background-image: url(' + becomeAgentBanner + ');background-size: cover;background-position: center;'">
        <v-row>
          <v-col cols="12">
            <v-container fluid
                         class="tfb-container">
              <v-row>
                <v-col cols="12"
                       sm="6">
                  <h2 class="tfb-title-text-medium">
                    Become an Agent
                  </h2>
                  <ul class="text-body-2">
                    <li>Are you ambitious and personable?<br /><br /></li>
                    <li>
                      Do you have the desire to be the driving force in your professional
                      future?<br /><br />
                    </li>
                    <li>Do you want to control your financial growth?<br /><br /></li>
                    <li>
                      Do you want to be a respected person in your community who offers proven
                      products and is sought out for your expertise?<br /><br />
                    </li>
                    <li>Do you want to have a choice of office locations?<br /><br /></li>
                  </ul>
                  <v-btn class="tfb-button background-blue extra-padding"
                         hreflang="en-us"
                         :to="{ name: 'salesManager' }">
                    Contact us here for agent openings
                  </v-btn>
                </v-col>
                <v-col cols="12"
                       lg="6"></v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container fluid
                 class="py-10">
      <v-row v-if="becomeAgentVideo !== null">
        <v-col cols="12"
               md="6"
               justify="center"
               class="py-10 align-center"
               :style="getVideoBackgroundImage(becomeAgentVideo.Thumbnail)">

          <div class="tfb-video align-center py-10">
            <div class="tfb-video-header">
              <img alt="Real Stories"
                   id="video-header-image"
                   v-bind:src="imageRootPath + '/real-stories-header.png'" />
            </div>
            <div class="tfb-video-title">
              <p class="tfb-title-text-medium text-white">
                {{becomeAgentVideo.Title}}
              </p>
            </div>
            <div class="tfb-video-subtitle">
              <p class="tfb-title-text text-white font-weight-bold">
                {{becomeAgentVideo.SubTitle}}
              </p><br />
            </div>
            <div class="tfb-video-button">
              <v-btn @click="getVideo(becomeAgentVideo.Id)"
                     class="tfb-button background-red">
                Watch Video
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-dialog v-model="showEmbedded"
                max-width="800">
        <v-card class="align-center">
          <span v-html="videoText"></span>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showLocal"
                max-width="800">
        <v-card class="align-center">
          <video v-bind:src="videoText"
                 controls>
            Sorry, your browser does not support embedded videos.
          </video>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
  /* eslint prefer-destructuring: 0 */
  import { dispatch } from 'vuex-pathify';
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbMetaMixin from '@/mixins/metaInfo';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'Careers',
    mixins: [tfbDataMixin, tfbMetaMixin, tfbHelperMixin],
    data() {
      return {
        careerOpeningsUrl: '',
        becomeAgentVideo: null,
        showEmbedded: false,
        showLocal: false,
        videoText: null,
      };
    },
    computed: {
      metaDescription() {
        return 'Helping people is rewarding, especially at Texas Farm Bureau Insurance. Check out our career opportunities.';
      },
      metaPageTitle() {
        return 'Careers';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/careers`;
      },
      becomeAgentBanner() {
        return `${process.env.VUE_APP_ROOT_URI}/publishingimages/become-an-agent-header.png`;
      },
      becomeAgentVideoBanner() {
        return `${process.env.VUE_APP_ROOT_URI}/publishingimages/become-an-agent-video.jpg`;
      },
    },
    created() {
      this.$store.dispatch('app/clearErrorMessage');
      this.$store.dispatch('app/getConfiguration', { sSection: 'ExternalUrl', sTitle: 'TFBCareers' })
        .then((response) => {
          if (response && response !== '') {
            this.careerOpeningsUrl = response;
          }
        })
        .catch((error) => {
          dispatch('app/setErrorMessage', error.toString());
        });
      this.$store.dispatch('app/getVideosByPage', { sPage: 'career', sTfbId: this.trackingId })
        .then((response) => {
          if (response && response !== null && response.length > 0) {
            this.becomeAgentVideo = response[0];
          }
        })
        .catch((error) => {
          this.$store.dispatch('app/setErrorMessage', error.toString());
        });
    },
    methods: {
      getVideo(videoId) {
        this.$store.dispatch('app/getVideoById', { sId: videoId, sTfbId: this.trackingId })
          .then((response) => {
            if (response) {
              if (response.Embed) {
                this.videoText = response.Embed;
                this.showEmbedded = true;
              } else if (response.Url) {
                this.videoText = response.Url;
                this.showLocal = true;
              }
            }
          })
          .catch(() => {
            // Don't do anything
          });
      },
    },
  };
</script>
<style lang="scss">

  .tfb-career-overlay {
    z-index: 999;
    margin-top: 75px;
    margin-bottom: 10px;
    margin-left: 10px;
  }

  @media (min-width: 480px) {
    .tfb-career-overlay {
      margin-top: 100px;
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }

  @media (min-width: 576px) {
    .tfb-career-overlay {
      margin-top: 120px;
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }

  @media (min-width: 768px) {
    .tfb-career-overlay {
      margin-top: 150px;
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }

  @media (min-width: 992px) {
    .tfb-career-overlay {
      margin-top: 5px;
      margin-bottom: 50px;
    }
  }

  @media (min-width: 1200px) {
    .tfb-career-overlay {
      margin-top: 10px;
      margin-left: 50px;
    }
  }

  @media (min-width: 1300px) {
    .tfb-career-overlay {
      margin-top: 10px;
      margin-bottom: 75px;
    }
  }

  @media (min-width: 1500px) {
    .tfb-career-overlay {
      margin-top: 30px;
      margin-bottom: 75px;
    }
  }

  @media (min-width: 1600px) {
    .tfb-career-overlay {
      margin-top: 40px;
      margin-bottom: 100px;
    }
  }
</style>
