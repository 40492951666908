<template>
  <div id="countyListing">
    <h1 class="tfb-title-text-large text-center">County Office Listing</h1>
    <v-container fluid>
      <v-row v-if="!countyLoading && countyList.length > 0">
        <v-col cols="12"
               v-for="county in countyList"
               v-bind:key="county.id">
          <a :href="county.countyUrl"
             hreflang="en-us"
             :aria-label="county.countyName">{{county.countyUrl}}</a>
        </v-col>
      </v-row>
      <v-row v-if="!countyLoading && countyList.length <= 0">
        No County Offices Available!
      </v-row>
      <v-row v-if="countyLoading">
        <v-col cols="12"
             v-for="i in 3"
             :key="i">
          <v-skeleton-loader class="mx-auto"
                             max-width="300"
                             type="list-item"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  /* eslint no-underscore-dangle: 0 */
  import tfbHelperMixin from '@/mixins/helpers';
  import tfbMetaMixin from '@/mixins/metaInfo';

  export default {
    name: 'CountyListing',
    mixins: [tfbHelperMixin, tfbMetaMixin],
    data() {
      return {
        countyLoading: true,
        countyList: [],
      };
    },
    computed: {
      metaPageTitle() {
        return 'Find Insurance Agents by County';
      },
      metaDescription() {
        return 'Find insurance agents by your county across Texas.';
      },
      metaUrl() {
        return `https://${window.location.host}/county/listing`;
      },
    },
    methods: {
      loadAgents() {
        this.countyLoading = true;
        this.$store.dispatch('agentcounty/getCountyList', { sTfbId: this.trackingId })
          .then((foundCounties) => {
            if (foundCounties !== null && foundCounties.data !== null && foundCounties.data._counties !== null && foundCounties.data._counties.length > 0) {
              for (let c = 0; c < foundCounties.data._counties.length; c += 1) {
                const county = foundCounties.data._counties[c];
                this.$store.dispatch('agentcounty/getOfficesByCounty', { sCountyName: county._countyName, sTfbId: this.trackingId })
                  .then((foundOffices) => {
                    if (foundOffices !== null && foundOffices.length > 0) {
                      for (let o = 0; o < foundOffices.length; o += 1) {
                        const office = foundOffices[o];
                        const cleanCountyName = this.replaceAll(office.name, ' ', '-').toLowerCase();
                        let builtCountyUrl = `https://${window.location.host}/county/details/${cleanCountyName}`;

                        if (office.branchCode && office.branchCode !== null && office.branchCode !== '') {
                          builtCountyUrl = `${builtCountyUrl}/${office.branchCode.toLowerCase()}`;
                        }

                        this.countyList.push({
                          id: office.officeNumber,
                          countyUrl: builtCountyUrl,
                          countyName: cleanCountyName,
                        });
                      }
                    }
                    this.countyLoading = false;
                  })
                  .catch(() => {
                    this.countyLoading = false;
                  });
              }
            }
            this.countyLoading = false;
          })
          .catch(() => {
            this.countyLoading = false;
          });
      },
    },
    created() {
      this.loadAgents();
    },
  };
</script>
<style lang="scss">

</style>
