import Vue from 'vue';
import { make } from 'vuex-pathify';
import axios from 'axios';
import _ from 'lodash';
import { namePolicyNotFoundErrorMessage } from '../../mixins/errorMessages';
import helpers from '../../mixins/helpers';
/* eslint no-param-reassign: 0 */
/* eslint prefer-destructuring: 0 */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const claimLookupServiceUrl = `${process.env.VUE_APP_WEB_URI}/services/TFBIC.Services.RESTClaims.Lookup`;
const claimModifyServiceUrl = `${process.env.VUE_APP_WEB_URI}/services/TFBIC.Services.RESTClaims.Add`;

const tokenName = process.env.VUE_APP_WEB_ENV !== 'PROD' ? `tfb_${process.env.VUE_APP_WEB_ENV}_token` : 'tfb_token';
const claimTokenName = process.env.VUE_APP_WEB_ENV !== 'PROD' ? `tfb_${process.env.VUE_APP_WEB_ENV}_claim_token` : 'tfb_claim_token';

const config = {
  crossdomain: true,
  // withCredentials: true,
  headers: { 'Access-Control-Allow-Origin': '*', Accept: 'application/json; odata=verbose', 'Content-Type': 'application/json; odata=verbose' },
};

function getDefaultState() {
  return {
    claim: {
      destination: null,
      type: null,
      effectiveDate: null,
      expirationDate: null,
      memberNumber: null,
      policyNumber: null,
      policySymbol: null,
      policyType: null,
      policySubtype: null,
      externalIdValTxt: null,
      companyName: null,
      corporationName: null,
      hasPhotos: null,
    },
    reporter: {
      name: null,
      type: null,
      phoneNumber: null,
      phoneType: null,
      emailAddress: null,
      reportingSource: 1,
    },
    loss: {
      date: null,
      time: null,
      type: null,
      description: null,
      location: null,
      city: null,
      state: 'TX',
      county: null,
      zip: null,
      policeDepartment: null,
      policeCaseNumber: null,
    },
    primaryInsured: {
      objectId: null,
      emailAddress: null,
      name: {
        firstName: null,
        lastName: null,
        suffix: null,
        displayName: null,
      },
      insuredType: null,
      gender: null,
      maritalStatus: null,
      dateOfBirth: null,
      address: {
        addressLine1: null,
        addressLine2: null,
        city: null,
        state: 'TX',
        zip: null,
      },
      primaryInd: 'false',
      phone: null,
      phoneType: null,
      licenseNumber: null,
      licenseState: null,
      licenseClass: null,
    },
    insureds: [
      {},
    ],
    thirdParties: [],
    drivers: [],
    vehicles: [],
    claimAdjuster: null,
    confirmationNumber: '',
    selectedFiles: [],
    uploadedFiles: [],
    errorFiles: [],
    openClaims: [],
    closedClaims: [],
  };
}

// state
const state = getDefaultState();

// mutations
const mutations = {
  ...make.mutations(state),
  SET_UPLOADED_FILES: (state, fileName) => {
    state.uploadedFiles.push(fileName);
  },
  SET_ERROR_FILES: (state, fileName) => {
    state.errorFiles.push(fileName);
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

// getters
const getters = {
  ...make.getters(state),
};

// actions
const actions = {
  clearToken() {
    Vue.$cookies.remove(claimTokenName);
  },
  getClaimsByMember(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'claims.js', methodName: 'getClaimsByMember-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const claimToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${claimToken}`;
      axios
        .get(`${claimLookupServiceUrl}/claims/member/${sRequest.sMemberNumber}`,
        config)
        .then((claimResponse) => {
          if (claimResponse && claimResponse.data && claimResponse.data.claims && claimResponse.data.claims !== null) {
            const openClaims = [];
            const closedClaims = [];
            for (let c = 0; c < claimResponse.data.claims.length; c += 1) {
              const foundClaim = claimResponse.data.claims[c];

              const policyList = context.rootGetters['policy/accountSummary'];
              if (policyList) {
                for (let p = 0; p < policyList.length; p += 1) {
                  const foundPolicy = policyList[p];

                  if (foundPolicy.PolicyNumber.trim() === foundClaim.PolicyNumber.trim() && foundPolicy.PolicyType.trim() === foundClaim.PolicyType.trim()) {
                    foundClaim.PolicyType = foundPolicy.PolicyDescription;
                  }
                }
              }

              foundClaim.DateOfLoss = helpers.methods.parseDate(helpers.methods.replaceAll(helpers.methods.replaceAll(foundClaim.DateOfLoss, ' CST', ''), ' CDT', ''));

              if (foundClaim.ClaimStatus === 'OPEN') {
                openClaims.push(foundClaim);
              } else {
                closedClaims.push(foundClaim);
              }
            }

            let sortedClaims = _.orderBy(closedClaims, ['DateOfLoss'], ['desc']);
            context.commit('SET_CLOSED_CLAIMS', sortedClaims);
            sortedClaims = _.orderBy(openClaims, ['DateOfLoss'], ['desc']);
            context.commit('SET_OPEN_CLAIMS', sortedClaims);
          }

          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getClaimDetails(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'claims.js', methodName: 'getClaimDetails-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const claimToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${claimToken}`;
      axios
        .get(`${claimLookupServiceUrl}/claims/detail/${sRequest.sClaimNumber}/${sRequest.sPolicyNumber}`,
        config)
        .then((claimResponse) => {
          let claimDetailsResponse = {};
          const claimAdjusters = [];
          const claimCoverages = [];
          if (claimResponse && claimResponse.data && claimResponse.data.claimDTO) {
            claimDetailsResponse = claimResponse.data.claimDTO;
          }

          if (claimResponse && claimResponse.data && claimResponse.data.claimPayments
            && claimResponse.data.claimPayments.claimPayment && claimResponse.data.claimPayments.claimPayment.length > 0
            && claimResponse.data.claimPayments.claimPayment[0].claimPayables && claimResponse.data.claimPayments.claimPayment[0].claimPayables.claimPayable
            && claimResponse.data.claimPayments.claimPayment[0].claimPayables.claimPayable.length > 0) {
            for (let c = 0; c < claimResponse.data.claimPayments.claimPayment[0].claimPayables.claimPayable.length; c += 1) {
              const foundCoverage = claimResponse.data.claimPayments.claimPayment[0].claimPayables.claimPayable[c];

              if (foundCoverage.clmCoverageCode && foundCoverage.clmCoverageCode !== null) {
                let coverageDescription = foundCoverage.clmCoverageCode;
                const coverageAmount = foundCoverage.transactionAmount;
                if (foundCoverage.literalDescriptionMap && foundCoverage.literalDescriptionMap.entry && foundCoverage.literalDescriptionMap.entry.length > 0) {
                  const claimCoverageEntries = foundCoverage.literalDescriptionMap.entry;
                  for (let e = 0; e < claimCoverageEntries.length; e += 1) {
                    const foundEntry = claimCoverageEntries[e];

                    if (foundEntry.string && foundEntry.string.length > 0) {
                      if (foundEntry.string[0] === 'clmLossTypeCode') {
                        coverageDescription = foundEntry.string[1];
                      }
                    }
                  }
                }

                claimCoverages.push({ CoverageDescription: coverageDescription, CoverageAmount: coverageAmount });
              }
            }
          }

          if (claimResponse && claimResponse.data && claimResponse.data.claimAssignments
            && claimResponse.data.claimAssignments.importAssignmentDTO && claimResponse.data.claimAssignments.importAssignmentDTO.length > 0) {
            for (let a = 0; a < claimResponse.data.claimAssignments.importAssignmentDTO.length; a += 1) {
              const foundAdjuster = claimResponse.data.claimAssignments.importAssignmentDTO[a];

              if (foundAdjuster.userFirstName && foundAdjuster.userFirstName !== null && foundAdjuster.userFirstName.trim() !== '') {
                const adjusterAddress = helpers.methods.buildAddress(foundAdjuster.userAddresss, null, null,
                  foundAdjuster.userCity, foundAdjuster.userState, foundAdjuster.userZipCode, null, null);

                claimAdjusters.push({
                  firstName: foundAdjuster.userFirstName.trim(),
                  lastName: foundAdjuster.userLastName.trim(),
                  address: adjusterAddress,
                  phoneNumber: foundAdjuster.userPhoneNumber,
                  extension: foundAdjuster.userPhoneExtension,
                  emailAddress: foundAdjuster.userEmail,
                });
              }
            }
          }

          claimDetailsResponse.Adjusters = claimAdjusters;
          claimDetailsResponse.Coverages = claimCoverages;

          resolve(claimDetailsResponse);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  checkClaim(context) {
    return new Promise((resolve, reject) => {
      if (context.state.claim.destination === 1) {
        let claimToken = Vue.$cookies.get(claimTokenName);

        if (!claimToken || claimToken === '') {
          claimToken = Vue.$cookies.get(tokenName);
        }
        config.headers.Authorization = `Bearer ${claimToken}`;
        // Check to see if there was already a claim reported within the past day
        axios
          .get(`${claimLookupServiceUrl}/claims/insured-report/${context.state.claim.policyNumber}`, config)
          .then((foundClaimResponse) => {
            if (foundClaimResponse && foundClaimResponse.data && foundClaimResponse.data.length > 0) {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        resolve(false);
      }
    });
  },
  validateClaim(context, validation) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(validation), fileName: 'claims.js', methodName: 'validateClaim-Start', trackerId: validation.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const policySearchRequest = {
        PolicyNumber: validation.sPolicyNumber, LastName: validation.sLastName, DateOfLoss: validation.sDateOfLoss, GoogleToken: validation.sCaptchaText,
      };
      axios
        .post(`${claimLookupServiceUrl}/policy/search`, policySearchRequest, config)
        .then((claimResponse) => {
          if (claimResponse && claimResponse.data && claimResponse.data.length > 0) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(claimResponse.data[0]), fileName: 'claims.js', methodName: 'validateClaim-Result', trackerId: validation.sTfbId,
            }, { root: true });
            Vue.$cookies.set(claimTokenName, claimResponse.data[0].AccessToken, 0);
            context.commit('SET_CLAIM', {
              effectiveDate: null,
              expirationDate: null,
              memberNumber: null,
              externalIdValTxt: null,
              companyName: null,
              corporationName: null,
              destination: claimResponse.data[0].ClaimDestination,
              type: claimResponse.data[0].ClaimType,
              policyNumber: claimResponse.data[0].PolicyNumber,
              policySymbol: (claimResponse.data[0].PolicySymbol !== '' && claimResponse.data[0].PolicySymbol !== 'Unknown' ? claimResponse.data[0].PolicySymbol : claimResponse.data[0].PolicyType),
              policyType: claimResponse.data[0].PolicyType,
              policySubtype: claimResponse.data[0].PolicySubType,
              hasPhotos: 'N',
            });
            context.commit('SET_LOSS', {
              date: claimResponse.data[0].DateOfLoss,
              type: null,
              description: null,
              location: null,
              city: null,
              state: 'TX',
              county: null,
              zip: null,
              policeDepartment: null,
              policeCaseNumber: null,
            });

            resolve({
              policyNumber: claimResponse.data[0].PolicyNumber,
              policySymbol: (claimResponse.data[0].PolicySymbol !== '' && claimResponse.data[0].PolicySymbol !== 'Unknown' ? claimResponse.data[0].PolicySymbol : claimResponse.data[0].PolicyType),
              policyType: claimResponse.data[0].PolicyType,
              date: claimResponse.data[0].DateOfLoss,
              claimDestination: claimResponse.data[0].ClaimDestination,
            });
          }

          reject(new Error(namePolicyNotFoundErrorMessage));
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'claims.js', methodName: 'validateClaim-Error', trackerId: validation.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getClaimPointSignedXml(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'claims.js', methodName: 'getClaimPointSignedXml-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const claimPointRequest = {
        Recipient: sRequest.sRecipient,
        PolicyNumber: sRequest.sPolicyNumber,
        LastName: sRequest.sLastName,
        DateOfLoss: sRequest.sDateOfLoss,
        FirstName: sRequest.sFirstName,
        InsuredName: sRequest.sInsuredName,
      };
      let claimToken = Vue.$cookies.get(claimTokenName);

      if (!claimToken || claimToken === '') {
        claimToken = Vue.$cookies.get(tokenName);
      }
      config.headers.Authorization = `Bearer ${claimToken}`;
      axios
        .post(`${claimLookupServiceUrl}/claimpoint/signed-xml `, claimPointRequest, config)
        .then((claimResponse) => {
          if (claimResponse && claimResponse.data) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(claimResponse.data), fileName: 'claims.js', methodName: 'getClaimPointSignedXml-Result', trackerId: sRequest.sTfbId,
            }, { root: true });
            resolve(claimResponse.data);
          }

          reject(new Error(namePolicyNotFoundErrorMessage));
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'claims.js', methodName: 'getClaimPointSignedXml-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  buildPolicy(context, policy) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(policy), fileName: 'claims.js', methodName: 'buildPolicy-Start', trackerId: policy.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      let claimToken = Vue.$cookies.get(claimTokenName);

      if (!claimToken || claimToken === '') {
        claimToken = Vue.$cookies.get(tokenName);
      }
      config.headers.Authorization = `Bearer ${claimToken}`;
      axios
        .get(`${claimLookupServiceUrl}/policy/${policy.policyNumber}/${policy.policySymbol}/${policy.date}`, config)
        .then((claimResponse) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(claimResponse), fileName: 'claims.js', methodName: 'buildPolicy-Result', trackerId: policy.sTfbId,
          }, { root: true });
          if (claimResponse && claimResponse.data) {
            const foundDrivers = [];
            const foundVehicles = [];
            context.commit('SET_CLAIM', {
              destination: (policy.claimDestination && policy.claimDestination !== null ? policy.claimDestination : claimResponse.data.ClaimDestination),
              type: claimResponse.data.ClaimType,
              effectiveDate: claimResponse.data.EffectiveDate,
              expirationDate: claimResponse.data.ExpirationDate,
              memberNumber: claimResponse.data.MemberNumber,
              policyNumber: claimResponse.data.PolicyNumber,
              policySymbol: (claimResponse.data.PolicySymbol !== '' && claimResponse.data.PolicySymbol !== 'Unknown' ? claimResponse.data.PolicySymbol : claimResponse.data.PolicyType),
              policyType: claimResponse.data.PolicyType,
              policySubtype: claimResponse.data.PolicySubType,
              externalIdValTxt: claimResponse.data.ExternalIdValTxt,
              companyName: claimResponse.data.CompanyName,
              corporationName: claimResponse.data.CorporationName,
              hasPhotos: 'N',
            });
            if (claimResponse.data.PrimaryInsured) {
              context.commit('SET_PRIMARY_INSURED', {
                objectId: '',
                emailAddress: claimResponse.data.PrimaryInsured.EmailAddress,
                name: {
                  firstName: claimResponse.data.PrimaryInsured.Name ? claimResponse.data.PrimaryInsured.Name.FirstName : '',
                  lastName: claimResponse.data.PrimaryInsured.Name ? claimResponse.data.PrimaryInsured.Name.LastName : '',
                  suffix: claimResponse.data.PrimaryInsured.Name ? claimResponse.data.PrimaryInsured.Name.Suffix : '',
                  displayName: claimResponse.data.PrimaryInsured.Name ? claimResponse.data.PrimaryInsured.Name.DisplayName : '',
                },
                insuredType: claimResponse.data.PrimaryInsured.InsuredType,
                gender: claimResponse.data.PrimaryInsured.Gender,
                maritalStatus: claimResponse.data.PrimaryInsured.MaritalStatus,
                dateOfBirth: claimResponse.data.PrimaryInsured.DateOfBirth,
                address: {
                  addressLine1: claimResponse.data.PrimaryInsured.Address ? claimResponse.data.PrimaryInsured.Address.AddressLine1 : '',
                  addressLine2: claimResponse.data.PrimaryInsured.Address ? claimResponse.data.PrimaryInsured.Address.AddressLine2 : '',
                  city: claimResponse.data.PrimaryInsured.Address ? claimResponse.data.PrimaryInsured.Address.City : '',
                  state: claimResponse.data.PrimaryInsured.Address ? claimResponse.data.PrimaryInsured.Address.State : '',
                  zip: claimResponse.data.PrimaryInsured.Address ? claimResponse.data.PrimaryInsured.Address.Zip : '',
                },
                primaryInd: claimResponse.data.PrimaryInd,
                phone: claimResponse.data.PrimaryInsured.Phone,
                phoneType: claimResponse.data.PrimaryInsured.PhoneType,
                licenseNumber: claimResponse.data.PrimaryInsured.LicenseNumber,
                licenseState: claimResponse.data.PrimaryInsured.LicenseState,
                licenseClass: claimResponse.data.PrimaryInsured.LicenseClass,
              });
            }

            if (claimResponse.data.Drivers && claimResponse.data.Drivers.length > 0) {
              for (let d = 0; d < claimResponse.data.Drivers.length; d += 1) {
                const foundDriver = claimResponse.data.Drivers[d];
                foundDrivers.push({
                  injuryInd: null,
                  injuryDescription: null,
                  objectId: '',
                  emailAddress: foundDriver.EmailAddress,
                  name: {
                    firstName: foundDriver.Name ? foundDriver.Name.FirstName : '',
                    lastName: foundDriver.Name ? foundDriver.Name.LastName : '',
                    suffix: foundDriver.Name ? foundDriver.Name.Suffix : '',
                    displayName: foundDriver.Name ? foundDriver.Name.DisplayName : '',
                  },
                  insuredType: foundDriver.InsuredType,
                  gender: foundDriver.Gender,
                  maritalStatus: foundDriver.MaritalStatus,
                  dateOfBirth: foundDriver.DateOfBirth,
                  address: {
                    addressLine1: foundDriver.Address ? foundDriver.Address.AddressLine1 : '',
                    addressLine2: foundDriver.Address ? foundDriver.Address.AddressLine2 : '',
                    city: foundDriver.Address ? foundDriver.Address.City : '',
                    state: foundDriver.Address ? foundDriver.Address.State : '',
                    zip: foundDriver.Address ? foundDriver.Address.Zip : '',
                  },
                  primaryInd: foundDriver.PrimaryInd,
                  phone: foundDriver.Phone,
                  phoneType: foundDriver.PhoneType,
                  licenseNumber: foundDriver.LicenseNumber,
                  licenseState: foundDriver.LicenseState,
                  licenseClass: foundDriver.LicenseClass,
                });
                context.commit('SET_DRIVERS', foundDrivers);
              }
            }

            if (claimResponse.data.Vehicles && claimResponse.data.Vehicles.length) {
              for (let v = 0; v < claimResponse.data.Vehicles.length; v += 1) {
                const foundVehicle = claimResponse.data.Vehicles[v];
                foundVehicles.push({
                  objectId: '',
                  licensePlate: foundVehicle.LicensePlate,
                  licensePlateState: '',
                  id: foundVehicle.Id,
                  seqNum: foundVehicle.SeqNum && foundVehicle.SeqNum !== null ? foundVehicle.SeqNum : v.toString(),
                  address: {
                    addressLine1: foundVehicle.Address ? foundVehicle.Address.AddressLine1 : '',
                    addressLine2: foundVehicle.Address ? foundVehicle.Address.AddressLine2 : '',
                    city: foundVehicle.Address ? foundVehicle.Address.City : '',
                    state: foundVehicle.Address ? foundVehicle.Address.State : '',
                    zip: foundVehicle.Address ? foundVehicle.Address.Zip : '',
                  },
                  year: foundVehicle.Year,
                  make: foundVehicle.Make,
                  model: foundVehicle.Model,
                  vin: foundVehicle.Vin,
                  bodyStyle: foundVehicle.BodyStyle,
                  displayName: `${foundVehicle.Year} ${foundVehicle.Make} ${foundVehicle.Model}`,
                  isDriveable: null,
                  currentLocation: null,
                  externalIdValTxt: foundVehicle.ExternalIdValTxt,
                  effectiveDate: foundVehicle.EffectiveDate,
                  expirationDate: foundVehicle.ExpirationDate,
                  damageLocations: [],
                  ownerInformation: {
                    firstName: '',
                    lastName: '',
                    objectId: '',
                  },
                });
                context.commit('SET_VEHICLES', foundVehicles);
              }
            }

            resolve(true);
          } else {
            reject(new Error(namePolicyNotFoundErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'claims.js', methodName: 'buildPolicy-Error', trackerId: policy.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  submitClaim(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'claims.js', methodName: 'submitClaim-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      context.commit('SET_CLAIM', sRequest.claim);
      context.commit('SET_LOSS', sRequest.loss);
      context.commit('SET_REPORTER', sRequest.reporter);
      context.commit('SET_INSUREDS', sRequest.insureds);
      context.commit('SET_THIRD_PARTIES', sRequest.thirdParties);

      let fnolServiceUrl = `${claimModifyServiceUrl}/fnol/`;
      if (sRequest.claim.type === 0) {
        fnolServiceUrl += 'auto';
      } else {
        fnolServiceUrl += 'property';
      }

      if (sRequest.preferredPhone && sRequest.preferredPhone !== '') {
        context.state.primaryInsured.phone = helpers.methods.removePhoneFormatting(sRequest.preferredPhone);
      }

      if (sRequest.preferredPhoneType && sRequest.preferredPhoneType !== '') {
        context.state.primaryInsured.phoneType = sRequest.preferredPhoneType;
      }

      let reporterName = sRequest.reporter.name;
      if (sRequest.reporter.onBehalfOf && sRequest.reporter.onBehalfOf !== '') {
        reporterName = `${sRequest.reporter.name} for ${sRequest.reporter.onBehalfOf}`;
      }

      let claimToken = Vue.$cookies.get(claimTokenName);

      if (!claimToken || claimToken === '') {
        claimToken = Vue.$cookies.get(tokenName);
      }
      config.headers.Authorization = `Bearer ${claimToken}`;
      const fnolRequest = {
        ClaimDestination: sRequest.claim.destination,
        ClaimType: sRequest.claim.type,
        EffectiveDate: sRequest.claim.effectiveDate,
        ExpirationDate: sRequest.claim.expirationDate,
        MemberNumber: sRequest.claim.memberNumber,
        PolicyNumber: sRequest.claim.policyNumber,
        PolicySymbol: sRequest.claim.policySymbol,
        PolicyType: sRequest.claim.policyType,
        PolicySubType: sRequest.claim.policySubtype,
        ExternalIdValTxt: sRequest.claim.externalIdValTxt,
        CompanyName: sRequest.claim.companyName,
        CorporationName: sRequest.claim.corporationName,
        HasPhotos: sRequest.claim.hasPhotos || 'N',
        PrimaryInsured: context.state.primaryInsured,
        ReporterInformation: {
          Name: reporterName,
          Type: sRequest.reporter.type,
          PhoneNumber: helpers.methods.removePhoneFormatting(sRequest.reporter.phoneNumber),
          PhoneType: sRequest.reporter.phoneType || '',
          EmailAddress: sRequest.reporter.emailAddress,
          ReportingSource: sRequest.reporter.reportingSource,
        },
        LossInformation: {
          DateOfLoss: sRequest.loss.date,
          TimeOfLoss: sRequest.loss.time,
          TypeOfLoss: sRequest.loss.type,
          LossDescription: sRequest.loss.description ? sRequest.loss.description : '',
          Location: sRequest.loss.location,
          City: sRequest.loss.city,
          State: sRequest.loss.state || ' ',
          County: sRequest.loss.county || ' ',
          PoliceDepartment: sRequest.loss.policeDepartment || ' ',
          PoliceCaseNumber: sRequest.loss.policeCaseNumber || ' ',
        },
      };

      if (sRequest.claim.type === 0) {
        const insuredObjects = [];
        const thirdPartyObjects = [];
        for (let i = 0; i < sRequest.insureds.length; i += 1) {
          const insured = sRequest.insureds[i];
          const insuredObject = {
            Driver: {
              Address: {
                AddressLine1: insured.driver.address && insured.driver.address.addressLine1 ? insured.driver.address.addressLine1 : '',
                AddressLine2: insured.driver.address && insured.driver.address.addressLine2 ? insured.driver.address.addressLine2 : '',
                City: insured.driver.address && insured.driver.address.city ? insured.driver.address.city : '',
                State: insured.driver.address && insured.driver.address.state ? insured.driver.address.state : '',
                Zip: insured.driver.address && insured.driver.address.zip ? insured.driver.address.zip : '',
              },
              DateOfBirth: insured.driver.dateOfBirth,
              EmailAddress: insured.driver.emailAddress,
              Gender: insured.driver.gender,
              InjuryDescription: insured.driver.injuryDescription || '',
              InjuryInd: insured.driver.injuryInd || 'N',
              InsuredType: insured.driver.insuredType,
              LicenseClass: '',
              LicenseNumber: insured.driver.licenseNumber,
              LicenseState: insured.driver.licenseState || '',
              MaritalStatus: insured.driver.maritalStatus,
              Name: {
                // eslint-disable-next-line no-nested-ternary
                DisplayName: insured.driver.name
                  ? (insured.driver.name.displayName === 'Other' ? `${insured.driver.name.firstName} ${insured.driver.name.lastName}` : insured.driver.name.displayName)
                  : '',
                FirstName: insured.driver.name ? insured.driver.name.firstName : '',
                LastName: insured.driver.name ? insured.driver.name.lastName : '',
                Suffix: insured.driver.name ? insured.driver.name.suffix : '',
              },
              ObjectId: insured.driver.objectId,
              Phone: helpers.methods.removePhoneFormatting(insured.driver.phone),
              PhoneType: insured.driver.phoneType || '',
              PrimaryInd: insured.driver.primaryInd,
            },
            Vehicle: {
              Address: {
                AddressLine1: insured.vehicle.address ? insured.vehicle.address.addressLine1 : '',
                AddressLine2: insured.vehicle.address ? insured.vehicle.address.addressLine2 : '',
                City: insured.vehicle.address ? insured.vehicle.address.city : '',
                State: insured.vehicle.address ? insured.vehicle.address.state : '',
                Zip: insured.vehicle.address ? insured.vehicle.address.zip : '',
              },
              BodyStyle: insured.vehicle.bodyStyle,
              CurrentLocation: insured.vehicle.currentLocation ? insured.vehicle.currentLocation : '',
              DamageLocations: [],
              EffectiveDate: insured.vehicle.effectiveDate,
              ExpirationDate: insured.vehicle.expirationDate,
              ExternalIdValTxt: insured.vehicle.externalIdValTxt,
              Id: insured.vehicle.id,
              IsDriveable: insured.vehicle.isDriveable,
              LicensePlate: insured.vehicle.licensePlate || '',
              LicensePlateState: insured.vehicle.licensePlateState,
              Make: insured.vehicle.make,
              Model: insured.vehicle.model,
              ObjectId: insured.vehicle.objectId,
              SeqNum: insured.vehicle.seqNum,
              Vin: insured.vehicle.vin,
              Year: insured.vehicle.year,
            },
          };
          insuredObjects.push(insuredObject);
        }
        for (let t = 0; t < sRequest.thirdParties.length; t += 1) {
          const thirdParty = sRequest.thirdParties[t];
          const thirdPartyObject = {
            Driver: {
              Address: {
                AddressLine1: thirdParty.driver.address ? thirdParty.driver.address.addressLine1 : '',
                AddressLine2: '',
                City: thirdParty.driver.address ? thirdParty.driver.address.city : '',
                State: thirdParty.driver.address ? thirdParty.driver.address.state : '',
                Zip: thirdParty.driver.address ? thirdParty.driver.address.zip : '',
              },
              DateOfBirth: '',
              EmailAddress: thirdParty.driver.emailAddress,
              Gender: '',
              InjuryDescription: thirdParty.driver.injuryDescription || '',
              InjuryInd: thirdParty.driver.injuryInd || 'N',
              InsuredType: 'Person',
              LicenseClass: thirdParty.driver.licenseClass,
              LicenseNumber: thirdParty.driver.licenseNumber,
              LicenseState: thirdParty.driver.licenseState,
              MaritalStatus: '',
              Name: {
                DisplayName: thirdParty.driver.name ? `${thirdParty.driver.name.firstName} ${thirdParty.driver.name.lastName}` : '',
                FirstName: thirdParty.driver.name ? thirdParty.driver.name.firstName : '',
                LastName: thirdParty.driver.name ? thirdParty.driver.name.lastName : '',
                Suffix: '',
              },
              ObjectId: thirdParty.driver.objectId,
              Phone: helpers.methods.removePhoneFormatting(thirdParty.driver.phoneNumber),
              PhoneType: thirdParty.driver.phoneType || '',
              PrimaryInd: 'false',
            },
            Vehicle: {
              Address: {
                AddressLine1: thirdParty.vehicle.address ? thirdParty.vehicle.address.addressLine1 : '',
                AddressLine2: thirdParty.vehicle.address ? thirdParty.vehicle.address.addressLine2 : '',
                City: thirdParty.vehicle.address ? thirdParty.vehicle.address.city : '',
                State: thirdParty.vehicle.address ? thirdParty.vehicle.address.state : '',
                Zip: thirdParty.vehicle.address ? thirdParty.vehicle.address.zip : '',
              },
              BodyStyle: thirdParty.vehicle.bodyStyle,
              CurrentLocation: thirdParty.vehicle.currentLocation || '',
              DamageLocations: [],
              ExternalIdValTxt: '',
              Id: '',
              IsDriveable: thirdParty.vehicle.isDriveable,
              LicensePlate: thirdParty.vehicle.licensePlate || '',
              LicensePlateState: thirdParty.vehicle.licensePlateState,
              Make: thirdParty.vehicle.make,
              Model: thirdParty.vehicle.model,
              ObjectId: thirdParty.vehicle.objectId,
              OwnerInformation: {
                FirstName: thirdParty.vehicle.ownerInformation ? thirdParty.vehicle.ownerInformation.firstName : '',
                LastName: thirdParty.vehicle.ownerInformation ? thirdParty.vehicle.ownerInformation.lastName : '',
                ObjectId: thirdParty.vehicle.ownerInformation ? thirdParty.vehicle.ownerInformation.objectId : '',
              },
              SeqNum: thirdParty.vehicle.seqNum,
              Vin: '',
              Year: thirdParty.vehicle.year,
            },
          };
          thirdPartyObjects.push(thirdPartyObject);
        }

        fnolRequest.InsuredInformation = insuredObjects;
        fnolRequest.ThirdPartyInformation = thirdPartyObjects;
      }
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: JSON.stringify(fnolRequest), fileName: 'claims.js', methodName: 'submitClaim-SendRequest', trackerId: sRequest.sTfbId,
      }, { root: true });

      axios
        .post(fnolServiceUrl, fnolRequest, config)
        .then(
          (claimSubmissionResponse) => {
            if (claimSubmissionResponse && claimSubmissionResponse.data && claimSubmissionResponse.data.SubmissionStatus === 'SUCCESS') {
              if ((sRequest.claim.policySymbol === 'PA6' || sRequest.claim.policyType === 'PA6'
                || (sRequest.claim.policyType === 'SM' && sRequest.claim.policySubType === '1'))
                && (sRequest.loss.type.toUpperCase() === 'GLASS REPAIR' || sRequest.loss.type.toUpperCase() === 'GLASS REPLACE')) {
                sRequest.claim.destination = 0;
                context.commit('SET_CLAIM', sRequest.claim);
              }

              if (sRequest.claim.destination === 0) {
                context.commit('SET_CONFIRMATION_NUMBER', claimSubmissionResponse.data.claimNumber);
              } else {
                context.commit('SET_CONFIRMATION_NUMBER', claimSubmissionResponse.data.ReferenceNumber);
              }
              context.commit('SET_CLAIM_ADJUSTER', claimSubmissionResponse.data.Adjuster);
              if (sRequest.files && sRequest.files.length > 0) {
                context.commit('SET_SELECTED_FILES', sRequest.files);
              }
            }
              resolve(claimSubmissionResponse);
          },
        )
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'claims.js', methodName: 'submitClaim-Catch', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  async uploadFiles(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: `${sRequest.claimId}, ${sRequest.file.name}`, fileName: 'claims.js', methodName: 'uploadFiles-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    try {
      let fnolServiceUrl = `${claimModifyServiceUrl}/`;
      if (sRequest.destination === 0) {
        fnolServiceUrl += `fnol/photos/add/${sRequest.claimId}`;
      } else {
        fnolServiceUrl += `photos/add/${sRequest.claimId}`;
      }
      let claimToken = Vue.$cookies.get(claimTokenName);

      if (!claimToken || claimToken === '') {
        claimToken = Vue.$cookies.get(tokenName);
      }
      config.headers.Authorization = `Bearer ${claimToken}`;
      config.headers['Content-Type'] = 'multipart/form-data';

      const uploadFileRequest = new FormData();
      uploadFileRequest.append('file', sRequest.file);

      axios.post(fnolServiceUrl, uploadFileRequest, config)
        .then(
          () => {
            context.commit('SET_UPLOADED_FILES', {
              name: sRequest.file.name,
            });
          },
      )
        .catch((error) => {
          context.commit('SET_ERROR_FILES', {
            name: sRequest.file.name,
          });
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'claims.js', methodName: 'uploadFiles.Service-Catch', trackerId: sRequest.sTfbId,
          }, { root: true });
        });
    } catch (error) {
      context.commit('SET_ERROR_FILES', {
        name: sRequest.file.name,
      });
      context.dispatch('app/logToULS', {
        logLevel: 'Error', logMessage: error, fileName: 'claims.js', methodName: 'uploadFiles-Catch', trackerId: sRequest.sTfbId,
      }, { root: true });
    }
  },
  getLossTypes(context) {
    return new Promise((resolve) => {
      let claimToken = Vue.$cookies.get(claimTokenName);

      if (!claimToken || claimToken === '') {
        claimToken = Vue.$cookies.get(tokenName);
      }
      config.headers.Authorization = `Bearer ${claimToken}`;
      axios
        .get(`${claimLookupServiceUrl}/lookup/loss-types/${context.state.claim.type}`, config)
        .then(
          (foundLossTypes) => {
            if (foundLossTypes && foundLossTypes.data && foundLossTypes.data.length > 0) {
              resolve(foundLossTypes.data);
            } else {
              resolve(null);
            }
          },
        );
    });
  },
  getPhoneTypes() {
    return new Promise((resolve) => {
      let claimToken = Vue.$cookies.get(claimTokenName);

      if (!claimToken || claimToken === '') {
        claimToken = Vue.$cookies.get(tokenName);
      }
      config.headers.Authorization = `Bearer ${claimToken}`;
      axios
        .get(`${claimLookupServiceUrl}/lookup/phone-types`, config)
        .then(
          (foundLossTypes) => {
            if (foundLossTypes && foundLossTypes.data && foundLossTypes.data.length > 0) {
              resolve(foundLossTypes.data);
            } else {
              resolve(null);
            }
          },
        );
    });
  },
  getReporterTypes() {
    return new Promise((resolve) => {
      let claimToken = Vue.$cookies.get(claimTokenName);

      if (!claimToken || claimToken === '') {
        claimToken = Vue.$cookies.get(tokenName);
      }
      config.headers.Authorization = `Bearer ${claimToken}`;
      axios
        .get(`${claimLookupServiceUrl}/lookup/reporter-types`, config)
        .then(
          (foundLossTypes) => {
            if (foundLossTypes && foundLossTypes.data && foundLossTypes.data.length > 0) {
              resolve(foundLossTypes.data);
            } else {
              resolve(null);
            }
          },
        );
    });
  },
  getCountyList() {
    return new Promise((resolve) => {
      let claimToken = Vue.$cookies.get(claimTokenName);

      if (!claimToken || claimToken === '') {
        claimToken = Vue.$cookies.get(tokenName);
      }
      config.headers.Authorization = `Bearer ${claimToken}`;
      axios
        .get(`${claimLookupServiceUrl}/lookup/counties`, config)
        .then(
          (foundLossTypes) => {
            if (foundLossTypes && foundLossTypes.data && foundLossTypes.data.length > 0) {
              resolve(foundLossTypes.data);
            } else {
              resolve(null);
            }
          },
        );
    });
  },
  getVehicleYears() {
    return new Promise((resolve) => {
      let claimToken = Vue.$cookies.get(claimTokenName);

      if (!claimToken || claimToken === '') {
        claimToken = Vue.$cookies.get(tokenName);
      }
      config.headers.Authorization = `Bearer ${claimToken}`;
      axios
        .get(`${claimLookupServiceUrl}/lookup/vehicle-years`, config)
        .then(
          (foundLossTypes) => {
            if (foundLossTypes && foundLossTypes.data && foundLossTypes.data.length > 0) {
              resolve(foundLossTypes.data);
            } else {
              resolve(null);
            }
          },
        );
    });
  },
  getVehicleMakesByYear(context, sRequest) {
    return new Promise((resolve) => {
      let claimToken = Vue.$cookies.get(claimTokenName);

      if (!claimToken || claimToken === '') {
        claimToken = Vue.$cookies.get(tokenName);
      }
      config.headers.Authorization = `Bearer ${claimToken}`;
      axios
        .get(`${claimLookupServiceUrl}/lookup/vehicle-makes/${sRequest.sYear}`, config)
        .then(
          (foundLossTypes) => {
            if (foundLossTypes && foundLossTypes.data && foundLossTypes.data.length > 0) {
              resolve(foundLossTypes.data);
            } else {
              resolve(null);
            }
          },
        );
    });
  },
  getVehicleModelsByYearMake(context, sRequest) {
    return new Promise((resolve) => {
      let claimToken = Vue.$cookies.get(claimTokenName);

      if (!claimToken || claimToken === '') {
        claimToken = Vue.$cookies.get(tokenName);
      }
      config.headers.Authorization = `Bearer ${claimToken}`;
      const modelSearchRequest = `{ "Make":"${sRequest.sMake}", "Year":"${sRequest.sYear}" }`;
      axios
        .post(`${claimLookupServiceUrl}/lookup/vehicle-models`, modelSearchRequest, config)
        .then(
          (foundLossTypes) => {
            if (foundLossTypes && foundLossTypes.data && foundLossTypes.data.length > 0) {
              resolve(foundLossTypes.data);
            } else {
              resolve(null);
            }
          },
        );
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
