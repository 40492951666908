<template>
  <div>
    <v-card v-bind:class="[ fixedWidth ? 'tfb-service-coverage-card-fixed' : 'tfb-service-coverage-card', 'mx-auto' ]"
            elevation="2">
      <v-card-title class="tfb-service-coverage-card-title text-center">
        <img v-bind:src="imageRootPath +'policy-icons/' + coverage.IconFileName"
             class="mx-auto my-1 tfb-service-coverage-icon"
             aria-hidden="true"
             v-bind:alt="coverage.Title"
             v-if="coverage.IconFileName && coverage.IconFileName !== '' && coverage.IconFileName !== null" />
      </v-card-title>
      <v-card-subtitle class="tfb-service-coverage-card-subtitle text-center">
        <h3 class="font-weight-bold text-darker-blue tfb-title-text-smaller text-uppercase">
          {{ coverage.Title }}
        </h3>
      </v-card-subtitle>
      <v-card-text class="tfb-service-coverage-card-text text-center">
        <p v-if="coverage.ShortDescription && coverage.ShortDescription !== '' && coverage.ShortDescription !== null">{{ coverage.ShortDescription }}</p>
      </v-card-text>
      <v-card-actions class="tfb-service-coverage-card-action text-center">
        <span class="font-italic tfb-service-coverage-card-require" v-if="coverage.CoverageTypeRequirement && coverage.CoverageTypeRequirement === 'Required'">Need to have!<br /></span>
        <span class="font-italic tfb-service-coverage-card-require" v-if="coverage.CoverageTypeRequirement && coverage.CoverageTypeRequirement === 'Preferred'">Nice to have!<br /></span>
        <v-hover v-if="coverage.LongDescription && coverage.LongDescription !== '' && coverage.LongDescription !== null">
          <v-btn slot-scope="{ hover }"
                 :class="`${hover? 'tfb-service-button-hover': ''} tfb-service-button mx-auto`"
                 outlined
                 @click.stop="modal = true">Learn More</v-btn>
        </v-hover>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="modal"
              max-width="600px">
      <v-card>
        <v-card-title class="background-darker-blue">
          <h3 class="tfb-title-text-small">{{coverage.Title}}</h3>
        </v-card-title>
        <v-card-text>
          <v-container class="tfb-component-container">
            <v-row>
              <v-col cols="12"
                     class="py-5">
                <span v-html="coverage.LongDescription"></span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'CoverageCard',
    props: {
      coverage: {
        type: Object,
        required: true,
      },
      fixedWidth: {
        type: Boolean,
        required: true,
        default: false,
      },
    },
    mixins: [tfbHelperMixin],
    data() {
      return {
        modal: false,
      };
    },
  };
</script>
<style lang="scss">

  .tfb-service-coverage-icon {
    max-height: 46px;
  }

  .tfb-service-coverage-card,
  .tfb-service-coverage-card-fixed {
    /*height: 275px;
    max-height: 275px;*/
    min-width: 280px;
  }

  .tfb-service-coverage-card-title {
    height: 86px;
  }

  .tfb-service-coverage-card-subtitle {
    height: 85px;
  }

  .tfb-service-coverage-card-text {
    height: 100px;
  }

  .tfb-service-coverage-card-action {
    text-align: center;
    /*height: 62px;*/
  }

    .tfb-service-coverage-card-action a {
      color: $tfb-blue;
      font-size: .75em;
    }

    .tfb-service-coverage-card-action.v-card__actions {
      display: block !important;
    }

  .tfb-service-coverage-card-require {
    font-size: 0.75rem !important;
  }

  @media (min-width: 960px) {
    .tfb-service-coverage-card-text {
      height: 85px;
    }

    .tfb-service-coverage-card {
      min-width: inherit;
    }
  }
</style>
