<template>
  <div v-if="asLowAsAmount && asLowAsAmount !== '0'">
    <v-row>
      <v-col cols="12">
        <h5 class="text-blue">Watch your monthly quote change as you fill in the information.</h5>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h3 class="text-uppercase text-red">
          Your Current Quote is {{asLowAsAmount}}
        </h3>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'AsLowAs',
    mixins: [tfbHelperMixin],
    computed: {
      asLowAsAmount() {
        try {
          return this.$store.getters['quotes/asLowAmount'];
        } catch (error) {
          return '0';
        }
      },
    },
  };
</script>
<style lang="scss">

</style>
