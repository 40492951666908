var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"main"}},[_c('div',{staticClass:"carousel-holder"},[(_vm.featuredPosts.length > 0)?_c('v-carousel',{attrs:{"cycle":"","hide-delimiters":"","show-arrows-on-hover":""}},_vm._l((_vm.featuredPosts),function(featuredPost,i){return _c('v-carousel-item',{key:i,attrs:{"src":featuredPost.headerImage}},[_c('div',{staticClass:"bottom-holder"},[_c('div',{staticClass:"blog-container"},[_c('router-link',{attrs:{"to":{ name: 'blogPost', params: { category: _vm.kebabify(featuredPost.urlCategory), title: _vm.kebabify(featuredPost.url) } },"hreflang":"en-us"}},[_c('span',{staticClass:"bottom-title"},[_vm._v(_vm._s(featuredPost.title))])])],1)])])}),1):_vm._e()],1),_c('v-container',{staticClass:"tfb-container event-holder",attrs:{"fluid":""}},[(!_vm.blogLoading && _vm.postRows.length > 0)?_c('div',_vm._l((_vm.postRows),function(postRow){return _c('div',{key:postRow.id},[(postRow.rowType === 1)?_c('v-row',{staticClass:"row-event"},_vm._l((postRow.posts),function(post){return _c('v-col',{key:post.id,staticClass:"box",attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"holder"},[_c('router-link',{staticClass:"link-stretched",attrs:{"to":{ name: 'blogPost', params: { category: _vm.kebabify(post.urlCategory), title: _vm.kebabify(post.url) } },"hreflang":"en-us"}}),_c('v-card',{attrs:{"height":"100%"}},[_c('v-img',{staticClass:"img-box",attrs:{"height":"200px","src":post.headerImage}}),_c('v-card-subtitle',{staticClass:"pb-0"},[(!post.subcategory || post.subcategory === null || post.subcategory === '')?_c('router-link',{staticClass:"category-link",attrs:{"to":{ name: 'blogFeedCategory', params: { category: _vm.kebabify(post.category) } },"hreflang":"en-us"}},[_vm._v(" "+_vm._s(post.category)+" ")]):_c('router-link',{staticClass:"category-link",attrs:{"to":{ name: 'blogFeedSubCategory',
                           params: { category: _vm.kebabify(post.category), subcategory: _vm.kebabify(post.subcategory) } },"hreflang":"en-us"}},[_vm._v(" "+_vm._s(post.subcategory)+" ")])],1),_c('v-card-text',{staticClass:"content-block"},[_c('h2',{staticClass:"title-post"},[_vm._v(_vm._s(post.title))]),_c('p',[_vm._v(" "+_vm._s(post.blurb)+" ")])])],1),_c('script',{attrs:{"type":"application/ld+json"},domProps:{"innerHTML":_vm._s(post.jsonld)}})],1)])}),1):_vm._e(),(postRow.rowType === 2 || postRow.rowType === 3)?_c('v-row',{staticClass:"row-event"},[_c('v-col',{staticClass:"box category-list-holder",attrs:{"cols":"12","md":"4","order":postRow.rowType === 2 ? 1 : 2}},[_c('div',{staticClass:"holder"},[_c('ul',{staticClass:"category-list"},_vm._l((postRow.posts.postArray),function(post){return _c('li',{key:post.id},[_c('router-link',{staticClass:"link-stretched",attrs:{"to":{ name: 'blogPost',
                       params: { category: _vm.kebabify(post.urlCategory),
                       title: _vm.kebabify(post.url) } },"hreflang":"en-us"}}),_c('v-img',{staticClass:"img-post",attrs:{"src":post.headerImage}}),_c('div',{staticClass:"title-container"},[(!post.subcategory || post.subcategory === null || post.subcategory === '')?_c('router-link',{staticClass:"category-link",attrs:{"to":{ name: 'blogFeedCategory', params: { category: _vm.kebabify(post.category) } },"hreflang":"en-us"}},[_vm._v(" "+_vm._s(post.category)+" ")]):_c('router-link',{staticClass:"category-link",attrs:{"to":{ name: 'blogFeedSubCategory',
                           params: { category: _vm.kebabify(post.category), subcategory: _vm.kebabify(post.subcategory) } },"hreflang":"en-us"}},[_vm._v(" "+_vm._s(post.subcategory)+" ")]),_c('strong',{staticClass:"title-post",staticStyle:{"overflow-wrap":"break-word"}},[_vm._v(_vm._s(post.title))])],1),_c('script',{attrs:{"type":"application/ld+json"},domProps:{"innerHTML":_vm._s(post.jsonld)}})],1)}),0)])]),_c('v-col',{staticClass:"box big-box",attrs:{"cols":"12","md":"8","order":postRow.rowType === 2 ? 2 : 1}},[(postRow.posts && postRow.posts.singlePost)?_c('div',{staticClass:"holder"},[_c('router-link',{staticClass:"link-stretched",attrs:{"to":{ name: 'blogPost',
                       params: { category: _vm.kebabify(postRow.posts.singlePost.urlCategory),
                       title: _vm.kebabify(postRow.posts.singlePost.url) } },"hreflang":"en-us"}}),_c('v-card',{attrs:{"height":"100%"}},[_c('v-img',{staticClass:"img-box",attrs:{"height":"200px","src":postRow.posts.singlePost.headerImage}}),_c('v-card-subtitle',{staticClass:"pb-0"},[(!postRow.posts.singlePost.subcategory || postRow.posts.singlePost.subcategory === null || postRow.posts.singlePost.subcategory === '')?_c('router-link',{staticClass:"category-link",attrs:{"to":{ name: 'blogFeedCategory', params: { category: _vm.kebabify(postRow.posts.singlePost.category) } },"hreflang":"en-us"}},[_vm._v(" "+_vm._s(postRow.posts.singlePost.category)+" ")]):_c('router-link',{staticClass:"category-link",attrs:{"to":{ name: 'blogFeedSubCategory',
                           params: { category: _vm.kebabify(postRow.posts.singlePost.category), subcategory: _vm.kebabify(postRow.posts.singlePost.subcategory) } },"hreflang":"en-us"}},[_vm._v(" "+_vm._s(postRow.posts.singlePost.subcategory)+" ")])],1),_c('v-card-text',{staticClass:"content-block"},[_c('h2',{staticClass:"title-post"},[_vm._v(_vm._s(postRow.posts.singlePost.title))]),_c('p',[_vm._v(" "+_vm._s(postRow.posts.singlePost.blurb)+" ")])])],1),_c('script',{attrs:{"type":"application/ld+json"},domProps:{"innerHTML":_vm._s(postRow.posts.singlePost.jsonld)}})],1):_vm._e()])],1):_vm._e()],1)}),0):_vm._e(),(!_vm.blogLoading && _vm.postRows.length > 0 && !_vm.moreDisabled)?_c('v-row',[_c('v-col',{staticClass:"align-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"tfb-button-min background-red btn-load",attrs:{"block":"","loading":_vm.blogMoreLoading},on:{"click":function($event){return _vm.loadMorePosts()}}},[_vm._v("Load More")])],1)],1):_vm._e(),(!_vm.blogLoading && (!_vm.postRows || _vm.postRows.length === 0))?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" No blog posts found ")])],1):_vm._e(),(_vm.blogLoading)?_c('v-row',_vm._l((3),function(i){return _c('v-col',{key:i,attrs:{"cols":"12","md":"4"}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"max-width":"300","type":"card"}})],1)}),1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }