<template>
  <div itemscope
       itemtype="http://schema.org/InsuranceAgency">
    <div class="tfb-about-header-photo"
         :style="getHeaderBackgroundImage(headerImage)"
         itemprop="image"/>
    <v-container fluid
                 class="tfb-about-header-overlay tfb-smaller-container">
      <v-row class="pb-auto pb-lg-16">
        <v-col cols="12"
               class="text-center">
          <img v-bind:src="`${imageRootPath}/who-we-are-header.png`"
               class="tfb-small-drop-shadow"
               aria-hidden="true"
               alt="Who We Are"/>
          <p class="tfb-title-text-medium text-darker-blue">
            We are an insurance provider built by Texans for Texans.
            We have been protecting families in our great state since 1952.
          </p>
        </v-col>
      </v-row>
      <v-row class="pt-auto pt-lg-16">
        <v-col cols="12"
               md="6"
               class="align-center">
          <v-btn class="tfb-button-fixed background-darkest-blue"
                 hreflang="en-us"
                 :to="{ name: 'insurance', params: { type: 'auto' }}">
            Learn About Our Insurance Offerings Here
          </v-btn>
        </v-col>
        <v-col cols="12"
               md="6"
               class="align-center">
          <v-btn class="tfb-button-fixed background-red"
                 hreflang="en-us"
                 :to="{ name: 'careers' }">Learn About Joining Our Team Here</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid
                 class="tfb-smaller-container">
      <v-row class="mt-auto mt-md-12">
        <v-col cols="12"
               class="text-center">
          <h2 class="tfb-title-text-medium text-darker-blue">
            Our Mission
          </h2>
          <p>
            We help our policyholders manage the financial risks of everyday life and successfully
            recover from insured losses through prompt, professional, personal service based on
            high ethical standards.
          </p>
        </v-col>
      </v-row>
      <v-row class="mb-12">
        <v-col cols="12"
               md="6">
          <span class="tfb-title-text-smaller text-darker-blue text-uppercase">
            We are:
          </span>
          <ul>
            <li>Headquartered in Waco, Texas</li>
            <li><span class="font-weight-bold">850+</span> multi-line Agents</li>
            <li><span class="font-weight-bold">300+</span> claims personnel</li>
            <li><span class="font-weight-bold">300</span> offices statewide</li>
            <li>Consistently ranked &ldquo;excellent&rdquo; by A.M. Best</li>
            <li>
              Serving more than <span class="font-weight-bold">500,000</span>
              policyholders and their families
            </li>
          </ul>
        </v-col>
        <v-col cols="12"
               md="6">
          <span class="tfb-title-text-smaller text-darker-blue text-uppercase">
            We are affiliated with:
          </span>
          <ul>
            <li>Texas Farm Bureau Mutual Insurance Company</li>
            <li>Texas Farm Bureau Underwriters</li>
            <li>Texas Farm Bureau Casualty Insurance Company</li>
            <li>Farm Bureau County Mutual Insurance Company of Texas</li>
            <li>Southern Farm Bureau Life Insurance Company</li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
    <div class="pt-12 background-lightest-gray">
      <v-container fluid
                   class="tfb-smaller-container">
        <v-row>
          <v-col cols="12">
            <h2 class="tfb-title-text-medium text-darker-blue text-center mb-4">
              <a href="https://texasfarmbureau.org/"
                 hreflang="en-us"
                 title="Texas Farm Bureau"
                 rel="noopener"
                 target="_blank"
                 class="footer-link">
                <img v-bind:src="`${imageRootPath}/tfb-org-logo.png`"
                     id="tfb-org-log"
                     aria-hidden="true"
                     alt="Our Parent Company" />
              </a><br />
              Our Parent Company
            </h2>
            <p class="my-5">
              As the unified voice of Texas agriculture, Texas Farm Bureau functions as a member of
              Farm Bureau, a national grassroots organization with memberships across America that
              focus on building strong, prosperous agricultural communities.
            </p>
            <p class="my-5">
              Texas Farm Bureau is an independent, nongovernmental, voluntary organization governed
              by the people it represents. Its purpose is to enhance and strengthen the lives and
              well-being of rural Texans by working to improve education, economic opportunity, and
              social advancement. Texas Farm Bureau&rsquo;s influence is local, county, and statewide.
              As the voice of agricultural producers at all levels, it functions as an open,
              nonpartisan, nonsectarian organization.
            </p>
            <p class="my-5">
              Texas Farm Bureau Insurance builds and manages a financially strong, efficient, and
              member-oriented operation that supports the objectives and membership growth goals
              of the Farm Bureau parent organization.
            </p>
          </v-col>
        </v-row>
      </v-container>
      <jd-power-banner banner-type="image"></jd-power-banner>
    </div>
    <meet-agents></meet-agents>
    <services></services>
    <videos :video-array="aboutUsVideos"></videos>
    <blog-feed></blog-feed>
  </div>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import JdPowerBanner from '@/views/layout/components/JDPowerBanner.vue';
  import MeetAgents from '@/views/agents/components/MeetAgents.vue';
  import Services from '@/views/services/components/ServicesCards.vue';
  import Videos from '@/views/content/components/Video.vue';
  import BlogFeed from '@/views/blog/components/BlogFeed.vue';
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbMetaMixin from '@/mixins/metaInfo';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'AboutUs',
    mixins: [tfbDataMixin, tfbMetaMixin, tfbHelperMixin],
    components: {
      JdPowerBanner,
      MeetAgents,
      Services,
      Videos,
      BlogFeed,
    },
    data() {
      return {
        aboutUsVideos: [],
      };
    },
    computed: {
      metaPageTitle() {
        return 'About Us';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/about-us`;
      },
      headerImage() {
        return `${process.env.VUE_APP_ROOT_URI}/publishingimages/about-us-header-banner.jpg`;
      },
    },
    async created() {
      dispatch('app/clearErrorMessage');
      dispatch('app/getVideosByPage', { sPage: 'about', sTfbId: this.trackingId })
        .then((response) => {
          this.aboutUsVideos = response;
        })
        .catch((error) => {
          dispatch('app/setErrorMessage', error.toString());
        });
    },
  };
</script>
<style lang="scss">


  .tfb-about-header-photo {
    background-size: cover;
    background-position: center;
    min-height: 382px;
  }

  @media (min-width: 960px) {
    .tfb-about-header-photo {
      min-height: 482px;
    }
  }

  @media (min-width: 1264px) {
    .tfb-about-header-photo {
      min-height: 682px;
    }
  }

  @media print {
    .tfb-about-header-photo {
      display: none !important
    }
  }

  .tfb-about-header-overlay {
    margin-top: -125px;
  }

  @media (min-width: 960px) {
    .tfb-about-header-overlay {
      margin-top: -425px;
    }
  }

  @media (min-width: 1264px) {
    .tfb-about-header-overlay {
      margin-top: -625px;
  margin-bottom: 100px;
    }
  }
</style>
