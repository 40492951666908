<template>
  <div id="mobile-header-navigation">
    <v-container fluid
                 class="tfb-borderless-container">
      <v-row no-gutters>
        <v-col cols="1"
               align-self="center"
               class="py-0 pl-2">
          <v-app-bar-nav-icon @click="drawer = true"
                              aria-label="Navigation Menu"></v-app-bar-nav-icon>
        </v-col>
        <v-col cols="7"
               sm="9"
               align-self="center"
               class="py-0">
          <router-link :to="{ name: 'home' }">
            <v-img max-height="70"
                   alt="Texas Farm Bureau Insurance"
                   class="tfb-logo"
                   itemprop="logo"
                   v-bind:src="imageRootPath +'tfb-logo.jpg'"
                   contain></v-img>
          </router-link>
        </v-col>
        <v-col cols="4"
               sm="2"
               align-self="center"
               class="ml-auto py-0 pr-2 text-right text-md-left">
          <v-icon color="tfb-blue"
                  @click="accountSummary"
                  aria-label="Go to Account Summary">mdi-account</v-icon>
          <a v-on:click="logout"
             v-if="isLoggedIn">LOG OUT</a>
          <router-link :to="{ name: 'policyholderLogin' }"
                       hreflang="en-us"
                       v-else>LOG IN</router-link>
        </v-col>
      </v-row>
    </v-container>
    <v-navigation-drawer v-model="drawer"
                         temporary
                         app
                         class="tfb-navbar-mobile">
      <v-list nav>
        <span v-for="link in headerLinks"
              v-bind:key="link.id">
          <span v-if="link.children">
            <v-list-group>
              <template v-slot:activator>
                <v-list-item-title class="tfb-navbar-navlink">{{link.text}}</v-list-item-title>
              </template>
              <v-list-item v-for="childLink in link.children"
                           v-bind:key="childLink.id"
                           class="px-4">
                <router-link :to="{ name: childLink.to, params: { type: childLink.param }}"
                             hreflang="en-us"
                             class="tfb-navbar-navlink">{{childLink.text}}</router-link>
              </v-list-item>
            </v-list-group>
          </span>
          <span v-else>
            <v-list-item>
              <span v-if="link.param != ''">
                <router-link :to="{ name: link.to, params: { type: link.param }}"
                             hreflang="en-us"
                             class="tfb-navbar-navlink">{{link.text}}</router-link>
              </span>
              <span v-else>
                <router-link :to="{ name: link.to }"
                             hreflang="en-us"
                             class="tfb-navbar-navlink">{{link.text}}</router-link>
              </span>
            </v-list-item>
          </span>
        </span>
      </v-list>
      <template v-slot:append>
        <v-divider />
        <div class="align-center">
          <v-icon color="tfb-blue">mdi-phone</v-icon>
          <a :href="primaryPhone.link"
             hreflang="en-us"
             class="mx-auto tfb-navbar-text">
            {{primaryPhone.display}}
          </a>
        </div>
        <div class="pa-3 pb-10 row-header">
          <blue-navigation></blue-navigation>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>
<script>
  import { dispatch, sync } from 'vuex-pathify';
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbHelperMixin from '@/mixins/helpers';
  import BlueNavigation from './QuickLinksHeaderNavigation.vue';

  export default {
    name: 'MobileNavigation',
    mixins: [tfbDataMixin, tfbHelperMixin],
    components: {
      BlueNavigation,
    },
    computed: {
      headerLinks() {
        return [
          {
            id: 0,
            text: 'Insurance',
            to: '',
            param: '',
            children: [
              {
                text: 'Auto',
                to: 'insurance',
                param: 'auto',
              },
              {
                text: 'Home',
                to: 'insurance',
                param: 'home',
              },
              {
                text: 'Farm & Ranch',
                to: 'insurance',
                param: 'farm-ranch',
              },
              {
                text: 'Commercial',
                to: 'insurance',
                param: 'commercial',
              },
              {
                text: 'Life',
                to: 'insurance',
                param: 'life',
              },
            ],
          },
          {
            id: 1,
            text: 'Quote',
            to: 'quoteEntry',
            param: 'auto',
            children: null,
          },
          {
            id: 2,
            text: 'Claims',
            to: 'claims',
            param: '',
            children: null,
          },
          {
            id: 3,
            text: 'About',
            to: 'about',
            param: '',
            children: null,
          },
          {
            id: 4,
            text: 'Contact',
            to: 'contact',
            param: '',
            children: null,
          },
        ];
      },
      drawer: sync('app/drawer'),
    },
    mounted() {
      if (!this.$store.state.app.tfbId) {
        dispatch('app/setVisitor');
      }
    },
    methods: {
      logout() {
        dispatch('app/setIsLoading', true);
        dispatch('member/doLogout')
          .then(() => {
            dispatch('app/setIsLoading', false);
            // Go to home page
            this.$router.push({ name: 'policyholderLogin' });
          })
          .catch((error) => {
            dispatch('app/setIsLoading', false);
            dispatch('app/setErrorMessage', error.toString());
          });
      },
      accountSummary() {
        if (this.isLoggedIn) {
          // Redirect where needed
          this.doRedirect();
        } else {
          this.$router.push({ name: 'policyholderLogin' });
        }
      },
    },
  };
</script>
<style lang="scss">

  .tfb-navbar-mobile .v-list-item {
    min-height: 36px !important;
  }

  .tfb-navbar-mobile .v-list-group__items {
    border-bottom: solid $tfb-darker-gray 1px;
  }
</style>
