<template>
  <keep-alive>
    <ValidationProvider tag="div"
                        :bails="false"
                        :rules="rules"
                        :name="name || $attrs.label"
                        :vid="vid"
                        v-slot="{ errors, valid, required }">
      <v-checkbox v-model="innerValue"
                  :error-messages="errors"
                  :success="valid"
                  type="checkbox"
                  v-bind="$attrs"
                  v-on="$listeners">
        <template v-slot:append>
          <v-icon v-if="required">mdi-asterisk</v-icon>
        </template>
      </v-checkbox>
    </ValidationProvider>
  </keep-alive>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

  export default {
    name: 'CheckboxInput',
  components: {
    ValidationProvider,
  },
    props: {
      vid: {
        type: String,
        default: undefined,
      },
      name: {
        type: String,
        default: undefined,
      },
      rules: {
        type: [Object, String],
        default: '',
      },
      value: {
        type: null,
      },
    },
    data() {
      return {
        innerValue: '',
      };
    },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>
