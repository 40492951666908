<template>
  <div>
    <v-card class="d-inline-block mx-auto overflow-hidden agent-card"
            v-for="agent in agentListing"
            v-bind:key="agent.code">
      <a :href="agent.agentUrl"
         hreflang="en-us"
         :aria-label="agent.name">
        <v-list-item three-line>
          <v-list-item-avatar tile
                              size="100"
                              left>
            <v-img :src="agent.photo"
                   class="rounded-0 agent-card-photo">
              <template v-slot:placeholder>
                <v-img :src="defaultAgentImage"></v-img>
              </template>
            </v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{agent.name}}
            </v-list-item-title>
            <v-row no-gutters>
              <v-col cols="12"
                     lg="6"
                     itemprop="address"
                     itemscope
                     itemtype="http://schema.org/PostalAddress"
                     v-if="agent.physicalAddressDisplay && agent.physicalAddressDisplay !== null">
                <span v-html="agent.physicalAddressDisplay"></span>
              </v-col>
              <v-col cols="12"
                     lg="6">
                {{agent.phoneNumber | formatPhone}}<br />
                <span class="text-lowercase">{{agent.emailAddress}}</span>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </a>
</v-card>
  </div>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';
  import tfbDataMixin from '@/mixins/tfbData';

  export default {
    name: 'AgentCards',
    mixins: [tfbDataMixin, tfbHelperMixin],
    props: {
      agentListing: {
        type: Array,
        required: true,
        function() {
          return [{
            code: '',
            name: '',
            email: '',
            physicalAddressDisplay: '',
            phoneNumber: '',
            photo: this.defaultAgentImage,
          }];
        },
      },
    },
  };
</script>
<style lang="scss">
  .agent-card {
    width: 100%;
    color: $black;
  }

    .agent-card > a .v-list-item .v-list-item__title {
      color: $tfb-blue;
      text-align: left;
      text-decoration: none;
    }

    .agent-card > a:hover {
      text-decoration: none;
    }

    .agent-card > a .v-list-item--three-line .v-list-item__content {
      text-align: left;
    }

  .agent-card-photo {
    width: auto;
    vertical-align: middle;
  }
</style>
