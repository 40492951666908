<template>
  <v-container fluid
               class="tfb-smaller-container pb-10">
    <v-row class="my-1">
      <v-col cols="12"
             class="align-center">
        <h1 class="tfb-title-text-medium text-darker-blue">
          Digital Millennium Copyright Act Policy
        </h1>
      </v-col>
    </v-row>
    <v-row class="my-1">
      <v-col cols="12">
        <v-expansion-panels accordion
                            flat>
          <v-expansion-panel>
            <v-expansion-panel-header class="show-hide-link">
              <span class="text-blue">Notice and Takedown Procedure</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              <p>
                It is our policy to expeditiously respond to clear notices of alleged copyright
                infringement that comply with the United States Digital Millennium Copyright
                Act (&ldquo;DMCA&rdquo;). This page describes the information that should be present in
                these notices. It is designed to make submitting notices of alleged
                infringement to Texas Farm Bureau Mutual Insurance Company and its affiliated
                companies (&ldquo;TFBMIC&rdquo;) as straightforward as possible while reducing the number
                of notices that we receive that are fraudulent or difficult to understand or
                verify. The form of notice specified below is consistent with the form
                suggested by the DMCA (the text of which can be found at the U.S. Copyright
                Office Web Site,
                <a href="https://www.copyright.gov"
                   hreflang="en-us"
                   target="_blank"
                   ref="noopener"
                   aria-label="www.copyright.gov (opens in new tab)">https://www.copyright.gov</a>), but we will respond to
                notices of this form from other jurisdictions as well.
              </p>
              <p>
                It is expected that all users of any part of the TFBMIC website,
                <a href="https://www.txfb-ins.com"
                   hreflang="en-us">www.txfb-ins.com</a> (the &ldquo;Website&rdquo;) will comply with
                applicable copyright laws. However, if TFBMIC receives proper notification
                of claimed copyright infringement, our response to these notices will include
                removing or disabling access to material claimed to be the subject of
                infringing activity and/or terminating user access to the Website, regardless of
                whether we may be liable for such infringement under United States law or the
                laws of another jurisdiction.
              </p>
              <p>
                If we remove or disable access in response to such a notice, we will make a
                good-faith attempt to contact the owner or administrator of the affected site
                or content so that they may make a counter notification pursuant to Sections
                512(g)(2) and (3) of the DMCA. We may also document notices of alleged
                infringement on which we act.
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="show-hide-link">
              <span class="text-blue">Designated Agent</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              <p>
                TFBMIC&rsquo;s Designated Agent to receive notification of alleged infringement
                under the DMCA is:
              </p>
              <address>
                <span class="font-weight-bold">
                  {{legalContact.name}}
                </span><br />
                Email:
                <a v-bind:href="legalContact.emailLink">
                  {{legalContact.emailDisplay}}
                </a><br />
                Physical: {{legalContact.address}}
              </address>
              <p>
                Upon receipt of proper notification of claimed infringement, TFBMIC will follow
                the procedures outlined herein and in the DMCA.
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="show-hide-link">
              <span class="text-blue">Infringement Notification</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              <p>
                If you believe that your work has been copied in a way that constitutes
                copyright infringement, or your intellectual property rights have been
                otherwise violated, please provide TFBMIC’s Designated Agent (listed above)
                the following information in a written communication (preferably via email):
              </p>
              <ol>
                <li>
                  Identification of the copyrighted work claimed to have been infringed, or,
                  if multiple copyrighted works at a single online site are covered by a single
                  notification, a representative list of such works at that site;
                </li>
                <li>
                  Identification of the material that is claimed to be infringing or to be
                  the subject of infringing activity and that is to be removed or access to which
                  is to be disabled, and information reasonably sufficient to permit TFBMIC to
                  locate the material;
                </li>
                <li>
                  Information reasonably sufficient to permit TFBMIC to contact the
                  complaining party, such as an address, telephone number, and, if available,
                  an email address at which the complaining party may be contacted;
                </li>
                <li>
                  The following statement: &ldquo;I have a good faith belief that use of the
                  material in the manner complained of is not authorized by the copyright owner,
                  its agent, or the law&rdquo;;
                </li>
                <li>
                  The following statement: &ldquo;I swear, under penalty of perjury, that the
                  information in the notification is accurate, and that I am the copyright owner
                  or am authorized to act on behalf of the owner of an exclusive right that is
                  allegedly infringed&rdquo;; and
                </li>
                <li>
                  A physical or electronic signature of the owner or a person authorized to
                  act on behalf of the owner of an exclusive right that is allegedly
                  infringed.
                </li>
              </ol>
              <p>
                Please note that you may be liable for damages (including costs and attorneys&rsquo;
                fees) if you materially misrepresent that material is infringing your
                copyrights. Accordingly, if you are not sure whether material available online
                infringes your copyright, we suggest that you first contact an attorney.
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="show-hide-link">
              <span class="text-blue">Counter Notification</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              <p>
                A provider of content subject to a claim of infringement may make a counter
                notification pursuant to sections 512(g)(2) and (3) of the DMCA. To file a
                counter notification with us, please provide TFBMIC’s Designated Agent (listed
                above) the following information in a written communication (preferably via
                email):
              </p>
              <ol>
                <li>
                  Identification of the material that has been removed or to which access has
                  been disabled and the location at which the material appeared before it was
                  removed or access to it was disabled;
                </li>
                <li>Your name, address, and telephone number;</li>
                <li>
                  The following statement: &ldquo;I consent to the jurisdiction of the federal
                  District Court for the Western District of Texas&rdquo;;
                </li>
                <li>
                  The following statement: &ldquo;I will accept service of process from [insert the
                  name of the person who submitted the infringement notification] or his/her
                  agent&rdquo;;
                </li>
                <li>
                  The following statement: &ldquo;I swear, under penalty of perjury, that I have a
                  good faith belief that the affected material was removed or disabled as a
                  result of a mistake or misidentification of the material to be removed or
                  disabled&rdquo;; and
                </li>
                <li>Your signature, in physical or electronic form.</li>
              </ol>
              <p>
                Upon receipt of such counter notification, TFBMIC will promptly provide the
                person who provided the original infringement notification with a copy of
                the counter notification, and inform that person that TFBMIC will replace the
                removed material or cease disabling access to it in 10 business days. TFBMIC
                will replace the removed material and cease disabling access to it not less
                than 10, nor more than 14, business days following receipt of the counter
                notice, unless our Designated Agent first receives notice from the person
                who submitted the original infringement notification that such person has filed
                an action seeking a court order to restrain the subscriber from engaging in
                infringing activity relating to the material on our system or network.
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="show-hide-link">
              <span class="text-blue">Repeat Infringers</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              <p>
                In accordance with Section 512(i)(1)(a) of the DMCA, TFBMIC will, in
                appropriate circumstances, disable and/or terminate the access by users who
                are repeat infringers.
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="show-hide-link">
              <span class="text-blue">Accommodation of Standard Technical Measures</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              <p>
                It is TFBMIC&rsquo;s policy to accommodate and not interfere with standard technical
                measures used by copyright owners to identify or protect copyrighted works that
                TFBMIC determines are reasonable under the circumstances.
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbMetaMixin from '@/mixins/metaInfo';

  export default {
    name: 'DMCA',
    mixins: [tfbDataMixin, tfbMetaMixin],
    computed: {
      metaPageTitle() {
        return 'Digital Millennium Copyright Act Policy';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/dmca`;
      },
    },
  };
</script>
