<template>
  <v-container fluid
               class="tfb-smaller-container pb-10">
    <h1 class="tfb-title-text-medium text-darker-blue text-uppercase">Manage My Account</h1>
    <!-- Policyholder Notice -->
    <mobile-app-banner v-if="showMobileAppBanner"></mobile-app-banner>
    <!-- Email Changes -->
    <a id="email"></a>
    <email-changes></email-changes>
    <!-- Password Changes -->
    <a id="password"></a>
    <password-changes></password-changes>
    <!-- Phone Changes -->
    <a id="phone"></a>
    <phone-changes v-if="textEligible !== null && textEligible.length > 0 && foundContactList && foundContactList !== null"
                   :contact-list="foundContactList"
                   :phone-loading="phoneLoading"></phone-changes>
    <!-- E-bill Changes -->
    <a id="ebill"></a>
    <ebill-changes :contact-list="foundContactList"
                   :policy-list="accountSummary"
                   :ab-list="accountBillSummary"></ebill-changes>
    <!-- Paperless Changes -->
    <a id="paperless"></a>
    <paperless-changes :contact-list="foundContactList"
                       :policy-list="accountSummary"></paperless-changes>
    <!-- Account Bill Changes -->
    <a id="accountBill"></a>
    <!--<account-bill-changes></account-bill-changes>-->
    <!-- Membership Changes -->
    <a id="membership"></a>
    <membership-changes></membership-changes>
    <!-- Communication Changes -->
    <a id="communication"></a>
    <communication-changes></communication-changes>
  </v-container>
</template>
<script>
  import { dispatch, sync } from 'vuex-pathify';
  import tfbMetaInfo from '@/mixins/metaInfo';
  import tfbHelperMixin from '@/mixins/helpers';
  import { insuredAccessOnlyErrorMessage } from '@/mixins/errorMessages';
  import MobileAppBanner from '@/views/layout/components/MobileAppBanner.vue';
  import EmailChanges from './management/Email.vue';
  import PhoneChanges from './management/PhoneNumber.vue';
  import PasswordChanges from './management/Password.vue';
  import EbillChanges from './management/Ebill.vue';
  import PaperlessChanges from './management/PaperlessPolicy.vue';
  import MembershipChanges from './management/Membership.vue';
  // import AccountBillChanges from './management/AccountBill.vue';
  import CommunicationChanges from './management/Communication.vue';

  export default {
    name: 'AccountSettings',
    mixins: [tfbMetaInfo, tfbHelperMixin],
    components: {
      EmailChanges,
      PasswordChanges,
      PhoneChanges,
      EbillChanges,
      PaperlessChanges,
      // AccountBillChanges,
      MembershipChanges,
      CommunicationChanges,
      MobileAppBanner,
    },
    data() {
      return {
        showPhoneNumbers: false,
        phoneLoading: false,
      };
    },
    computed: {
      metaPageTitle() {
        return 'Account Settings | PolicyHolder';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/policyholder/manage-account`;
      },
      memberNumber() {
        try {
          return this.$store.getters['member/memberNumber'];
        } catch (error) {
          return '';
        }
      },
      showMobileAppBanner() {
        return this.$store.state.app.policyholderMobileAppDisplay === 'Y';
      },
       accountSummary() {
        try {
          return this.$store.getters['policy/accountSummary'];
        } catch (error) {
          return [];
        }
       },
       accountBillSummary() {
        try {
          return this.$store.getters['payments/accountBillSummary'];
        } catch (error) {
          return [];
        }
       },
      // accountSummary: sync('policy/accountSummary'),
      // accountBillSummary: sync('payments/accountBillSummary'),
      textEligible: sync('app/textEligible'),
      foundContactList: sync('member/contactList'),
      showPhoneVerification: sync('member/showPhoneVerification'),
      showPhoneAdd: sync('member/showPhoneAdd'),
    },
    watch: {
      showPhoneVerification() {
        this.getMemberPhoneNumbers();
      },
      showPhoneAdd() {
        this.getMemberPhoneNumbers();
      },
    },
    methods: {
      getMemberPhoneNumbers() {
        this.phoneLoading = true;
        this.showPhoneNumbers = true;
        dispatch('member/getPhoneNumbers', { sMemberNumber: this.memberNumber, sTfbId: this.trackingId })
          .then(() => {
            this.phoneLoading = false;
          })
          .catch(() => {
            this.phoneLoading = false;
          });
      },
      getAccountSummary() {
        dispatch('policy/getAccountSummary', { sMemberNumber: this.memberNumber, sTfbId: this.trackingId })
          .then(() => {
            // Policies should load
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out, log out and go to login
              this.$store.dispatch('app/setIsLoading', true);
              dispatch('member/doLogout')
                .then(() => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$router.push({ name: 'policyholderLogin' });
                })
                .catch((accessError) => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$store.dispatch('app/setErrorMessage', accessError.toString());
                });
            }
            dispatch('app/setErrorMessage', error.toString());
          });
      },
      getAccountBillSummary() {
        dispatch('payments/getAccountBillInformation', { sMemberNumber: this.memberNumber, sTfbId: this.trackingId })
          .then(() => {
            // Policies should load
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error.toString());
          });
      },
    },
    mounted() {
      dispatch('app/clearErrorMessage');
      dispatch('app/setIsLoading', true);

      if (this.accountSummary === null || this.accountSummary.length <= 0) {
        // Go fetch the policy list again
        this.getAccountSummary();
        this.getAccountBillSummary();
      }

      // Is the Policyholder application enabled?
      this.$store.dispatch('app/doApplicationCheck', { sApplicationName: 'Policyholder', sTfbId: this.trackingId })
        .then((returnedStatus) => {
          if (returnedStatus === '') {
            // Is this a valid log in still?
            const includedToken = this.$route.query.access_token;
            dispatch('member/validateLogin', { sToken: includedToken, sTfbId: this.trackingId })
              .then((loginValid) => {
                if (!loginValid) {
                  // Invalid login - go back to login screen
                  dispatch('member/doLogout')
                    .then(() => {
                      this.$router.push({ name: 'policyholderLogin' });
                    })
                    .catch((accessError) => {
                      dispatch('app/setErrorMessage', accessError.toString());
                      dispatch('app/setIsLoading', false);
                    });
                } else if (!this.isMemberRole) {
                  // Not the member, don't allow changes
                  this.$store.dispatch('app/setErrorMessage', insuredAccessOnlyErrorMessage);
                  // this.$router.push({ name: 'policyholderAccountSummary' });
                }

                if (this.doStatusCheck()) {
                // Check if Text Notifications are enabled
                this.$store.dispatch('app/getConfiguration', { sSection: 'Limit', sTitle: 'AllowedEbillTextNotify' })
                  .then((allowedText) => {
                    if (allowedText && allowedText !== null && allowedText.length > 0) {
                      dispatch('app/setTextEligible', allowedText);
                      this.getMemberPhoneNumbers();
                    }
                  })
                  .catch(() => {
                    // Do nothing
                  });
                }

                this.$store.dispatch('app/setIsLoading', false);
              })
              .catch((error) => {
                dispatch('app/setErrorMessage', error.toString());
                dispatch('app/setIsLoading', false);
              });
            this.$store.dispatch('app/setIsLoading', false);
          } else {
            this.$store.dispatch('app/setErrorMessage', returnedStatus);
            this.$store.dispatch('app/setIsLoading', false);
          }
        })
        .catch((error) => {
          this.$store.dispatch('app/setErrorMessage', error.toString());
          this.$store.dispatch('app/setIsLoading', false);
        });
    },
  };
</script>
<style lang="scss">
</style>
