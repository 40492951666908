<template>
  <div class="no-gutters remove-spacing no-print"
       role="banner">
    <!-- Begin Notices -->
    <noscript>
      <div class="tfb-notification tfb-notification-notice text-center"
           role="alert"
           id="no-script-panel">
        Javascript must be enabled for full functionality of this website.
      </div>
    </noscript>
    <v-expansion-panels class="tfb-notification"
                        role="alert"
                        v-show="noticeText !== ''"
                        id="notification-panel">
      <!-- Notice -->
      <v-expansion-panel class="tfb-notification-notice">
        <v-expansion-panel-header disable-icon-rotate
                                  class="text-format-bold">
          <template v-slot:actions>
            <v-icon color="white">mdi-exclamation</v-icon>
          </template>
          Notice From Texas Farm Bureau Insurance
        </v-expansion-panel-header>
        <v-expansion-panel-content id="notice-panel">
          <span v-html="noticeText"></span>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- End Notice -->
    </v-expansion-panels>
    <!-- End Notices -->
  </div>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'Notification',
    mixins: [tfbHelperMixin],
    data() {
      return {
        noticeText: '',
        hideNotice: true,
      };
    },
    mounted() {
      this.$store.dispatch('app/getNotices', { sTfbId: this.trackingId })
        .then((response) => {
          if (response && response.length > 0) {
            const currentTimestamp = new Date().toJSON();
            for (let i = 0; i < response.length; i += 1) {
              const item = response[i];
              if (item.StartTime <= currentTimestamp && item.EndTime > currentTimestamp) {
                if (this.noticeText) {
                  this.noticeText += `<hr /><strong>Notice:</strong> ${item.Title}`;
                } else {
                  this.noticeText = `<strong>Notice:</strong> ${item.Title}`;
                }
              }
            }
          }
        })
        .catch((error) => {
          this.$store.dispatch('app/logToULS', {
            logLevel: 'Error',
            logMessage: error,
            fileName: 'HeaderNotification.vue',
            methodName: 'getNotices-Catch',
            trackerId: this.trackingId,
          });
        });
    },
  };
</script>
<style lang="scss">
  .tfb-notification {
    color: #e6e6e6 !important;
    background-color: $tfb-red !important;
    border-color: $tfb-darkest-red !important;
    vertical-align: middle;
    margin: 0px;
    padding: 0px;
    margin-bottom: 2px;
    border: 1px solid transparent;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  .tfb-notification-notice {
    color: #e6e6e6 !important;
    background-color: $tfb-red !important;
    border-color: $tfb-darkest-red !important;
    padding: 5px;
    vertical-align: middle;
    margin: 0px;
    width: 100%;
  }

  .tfb-notification-notice hr {
    border-top-color: $tfb-darkest-red;
  }

  .remove-spacing {
    margin-top: -2px;
    margin-bottom: -2px;
  }
</style>
