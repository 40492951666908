<template>
  <!-- Billing Documents -->
      <v-expansion-panels accordion
                          flat
                          class="tfb-section my-3"
                          v-if="laurusFlag === 'Y' || policyType === 'AB'">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12"
                     md="5">
                <template v-slot:actions>
                  <v-icon>$expand</v-icon>
                </template>
                <h3 class="text-blue font-weight-bold">Billing Documents</h3>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="background-lightest-gray py-4">
            <v-data-table dense
                          :headers="billingDocumentHeaders"
                          :items="documentList"
                          :items-per-page="10"
                          :loading="documentLoading"
                          loading-text="Loading... Please wait">
              <template v-slot:no-data>
                No past billing documents currently available
              </template>
              <template v-slot:item.Date="{ item }">
                <a href=""
                   v-on:click.prevent="getBillingDocument(policyNumber, policyType, policySubType,
                               item.DocumentId, item.FormDescription)">{{item.Date}}</a>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'BillingDocumentList',
    props: {
      policyNumber: {
        type: String,
        required: true,
      },
      policyType: {
        type: String,
        required: true,
      },
      policySubType: {
        type: String,
        required: true,
      },
      laurusFlag: {
        type: String,
        required: true,
      },
    },
    mixins: [tfbHelperMixin],
    data() {
      return {
        documentLoading: true,
        documentList: [],
        billingDocumentHeaders: [
          { text: 'Document Type', align: 'start', value: 'LabelDescription' },
          { text: 'Date', value: 'Date' },
        ],
      };
    },
    methods: {
      async getBillingDocuments() {
        dispatch('documents/getBillingDocumentList', {
          sPolicyNumber: this.policyNumber, sPolicyType: this.policyType, sPolicySubType: this.policySubType, sLocationType: 'BILLHIST', sTfbId: this.trackingId,
        })
          .then((documentResponse) => {
            this.documentList = documentResponse;
            this.documentLoading = false;
          })
          .catch(() => {
            this.documentLoading = false;
          });
      },
      getBillingDocument(policyNumber, policyType, policySubType, documentId, formDescription) {
        this.$store.dispatch('app/setIsLoading', true);
        dispatch('documents/getSpecificIdBillingDocument', {
          sPolicyNumber: policyNumber, sPolicyType: policyType, sPolicySubType: policySubType, sDocumentId: documentId, sFormDescription: formDescription, sTfbId: this.trackingId,
        })
          .then((documentPages) => {
            this.$store.dispatch('app/setIsLoading', false);
            if (documentPages && documentPages !== null) {
              this.$store.dispatch('app/setIsDocumentLoading', true);
              dispatch('documents/showPDF', {
                inputPDFArray: documentPages, fileName: `${policyNumber}-${formDescription}.pdf`, tfbId: this.trackingId,
              })
                .then(() => {
                  // Do nothing
                })
                .catch((error) => {
                  this.$store.dispatch('app/setIsDocumentLoading', false);
                  this.$store.dispatch('app/setErrorMessage', error.toString());
                });
            }
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out, log out and go to login
              this.$store.dispatch('app/setIsLoading', true);
              dispatch('member/doLogout')
                .then(() => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$router.push({ name: 'policyholderLogin' });
                })
                .catch((accessError) => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$store.dispatch('app/setErrorMessage', accessError.toString());
                });
            } else if (error.toString().indexOf('status code 404') >= 0) {
              // Document was not found
              this.$store.dispatch('app/setIsLoading', false);
              this.$store.dispatch('app/setErrorMessage', 'Unable to load document. Please try again.');
            } else {
              this.$store.dispatch('app/setIsLoading', false);
              this.$store.dispatch('app/setErrorMessage', error.toString());
            }
          });
      },
    },
    mounted() {
      if (this.laurusFlag === 'Y' || this.policyType === 'AB') {
        this.getBillingDocuments();
      }
    },
  };
</script>
<style lang="scss">


</style>
