import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const opts = {
  theme: {
    disable: true,
    themeCache: {
      get: key => localStorage.getItem(key),
      set: (key, value) => localStorage.setItem(key, value),
    },
  },
  breakpoint: {
    mobileBreakpoint: 'sm', // This is equivalent to a value of 960
  },
};

export default new Vuetify(opts);
