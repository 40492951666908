<template>
  <v-container fluid
               class="tfb-container">
    <h1 class="tfb-title-text-medium text-darker-blue text-uppercase">
      AssurancePay &reg; Recurring Payments
    </h1>
    <v-row>
      <v-col cols="12">
        <v-container fluid
                     class="drop-shadow background-white tfb-smaller-container">
          <v-row v-if="!showFinal && !showOptOut && !showForm">
            <v-col cols="12">
              <p v-if="changeType === 'signup'">
                AssurancePay&reg; Recurring Payments must be setup at least five (5) business days prior to your next due date.<br /><br />
                Any billed installments on your account will need to be paid before automatic drafting will begin.
              </p>
              <p v-else>
                Your bill account is currently being paid by Recurring Payments drawn from your bank account. From this screen you have
                options to modify your AssurancePay settings, or cancel your Assurance Pay recurring payments.<br /><br />
                Submissions must be at least five (5) business days prior to the next draft date as shown on the Recurring Payment Schedule.
                Changes submitted may not take effect until the next billing cycle.
              </p>
            </v-col>
          </v-row>
          <v-row v-if="recurringRequest && recurringRequest !== null"
                 class="mt-1">
            <v-col cols="5"
                   class="py-1"><span class="font-weight-bold">Policy Number:</span></v-col>
            <v-col cols="7"
                   class="py-1">
              {{recurringRequest.PolicyNumber}}
            </v-col>
          </v-row>
          <v-row v-if="recurringRequest && recurringRequest !== null">
            <v-col cols="5"
                   class="py-1"><span class="font-weight-bold">Policy Holder Name:</span></v-col>
            <v-col cols="7"
                   class="py-1">
              {{recurringRequest.InsuredName}}
            </v-col>
          </v-row>
          <div v-if="showCurrent && currentRecurring !== null">
            <v-row>
              <v-col cols="12"
                     class="mt-3 pb-0">
                <h2 class="tfb-title-text-small text-darker-blue text-uppercase">
                  Current AssurancePay&reg; Information
                </h2>
              </v-col>
            </v-row>
            <!-- Display current information -->
            <v-row>
              <v-col cols="5"
                     class="py-1"><span class="font-weight-bold">Name on Bank Account:</span></v-col>
              <v-col cols="7"
                     class="py-1">
                {{currentRecurring.PolicyholderName}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5"
                     class="py-1"><span class="font-weight-bold">Bank Name:</span></v-col>
              <v-col cols="7"
                     class="py-1">
                {{currentRecurring.BankName}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5"
                     class="py-1"><span class="font-weight-bold">Bank Routing Number:</span></v-col>
              <v-col cols="7"
                     class="py-1">
                {{currentRecurring.MaskedRoutingNumber}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5"
                     class="py-1"><span class="font-weight-bold">Bank Account Number:</span></v-col>
              <v-col cols="7"
                     class="py-1">
                {{currentRecurring.MaskedAccountNumber}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5"
                     class="py-1"><span class="font-weight-bold">Type of Account:</span></v-col>
              <v-col cols="7"
                     class="py-1">
                {{currentRecurring.FullAccountType}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5"
                     class="py-1"><span class="font-weight-bold">Requested Draft Day:</span></v-col>
              <v-col cols="7"
                     class="py-1">
                {{currentRecurring.PaymentDate}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-btn block
                       @click="cancelRecurringChanges()"
                       class="tfb-button background-red">Cancel</v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn block
                       :disabled="formDisabled"
                       @click="modifyRecurringChanges()"
                       class="tfb-button background-red">Modify</v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn block
                       :disabled="formDisabled"
                       @click="optOutRecurringChanges()"
                       class="tfb-button background-red">Opt Out</v-btn>
              </v-col>
            </v-row>
          </div>
          <div v-if="showForm">
            <v-row>
              <v-col cols="12">
                <h2 class="tfb-title-text-small text-darker-blue text-uppercase">
                  Modify AssurancePay&reg; Recurring Payments
                </h2>
              </v-col>
            </v-row>
            <!-- Display pre-filled form -->
            <ValidationObserver ref="obv" v-slot="{ handleSubmit }">
              <v-form ref="assurancePayForm"
                      @submit.stop.prevent="handleSubmit(saveRecurringChanges)">
                <v-row>
                  <v-col cols="12">
                    <text-input rules="required|full_name_alpha_only|min:4|max:60"
                                minlength="4"
                                maxlength="60"
                                label="Name on Bank Account"
                                v-model="assurancePayForm.accountName"></text-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <text-input rules="routing_number|required|numeric|min:4|max:10"
                                minlength="4"
                                maxlength="10"
                                label="Bank Routing Number"
                                @blur="getBankName()"
                                type="number"
                                v-model="assurancePayForm.routingNumber"></text-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <text-input rules="required"
                                label="Bank Name"
                                disabled
                                v-model="assurancePayForm.bankName"></text-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <text-input rules="required|confirmed:account|min:4|max:17"
                                minlength="4"
                                maxlength="17"
                                label="Bank Account Number"
                                v-model="assurancePayForm.accountNumber"></text-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <text-input rules="required|min:4|max:17"
                                minlength="4"
                                maxlength="17"
                                label="Confirm Bank Account Number"
                                v-model="assurancePayForm.confirmAccountNumber"
                                vid="account"></text-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <select-input rules="required|alpha"
                                  label="Bank Account Type"
                                  :items="accountTypeOptions"
                                  item-text="DisplayText"
                                  item-value="Value"
                                  v-model="assurancePayForm.accountType"></select-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <select-input rules="required|numeric"
                                  label="Requested Draft Day"
                                  :items="draftDayList"
                                  v-model="assurancePayForm.draftDay"></select-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <keep-alive>
                      <ValidationProvider tag="div"
                                          :bails="false"
                                          rules="required"
                                          name="confirmation"
                                          v-slot="{ errors, valid, required }">
                        <v-checkbox v-model="assurancePayForm.confirmation"
                                    :error-messages="errors"
                                    :success="valid"
                                    type="checkbox">
                          <template v-slot:label>
                            <span v-if="changeType.toLowerCase() === 'signup'">
                              I have read and agree to the <a href="/consumerdocuments/recurring%20payments%20terms%20and%20conditions.pdf"
                                                              id="assurance-paper-terms"
                                                              target="_blank"
                                                              @click.stop
                                                              title="AssurancePay Terms and Conditions">Terms and Conditions</a>
                              for AssurancePay&reg; Recurring Premium Payments via Electronic Funds Transfer (EFT.)
                              I authorize the Texas Farm Bureau Underwriters, Texas Farm Bureau Casualty Insurance Company, Farm Bureau County Mutual Insurance
                              Company of Texas, or Texas Farm Bureau Mutual Insurance Company, as the Companies issuing the policy described above, to initiate
                              through the designated financial entity the appropriate entries to transfer premium payments.
                              I understand I am responsible for paying any paper billings I may already have received or receive prior to receiving a recurring payment schedule.
                            </span>
                            <span v-else>
                              I am authorizing Texas Farm Bureau Insurance Companies to deduct my premium payments from the bank above.
                              I understand that the original AssurancePay&reg; Recurring Electronic Funds Transfer (EFT) Terms of Agreement are still in force in
                              accordance with the original AssurancePay&reg; Enrollment Form which I previously signed and that I must allow a reasonable time for
                              processing this change.
                            </span>
                          </template>
                          <template v-slot:append>
                            <v-icon>mdi-asterisk</v-icon>
                          </template>
                        </v-checkbox>
                      </ValidationProvider>
                    </keep-alive>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-btn block
                           @click="cancelRecurringChanges()"
                           class="tfb-button background-red">Cancel</v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn block
                           :disabled="formDisabled"
                           type="submit"
                           class="tfb-button background-red">Submit</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </ValidationObserver>
          </div>
          <div v-if="showOptOut">
            <v-row>
              <v-col cols="12">
                <h2 class="tfb-title-text-small text-darker-blue text-uppercase">
                  AssurancePay&reg; Recurring Payments Opt-Out
                </h2>
              </v-col>
            </v-row>
            <!-- Display pre-filled form -->
            <ValidationObserver ref="obv" v-slot="{ handleSubmit }">
              <v-form ref="optOutForm"
                      @submit.stop.prevent="handleSubmit(saveRecurringOptOut)"
                      lazy-validation>
                <v-row>
                  <v-col cols="12">
                    Acknowledge the statement below and click the Submit button to confirm removing AssurancePay&reg; Recurring Payments from your policy. Clicking
                    Cancel will terminate your request and return you to the Account Summary screen.
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <keep-alive>
                      <ValidationProvider tag="div"
                                          :bails="false"
                                          rules="required"
                                          name="confirmation"
                                          v-slot="{ errors, valid, required }">
                        <v-checkbox v-model="optOutForm.confirmation"
                                    :error-messages="errors"
                                    :success="valid"
                                    type="checkbox">
                          <template v-slot:label>
                            I am authorizing Texas Farm Bureau Insurance Companies to remove my bill account from AssurancePay&reg; Recurring Payments.
                            I understand that I will be receiving billing statements and will be responsible for paying the amount due as stated on each statement.
                            I understand that the original AssurancePay&reg; Recurring Electronic Funds Transfer (EFT) Terms of Agreement are still in force in
                            accordance with the original AssurancePay&reg; Enrollment Form which I previously signed and that I must allow a reasonable time for
                            processing this change.
                          </template>
                          <template v-slot:append>
                            <v-icon>mdi-asterisk</v-icon>
                          </template>
                        </v-checkbox>
                      </ValidationProvider>
                    </keep-alive>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-btn block
                           @click="cancelRecurringChanges()"
                           class="tfb-button background-red">Cancel</v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn block
                           :disabled="formDisabled"
                           type="submit"
                           class="tfb-button background-red">Submit</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </ValidationObserver>
          </div>
          <div v-if="showFinal">
            <v-row>
              <v-col cols="12">
                <h2 class="tfb-title-text-small text-darker-blue text-uppercase">
                  Confirmation
                </h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h3 class="tfb-title-text-smaller">Your AssurancePay&reg; request has been submitted.</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" v-if="changeType.toLowerCase() === 'signup'">
                Once processed, you will receive a Recurring Payment Schedule for your installments.<br />
                Please continue to pay any bill statements until you receive your Recurring Payment Schedule.
              </v-col>
              <v-col cols="12" v-if="!showOptOut && changeType.toLowerCase() === 'change'">
                We will process this request as quickly as possible. However, due to the time required to process this request, your
                Recurring Payments through AssurancePay&reg; may not be changed before your next scheduled draft. The time
                required to change the drafts varies for different financial institutions and may also be affected by weekends and
                holidays. Stay in communication with your financial institution and your agent to be sure when this request goes
                into effect. Once processed, you will receive an updated Recurring Payment Schedule.
              </v-col>
              <v-col cols="12" v-if="showOptOut">
                We will process this request as quickly as possible. However, due to the time required to process this request, your
                Recurring Payments through AssurancePay&reg; may not be stopped before your next scheduled draft. The time
                required to stop the drafts varies for different financial institutions and may also be affected by weekends and
                holidays. Stay in communication with your financial institution and your agent to be sure when this request goes
                into effect. Once processed, you will begin receiving statements for your installments.
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" v-if="showOptOut">
                Thank you for choosing Texas Farm Bureau Insurance Companies.
              </v-col>
              <v-col cols="12" v-else>
                Thank you for choosing the AssurancePay&reg; Recurring EFT Payment plan.
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-btn block
                       @click="cancelRecurringChanges()"
                       class="tfb-button background-red">Return to Account Summary</v-btn>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import tfbMetaInfo from '@/mixins/metaInfo';
  import tfbHelperMixin from '@/mixins/helpers';
  import { insuredAccessOnlyErrorMessage } from '@/mixins/errorMessages';

  export default {
    name: 'RecurringPayments',
    props: {
      changeType: {
        type: String,
        required: true,
      },
    },
    mixins: [tfbMetaInfo, tfbHelperMixin],
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    data() {
      return {
        formDisabled: false,
        bankLoading: false,
        showOptOut: false,
        showCurrent: false,
        showForm: false,
        showFinal: false,
        currentRecurring: null,
        assurancePayForm: {
          accountName: null,
          draftDay: 15,
          routingNumber: null,
          bankName: null,
          accountNumber: null,
          confirmAccountNumber: null,
          accountType: null,
        },
        optOutForm: {
        },
        accountTypeOptions: [{ Value: 'CH', DisplayText: 'Checking' }, { Value: 'SA', DisplayText: 'Savings' }],
      };
    },
    computed: {
      metaPageTitle() {
        return 'AssurancePay Recurring Payments | PolicyHolder';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/policyholder/payments/recurring-payments/${this.changeType}`;
      },
      emailAddress() {
        try {
          return this.$store.getters['member/emailAddress'];
        } catch (error) {
          return '';
        }
      },
      agentId() {
        try {
          return this.$store.getters['member/agentNumber'];
        } catch (error) {
          return '';
        }
      },
      memberNumber() {
        try {
          return this.$store.getters['member/memberNumber'];
        } catch (error) {
          return '';
        }
      },
      recurringRequest() {
        try {
          return this.$store.getters['payments/recurringRequest'];
        } catch (error) {
          return null;
        }
      },
      draftDayList() {
        const dayList = [];
        for (let i = 1; i <= 28; i += 1) {
          dayList.push(i);
        }

        return dayList;
      },
    },
    methods: {
      getBankName() {
        this.bankLoading = true;
        dispatch('payments/validateRoutingNumber', {
          sRoutingNumber: this.assurancePayForm.routingNumber, sTfbId: this.trackingId,
        })
          .then((validationResult) => {
            this.assurancePayForm.bankName = validationResult;
            if (!validationResult || validationResult === '') {
              this.assurancePayForm.routingNumber = null;
            }
            this.bankLoading = false;
          })
          .catch((error) => {
            if (error.toString().indexOf('Request failed with status code 401') >= 0) {
              // Session has timed out, log out and go to login
              this.$store.dispatch('member/doLogout')
                .then(() => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$router.push({ name: 'policyholderLogin' });
                })
                .catch((accessError) => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$store.dispatch('app/setErrorMessage', accessError.toString());
                });
            } else {
              dispatch('app/setErrorMessage', error.toString());
            }
            this.bankLoading = false;
          });
      },
      cancelRecurringChanges() {
        // Clear the request
        this.$store.commit('payments/SET_RECURRING_REQUEST', null);
        // Go back to Summary screen
        this.$router.push({ name: 'policyholderAccountSummary' });
      },
      modifyRecurringChanges() {
        this.showOptOut = false;
        this.showCurrent = false;
        this.showForm = true;
      },
      optOutRecurringChanges() {
        this.showOptOut = true;
        this.showCurrent = false;
        this.showForm = false;
      },
      saveRecurringChanges() {
        dispatch('app/clearErrorMessage');
        dispatch('app/setIsLoading', true);
        this.$store.dispatch('app/logToULS', {
          logLevel: 'Info',
          logMessage: `${this.recurringRequest.PolicyNumber}-${this.memberNumber}-${this.changeType.toLowerCase()}`,
          fileName: 'RecurringPayments.vue',
          methodName: 'saveRecurringChanges-Start',
          trackerId: this.trackingId,
        });

        dispatch('payments/modifyRecurringPayments', {
          sAccountType: this.assurancePayForm.accountType,
          sAgentId: this.agentId,
          sAccountNumber: this.assurancePayForm.accountNumber,
          sBankName: this.assurancePayForm.bankName,
          sEffectiveDate: this.recurringRequest.EffectiveDate,
          sEmailAddress: this.emailAddress,
          sMemberNumber: this.memberNumber,
          sPaymentDate: this.assurancePayForm.draftDay,
          sPolicyNumber: this.recurringRequest.PolicyNumber,
          sPolicySubType: this.recurringRequest.PolicySubType,
          sPolicyType: this.recurringRequest.PolicyType,
          sPolicyholderName: this.assurancePayForm.accountName,
          sRequestType: this.changeType.toLowerCase() === 'signup' ? 0 : 1,
          sRoutingNumber: this.assurancePayForm.routingNumber,
          sTfbId: this.trackingId,
        })
          .then(() => {
            this.$store.dispatch('app/logToULS', {
              logLevel: 'Info',
              logMessage: `${this.recurringRequest.PolicyNumber}-${this.memberNumber}-${this.changeType.toLowerCase()}`,
              fileName: 'RecurringPayments.vue',
              methodName: 'modifyRecurringPayments-Response',
              trackerId: this.trackingId,
            });
            this.showCurrent = false;
            this.showFinal = true;
            this.showForm = false;
            this.showOptOut = false;
            // Clear the request
            this.$store.commit('payments/SET_RECURRING_REQUEST', null);
            dispatch('app/setIsLoading', false);
          })
          .catch((error) => {
            this.$store.dispatch('app/logToULS', {
              logLevel: 'Error',
              logMessage: error,
              fileName: 'RecurringPayments.vue',
              methodName: 'modifyRecurringPayments-Catch',
              trackerId: this.trackingId,
            });
            if (error.toString().indexOf('Request failed with status code 401') >= 0) {
              // Session has timed out, log out and go to login
              this.$store.dispatch('member/doLogout')
                .then(() => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$router.push({ name: 'policyholderLogin' });
                })
                .catch((accessError) => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$store.dispatch('app/setErrorMessage', accessError.toString());
                });
            }
            dispatch('app/setErrorMessage', error.toString());
            dispatch('app/setIsLoading', false);
          });
      },
      saveRecurringOptOut() {
        dispatch('app/clearErrorMessage');
        dispatch('app/setIsLoading', true);
        this.$store.dispatch('app/logToULS', {
          logLevel: 'Info',
          logMessage: `${this.recurringRequest.PolicyNumber}-${this.memberNumber}-${this.changeType.toLowerCase()}`,
          fileName: 'RecurringPayments.vue',
          methodName: 'saveRecurringOptOut-Start',
          trackerId: this.trackingId,
        });

        dispatch('payments/modifyRecurringPayments', {
          sAccountType: this.currentRecurring.AccountType,
          sAgentId: this.agentId,
          sAccountNumber: this.currentRecurring.AccountNumber,
          sBankName: this.currentRecurring.BankName,
          sEffectiveDate: this.recurringRequest.EffectiveDate,
          sEmailAddress: this.emailAddress,
          sMemberNumber: this.memberNumber,
          sPaymentDate: this.currentRecurring.PaymentDate,
          sPolicyNumber: this.recurringRequest.PolicyNumber,
          sPolicySubType: this.recurringRequest.PolicySubType,
          sPolicyType: this.recurringRequest.PolicyType,
          sPolicyholderName: this.currentRecurring.PolicyholderName,
          sRequestType: 2,
          sRoutingNumber: this.currentRecurring.RoutingNumber,
          sTfbId: this.trackingId,
        })
          .then(() => {
            this.$store.dispatch('app/logToULS', {
              logLevel: 'Info',
              logMessage: `${this.recurringRequest.PolicyNumber}-${this.memberNumber}-${this.changeType.toLowerCase()}`,
              fileName: 'RecurringPayments.vue',
              methodName: 'modifyRecurringPayments-Response',
              trackerId: this.trackingId,
            });
            this.showCurrent = false;
            this.showFinal = true;
            this.showForm = false;
            this.showOptOut = false;
            // Clear the request
            this.$store.commit('payments/SET_RECURRING_REQUEST', null);
            dispatch('app/setIsLoading', false);
          })
          .catch((error) => {
            this.$store.dispatch('app/logToULS', {
              logLevel: 'Error',
              logMessage: error,
              fileName: 'RecurringPayments.vue',
              methodName: 'modifyRecurringPayments-Catch',
              trackerId: this.trackingId,
            });
            if (error.toString().indexOf('Request failed with status code 401') >= 0) {
              // Session has timed out, log out and go to login
              this.$store.dispatch('member/doLogout')
                .then(() => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$router.push({ name: 'policyholderLogin' });
                })
                .catch((accessError) => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$store.dispatch('app/setErrorMessage', accessError);
                });
            } else {
              dispatch('app/setErrorMessage', error);
              dispatch('app/setIsLoading', false);
            }
          });
      },
    },
    mounted() {
      dispatch('app/clearErrorMessage');
      dispatch('app/setIsLoading', true);
      dispatch('app/doApplicationCheck', { sApplicationName: 'AssurancePay', sTfbId: this.trackingId })
        .then((returnedStatus) => {
          if (returnedStatus === '') {
            if (!this.isLoggedIn) {
              // Invalid login - go back to login screen
              this.$router.push({ name: 'policyholderLogin' });
            } else if (!this.isMemberRole) {
              // Not the member, don't allow changes
              this.$store.dispatch('app/setErrorMessage', insuredAccessOnlyErrorMessage);
              this.$router.push({ name: 'policyholderAccountSummary' });
            } else if (!this.recurringRequest || this.recurringRequest === null) {
              // No request values, push back to Summary screen
              this.$router.push({ name: 'policyholderAccountSummary' });
            }

            if (this.changeType.toLowerCase() === 'signup') {
              this.showForm = true;
              this.assurancePayForm.accountName = this.recurringRequest.InsuredName;
            } else if (this.changeType.toLowerCase() === 'change') {
              // Get the existing AssurancePay information
              this.showCurrent = true;
              dispatch('payments/getRecurringPaymentInfo', {
                sPolicyNumber: this.recurringRequest.PolicyNumber, sTfbId: this.trackingId,
              })
                .then((recurringResult) => {
                  this.currentRecurring = recurringResult;
                  this.assurancePayForm.accountName = recurringResult.PolicyholderName;
                  this.assurancePayForm.bankName = recurringResult.BankName;
                  this.assurancePayForm.routingNumber = recurringResult.RoutingNumber;
                  this.assurancePayForm.accountType = recurringResult.AccountType;
                  this.assurancePayForm.draftDay = recurringResult.PaymentDate;
                })
                .catch((error) => {
                  dispatch('app/setErrorMessage', error.toString());
                });
            } else {
              // Invalid change type, push back to Summary screen
              this.$router.push({ name: 'policyholderAccountSummary' });
            }

            dispatch('app/setIsLoading', false);
          } else {
            dispatch('app/setErrorMessage', returnedStatus);
            dispatch('app/setIsLoading', false);
            this.formDisabled = true;
          }
        })
        .catch((error) => {
          dispatch('app/setErrorMessage', error.toString());
          dispatch('app/setIsLoading', false);
        });
    },
  };
</script>
<style lang="scss">

</style>
