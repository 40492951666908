<template>
  <div class="background-darker-blue py-12">
    <h2 class="tfb-title-text-large text-center">Coverages</h2>
    <v-container fluid
                 class="tfb-smallest-container tfb-quote-box pa-5 my-8">
      <v-row v-if="quoteDetails && quoteDetails != null">
        <v-col cols="12"
               sm="6"
               class="text-center">
          <v-card v-bind:class="[ quoteDetails.SelectedCoverageLevel !== 'Custom' ? 'selected-item' : '', 'fill-height' ]">
            <a href=""
               v-on:click.prevent="setupCoverage('Default')">Standard Quote</a><br />
            <span class="text-body-2"
                  v-html="averageMinMax"></span>
            <v-overlay absolute
                       :value="averageOverlay">
              <v-progress-circular indeterminate
                                   color="#d70019"></v-progress-circular>
            </v-overlay>
          </v-card>
        </v-col>
        <v-col cols="12"
               sm="6"
               class="text-center">
          <v-card v-bind:class="[ quoteDetails.SelectedCoverageLevel === 'Custom' ? 'selected-item' : '', 'fill-height' ]">
            <a href=""
               v-on:click.prevent="setupCoverage('Custom')">Custom</a><br />
            <span class="text-body-2"
                  v-html="customMinMax"></span>
            <v-overlay absolute
                       :value="customOverlay">
              <v-progress-circular indeterminate
                                   color="#d70019"></v-progress-circular>
            </v-overlay>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h5>
            The 'Standard Quote' is calculated using the most common coverage limits.
            You may also customize your coverage by changing individual items and selecting 'Recalculate'.
          </h5>
        </v-col>
      </v-row>
      <ValidationObserver ref="obv" v-slot="{ passes }">
        <v-row>
          <v-col cols="12">
            <v-btn @click="passes(onRecalculateSubmit)"
                   :disabled="recalculateDisabled"
                   class="tfb-button-min background-red float-right">
              Recalculate
            </v-btn>
            <br /><br />
          </v-col>
        </v-row>
        <required-fields-row></required-fields-row>
        <v-form ref="coverageForm"
                v-if="quoteDetails && quoteDetails != null"
                @submit.stop.prevent="passes(onSubmit)">
          <!-- Bodily Injury -->
          <v-row>
            <v-col cols="12">
              <select-input rules="required"
                            label="Bodily Injury Liability Limits"
                            :items="bodilyInjuryOptions"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="quoteDetails.BodilyInjuryLimit"
                            @change="onCoverageChange()"
                            vid="biLimit"></select-input>
            </v-col>
          </v-row>
          <!-- Property Damage -->
          <v-row>
            <v-col cols="12">
              <select-input rules="required|bi_pd_coverage_difference:@biLimit"
                            label="Property Damage Liability Limits"
                            :items="propertyDamageOptions"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="quoteDetails.PropertyDamageLimit"
                            @change="onCoverageChange()"
                            vid="pdLimit"></select-input>
            </v-col>
          </v-row>
          <!-- Uninsured/Underinsured Motorist Bodily Injury -->
          <v-row>
            <v-col cols="12">
              <select-input rules="bi_umbi_coverage_difference:@biLimit"
                            label="Uninsured/Underinsured Motorist Bodily Injury Limits"
                            :items="uninsuredBodilyInjuryLimitsOptions"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="quoteDetails.UninsuredBodilyInjuryLimit"
                            @change="onCoverageChange()"
                            vid="umbiLimit"></select-input>
            </v-col>
          </v-row>
          <!-- Uninsured/Underinsured Motorist Property Damage -->
          <v-row>
            <v-col cols="12">
              <select-input rules="pd_umpd_coverage_difference:@pdLimit"
                            label="Uninsured/Underinsured Motorist Property Damage Limits"
                            :items="uninsuredPropertyDamageLimitsOptions"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="quoteDetails.UninsuredPropertyDamageLimit"
                            @change="onCoverageChange()"
                            vid="umpdLimit"></select-input>
            </v-col>
          </v-row>
          <!-- PIP/MED -->
          <v-row>
            <v-col cols="12">
              <select-input label="PIP/MED Coverage"
                            :items="pipMEDLimitsOptions"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="quoteDetails.MedicalPIPLimit"
                            @change="onCoverageChange()"
                            vid="pipMedLimit"></select-input>
            </v-col>
          </v-row>
          <!-- Other Than Collision Deductible -->
          <v-row>
            <v-col cols="12">
              <h4 class="text-red">Deductible &mdash; Other Than Collision</h4>
            </v-col>
          </v-row>
          <v-row v-for="vehicle in vehicleList"
                 :key="`comp${vehicle.VehicleSequenceNumber}`">
            <v-col cols="12"
                   sm="6">
              {{vehicle.Description}}
            </v-col>
            <v-col cols="12"
                   sm="6">
              <select-input label="Deductible - Other Than Collision"
                            :items="comprehensiveDeductiblesOptions"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="vehicle.ComprehensiveDeductibleCode"
                            @change="onCoverageChange()"
                            :vid="`comp${vehicle.VehicleSequenceNumber}`"></select-input>
            </v-col>
          </v-row>
          <!-- Collision Deductible -->
          <v-row>
            <v-col cols="12">
              <h4 class="text-red">Deductible &mdash; Collision</h4>
            </v-col>
          </v-row>
          <v-row v-for="vehicle in vehicleList"
                 :key="`coll${vehicle.VehicleSequenceNumber}`">
            <v-col cols="12"
                   sm="6">
              {{vehicle.Description}}
            </v-col>
            <v-col cols="12"
                   sm="6">
              <select-input label="Deductible - Collision"
                            :items="collisionDeductiblesOptions"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="vehicle.CollisionDeductibleCode"
                            @change="onCoverageChange()"
                            :vid="`coll${vehicle.VehicleSequenceNumber}`"></select-input>
            </v-col>
          </v-row>
          <!-- Rental Car Reimbursement -->
          <v-row>
            <v-col cols="12">
              <select-input label="Rental Car Reimbursement"
                            :items="rentalLimitsOptions"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="quoteDetails.RentalReimbursementLimit"
                            @change="onCoverageChange()"
                            vid="rentalLimit"></select-input>
            </v-col>
          </v-row>
          <v-row v-if="quoteDetails.RentalReimbursementLimit && quoteDetails.RentalReimbursementLimit !== '' && quoteDetails.RentalReimbursementLimit !== null">
            <v-col cols="12"
                   sm="6">
              <h4 class="text-red">Apply Rental Car Reimbursement to:</h4>
            </v-col>
            <v-col cols="12"
                   sm="6">
              <keep-alive>
                <ValidationProvider tag="div"
                                    :bails="false"
                                    rules="required"
                                    name="vehicle rental"
                                    v-slot="{ errors, valid, required }">
                  <v-checkbox v-for="vehicle in vehicleList"
                              :key="vehicle.VehicleSequenceNumber"
                              :label="vehicle.Description"
                              hide-details="auto"
                              class="no-margin-check"
                              v-model="rentalArray"
                              :error-messages="errors"
                              :success="valid"
                              :value="vehicle.VehicleSequenceNumber"
                              type="checkbox">
                    <template v-slot:append>
                      <v-icon>mdi-asterisk</v-icon>
                    </template>
                  </v-checkbox>
                </ValidationProvider>
              </keep-alive>
            </v-col>
          </v-row>
          <!-- Roadside Assistance -->
          <v-row>
            <v-col cols="12">
              <select-input label="Roadside Assistance"
                            :items="roadsideLimitsOptions"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="quoteDetails.RoadsideAssistanceLimit"
                            @change="onCoverageChange()"
                            vid="roadsideLimit"></select-input>
            </v-col>
          </v-row>
          <v-row v-if="quoteDetails.RoadsideAssistanceLimit && quoteDetails.RoadsideAssistanceLimit !== '' && quoteDetails.RoadsideAssistanceLimit !== null">
            <v-col cols="12"
                   sm="6">
              <h4 class="text-red">Apply Roadside Assistance to:</h4>
            </v-col>
            <v-col cols="12"
                   sm="6">
              <keep-alive>
                <ValidationProvider tag="div"
                                    :bails="false"
                                    rules="required"
                                    name="Roadside Assistance"
                                    v-slot="{ errors, valid, required }">
                  <v-checkbox v-for="vehicle in vehicleList"
                              :key="vehicle.VehicleSequenceNumber"
                              :label="vehicle.Description"
                              hide-details="auto"
                              class="no-margin-check"
                              v-model="roadsideArray"
                              :error-messages="errors"
                              :success="valid"
                              :value="vehicle.VehicleSequenceNumber"
                              type="checkbox">
                    <template v-slot:append>
                      <v-icon>mdi-asterisk</v-icon>
                    </template>
                  </v-checkbox>
                </ValidationProvider>
              </keep-alive>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn @click="passes(onRecalculateSubmit)"
                     :disabled="recalculateDisabled"
                     class="tfb-button-min background-red float-right">
                Recalculate
              </v-btn>
              <br /><br />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   sm="6"
                   order="2"
                   order-sm="1">
              <v-btn @click="onBack"
                     v-bind:class="[ !isMobile ? 'float-right' : '', 'tfb-button-min', 'background-red' ]">
                <v-icon>mdi-chevron-left</v-icon>
                Go Back
              </v-btn>
            </v-col>
            <v-col cols="12"
                   sm="6"
                   order="1"
                   order-sm="2">
              <v-btn type="submit"
                     :disabled="formDisabled"
                     class="tfb-button-min background-red float-right">
                Continue
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
    </v-container>
  </div>
</template>
<script>
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import tfbHelperMixin from '@/mixins/helpers';
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbMetaMixin from '@/mixins/metaInfo';
  import { genericErrorMessage } from '@/mixins/errorMessages';
  import RequiredFieldsRow from '@/views/layout/components/RequiredFieldsRow.vue';
/* eslint prefer-destructuring: 0 */
/* eslint object-shorthand: 0 */
/* eslint no-mixed-operators: 0 */

  export default {
    name: 'AutoQuoteCoverages',
    mixins: [tfbHelperMixin, tfbDataMixin, tfbMetaMixin],
    components: {
      ValidationObserver,
      ValidationProvider,
      RequiredFieldsRow,
    },
    data() {
      return {
        formDisabled: false,
        recalculateDisabled: true,
        quoteDetails: null,
        vehicleList: null,
        bodilyInjuryOptions: null,
        propertyDamageOptions: null,
        uninsuredBodilyInjuryLimitsOptions: null,
        uninsuredPropertyDamageLimitsOptions: null,
        collisionDeductiblesOptions: null,
        comprehensiveDeductiblesOptions: null,
        pipMEDLimitsOptions: null,
        rentalLimitsOptions: null,
        roadsideLimitsOptions: null,
        isMobile: this.$vuetify.breakpoint.smAndDown,
        rentalArray: [],
        roadsideArray: [],
        averageMinMax: null,
        customMinMax: null,
        averageOverlay: false,
        customOverlay: false,
      };
    },
    computed: {
      metaDescription() {
        return 'Looking for an auto insurance quote? Use our online tool to learn how Texas Farm Bureau Insurance can help provide coverage for your precious ride.';
      },
      metaPageTitle() {
        return 'Coverages | Auto Rate Quote';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/quotes/auto/coverages`;
      },
      agreementNumber() {
        try {
          return this.$store.getters['quotes/agreementNumber'];
        } catch (error) {
          return '';
        }
      },
      subAgreementNumber() {
        try {
          return this.$store.getters['quotes/subAgreementNumber'];
        } catch (error) {
          return '';
        }
      },
      quoteData() {
        try {
          return this.$store.getters['quotes/quote'];
        } catch (error) {
          return null;
        }
      },
    },
    methods: {
      getBodilyInjuryLimits() {
        this.$store.dispatch('quotes/getBodilyInjuryLimits', { sEffectiveDate: this.quoteDetails.EffectiveDate, sTfbId: this.trackingId })
          .then((bodilyInjuryResponse) => {
            this.bodilyInjuryOptions = bodilyInjuryResponse;
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
      getPropertyDamageLimits() {
        this.$store.dispatch('quotes/getPropertyDamageLimits', { sEffectiveDate: this.quoteDetails.EffectiveDate, sTfbId: this.trackingId })
          .then((propertyDamageResponse) => {
            this.propertyDamageOptions = propertyDamageResponse;
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
      getUninsuredBodilyInjuryLimits() {
        this.$store.dispatch('quotes/getUninsuredBodilyInjuryLimits', { sEffectiveDate: this.quoteDetails.EffectiveDate, sTfbId: this.trackingId })
          .then((uninsuredBodilyInjuryLimitsResponse) => {
            this.uninsuredBodilyInjuryLimitsOptions = uninsuredBodilyInjuryLimitsResponse;
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
      getUninsuredPropertyDamageLimits() {
        this.$store.dispatch('quotes/getUninsuredPropertyDamageLimits', { sEffectiveDate: this.quoteDetails.EffectiveDate, sTfbId: this.trackingId })
          .then((uninsuredPropertyDamageLimitsResponse) => {
            this.uninsuredPropertyDamageLimitsOptions = uninsuredPropertyDamageLimitsResponse;
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
      getCollisionDeductibles() {
        this.$store.dispatch('quotes/getCollisionDeductibles', { sEffectiveDate: this.quoteDetails.EffectiveDate, sTfbId: this.trackingId })
          .then((collisionDeductiblesResponse) => {
            this.collisionDeductiblesOptions = collisionDeductiblesResponse;
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
      getComprehensiveDeductibles() {
        this.$store.dispatch('quotes/getComprehensiveDeductibles', { sEffectiveDate: this.quoteDetails.EffectiveDate, sTfbId: this.trackingId })
          .then((comprehensiveDeductiblesResponse) => {
            this.comprehensiveDeductiblesOptions = comprehensiveDeductiblesResponse;
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
      getPIPMEDLimits() {
        this.$store.dispatch('quotes/getPIPMEDLimits', { sEffectiveDate: this.quoteDetails.EffectiveDate, sTfbId: this.trackingId })
          .then((pipMEDLimitsResponse) => {
            this.pipMEDLimitsOptions = pipMEDLimitsResponse;
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
      getRentalLimits() {
        this.$store.dispatch('quotes/getRentalLimits', { sEffectiveDate: this.quoteDetails.EffectiveDate, sTfbId: this.trackingId })
          .then((rentalLimitsResponse) => {
            this.rentalLimitsOptions = rentalLimitsResponse;
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
      getRoadsideAssistanceLimits() {
        this.$store.dispatch('quotes/getRoadsideAssistanceLimits', { sEffectiveDate: this.quoteDetails.EffectiveDate, sTfbId: this.trackingId })
          .then((roadsideLimitsResponse) => {
            this.roadsideLimitsOptions = roadsideLimitsResponse;
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
      setupQuote() {
        if (this.quoteData && this.quoteData !== null) {
          this.quoteDetails = this.quoteData.Quote;
          this.vehicleList = this.quoteData.Vehicles;

          if (this.vehicleList && this.vehicleList.length > 0) {
            if (!this.vehicleList[0].Year || this.vehicleList[0].Year === '' || this.vehicleList[0].Year === null) {
              // No saved vehicle information, so send them back
              this.$router.push({ name: 'quoteVehicle' });
            }
          } else {
            // No saved vehicle information, so send them back
            this.$router.push({ name: 'quoteVehicle' });
          }

          if (this.quoteData.Operators && this.quoteData.Operators.length > 0) {
            if (!this.quoteData.Operators[0].DriversLicenseNumber || this.quoteData.Operators[0].DriversLicenseNumber === '' || this.quoteData.Operators[0].DriversLicenseNumber === null) {
              // No saved driver information, so send them back
              this.$router.push({ name: 'quoteDriver' });
            }
          } else {
            // No saved driver information, so send them back
            this.$router.push({ name: 'quoteDriver' });
          }

          if (this.quoteDetails && this.quoteDetails !== null && this.quoteDetails.AgreementNumber !== null && this.quoteDetails.AgreementNumber !== '') {
            this.getBodilyInjuryLimits();
            this.getPropertyDamageLimits();
            this.getUninsuredBodilyInjuryLimits();
            this.getUninsuredPropertyDamageLimits();
            this.getCollisionDeductibles();
            this.getComprehensiveDeductibles();
            this.getPIPMEDLimits();
            this.getRentalLimits();
            this.getRoadsideAssistanceLimits();

            // If custom, get values; else start with average
            if (!this.quoteDetails.SelectedCoverageLevel || this.quoteDetails.SelectedCoverageLevel !== 'Custom') {
              // Start with average coverage
              this.setupCoverage('Default');
            } else {
              this.setupVehicleCoverage();
            }

            // Get the various min/max amounts
            this.setupMinMax();
          } else {
            // No saved quote information, so go back to home
            this.$store.commit('quotes/SET_TIMEOUT', true);
            this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
          }
        } else {
          // No saved quote information, so go back to home
          this.$store.commit('quotes/SET_TIMEOUT', true);
          this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
        }
      },
      setupMinMax() {
        this.averageOverlay = true;
        this.averageMinMax = '-';
        this.customOverlay = true;
        this.customMinMax = 'N/A';

        this.$store.dispatch('quotes/getMinMaxAmount', {
          sAgreementNumber: this.quoteDetails.AgreementNumber,
          sSubAgreementNumber: this.quoteDetails.SubAgreementNumber,
          sCoverageLevel: 'Default',
          sTfbId: this.trackingId,
        })
          .then((premiumResponse) => {
            if (premiumResponse.MinimumAmount !== null && premiumResponse.MaximumAmount !== null) {
              if (premiumResponse.MinimumAmount === premiumResponse.MaximumAmount) {
                this.averageMinMax = `${this.formatMoney((premiumResponse.MinimumAmount / 6).toString(), 2)}<br />per month`;
              } else {
                this.averageMinMax = `${this.formatMoney((premiumResponse.MinimumAmount / 6).toString(), 2)} - ${this.formatMoney((premiumResponse.MaximumAmount / 6).toString(), 2)}
<br />per month`;
              }
            } else {
              this.averageMinMax = 'Unable to fetch amounts';
            }

            if (premiumResponse.ErrorMessage !== null && premiumResponse.ErrorMessage !== '') {
              this.$store.dispatch('app/setErrorMessage', premiumResponse.ErrorMessage);
            }
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
            this.averageMinMax = 'Unable to fetch amounts';
          })
          .finally(() => {
            this.averageOverlay = false;
            if (this.quoteDetails.SelectedCoverageLevel === 'Custom') {
              this.$store.dispatch('quotes/getMinMaxAmount', {
                sAgreementNumber: this.quoteDetails.AgreementNumber,
                sSubAgreementNumber: this.quoteDetails.SubAgreementNumber,
                sCoverageLevel: 'Custom',
                sTfbId: this.trackingId,
              })
                .then((premiumResponse) => {
                  if (premiumResponse.MinimumAmount !== null && premiumResponse.MaximumAmount !== null) {
                    if (premiumResponse.MinimumAmount === premiumResponse.MaximumAmount) {
                      this.customMinMax = `${this.formatMoney((premiumResponse.MinimumAmount / 6).toString(), 2)}<br />per month`;
                    } else {
                      this.customMinMax = `${this.formatMoney((premiumResponse.MinimumAmount / 6).toString(), 2)} -
${this.formatMoney((premiumResponse.MaximumAmount / 6).toString(), 2)}<br />per month`;
                    }
                  } else {
                    this.customMinMax = 'Unable to fetch amounts';
                  }

                  if (premiumResponse.ErrorMessage !== null && premiumResponse.ErrorMessage !== '') {
                    this.$store.dispatch('app/setErrorMessage', premiumResponse.ErrorMessage);
                  }
                })
                .catch((error) => {
                  if (error.toString().indexOf('status code 401') >= 0) {
                    // Session has timed out
                    this.$store.commit('quotes/SET_TIMEOUT', true);
                    this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
                  }
                  this.$store.dispatch('app/setErrorMessage', error.toString());
                  this.customMinMax = 'Unable to fetch amounts';
                })
                .finally(() => {
                  this.customOverlay = false;
                  this.formDisabled = false;
                });
            } else {
              this.customMinMax = 'N/A';
              this.customOverlay = false;
              this.formDisabled = false;
            }
          });
      },
      setupCoverage(coverageLevel) {
        this.quoteDetails.SelectedCoverageLevel = coverageLevel;

        this.$store.dispatch('quotes/getLimitsByLevel', { sCoverageLevel: coverageLevel, sEffectiveDate: this.quoteDetails.EffectiveDate, sTfbId: this.trackingId })
          .then((limitsResponse) => {
            let collValue = '';
            let compValue = '';

            for (let l = 0; l < limitsResponse.length; l += 1) {
              const foundLimit = limitsResponse[l];

              switch (foundLimit.DisplayText) {
                case 'BI':
                  this.quoteDetails.BodilyInjuryLimit = this.valueCheck(foundLimit.Value) ? foundLimit.Value : '';
                  break;
                case 'PD':
                  this.quoteDetails.PropertyDamageLimit = this.valueCheck(foundLimit.Value) ? foundLimit.Value : '';
                  break;
                case 'UMBI':
                  this.quoteDetails.UninsuredBodilyInjuryLimit = this.valueCheck(foundLimit.Value) ? foundLimit.Value : '';
                  break;
                case 'UMPD':
                  this.quoteDetails.UninsuredPropertyDamageLimit = this.valueCheck(foundLimit.Value) ? foundLimit.Value : '';
                  break;
                case 'PIPMED':
                  this.quoteDetails.MedicalPIPLimit = this.valueCheck(foundLimit.Value) ? foundLimit.Value : '';
                  break;
                case 'Rental':
                  this.quoteDetails.RentalReimbursementLimit = this.valueCheck(foundLimit.Value) ? foundLimit.Value : '';
                  break;
                case 'Roadside':
                case 'Towing':
                  this.quoteDetails.RoadsideAssistanceLimit = this.valueCheck(foundLimit.Value) ? foundLimit.Value : '';
                  break;
                case 'CollDeduct':
                  collValue = this.valueCheck(foundLimit.Value) ? foundLimit.Value : '';
                  break;
                case 'OTCDeduct':
                  compValue = this.valueCheck(foundLimit.Value) ? foundLimit.Value : '';
                  break;
                default:
                  break;
              }
            }

            for (let v = 0; v < this.vehicleList.length; v += 1) {
              const foundVehicle = this.vehicleList[v];

              foundVehicle.CollisionDeductibleCode = collValue;
              foundVehicle.ComprehensiveDeductibleCode = compValue;
            }

            this.setupVehicleCoverage();
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });

        if (this.quoteDetails.SelectedCoverageLevel !== 'Custom') {
          this.customMinMax = 'N/A';
          this.formDisabled = false;
          this.recalculateDisabled = true;
        }
      },
      setupVehicleCoverage() {
        for (let v = 0; v < this.vehicleList.length; v += 1) {
          const foundVehicle = this.vehicleList[v];

          if (foundVehicle.IncludeRentalReimbursement === 'Y') {
            this.rentalArray.push(foundVehicle.VehicleSequenceNumber);
          }

          if (foundVehicle.IncludeRoadsideAssistance === 'Y') {
            this.roadsideArray.push(foundVehicle.VehicleSequenceNumber);
          }
        }

        // Is a rental limit set but no vehicles?
        if (this.quoteDetails.RentalReimbursementLimit !== null && this.quoteDetails.RentalReimbursementLimit !== ''
          && this.quoteDetails.RentalReimbursementLimit !== '0' && this.rentalArray.length === 0) {
          // Add all vehicles
          for (let v = 0; v < this.vehicleList.length; v += 1) {
            const foundVehicle = this.vehicleList[v];
            this.rentalArray.push(foundVehicle.VehicleSequenceNumber);
          }
        }

        if (this.quoteDetails.RoadsideAssistanceLimit !== null && this.quoteDetails.RoadsideAssistanceLimit !== ''
          && this.quoteDetails.RoadsideAssistanceLimit !== '0' && this.roadsideArray.length === 0) {
          // Add all vehicles
          for (let v = 0; v < this.vehicleList.length; v += 1) {
            const foundVehicle = this.vehicleList[v];
            this.roadsideArray.push(foundVehicle.VehicleSequenceNumber);
          }
        }
      },
      onBack() {
        this.$store.dispatch('app/clearErrorMessage');
        this.$router.push({ name: 'quoteDriver' });
      },
      onCoverageChange() {
        this.quoteDetails.SelectedCoverageLevel = 'Custom';
        this.customMinMax = '';
        this.formDisabled = true;
        this.recalculateDisabled = false;
      },
      onRecalculateSubmit() {
        this.$store.dispatch('app/clearErrorMessage');
        this.customOverlay = true;
        this.scrollToTop();

        // Update the coverage information
        this.$store.dispatch('quotes/updateQuoteCoverages', {
          sQuote: this.quoteDetails,
          sVehicles: this.vehicleList,
          sRental: this.rentalArray,
          sRoadside: this.roadsideArray,
          sTfbId: this.trackingId,
        })
          .then(() => {
            this.$store.dispatch('quotes/getMinMaxAmount', {
              sAgreementNumber: this.quoteDetails.AgreementNumber,
              sSubAgreementNumber: this.quoteDetails.SubAgreementNumber,
              sCoverageLevel: 'Custom',
              sTfbId: this.trackingId,
            })
              .then((premiumResponse) => {
                if (premiumResponse.MinimumAmount !== null && premiumResponse.MaximumAmount !== null) {
                  if (premiumResponse.MinimumAmount === premiumResponse.MaximumAmount) {
                    this.customMinMax = `${this.formatMoney((premiumResponse.MinimumAmount / 6).toString(), 2)}<br />per month`;
                  } else {
                    this.customMinMax = `${this.formatMoney((premiumResponse.MinimumAmount / 6).toString(), 2)} -
${this.formatMoney((premiumResponse.MaximumAmount / 6).toString(), 2)}<br />per month`;
                  }
                } else {
                  this.customMinMax = 'Unable to fetch amounts';
                }

                if (premiumResponse.ErrorMessage !== null && premiumResponse.ErrorMessage !== '') {
                  this.$store.dispatch('app/setErrorMessage', premiumResponse.ErrorMessage);
                }
              })
              .catch((error) => {
                if (error.toString().indexOf('status code 401') >= 0) {
                  // Session has timed out
                  this.$store.commit('quotes/SET_TIMEOUT', true);
                  this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
                }
                this.$store.dispatch('app/setErrorMessage', error.toString());
                this.customMinMax = 'Unable to fetch amounts';
              })
              .finally(() => {
                this.customOverlay = false;
                this.formDisabled = false;
                this.recalculateDisabled = true;
              });
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
            this.$store.dispatch('app/setIsLoading', false);
            this.customOverlay = false;
            this.formDisabled = false;
            this.recalculateDisabled = true;
          });
      },
      onSubmit() {
        this.$store.dispatch('app/clearErrorMessage');
        this.$store.dispatch('app/setIsLoading', true);

        // Update the coverage information
        this.$store.dispatch('quotes/updateQuoteCoverages', {
          sQuote: this.quoteDetails,
          sVehicles: this.vehicleList,
          sRental: this.rentalArray,
          sRoadside: this.roadsideArray,
          sTfbId: this.trackingId,
        })
          .then((quoteReturn) => {
            if (quoteReturn) {
              this.$router.push({ name: 'quoteDisclaimer' });
            } else {
              this.$store.dispatch('app/setErrorMessage', genericErrorMessage);
              this.$store.dispatch('app/setIsLoading', false);
            }
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
            this.$store.dispatch('app/setIsLoading', false);
          });
      },
    },
    mounted() {
      this.$store.dispatch('app/clearErrorMessage');
      this.$store.dispatch('app/setIsLoading', true);
      this.setupQuote();
      this.$store.dispatch('app/setIsLoading', false);
    },
  };
</script>
<style lang="scss">

  .tfb-quote-box {
    background-color: $white;
    color: $black;
  }

  .selected-item {
    background-color: $tfb-blue !important;
    color: $white !important;
  }

    .selected-item a,
    .selected-item a:visited {
      color: $white !important;
      text-decoration: underline !important;
    }

    .selected-item a:hover {
      color: $white !important;
      text-decoration: none !important;
    }
</style>
