<template>
  <v-dialog v-model="showTextEnable"
            max-width="300px">
    <v-card>
      <v-card-title class="background-darker-blue">
        <h3 class="tfb-title-text-small">Enable Text Messaging</h3>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              To enable receiving text messages from Texas Farm Bureau, please
              text the word "START" to <a :href="linkTextStartPhoneNumber">{{displayTextStartPhoneNumber | formatPhone}}</a>.
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import { dispatch, sync } from 'vuex-pathify';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'EnableTextDialog',
    mixins: [tfbHelperMixin],
    data() {
      return {
        displayTextStartPhoneNumber: '',
        linkTextStartPhoneNumber: '',
      };
    },
    computed: {
      showTextEnable: sync('member/showTextEnable'),
    },
    methods: {
      getTextPhoneNumber() {
        dispatch('app/getConfiguration', { sSection: 'Value', sTitle: 'EnableTextPhoneNumber' })
          .then((response) => {
            if (response && response !== '') {
              this.displayTextStartPhoneNumber = response;
              this.linkTextStartPhoneNumber = `sms:+1${response}`;
            }
          })
          .catch(() => {
            // Do nothing
          });
      },
    },
    mounted() {
      this.getTextPhoneNumber();
    },
  };
</script>
<style lang="scss">

</style>
