<template>
  <v-dialog v-model="showPhoneVerification"
            persistent
            max-width="600px">
    <v-card>
      <v-card-title class="background-darker-blue">
        <h3 class="tfb-title-text-small text-uppercase">Phone Verification</h3>
      </v-card-title>
      <ValidationObserver ref="obv" v-slot="{ passes }">
        <v-form ref="verificationForm"
                @submit.stop.prevent="passes(verifyPhone)">
          <v-card-text>
            <v-container class="py-0 tfb-component-container">
              <v-row>
                <v-col cols="12"
                       class="py-0">
                  <p class="text-black">
                    To verify your phone number, please enter the code sent via text message (SMS) to the number you provided above.
                    If you do not receive the code within the next few minutes, close the verification box and click the link:
                    &ldquo;Click here to verify your phone number&rdquo;. If you did not receive this code or need help, please
                    call <a :href="contactUsPhone.link">{{ contactUsPhone.display }}</a>.
                  </p>
                </v-col>
              </v-row>
              <v-row class="tfb-notification"
                     role="alert"
                     v-if="showError">
                <v-col cols="12"
                       class="tfb-notification-notice">
                  <span v-html="errorMessage"></span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"
                       class="py-0">
                  <v-otp-input length="6"
                               v-model="enteredVerificationCode"
                               :disabled="saveOverlay"
                               @finish="verifyPhone"></v-otp-input>
                  <!--<text-input rules="required|min:6|max:8"
                              minlength="6"
                              maxlength="8"
                              label="Verification Code"
                              v-model="enteredVerificationCode"></text-input>-->
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="tfb-button background-red"
                   @click="onClose">Close</v-btn>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
      <v-overlay absolute
                 :value="saveOverlay">
        <v-progress-circular indeterminate
                             size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </v-dialog>
</template>
<script>
  import { dispatch, sync } from 'vuex-pathify';
  import { ValidationObserver } from 'vee-validate';
  import tfbHelperMixin from '@/mixins/helpers';
  import tfbDataMixin from '@/mixins/tfbData';

  export default {
    name: 'PhoneVerification',
    mixins: [tfbHelperMixin, tfbDataMixin],
    components: {
      ValidationObserver,
    },
    data() {
      return {
        saveOverlay: false,
        enteredVerificationCode: null,
        showError: false,
        errorMessage: null,
      };
    },
    computed: {
      showPhoneVerification: sync('member/showPhoneVerification'),
    },
    methods: {
      onClose() {
        this.showPhoneVerification = false;
      },
      verifyPhone() {
        this.saveOverlay = true;
        dispatch('member/doPhoneVerification', {
          sVerificationCode: this.enteredVerificationCode, sTfbId: this.trackingId,
        })
          .then((verifyResponse) => {
            if (verifyResponse && verifyResponse !== null && verifyResponse !== '') {
              this.saveOverlay = false;
              this.enteredVerificationCode = null;
              this.showPhoneVerification = false;
            } else {
              this.showError = true;
              this.errorMessage = 'Invalid verification code. Please try again.';
              this.saveOverlay = false;
              this.enteredVerificationCode = '';
            }
          })
          .catch((error) => {
            this.showError = true;
            this.errorMessage = error;
            this.saveOverlay = false;
          });
      },
    },
  };
</script>
<style lang="scss">

</style>
