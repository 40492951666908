<template>
  <keep-alive>
    <ValidationProvider tag="div"
                        :bails="false"
                        :rules="rules"
                        :name="name || $attrs.label"
                        :vid="vid"
                        v-slot="{ errors, valid, required }">
      <v-select outlined
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                v-model="innerValue"
                v-bind="$attrs"
                v-on="$listeners">
        <template v-slot:append>
          <v-icon>mdi-menu-down</v-icon>
          <v-icon v-if="required">mdi-asterisk</v-icon>
        </template>
      </v-select>
    </ValidationProvider>
  </keep-alive>
</template>
<script>
  import { ValidationProvider } from 'vee-validate';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'SelectInput',
    mixins: [tfbHelperMixin],
    components: {
      ValidationProvider,
    },
    props: {
      vid: {
        type: String,
        default: undefined,
      },
      name: {
        type: String,
        default: undefined,
      },
      rules: {
        type: [Object, String],
        default: '',
      },
      value: {
        type: null,
      },
    },
    data() {
      return {
        innerValue: '',
      };
    },
    watch: {
      // Handles internal model changes.
      innerValue(value) {
        this.$emit('input', value);
      },
      // Handles external model changes.
      value(newVal) {
        this.innerValue = newVal;
      },
    },
    created() {
      if (this.value) {
        this.innerValue = this.value;
      }
    },
  };
</script>
