<template>
  <div id="policyholder-login"
       class="background-darker-blue tfb-conversion">
    <v-container fluid
                 class="tfb-conversion-box">
      <span class="tfb-title-text-medium text-uppercase">Confirm Email Address</span>
      <v-row v-if="!showConfirmation">
        <v-col>
          We need to verify <strong>{{emailAddress}}</strong> is your correct email address.
        </v-col>
      </v-row>
      <v-row v-if="!showConfirmation">
        <v-col>
          Please use the button below to complete the one-time verification process.
        </v-col>
      </v-row>
      <v-row v-if="showConfirmation">
        <v-col>
          <p>You're almost there! We sent an email to <strong>{{emailAddress}}</strong>.</p>
          <p>Just click on the link in that email to confirm your email address. If you don't see it, you may need to check your spam or junk folder.</p>
          <p>Still can't find the email?</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center">
          <v-btn @click="sendVerification()"
                 :disabled="formDisabled"
                 class="tfb-button-min background-red"
                 v-if="!showConfirmation">Click here to verify your email address</v-btn>
          <v-btn @click="sendVerification()"
                 :disabled="formDisabled"
                 class="tfb-button-min background-red"
                 v-if="showConfirmation">Resend Email</v-btn>
        </v-col>
      </v-row>
      <v-row v-if="showConfirmation">
        <v-col class="text-center">
          Need Help? Contact Us at <a :href="contactUsPhone.link">{{ contactUsPhone.display }}</a>
        </v-col>
      </v-row>
      <!-- Need to change email -->
      <v-row>
        <v-col class="text-center">
          <a href=""
             v-on:click.prevent="toggleEmailChange()"
             hreflang="en-us"
             aria-label="Need to update your email">Need to update your email?</a>
        </v-col>
      </v-row>
      <ValidationObserver ref="obv"
                          v-slot="{ handleSubmit }"
                          v-if="showEmailChange">
        <v-form @submit.stop.prevent="handleSubmit(submitEmailChange)">
          <v-row>
            <v-col cols="12">
              <text-input rules="required|email|valid_domain|confirmed:confirmNewEmailAddress|min:4|max:60"
                          minlength="4"
                          maxlength="60"
                          label="Email Address"
                          type="email"
                          v-model="newEmailAddress"></text-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <text-input rules="required|email|min:4|max:60"
                          minlength="4"
                          maxlength="60"
                          label="Confirm Email Address"
                          type="email"
                          v-model="confirmNewEmailAddress"
                          vid="confirmNewEmailAddress"></text-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn block
                     :disabled="formDisabled"
                     type="submit"
                     class="tfb-button background-red">Change Email Address</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
    </v-container>
  </div>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import { ValidationObserver } from 'vee-validate';
  import tfbMetaInfo from '@/mixins/metaInfo';
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'EmailVerification',
    mixins: [tfbMetaInfo, tfbHelperMixin, tfbDataMixin],
    components: {
      ValidationObserver,
    },
    directives: {},
    data() {
      return {
        newEmailAddress: null,
        confirmNewEmailAddress: null,
        showConfirmation: false,
        showEmailChange: false,
        formDisabled: false,
      };
    },
    computed: {
      metaPageTitle() {
        return 'Email Verification | PolicyHolder';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/policyholder/verify-email`;
      },
      emailAddress() {
        try {
          return this.$store.getters['member/emailAddress'];
        } catch (error) {
          return '';
        }
      },
      emailVerified() {
        try {
          return this.$store.getters['member/verifiedIndicator'];
        } catch (error) {
          return true;
        }
      },
    },
    methods: {
      toggleEmailChange() {
        if (this.showEmailChange === true) {
          this.showEmailChange = false;
        } else {
          this.showEmailChange = true;
        }
      },
      sendVerification() {
        this.emailSuccess = false;
        this.verificationSuccess = false;
        dispatch('app/setIsLoading', true);
        this.$store.dispatch('app/clearErrorMessage');
        this.formDisabled = true;
        dispatch('member/sendEmailVerification', {
          sEmailAddress: this.emailAddress, sIncludeAllMembers: true, sTfbId: this.trackingId,
        })
          .then(() => {
            // Update was successful
            this.showConfirmation = true;
            this.formDisabled = false;
            // Hide change email section
            this.showEmailChange = false;
            dispatch('app/setIsLoading', false);
          })
          .catch((error) => {
            this.formDisabled = false;
            dispatch('app/setErrorMessage', error);
            dispatch('app/setIsLoading', false);
          });
      },
      submitEmailChange() {
        dispatch('app/setIsLoading', true);
        this.$store.dispatch('app/clearErrorMessage');
        this.formDisabled = true;
        // Make sure new email address is different than old email address
        if (this.emailAddress.toUpperCase().trim() === this.newEmailAddress.toUpperCase().trim()) {
          dispatch('app/setErrorMessage', 'New email address is the same as the current email address. Please enter a different email address to change your email address.');
          this.formDisabled = false;
          dispatch('app/setIsLoading', false);
        } else {
          dispatch('member/updateEmailAddress', {
            sOldEmailAddress: this.emailAddress,
            sNewEmailAddress: this.newEmailAddress,
            sTfbId: this.trackingId,
          })
            .then((response) => {
              if (response.indexOf('verification request has been received') >= 0) {
                // Hide change email section
                this.showEmailChange = false;
                // Show success message
                this.showConfirmation = true;
                dispatch('app/setIsLoading', false);
                this.formDisabled = true;
              } else {
                dispatch('app/setErrorMessage', response);
                dispatch('app/setIsLoading', false);
                this.formDisabled = false;
              }
            })
            .catch((error) => {
              dispatch('app/setErrorMessage', error.toString());
              dispatch('app/setIsLoading', false);
              this.formDisabled = false;
              dispatch('app/logToULS', {
                logLevel: 'Error',
                logMessage: error,
                fileName: 'EmailVerification.vue',
                methodName: 'submitEmailChange-Catch',
                trackerId: this.trackingId,
              });
            });
        }
      },
    },
    watch: {},
    created() {},
    mounted() {
      dispatch('app/logToULS', {
        logLevel: 'Info',
        logMessage: `Email verified? ${this.emailVerified}`,
        fileName: 'EmailVerification.vue',
        methodName: 'mounted',
        trackerId: this.trackingId,
      });
      // Check to see if application is available/enabled
      dispatch('app/doApplicationCheck', { sApplicationName: 'Policyholder', sTfbId: this.trackingId })
        .then((returnedStatus) => {
          // Is there an outage message or not?
          if (returnedStatus === '') {
            // No outage message; is this person logged in?
            if (this.isLoggedIn) {
              // Logged in; is this log in still valid?
              const includedToken = this.$route.query.access_token;
              dispatch('member/validateLogin', { sToken: includedToken, sTfbId: this.trackingId })
                .then((loginValid) => {
                  if (loginValid) {
                    // Is the email still in need of verification?
                    if (this.emailVerified) {
                      // Email already verified, redirect to Account Summary
                      this.$router.push({ name: 'policyholderAccountSummary' });
                    }
                    dispatch('app/setIsLoading', false);
                  }
                })
                .catch((error) => {
                  dispatch('app/setErrorMessage', error.toString());
                  dispatch('app/setIsLoading', false);
                });
            } else {
              // See if this is an SSO session
              const includedToken = this.$route.query.access_token;

              if (includedToken && includedToken !== '') {
                // We have a token, see if we can log in
                dispatch('member/doSsoLogin', { sAccessToken: includedToken, sTfbId: this.trackingId })
                  .then((response) => {
                    dispatch('app/logToULS', {
                      logLevel: 'Info',
                      logMessage: response,
                      fileName: 'EmailVerification.vue',
                      methodName: 'doSsoLogin-Success',
                      trackerId: this.trackingId,
                    });

                    if (response === 'invalid_grant') {
                      dispatch('app/setErrorMessage', 'Username/Password Not Found');
                      dispatch('app/setIsLoading', false);
                    }

                    if (response !== '') {
                      dispatch('app/setErrorMessage', response);
                      dispatch('app/setIsLoading', false);
                    } else {
                      dispatch('app/setIsLoading', false);
                    }
                  })
                  .catch((error) => {
                    dispatch('app/setErrorMessage', error.toString());
                    dispatch('app/setIsLoading', false);
                    dispatch('app/logToULS', {
                      logLevel: 'Error',
                      logMessage: error,
                      fileName: 'EmailVerification.vue',
                      methodName: 'doSsoLogin-Catch',
                      trackerId: this.trackingId,
                    });
                  });
              }
              dispatch('app/setIsLoading', false);
            }
          } else {
            dispatch('app/setErrorMessage', returnedStatus);
            dispatch('app/setIsLoading', false);
            this.formDisabled = true;
          }
        })
        .catch((error) => {
          dispatch('app/setErrorMessage', error.toString());
          dispatch('app/setIsLoading', false);
        });
    },
  };
</script>
<style lang="scss">

</style>
