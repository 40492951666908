<template>
  <span>
    <span id="policyholder-header-greeting"
          class="tfb-title-text-large text-darker-blue">
      Good {{timeOfDay}}{{memberFirstName}}!<br />
    </span>
    <strong v-if="paymentWordingDisplay === 'Y'">
      <br />
      Making a payment via your checking or savings account? You can now save your account data in our new Wallet feature!<br /><br/>
      When adding your checking or savings account information, click the check box at the bottom, &ldquo;Add to Wallet&rdquo;,
      click &ldquo;SAVE&rdquo; and your account information will be saved for future use! Note: This feature is not available
      when paying via the Guest Payment Site.<br/><br/>
    </strong>
  </span>
</template>
<script>
  import _ from 'lodash';
  import { getHours } from 'date-fns';

  export default {
    name: 'PolicyHolderHeaderGreeting',
    computed: {
      timeOfDay() {
        let type = 'Day';
        const currentHour = getHours(new Date());

        if (currentHour < 12) {
          type = 'Morning';
        } else if (currentHour >= 12 && currentHour < 18) {
          type = 'Afternoon';
        } else if (currentHour >= 18) {
          type = 'Evening';
        }

        return type;
      },
      memberFirstName() {
        try {
          const storedName = this.$store.getters['member/memberFirstName'];

          if (storedName && storedName !== '') {
            if (storedName.length === 7 && _.startsWith(storedName.toLowerCase(), 'ut', 0)) {
              return `, ${storedName.toUpperCase()}`;
            }

            return `, ${_.startCase(storedName.toLowerCase())}`;
          }
          return '';
        } catch (error) {
          return '';
        }
      },
      paymentWordingDisplay() {
        try {
          return this.$store.getters['app/paymentWordingDisplay'];
        } catch (error) {
          return 'N';
        }
      },
    },
  };
</script>
