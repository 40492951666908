<template>
  <v-container fluid
               class="tfb-container tfb-service-tabs-container">
    <v-row>
      <v-col cols="12"
             md="6"
             class="ml-auto tfb-header-overlay">
        <v-tabs grow
                icons-and-text
                show-arrows
                v-model="tab"
                class="tfb-service-tabs"
                v-if="serviceItems">
          <v-tabs-slider></v-tabs-slider>
          <v-tab v-for="service in serviceItems"
                 v-bind:key="service.id"
                 :href="`#tab-${service.ServiceType}`"
                 :title="`${service.Title} Quote`">
            <img v-if="tab === `tab-${service.ServiceType}`"
                 v-bind:src="`${imageRootPath}policy-icons/${service.ServiceType}-blue.png`"
                 v-bind:id="`${service.ServiceType}-services-icon`"
                 aria-hidden="true"
                 v-bind:alt="service.Title + ' Insurance'"  />
            <img v-else
                 v-bind:src="`${imageRootPath}policy-icons/${service.ServiceType}-black.png`"
                 v-bind:id="`${service.ServiceType}-services-icon`"
                 aria-hidden="true"
                 v-bind:alt="service.Title + ' Insurance'" />
          </v-tab>
          <v-tab-item v-for="service in serviceItems"
                      v-bind:key="service.id"
                      :value="'tab-' + service.ServiceType">
            <v-card flat
                    tile>
              <v-card-text class="align-center">
                <h2 class="tfb-title-text-medium text-darkest-blue text-uppercase text-center">
                  {{ service.Title }} Quote
                </h2>
                {{ service.TagLine }}
                <br />
                <br />
                <v-btn :to="{ name: 'quoteEntry', params: { type: service.ServiceType }}"
                       v-if="service.AllowQuotes"
                       hreflang="en-us"
                       class="tfb-quote-button-min background-red">
                  Let's Begin
                </v-btn>
                <v-btn :to="{ name: 'countyLookup' }"
                       v-else
                       hreflang="en-us"
                       class="tfb-quote-button-min background-red">
                  Find a Local Agent
                </v-btn>
              </v-card-text>
              <v-card-actions class="tfb-card-footer text-center"
                              v-if="service.AllowQuotes">
                <router-link :to="{ name: 'quoteEntry', params: { type: service.ServiceType }}"
                             hreflang="en-us">
                  Retrieve Saved Quote
                </router-link> |
                <router-link :to="{ name: 'countyLookup'}"
                             hreflang="en-us">Find an Agent</router-link>
              </v-card-actions>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'ServicesTabs',
    mixins: [tfbHelperMixin],
    data() {
      return {
        tab: null,
        serviceItems: null,
      };
    },
    created() {
      this.fetchData();
    },
    methods: {
      fetchData() {
        this.serviceItems = null;
        this.$store.dispatch('services/getServices', { sTfbId: this.trackingId })
          .then((response) => {
            this.serviceItems = response;
          });
      },
    },
  };
</script>
<style lang="scss">
  .tfb-service-tabs-container {
    min-height: 277px;
  }

  .tfb-service-tabs .v-tabs--icons-and-text > .v-tabs-bar {
    height: 50px;
  }

  .tfb-service-tabs .v-tab {
    background-color: $tfb-lightest-gray;
    border-color: $tfb-darkest-gray;
  }

    .tfb-service-tabs .v-tab:not(:last-child) {
      border-right: 1px solid rgba(0, 0, 0, 0.12);
    }

  .tfb-service-tabs .v-tab--active {
    background-color: $white;
    border-color: $tfb-darkest-gray;
  }

  .tfb-service-tabs a.v-tab--active img {
    opacity: 1 !important;
  }

  .tfb-service-tabs a.v-tab img {
    max-height: 30px;
    opacity: .5;
  }

  .tfb-service-tabs a.v-tab:hover img {
    opacity: inherit;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }

  .tfb-service-tabs .v-tabs-slider {
    background-color: rgba(0, 0, 0, 0) !important;
  }
</style>
