<template>
  <v-container fluid
               class="tfb-container">
    <span class="tfb-title-text-medium text-darker-blue text-uppercase">Manage Memberships</span>
    <v-row class="mb-10">
      <v-col cols="12">
        <v-container fluid
                     class="tfb-smallest-container background-white drop-shadow">
          <ValidationObserver ref="addobv" v-slot="{ handleSubmit }">
            <v-form @submit.stop.prevent="handleSubmit(addMembership)"
                    v-if="changeType === 'add'"
                    class="pb-5">
              <v-row>
                <v-col cols="12">
                  <text-input rules="required|numeric|min:6|max:10"
                              label="Policy Number"
                              minlength="6"
                              maxlength="10"
                              v-model="add.policyNumber"></text-input>
                </v-col>
                <v-col cols="12">
                  <text-input rules="required|alpha_num|min:4|max:6"
                              label="Member Number"
                              autocapitalize="characters"
                              minlength="4"
                              maxlength="6"
                              v-model="add.memberNumber"></text-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"
                   sm="6"
                   md="4"
                   class="py-0">
                  <v-btn block
                         type="submit"
                         class="tfb-button background-red">Submit</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
          <ValidationObserver ref="remobv" v-slot="{ handleSubmit }">
            <v-form @submit.stop.prevent="handleSubmit(removeMembership)"
                    v-if="changeType === 'remove'"
                    class="pb-5">
              <v-row>
                <v-col cols="12">
                  By removing this membership from your account, you will no longer be able to access it via this email address.
                  <strong>Any E-bill, Text, or Paperless options on policies associated with that membership will also be removed.</strong>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"
                       sm="8">
                  Please enter in the membership number to confirm removal:
                </v-col>
                <v-col cols="12"
                       sm="4">
                  <text-input :rules="memberRules"
                              label="Member Number"
                              autocapitalize="characters"
                              minlength="4"
                              maxlength="6"
                              v-model="remove.memberNumber"></text-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"
                   sm="6"
                   md="4"
                   class="py-0">
                  <v-btn block
                         type="submit"
                         class="tfb-button background-red">Submit</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import { ValidationObserver } from 'vee-validate';
  import tfbMetaInfo from '@/mixins/metaInfo';
  import tfbHelperMixin from '@/mixins/helpers';
  import { sessionErrorMessage } from '@/mixins/errorMessages';
  /* eslint object-shorthand: 0 */

  export default {
    name: 'AccountMemberships',
    mixins: [tfbMetaInfo, tfbHelperMixin],
    props: {
      changeType: {
        type: String,
        required: true,
      },
    },
    components: {
      ValidationObserver,
    },
    data() {
      return {
        add: {
          policyNumber: '',
          memberNumber: '',
        },
        remove: {
          memberNumber: '',
        },
        memberRules: {
          required: true,
          min: 4,
          max: 6,
          is: '',
        },
      };
    },
    computed: {
      metaPageTitle() {
        return 'Manage My Memberships | PolicyHolder';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/policyholder/membership/${this.changeType}`;
      },
      memberList() {
        try {
          return this.$store.getters['member/memberList'];
        } catch (error) {
          return null;
        }
      },
      removeRequest() {
        try {
          return this.$store.getters['member/removeMemberRequest'];
        } catch (error) {
          return null;
        }
      },
    },
    watch: {
      'add.memberNumber'(val) {
        this.add.memberNumber = val.toUpperCase();
      },
      'remove.memberNumber'(val) {
        this.remove.memberNumber = val.toUpperCase();
      },
    },
    mounted() {
      dispatch('app/clearErrorMessage');
      dispatch('app/setIsLoading', true);
      if (!this.isLoggedIn) {
        // Invalid login - go back to login screen
        dispatch('app/setErrorMessage', sessionErrorMessage);
        this.$router.push({ name: 'policyholderLogin' });
      } else if (this.changeType === 'remove' && this.removeRequest === null) {
        // Invalid request - go back to management page
        this.$router.push({ name: 'policyholderAccountManagement' });
      } else if (this.changeType === 'remove') {
        // Invalid request - go back to management page
        this.memberRules.is = this.removeRequest.MemberNumber.toUpperCase();
      }
      dispatch('app/setIsLoading', false);
    },
    methods: {
      addMembership() {
        this.$store.dispatch('app/setIsLoading', true);
        this.$store.dispatch('app/clearErrorMessage');
        this.$store.dispatch('member/addNewMembership', {
          sPolicyNumber: this.add.policyNumber,
          sMemberNumber: this.add.memberNumber,
          sTfbId: this.trackingId,
        })
          .then((response) => {
            if (response !== '') {
              this.$store.dispatch('app/setErrorMessage', response);
              this.$store.dispatch('app/setIsLoading', false);
            } else {
              // Redirect to Account Summary
              this.$router.push({ name: 'policyholderAccountSummary' });
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
            this.$store.dispatch('app/setIsLoading', false);
          });
      },
      removeMembership() {
        this.$store.dispatch('app/setIsLoading', true);
        this.$store.dispatch('app/clearErrorMessage');
        this.$store.dispatch('member/removeMembership', {
          sMemberId: this.removeRequest.MemberId,
          sMemberNumber: this.remove.memberNumber,
          sTfbId: this.trackingId,
        })
          .then((response) => {
            if (response !== '') {
              this.$store.dispatch('app/setErrorMessage', response);
              this.$store.dispatch('app/setIsLoading', false);
            } else {
              // Redirect to Account Summary
              this.$router.push({ name: 'policyholderAccountSummary' });
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
            this.$store.dispatch('app/setIsLoading', false);
          });
      },
    },
  };
</script>
<style lang="scss">

</style>
