<template>
  <div id="agent-details">
    <div itemscope
         itemtype="http://schema.org/Person"
         class="background-darker-blue tfb-agent-details">
      <v-container class="tfb-smallest-container">
        <v-row>
          <v-col cols="12"
                 sm="3">
            <v-skeleton-loader :loading="agentLoading"
                               type="image"
                               class="mx-auto">
              <v-img contain
                     v-if="agentDetails && agentDetails !== null"
                     :src="agentDetails.photo"
                     class="rounded-0 agent-photo mx-auto"
                     :alt="agentDetails.name"
                     max-height="240"
                     max-width="192"
                     min-height="240"
                     min-width="192"
                     itemprop="image">
                <template v-slot:placeholder>
                  <v-img :src="defaultAgentImage"
                         alt="No Photo Available"
                         itemprop="image"
                         class="rounded-0 agent-photo mx-auto"></v-img>
                </template>
                <template v-slot:error>
                  <v-img :src="defaultAgentImage"
                         alt="No Photo Available"
                         itemprop="image"
                         class="rounded-0 agent-photo mx-auto"></v-img>
                </template>
              </v-img>
              <v-img v-else
                     :src="defaultAgentImage"
                     class="rounded-0 agent-photo mx-auto"></v-img>
            </v-skeleton-loader>
          </v-col>
          <v-col cols="12"
                 sm="9"
                 align-self="center">
            <v-skeleton-loader :loading="agentLoading"
                               type="paragraph"
                               class="mx-auto">
              <div v-if="agentDetails && agentDetails !== null">
                <h1 class="tfb-title-text-medium text-uppercase text-blue"
                    itemprop="name">
                  {{agentDetails.name}}
                </h1>
                <v-container>
                  <v-row>
                    <v-col cols="12"
                           sm="5">
                      <span class="tfb-header-text-small text-uppercase font-weight-bold">
                        Office
                      </span><br />
                      <a v-bind:href="agentDetails.phoneNumber | linkFormatPhone"
                         hreflang="en-us"
                         itemprop="telephone"
                         class="county-agent-links">
                        {{agentDetails.phoneNumber | formatPhone}}
                      </a><br />
                      <br />
                      <span class="tfb-header-text-small text-uppercase font-weight-bold"
                            v-if="agentDetails.faxNumber && agentDetails.faxNumber !== ''">
                        Fax
                      </span><br />
                      <a v-bind:href="agentDetails.faxNumber | linkFormatPhone"
                         hreflang="en-us"
                         itemprop="faxNumber"
                         class="county-agent-links">
                        {{agentDetails.faxNumber | formatPhone}}
                      </a><br />
                      <br />
                      <span class="tfb-header-text-small text-uppercase font-weight-bold">
                        Email
                      </span><br />
                      <a v-bind:href="agentDetails.emailAddress | linkFormatEmail"
                         hreflang="en-us"
                         class="county-agent-links"
                         itemprop="email">
                        {{agentDetails.emailAddress}}
                      </a><br />
                      <br />
                      <a v-bind:href="agentFacebook"
                         hreflang="en-us"
                         v-if="agentFacebook"
                         class="county-agent-links"
                         target="_blank">
                        Visit me on Facebook!
                      </a><br />
                    </v-col>
                    <v-col cols="12"
                           sm="7">
                      <span class="tfb-header-text-small text-uppercase font-weight-bold">
                        Mailing Address
                      </span><br />
                      <div itemprop="address"
                           itemscope
                           itemtype="http://schema.org/PostalAddress"
                           v-if="agentDetails.mailingAddressDisplay && agentDetails.mailingAddressDisplay !== null">
                        <span v-html="agentDetails.mailingAddressDisplay"></span>
                        <br />
                      </div>
                      <span class="tfb-header-text-small text-uppercase font-weight-bold">
                        Physical Address
                      </span><br />
                      <div itemprop="address"
                           itemscope
                           itemtype="http://schema.org/PostalAddress"
                           v-if="agentDetails.physicalAddressDisplay && agentDetails.physicalAddressDisplay !== null">
                        <span v-html="agentDetails.physicalAddressDisplay"></span>
                        <v-icon class="mdi-18px tfb-county-icon">mdi-map-marker</v-icon><a v-bind:href="agentDetails.mapUrl"
                                                                                           hreflang="en-us"
                                                                                           class="county-agent-links"
                                                                                           target="_blank">Directions</a><br />
                      </div><br />
                      <a v-bind:href="agentWeatherLink"
                         hreflang="en-us"
                         v-if="agentWeatherLink"
                         class="county-agent-links"
                         target="_blank">
                        Sign up for weather alerts!
                      </a><br />
                      <script v-if="siFiTracking"
                              defer
                              async
                              type="application/javascript"
                              :src='`https://tag.simpli.fi/sifitag/${siFiTracking}`'></script>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
              <div v-else>
                <span class="tfb-title-text-small text-white">
                  Unable to locate agent information
                </span>
              </div>
            </v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row v-if="agentDetails && agentDetails !== null && agentSummary">
          <v-col cols="12">
            <h2 class="tfb-title-text-small text-uppercase text-blue">
              About {{agentDetails.name}}
            </h2>
            <p itemprop="description">
              {{agentSummary}}
            </p>
          </v-col>
        </v-row>
      </v-container>
      <span v-if="agentDetails && agentDetails !== null">
        <agent-contact :agent-email="agentDetails.emailAddress"></agent-contact>
        <blog :header="`Resources from ${agentDetails.name}`"
              sub-header=""
              :keywords="blogKeywords"></blog>
      </span>
    </div>

    <agent-map :markers="markers"
               :info-window-open="true"></agent-map>
  </div>
</template>
<script>
  /* eslint no-underscore-dangle: 0 */
  /* eslint no-nested-ternary: 0 */
  /* eslint no-useless-escape: 0 */
  import { dispatch } from 'vuex-pathify';
  import Blog from '@/views/blog/components/BlogCards.vue';
  import { defaultMarkerIcon } from '@/mixins/googleSettings';
  import tfbMetaMixin from '@/mixins/metaInfo';
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbHelperMixin from '@/mixins/helpers';
  import AgentContact from './components/AgentContact.vue';
  import AgentMap from './components/AgentMap.vue';

  export default {
    name: 'AgentDetails',
    mixins: [tfbDataMixin, tfbMetaMixin, tfbHelperMixin],
    components: {
      Blog,
      AgentMap,
      AgentContact,
    },
    data() {
      return {
        agentLoading: false,
        agentDetails: null,
        agentFacebook: null,
        agentSummary: null,
        agentWeatherDescription: null,
        agentWeatherLink: null,
        siFiTracking: null,
        markers: [{
          infoText: 'Texas Farm Bureau Home Office',
          position: { lat: 31.5248446, lng: -97.2181 },
        }],
      };
    },
    computed: {
      metaDescription() {
        if (this.agentDetails && this.agentDetails !== null) {
          return `Learn more about ${this.agentDetails.name}, a Texas Farm Bureau Insurance agent in ${this.agentDetails.countyName} County`;
        }

        if (this.$store.state.location.countyName) {
          return `Learn more about this Texas Farm Bureau Insurance agent in ${this.$store.state.location.countyName} County`;
        }

        return 'Learn more about this Texas Farm Bureau Insurance agent';
      },
      metaPageTitle() {
        if (this.agentDetails && this.agentDetails !== null) {
          return `${this.agentDetails.name} | ${this.agentDetails.countyName} County`;
        }

        if (this.$store.state.location.countyName) {
          return `Agent Information | ${this.$store.state.location.countyName} County`;
        }

        return 'Agent Information';
      },
      metaUrl() {
        let agentUrl = `https://${window.location.host}/insurance/agent/`;

        if (this.agentDetails && this.agentDetails !== null) {
          const countyName = this.replaceAll(this.agentDetails.countyName, ' ', '-').toLowerCase();
          const agentName = this.replaceAll(this.replaceAll(this.replaceAll(this.agentDetails.name, ' ', '-'), '.', ''), ',', '').toLowerCase();
          agentUrl += `${countyName}/${agentName}/${this.$route.params.id}`;
        } else {
          agentUrl += `${this.$route.params.id}`;
        }

        return agentUrl;
      },
      headerImage() {
        return this.agentDetails && this.agentDetails !== null ? this.agentDetails.photo : this.defaultHeaderImage;
      },
      blogKeywords() {
        return [
          (this.agentDetails && this.agentDetails !== null ? this.agentDetails.name : (this.$route.params.name ? this.replaceAll(this.$route.params.name, '-', ' ') : 'agent')),
          (this.agentDetails && this.agentDetails !== null ? `${this.agentDetails.countyName} County`
            : (this.$store.state.location.countyName ? this.$store.state.location.countyName
              : (this.$route.params.county ? this.$route.params.county : 'county')))];
      },
    },
    methods: {
      async getAgentInformation(agentCode) {
        await dispatch('agentcounty/getAgentDetails', { sAgentCode: agentCode, sDistance: 0, sTfbId: this.trackingId })
          .then((detailResponse) => {
            if (detailResponse && (detailResponse.errorMessage === null || detailResponse.errorMessage === '')) {
              // Save the agent code to the store in case this user goes to a quote
              dispatch('app/setQuoteAgentCode', agentCode);

              this.agentDetails = detailResponse;

              this.markers.pop();
              this.markers.push({
                infoText: this.agentDetails.agentMapInfoBox,
                position: {
                  lat: Number(this.agentDetails.latitude),
                  lng: Number(this.agentDetails.longitude),
                },
                icon: defaultMarkerIcon,
              });
              this.agentLoading = false;

              this.$store.dispatch('app/getAgentDetails', { sAgentCode: agentCode, sTfbId: this.trackingId })
                .then((listResponse) => {
                  if (listResponse) {
                    if (listResponse.FacebookLink) {
                      this.agentFacebook = listResponse.FacebookLink.Url;
                    }
                    if (listResponse.AgentSummary) {
                      const telephoneReplace = /(http:\/\/tfbtel\.)/igm;
                      const telephoneReplaceAlt = /(http&#58;\/\/tfbtel\.)/igm;
                      this.agentSummary = listResponse.AgentSummary.replace(telephoneReplace, 'tel:').replace(telephoneReplaceAlt, 'tel:');
                    }
                    if (listResponse.SevereWeatherAlertLink) {
                      this.agentWeatherDescription = 'Sign up for weather alerts!';
                      this.agentWeatherLink = listResponse.SevereWeatherAlertLink.Url;
                    }
                    if (listResponse.SiFiTag) {
                      this.siFiTracking = listResponse.SiFiTag;
                    }
                  }
                })
                .catch(() => {
                  // Do nothing
                });
            } else {
              this.agentLoading = false;
              dispatch('app/setErrorMessage', { ErrorMessage: detailResponse.errorMessage });
            }
          })
          .catch(() => {
            this.agentLoading = false;
            dispatch('app/setErrorMessage');
          });
      },
      async getAgentCodeByEmail(agentName) {
        await dispatch('agentcounty/getAgentCodeByEmail', { sAgentEmail: `${agentName}@txfb-ins.com`, sTfbId: this.trackingId })
          .then((codeResponse) => {
            if (codeResponse && codeResponse._agentCode) {
              this.getAgentInformation(codeResponse._agentCode);
            } else {
              this.agentLoading = false;
              dispatch('app/setErrorMessage');
            }
          })
          .catch(() => {
            this.agentLoading = false;
            dispatch('app/setErrorMessage');
          });
      },
    },
      mounted() {
        dispatch('app/clearErrorMessage');
        dispatch('app/setIsLoading', true);
        this.agentLoading = true;

        // How are we looking up the agent information?
        if (this.$route.params.id && this.$route.params.id !== 'undefined') {
          if (Number.isNaN(this.$route.params.id)) {
            // This is not a number, so assuming a name of some sort
            this.getAgentCodeByEmail(this.$route.params.id);
          } else {
            // This is a number, so assuming it's the agent code
            this.getAgentInformation(this.$route.params.id);
          }
        } else if (this.$route.params.name && this.$route.params.name !== 'undefined') {
          // This is a name of some sort
          this.getAgentCodeByEmail(this.$route.params.name);
        } else if (this.$route.query.a && this.$route.params.a !== 'undefined') {
          // Assuming this is the agent code
          this.getAgentInformation(this.$route.query.a);
        } else {
          // Nothing we can use to look up the information
          this.agentLoading = false;

          // Redirect back to the county lookup
          this.$router.push({ name: 'countyLookup' });
        }

        dispatch('app/setIsLoading', false);
      },
  };
</script>
<style lang="scss">

.tfb-agent-details {
  padding-top: 50px;
  /*padding-bottom: 150px;*/
  color: $white;
}

.tfb-agent-request-box {
  background: $white;
  padding: 30px;
  max-width: 600px;
}

.agent-photo {
  max-height: 240px;
  max-width: 192px;
}
</style>
