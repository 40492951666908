<template>
  <v-container fluid
               class="tfb-smaller-container tfb-component-container">
    <v-row>
      <v-col cols="12">
        <v-container fluid
                     class="drop-shadow background-white px-md-10 pb-5">
          <v-row>
            <v-col cols="12">
              <span class="tfb-title-text-small text-uppercase">
                Marketing Communication Preferences
              </span>
            </v-col>
          </v-row>
          <v-row id="communication-current">
            <v-col cols="12"
                   class="py-0">
              <span v-if="communicationIndicator">You are currently subscribed to receive marketing communications. You may adjust your preferences below.</span>
              <span v-else>You are currently unsubscribed from receiving marketing communications. You may adjust your preferences below.</span>
              <br />
            </v-col>
          </v-row>
          <v-row v-if="communicationSuccess">
            <v-col cols="12"
                   class="py-0">
              <v-alert type="success">
                Your marketing communication preferences have been updated.
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   class="py-0">
              <v-checkbox v-model="selectedCommunication"
                          label="I would like to receive marketing communications from Texas Farm Bureau Insurance Companies">
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   sm="6"
                   md="4">
              <v-btn block
                         :disabled="formDisabled"
                     @click="updateCommunication()"
                     class="tfb-button background-red">Submit</v-btn>
            </v-col>
          </v-row>
          <v-overlay absolute
                     :value="saveOverlay">
            <v-progress-circular indeterminate
                                 size="64"></v-progress-circular>
          </v-overlay>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'CommunicationChanges',
    mixins: [tfbHelperMixin],
    data() {
      return {
        saveOverlay: false,
        formDisabled: false,
        selectedCommunication: false,
        communicationSuccess: false,
      };
    },
    computed: {
      communicationIndicator() {
        try {
          return this.$store.getters['member/communicationIndicator'];
        } catch (error) {
          return false;
        }
      },
      emailAddress() {
        try {
          return this.$store.getters['member/emailAddress'];
        } catch (error) {
          return '';
        }
      },
      memberNumber() {
        try {
          return this.$store.getters['member/memberNumber'];
        } catch (error) {
          return '';
        }
      },
    },
    methods: {
      updateCommunication() {
        this.communicationSuccess = false;
        this.saveOverlay = true;
        dispatch('member/updateCommunication', {
          sUserName: this.emailAddress,
          sPreference: (this.selectedCommunication ? 'Y' : 'N'),
          sMemberNumber: this.memberNumber,
          sTfbId: this.trackingId,
        })
          .then(() => {
            // Update was successful
            this.communicationSuccess = true;
            this.saveOverlay = false;
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error);
            this.saveOverlay = false;
          });
      },
    },
    mounted() {
      this.selectedCommunication = this.communicationIndicator;

      if (!this.isMemberRole) {
        // Not the member, don't allow changes
        this.formDisabled = true;
      }
    },
  };
</script>
<style lang="scss">

</style>
