<template>
  <v-sheet elevation="4"
           class="pa-4">
    <!-- Accident Date -->
    <v-row>
      <v-col cols="12">
        <v-menu ref="accidentDateMenu"
                v-model="accidentDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px">
          <template v-slot:activator="{ on }">
            <ValidationProvider tag="div"
                                :bails="false"
                                rules="required"
                                name="Accident Date"
                                v-slot="{ errors, valid }">
              <v-text-field outlined
                            required
                            hide-details="auto"
                            :error-messages="errors"
                            :success="valid"
                            v-model="accidentDateFormatted"
                            label="Accident Date"
                            @blur="accident.AccidentDate = parseDate(accidentDateFormatted)"
                            v-on="on">
                <template v-slot:append>
                  <v-icon>mdi-calendar</v-icon>
                  <v-icon>mdi-asterisk</v-icon>
                </template>
              </v-text-field>
            </ValidationProvider>
          </template>
          <v-date-picker v-model="accident.AccidentDate"
                         color="background-blue"
                         no-title
                         :min="minIncidentDate"
                         :max="maxIncidentDate"
                         @input="accidentDateMenu = false"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <!-- Accident Type -->
    <v-row>
      <v-col cols="12">
        <select-input rules="required"
                      label="Which option best describes this accident?"
                      name="accident type"
                      :items="accidentTypeOptions"
                      item-text="AccidentTypeText"
                      item-value="AccidentTypeId"
                      v-model="accident.AccidentTypeCode"></select-input>
      </v-col>
    </v-row>
    <!-- At Fault Indicator -->
    <v-row>
      <v-col cols="12">
        <select-input rules="required"
                      label="What option best describes the driver's responsibility for this accident?"
                      name="driver responsibility"
                      :items="atFaultOptions"
                      item-text="DisplayText"
                      item-value="Value"
                      v-model="accident.AtFaultIndicator"></select-input>
      </v-col>
    </v-row>
    <!-- Accident Cost -->
    <v-row>
      <v-col cols="12">
        <text-input rules="required|min:1|max:10|integer"
                    label="Total property damage paid"
                    type="number"
                    prefix="mdi-currency-usd"
                    v-model="accident.AccidentAmount"></text-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn v-on:click="$emit('remove-accident', accident.Id)"
               class="float-right tfb-button-min background-red">
          Remove Accident
        </v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
  import { subYears } from 'date-fns';
  import { ValidationProvider } from 'vee-validate';
  import tfbHelperMixin from '@/mixins/helpers';
/* eslint object-shorthand: 0 */

  export default {
    name: 'RateQuoteAccident',
    props: {
      accident: {
        type: Object,
        required: true,
      },
    },
    mixins: [tfbHelperMixin],
    components: {
      ValidationProvider,
    },
    data() {
      return {
        accidentTypeOptions: null,
        atFaultOptions: [{ Value: true, DisplayText: 'At-Fault' }, { Value: false, DisplayText: 'Not-At-Fault' }],
        accidentDateFormatted: this.formatDate(this.accident.AccidentDate),
        accidentDateMenu: false,
      };
    },
    computed: {
      minIncidentDate() {
        return this.parseDate(subYears(new Date(), 3).toISOString());
      },
      maxIncidentDate() {
        return this.parseDate(new Date().toISOString());
      },
    },
    methods: {
      getAccidentTypes() {
        this.$store.dispatch('quotes/getAccidentTypes', { sTfbId: this.trackingId })
          .then((accidentTypeResponse) => {
            this.accidentTypeOptions = accidentTypeResponse;
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
    },
    watch: {
      'accident.AccidentDate'(val) {
        this.accidentDateFormatted = this.formatDate(val);
      },
    },
    mounted() {
      this.getAccidentTypes();
    },
  };
</script>
<style lang="scss">

</style>
