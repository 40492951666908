<template>
  <div id="policyholder-pwreset"
       class="background-darker-blue tfb-conversion">
    <v-container fluid
                 class="tfb-conversion-box">
      <span class="tfb-title-text-medium text-uppercase">Reset Password</span>
      <v-row>
        <v-col>
          Please change your password below.<br />
          <br />
          Once you have successfully changed your password,
          you will be redirected to login using your new password.<br />
        </v-col>
      </v-row>
      <ValidationObserver ref="obv" v-slot="{ handleSubmit }">
        <v-form @submit.stop.prevent="handleSubmit(onSubmit)">
          <v-row>
            <v-col>
              <text-input rules="required|confirmed:confirmation|min:8|max:25"
                          minlength="8"
                          maxlength="25"
                          label="Password"
                          v-model="passWord"
                          :disabled="formDisabled"
                          type="password"></text-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <text-input rules="required|min:8|max:25"
                          minlength="8"
                          maxlength="25"
                          label="Confirm Password"
                          v-model="confirmPassWord"
                          :disabled="formDisabled"
                          type="password"
                          vid="confirmation"></text-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn block
                     :disabled="formDisabled"
                     type="submit"
                     class="tfb-button background-red">Reset Password</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
    </v-container>
  </div>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import { ValidationObserver } from 'vee-validate';
  import tfbMetaInfo from '@/mixins/metaInfo';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'ResetPassword',
    mixins: [tfbMetaInfo, tfbHelperMixin],
    components: {
      ValidationObserver,
    },
    data() {
      return {
        formDisabled: false,
        passWord: '',
        confirmPassWord: '',
      };
    },
    computed: {
      metaPageTitle() {
        return 'Reset Password';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/policyholder/manage-account/password-reset`;
      },
      validationCode() {
        try {
          return this.$route.query.i;
        } catch (error) {
          return null;
        }
      },
      emailAddress() {
        try {
          return this.$route.query.e;
        } catch (error) {
          return null;
        }
      },
      memberList() {
        try {
          return this.$store.getters['member/memberList'];
        } catch (error) {
          return null;
        }
      },
    },
    methods: {
      onSubmit() {
        dispatch('app/setIsLoading', true);
        dispatch('app/clearErrorMessage');
        // Update the password
        dispatch('member/updatePassword', {
          sUserName: this.emailAddress, sNewPassword: this.passWord, sMemberList: this.memberList, sTfbId: this.trackingId,
        })
          .then((updateResponse) => {
            if (updateResponse && updateResponse.indexOf('updated') >= 0) {
              // Update was successful, send them back to the log in page
              dispatch('member/doLogout')
                .then(() => {
                  this.$router.push({ name: 'policyholderLogin' });
                })
                .catch((accessError) => {
                  dispatch('app/setErrorMessage', accessError.toString());
                  dispatch('app/setIsLoading', false);
                });
            } else {
              dispatch('app/setErrorMessage', updateResponse);
            }
            dispatch('app/setIsLoading', false);
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error);
            dispatch('app/setIsLoading', false);
            dispatch('app/logToULS', {
              logLevel: 'Error',
              logMessage: error,
              fileName: 'ResetPassword.vue',
              methodName: 'onSubmit-Catch',
              trackerId: this.trackingId,
            });
          });
      },
    },
    async mounted() {
      dispatch('app/setIsLoading', true);
      await dispatch('app/doApplicationCheck', { sApplicationName: 'Policyholder', sTfbId: this.trackingId })
        .then((returnedStatus) => {
          if (returnedStatus !== '') {
            // Application is unavailable
            dispatch('app/setErrorMessage', returnedStatus);
            this.formDisabled = true;
            dispatch('app/setIsLoading', false);
          } else {
              // Application is available; Check the validation code is present
            if (this.validationCode && this.validationCode !== null && this.validationCode !== '') {
              // Check if the validation code is valid
              this.$store.dispatch('member/doPasswordVerification', { sValidationCode: this.validationCode, sEmailAddress: this.emailAddress, sTfbId: this.trackingId })
                .then((response) => {
                  if (response && response.ReturnMessage && response.ReturnMessage.indexOf('SUCCESS') >= 0) {
                    // Successful response
                    this.formDisabled = false;
                  } else {
                    // Invalid link
                    this.$store.dispatch('app/setErrorMessage', 'Unable to locate an account with that email address. Please try again or register for a new account.');
                    this.formDisabled = true;
                  }
                  this.$store.dispatch('app/setIsLoading', false);
                })
                .catch((error) => {
                  this.$store.dispatch('app/setErrorMessage', error.toString());
                  this.$store.dispatch('app/setIsLoading', false);
                  this.formDisabled = true;
                });
            } else {
              // Reset password for some other reason, allow the change
              this.formDisabled = false;
            }
            dispatch('app/setIsLoading', false);
          }
        })
        .catch((error) => {
          dispatch('app/setErrorMessage', error.toString());
          dispatch('app/setIsLoading', false);
          this.formDisabled = true;
        });
    },
  };
</script>
<style lang="scss">

</style>
