<template>
  <v-container fluid
               class="tfb-container pb-10">
    <h1 class="tfb-title-text-medium text-darker-blue text-uppercase">Terms and Conditions</h1>
    <v-row>
      <v-col cols="12">
        <v-container fluid
                     class="drop-shadow background-white">
          <v-row v-if="showPaperlessTerms">
            <v-col cols="12">
              <h2 class="tfb-title-text-small text-uppercase text-darker-blue">Paperless Policy</h2>
            </v-col>
          </v-row>
          <v-row v-bind:class="[ !showingFullEmail ? 'showLess' : '' ]">
            <v-col cols="12"
                   class="text-body-2">
              <p>
                By clicking on the &ldquo;Accept&rdquo; button below, you accept these Terms and Conditions governing the use of <strong>Paperless Policy.</strong>
              </p>
              <p>
                Throughout these Terms and Conditions, the words &ldquo;you&rdquo; and &ldquo;your&rdquo; refer to the policyholder, the named insured and/or the spouse if
                a resident of the same household, if applicable. The words &ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our&rdquo; within these Terms and Conditions refer
                to the companies providing your insurance, which may include, but not be limited to, Texas Farm Bureau Mutual Insurance Company, Texas Farm Bureau Premium
                Finance Company, Texas Farm Bureau Underwriters, Texas Farm Bureau Casualty Insurance Company, and Farm Bureau County Mutual Insurance Company of Texas and
                may also include, as applicable, a contractor acting on our behalf.
              </p>
              <p>
                These Terms and Conditions are in addition to other terms and conditions for other services provided by us. By agreeing to these Terms and Conditions, we will
                send you notifications through e-mail to electronically view your Paperless Policy, which includes but is not limited to viewing your policy details, past and
                current policy documents, agent contact information, and enables you to pay your premium, file a claim, and print ID cards. YOUR ACCEPTANCE OF THESE TERMS AND
                CONDITIONS CONSTITUTES YOUR AGREEMENT TO THESE TERMS AND CONDITIONS AND ANY SUBSEQUENT CHANGES TO THESE TERMS AND CONDITIONS; DO NOT CLICK &ldquo;ACCEPT&rdquo;
                OR PROVIDE PERSONAL INFORMATION IF YOU DO NOT AGREE WITH ALL OF THESE TERMS AND CONDITIONS.
              </p>
              <p>
                Paperless Policy must be selected individually for eligible insurance policies or master account. Only certain policies are eligible for Paperless Policy.
                You may receive certain documents by mail when required by law, due to technological constraints, or as we deem appropriate. Once you are successfully enrolled
                in Paperless Policy, you should expect to stop receiving paper policy documents within the next two billing cycles. In addition to Paperless Policy, you will
                continue to receive copies of billing-related notices through your chosen method of delivery, whether via paper or email. Any Paperless Policy notification we
                send will be dated when sent to the e-mail address on file, even if a document is sent both electronically and as a paper copy. A paper copy does not alter the
                delivery date of the electronic document or any deadlines or responsibilities referenced by the document.
              </p>
              <p>
                We do not charge a separate fee for your use of Paperless Policy, but your wireless carrier or internet service provider may charge message, data, or other rates
                or fees for these notifications or your use of cellular data or internet to access these notifications.
              </p>
              <p>
                The enrolled e-mail will begin receiving Paperless Policy notifications regarding the selected policies shortly after enrollment. The frequency of e-mails and
                the timing of receipt of e-mails will depend on a variety of factors, including, but not limited to, the term of enrollment.
              </p>
              <p>
                Paperless Policy should be available through your electronic or mobile devices when in operating range of your wireless or internet service provider. However,
                this service may not be available through all wireless carriers or internet service providers, and certain functionalities of Paperless Policy may not be supported
                by your wireless carrier or internet service provider. It is your responsibility to contact your wireless carrier or internet service provider with questions regarding
                any issues, including rates, fees, and coverage. We cannot guarantee that Paperless Policy notifications provided through your wireless carrier or internet service
                provider will be delivered to you and are not liable for any delayed or undelivered Paperless Policy notifications.
              </p>
              <p>
                WE DO NOT WARRANT THAT THE INFORMATION, PROCESSES, OR SERVICES WILL BE UNINTERRUPTED, ACCURATE, COMPLETE, USEFUL, FUNCTIONAL, BUG, OR ERROR-FREE. TO THE EXTENT
                PERMITTED BY LAW, THIS SERVICE IS PROVIDED &ldquo;AS IS&rdquo;, &ldquo;WITH ALL FAULTS&rdquo;, AND WITHOUT EXPRESS OR IMPLIED WARRANTIES OF ANY KIND, INCLUDING
                THE FOLLOWING EXPRESSLY DISCLAIMED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. YOU ASSUME ALL RESPONSIBILITY AND
                RISK FOR USE OF PAPERLESS POLICY.
              </p>
              <p>
                It is your responsibility to provide us with an accurate and complete e-mail address, and to promptly update your Online Account with any changes to your e-mail
                address. Changes to your e-mail address used for Paperless Policy purposes will only update the e-mail address used for Paperless Policy, and will not update any
                other e-mail addresses you have registered with us.
              </p>
              <p>
                Please ensure that you have disabled any spam or other filters that may prevent the delivery of a Paperless Policy notification to you. Additionally, please ensure
                that your e-mail is active and capable of receiving new e-mails. We are not responsible for problems arising from e-mails sent to an inactive or out-of-date e-mail
                address. You further acknowledge that we will continue to send Paperless Policy notifications to the enrolled e-mail address until you change or unenroll that e-mail
                address. You will receive a confirmation by e-mail when we have received a request to discontinue Paperless Policy notifications. Discontinuing Paperless Policy
                notifications will resume paper policy documents within fifteen (15) business days.
              </p>
              <p>
                At any time, you may provide updates to contacts and enrolled e-mail addresses or opt out of Paperless Policy notifications in the &ldquo;Manage My Account&rdquo;
                section of our website. You may subsequently re-enroll in Paperless Policy at any time if you so choose.
              </p>
              <p>
                In lieu of paper policy documents, you will be sent an e-mail notification with a link to your online account where you can &ldquo;View Policy Documents&rdquo;.
                Click on the link contained in the e-mail to access your account and view Paperless Policy documents or simply go the &ldquo;My Accounts&rdquo; section of our website
                and log in to your account at any time. You will be required to enter a User ID and Password to access documents. The document will appear as a .PDF document when
                viewed, so you will need compatible software capable of opening and viewing .PDF documents, such as that provided by Adobe Systems, Inc.
              </p>
              <p>
                You are responsible for use of the Paperless Policy service and are fully responsible for any payments to your account. If you believe a person not authorized
                by you has gained access to your e-mail, you must notify us promptly of any such unauthorized access. You are responsible and assume the risk for any loss, theft,
                or unauthorized use of your e-mail address, which could permit unauthorized persons to have access to your personal information and account information and/or use
                such information for unauthorized purposes.
              </p>
              <p>
                We may change these Terms and Conditions at any point and will provide you notice of the updated Terms and Conditions. However, your continued use of this Paperless
                Policy service represents your ongoing acceptance of these Terms and Conditions.
              </p>
              <p>
                AGREEMENT TO ARBITRATE<br />
                YOU AGREE TO ARBITRATE ANY AND ALL DISPUTES, CONTROVERSIES, OR CLAIMS BETWEEN, ON THE ONE HAND, YOU OR ANYONE ELSE ASSERTING A CLAIM BELONGING TO, OBTAINED THROUGH,
                OR ON BEHALF OF YOU, AND, ON THE OTHER HAND, US, OUR AFFILIATES, AND ANY OF OUR OR THEIR OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS, AND/OR REPRESENTATIVES, ARISING
                OUT OF OR RELATED IN ANY WAY TO: (I) THESE TERMS AND CONDITIONS AND/OR (II) PAPERLESS POLICY (THE &ldquo;COVERED CLAIMS&rdquo;).  AN &ldquo;AFFILIATE&rdquo; SHALL MEAN
                ANY ENTITY CONTROLLED BY OR UNDER COMMON CONTROL WITH, DIRECTLY OR INDIRECTLY, THE TEXAS FARM BUREAU (WHICH SHALL INCLUDE WITHOUT LIMITATION CONTROL EXERCISED BY
                MANAGEMENT CONTRACT, PROXIES, OR ANY OTHER MEANS).  FOR ALL COVERED CLAIMS, YOU AGREE THAT ARBITRATION SHALL BE THE EXCLUSIVE REMEDY AND SHALL BE FINAL AND BINDING.
                JUDGMENT UPON ANY ARBITRATION AWARD MAY BE ENTERED IN ANY COURT OF COMPETENT JURISDICTION.
              </p>
              <p>
                <strong>
                  YOU UNDERSTAND AND AGREE THAT ARBITRATION IS THE ONLY LITIGATION FORUM FOR RESOLVING A COVERED CLAIM, AND THAT BOTH WE AND YOU ARE WAIVING A RIGHT TO A TRIAL
                  BEFORE A JUDGE OR JURY IN FEDERAL OR STATE COURT IN FAVOR OF ARBITRATION.
                </strong>
              </p>
              <p>
                YOUR COVERED CLAIM(S) WILL BE DETERMINED IN AN INDIVIDUAL PROCEEDING AND MAY NOT BE JOINED TOGETHER WITH THE CLAIMS OF ANY OTHER PERSONS WITHOUT THE EXPRESS WRITTEN
                CONSENT OF ALL THE OTHER PARTIES TO THE PROCEEDING.  YOU AGREE TO WAIVE ANY RIGHT TO COMMENCE OR PARTICIPATE IN A CLASS, COLLECTIVE, OR REPRESENTATIVE ACTION; AND
                ANY RIGHT TO UTILIZE CLASS-ACTION, COLLECTIVE-ACTION, OR REPRESENTATIVE-ACTION PROCEDURES. (THIS PARAGRAPH IS REFERRED TO AS THE  &ldquo;CLASS ACTION WAIVER&rdquo;).
                <strong>
                  THE CLASS ACTION WAIVER PRECLUDES YOU OR ANY PARTY FROM PARTICIPATING IN OR BEING REPRESENTED IN ANY CLASS, COLLECTIVE, OR REPRESENTATIVE ACTION REGARDING A
                  COVERED CLAIM IN ANY FORUM.
                </strong>
              </p>
              <p>
                IN ARBITRATION, THE ARBITRATOR SHALL HAVE NO POWER TO ARBITRATE ANY FORM OF A CLASS, COLLECTIVE, OR REPRESENTATIVE PROCEEDING; TO UTILIZE CLASS-ACTION,
                COLLECTIVE-ACTION, OR REPRESENTATIVE-ACTION PROCEDURES; OR TO COMBINE A COVERED CLAIM WITH ANY OTHER CLAIM THAT IS NOT A COVERED CLAIM.  EACH PARTY SHALL
                ASSERT IN ONE PROCEEDING ALL OF THE PARTY&rsquo;S COVERED CLAIMS AGAINST THE OTHER PARTIES ARISING OUT OF THE SAME TRANSACTION OR OCCURRENCE OR SERIES OF
                TRANSACTIONS OR OCCURRENCES.  IF THE CLASS ACTION WAIVER IS, BY FORCE OF LAW, LIMITED, VOIDED, OR DETERMINED TO BE ILLEGAL, INVALID, OR OTHERWISE UNENFORCEABLE,
                THEN THIS AGREEMENT TO ARBITRATE SHALL BE NULL AND VOID WITH RESPECT TO SUCH PROCEEDING, SUBJECT TO THE RIGHT TO APPEAL THE LIMITATION OR INVALIDATION OF THE CLASS
                ACTION WAIVER AND, IF SUCH APPEAL IS SUCCESSFUL, TO ENFORCE THIS AGREEMENT TO ARBITRATE SUBJECT TO THE CLASS ACTION WAIVER.
              </p>
              <p>
                THE ARBITRATOR SHALL HAVE THE EXCLUSIVE AUTHORITY TO RESOLVE ANY AND ALL ISSUES OF ARBITRABILITY, INCLUDING BUT NOT LIMITED TO ISSUES REGARDING THE EXISTENCE,
                ENFORCEABILITY, VALIDITY, LEGALITY, UNCONSCIONABILITY, SCOPE, INTERPRETATION, OR APPLICABILITY OF THIS AGREEMENT OR ANY PART THEREOF; NOTWITHSTANDING THE ABOVE,
                ANY ISSUES REGARDING THE VALIDITY AND EFFECT OF THE CLASS ACTION WAIVER MAY BE DETERMINED ONLY BY A COURT AND NOT BY AN ARBITRATOR.
              </p>
              <p>
                THE ARBITRATOR SHALL NOT HAVE THE POWER TO GRANT GREATER RELIEF THAN WOULD HAVE BEEN AVAILABLE HAD THE MATTER BEEN HEARD IN COURT.  THE ARBITRATOR SHALL BAR A
                COVERED CLAIM IF ARBITRATION IS NOT INITIATED WITHIN THE TIME OTHERWISE PERMITTED FOR LEGAL ACTION UNDER THE APPLICABLE LIMITATIONS PERIOD.  THE PARTIES DO NOT
                WAIVE ANY EXHAUSTION REQUIREMENTS THAT MAY APPLY PRIOR TO ASSERTING A COVERED CLAIM.  THE ARBITRATOR SHALL HAVE NO POWER TO VARY OR IGNORE ANY TERM OF THESE
                TERMS AND CONDITIONS.  THE ARBITRATOR SHALL FOLLOW THE SUBSTANTIVE LAWS OF THE STATE OF TEXAS (WITHOUT REFERENCE TO ITS CHOICE-OF-LAW PRINCIPLES) AND ANY
                APPLICABLE FEDERAL LAW.
              </p>
              <p>
                WE WILL PAY ALL ARBITRATOR COSTS AND ADMINISTRATIVE FEES OF THE ARBITRATION EXCEPT FOR ANY INITIAL FILING FEE FOR A CLAIM BROUGHT BY YOU, WHICH FEE SHALL BE
                PAID BY YOU UP TO THE LESSER OF $400 OR THE COST OF FILING A NEW CIVIL SUIT IN THE U.S. DISTRICT COURT FOR THE WESTERN DISTRICT OF TEXAS.  THE PARTIES WILL BE
                RESPONSIBLE FOR THEIR OWN ATTORNEY&rsquo;S FEES UNLESS OTHERWISE ORDERED BY THE ARBITRATOR IN ACCORDANCE WITH APPLICABLE LAW.  ARBITRATION SHALL BE CONDUCTED BY
                A SINGLE ARBITRATOR SELECTED THROUGH THE AMERICAN ARBITRATION ASSOCIATION (&ldquo;AAA&rdquo;).  THE PROCEEDING SHALL BE GOVERNED BY THE COMMERCIAL ARBITRATION
                RULES OF THE AAA IN EFFECT AT THE TIME THE ARBITRATION IS COMMENCED, BUT ONLY TO THE EXTENT CONSISTENT WITH THIS AGREEMENT.  VENUE FOR THE ARBITRATION PROCEEDING
                SHALL BE IN MCLENNAN COUNTY, TEXAS.
              </p>
              <p>
                THIS AGREEMENT TO ARBITRATE IS MADE PURSUANT TO AND SHALL BE GOVERNED BY THE FEDERAL ARBITRATION ACT. EXCEPT AS MAY BE REQUIRED BY LAW OR AS NECESSARY IN A
                PROCEEDING TO CONFIRM OR ENFORCE AN ARBITRATION AWARD, NEITHER A PARTY NOR AN ARBITRATOR MAY DISCLOSE THE EXISTENCE, CONTENT, OR RESULTS OF ANY ARBITRATION
                UNDER THESE TERMS AND CONDITIONS.  AN ARBITRATION AWARD AND ANY JUDGMENT CONFIRMING AN AWARD WILL APPLY ONLY TO THE SPECIFIC CASE ADJUDICATED AND SHALL NOT BE
                USED IN ANY OTHER CASE OR ARBITRATION PROCEEDING EXCEPT TO ENFORCE THE AWARD.
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   class="text-center">
              <v-btn plain
                     class="tfb-expand-terms-button mx-auto"
                     @click="showingFullEmail = !showingFullEmail">Read {{ showingFullEmail ? "Less" : "More" }}</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   class="text-center">
              <span class="font-weight-bold">Paperless Policy Notifications Notifications will be sent to: </span>{{emailAddress}}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   class="text-center">
              <span class="font-weight-bold">Please enter your initials by each change.</span>
            </v-col>
          </v-row>
          <ValidationObserver ref="obv" v-slot="{ handleSubmit }">
            <v-form ref="form"
                    v-if="paperlessRequest"
                    @submit.stop.prevent="handleSubmit(savePaperlessChanges)">
              <v-row>
                <v-col cols="12">
                  <v-data-table :headers="paperlessHeaders"
                                :items="paperlessRequest"
                                hide-default-footer
                                disable-sort>
                    <template v-slot:item.NewPaperless="{ item }">
                      <span v-if="item.NewPaperless">
                        Enroll
                      </span>
                      <span v-else>
                        Unenroll
                      </span>
                    </template>
                    <template v-slot:item.MemberInitials="{ item }">
                      <text-input rules="required|min:2|max:3"
                                  dense
                                  minlength="2"
                                  maxlength="3"
                                  label="Initials"
                                  v-model="item.MemberInitials"
                                  :vid="item.PolicyNumber"></text-input>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-btn block
                         @click="cancelPaperlessChanges()"
                         class="tfb-button background-red">Cancel</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn block
                         type="submit"
                         :disabled="formDisabled"
                         class="tfb-button background-red">Accept</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import { ValidationObserver } from 'vee-validate';
  import tfbMetaInfo from '@/mixins/metaInfo';
  import tfbHelperMixin from '@/mixins/helpers';
  import { insuredAccessOnlyErrorMessage } from '@/mixins/errorMessages';

  export default {
    name: 'ChangePaperless',
    mixins: [tfbMetaInfo, tfbHelperMixin],
    components: {
      ValidationObserver,
    },
    data() {
      return {
        formDisabled: false,
        showingFullEmail: false,
        showPaperlessTerms: true,
        enrollRequests: [],
        unEnrollRequests: [],
        paperlessHeaders: [
          { text: 'Policy', align: 'start', value: 'PolicyNumber' },
          { text: 'Action', value: 'NewPaperless' },
          { text: 'Initials', value: 'MemberInitials' },
        ],
      };
    },
    computed: {
      metaPageTitle() {
        return 'Paperless Policy Notifications Terms and Conditions | PolicyHolder';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/policyholder/policy/paperless`;
      },
      paperlessRequest() {
        try {
          return this.$store.getters['policy/paperlessRequest'];
        } catch (error) {
          return null;
        }
      },
      emailAddress() {
        try {
          return this.$store.getters['member/emailAddress'];
        } catch (error) {
          return '';
        }
      },
      memberId() {
        try {
          return this.$store.getters['member/memberId'];
        } catch (error) {
          return '0';
        }
      },
      memberNumber() {
        try {
          return this.$store.getters['member/memberNumber'];
        } catch (error) {
          return '';
        }
      },
    },
    methods: {
      cancelPaperlessChanges() {
        // Clear the request
        this.$store.commit('policy/SET_PAPERLESS_REQUEST', null);
        // Go back to Management screen
        this.$router.push({ name: 'policyholderAccountManagement' });
      },
      savePaperlessChanges() {
        dispatch('app/setIsLoading', true);
        this.$store.dispatch('app/logToULS', {
          logLevel: 'Info',
          logMessage: `${this.memberId}-${this.memberNumber}`,
          fileName: 'ChangePaperless.vue',
          methodName: 'savePaperlessChanges-Start',
          trackerId: this.trackingId,
        });

        // Split the changes into enroll/unenroll
        for (let p = 0; p < this.paperlessRequest.length; p += 1) {
          const policy = this.paperlessRequest[p];

          if (policy.NewPaperless && policy.NewPaperless === true) {
            this.enrollRequests.push(policy);
          } else {
            this.unEnrollRequests.push(policy);
          }
        }

        if (this.enrollRequests.length > 0) {
          // Call service
          dispatch('policy/setupPaperless', {
            sEmailAddress: this.emailAddress, sMemberNumber: this.memberNumber, sMemberId: this.memberId, sPolicyList: this.paperlessRequest, sTfbId: this.trackingId,
          })
            .then((setupResult) => {
              if (setupResult) {
                this.$store.dispatch('app/logToULS', {
                  logLevel: 'Info',
                  logMessage: setupResult,
                  fileName: 'ChangePaperless.vue',
                  methodName: 'setupPaperless-Response',
                  trackerId: this.trackingId,
                });
                // Handle return
                if (setupResult.ErrorMessage) {
                  dispatch('app/setErrorMessage', setupResult.ErrorMessage);
                  dispatch('app/setIsLoading', false);
                } else {
                  // Clear Account Summary to refetch
                  this.$store.commit('policy/CLEAR_ACCOUNT_SUMMARY');
                  // Clear the request
                  this.$store.commit('payments/SET_PAPERLESS_REQUEST', null);
                  // Fetch the Account/Account Bill Summary information again
                  dispatch('policy/getAccountSummary', { sMemberNumber: this.memberNumber, sTfbId: this.trackingId });
                  // Go back to Summary screen
                  this.$router.push({ name: 'policyholderAccountSummary' });
                }
              }
              dispatch('app/setIsLoading', false);
            })
            .catch((error) => {
              this.$store.dispatch('app/logToULS', {
                logLevel: 'Error',
                logMessage: error,
                fileName: 'ChangePaperless.vue',
                methodName: 'setupPaperless-Catch',
                trackerId: this.trackingId,
              });
              dispatch('app/setErrorMessage', error.toString());
              dispatch('app/setIsLoading', false);
            });
        }

        if (this.unEnrollRequests.length > 0) {
          // Call service
          dispatch('policy/removePaperless', {
            sEmailAddress: this.emailAddress, sMemberNumber: this.memberNumber, sMemberId: this.memberId, sPolicyList: this.unEnrollRequests, sTfbId: this.trackingId,
          })
            .then((setupResult) => {
              if (setupResult) {
                this.$store.dispatch('app/logToULS', {
                  logLevel: 'Info',
                  logMessage: setupResult,
                  fileName: 'ChangePaperless.vue',
                  methodName: 'removePaperless-Response',
                  trackerId: this.trackingId,
                });
                // Handle return
                if (setupResult.ErrorMessage) {
                  dispatch('app/setErrorMessage', setupResult.ErrorMessage);
                  dispatch('app/setIsLoading', false);
                } else {
                  // Clear the request
                  this.$store.commit('payments/SET_EBILL_REQUEST', null);
                  // Clear Account Summary to refetch
                  this.$store.commit('policy/CLEAR_ACCOUNT_SUMMARY');
                  // Go back to Summary screen
                  this.$router.push({ name: 'policyholderAccountSummary' });
                }
              }
              dispatch('app/setIsLoading', false);
            })
            .catch((error) => {
              this.$store.dispatch('app/logToULS', {
                logLevel: 'Error',
                logMessage: error,
                fileName: 'ChangePaperless.vue',
                methodName: 'removePaperless-Catch',
                trackerId: this.trackingId,
              });
              dispatch('app/setErrorMessage', error.toString());
              dispatch('app/setIsLoading', false);
            });
        }
      },
    },
    mounted() {
      dispatch('app/clearErrorMessage');
      dispatch('app/setIsLoading', true);

      if (!this.isLoggedIn) {
        // Invalid login - go back to login screen
        this.$router.push({ name: 'policyholderLogin' });
      } else if (!this.isMemberRole) {
        // Not the member, don't allow changes
        this.formDisabled = true;
        this.$store.dispatch('app/setErrorMessage', insuredAccessOnlyErrorMessage);
      } else if (!this.paperlessRequest || this.paperlessRequest === null) {
        // No request values, push back to Management screen
        this.$router.push({ name: 'policyholderAccountManagement' });
      }
      dispatch('app/setIsLoading', false);
    },
  };
</script>
<style lang="scss">
</style>
