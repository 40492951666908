/* eslint no-underscore-dangle: 0 */
import { make } from 'vuex-pathify';
import axios from 'axios';

const sharePointListUrl = `${process.env.VUE_APP_ROOT_URI}/_api/lists/getbytitle('%%LIST%%')/items`;

const config = {
  crossdomain: true,
  // withCredentials: true,
  headers: { 'Access-Control-Allow-Origin': '*', Accept: 'application/json; odata=verbose' },
};

// Groups/sorts service coverages
function groupServiceCoverages(serviceCoverages) {
  let groupName = '';
  let groupCoverages = [];
  let coverageGroup = {};
  const coverageReturn = [];

  for (let c = 0; c < serviceCoverages.length; c += 1) {
    const coverage = serviceCoverages[c];
    if (coverage) {
      // console.log(coverage);
      // Does this coverage start a new section group?
      if (coverage.CoverageTypeSection !== groupName) {
        if (groupCoverages.length > 0) {
          // Figure out what the actual name needs to be
          let finalGroupName = '';
          if (groupName !== null && groupName.toUpperCase() === 'VEHICLE') {
            finalGroupName = 'For Your Vehicle';
          } else if (groupName !== null && groupName.toUpperCase() === 'SELF') {
            finalGroupName = 'For You';
          } else {
            finalGroupName = groupName;
          }
          // Add the existing grouped coverages to a coverage group with the given name
          coverageGroup = { Name: finalGroupName, Coverages: groupCoverages };
          // Add the document group to the return
          coverageReturn.push(coverageGroup);
        }

        // Set the name of the new section group
        groupName = coverage.CoverageTypeSection;
        // Clear out the grouped coverages
        groupCoverages = [];
      }

      // Add the document to the document group
      groupCoverages.push(coverage);
    }
  }

  // Figure out what the actual name needs to be
  let finalGroupName = '';
  if (groupName !== null && groupName.toUpperCase() === 'VEHICLE') {
    finalGroupName = 'For Your Vehicle';
  } else if (groupName !== null && groupName.toUpperCase() === 'SELF') {
    finalGroupName = 'For You';
  } else {
    finalGroupName = groupName;
  }

  // Add the last grouped coverages to a coverage group with the name
  coverageGroup = { Name: finalGroupName, Coverages: groupCoverages };

  // Add the last/remaining coverage group to the return
  coverageReturn.push(coverageGroup);

  return coverageReturn;
}

const state = {
  serviceItems: null,
  serviceCoverages: null,
  serviceOptionalCoverages: null,
  serviceDiscounts: null,
};

const actions = {
  getServices(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${sharePointListUrl.replace('%%LIST%%', 'services')}?$select=*&$filter=(TFBEnabled eq 1)&$orderby=OrderBy`, config)
        .then((response) => {
          if (response && response.data && response.data.d && response.data.d.results) {
            resolve(response.data.d.results);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          context.dispatch('logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'services.js', methodName: 'getServices-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getServiceInformation(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${sharePointListUrl.replace('%%LIST%%', 'services')}?$select=*&$filter=((ServiceType eq '${sRequest.sServiceType}') and (TFBEnabled eq 1))&$orderby=OrderBy`, config)
        .then((response) => {
          if (response && response.data && response.data.d && response.data.d.results && response.data.d.results.length > 0) {
            resolve(response.data.d.results[0]);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          context.dispatch('logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'services.js', methodName: 'getServiceInformation-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getServiceCoverages(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${sharePointListUrl.replace('%%LIST%%', 'servicecoverages')}?$select=*&$filter=((ServiceType eq '${sRequest.sServiceType}') and (CoverageType eq 'Coverage')
        and (TFBEnabled eq 1) and (Optional eq 0))&$orderby=CoverageTypeSection,OrderBy`, config)
        .then((response) => {
          if (response && response.data && response.data.d && response.data.d.results) {
            resolve(groupServiceCoverages(response.data.d.results));
            // resolve(response.data.d.results);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          context.dispatch('logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'services.js', methodName: 'getServiceCoverages-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getServiceOptionalCoverages(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${sharePointListUrl.replace('%%LIST%%', 'servicecoverages')}?$select=*&$filter=((ServiceType eq '${sRequest.sServiceType}') and (CoverageType eq 'Coverage')
        and (TFBEnabled eq 1) and (Optional eq 1))&$orderby=OrderBy`, config)
        .then((response) => {
          if (response && response.data && response.data.d && response.data.d.results) {
            resolve(response.data.d.results);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          context.dispatch('logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'services.js', methodName: 'getServiceOptionalCoverages-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getServiceDiscounts(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${sharePointListUrl.replace('%%LIST%%', 'servicecoverages')}?$select=*&$filter=((ServiceType eq '${sRequest.sServiceType}') and (CoverageType eq 'Discount')
        and (TFBEnabled eq 1))&$orderby=OrderBy`, config)
        .then((response) => {
          if (response && response.data && response.data.d && response.data.d.results) {
            resolve(response.data.d.results);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          context.dispatch('logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'services.js', methodName: 'getServiceDiscounts-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
};

const mutations = {
  ...make.mutations(state),
};

const getters = {
  ...make.getters(state),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
