<template>
  <v-container class="appstore-links-width-override">
    <v-row>
      <v-col cols="6"
             align="center"
             v-if="appleLink && appleLink !== undefined && appleLink !== null && appleLink !== ''">
        <a v-bind:href="appleLink"
           target="_blank">
          <img v-bind:src="`${imageRootPath}icons/apple-appstore-badge.svg`"
               alt="Download on the App Store"
               class="appstore-badge">
        </a>
      </v-col>
      <v-col cols="6"
             align="center"
             v-if="googleLink && googleLink !== undefined && googleLink !== null && googleLink !== ''">
        <a v-bind:href="googleLink"
           target="_blank">
          <img v-bind:src="`${imageRootPath}icons/google-play-badge.png`"
               alt="Get it on Google Play"
               class="appstore-badge" />
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    name: 'MobileAppFooterLinks',
    mixins: [],
    data() {
      return {};
    },
    computed: {
      imageRootPath() {
        return `${process.env.VUE_APP_ROOT_URI}/publishingimages/`;
      },
      appleLink() {
        if (!this.$store.state.app.appleAppStoreLink) {
          this.$store.dispatch('app/setVisitor');
        }
        return this.$store.state.app.appleAppStoreLink;
      },
      googleLink() {
        if (!this.$store.state.app.googleAppStoreLink) {
          this.$store.dispatch('app/setVisitor');
        }
        return this.$store.state.app.googleAppStoreLink;
      },
    },
    mounted() {},
  };
</script>
<style lang="scss">
  .appstore-links-width-override {
    width: auto !important;
  }
  .appstore-badge {
    min-height: 40px;
    width: 150px;
    display: inline-block;
    overflow: hidden;
  }

  @media (min-width: 960px) {
    .appstore-badge {
      height: 50px;
      width: auto;
    }
  }
</style>
