<template>
  <div itemscope
       itemtype="http://schema.org/SearchResultsPage">
    <v-container fluid
                 class="tfb-container pb-10">
      <v-row class="my-1">
        <v-col>
          <h1 class="tfb-title-text-medium text-darker-blue text-center">
            Search Results
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-form @submit.stop.prevent="submitSearch">
            <v-text-field background-color="white"
                          dense
                          filled
                          id="agent-search-zip"
                          required
                          type="search"
                          v-model="enteredSearchText">
              <template v-slot:append-outer>
                <v-btn class="tfb-button-attached-dense background-red"
                       v-on:click="submitSearch">Search</v-btn>
              </template>
            </v-text-field>
          </v-form>
        </v-col>
      </v-row>
      <div v-if="!resultsLoading && results && results.length > 0">
        <v-row>
          <v-col cols="12">
            <span class="tfb-text-standard-small font-italic">Displaying {{startRow + 1}}&mdash;{{startRow + 10}} of about {{totalRows}} results for &ldquo;{{searchText}}&rdquo;</span>
          </v-col>
        </v-row>
        <v-row v-for="result in results"
               v-bind:key="result.Id">
          <v-col v-if="result.Photo !== null && result.Photo !== ''"
                 cols="2">
            <a v-bind:href="result.Path"
               hreflang="en-us"
               v-if="result.Path !== null && result.Path !== ''">
              <v-img :src="result.Photo"
                     class="rounded-0 agent-photo mx-auto"
                     :alt="result.Title"
                     itemprop="image">
                <template v-slot:placeholder>
                  <v-img :src="defaultAgentImage"
                         alt="No Photo Available"
                         itemprop="image"
                         class="rounded-0 agent-photo mx-auto"></v-img>
                </template>
              </v-img>
            </a>
          </v-col>
          <v-col :cols="result.Photo !== null && result.Photo !== '' ? '10' : '12'">
            <a v-bind:href="result.Path"
               hreflang="en-us"
               v-if="result.Path !== null && result.Path !== ''">{{result.Title}}</a>
            <span v-if="result.Path === null || result.Path === ''">
              <strong>{{result.Title}}</strong>
            </span>
            <p v-html="result.Highlighted"></p>
            <hr />
          </v-col>
        </v-row>
        <v-row v-if="enablePreviousPageLink || enableNextPageLink">
          <v-col cols="12"
                 class="text-center">
            <a href=""
               v-on:click.prevent="goToPreviousPage()"
               hreflang="en-us"
               v-show="enablePreviousPageLink">Previous Page</a> |
            <a href=""
               v-on:click.prevent="goToNextPage()"
               hreflang="en-us"
               v-show="enableNextPageLink">Next Page</a>
          </v-col>
        </v-row>
      </div>
      <v-row v-if="!resultsLoading && (!results || results.length === 0) && searchText !== null && searchText !== ''">
        <v-col cols="12">
          No results found for &ldquo;{{searchText}}&rdquo;
        </v-col>
      </v-row>
      <div v-if="resultsLoading && (!results || results.length === 0)">
        <v-row v-for="i in 3"
               :key="i">
          <v-col>
            <v-skeleton-loader :loading="resultsLoading"
                               type="list-item-avatar-three-line"
                               class="mx-auto">
            </v-skeleton-loader>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
  import _ from 'lodash';
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbHelperMixin from '@/mixins/helpers';
  import tfbMetaMixin from '@/mixins/metaInfo';

  export default {
    name: 'SearchResults',
    mixins: [tfbDataMixin, tfbHelperMixin, tfbMetaMixin],
    data() {
      return {
        resultsLoading: false,
        results: [],
        searchText: '',
        startRow: 0,
        totalRows: 0,
        enteredSearchText: '',
      };
    },
    computed: {
      metaPageTitle() {
        return 'Search Results';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/search`;
      },
      enableNextPageLink() {
        return this.startRow >= 0 && this.totalRows > 10 && (this.startRow + 10) < this.totalRows;
      },
      enablePreviousPageLink() {
        return this.startRow > 0;
      },
    },
    watch: {
      startRow(val) {
        this.getSearchResults(this.enteredSearchText, val);
      },
    },
    methods: {
      getSearchResults(searchTerm, startSearchRow) {
        this.resultsLoading = true;
        this.results = [];
        this.$store.dispatch('app/getSearchResults', {
          sSearchTerm: searchTerm, sStartRow: startSearchRow, sTfbId: this.trackingId,
        })
          .then((foundResults) => {
            this.searchText = searchTerm;
            this.results = foundResults.Results;
            this.resultsLoading = false;
            this.totalRows = foundResults.TotalRows;
            this.$router.push({ name: 'searchResults', query: { term: searchTerm, start: startSearchRow } });
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
            this.searchText = searchTerm;
            this.resultsLoading = false;
          });
      },
      submitSearch() {
        if (this.startRow !== 0) {
          // Update the start row which will trigger the search
          this.startRow = 0;
        } else {
          // Call the search
          this.getSearchResults(this.enteredSearchText, 0);
        }
      },
      goToNextPage() {
        // Update the start row which will trigger the search
        this.startRow = this.startRow + 10;
      },
      goToPreviousPage() {
        // Update the start row which will trigger the search
        this.startRow = this.startRow - 10;
      },
    },
    mounted() {
      this.$store.dispatch('app/clearErrorMessage');
      this.enteredSearchText = this.$route.query.term;
      this.startRow = !this.$route.query.start || this.$route.query.start === null || _.isNaN(this.$route.query.start) ? 0 : parseInt(this.$route.query.start, 10);

      if (this.enteredSearchText !== null && this.enteredSearchText !== '') {
        this.getSearchResults(this.enteredSearchText, this.startRow);
      }
    },
  };
</script>
<style lang="scss" scoped>

  #main {
    background-color: $tfb-lightest-gray !important;
  }

  .holder:hover .v-card .v-image {
    -webkit-filter: grayscale(1);
    filter: gray;
  }

  .slide .v-image .v-image__image {
    background-position-y: top !important;
  }
</style>
