<template>
  <div id="post-sidebar">
    <aside id="sidebar"
           v-if="displayLocation === 'side' && posts.length > 0">
      <section class="post"
               v-for="post in posts"
               v-bind:key="post.id">
        <router-link :to="{ name: 'blogPost',
                         params: { category: kebabify(post.urlCategory),
                         title: kebabify(post.url) } }"
                     class="link-stretched"
                     hreflang="en-us"></router-link>
        <div class="image-block">
          <router-link :to="{ name: 'blogPost',
                         params: { category: kebabify(post.urlCategory),
                         title: kebabify(post.url) } }"
                       hreflang="en-us">
            <v-img height="200px"
                   :src="post.headerImage">
            </v-img>
          </router-link>
        </div>
        <div class="holder-text">
          <router-link :to="{ name: 'blogFeedCategory',
                         params: { category: kebabify(post.category) } }"
                       class="category-link"
                       hreflang="en-us">
            {{post.category}}
          </router-link>
          <span v-if="post.subcategory && post.subcategory !== null && post.subcategory !== ''"
                class="category-text">
            &nbsp;&nbsp;/&nbsp;&nbsp;
          </span>
          <router-link v-if="post.subcategory && post.subcategory !== null && post.subcategory !== ''"
                       class="category-link"
                       :to="{ name: 'blogFeedSubCategory',
                             params: { category: kebabify(post.category), subcategory: kebabify(post.subcategory) } }"
                       hreflang="en-us">
            {{post.subcategory}}
          </router-link>
          <h3 class="title-post">
            {{post.title}}
          </h3>
        </div>
      </section>
    </aside>
    <div class="more-posts"
         v-if="displayLocation === 'bottom' && posts.length > 0">
      <div class="strip-holder">
        <span class="band-strip">
          <strong class="title-strip">
            Related Articles
          </strong>
        </span>
      </div>
      <div class="article-post">
        <div class="post-item"
             v-for="post in posts"
             v-bind:key="post.id">
          <div class="img-post">
            <router-link :to="{ name: 'blogPost',
                         params: { category: kebabify(post.urlCategory),
                         title: kebabify(post.url) } }"
                         hreflang="en-us">
              <v-img height="200px"
                     :src="post.headerImage">
              </v-img>
            </router-link>
          </div>
          <strong>
            <router-link :to="{ name: 'blogPost',
                         params: { category: kebabify(post.urlCategory),
                         title: kebabify(post.url) } }"
                         class="post-title"
                         hreflang="en-us">
              {{post.title}}
            </router-link>
          </strong>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { startCase } from 'lodash';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'BlogSidebar',
    props: {
      category: {
        type: String,
        required: true,
      },
      subcategory: {
        type: String,
        required: true,
      },
      displayLocation: {
        type: String,
        required: true,
      },
      currentPostId: {
        type: Number,
        required: true,
      },
    },
    mixins: [tfbHelperMixin],
    data() {
      return {
        posts: [],
        loadBatchSize: 3,
      };
    },
    methods: {
      getPosts() {
        this.posts = [];
        this.$store.dispatch('blog/getRelatedPosts', {
          sCategory: startCase(this.category),
          sSubCategory: startCase(this.subcategory),
          sExcludeId: this.currentPostId,
          sDirection: (this.displayLocation === 'bottom' ? 'desc' : 'asc'),
          sTfbId: this.trackingId,
        })
          .then((foundPosts) => {
            this.posts = foundPosts;
          })
          .catch(() => {
            // Do nothing
          });
      },
    },
    watch: {
      $route: 'getPosts',
    },
    created() {
      this.getPosts();
    },
  };
</script>
<style lang="scss">

  .strip-holder {
    text-align: center;
    padding-bottom: 10px;
    margin-top: -2px;
    margin-bottom: 10px;
  }

    .strip-holder .title-strip {
      color: $white;
      text-transform: uppercase;
      display: inline-block;
      padding: 3px 5px;
      background: $tfb-red;
      min-width: 64px;
      text-align: center;
      position: relative;
    }

      .strip-holder .title-strip:before, .strip-holder .title-strip:after {
        content: "";
        position: absolute;
        top: auto;
        right: auto;
        bottom: -13px;
        left: -42px;
        width: 89px;
        height: 39px;
        background: url(/PublishingImages/ribbon-left-blue.png) no-repeat;
      }

      .strip-holder .title-strip:after {
        position: absolute;
        top: auto;
        right: -42px;
        bottom: -13px;
        left: auto;
        background: url(/PublishingImages/ribbon-right-blue.png) no-repeat;
      }

  .article .strip-holder {
    clear: left;
    overflow: hidden;
    margin-bottom: 7px;
  }

    .article .strip-holder .band-strip {
      display: inline-block;
      position: relative;
      padding-bottom: 19px;
    }

      .article .strip-holder .band-strip:before, .article .strip-holder .band-strip:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 100%;
        bottom: auto;
        left: auto;
        height: 1px;
        background: $tfb-off-white;
        width: 99999px;
        margin-right: 29px;
      }

      .article .strip-holder .band-strip:after {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 100%;
        margin-left: 29px;
      }

    .article .strip-holder .title-strip {
      font-size: 11px;
      padding: 5px;
      min-width: 120px;
    }

      .article .strip-holder .title-strip:before, .article .strip-holder .title-strip:after {
        content: "";
        position: absolute;
        top: 11px;
        right: auto;
        bottom: auto;
        left: -29px;
        width: 61px;
        height: 27px;
        background: url(/PublishingImages/ribbon-left-red.png) no-repeat;
      }

      .article .strip-holder .title-strip:after {
        position: absolute;
        top: 11px;
        right: -29px;
        bottom: auto;
        left: auto;
        background: url(/PublishingImages/ribbon-right-red.png) no-repeat;
      }

  .event-holder .box .title-strip {
    font-size: 9px;
    line-height: 1;
    font-weight: 700;
    font-family: 'BS-light', $body-font-family;
  }

    .event-holder .box .title-strip:before,
    .event-holder .box .title-strip:after {
      content: "";
      display: inline-block;
      position: absolute;
      top: auto;
      right: auto;
      bottom: -5px;
      left: -16px;
      width: 33px;
      height: 14px;
      background: url(/PublishingImages/ribbon-small-left-red.png) no-repeat;
    }

    .event-holder .box .title-strip:after {
      position: absolute;
      top: auto;
      right: -16px;
      bottom: -5px;
      left: auto;
      background: url(/PublishingImages/ribbon-small-right-red.png) no-repeat;
    }

  @media print {
    .article .strip-holder {
      display: none
    }
  }
</style>
