<template>
  <div class="tfb-video-wrapper background-darker-gray">
    <v-row no-gutters
           style="height: 100%;">
      <v-col cols="12"
             :md="videoBreakPoint"
             v-for="video in videoArray"
             v-bind:key="video.id"
             v-bind:class="videoClass">
        <div :style="getVideoBackgroundImage(video.Thumbnail)">
          <div class="tfb-video align-center px-5 py-10">
            <div class="tfb-video-header">
              <img alt="Real Stories"
                   id="video-header-image"
                   v-bind:src="imageRootPath + '/real-stories-header.png'" />
            </div>
            <div class="tfb-video-title">
              <p class="tfb-title-text-medium">
                {{video.Title}}
              </p>
            </div>
            <div class="tfb-video-subtitle">
              <p class="tfb-title-text">
                {{video.SubTitle}}
              </p><br />
            </div>
            <div class="tfb-video-button">
              <v-btn @click="getVideo(video.Id)"
                     class="tfb-button background-red">
                Watch Video
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="showEmbedded"
              max-width="800"
              id="embedVideoDialog">
      <v-card class="align-center tfb-video-dialog">
        <span v-html="videoText"></span>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showLocal"
              max-width="800"
              id="localVideoDialog">
      <v-card class="align-center tfb-video-dialog">
        <video v-bind:src="videoText"
               controls>
          Sorry, your browser does not support embedded videos.
        </video>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'Video',
    mixins: [tfbHelperMixin],
    props: {
      videoArray: {
        type: Array,
        required: true,
        function() {
          return [{
            Id: '', Order: '', SubTitle: '', Title: '', Thumbnail: '',
          }];
        },
      },
    },
    data() {
      return {
        showEmbedded: false,
        showLocal: false,
        videoText: null,
      };
    },
    computed: {
      videoBreakPoint() {
        return 12 / this.videoArray.length;
      },
      videoClass() {
        return {
          'video-separator': this.videoArray.length > 1,
        };
      },
    },
    methods: {
      getVideo(videoId) {
        this.$store.dispatch('app/getVideoById', { sId: videoId, sTfbId: this.trackingId })
          .then((response) => {
            if (response) {
              if (response.Embed) {
                this.videoText = response.Embed;
                this.showEmbedded = true;
              } else if (response.Url) {
                this.videoText = response.Url;
                this.showLocal = true;
              }
            }
          })
          .catch(() => {
            // Don't do anything
          });
      },
    },
  };
</script>
<style lang="scss">
  .tfb-video-wrapper {
    padding: 10px;
    overflow: hidden;
    height: 100%;
  }
  .tfb-video {
    max-width: 600px;
    vertical-align: middle;
    min-height: 330px;
  }
  .tfb-video-header {
    height: 25px;
  }
  .tfb-video-title {
    height: 135px;
  }
  .tfb-video-subtitle {
    height: 85px;
  }
  .tfb-video-button {
    height: 36px;
  }

  .tfb-video-dialog {
    padding: 20px;
  }

  div .video-separator:not(:first-child) {
    padding-top: 5px !important;
  }
  @media (min-width: 960px) {
    div .video-separator:not(:first-child) {
      padding-top: 0px !important;
      padding-left: 9px !important;
    }
  }
</style>
