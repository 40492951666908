<template>
  <v-container fluid
               class="tfb-smaller-container tfb-component-container">
    <v-row>
      <v-col cols="12">
        <v-container fluid
                     class="drop-shadow background-white px-md-10 pb-5">
          <v-row>
            <v-col cols="12">
              <span class="tfb-title-text-small text-uppercase">
                Manage Email Address
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   class="py-0">
              <label class="font-weight-bold">Current Email Address:</label>
              {{emailAddress}}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   class="pt-0">
              <label class="font-weight-bold">Verification Status:</label>&nbsp;&nbsp;
              <span v-if="!emailVerified">
                <a href=""
                   v-on:click.prevent="sendVerification()">Click here to verify your email address</a>
              </span>
              <span v-else>
                <v-icon small>mdi-check</v-icon> Verified
              </span>
            </v-col>
          </v-row>
          <v-row v-if="pendingEmailAddress && pendingEmailAddress !== null && pendingEmailAddress !== ''">
            <v-col cols="12"
                   class="pt-0 font-italic body-2">
              <label class="font-weight-bold">Pending Email Address:</label>
              {{pendingEmailAddress}} <a href="" v-on:click.prevent="sendPendingVerification()">Resend email address verification</a>
            </v-col>
          </v-row>
          <v-row v-if="verificationSuccess || emailSuccess">
            <v-col cols="12">
              <v-alert type="success"
                       v-if="verificationSuccess">
                Thank you! A verification email has been sent. Please click on the link in that email to verify your email address.
              </v-alert>
              <v-alert type="success"
                       v-if="emailSuccess">
                A verification email has been sent to your new email address. Please click on the accompanying link to verify your email address and complete the update process.
              </v-alert>
            </v-col>
          </v-row>
          <ValidationObserver ref="obv" v-slot="{ handleSubmit }">
            <v-form @submit.stop.prevent="handleSubmit(updateEmailAddress)"
                    v-if="!emailSuccess">
              <v-row>
                <v-col cols="12">
                  <text-input rules="required|email|valid_domain|confirmed:username|min:4|max:60"
                              minlength="4"
                              maxlength="60"
                              label="New Email Address"
                              v-model="newEmailAddress"
                              :disabled="formDisabled"></text-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <text-input rules="required|email|min:4|max:60"
                              minlength="4"
                              maxlength="60"
                              label="Confirm New Email Address"
                              v-model="confirmNewEmailAddress"
                              :disabled="formDisabled"
                              vid="username"></text-input>
                </v-col>
              </v-row>
              <v-row v-if="memberList && memberList.length > 1">
                <v-col cols="12"
                       class="py-0">
                  <v-checkbox v-model="updateAllMembers"
                              label="Update for all associated memberships?">
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"
                       class="font-weight-bold py-0">
                  Please note that your new email address will be your username upon verification.
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"
                       sm="6"
                       md="4">
                  <v-btn block
                         :disabled="formDisabled"
                         type="submit"
                         class="tfb-button background-red">Submit</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
          <v-overlay absolute
                     :value="saveOverlay">
            <v-progress-circular indeterminate
                                 size="64"></v-progress-circular>
          </v-overlay>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import { ValidationObserver } from 'vee-validate';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'EmailChanges',
    mixins: [tfbHelperMixin],
    components: {
      ValidationObserver,
    },
    data() {
      return {
        saveOverlay: false,
        formDisabled: false,
        newEmailAddress: '',
        confirmNewEmailAddress: '',
        updateAllMembers: true,
        emailSuccess: false,
        verificationSuccess: false,
      };
    },
    computed: {
      memberList() {
        try {
          return this.$store.getters['member/memberList'];
        } catch (error) {
          return null;
        }
      },
      emailAddress() {
        try {
          return this.$store.getters['member/emailAddress'];
        } catch (error) {
          return '';
        }
      },
      pendingEmailAddress() {
        try {
          return this.$store.getters['member/pendingEmailAddress'];
        } catch (error) {
          return '';
        }
      },
      emailVerified() {
        try {
          return this.$store.getters['member/verifiedIndicator'];
        } catch (error) {
          return false;
        }
      },
    },
    methods: {
      sendVerification() {
        this.emailSuccess = false;
        this.verificationSuccess = false;
        this.saveOverlay = true;
        dispatch('member/sendEmailVerification', {
          sEmailAddress: this.emailAddress, sTfbId: this.trackingId,
        })
          .then(() => {
            // Update was successful
            this.verificationSuccess = true;
            this.saveOverlay = false;
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error);
            this.saveOverlay = false;
          });
      },
      sendPendingVerification() {
        this.emailSuccess = false;
        this.verificationSuccess = false;
        this.saveOverlay = true;
        dispatch('member/sendEmailVerification', {
          sEmailAddress: this.pendingEmailAddress, sTfbId: this.trackingId,
        })
          .then(() => {
            // Update was successful
            this.verificationSuccess = true;
            this.saveOverlay = false;
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error);
            this.saveOverlay = false;
          });
      },
      updateEmailAddress() {
        this.emailSuccess = false;
        this.verificationSuccess = false;
        this.saveOverlay = true;
        dispatch('member/updateEmailAddress', {
          sOldEmailAddress: this.emailAddress, sNewEmailAddress: this.newEmailAddress, sIncludeAllMembers: this.updateAllMembers, sTfbId: this.trackingId,
        })
          .then(() => {
            // Update was successful
            this.emailSuccess = true;
            this.saveOverlay = false;
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error);
            this.saveOverlay = false;
          });
      },
    },
    mounted() {
      if (!this.isMemberRole) {
        // Not the member, don't allow changes
        this.formDisabled = true;
      }
    },
  };
</script>
<style lang="scss">

</style>
