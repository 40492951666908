<template>
  <div id="start-claim"
       class="background-darker-blue tfb-claim">
    <h1 class="tfb-title-text-large text-center">
      File A Claim
    </h1>
    <v-container class="tfb-smallest-container tfb-claim-box">
      <h2 class="tfb-title-text-small text-darkest-blue text-uppercase">
        Claim Submission Confirmation
      </h2>
      <v-row>
        <v-col cols="12">
          Your claim has been submitted successfully!<br /><br />
          <div v-if="claim.destination === 0">
            <label class="font-weight-bold">Claim Number: </label>
            <span id="claimNumber">{{claimConfirmation}}</span>
          </div>
          <div v-else>
            <label class="font-weight-bold">Confirmation Number: </label>
            <span id="caseNumber">{{claimConfirmation}}</span>
            <br />
            <br />
            <p>
              Please keep the above confirmation number for your records.
              <strong>It is not your claim number.</strong>
              That will be provided to you by a Texas Farm Bureau Insurance Claims Representative.
            </p>
            <p>
              Please also note that your claim will not be viewable online until it is entered into the system by your Claims Representative.
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="selectedFiles && selectedFiles.length > 0">
        <v-col cols="12">
          <span class="tfb-title-text-smaller text-darkest-blue text-uppercase">
            Uploaded Files:
          </span>
          <ul v-if="errorFiles && errorFiles.length > 0">
            <li v-for="file in errorFiles"
                v-bind:key="file.id"
                style="list-style-type:none;">
              <v-icon>mdi-close</v-icon> {{file.name}}
            </li>
          </ul>
          <ul v-if="uploadedFiles && uploadedFiles.length > 0">
            <li v-for="file in uploadedFiles"
                v-bind:key="file.id"
                style="list-style-type:none;">
              <v-icon>mdi-check</v-icon> {{file.name}}
            </li>
          </ul>
          <span v-else>
            <br />No files uploaded.
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <span class="tfb-title-text-smaller text-darkest-blue text-uppercase">
            Claims Adjuster
          </span>
        </v-col>
      </v-row>
      <v-row v-if="!claimAdjuster">
        <v-col cols="12">
          Adjuster Information Unavailable<br />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <div v-if="claimAdjuster.Name === null || claimAdjuster.Name === '' || claimAdjuster.Name === 'null'">
            <span class="font-weight-bold">Adjuster Information Unavailable</span><br />
          </div>
          <div v-else>
            <span class="font-weight-bold">{{claimAdjuster.Name}}</span><br />
          </div>
          <address>
            <div v-if="claimAdjuster.Email && claimAdjuster.Email !== null
                 && claimAdjuster.Email !== '' && claimAdjuster.Email !== 'null'">
              <span>Email:</span>
              <a v-bind:href="claimAdjuster.Email | linkFormatEmail"
                 itemprop="email">{{claimAdjuster.Email}}</a><br />
            </div>
            <div v-if="claimAdjuster.office && claimAdjuster.office !== null
                 && claimAdjuster.office.Phone !== null && claimAdjuster.office.Phone !== ''
                 && claimAdjuster.office.Phone !== 'null'">
              <label>Phone:</label>
              <a v-bind:href="claimAdjuster.office.Phone | linkFormatPhone"
                 itemprop="telephone">{{claimAdjuster.office.Phone | formatPhone}}</a><br />
            </div>
          </address>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import tfbHelperMixin from '@/mixins/helpers';
  import tfbMetaMixin from '@/mixins/metaInfo';

  export default {
    name: 'ClaimReport',
    mixins: [tfbMetaMixin, tfbHelperMixin],
    computed: {
      metaDescription() {
        return 'Report a claim or view the progress of current claims on the Texas Farm Bureau Insurance resources page.';
      },
      metaPageTitle() {
        return 'Submission Confirmation';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/claims/confirm`;
      },
      claimConfirmation() {
        return this.$store.getters['claims/confirmationNumber'];
      },
      claimAdjuster() {
        return this.$store.getters['claims/claimAdjuster'];
      },
      claim() {
        return this.$store.getters['claims/claim'];
      },
      selectedFiles() {
        return this.$store.getters['claims/selectedFiles'];
      },
      uploadedFiles() {
        return this.$store.getters['claims/uploadedFiles'];
      },
      errorFiles() {
        return this.$store.getters['claims/errorFiles'];
      },
    },
    created() {
      dispatch('app/clearErrorMessage');
      dispatch('app/setIsLoading', true);
      if (this.selectedFiles && this.selectedFiles.length > 0) {
        for (let f = 0; f < this.selectedFiles.length; f += 1) {
          dispatch('claims/uploadFiles', { destination: this.claim.destination, claimId: this.claimConfirmation, file: this.selectedFiles[f] })
            .then(() => {
              // const processedFileLength = this.uploadedFiles.length + this.errorFiles.length;
              // if (processedFileLength === this.selectedFiles.length) {
                dispatch('app/setIsLoading', false);
              // }
            })
            .catch((error) => {
              dispatch('app/setErrorMessage', error.toString());
              dispatch('app/setIsLoading', false);
            });
        }
      } else {
        dispatch('app/setIsLoading', false);
      }
    },
  };
</script>
<style lang="scss">

  .tfb-claim {
    padding-top: 50px;
    padding-bottom: 100px;
  }

  .tfb-claim-box {
    background-color: $white;
    padding: 20px;
    color: $tfb-darkest-blue;
  }
</style>
