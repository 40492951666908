<template>
  <v-container fluid
               class="tfb-container pb-10">
    <h1 class="tfb-title-text-medium text-darker-blue text-uppercase">Terms and Conditions</h1>
    <v-row>
      <v-col cols="12">
        <v-container fluid
                     class="drop-shadow background-white">
          <v-row v-if="showEbillTerms">
            <v-col cols="12">
              <h2 class="tfb-title-text-small text-uppercase text-darker-blue">E-bill Paperless Billing</h2>
            </v-col>
          </v-row>
          <v-row v-if="showEbillTerms"
                 v-bind:class="[ !showingFullEmail ? 'showLess' : '' ]">
            <v-col cols="12"
                   class="text-body-2">
              <p>
                By clicking on the &ldquo;Accept&rdquo; button below, you accept these Terms and Conditions governing the use of <strong>E-bill Paperless Billing.</strong>
              </p>
              <p>
                Throughout these Terms and Conditions, the words &ldquo;you&rdquo; and &ldquo;your&rdquo; refer to the policyholder, the named insured and/or the spouse if
                a resident of the same household, if applicable. The words &ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our&rdquo; within these Terms and Conditions refer
                to the companies providing your insurance, which may include, but not be limited to, Texas Farm Bureau Mutual Insurance Company, Texas Farm Bureau Premium
                Finance Company, Texas Farm Bureau Underwriters, Texas Farm Bureau Casualty Insurance Company, and Farm Bureau County Mutual Insurance Company of Texas and
                may also include, as applicable, a contractor acting on our behalf.
              </p>
              <p>
                These Terms and Conditions are in addition to other terms and conditions for other services provided by us. By agreeing to these Terms and Conditions, we will
                send you billing-related notices or payment reminders including, but not limited to, billing statements, billing notices, and other billing-related
                communications, electronically to the e-mail address you provided us. YOUR ACCEPTANCE OF THESE TERMS AND CONDITIONS CONSTITUTES YOUR AGREEMENT TO THESE TERMS
                AND CONDITIONS AND ANY SUBSEQUENT CHANGES TO THESE TERMS AND CONDITIONS; DO NOT CLICK &ldquo;ACCEPT&rdquo; OR PROVIDE PERSONAL INFORMATION IF YOU DO NOT AGREE
                WITH ALL OF THESE TERMS AND CONDITIONS.
              </p>
              <p>
                E-bill Paperless Billing must be selected individually for eligible insurance policies or master account. Only certain policies are eligible for E-bill Paperless
                Billing.  You may receive certain documents by mail when required by law, due to technological constraints, or as we deem appropriate. Once you are successfully
                enrolled in E-bill Paperless Billing, you should expect to stop receiving paper billing statements within the next two billing cycles. In addition to E-bill Paperless
                Billing notifications, you will continue to receive copies of your policy documents through your chosen method of delivery, whether via paper or e-mail. Any E-bill
                Paperless Billing notification we send will be dated when sent to the e-mail address on file, even if a document is sent both electronically and as a paper copy.
                A paper copy does not alter the delivery date of the electronic document or any deadlines or responsibilities referenced by the document.
              </p>
              <p>
                We do not charge a separate fee for your use of E-bill Paperless Billing, but your wireless carrier or internet service provider may charge message, data, or other
                rates or fees for these notifications or your use of cellular data or internet to access these notifications.
              </p>
              <p>
                The enrolled e-mail will begin receiving E-bill Paperless Billing notifications regarding the selected Account shortly after enrollment. The frequency and the timing
                of receipt of e-mails will depend on a variety of factors, including, but not limited to, the policy&rsquo;s billing cycle and whether payment on the account is
                timely or delinquent.
              </p>
              <p>
                E-bill Paperless Billing should be available through your electronic or mobile devices when in operating range of your wireless or internet service provider. However,
                this service may not be available through all wireless carriers or internet service providers, and certain functionalities of E-bill Paperless Billing may not be
                supported by your wireless carrier or internet service provider. It is your responsibility to contact your wireless carrier or internet service provider with questions
                regarding any issues, including rates, fees, and coverage. We cannot guarantee that E-bill Paperless Billing notifications provided through your wireless carrier or
                internet service provider will be delivered to you and are not liable for any delayed or undelivered E-bill Paperless Billing notifications.
              </p>
              <p>
                WE DO NOT WARRANT THAT THE INFORMATION, PROCESSES, OR SERVICES WILL BE UNINTERRUPTED, ACCURATE, COMPLETE, USEFUL, FUNCTIONAL, BUG, OR ERROR-FREE. TO THE EXTENT PERMITTED
                BY LAW, THIS SERVICE IS PROVIDED &ldquo;AS IS&rdquo;, &ldquo;WITH ALL FAULTS&rdquo;, AND WITHOUT EXPRESS OR IMPLIED WARRANTIES OF ANY KIND, INCLUDING THE FOLLOWING
                EXPRESSLY DISCLAIMED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. YOU ASSUME ALL RESPONSIBILITY AND RISK FOR USE OF
                E-BILL PAPERLESS BILLING.
              </p>
              <p>
                It is your responsibility to provide us with an accurate and complete e-mail address, and to promptly update your Online Account with any changes to your e-mail address.
                Changes to your e-mail address used for E-bill Paperless Billing purposes will only update the e-mail address used for E-bill Paperless Billing notifications, and will not
                update any other e-mail addresses you have registered with us.
              </p>
              <p>
                Please ensure that you have disabled any spam or other filters that may prevent the delivery of an E-bill Paperless Billing notification to you. Additionally, please
                ensure that your e-mail is active and capable of receiving new e-mails. We are not responsible for problems arising from e-mails sent to an inactive or out-of-date
                e-mail address. Non-receipt of an E-bill Paperless Billing notification is not an excuse to not pay your bill. You are still responsible for payment of bills or any
                other amounts related to your insurance policy. You further acknowledge that we will continue to send E-bill Paperless Billing notifications to the enrolled e-mail
                address until you change or unenroll that e-mail address. You will receive a confirmation by e-mail when we have received a request to discontinue E-bill Paperless
                Billing notifications. Discontinuing E-bill Paperless Billing notifications will resume paper billing documents within fifteen (15) business days.
              </p>
              <p>
                At any time, you may provide updates to contacts and enrolled e-mail addresses or opt out of E-bill Paperless Billing in the &ldquo;Manage My Account&rdquo; section of
                our website. You may subsequently re-enroll in E-bill Paperless Billing at any time if you so choose.
              </p>
              <p>
                In lieu of paper billing documents, you will be sent an e-mail notification with a link to your online account where you can &ldquo;View Your Statement&rdquo;. Click
                on the link contained in the e-mail to access your account and view billing-related documents or simply go the &ldquo;My Accounts&rdquo; section of our website and
                log in to your account at any time. You will be required to enter a User ID and Password to access billing-related communications and applicable documents. The
                documents will appear as a .PDF document when viewed, so you will need compatible software capable of opening and viewing .PDF documents, such as that provided by
                Adobe Systems, Inc.
              </p>
              <p>
                You are responsible for use of the E-bill Paperless Billing service and are fully responsible for any payments to your account. If you believe a person not authorized
                by you has gained access to your e-mail, you must notify us promptly of any such unauthorized access. You are responsible and assume the risk for any loss, theft, or
                unauthorized use of your e-mail address, which could permit unauthorized persons to have access to your personal information and account information and/or use such
                information for unauthorized purposes.
              </p>
              <p>
                We may change these Terms and Conditions at any point and will provide you notice of the updated Terms and Conditions. However, your continued use of this E-bill Paperless
                Billing service represents your ongoing acceptance of these Terms and Conditions.
              </p>
              <p>
                AGREEMENT TO ARBITRATE<br />
                YOU AGREE TO ARBITRATE ANY AND ALL DISPUTES, CONTROVERSIES, OR CLAIMS BETWEEN, ON THE ONE HAND, YOU OR ANYONE ELSE ASSERTING A CLAIM BELONGING TO, OBTAINED THROUGH,
                OR ON BEHALF OF YOU, AND, ON THE OTHER HAND, US, OUR AFFILIATES, AND ANY OF OUR OR THEIR OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS, AND/OR REPRESENTATIVES, ARISING
                OUT OF OR RELATED IN ANY WAY TO: (I) THESE TERMS AND CONDITIONS AND/OR (II) E-BILL PAPERLESS BILLING (THE &ldquo;COVERED CLAIMS&rdquo;).  AN &ldquo;AFFILIATE&rdquo;
                SHALL MEAN ANY ENTITY CONTROLLED BY OR UNDER COMMON CONTROL WITH, DIRECTLY OR INDIRECTLY, THE TEXAS FARM BUREAU (WHICH SHALL INCLUDE WITHOUT LIMITATION CONTROL
                EXERCISED BY MANAGEMENT CONTRACT, PROXIES, OR ANY OTHER MEANS).  FOR ALL COVERED CLAIMS, YOU AGREE THAT ARBITRATION SHALL BE THE EXCLUSIVE REMEDY AND SHALL BE FINAL
                AND BINDING. JUDGMENT UPON ANY ARBITRATION AWARD MAY BE ENTERED IN ANY COURT OF COMPETENT JURISDICTION.
              </p>
              <p>
                <strong>
                  YOU UNDERSTAND AND AGREE THAT ARBITRATION IS THE ONLY LITIGATION FORUM FOR RESOLVING A COVERED CLAIM, AND THAT BOTH WE AND YOU ARE WAIVING A RIGHT TO A TRIAL
                  BEFORE A JUDGE OR JURY IN FEDERAL OR STATE COURT IN FAVOR OF ARBITRATION.
                </strong>
              </p>
              <p>
                YOUR COVERED CLAIM(S) WILL BE DETERMINED IN AN INDIVIDUAL PROCEEDING AND MAY NOT BE JOINED TOGETHER WITH THE CLAIMS OF ANY OTHER PERSONS WITHOUT THE EXPRESS
                WRITTEN CONSENT OF ALL THE OTHER PARTIES TO THE PROCEEDING.  YOU AGREE TO WAIVE ANY RIGHT TO COMMENCE OR PARTICIPATE IN A CLASS, COLLECTIVE, OR REPRESENTATIVE
                ACTION; AND ANY RIGHT TO UTILIZE CLASS-ACTION, COLLECTIVE-ACTION, OR REPRESENTATIVE-ACTION PROCEDURES. (THIS PARAGRAPH IS REFERRED TO AS THE
                &ldquo;CLASS ACTION WAIVER&rdquo;). <strong>
                  THE ACTION WAIVER PRECLUDES YOU OR ANY PARTY FROM PARTICIPATING IN OR BEING REPRESENTED IN ANY CLASS, COLLECTIVE,
                  OR REPRESENTATIVE ACTION REGARDING A COVERED CLAIM IN ANY FORUM.
                </strong>
              </p>
              <p>
                IN ARBITRATION, THE ARBITRATOR SHALL HAVE NO POWER TO ARBITRATE ANY FORM OF A CLASS, COLLECTIVE, OR REPRESENTATIVE PROCEEDING; TO UTILIZE CLASS-ACTION,
                COLLECTIVE-ACTION, OR REPRESENTATIVE-ACTION PROCEDURES; OR TO COMBINE A COVERED CLAIM WITH ANY OTHER CLAIM THAT IS NOT A COVERED CLAIM.  EACH PARTY SHALL
                ASSERT IN ONE PROCEEDING ALL OF THE PARTY&rsquo;S COVERED CLAIMS AGAINST THE OTHER PARTIES ARISING OUT OF THE SAME TRANSACTION OR OCCURRENCE OR SERIES OF
                TRANSACTIONS OR OCCURRENCES.  IF THE CLASS ACTION WAIVER IS, BY FORCE OF LAW, LIMITED, VOIDED, OR DETERMINED TO BE ILLEGAL, INVALID, OR OTHERWISE UNENFORCEABLE,
                THEN THIS AGREEMENT TO ARBITRATE SHALL BE NULL AND VOID WITH RESPECT TO SUCH PROCEEDING, SUBJECT TO THE RIGHT TO APPEAL THE LIMITATION OR INVALIDATION OF THE
                CLASS ACTION WAIVER AND, IF SUCH APPEAL IS SUCCESSFUL, TO ENFORCE THIS AGREEMENT TO ARBITRATE SUBJECT TO THE CLASS ACTION WAIVER.
              </p>
              <p>
                THE ARBITRATOR SHALL HAVE THE EXCLUSIVE AUTHORITY TO RESOLVE ANY AND ALL ISSUES OF ARBITRABILITY, INCLUDING BUT NOT LIMITED TO ISSUES REGARDING THE EXISTENCE,
                ENFORCEABILITY, VALIDITY, LEGALITY, UNCONSCIONABILITY, SCOPE, INTERPRETATION, OR APPLICABILITY OF THIS AGREEMENT OR ANY PART THEREOF; NOTWITHSTANDING THE ABOVE,
                ANY ISSUES REGARDING THE VALIDITY AND EFFECT OF THE CLASS ACTION WAIVER MAY BE DETERMINED ONLY BY A COURT AND NOT BY AN ARBITRATOR.
              </p>
              <p>
                THE ARBITRATOR SHALL NOT HAVE THE POWER TO GRANT GREATER RELIEF THAN WOULD HAVE BEEN AVAILABLE HAD THE MATTER BEEN HEARD IN COURT.  THE ARBITRATOR SHALL BAR A
                COVERED CLAIM IF ARBITRATION IS NOT INITIATED WITHIN THE TIME OTHERWISE PERMITTED FOR LEGAL ACTION UNDER THE APPLICABLE LIMITATIONS PERIOD.  THE PARTIES DO NOT
                WAIVE ANY EXHAUSTION REQUIREMENTS THAT MAY APPLY PRIOR TO ASSERTING A COVERED CLAIM.  THE ARBITRATOR SHALL HAVE NO POWER TO VARY OR IGNORE ANY TERM OF THESE
                TERMS AND CONDITIONS.  THE ARBITRATOR SHALL FOLLOW THE SUBSTANTIVE LAWS OF THE STATE OF TEXAS (WITHOUT REFERENCE TO ITS CHOICE-OF-LAW PRINCIPLES) AND ANY
                APPLICABLE FEDERAL LAW.
              </p>
              <p>
                WE WILL PAY ALL ARBITRATOR COSTS AND ADMINISTRATIVE FEES OF THE ARBITRATION EXCEPT FOR ANY INITIAL FILING FEE FOR A CLAIM BROUGHT BY YOU, WHICH FEE SHALL BE
                PAID BY YOU UP TO THE LESSER OF $400 OR THE COST OF FILING A NEW CIVIL SUIT IN THE U.S. DISTRICT COURT FOR THE WESTERN DISTRICT OF TEXAS.  THE PARTIES WILL
                BE RESPONSIBLE FOR THEIR OWN ATTORNEY&rsquo;S FEES UNLESS OTHERWISE ORDERED BY THE ARBITRATOR IN ACCORDANCE WITH APPLICABLE LAW.  ARBITRATION SHALL BE CONDUCTED
                BY A SINGLE ARBITRATOR SELECTED THROUGH THE AMERICAN ARBITRATION ASSOCIATION (&ldquo;AAA&rdquo;).  THE PROCEEDING SHALL BE GOVERNED BY THE COMMERCIAL ARBITRATION RULES
                OF THE AAA IN EFFECT AT THE TIME THE ARBITRATION IS COMMENCED, BUT ONLY TO THE EXTENT CONSISTENT WITH THIS AGREEMENT.  VENUE FOR THE ARBITRATION PROCEEDING
                SHALL BE IN MCLENNAN COUNTY, TEXAS.
              </p>
              <p>
                THIS AGREEMENT TO ARBITRATE IS MADE PURSUANT TO AND SHALL BE GOVERNED BY THE FEDERAL ARBITRATION ACT. EXCEPT AS MAY BE REQUIRED BY LAW OR AS NECESSARY IN A
                PROCEEDING TO CONFIRM OR ENFORCE AN ARBITRATION AWARD, NEITHER A PARTY NOR AN ARBITRATOR MAY DISCLOSE THE EXISTENCE, CONTENT, OR RESULTS OF ANY ARBITRATION
                UNDER THESE TERMS AND CONDITIONS.  AN ARBITRATION AWARD AND ANY JUDGMENT CONFIRMING AN AWARD WILL APPLY ONLY TO THE SPECIFIC CASE ADJUDICATED AND SHALL NOT
                BE USED IN ANY OTHER CASE OR ARBITRATION PROCEEDING EXCEPT TO ENFORCE THE AWARD.
              </p>
            </v-col>
          </v-row>
          <v-row v-if="showEbillTerms">
            <v-col cols="12"
                   class="text-center">
              <v-btn text
                     class="tfb-expand-terms-button mx-auto"
                     @click="showingFullEmail = !showingFullEmail">Read {{ showingFullEmail ? "Less" : "More" }}</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="showTextTerms">
            <v-col cols="12">
              <h2 class="tfb-title-text-small text-uppercase text-darker-blue">Text Billing Alerts</h2>
            </v-col>
          </v-row>
          <v-row v-if="showTextTerms"
                 v-bind:class="[ !showingFullText ? 'showLess' : '' ]">
            <v-col cols="12"
                   class="text-body-2">
              <p>
                By clicking on the &ldquo;Accept&rdquo; button below, you accept these Terms and Conditions governing the use of <strong>Text Billing Alerts</strong>.
              </p>
              <p>
                Throughout these Terms and Conditions, the words &ldquo;you&rdquo; and &ldquo;your&rdquo; refer to the policyholder, the named insured and/or the spouse if
                a resident of the same household, if applicable. The words &ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our&rdquo; within these Terms and Conditions refer
                to the companies providing your insurance, which may include, but not be limited to, Texas Farm Bureau Mutual Insurance Company, Texas Farm Bureau Premium
                Finance Company, Texas Farm Bureau Underwriters, Texas Farm Bureau Casualty Insurance Company, and Farm Bureau County Mutual Insurance Company of Texas and
                may also include, as applicable, a contractor acting on our behalf.
              </p>
              <p>
                These Terms and Conditions are in addition to other terms and conditions for other services provided by us. By agreeing to these Terms and Conditions, we will
                send you billing-related text messages to your mobile number, including, but not limited to, billing statements, billing notices, and other billing-related
                communications. YOUR ACCEPTANCE OF THESE TERMS AND CONDITIONS CONSTITUTES YOUR AGREEMENT TO THESE TERMS AND CONDITIONS AND ANY SUBSEQUENT CHANGES TO THESE TERMS
                AND CONDITIONS; DO NOT CLICK &ldquo;ACCEPT&rdquo; OR PROVIDE PERSONAL INFORMATION IF YOU DO NOT AGREE WITH ALL OF THESE TERMS AND CONDITIONS.
              </p>
              <p>
                Text Billing Alerts must be selected individually for eligible insurance policies. In addition to Text Billing Alerts, you will continue to receive copies of
                billing-related notices through your chosen method of delivery, whether via paper or email. You may receive certain documents by mail when required by law,
                due to technological constraints, or as we deem appropriate.
              </p>
              <p>
                Text Billing Alerts should be available through your electronic or mobile devices when in operating range of your wireless or internet service provider.
                However, this service may not be available through all wireless carriers or internet service providers, and certain functionalities of Text Billing Alerts
                may not be supported by your wireless carrier or internet service provider. It is your responsibility to contact your carrier with questions regarding any
                issues, including rates, fees, and coverage. We cannot guarantee that Text Billing Alerts provided through your wireless carrier or internet service
                provider will be delivered to you and are not liable for any delayed or undelivered Text Billing Alerts.
              </p>
              <p>
                By enrolling a mobile number in Text Billing Alerts, you are certifying that you are the account holder for the mobile number, or you have permission from
                the account holder to use that mobile number to receive text messages from us. You grant us express permission to send automated text messages to the mobile
                number unless and until such permission is revoked. By granting us express permission to send you Text Billing Alerts, you are requesting to receive text
                messages even though your mobile number may otherwise be on the federal or Texas Do Not Call List and you agree that your request overrides any prior Do
                Not Call or opt-out request. We may use third-party vendors to enhance certain features, including texts sent through an automatic telephone dialing system.
              </p>
              <p>
                The enrolled mobile number will begin receiving Text Billing Alerts regarding the selected policies shortly after enrollment. The frequency of text messages
                and the timing of receipt of text messages will depend on a variety of factors, including, but not limited to, the policy&rsquo;s billing cycle and whether
                payment on the account is timely or delinquent.
              </p>
              <p>
                WE DO NOT WARRANT THAT THE INFORMATION, PROCESSES, OR SERVICES WILL BE UNINTERRUPTED, ACCURATE, COMPLETE, USEFUL, FUNCTIONAL, BUG, OR ERROR-FREE. TO THE
                EXTENT PERMITTED BY LAW, THIS SERVICE IS PROVIDED &ldquo;AS IS&rdquo;, &ldquo;WITH ALL FAULTS&rdquo;, AND WITHOUT EXPRESS OR IMPLIED WARRANTIES OF ANY KIND,
                INCLUDING THE FOLLOWING EXPRESSLY DISCLAIMED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. YOU ASSUME ALL
                RESPONSIBILITY AND RISK FOR USE OF TEXT BILLING ALERTS.
              </p>
              <p>
                It is your responsibility to provide us with an accurate and complete mobile number capable of receiving text messages. You must notify us through your
                Online Account if your mobile number is changed or deactivated, and you further acknowledge that we will continue to send Text Billing Alerts to the enrolled
                mobile number until you change or unenroll the mobile number.
              </p>
              <p>
                Changing a contact&rsquo;s mobile number used for Text Billing Alerts will update the mobile number used for Text Billing Alerts and will also update the
                mobile number associated with that contact across all of our systems for all purposes.
              </p>
              <p>
                Please ensure that you have disabled any robocall or other filters that may prevent the delivery of text messages to the enrolled mobile number. In the
                event any Text Billing Alert is delayed or undelivered, you are still responsible for payment of any premium or other amount due.
              </p>
              <p>
                As part of Text Billing Alerts, you will receive text messages notifying you when certain billing-related notices are ready to be viewed. Click on the link
                contained in the text message to log in to your account and view billing-related documents associated with your account. You may also go to the
                &ldquo;My Accounts&rdquo; section of our website and log in to your account at any time. You will be required to enter a User ID and Password to access your account.
              </p>
              <p>
                You are responsible for use of Text Billing Alerts and are fully responsible for any payments to your account. If you believe a person not authorized by
                you has gained access to your mobile number, you must notify us promptly of any such unauthorized access. You are responsible and assume the risk for any
                loss, theft, or unauthorized use of your mobile number, which could permit unauthorized persons to have access to your personal information and account
                information and/or use such information for unauthorized purposes.
              </p>
              <p>
                If a Text Billing Alert relates to a document, the document will appear as a .PDF document when viewed, so you will need compatible software capable of
                opening and viewing .PDF documents, such as that provided by Adobe Systems, Inc.
              </p>
              <p>
                At any time, you may provide updates to contacts and enrolled mobile numbers or opt out of Text Billing Alerts in the &ldquo;Manage My Account&rdquo;
                section of our website. By replying &ldquo;STOP&rdquo; to any text from us, that mobile number will be unenrolled in any future Text Billing Alerts.
                You will receive confirmation by either text message or email of a request to unenroll in Text Billing Alerts. If you reply &ldquo;STOP&rdquo; to any text
                from us, you agree to receive a confirmation message from us stating you have opted out of Text Billing Alerts. You will also no longer be able to receive
                text messages to that mobile number from us for any other services or alerts we may provide unless we amend these Terms and Conditions. You may subsequently
                enroll in Text Billing Alerts from a different mobile number if you so choose.
              </p>
              <p>
                We may change these Terms and Conditions at any point and will provide you notice of the updated Terms and Conditions. However, your continued use of Text
                Billing Alerts represents your ongoing acceptance of these Terms and Conditions.
              </p>
              <p>
                AGREEMENT TO ARBITRATE<br />
                YOU AGREE TO ARBITRATE ANY AND ALL DISPUTES, CONTROVERSIES, OR CLAIMS BETWEEN, ON THE ONE HAND, YOU OR ANYONE ELSE ASSERTING A CLAIM BELONGING TO, OBTAINED
                THROUGH, OR ON BEHALF OF YOU, AND, ON THE OTHER HAND, US, OUR AFFILIATES, AND ANY OF OUR OR THEIR OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS, AND/OR
                REPRESENTATIVES, ARISING OUT OF OR RELATED IN ANY WAY TO: (I) THESE TERMS AND CONDITIONS AND/OR (II) TEXT BILLING ALERTS (THE &ldquo;COVERED CLAIMS&rdquo;).
                AN &ldquo;AFFILIATE&rdquo; SHALL MEAN ANY ENTITY CONTROLLED BY OR UNDER COMMON CONTROL WITH, DIRECTLY OR INDIRECTLY, THE TEXAS FARM BUREAU (WHICH SHALL
                INCLUDE WITHOUT LIMITATION CONTROL EXERCISED BY MANAGEMENT CONTRACT, PROXIES, OR ANY OTHER MEANS).  FOR ALL COVERED CLAIMS, YOU AGREE THAT ARBITRATION SHALL
                BE THE EXCLUSIVE REMEDY AND SHALL BE FINAL AND BINDING. JUDGMENT UPON ANY ARBITRATION AWARD MAY BE ENTERED IN ANY COURT OF COMPETENT JURISDICTION.
              </p>
              <p>
                <strong>
                  YOU UNDERSTAND AND AGREE THAT ARBITRATION IS THE ONLY LITIGATION FORUM FOR RESOLVING A COVERED CLAIM, AND THAT BOTH WE AND YOU ARE WAIVING A RIGHT TO
                  A TRIAL BEFORE A JUDGE OR JURY IN FEDERAL OR STATE COURT IN FAVOR OF ARBITRATION.
                </strong>
              </p>
              <p>
                YOUR COVERED CLAIM(S) WILL BE DETERMINED IN AN INDIVIDUAL PROCEEDING AND MAY NOT BE JOINED TOGETHER WITH THE CLAIMS OF ANY OTHER PERSONS WITHOUT THE EXPRESS
                WRITTEN CONSENT OF ALL THE OTHER PARTIES TO THE PROCEEDING.  YOU AGREE TO WAIVE ANY RIGHT TO COMMENCE OR PARTICIPATE IN A CLASS, COLLECTIVE, OR REPRESENTATIVE
                ACTION; AND ANY RIGHT TO UTILIZE CLASS-ACTION, COLLECTIVE-ACTION, OR REPRESENTATIVE-ACTION PROCEDURES. (THIS PARAGRAPH IS REFERRED TO AS THE
                &ldquo;CLASS ACTION WAIVER&rdquo;).  <strong>
                  THE CLASS ACTION WAIVER PRECLUDES YOU OR ANY PARTY FROM PARTICIPATING IN OR BEING REPRESENTED IN ANY CLASS,
                  COLLECTIVE, OR REPRESENTATIVE ACTION REGARDING A COVERED CLAIM IN ANY FORUM.
                </strong>
              </p>
              <p>
                IN ARBITRATION, THE ARBITRATOR SHALL HAVE NO POWER TO ARBITRATE ANY FORM OF A CLASS, COLLECTIVE, OR REPRESENTATIVE PROCEEDING; TO UTILIZE CLASS-ACTION,
                COLLECTIVE-ACTION, OR REPRESENTATIVE-ACTION PROCEDURES; OR TO COMBINE A COVERED CLAIM WITH ANY OTHER CLAIM THAT IS NOT A COVERED CLAIM.  EACH PARTY SHALL
                ASSERT IN ONE PROCEEDING ALL OF THE PARTY&rsquo;S COVERED CLAIMS AGAINST THE OTHER PARTIES ARISING OUT OF THE SAME TRANSACTION OR OCCURRENCE OR SERIES OF
                TRANSACTIONS OR OCCURRENCES.  IF THE CLASS ACTION WAIVER IS, BY FORCE OF LAW, LIMITED, VOIDED, OR DETERMINED TO BE ILLEGAL, INVALID, OR OTHERWISE UNENFORCEABLE,
                THEN THIS AGREEMENT TO ARBITRATE SHALL BE NULL AND VOID WITH RESPECT TO SUCH PROCEEDING, SUBJECT TO THE RIGHT TO APPEAL THE LIMITATION OR INVALIDATION OF THE
                CLASS ACTION WAIVER AND, IF SUCH APPEAL IS SUCCESSFUL, TO ENFORCE THIS AGREEMENT TO ARBITRATE SUBJECT TO THE CLASS ACTION WAIVER.
              </p>
              <p>
                THE ARBITRATOR SHALL HAVE THE EXCLUSIVE AUTHORITY TO RESOLVE ANY AND ALL ISSUES OF ARBITRABILITY, INCLUDING BUT NOT LIMITED TO ISSUES REGARDING THE EXISTENCE,
                ENFORCEABILITY, VALIDITY, LEGALITY, UNCONSCIONABILITY, SCOPE, INTERPRETATION, OR APPLICABILITY OF THIS AGREEMENT OR ANY PART THEREOF; NOTWITHSTANDING THE ABOVE,
                ANY ISSUES REGARDING THE VALIDITY AND EFFECT OF THE CLASS ACTION WAIVER MAY BE DETERMINED ONLY BY A COURT AND NOT BY AN ARBITRATOR.
              </p>
              <p>
                THE ARBITRATOR SHALL NOT HAVE THE POWER TO GRANT GREATER RELIEF THAN WOULD HAVE BEEN AVAILABLE HAD THE MATTER BEEN HEARD IN COURT.  THE ARBITRATOR SHALL BAR A
                COVERED CLAIM IF ARBITRATION IS NOT INITIATED WITHIN THE TIME OTHERWISE PERMITTED FOR LEGAL ACTION UNDER THE APPLICABLE LIMITATIONS PERIOD.  THE PARTIES DO NOT
                WAIVE ANY EXHAUSTION REQUIREMENTS THAT MAY APPLY PRIOR TO ASSERTING A COVERED CLAIM.  THE ARBITRATOR SHALL HAVE NO POWER TO VARY OR IGNORE ANY TERM OF THESE
                TERMS AND CONDITIONS.  THE ARBITRATOR SHALL FOLLOW THE SUBSTANTIVE LAWS OF THE STATE OF TEXAS (WITHOUT REFERENCE TO ITS CHOICE-OF-LAW PRINCIPLES) AND ANY
                APPLICABLE FEDERAL LAW.
              </p>
              <p>
                WE WILL PAY ALL ARBITRATOR COSTS AND ADMINISTRATIVE FEES OF THE ARBITRATION EXCEPT FOR ANY INITIAL FILING FEE FOR A CLAIM BROUGHT BY YOU, WHICH FEE SHALL BE
                PAID BY YOU UP TO THE LESSER OF $400 OR THE COST OF FILING A NEW CIVIL SUIT IN THE U.S. DISTRICT COURT FOR THE WESTERN DISTRICT OF TEXAS.  THE PARTIES WILL
                BE RESPONSIBLE FOR THEIR OWN ATTORNEY&rsquo;S FEES UNLESS OTHERWISE ORDERED BY THE ARBITRATOR IN ACCORDANCE WITH APPLICABLE LAW.  ARBITRATION SHALL BE
                CONDUCTED BY A SINGLE ARBITRATOR SELECTED THROUGH THE AMERICAN ARBITRATION ASSOCIATION (&ldquo;AAA&rdquo;).  THE PROCEEDING SHALL BE GOVERNED BY THE COMMERCIAL
                ARBITRATION RULES OF THE AAA IN EFFECT AT THE TIME THE ARBITRATION IS COMMENCED, BUT ONLY TO THE EXTENT CONSISTENT WITH THIS AGREEMENT.  VENUE FOR THE
                ARBITRATION PROCEEDING SHALL BE IN MCLENNAN COUNTY, TEXAS.
              </p>
              <p>
                THIS AGREEMENT TO ARBITRATE IS MADE PURSUANT TO AND SHALL BE GOVERNED BY THE FEDERAL ARBITRATION ACT. EXCEPT AS MAY BE REQUIRED BY LAW OR AS NECESSARY IN A
                PROCEEDING TO CONFIRM OR ENFORCE AN ARBITRATION AWARD, NEITHER A PARTY NOR AN ARBITRATOR MAY DISCLOSE THE EXISTENCE, CONTENT, OR RESULTS OF ANY ARBITRATION
                UNDER THESE TERMS AND CONDITIONS.  AN ARBITRATION AWARD AND ANY JUDGMENT CONFIRMING AN AWARD WILL APPLY ONLY TO THE SPECIFIC CASE ADJUDICATED AND SHALL NOT
                BE USED IN ANY OTHER CASE OR ARBITRATION PROCEEDING EXCEPT TO ENFORCE THE AWARD.
              </p>
            </v-col>
          </v-row>
          <v-row v-if="showTextTerms">
            <v-col cols="12"
                   class="text-center">
              <v-btn text
                     class="tfb-expand-terms-button mx-auto"
                     @click="showingFullText = !showingFullText">Read {{ showingFullText ? "Less" : "More" }}</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   class="text-center">
              <span class="font-weight-bold">Email Notifications will be sent to: </span>{{emailAddress}}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   class="text-center">
              <span class="font-weight-bold">Please enter your initials by each change.</span>
            </v-col>
          </v-row>
          <ValidationObserver ref="obv" v-slot="{ handleSubmit }">
            <v-form ref="form"
                    v-if="ebillRequest"
                    @submit.stop.prevent="handleSubmit(saveEbillChanges)">
              <v-row>
                <v-col cols="12">
                  <v-data-table :headers="ebillHeaders"
                                :items="ebillRequest"
                                hide-default-footer
                                disable-sort>
                    <template v-slot:item.MemberInitials="{ item }">
                      <text-input rules="required|min:2|max:3"
                                  dense
                                  minlength="2"
                                  maxlength="3"
                                  label="Initials"
                                  v-model="item.MemberInitials"
                                  :vid="item.PolicyNumber"></text-input>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-btn block
                         @click="cancelEbillChanges()"
                         class="tfb-button background-red">Cancel</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn block
                         type="submit"
                         :disabled="formDisabled"
                         class="tfb-button background-red">Accept</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-container>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import { ValidationObserver } from 'vee-validate';
  import tfbMetaInfo from '@/mixins/metaInfo';
  import tfbHelperMixin from '@/mixins/helpers';
  import { insuredAccessOnlyErrorMessage } from '@/mixins/errorMessages';

  export default {
    name: 'ChangeEbill',
    mixins: [tfbMetaInfo, tfbHelperMixin],
    components: {
      ValidationObserver,
    },
    data() {
      return {
        formDisabled: false,
        showEbillTerms: false,
        showTextTerms: false,
        showingFullEmail: false,
        showingFullText: false,
        changeRequests: [],
        unEnrollRequests: [],
        ebillHeaders: [
          { text: 'Policy', align: 'start', value: 'PolicyNumber' },
          { text: 'E-bill Action', value: 'EbillChange' },
          { text: 'Text Action', value: 'PhoneChange' },
          { text: 'Initials', value: 'MemberInitials' },
        ],
      };
    },
    computed: {
      metaPageTitle() {
        return 'E-bill Paperless Billing and Text Message Notification Terms and Conditions | PolicyHolder';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/policyholder/payments/ebill`;
      },
      ebillRequest() {
        try {
          return this.$store.getters['payments/ebillRequest'];
        } catch (error) {
          return null;
        }
      },
      emailAddress() {
        try {
          return this.$store.getters['member/emailAddress'];
        } catch (error) {
          return '';
        }
      },
      memberId() {
        try {
          return this.$store.getters['member/memberId'];
        } catch (error) {
          return '0';
        }
      },
      memberNumber() {
        try {
          return this.$store.getters['member/memberNumber'];
        } catch (error) {
          return '';
        }
      },
    },
    methods: {
      cancelEbillChanges() {
        dispatch('app/clearErrorMessage');
        // Clear the request
        this.$store.commit('payments/SET_EBILL_REQUEST', null);
        // Go back to Management screen
        this.$router.push({ name: 'policyholderAccountManagement' });
      },
      saveEbillChanges() {
        dispatch('app/clearErrorMessage');
        dispatch('app/setIsLoading', true);
        this.$store.dispatch('app/logToULS', {
          logLevel: 'Info',
          logMessage: `${this.memberId}-${this.memberNumber}-${this.ebillRequest.type}-${this.emailAddress}`,
          fileName: 'ChangeEbill.vue',
          methodName: 'saveEbillChanges-Start',
          trackerId: this.trackingId,
        });
        // Call service
        dispatch('payments/setupEbillInformation', {
          sEmailAddress: this.emailAddress, sMemberNumber: this.memberNumber, sMemberId: this.memberId, sPolicyList: this.ebillRequest, sTfbId: this.trackingId,
        })
          .then((setupResult) => {
            if (setupResult) {
              this.$store.dispatch('app/logToULS', {
                logLevel: 'Info',
                logMessage: setupResult,
                fileName: 'ChangeEbill.vue',
                methodName: 'setupEbillInformation-Response',
                trackerId: this.trackingId,
              });
              // Handle return
              if (setupResult.ErrorMessage) {
                dispatch('app/setErrorMessage', setupResult.ErrorMessage);
                dispatch('app/setIsLoading', false);
              } else {
                // Clear Account/Account Bill Summary to refetch
                this.$store.commit('policy/CLEAR_ACCOUNT_SUMMARY');
                this.$store.commit('payments/CLEAR_ACCOUNT_BILL_SUMMARY');
                // Clear the E-bill request
                this.$store.commit('payments/SET_EBILL_REQUEST', null);
                // Fetch the Account/Account Bill Summary information again
                dispatch('policy/getAccountSummary', { sMemberNumber: this.memberNumber, sTfbId: this.trackingId });
                dispatch('payments/getAccountBillInformation', { sMemberNumber: this.memberNumber, sTfbId: this.trackingId });
                // Go back to Summary screen
                this.$router.push({ name: 'policyholderAccountSummary' });
              }
            }
            dispatch('app/setIsLoading', false);
          })
          .catch((error) => {
            this.$store.dispatch('app/logToULS', {
              logLevel: 'Error',
              logMessage: error,
              fileName: 'ChangeEbill.vue',
              methodName: 'setupEbillInformation-Catch',
              trackerId: this.trackingId,
            });
            dispatch('app/setErrorMessage', error.toString());
            dispatch('app/setIsLoading', false);
          });
        },
    },
    mounted() {
      dispatch('app/clearErrorMessage');
      dispatch('app/setIsLoading', true);
      dispatch('app/doApplicationCheck', { sApplicationName: 'Ebill', sTfbId: this.trackingId })
        .then((returnedStatus) => {
          if (returnedStatus === '') {
            if (!this.isLoggedIn) {
              // Invalid login - go back to login screen
              this.$router.push({ name: 'policyholderLogin' });
            } else if (!this.isMemberRole) {
              // Not the member, don't allow changes
              this.formDisabled = true;
              this.$store.dispatch('app/setErrorMessage', insuredAccessOnlyErrorMessage);
              this.$router.push({ name: 'policyholderAccountManagement' });
            } else if (!this.ebillRequest || this.ebillRequest === undefined || this.ebillRequest === null || this.ebillRequest.length <= 0) {
              // No request values, push back to Management screen
              this.$router.push({ name: 'policyholderAccountManagement' });
            } else {
              for (let i = 0; i < this.ebillRequest.length; i += 1) {
                const policy = this.ebillRequest[i];
                // Do we need to show the E-bill terms?
                if (policy.NewEbill !== policy.OriginalEbill) {
                  this.showEbillTerms = true;
                }

                // Do we need to show the Text terms?
                if (policy.NewPhone !== null && policy.OriginalPhone !== null && policy.NewPhone.MiraId !== policy.OriginalPhone.MiraId) {
                  this.showTextTerms = true;
                }
              }
            }

            dispatch('app/setIsLoading', false);
          } else {
            this.formDisabled = true;
            dispatch('app/setErrorMessage', returnedStatus);
            dispatch('app/setIsLoading', false);
          }
        })
        .catch((error) => {
          dispatch('app/setErrorMessage', error.toString());
          dispatch('app/setIsLoading', false);
        });
    },
  };
</script>
<style lang="scss">

</style>
