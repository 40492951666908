<template>
  <div class="py-2 background-darker-blue"
       v-if="serviceItem && serviceItem.DiscountTitle !== null && serviceItem.DiscountTitle !== ''">
    <v-container class="tfb-container align-center my-12">
      <v-row>
        <v-col cols="12">
          <h2 class="tfb-title-text-medium text-white">
            {{ serviceItem.DiscountTitle }}
          </h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"
               class="tfb-title-text text-white mb-8">
          <span v-html="serviceItem.DiscountHeader"></span>
        </v-col>
      </v-row>
      <v-row v-if="serviceDiscounts && serviceDiscounts !== null && serviceDiscounts.length > 0">
        <v-col v-for="discount in serviceDiscounts"
               v-bind:key="discount.id">
          <discount-item :discount="discount"></discount-item>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import DiscountItem from '@/views/services/coverages/DiscountItem.vue';

  export default {
    name: 'ServiceDiscounts',
    props: {
      service: {
        type: String,
        required: true,
      },
      serviceItem: {
        type: Object,
        required: true,
      },
    },
    components: {
      DiscountItem,
    },
    data() {
      return {
        serviceDiscounts: null,
      };
    },
    watch: {
      $route: 'startDataFetch',
    },
    methods: {
      startDataFetch() {
        this.getDiscountData();
      },
      getDiscountData() {
        this.$store.dispatch('services/getServiceDiscounts', { sServiceType: this.service, sTfbId: this.trackingId })
          .then((discountResponse) => {
            this.serviceDiscounts = discountResponse;
          })
          .catch((error) => {
            this.$store.dispatch('app/setIsLoading', false);
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
    },
    created() {
      this.startDataFetch();
    },
  };
 </script>
<style lang="scss">

</style>
