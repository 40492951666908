<template>
  <v-row>
    <v-col cols="12">
      <h4 class="tfb-title-text-small-bold text-blue text-uppercase">{{claimType}} Claims</h4>
      <v-container v-if="claimListLoading">
        <v-row v-for="i in 3"
               :key="i">
          <v-col cols="12">
            <v-skeleton-loader :loading="claimListLoading"
                               type="list-item-avatar-three-line"
                               class="mx-auto">
            </v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
      <div v-if="!claimListLoading && claimList !== null && claimList.length > 0">
        <v-expansion-panels accordion
                            flat>
          <v-expansion-panel v-for="claim in claimList"
                             :key="claim.ClaimNumber">
            <v-expansion-panel-header @click="getClaimDetails(claim.ClaimNumber, claim.PolicyNumber)">
              <v-container>
                <v-row>
                  <v-col cols="12"
                         class="py-0">
                    <span class="font-weight-bold">{{claim.PolicyType}} Policy:</span> {{claim.PolicyNumber}}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12"
                         class="py-0">
                    <span class="font-weight-bold">Claim:</span> {{claim.ClaimNumber}}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12"
                         class="py-0">
                    <span class="font-weight-bold">Date of Loss:</span> {{formatDate(claim.DateOfLoss)}}
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container v-if="claimDetailLoading">
                <v-row>
                  <v-col cols="12">
                    <v-skeleton-loader :loading="claimDetailLoading"
                                       type="list-item-avatar-three-line"
                                       class="mx-auto">
                    </v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-container>
              <v-container fluid
                           v-if="!claimDetailLoading && claimDetails !== null">
                <v-row>
                  <v-col cols="12"
                         md="5">
                    <span class="font-weight-bold">Description of Loss</span><br />
                  </v-col>
                  <v-col cols="12"
                         md="7">
                    {{claimDetails.lossDescription}}<br />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-data-table dense
                                  :headers="coverageHeaders"
                                  :items="claimDetails.Coverages"
                                  :items-per-page="10"
                                  class="tfb-table">
                      <template v-slot:no-data>
                        No Coverage Information Available
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12"
                         class="py-0">
                    <span class="text-darkest-blue text-uppercase font-weight-bold">
                      Claims Adjuster
                    </span>
                  </v-col>
                </v-row>
                <div v-if="claimDetails.Adjusters && claimDetails.Adjusters.length > 0">
                  <v-row v-for="adjuster in claimDetails.Adjusters"
                         :key="adjuster.id">
                    <v-col cols="12">
                      <address>
                        <span class="font-weight-bold">{{adjuster.firstName}} {{adjuster.lastName}}</span><br />
                        <div v-if="adjuster.address !== null && adjuster.address !== ''">
                          <span v-html="adjuster.address"></span>
                        </div>
                        <div v-if="adjuster.phoneNumber !== null && adjuster.phoneNumber !== ''">
                          <strong>Phone:</strong> <a v-bind:href="`tel:+${adjuster.phoneNumber}`">{{adjuster.phoneNumber | formatPhone}}</a><br />
                        </div>
                        <div v-if="adjuster.emailAddress !== null && adjuster.emailAddress !== ''">
                          <strong>Email Address:</strong> <a v-bind:href="`mailto:${adjuster.emailAddress}`">{{adjuster.emailAddress}}</a><br />
                        </div>
                      </address>
                    </v-col>
                  </v-row>
                </div>
                <v-row v-else>
                  <v-col cols="12">
                    <span class="font-weight-bold">Adjuster Information Unavailable</span><br />
                  </v-col>
                </v-row>
              </v-container>
              <v-container fluid
                           v-if="!claimDetailLoading && claimDetails === null">
                <v-row>
                  <v-col cols="12">
                    <span class="font-weight-bold">No Claim Information Found</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <v-container v-if="!claimListLoading && (!claimList || claimList.length <= 0)">
        <v-row>
          <v-col cols="12"
                 class="py-0">
            You have no available {{claimType}} claims to view.
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import tfbHelperMixin from '@/mixins/helpers';
  import tfbDataMixin from '@/mixins/tfbData';

  export default {
    name: 'ViewClaims',
    props: {
      claimListLoading: {
        type: Boolean,
        required: true,
      },
      claimType: {
        type: String,
        required: true,
      },
      claimList: {
        type: Array,
        required: true,
      },
    },
    mixins: [tfbHelperMixin, tfbDataMixin],
    data() {
      return {
        claimDetails: null,
        claimDetailLoading: false,
        coverageHeaders: [
          { text: 'Coverage', align: 'start', value: 'CoverageDescription' },
          { text: 'Amount Paid', value: 'CoverageAmount' },
        ],
      };
    },
    computed: {
      memberNumber() {
        try {
          return this.$store.getters['member/memberNumber'];
        } catch (error) {
          return '';
        }
      },
    },
    methods: {
      getClaimDetails(claimNumber, policyNumber) {
        this.claimDetailLoading = true;
        dispatch('claims/getClaimDetails', {
          sClaimNumber: claimNumber, sPolicyNumber: policyNumber, sTfbId: this.trackingId,
        })
          .then((claimResponse) => {
            this.claimDetails = claimResponse;
            this.claimDetailLoading = false;
          })
          .catch((error) => {
            this.$store.dispatch('app/logToULS', {
              logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'ViewClaim.vue', methodName: 'getClaimDetails', trackerId: this.trackingId,
            });
            dispatch('app/setErrorMessage', error.toString());
            this.claimDetailLoading = false;
          });
      },
    },
  };
</script>
<style lang="scss">

</style>
