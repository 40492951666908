<template>
  <v-container fluid
               class="tfb-smaller-container pb-10">
    <v-row class="my-1">
      <v-col>
        <h1 class="tfb-title-text-medium text-darker-blue text-center">Terms &amp; Conditions</h1>
      </v-col>
    </v-row>
    <v-row class="my-1">
      <v-col cols="12"
             class="text-center text-uppercase">
        Please read these terms and conditions of use carefully before using this website.
        By using this website, you agree to these terms of use. If you do not agree to these
        terms of use, please do not use this website.
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h2 class="tfb-title-text-small text-darker-blue text-uppercase">
          Website Terms and Conditions of Use:
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"
             md="6">
        <v-expansion-panels accordion
                            flat>
          <v-expansion-panel>
            <v-expansion-panel-header>
              A. <span class="text-blue pl-1">Welcome</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              <p>
                This website (The Texas Farm Bureau Insurance Companies Website) (the &ldquo;Website&rdquo;)
                is owned and operated by Texas Farm Bureau Mutual Insurance Company and its
                affiliated companies (&ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;TFBMIC&rdquo;.) We provide various information,
                materials, and products on and through the Website (the &ldquo;Services&rdquo;), which are
                subject to certain ground rules provided in this document (the &ldquo;Terms&rdquo;). By
                accessing, using, or contributing to the Services, and in consideration of the
                Services we provide to you, you agree to abide by the Terms.
              </p>
              <p>
                TFBMIC has the right at any time to change or discontinue any aspect or feature
                of the Website, including, without limitation, the content, materials, products,
                hours of availability, and equipment needed for access or use of the Website, or
                these Terms. These Terms apply to all users of the Website, including visitors,
                agents, and other authorized users.
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              B. <span class="text-blue pl-1">Disclaimer of Warranty; Limitation of Liability</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              <ol>
                <li>
                  THIS WEBSITE AND THE SERVICES INCLUDED ON THE WEBSITE ARE PROVIDED ON AN
                  &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS WITHOUT ANY WARRANTIES OF ANY KIND. TFBMIC, TO THE
                  FULLEST EXTENT PERMITTED BY LAW, DISCLAIMS ALL WARRANTIES, EXPRESS AND IMPLIED,
                  INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR ANY PARTICULAR
                  PURPOSE, AND NON-INFRINGEMENT. TFBMIC DOES NOT WARRANT THAT THIS WEBSITE AND ITS
                  SERVER OR ANY LINKED SITES ARE FREE OF COMPUTER VIRUSES OR OTHER HARMFUL
                  COMPONENTS. TFBMIC AND ITS RESPECTIVE EMPLOYEES, AGENTS, THIRD PARTY CONTENT
                  PROVIDERS AND LICENSORS DO NOT WARRANT THAT THE WEBSITE WILL BE UNINTERRUPTED
                  OR ERROR FREE; NOR DOES IT MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE
                  OBTAINED FROM USE OF THE WEBSITE, OR AS TO THE ACCURACY, COMPLETENESS, OR RELIABILITY OF
                  ANY INFORMATION, SERVICE OR MERCHANDISE PROVIDED THROUGH THE WEBSITE. IF YOUR
                  USE OF THE WEBSITE OR ANY MATERIAL RESULTS IN THE NEED FOR SERVICING OR REPLACING
                  EQUIPMENT, SOFTWARE OR DATA, TFBMIC IS NOT RESPONSIBLE FOR THOSE COSTS. YOU
                  EXPRESSLY AGREE THAT USE OF THE WEBSITE IS AT YOUR SOLE RISK.
                </li>
                <li>
                  IN NO EVENT WILL TFBMIC, OR ANY PERSON OR ENTITY INVOLVED IN CREATING, PRODUCING
                  OR DISTRIBUTING THE WEBSITE OR THE CONTENT INCLUDED THEREIN, BE LIABLE IN
                  CONTRACT, IN TORT (INCLUDING FOR ITS OWN NEGLIGENCE) OR UNDER ANY OTHER LEGAL THEORY
                  (INCLUDING STRICT LIABILITY) FOR ANY DAMAGES, INCLUDING, WITHOUT LIMITATION,
                  DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, CONSEQUENTIAL OR SIMILAR DAMAGES,
                  INCLUDING, WITHOUT LIMITATION, LOST PROFITS OR REVENUES, LOSS OF USE OR SIMILAR
                  ECONOMIC LOSS, ARISING OUT OF THE USE OF OR INABILITY TO USE THE WEBSITE. YOU
                  HEREBY ACKNOWLEDGE THAT THE PROVISIONS OF THIS SECTION SHALL APPLY TO ALL USE OF AND
                  CONTENT ON THE WEBSITE.
                  <span class="font-weight-bold">
                    APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR
                    INCIDENTAL OR CONSEQUENTIAL DAMAGES, IN WHICH EVENT THE ABOVE LIMITATION
                    OR EXCLUSION MAY NOT BE APPLICABLE. HOWEVER, IN NO EVENT SHALL TFBMIC&rsquo;S
                    TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION
                    WHETHER IN CONTRACT, TORT (INCLUDING ITS OWN NEGLIGENCE) OR UNDER ANY
                    OTHER LEGAL THEORY (INCLUDING STRICT LIABILITY) EXCEED THE AMOUNT PAID
                    BY YOU, IF ANY, FOR ACCESSING AND/OR USING THIS WEBSITE.
                  </span>
                </li>
                <li>
                  THE ABOVE DISCLAIMER OF LIABILITY APPLIES TO ANY DAMAGES OR INJURY CAUSED BY ANY
                  FAILURE OF PERFORMANCE, ERROR, OMISSION, INACCURACY, INTERRUPTION, DELETION,
                  DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, COMMUNICATION LINE FAILURE,
                  THEFT OR DESTRUCTION OR UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE OF THIS
                  WEBSITE, WHETHER FOR BREACH OF CONTRACT, TORTIOUS BEHAVIOR (INCLUDING STRICT
                  LIABILITY), NEGLIGENCE, OR UNDER ANY OTHER CAUSE OF ACTION. YOU SPECIFICALLY
                  ACKNOWLEDGE THAT TFBMIC IS NOT LIABLE FOR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL
                  CONDUCT OF OTHER USERS OR THIRD-PARTIES AND THAT THE RISK OF INJURY FROM THE
                  FOREGOING RESTS ENTIRELY WITH YOU.
                </li>
              </ol>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              C. <span class="text-blue pl-1">Secure Access</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              In order to access the secure pages of the Website, including online payment and
              policyholder information screens, you will be required to register and log in by
              providing certain personal identifiable information, including your policy number,
              your county membership number, your email address, your user Id, and your password.
              After you complete the registration process, you are an &ldquo;authorized user&rdquo; of the
              Website. In addition to the ability to access non-secure areas of the Website,
              authorized users are also able to maintain a Website account, make online payments,
              and retrieve policyholder information through the Website. Authorized users should
              contact the Website webmaster at
              <a href="mailto:webmaster@txfb-ins.com">webmaster@txfb-ins.com</a> for Website
              account questions and similar Website-related matters.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              D. <span class="text-blue pl-1">Website Claim Reporting</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              The Website may provide you the opportunity to report auto and/or property claims
              by entering and submitting information to TFBMIC via the Website. In order to
              properly submit a claim through the Website, you will be required to enter certain
              personal identifiable information about the TFBMIC insured and any other parties
              involved in the claim. The accuracy of any information you submit is your
              responsibility. We are not responsible for any inaccuracies in information
              submitted to us through the Website. However, we will take steps to safeguard
              any information provided to us through the Website as set forth in our
              <router-link :to="{ name: 'privacy'}"
                           hreflang="en-us">Privacy Notice</router-link>.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              E. <span class="text-blue pl-1">Website Auto Quotes</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              The Website may also provide you the opportunity to complete an automobile
              insurance rate quote. While we have taken every precaution to ensure accurate
              quotes, we ask that you keep in mind the following while completing your quote:
              (1) we do not write policies for applicants who reside outside the State of
              Texas, (2) we give quotes for private passenger automobiles only (passenger
              cars, vans, sports utility vehicles and light trucks), (3) your quote will be
              based on the accuracy of the answers that your provide to us, (4) we do not give
              quotes online for motorcycles, mopeds or recreational vehicles, (5) online rate
              quotes are limited to 4 vehicles and 4 drivers – if you have more than 4 vehicles
              to insure, please visit with your agent to ensure that we are providing you the
              best possible rate. We do our very best to accommodate all customers; however,
              we are unable to provide online quotes for all situations. If we find that we are
              unable to provide a quote for your particular situation, we will provide you a
              way to contact an agent in your area regarding your free rate quote. All
              applications for coverage are subject to final underwriting.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              F. <span class="text-blue pl-1">Security Policy</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              You acknowledge and agree that by providing TFBMIC with any personal or confidential
              user information, you consent to the transmission of such personal or confidential
              user information through the public Internet, and understand that there are risks
              that this information could be viewed by other parties. You agree to adhere to procedures
              that we may establish to enhance the safety and confidentiality of transmissions of
              personal data over the Internet.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              G. <span class="text-blue pl-1">Personal Information</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              Industry-standard efforts are used to safeguard the confidentiality of all
              information, regardless of whether it identifies you or not. This security includes
              use of firewalls, secure socket layers and encryption. Your log in information,
              including your email address and password, and your billing contact information
              (collectively your &ldquo;Personal Information&rdquo;) are protected from disclosure according
              to our
              <router-link :to="{ name: 'privacy'}"
                           hreflang="en-us">Privacy Notice</router-link>,
              which we encourage you to read. You should also take steps to protect the
              security of your Personal Information. For example, it is your responsibility
              to protect your password. Do not share your password with any third parties.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              H. <span class="text-blue pl-1">Community Information Sharing</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              This Website may allow you to share, discuss, and discover information and ideas.
              We do not verify all information shared by those commenting. The comments, opinions,
              and statements made do not necessarily reflect the views, policies, or practices of
              TFBMIC and are the responsibility of those individuals who created the information.
              Any information posted is public, and should be courteous, relevant, on topic, and
              safe. Do not post or share obscene or otherwise objectionable material. Keep your
              comments relevant to the area or topic where they are placed. Do not post links to
              sites that contain viruses or malicious software. Any information you post on the
              Website may be reproduced by TFBMIC for business purposes. TFBMIC has the permanent
              right to use your comments, which could include using, distributing, republishing,
              reproducing, changing, or displaying your comments on this Website or in any other
              form or forum.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              I. <span class="text-blue pl-1">Copyright</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              The entire content included on this Website, including but not limited to text,
              graphics, or code is copyrighted as a collective work under the laws of the United
              States and other copyright laws, and is the property of TFBMIC. Any reproduction,
              distribution, display or transmission of the content of the Website is strictly
              prohibited, unless authorized in writing by TFBMIC. You further agree not to change
              or delete any proprietary notices from materials downloaded from the Website.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              J. <span class="text-blue pl-1">Copyright Infringement/Complaints</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              Pursuant to Title 17, United States Code, Section 512(c)(2), notifications of
              claimed copyright infringement should be sent to Service Provider&rsquo;s Designated Agent. If
              you are a copyright owner and you believe that your copyrighted materials have been
              used in a way that constitutes copyright infringement, please see our
              <router-link :to="{ name: 'dmca'}"
                           hreflang="en-us">DMCA Notice Policy</router-link>.
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12"
             md="6">
        <v-expansion-panels accordion
                            flat>
          <v-expansion-panel>
            <v-expansion-panel-header>
              K. <span class="text-blue pl-1">Marks</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              All trademarks, service marks, trade names, and/or trade dress on the Website is
              owned or licensed by TFBMIC. Unauthorized use of any trademark, service mark, or logo
              appearing on the Website may be a violation of federal and/or state trademark laws.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              L. <span class="text-blue pl-1">Restrictions on Use</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              <ol>
                <li>
                  You shall use the Website for lawful purposes only. You shall not transmit
                  through the Website any material that (i) violates or infringes in any way upon
                  the rights of others, (ii) is unlawful, threatening, abusive, defamatory,
                  invasive of privacy or publicity rights, vulgar, obscene, profane or otherwise
                  objectionable, or (iii) encourages conduct that would constitute a criminal
                  offense, gives rise to civil liability or otherwise violates any law. Any
                  conduct by you that in TFBMIC&rsquo;s discretion restricts or inhibits any other
                  user from using or enjoying the Website will not be permitted.
                </li>
                <li>
                  As stated above, the Website contains copyrighted material, trademarks and
                  other proprietary information, including, without limitation, text, software, photos,
                  video, graphics, music and sound, and the contents of the Website are
                  copyrighted under the United States copyright laws. You may not modify, publish, transmit,
                  display, participate in the transfer or sale, create derivative works, or in any
                  way exploit, any of the content, in whole or in part. No copying,
                  redistribution, retransmission, publication, or commercial exploitation of downloaded material
                  will be permitted (except as set forth in paragraph F. above) without the
                  express written permission of TFBMIC. In the event of any permitted copying,
                  redistribution, or publication of copyrighted material, no changes in or
                  deletion of author attribution, trademark legend or copyright notice shall be made. You
                  acknowledge that you do not acquire any ownership rights by downloading
                  copyrighted material.
                </li>
                <li>
                  The foregoing provisions of this Section are for the benefit of TFBMIC, its
                  affiliates, and its third party content providers and licensors and each shall
                  have the right to assert and enforce such provisions directly or on its own
                  behalf.
                </li>
              </ol>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              M. <span class="text-blue pl-1">User Representations and Warranties</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              You represent and warrant that if you are an authorized user, you will use only your
              log in information to access any secure page of the Website. You further represent
              and warrant that you will not use the log in information of any other authorized user to
              access any secure page of the Website.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              N. <span class="text-blue pl-1">Monitoring</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              TFBMIC shall have the right, but not the obligation, to monitor the content of the
              Website to determine compliance with these Terms and any operating rules established
              by TFBMIC and to satisfy any law, regulation or authorized government request.
              TFBMIC shall have the right in its sole discretion to edit, add to or remove any
              material submitted to or posted on the Website. TFBMIC assumes no responsibility
              for the deletion of or failure to store electronic-mail messages or other
              information submitted to the Website.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              O. <span class="text-blue pl-1">Violations of Rules, Regulations and these Terms</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              We reserve the right to seek all remedies available at law and in equity for
              violations of the rules and regulations set forth herein, including the right to
              block access from a particular Internet address to this Website. If you or an
              authorized user violates these Terms, we may, in our discretion, restrict or
              block your or an authorized user&rsquo;s access to this Website or your or the authorized
              user&rsquo;s account.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              P. <span class="text-blue pl-1">Indemnification</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              You agree to defend, indemnify and hold harmless TFBMIC and its affiliates, their
              respective directors, officers, employees, agents, successors and assigns from and
              against any and all claims, actions, demands, damages, costs, liabilities, losses
              and expenses (including reasonable attorneys&rsquo; fees) arising out of your use of the
              Website, your violation of these Terms or any applicable law, statute, ordinance or
              regulation, or your infringement of any intellectual property or other right of any
              person or entity.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Q. <span class="text-blue pl-1">Changed Terms</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              TFBMIC has the right at any time to change or modify these Terms, or any part
              thereof, or to impose new conditions. Such changes, modifications, additions or
              deletions shall be effective immediately upon notice thereof, which may be given by
              any means including, without limitation, posting on the Website, or by electronic or
              conventional mail, or by any other means. Any use of the Website by you after such
              notice shall be deemed to constitute acceptance of such changes, modifications,
              additions or deletions.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              R. <span class="text-blue pl-1">Linked Internet Sites</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              ANY LINKS IN THIS WEBSITE WILL LET YOU LEAVE OUR WEBSITE. THE LINKED SITES MAY NOT
              BE UNDER OUR CONTROL, AND THE CONTENT AVAILABLE ON THE INTERNET SITES LINKED TO THIS
              WEBSITE MAY NOT NECESSARILY REFLECT OUR OPINION. WE ARE PROVIDING THESE LINKS TO
              OTHER INTERNET SITES AS A CONVENIENCE TO YOU, AND ACCESS TO ANY OTHER INTERNET SITES
              LINKED TO THIS WEBSITE IS AT YOUR OWN RISK. THE INCLUSION OF ANY LINK DOES NOT IMPLY
              THAT WE RECOMMEND OR ENDORSE THE LINKED SITE, OR MAKE ANY REPRESENTATIONS AS TO THE
              VERACITY OR RELIABILITY OF THE INFORMATION OR SERVICES PROVIDED IN THE LINKED SITE.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              S. <span class="text-blue pl-1">Governing Law; General Provisions</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              These Terms shall be construed in accordance with the law of the state of Texas,
              without regard to any conflict of laws rules, and you hereby consent to exclusive
              jurisdiction and venue of any federal or state court located in Waco, McLennan
              County, Texas for any cause of action, claim, liability, demand, or dispute arising
              out of your use of this Website or its Terms. We operate this Website from our
              offices within the United States of America. We make no representation that the
              information in this Website is appropriate or available for use in other locations,
              and access to this Website from territories where the contents of this Website may
              be illegal is prohibited. Those who choose to access this Website from other locations
              do so on their own initiative and are responsible for compliance with applicable
              local laws. No waiver by either party of any breach or default hereunder shall be
              deemed to be a waiver of any preceding or subsequent breach or default. The
              headings used herein are for convenience only and shall not be given any legal
              import.
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row class="my-1">
      <v-col cols="12">
        <h2 class="tfb-title-text-small text-darker-blue text-uppercase">
          J.D. Power Disclaimer:
        </h2>
        Texas Farm Bureau received the highest score in Texas in the J.D. Power 2012-2022 and 2024 U.S. Auto Insurance Studies of customers’ satisfaction with their auto insurance provider. Visit
        <a href="https://jdpower.com/awards"
           hreflang="en-us"
           target="_blank"
           rel="noopener"
           aria-label="jdpower.com/awards (opens in new tab)">jdpower.com/awards</a>
        for more details.
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h2 class="tfb-title-text-small text-darker-blue text-uppercase">
          Conclusion:
        </h2>
        If you have any questions or comments concerning our privacy standards and procedures,
        please write us at {{poBoxAddress}}. Our functional
        regulator is the Texas Department of Insurance. If we cannot resolve your concerns
        or answer your questions, feel free to contact our regulator.
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbMetaMixin from '@/mixins/metaInfo';

  export default {
    name: 'TermsConditions',
    mixins: [tfbDataMixin, tfbMetaMixin],
    computed: {
      metaDescription() {
        return 'With Texas Farm Bureau Insurance, your information is secure. Review our website terms and conditions.';
      },
      metaPageTitle() {
        return 'Terms & Conditions';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/terms-conditions`;
      },
    },
    mounted() {
      this.$store.dispatch('app/setIsLoading', false);
    },
    created() {
      this.$store.dispatch('app/setIsLoading', true);
    },
  };
</script>
