<template>
  <div id="policyholder-login"
       class="background-darker-blue tfb-conversion">
    <v-container fluid
                 class="tfb-conversion-box">
      <span class="tfb-title-text-medium text-uppercase">Convert Your User Id</span>
      <v-row v-if="!showConfirmation">
        <v-col>
          We've made some changes to our online account management system.
          <strong>
            From now on, instead of using your current User ID to log in,
            you'll use your email address.
          </strong><br />
        </v-col>
      </v-row>
      <v-row v-if="!showConfirmation">
        <v-col>
          <h5 class="header-text text-medium">
            Please enter the email address you would like to use for your log in below.
          </h5>
        </v-col>
      </v-row>
      <ValidationObserver ref="obv"
                          v-slot="{ handleSubmit }"
                          v-if="!showConfirmation">
        <v-form @submit.stop.prevent="handleSubmit(onSubmit)">
          <v-row>
            <v-col cols="12">
              <text-input rules="required|email|valid_domain|confirmed:username|min:4|max:60"
                          minlength="4"
                          maxlength="60"
                          label="Email Address"
                          type="email"
                          v-model="userName"></text-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <text-input rules="required|email|min:4|max:60"
                          minlength="4"
                          maxlength="60"
                          label="Confirm Email Address"
                          type="email"
                          v-model="confirmUserName"
                          vid="username"></text-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn block
                         :disabled="formDisabled"
                     type="submit"
                     class="tfb-button background-red">Convert My User Id</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
      <span v-if="showConfirmation"
            class="tfb-title-text-small"><br />Thank You!</span>
      <v-row v-if="showConfirmation">
        <v-col>
          <p>A verification link has been sent to your email address.</p>
          <p>Please follow the instructions within the email message to complete the one-step verification.</p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import { ValidationObserver } from 'vee-validate';
  import tfbMetaInfo from '@/mixins/metaInfo';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'IdConversion',
    mixins: [tfbMetaInfo, tfbHelperMixin],
    components: {
      ValidationObserver,
    },
    data() {
      return {
        userName: null,
        confirmUserName: null,
        showConfirmation: false,
        formDisabled: false,
      };
    },
    computed: {
      metaPageTitle() {
        return 'Id Conversion';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/policyholder/id-conversion`;
      },
      emailAddress() {
        try {
          return this.$store.getters['member/emailAddress'];
        } catch (error) {
          return '';
        }
      },
      idNeedsConvert() {
        try {
          return this.$store.getters['member/conversionIndicator'];
        } catch (error) {
          return false;
        }
      },
    },
    methods: {
      async onSubmit() {
        dispatch('app/setIsLoading', true);
        dispatch('app/clearErrorMessage');
        await dispatch('member/doIdConversion', { sNewUserName: this.userName, sOldUserName: this.$store.state.member.userName, sTfbId: this.trackingId })
          .then((response) => {
            if (response.indexOf('verification request has been received') >= 0) {
              // Redirect where needed
              // this.doRedirect();
              this.showConfirmation = true;
              dispatch('app/setIsLoading', false);
            } else {
              dispatch('app/setErrorMessage', response);
              dispatch('app/setIsLoading', false);
            }
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error.toString());
            dispatch('app/setIsLoading', false);
            dispatch('app/logToULS', {
              logLevel: 'Error',
              logMessage: error,
              fileName: 'Login.vue',
              methodName: 'doLogin-Catch',
              trackerId: this.trackingId,
            });
          });
      },
    },
    async mounted() {
      dispatch('app/logToULS', {
        logLevel: 'Info',
        logMessage: `Id needs conversion? ${this.idNeedsConvert}`,
        fileName: 'IdConversion.vue',
        methodName: 'mounted',
        trackerId: this.trackingId,
      });
      // Check to see if application is available/enabled
      await dispatch('app/doApplicationCheck', { sApplicationName: 'Policyholder', sTfbId: this.trackingId })
        .then((returnedStatus) => {
          // Is there an outage message or not?
          if (returnedStatus === '') {
            // No outage message; is this person logged in?
            if (this.isLoggedIn) {
              // Logged in; is this log in still valid?
              const includedToken = this.$route.query.access_token;
              dispatch('member/validateLogin', { sToken: includedToken, sTfbId: this.trackingId })
                .then((loginValid) => {
                  if (loginValid) {
                    // Is the id still in need of conversion?
                    if (!this.idNeedsConvert) {
                      // Id already converted, redirect to Account Summary
                      this.$router.push({ name: 'policyholderAccountSummary' });
                    }

                    // Prefill with existing email
                    this.userName = this.emailAddress;
                  }
                  dispatch('app/setIsLoading', false);
                })
                .catch((error) => {
                  dispatch('app/setErrorMessage', error.toString());
                  dispatch('app/setIsLoading', false);
                });
            } else {
              // See if this is an SSO session
              const includedToken = this.$route.query.access_token;

              if (includedToken && includedToken !== '') {
                // We have a token, see if we can log in
                dispatch('member/doSsoLogin', { sAccessToken: includedToken, sTfbId: this.trackingId })
                  .then((response) => {
                    dispatch('app/logToULS', {
                      logLevel: 'Info',
                      logMessage: response,
                      fileName: 'IdConversion.vue',
                      methodName: 'doSsoLogin-Success',
                      trackerId: this.trackingId,
                    });

                    if (response === 'invalid_grant') {
                      dispatch('app/setErrorMessage', 'Username/Password Not Found');
                      dispatch('app/setIsLoading', false);
                    }

                    if (response !== '') {
                      dispatch('app/setErrorMessage', response);
                      dispatch('app/setIsLoading', false);
                    } else {
                      // Prefill with existing email
                      this.userName = this.$store.state.member.emailAddress;
                      dispatch('app/setIsLoading', false);
                    }
                  })
                  .catch((error) => {
                    dispatch('app/setErrorMessage', error.toString());
                    dispatch('app/setIsLoading', false);
                    dispatch('app/logToULS', {
                      logLevel: 'Error',
                      logMessage: error,
                      fileName: 'IdConversion.vue',
                      methodName: 'doSsoLogin-Catch',
                      trackerId: this.trackingId,
                    });
                  });
              }
              dispatch('app/setIsLoading', false);
            }
          } else {
            dispatch('app/setErrorMessage', returnedStatus);
            dispatch('app/setIsLoading', false);
          }
        })
        .catch((error) => {
          dispatch('app/setErrorMessage', error.toString());
          dispatch('app/setIsLoading', false);
        });
    },
  };
</script>
<style lang="scss">

</style>
