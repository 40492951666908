<template>
  <div>
    <div class="background-darker-blue py-12">
      <h1 class="tfb-title-text-large text-center">File A Claim</h1>
      <p class="tfb-title-text-smaller text-center">
        Call our 24-hour claims representatives at
        <a :href="claimsReportingCenter.phoneLink"
           class="tfb-title-text-smaller-bold text-white">
          {{ claimsReportingCenter.phoneDisplay }}</a> or contact your Agent.
      </p>
      <v-container class="tfb-smallest-container tfb-claim-box pa-5">
        <required-fields-row></required-fields-row>
        <ValidationObserver ref="obv" v-slot="{ handleSubmit }">
          <v-form @submit.stop.prevent="handleSubmit(onSubmit)">
            <!-- Last Name -->
            <v-row v-if="!isLoggedIn">
              <v-col cols="12"
                     class="py-1">
                <text-input rules="required|min:2|max:25|name"
                            label="Insured Last Name or Business Name"
                            autocapitalize="words"
                            autocomplete="shipping given-name"
                            minlength="2"
                            maxlength="25"
                            v-model="lastName"></text-input>
              </v-col>
            </v-row>
            <v-row>
              <!-- Policy List -->
              <v-col cols="12"
                     class="py-1"
                     v-if="isLoggedIn && policyList">
                <select-input rules="required"
                              label="Policy Number"
                              :items="policyList"
                              item-text="PolicyNumber"
                              item-value="PolicyNumber"
                              v-model="selectPolicyNumber"
                              return-object></select-input>
              </v-col>
              <!-- Policy Number -->
              <v-col cols="12"
                     class="py-1"
                     v-else>
                <text-input rules="required|numeric|min:6|max:10"
                            label="Policy Number"
                            autocapitalize="characters"
                            minlength="6"
                            maxlength="10"
                            v-model="policyNumber"></text-input>
              </v-col>
            </v-row>
            <!-- Date of Loss -->
            <v-row>
              <v-col cols="12"
                     class="py-1">
                <v-menu ref="dateMenu"
                        v-model="dateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px">
                  <template v-slot:activator="{ on }">
                    <ValidationProvider tag="div"
                                        :bails="false"
                                        rules="required|min:6|max:10"
                                        name="Date of Loss"
                                        v-slot="{ errors, valid, required }">
                      <v-text-field outlined
                                    required
                                    readonly
                                    hide-details="auto"
                                    :error-messages="errors"
                                    :success="valid"
                                    v-model="dateOfLossFormatted"
                                    label="Date of Loss"
                                    v-on="on">
                        <template v-slot:append>
                          <v-icon>mdi-calendar</v-icon>
                          <v-icon>mdi-asterisk</v-icon>
                        </template>
                      </v-text-field>
                    </ValidationProvider>
                  </template>
                  <v-date-picker v-model="dateOfLoss"
                                 color="background-blue"
                                 no-title
                                 :min="minDateOfLoss"
                                 :max="maxDateOfLoss"
                                 @input="dateMenu = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <claim-point-link v-if="isLoggedIn && selectPolicyNumber !== null"
                              :showClaimPoint="showClaimPoint"
                              :policyNumber="selectPolicyNumber.PolicyNumber"
                              :insuredName="selectPolicyNumber.PolicyInsuredName"
                              :dateOfLoss="dateOfLoss"></claim-point-link>
            <claim-point-link v-if="!isLoggedIn" :showClaimPoint="showClaimPoint"
                              :policyNumber="policyNumber"
                              :insuredLastName="lastName"
                              :dateOfLoss="dateOfLoss"></claim-point-link>
              <v-row v-if="!isLoggedIn">
                <v-col cols="12"
                       sm="6"
                       offset-sm="6">
                  <span role="alert"
                        v-show="!hasCaptcha"
                        class="text-darkest-red">Please validate you are not a bot.</span>
                  <vue-recaptcha ref="recaptcha"
                                 @verify="onVerify"
                                 @expired="onExpired"
                                 sitekey="6LdNyB8TAAAAAMAzsnuftXFam0KZpOLfO_h8QKya"></vue-recaptcha>
                  <br />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-btn block
                         :disabled="formDisabled"
                         type="submit"
                         class="tfb-button background-red">
                    Start Claim
                  </v-btn>
                </v-col>
              </v-row>
          </v-form>
        </ValidationObserver>
      </v-container>
      <recent-claim :showDialog="dialog"></recent-claim>
    </div>
    <claims-process></claims-process>
    <div class="background-lightest-gray">
      <v-container class="pa-10">
        <v-row>
          <v-col v-if="$vuetify.breakpoint.mdAndUp">
            <v-card class="fill-height tfb-claim-card">
              <v-card-text>
                <faqs faq-keyword="claims"></faqs>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <claims-numbers></claims-numbers>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.smAndDown">
            <v-card class="fill-height">
              <v-card-text>
                <faqs faq-keyword="claims"></faqs>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
  import DOMPurify from 'dompurify';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { subMonths } from 'date-fns';
  import VueRecaptcha from 'vue-recaptcha';
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbHelperMixin from '@/mixins/helpers';
  import tfbMetaMixin from '@/mixins/metaInfo';
  import { namePolicyNotFoundErrorMessage } from '@/mixins/errorMessages';
  import Faqs from '@/views/content/components/FAQs.vue';
  import RequiredFieldsRow from '@/views/layout/components/RequiredFieldsRow.vue';
  import ClaimPointLink from './components/ClaimPointLink.vue';
  import RecentClaim from './components/RecentClaim.vue';
  import ClaimsProcess from './components/ClaimsProcess.vue';
  import ClaimsNumbers from './components/ClaimsNumbers.vue';

  export default {
    name: 'ClaimMain',
    mixins: [tfbDataMixin, tfbHelperMixin, tfbMetaMixin],
    components: {
      ValidationObserver,
      ValidationProvider,
      VueRecaptcha,
      Faqs,
      RecentClaim,
      ClaimsProcess,
      ClaimsNumbers,
      RequiredFieldsRow,
      ClaimPointLink,
    },
    data() {
      return {
        formDisabled: false,
        hasCaptcha: false,
        dialog: false,
        captchaText: '',
        lastName: '',
        policyNumber: '',
        selectPolicyNumber: null,
        txfbInsured: {},
        dateOfLoss: this.parseDate(new Date().toISOString()),
        dateMenu: false,
        showClaimPoint: false,
      };
    },
    computed: {
      metaDescription() {
        return 'Report a claim or view the progress of current claims on the Texas Farm Bureau Insurance resources page.';
      },
      metaPageTitle() {
        return 'Claims';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/claims`;
      },
      dateOfLossFormatted() {
        return this.formatDate(this.dateOfLoss);
      },
      minDateOfLoss() {
        return this.parseDate(subMonths(new Date(), 24).toISOString());
      },
      maxDateOfLoss() {
        return this.parseDate(new Date().toISOString());
      },
      policyList() {
        try {
          return this.$store.getters['policy/accountSummary'];
        } catch (error) {
          return null;
        }
      },
    },
    watch: {
      selectPolicyNumber(val) {
        if (this.$store.getters['app/claimPointEnabled'] === 'Y' && val.PolicyType === 'SM' && val.PolicySubType === '1') {
          this.showClaimPoint = true;
        } else {
          this.showClaimPoint = false;
        }
      },
    },
    methods: {
      onCaptchaSubmit() {
        this.hasCaptcha = true;
        this.$refs.invisibleRecaptcha.execute();
      },
      onVerify(response) {
        this.hasCaptcha = true;
        this.captchaText = response;
        // console.log(`Verify: ${response}`);
      },
      onExpired() {
        this.hasCaptcha = false;
        this.captchaText = '';
        this.$refs.recaptcha.reset();
      },
      onDuplicateClaim(selection) {
        this.dialog = false;
        if (selection) {
          this.$router.push({ name: 'claimsReport', params: { number: this.$store.state.claims.claim.policyNumber, type: this.$store.state.claims.claim.type } });
        }
      },
      onSubmit() {
        this.$store.dispatch('app/clearErrorMessage');
        this.$store.commit('claims/RESET_STATE');
        this.$store.dispatch('claims/clearToken');
        if (!this.isLoggedIn && !this.hasCaptcha && this.captchaText === '') {
          this.onExpired();
        } else {
          this.$store.dispatch('app/setIsLoading', true);
          if (this.isLoggedIn) {
            this.$store.commit('claims/SET_LOSS', {
              date: DOMPurify.sanitize(this.dateOfLoss),
              type: null,
              description: null,
              location: null,
              city: null,
              state: 'TX',
              county: null,
              zip: null,
              policeDepartment: null,
              policeCaseNumber: null,
            });
            this.$store.dispatch('claims/buildPolicy', {
              policyNumber: this.selectPolicyNumber.PolicyNumber,
              policySymbol: (this.selectPolicyNumber.PolicySymbol && this.selectPolicyNumber.PolicySymbol !== null ? this.selectPolicyNumber.PolicySymbol : this.selectPolicyNumber.PolicyType),
              policyType: this.selectPolicyNumber.PolicyType,
              date: DOMPurify.sanitize(this.dateOfLoss),
              claimDestination: null,
              sTfbId: this.trackingId,
            })
              .then(() => {
                this.$store.dispatch('claims/checkClaim')
                  .then((hasExisting) => {
                    if (!hasExisting) {
                      // Continue to full form
                      this.$router.push({ name: 'claimsReport', params: { number: this.selectPolicyNumber.PolicyNumber, type: this.$store.state.claims.claim.type } });
                    } else {
                      this.dialog = true;
                      this.$store.dispatch('app/setIsLoading', false);
                    }
                  });
              })
              .catch((error) => {
                if (error.toString().indexOf('Request failed with status code 401') >= 0) {
                  // Session has timed out, log out and allow the user to enter the claim
                  this.$store.dispatch('member/doLogout')
                    .then(() => {
                      this.$store.dispatch('app/setIsLoading', false);
                    })
                    .catch((accessError) => {
                      this.$store.dispatch('app/setIsLoading', false);
                      this.$store.dispatch('app/setErrorMessage', accessError.toString());
                    });
                }
                this.$store.dispatch('app/setErrorMessage', error.toString());
                this.$store.dispatch('app/setIsLoading', false);
              });
          } else {
            this.$store.dispatch('claims/validateClaim', {
              sLastName: DOMPurify.sanitize(this.lastName),
              sPolicyNumber: DOMPurify.sanitize(this.policyNumber),
              sDateOfLoss: DOMPurify.sanitize(this.dateOfLoss),
              sCaptchaText: this.captchaText,
              sTfbId: this.trackingId,
            })
              .then((validationReturn) => {
                if (validationReturn) {
                  this.$store.dispatch('claims/buildPolicy', validationReturn)
                    .then((builtPolicy) => {
                      if (builtPolicy) {
                        this.$store.dispatch('claims/checkClaim')
                          .then((hasExisting) => {
                            if (!hasExisting) {
                              // Continue to full form
                              this.$router.push({ name: 'claimsReport', params: { number: this.$store.state.claims.claim.policyNumber, type: this.$store.state.claims.claim.type } });
                            } else {
                              this.dialog = true;
                              this.onExpired();
                              this.$store.dispatch('app/setIsLoading', false);
                            }
                          })
                          .catch((error) => {
                            this.$store.dispatch('app/setErrorMessage', error.toString());
                            this.onExpired();
                            this.$store.dispatch('app/setIsLoading', false);
                          });
                      } else {
                        // Display an error that the policy was not found
                        this.$store.dispatch('app/setErrorMessage', namePolicyNotFoundErrorMessage);
                        this.onExpired();
                        this.$store.dispatch('app/setIsLoading', false);
                      }
                    })
                    .catch((error) => {
                      this.$store.dispatch('app/setErrorMessage', error.toString());
                      this.onExpired();
                      this.$store.dispatch('app/setIsLoading', false);
                    });
                } else {
                  // Display an error that the policy was not found
                  this.$store.dispatch('app/setErrorMessage', namePolicyNotFoundErrorMessage);
                  this.onExpired();
                  this.$store.dispatch('app/setIsLoading', false);
                }
              })
              .catch((error) => {
                this.$store.dispatch('app/setErrorMessage', error.toString());
                this.onExpired();
                this.$store.dispatch('app/setIsLoading', false);
              });
          }
        }
      },
    },
    async created() {
      this.$store.dispatch('app/clearErrorMessage');
      this.$store.dispatch('app/setIsLoading', true);
      await this.$store.dispatch('app/doApplicationCheck', { sApplicationName: 'Claims', sTfbId: this.trackingId })
        .then((returnedStatus) => {
          if (returnedStatus !== '') {
            this.formDisabled = true;
            this.$store.dispatch('app/setErrorMessage', returnedStatus);
          }
          this.$store.dispatch('app/setIsLoading', false);
        })
        .catch((error) => {
          this.$store.dispatch('app/setErrorMessage', error.toString());
          this.$store.dispatch('app/setIsLoading', false);
        });
    },
  };
</script>
<style lang="scss">

  .tfb-claim-box {
    background-color: $white;
  }

  .tfb-claim-card {
    max-width: 600px !important;
  margin-left: auto;
  margin-right: auto;
  }
</style>
