<template>
  <v-dialog v-model="email"
            persistent
            max-width="600px">
    <v-card>
      <v-card-title class="background-darker-blue">
        <h3 class="tfb-title-text-small">Email This Quote</h3>
      </v-card-title>
      <ValidationObserver ref="obv" v-slot="{ passes }">
        <v-form ref="emailForm"
                @submit.stop.prevent="passes(onSubmit)">
          <v-card-text>
            <v-container class="py-0 tfb-component-container">
              <v-row class="tfb-notification"
                     role="alert"
                     v-show="errorMessage && errorMessage !== null">
                <v-col cols="12"
                       class="tfb-notification-notice">
                  <span v-html="errorMessage"></span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <text-input rules="required|email|min:3|max:250"
                              autocomplete="home email"
                              minlength="3"
                              maxlength="250"
                              label="Email Address"
                              v-model="sendQuoteEmail"
                              type="email"></text-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="tfb-button background-red"
                   @click="onClose">Close</v-btn>
            <v-btn class="tfb-button background-red"
                   type="submit">Submit</v-btn>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
      <v-overlay absolute
                 :value="saveOverlay">
        <v-progress-circular indeterminate
                             size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </v-dialog>
</template>
<script>
  import { sync } from 'vuex-pathify';
  import { ValidationObserver } from 'vee-validate';
  import tfbHelperMixin from '@/mixins/helpers';
  import { genericErrorMessage } from '@/mixins/errorMessages';
/* eslint object-shorthand: 0 */

  export default {
    name: 'RateQuoteEmail',
    mixins: [tfbHelperMixin],
    components: {
      ValidationObserver,
    },
    props: {
      inputEmail: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        errorMessage: null,
        saveOverlay: false,
        sendQuoteEmail: null,
      };
    },
    computed: {
      agreementNumber() {
        try {
          return this.$store.getters['quotes/agreementNumber'];
        } catch (error) {
          return '';
        }
      },
      subAgreementNumber() {
        try {
          return this.$store.getters['quotes/subAgreementNumber'];
        } catch (error) {
          return '';
        }
      },
      email: sync('quotes/email'),
    },
    watch: {
      inputEmail(val) {
        this.sendQuoteEmail = val;
      },
    },
    methods: {
      onSubmit() {
        this.saveOverlay = true;
        this.errorMessage = null;
        this.requestSubmitted = false;
        this.$store.dispatch('quotes/requestEmail', {
          sAgreementNumber: this.agreementNumber,
          sContactMethod: '',
          sEmailAddress: this.sendQuoteEmail,
          sPhoneNumber: '',
          sPreferredAgent: '',
          sSendCopy: '',
          sSubAgreementNumber: this.subAgreementNumber,
          sTfbId: this.trackingId,
        })
          .then((sendQuoteResponse) => {
            if (sendQuoteResponse.ErrorMessage && sendQuoteResponse.ErrorMessage !== null && sendQuoteResponse.ErrorMessage !== '') {
              this.errorMessage = sendQuoteResponse.ErrorMessage;
            } else {
              this.email = false;
            }
            this.saveOverlay = false;
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            if (error.toString().indexOf('status code 500') >= 0) {
              // General server error
              this.errorMessage = genericErrorMessage;
            } else {
              this.errorMessage = error.toString();
            }
            this.saveOverlay = false;
          });
      },
      onClose() {
        this.sendQuoteEmail = this.inputEmail;
        this.email = false;
      },
    },
    mounted() {
      this.sendQuoteEmail = this.inputEmail;
    },
  };
</script>
<style lang="scss">

</style>
