<template>
  <span v-if="showJdPower"
       class="jd-power-area">
    <div class="jd-power-banner"
         v-if="bannerType === 'image'">
      <router-link :to="{ name: 'termsConditions'}">
        <div class="jd-power-banner-image">
          <picture id="jd-power-banner">
            <source media="(max-width: 960px)"
                    v-bind:srcset="imageRootPath +'jd-power-award.png'">
            <source media="(min-width: 961px)"
                    v-bind:srcset="imageRootPath +'jd-power-award-group.png'">
            <img v-bind:src="imageRootPath +'jd-power-award-group.png'"
                 alt="#1 in Customer Satisfaction Among Auto Insurers in Texas 12 out of 13 years and #1 in Trust">
          </picture>
        </div>
        <div class="jd-power-banner-text">
          <p>
            <span class="jd-power-text-bold">
              #1 in Customer Satisfaction
            </span> Among Auto Insurers in Texas<br/>
            12 out of 13 years and <span class="jd-power-text-bold">#1 in Trust</span>
          </p>
        </div>
      </router-link>
    </div>
    <div class="text-center my-12 jd-power-text"
         v-if="bannerType === 'text'">
      <router-link :to="{ name: 'termsConditions'}">
        <h2 class="tfb-title-text-medium text-blue">
          <span class="jd-power-text-bold">
            #1 in Customer Satisfaction Among Auto Insurers in Texas,
          </span><br/>
          12 out of 13 years by J.D. Power
        </h2>
      </router-link>
    </div>
    <span class="jd-power-footer-image"
          v-if="bannerType === 'footer'">
      <img v-bind:src="`${imageRootPath}jd-power-award.png`" alt="#1 in Customer Satisfaction Among Auto Insurers in Texas 12 out of 13 years and #1 in Trust">
    </span>
    <span class="jd-power-footer-text"
          v-if="bannerType === 'footer'">
      Texas Farm Bureau Insurance Companies.
      <span class="jd-power-text-bold">
        #1 in Customer Satisfaction Among Auto Insurers in Texas 12 out of 13 years and #1 in Trust
      </span>
    </span>
  </span>
</template>
<script>
  export default {
    name: 'JdPowerBanner',
    props: {
      bannerType: {
        type: String,
        required: true,
        default() {
          return 'image';
        },
        validator(value) {
          return [
            'image',
            'text',
            'footer',
          ].indexOf(value) !== -1;
        },
      },
    },
    computed: {
      imageRootPath() {
        return `${process.env.VUE_APP_ROOT_URI}/publishingimages/`;
      },
      showJdPower() {
        if (!this.$store.state.app.showJdPower) {
          this.$store.dispatch('app/setVisitor');
        }
        return this.$store.state.app.showJdPower;
      },
    },
  };
</script>
<style lang="scss">
  .jd-power-area {
    z-index: 998;
  }

  .jd-power-text {
    max-width: 1300px;
    margin: auto;
  }
  .jd-power-banner {
    background-color: $tfb-darker-blue;
    margin-top: 180px;
    align-content: center;
    text-align: center;
    margin-bottom: 40px;
  }

  .jd-power-banner-image img {
    max-width: 1200px;
    margin-top: -150px;
  }

  .jd-power-banner-text {
    margin-top: -250px;
    color: $white;
    font-family: 'BS-light', $body-font-family;
    font-size: 40px;
    width: 100%;
    padding-bottom: 20px;
  }

  .jd-power-banner-text p {
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
  }

  .jd-power-text-bold {
    font-family: 'BS-bold', $body-font-family;
    font-weight: 900;
  }

  .jd-power-banner-text a,
  .jd-power-banner-text a:hover,
  .jd-power-banner-text a:visited {
    color: $white;
  }

  .jd-power-area a:hover {
    text-decoration: none;
  }

  .jd-power-footer-image img {
    max-width: 100px;
    margin-top: -30px;
    z-index: 999;
  }

  .jd-power-footer-text {
    margin-top: -25px;
    color: $white;
    padding: 5px 10px;
    font-size: 20px;
    font-family: 'BS-light', $body-font-family;
    max-width: 600px;
    height: 92px;
  }

  @media (max-width: 1600px) {
    .jd-power-footer-text {
      display: none !important;
    }
  }

  @media (max-width: 960px) {
      .jd-power-banner {
        margin-top: 100px;
      }

      .jd-power-banner-image img {
        margin-top: -60px;
      }

      .jd-power-banner-text {
        margin-top: -5px;
        color: $white;
        padding: 5px 10px;
        font-size: 20px;
      }

        .jd-power-banner-text p {
          max-width: 600px;
        }
  }

  @media (min-width: 960px) {
      .jd-power-footer-image img {
        margin-top: -25px;
        margin-bottom: -72px;
        margin-right: 5px;
        margin-left: 5px;
      }
  }

  @media (min-width: 1264px) {
      .jd-power-footer-image img {
        margin-bottom: -52px;
      }
  }
</style>
