<template>
  <div id="main">
    <div class="carousel-holder">
      <v-carousel cycle
                  hide-delimiters
                  show-arrows-on-hover
                  v-if="featuredPosts.length > 0">
        <v-carousel-item v-for="(featuredPost, i) in featuredPosts"
                         :key="i"
                         :src="featuredPost.headerImage">
          <div class="bottom-holder">
            <div class="blog-container">
              <router-link :to="{ name: 'blogPost', params: { category: kebabify(featuredPost.urlCategory), title: kebabify(featuredPost.url) } }"
                           hreflang="en-us">
                <span class="bottom-title">{{ featuredPost.title }}</span>
              </router-link>
            </div>
          </div>
        </v-carousel-item>
      </v-carousel>
    </div>
    <v-container fluid
                 class="tfb-container event-holder">
      <div v-if="!blogLoading && postRows.length > 0">
        <div v-for="postRow in postRows"
             v-bind:key="postRow.id">
          <v-row v-if="postRow.rowType === 1"
                 class="row-event">
            <v-col cols="12"
                   md="4"
                   v-for="post in postRow.posts"
                   v-bind:key="post.id"
                   class="box">
              <div class="holder">
                <router-link :to="{ name: 'blogPost', params: { category: kebabify(post.urlCategory), title: kebabify(post.url) } }"
                             hreflang="en-us"
                             class="link-stretched">
                </router-link>
                <v-card height="100%">
                  <v-img height="200px"
                         :src="post.headerImage"
                         class="img-box">
                  </v-img>
                  <v-card-subtitle class="pb-0">
                    <router-link class="category-link"
                                 :to="{ name: 'blogFeedCategory', params: { category: kebabify(post.category) } }"
                                 v-if="!post.subcategory || post.subcategory === null || post.subcategory === ''"
                                 hreflang="en-us">
                      {{ post.category }}
                    </router-link>
                    <router-link v-else
                                 class="category-link"
                                 :to="{ name: 'blogFeedSubCategory',
                             params: { category: kebabify(post.category), subcategory: kebabify(post.subcategory) } }"
                                 hreflang="en-us">
                      {{post.subcategory}}
                    </router-link>
                  </v-card-subtitle>
                  <v-card-text class="content-block">
                    <h2 class="title-post">{{ post.title }}</h2>
                    <p>
                      {{ post.blurb }}
                    </p>
                  </v-card-text>
                </v-card>
                <script type="application/ld+json" v-html="post.jsonld">
                </script>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="postRow.rowType === 2 || postRow.rowType === 3"
                 class="row-event">
            <v-col cols="12"
                   md="4"
                   class="box category-list-holder"
                   v-bind:order="postRow.rowType === 2 ? 1 : 2">
              <div class="holder">
                <ul class="category-list">
                  <li v-for="post in postRow.posts.postArray"
                      v-bind:key="post.id">
                    <router-link :to="{ name: 'blogPost',
                         params: { category: kebabify(post.urlCategory),
                         title: kebabify(post.url) } }"
                                 class="link-stretched"
                                 hreflang="en-us">
                    </router-link>
                    <v-img :src="post.headerImage"
                           class="img-post"></v-img>
                    <div class="title-container">
                      <router-link class="category-link"
                                   :to="{ name: 'blogFeedCategory', params: { category: kebabify(post.category) } }"
                                   v-if="!post.subcategory || post.subcategory === null || post.subcategory === ''"
                                   hreflang="en-us">
                        {{ post.category }}
                      </router-link>
                      <router-link v-else
                                   class="category-link"
                                   :to="{ name: 'blogFeedSubCategory',
                             params: { category: kebabify(post.category), subcategory: kebabify(post.subcategory) } }"
                                   hreflang="en-us">
                        {{post.subcategory}}
                      </router-link>
                      <strong class="title-post" style="overflow-wrap: break-word;">{{ post.title }}</strong>
                    </div>
                    <script type="application/ld+json" v-html="post.jsonld">
                    </script>
                  </li>
                </ul>
              </div>
            </v-col>
            <v-col cols="12"
                   md="8"
                   class="box big-box"
                   v-bind:order="postRow.rowType === 2 ? 2 : 1">
              <div class="holder"
                   v-if="postRow.posts && postRow.posts.singlePost">
                <router-link :to="{ name: 'blogPost',
                         params: { category: kebabify(postRow.posts.singlePost.urlCategory),
                         title: kebabify(postRow.posts.singlePost.url) } }"
                             class="link-stretched"
                             hreflang="en-us">
                </router-link>
                <v-card height="100%">
                  <v-img height="200px"
                         :src="postRow.posts.singlePost.headerImage"
                         class="img-box">
                  </v-img>
                  <v-card-subtitle class="pb-0">
                    <router-link class="category-link"
                                 :to="{ name: 'blogFeedCategory', params: { category: kebabify(postRow.posts.singlePost.category) } }"
                                 v-if="!postRow.posts.singlePost.subcategory || postRow.posts.singlePost.subcategory === null || postRow.posts.singlePost.subcategory === ''"
                                 hreflang="en-us">
                      {{ postRow.posts.singlePost.category }}
                    </router-link>
                    <router-link v-else
                                 class="category-link"
                                 :to="{ name: 'blogFeedSubCategory',
                             params: { category: kebabify(postRow.posts.singlePost.category), subcategory: kebabify(postRow.posts.singlePost.subcategory) } }"
                                 hreflang="en-us">
                        {{postRow.posts.singlePost.subcategory}}
                    </router-link>
                  </v-card-subtitle>
                  <v-card-text class="content-block">
                    <h2 class="title-post">{{ postRow.posts.singlePost.title }}</h2>
                    <p>
                      {{ postRow.posts.singlePost.blurb }}
                    </p>
                  </v-card-text>
                </v-card>
                <script type="application/ld+json" v-html="postRow.posts.singlePost.jsonld">
                </script>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-row v-if="!blogLoading && postRows.length > 0 && !moreDisabled">
        <v-col cols="12"
               class="align-center">
          <v-btn block
                 @click="loadMorePosts()"
                 :loading="blogMoreLoading"
                 class="tfb-button-min background-red btn-load">Load More</v-btn>
        </v-col>
      </v-row>
      <v-row v-if="!blogLoading && (!postRows || postRows.length === 0)">
        <v-col cols="12">
          No blog posts found
        </v-col>
      </v-row>
      <v-row v-if="blogLoading">
        <v-col cols="12"
               md="4"
               v-for="i in 3"
               :key="i">
          <v-skeleton-loader class="mx-auto"
                             max-width="300"
                             type="card"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import { startCase } from 'lodash';
  import tfbHelperMixin from '@/mixins/helpers';
  import tfbMetaMixin from '@/mixins/metaInfo';

  export default {
    name: 'BlogMain',
    mixins: [tfbHelperMixin, tfbMetaMixin],
    data() {
      return {
        blogLoading: true,
        blogMoreLoading: true,
        moreDisabled: false,
        featuredPosts: [],
      };
    },
    computed: {
      metaDescription() {
        if (this.$route.params.subcategory) {
          return `${startCase(this.$route.params.subcategory)} | ${startCase(this.$route.params.category)} | Texas Heritage for Living`;
        }

        if (this.$route.params.category) {
          return `${startCase(this.$route.params.category)} | Texas Heritage for Living`;
        }

        return 'From delicious recipes to home and gardening tips, the Texas Farm Bureau Insurance blog has everything you need to know in order to live life like a true Texan.';
      },
      metaPageTitle() {
        if (this.$route.params.subcategory) {
          return `${startCase(this.$route.params.subcategory)} | ${startCase(this.$route.params.category)} | Texas Heritage for Living`;
        }

        if (this.$route.params.category) {
          return `${startCase(this.$route.params.category)} | Texas Heritage for Living`;
        }

        return 'Texas Heritage for Living';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/blog`;
      },
      postRows() {
        try {
          return this.$store.getters['blog/postRows'];
        } catch (error) {
          return [];
        }
      },
    },
    methods: {
      getFeaturedPosts() {
        // Get carousel posts
        this.$store.dispatch('blog/getFeaturedPosts', { sTop: this.carouselSize, sTfbId: this.trackingId })
          .then((foundPosts) => {
            this.featuredPosts = foundPosts;
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
      loadBlogPosts() {
        this.blogLoading = true;
        if (this.$route.params.subcategory) {
          this.$store.dispatch('blog/getFeedPostsByCategory', { sCategory: startCase(this.$route.params.subcategory), sTfbId: this.trackingId })
            .then((foundPosts) => {
              this.handlePostResponse(foundPosts);
            })
            .catch((error) => {
              this.$store.dispatch('app/setErrorMessage', error.toString());
              this.blogLoading = false;
            });
        } else if (this.$route.params.category) {
          this.$store.dispatch('blog/getFeedPostsByCategory', { sCategory: startCase(this.$route.params.category), sTfbId: this.trackingId })
            .then((foundPosts) => {
              this.handlePostResponse(foundPosts);
            })
            .catch((error) => {
              this.$store.dispatch('app/setErrorMessage', error.toString());
              this.blogLoading = false;
            });
        } else {
          this.$store.dispatch('blog/getFeedPosts', { sTop: 16, sTfbId: this.trackingId })
            .then((foundPosts) => {
              this.getFeaturedPosts();
              this.handlePostResponse(foundPosts);
            })
            .catch((error) => {
              this.$store.dispatch('app/setErrorMessage', error.toString());
              this.blogLoading = false;
            });
        }
      },
      loadMorePosts() {
        this.blogMoreLoading = true;
        this.$store.dispatch('blog/getNextFeedPosts', { sTfbId: this.trackingId })
          .then((foundPosts) => {
            this.handlePostResponse(foundPosts);
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
            this.blogMoreLoading = false;
          });
      },
      handlePostResponse(response) {
        this.blogLoading = false;
        this.blogMoreLoading = false;
        if (!response) {
          this.moreDisabled = true;
        }
      },
      resetBlogFeed() {
        this.$store.commit('blog/RESET_STATE');
        this.featuredPosts = [];

        this.$store.dispatch('app/doApplicationCheck', { sApplicationName: 'Blog', sTfbId: this.trackingId })
          .then((returnedStatus) => {
            if (returnedStatus === '') {
              this.loadBlogPosts();
            } else {
              this.blogLoading = false;
              this.$store.dispatch('app/setErrorMessage', returnedStatus);
              this.$store.dispatch('app/setIsLoading', false);
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error);
            this.blogLoading = false;
          });
      },
    },
    watch: {
      $route: 'resetBlogFeed',
    },
    created() {
      this.$store.dispatch('app/clearErrorMessage');
      this.resetBlogFeed();
    },
  };
</script>
<style lang="scss">


  #main {
    background-color: $tfb-lightest-gray !important;
  }

  .slide .v-image .v-image__image {
    background-position-y: top !important;
  }

  .img-post .v-responsive__sizer {
    padding-bottom: 100% !important;
  }
</style>
