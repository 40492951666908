<template>
  <v-container fluid
               class="tfb-smaller-container pb-10">
    <v-row class="my-1">
      <v-col cols="12"
             class="align-center">
        <h1 class="tfb-title-text-medium text-darker-blue">Required Notices</h1>
      </v-col>
    </v-row>
    <v-row class="my-1">
      <v-col cols="12">
        <p>UMR creates and publishes the Machine-Readable Files on behalf of Texas Farm Bureau.</p>
        <p>To link to the Machine-Readable Files, please click on the URL provided: <a href="https://transparency-in-coverage.uhc.com/"
                                                                                       hreflang="en-us"
                                                                                       target="_blank"
                                                                                       rel="noopener"
                                                                                       aria-label="transparency-in-coverage.uhc.com (opens in new tab)">transparency-in-coverage.uhc.com</a></p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import tfbMetaMixin from '@/mixins/metaInfo';

  export default {
    name: 'RequiredNotices',
    mixins: [tfbMetaMixin],
    computed: {
      metaDescription() {
        return 'UMR creates and publishes the Machine-Readable Files on behalf of Texas Farm Bureau.';
      },
      metaPageTitle() {
        return 'Required Notices';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/required-notices`;
      },
    },
    created() {
      this.$store.dispatch('app/setIsLoading', true);
    },
    mounted() {
      this.$store.dispatch('app/setIsLoading', false);
    },
  };
</script>
<style lang="scss">

</style>
