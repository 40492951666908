const genericErrorMessage = 'We apologize. The system you are accessing is temporarily unavailable. Please try again later.';
const namePolicyNotFoundErrorMessage = 'Unable to locate information for the given Last Name and Policy Number. Please check the entered information and try again.';
const insuredAccessOnlyErrorMessage = 'Sorry, this functionality is only available to the insured.';
const sessionErrorMessage = 'Sorry, this session has expired. Please log in again.';
const collisionDeductibleRentalErrorMessage = 'Collision deductible is required for Rental Reimbursement';
const comprehensiveDeductibleRentalErrorMessage = 'Comprehensive deductible is required for Rental Reimbursement';
const invalidRegistrationIdMessage = 'We&rsquo;re sorry, but the link provided is no longer valid. Please enter registration information below or contact your agent for a new registration link.';

export {
  genericErrorMessage, namePolicyNotFoundErrorMessage, insuredAccessOnlyErrorMessage, sessionErrorMessage,
  collisionDeductibleRentalErrorMessage, comprehensiveDeductibleRentalErrorMessage, invalidRegistrationIdMessage,
};
