<template>
  <v-container fluid
               class="pa-0"
               id="agent-listing">
    <v-row dense
           no-gutters>
      <v-col cols="12"
             lg="4">
        <v-container fluid
                     class="pa-0"
                     id="agent-listing-detail">
          <v-row no-gutters
                 class="find-agents-gray px-5">
            <v-col align-self="start">
              <h1 class="tfb-title-text-small text-uppercase">
                <router-link :to="{ name: 'countyListing' }"
                             class="find-agents-listing-link">Find</router-link> an
                <router-link :to="{ name: 'agentListing' }"
                             class="find-agents-listing-link">Agent</router-link>
              </h1>
            </v-col>
          </v-row>
          <v-form @submit.stop.prevent="onSubmit">
            <v-row no-gutters
                   class="find-agents-gray px-5">
              <v-col cols="5"
                     align-self="center">
                <v-text-field id="agent-search-zip"
                              v-model="searchAgentZipCode"
                              type="number"
                              dense
                              filled
                              background-color="white"
                              placeholder="ZIP CODE"
                              @change="clearCounty">
                </v-text-field>
              </v-col>
              <v-col cols="2"
                     align-self="center"
                     class="text-center">
                <span class="text-uppercase">
                  or
                </span>
              </v-col>
              <v-col cols="5"
                     align-self="center">
                <v-select dense
                          filled
                          background-color="white"
                          placeholder="COUNTY"
                          id="search-agent-county"
                          name="search-agent-county"
                          @change="clearZipCode"
                          :items="countyOptions"
                          v-model="searchAgentCounty"
                          item-text="_countyName"
                          item-value="_countyName">
                </v-select>
              </v-col>
            </v-row>
            <v-row no-gutters
                   class="find-agents-gray px-5">
              <v-col>
                <v-btn block
                       :disabled="formDisabled"
                       class="tfb-button background-red"
                       type="submit">
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-row no-gutters
                 class="find-agents-listing-gray px-3">
            <v-col>
              <div class="mb-2">
                <span v-if="agents.length > 0">Displaying {{agents.length}} results from {{searchValue}}</span>
                <span v-if="!agentLoading && agents.length <= 0 && searchValue !== null && searchValue !== ''">
                  Unable to locate agents in {{searchValue}}.
                </span>
                <span v-if="!agentLoading && (searchValue === null || searchValue === '')">
                  Enter your ZIP code or select your county to locate agents in your area.
                </span>
              </div>
              <v-skeleton-loader :loading="agentLoading"
                                 type="list-item-avatar-three-line@3"
                                 class="mx-auto">
                <agent-cards :agent-listing="agents"></agent-cards>
              </v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="12"
             md="8">
        <agent-map :markers="markers"
               :info-window-open="true"></agent-map>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  /* eslint no-underscore-dangle: 0 */
  import _ from 'lodash';
  import {
    defaultMarkerIcon, secondaryMarkerIcon, defaultMarker,
  } from '@/mixins/googleSettings';
  import tfbHelperMixin from '@/mixins/helpers';
  import tfbMetaMixin from '@/mixins/metaInfo';
  import AgentCards from './components/AgentCards.vue';
  import AgentMap from './components/AgentMap.vue';

  export default {
    name: 'FindAnAgent',
    mixins: [tfbMetaMixin, tfbHelperMixin],
    data() {
      return {
        formDisabled: false,
        markers: [],
        searchAgentZipCode: '',
        searchAgentCounty: '',
        searchValue: '',
        countyOptions: [],
        agentLoading: false,
        agents: [],
      };
    },
    components: {
      AgentCards,
      AgentMap,
    },
    computed: {
      metaPageTitle() {
        return 'Find an Agent';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/county/lookup`;
      },
    },
    mounted() {
      try {
        this.$store.dispatch('app/clearErrorMessage');
        this.$store.dispatch('app/setIsLoading', true);
        this.getCountyList();
        this.$store.dispatch('location/setLocation').then(() => {
          if (this.$store.state.location.latitude && this.$store.state.location.latitude !== null && this.$store.state.location.latitude !== '') {
            this.markers.push({
              infoText: 'Your Current Location',
              position: {
                lat: Number(this.$store.state.location.latitude),
                lng: Number(this.$store.state.location.longitude),
              },
              icon: secondaryMarkerIcon,
            });
          }

          if (this.$store.state.location.countyName && this.$store.state.location.countyName !== null && this.$store.state.location.countyName !== '') {
            this.searchAgentCounty = this.$store.state.location.countyName;
            this.onSubmit();
          } else if (this.$store.state.location.zipCode && this.$store.state.location.zipCode !== null && this.$store.state.location.zipCode !== '') {
            this.searchAgentZipCode = this.$store.state.location.zipCode;
            this.onSubmit();
          }
        });
        this.$store.dispatch('app/setIsLoading', false);
      } catch (e) {
        this.$store.dispatch('app/logToULS', {
          logLevel: 'Error',
          logMessage: e,
          fileName: 'FindAnAgent.vue',
          methodName: 'mounted-Catch',
          trackerId: this.trackingId,
        });
        this.$store.dispatch('app/setIsLoading', false);
      }
    },
    methods: {
      clearZipCode() {
        this.searchAgentZipCode = '';
      },
      clearCounty() {
        this.searchAgentCounty = '';
      },
      getCountyList() {
        this.$store.dispatch('agentcounty/getCountyList')
          .then((response) => {
            if (response && response.data && response.data._counties) {
              this.countyOptions = response.data._counties;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      },
      onSubmit() {
        // Reset the error display
        this.$store.dispatch('app/clearErrorMessage');

        // Disable the search button
        this.formDisabled = true;

        // Set the agent listing loading indicator
        this.agentLoading = true;

        // Reset the Google Map markers
        this.markers = [];

        // Empty the agent listing
        this.agents = [];

        // Was a zip code entered? Was a county selected?
        if (this.searchAgentZipCode !== '') {
          // Searching by zip code
          this.searchAgentCounty = '';
          this.searchValue = this.searchAgentZipCode;

          // Get the county associated with this zip code
          this.$store.dispatch('location/getAddress', { sAddress: this.searchAgentZipCode })
            .then((response) => {
              if (response.data.results && response.data.results.length > 0) {
                // eslint-disable-next-line prefer-destructuring
                const location = response.data.results[0].geometry.location;
                this.markers.push({
                  infoText: 'Your Current Location',
                  position: {
                    lat: Number(location.lat),
                    lng: Number(location.lng),
                  },
                  icon: secondaryMarkerIcon,
                });
                this.fetchOfficesByLatLong(location.lat, location.lng, this.searchAgentZipCode);
              } else {
                this.markers.push(defaultMarker);
                this.agentLoading = false;
                this.formDisabled = false;
              }
            })
            .catch((error) => {
              this.$store.dispatch('app/setErrorMessage', error.toString());
              this.agentLoading = false;
              this.formDisabled = false;
            });
        } else if (this.searchAgentCounty !== '') {
          // Searching by county
          this.searchAgentZipCode = '';
          this.searchValue = `${this.searchAgentCounty} County`;

          this.$store.dispatch('location/getAddress', { sAddress: `${this.searchAgentCounty} County Texas` })
            .then((response) => {
              if (response.data.results && response.data.results.length > 0) {
                // eslint-disable-next-line prefer-destructuring
                const location = response.data.results[0].geometry.location;
                this.markers.push({
                  infoText: 'Your Current Location',
                  position: {
                    lat: Number(location.lat),
                    lng: Number(location.lng),
                  },
                  icon: secondaryMarkerIcon,
                });
              } else {
                this.markers.push(defaultMarker);
              }

              // Get the agent listing for this county
              this.fetchAgentsByCounty(this.searchAgentCounty);
            })
            .catch((error) => {
              this.$store.dispatch('app/setErrorMessage', error.toString());
              this.agentLoading = false;
              this.formDisabled = false;
            });
        } else {
          // Nothing selected, show error
          this.$store.dispatch('app/setErrorMessage', 'Please enter a ZIP code or select a county');
          this.agentLoading = false;
          this.formDisabled = false;
        }
      },
      fetchAgentsByCounty(countyName) {
        // Get the offices in this county
        this.$store.dispatch('agentcounty/getOfficesByCounty', { sCountyName: countyName, sBranchCode: null, sTfbId: this.trackingId })
          .then((response) => {
            if (response && response.length > 0) {
              // Empty the agent listing again
              this.agents = [];

              // Loop through the returned offices
              for (let c = 0; c < response.length; c += 1) {
                const countyOffice = response[c];

                // Add the county office to the map
                this.markers.push({
                  infoText: countyOffice.officeMapInfoBox,
                  position: {
                    lat: Number(countyOffice.latitude),
                    lng: Number(countyOffice.longitude),
                  },
                  icon: defaultMarkerIcon,
                });

                // Find all the agents in the office
                this.fetchAgentsByCountyBranch(countyOffice.name, countyOffice.branchCode, countyOffice.distance);
              }
            } else if (response && response.data && response.data._errorMessage) {
              // Error message was returned
              this.$store.dispatch('app/setErrorMessage', response.data._errorMessage);
              // Set the agent listing loading indicator
              this.agentLoading = false;
              this.formDisabled = false;
            } else {
              // No offices were found
              // Set the agent listing loading indicator
              this.agentLoading = false;
              this.formDisabled = false;
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
            // Set the agent listing loading indicator
            this.agentLoading = false;
            this.formDisabled = false;
          });
      },
      fetchOfficesByLatLong(latitude, longitude) {
        // Get the offices in this county
        this.$store.dispatch('agentcounty/getOfficesByLatLong', {
          sLatitude: latitude, sLongitude: longitude, sRadius: '25', sTfbId: this.trackingId,
        })
          .then((response) => {
            if (response && response.length > 0) {
              // Empty the agent listing again
              this.agents = [];

              // Loop through the returned offices
              for (let c = 0; c < response.length; c += 1) {
                const countyOffice = response[c];

                // Add the county office to the map
                this.markers.push({
                  infoText: countyOffice.officeMapInfoBox,
                  position: {
                    lat: Number(countyOffice.latitude),
                    lng: Number(countyOffice.longitude),
                  },
                  icon: defaultMarkerIcon,
                });

                // Find all the agents in the office
                this.fetchAgentsByCountyBranch(countyOffice.name, countyOffice.branchCode, countyOffice.distance);
              }
            } else {
              // No offices were found, try bumping up the search radius
              this.$store.dispatch('agentcounty/getOfficesByLatLong', {
                sLatitude: latitude, sLongitude: longitude, sRadius: '50', sTfbId: this.trackingId,
              })
                .then((officeResponse) => {
                  if (officeResponse && officeResponse.length > 0) {
                    // Empty the agent listing again
                    this.agents = [];

                    // Loop through the returned offices
                    for (let c = 0; c < officeResponse.length; c += 1) {
                      const countyOffice = officeResponse[c];

                      // Add the county office to the map
                      this.markers.push({
                        infoText: countyOffice.officeMapInfoBox,
                        position: {
                          lat: Number(countyOffice.latitude),
                          lng: Number(countyOffice.longitude),
                        },
                        icon: defaultMarkerIcon,
                      });

                      // Find all the agents in the office
                      this.fetchAgentsByCountyBranch(countyOffice.name, countyOffice.branchCode, countyOffice.distance);
                    }
                  } else {
                    // No offices were found
                    // Set the agent listing loading indicator
                    this.agentLoading = false;
                    this.formDisabled = false;
                  }
                })
                .catch((error) => {
                  this.$store.dispatch('app/setErrorMessage', error.toString());
                  // Set the agent listing loading indicator
                  this.agentLoading = false;
                  this.formDisabled = false;
                });
              // Set the agent listing loading indicator
              this.agentLoading = false;
              this.formDisabled = false;
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
            // Set the agent listing loading indicator
            this.agentLoading = false;
            this.formDisabled = false;
          });
      },
      fetchAgentsByCountyBranch(countyName, branchCode, distance) {
        // Find all the agents in the office
        this.$store.dispatch('agentcounty/getAgentsByOffice', { sCountyName: countyName, sBranchCode: branchCode, sTfbId: this.trackingId })
          .then((agentResponse) => {
            if (agentResponse && agentResponse.data && agentResponse.data._agents) {
              // Loop through the returned agents
              for (let a = 0; a < agentResponse.data._agents.length; a += 1) {
                const agent = agentResponse.data._agents[a];
                // Get the agent details
                this.$store.dispatch('agentcounty/getAgentDetails', { sAgentCode: agent._agentCode, sDistance: distance, sTfbId: this.trackingId })
                  .then((detailResponse) => {
                    if (detailResponse) {
                      // Add the agent to the listing
                      this.agents.push(detailResponse);
                    }

                    this.agents = _.uniqBy(this.agents, 'code');
                    this.agents = _.orderBy(this.agents, ['distance'], ['asc']);
                  })
                  .catch((error) => {
                    this.$store.dispatch('app/setErrorMessage', error.toString());
                    // Set the agent listing loading indicator
                    this.agentLoading = false;
                    this.formDisabled = false;
                  });
              }
            }

            this.agents = _.uniqBy(this.agents, 'code');
            this.agents = _.orderBy(this.agents, ['distance'], ['asc']);

            // Set the agent listing loading indicator
            this.agentLoading = false;
            this.formDisabled = false;
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
            // Set the agent listing loading indicator
            this.agentLoading = false;
            this.formDisabled = false;
          });
      },
    },
  };
</script>
<style lang="scss">

  .find-agents-gray {
    background-color: $tfb-gray !important;
    color: $white;
    font-size: small;
    vertical-align: middle;
    padding: 5px;
  }

  .find-agents-listing-gray {
    background-color: $tfb-lightest-gray !important;
    color: $black;
    font-size: .8em;
    vertical-align: middle;
    padding: 5px;
    height: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  a.find-agents-listing-link,
  a:hover.find-agents-listing-link,
  a:active.find-agents-listing-link,
  a:visited.find-agents-listing-link {
    color: $white !important;
    text-decoration: none !important;
  }
</style>
