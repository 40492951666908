<template>
  <div>
    <!-- Driver Information -->
    <v-row no-gutters>
      <v-col cols="12"
             class="py-1">
        <h3 class="tfb-title-text-small text-blue text-uppercase">
          Drivers
        </h3>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12"
             class="py-1">
        <h4 class="tfb-title-text-smaller font-weight-bold text-uppercase">
          Active Drivers
        </h4>
      </v-col>
    </v-row>
    <div v-if="coveredDrivers && coveredDrivers.length > 0"
         class="pt-3 pb-5 px-1">
      <v-row v-for="driver in coveredDrivers"
             v-bind:key="driver.DriversLicenseNumber">
        <v-col cols="12"
               sm="4"
               class="py-0">
          <label class="font-weight-bold">{{driver.FullName}}</label>
        </v-col>
        <v-col cols="12"
               sm="4"
               class="py-0">
          <label class="font-weight-bold">Year of Birth:</label> {{ driver.YearOfBirth }}
        </v-col>
        <v-col cols="12"
               sm="4"
               class="py-0">
          <label class="font-weight-bold">Drivers License:</label> {{driver.MaskedDriversLicenseNumber}}
        </v-col>
        <v-col cols="12"
               class="d-block d-md-none"><hr /></v-col>
      </v-row>
    </div>
    <div v-else
         class="pt-3 pb-5 px-1">
      <v-row>
        <v-col class="py-0">
          No Active Drivers Found
        </v-col>
      </v-row>
    </div>
    <v-row no-gutters>
      <v-col cols="12"
             class="py-1">
        <h4 class="tfb-title-text-smaller font-weight-bold text-uppercase">
          Excluded Drivers
        </h4>
      </v-col>
    </v-row>
    <div v-if="excludedDrivers && excludedDrivers.length > 0"
         class="pt-3 pb-5 px-1">
      <v-row v-for="driver in excludedDrivers"
             v-bind:key="driver.id">
        <v-col class="py-0">
          {{driver.FullName}}
        </v-col>
      </v-row>
    </div>
    <div v-else
         class="pt-3 pb-5 px-1">
      <v-row>
        <v-col class="py-0">
          No Excluded Drivers Found
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'PolicyDetailsDrivers',
    props: {
      coveredDrivers: {
        type: Array,
        required: true,
      },
      excludedDrivers: {
        type: Array,
        required: true,
      },
    },
  };
</script>
<style lang="scss">

</style>
