<template>
  <div id="policyholder-forgot-password"
       class="background-darker-blue tfbins-login">
    <v-container class="tfbins-login-box">
      <h1 class="tfb-title-text-medium text-uppercase">Forgot Password</h1>
      <div v-if="!submitted">
        <ValidationObserver ref="obv" v-slot="{ handleSubmit }">
          <v-form @submit.stop.prevent="handleSubmit(onSubmit)">
            <v-row>
              <v-col cols="12">
                <text-input rules="required|min:4|max:60|all_allowed"
                            minlength="4"
                            maxlength="60"
                            label="Username/Email Address"
                            autocomplete="username"
                            v-model="userName"></text-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn block
                       :disabled="formDisabled"
                       type="submit"
                       class="tfb-button background-red">Reset Password</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </ValidationObserver>
      </div>
      <v-row v-else>
        <v-col cols="12">
          An email has been sent to the requested account with further information. If you do not receive an email,
          please confirm you have entered the same email address used during account registration.
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <router-link :to="{ name: 'policyholderRegister'}"
                       hreflang="en-us">Register</router-link> |
          <router-link :to="{ name: 'policyholderLogin'}"
                       hreflang="en-us">Login</router-link>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import { ValidationObserver } from 'vee-validate';
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbMetaMixin from '@/mixins/metaInfo';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'PolicyHolderLogin',
    mixins: [tfbDataMixin, tfbMetaMixin, tfbHelperMixin],
    components: {
      ValidationObserver,
    },
    data() {
      return {
        userName: '',
        submitted: false,
        formDisabled: false,
      };
    },
    computed: {
      metaPageTitle() {
        return 'Forgot Password | PolicyHolder';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/policyholder/forgot-password`;
      },
    },
    async mounted() {
      this.$store.dispatch('app/setIsLoading', true);
      this.$store.dispatch('app/clearErrorMessage');
      await this.$store.dispatch('app/doApplicationCheck', { sApplicationName: 'Policyholder', sTfbId: this.trackingId })
        .then((returnedStatus) => {
          if (returnedStatus === '') {
            if (this.isLoggedIn) {
              // Redirect where needed
              this.doRedirect();
            }
          } else {
            this.$store.dispatch('app/setErrorMessage', returnedStatus);
            this.formDisabled = true;
          }
          this.$store.dispatch('app/setIsLoading', false);
        })
        .catch((error) => {
          this.$store.dispatch('app/setErrorMessage', error.toString());
          this.$store.dispatch('app/setIsLoading', false);
        });
    },
    methods: {
      onSubmit() {
        this.$store.dispatch('app/setIsLoading', true);
        this.$store.dispatch('app/clearErrorMessage');
        this.$store.dispatch('member/doForgotPassword', { sUserName: this.userName, sTfbId: this.trackingId })
          .then((response) => {
            if (response && (response.indexOf('We have sent your new password') >= 0
              || response.indexOf('Password request successfully') >= 0
              || response.indexOf('Your password request has been received') >= 0)) {
              this.submitted = true;
            } else {
              this.$store.dispatch('app/setErrorMessage', 'Unable to locate an account with that email address. Please try again or register for an account.');
            }
            this.$store.dispatch('app/setIsLoading', false);
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
            this.$store.dispatch('app/setIsLoading', false);
          });
      },
    },
  };
</script>
<style lang="scss">

  .tfbins-login {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .tfbins-login-box {
    background: $white;
    padding: 20px 50px;
    max-width: 600px;
  }
</style>
