<template>
  <v-container fluid
               class="tfb-container pb-10">
    <v-row class="my-1">
      <v-col>
        <h1 class="tfb-title-text-medium text-darker-blue text-center">Payment Options</h1>
      </v-col>
    </v-row>
    <v-row v-if="paymentWordingDisplay === 'Y'">
      <v-col>
        <strong>
          Making a payment via your checking or savings account? You can now save your account data in our new Wallet feature!<br /><br />
          When adding your checking or savings account information, click the check box at the bottom, &ldquo;Add to Wallet&rdquo;,
          click &ldquo;SAVE&rdquo; and your account information will be saved for future use! Note: This feature is not available
          when paying via the Guest Payment Site.
        </strong>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="drop-shadow fill-height mx-lg-10 mx-md-6 pa-6 pa-lg-10">
          <v-card-title><h2 class="tfb-title-text-small font-weight-bold text-darker-blue">Pay Online via My TFBI Account</h2></v-card-title>
          <v-card-text>
            Log in to your account and make a one-time payment on your policy via
            your checking or savings account with no added processing fees! You
            can also set-up automatic payments with AssurancePay&reg; for no added
            service fees!
          </v-card-text>
          <v-card-actions class="tfb-card-footer text-center">
            <v-btn :to="{ name: 'policyholderLogin' }"
                   :disabled="formDisabled"
                   hreflang="en-us"
                   class="tfb-button-min background-red">Log In</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="drop-shadow fill-height mx-lg-10 mx-md-6 pa-6 pa-lg-10">
          <v-card-title><h2 class="tfb-title-text-small font-weight-bold text-darker-blue">Pay Online via Guest Payment Site</h2></v-card-title>
          <v-card-text>
            Use this option to make a one-time credit, debit, or checking/savings account
            payment on your policy without logging in. Processing fees ($3.95) and other
            restrictions will apply.
          </v-card-text>
          <v-card-actions class="tfb-card-footer text-center">
            <v-btn :href="nonRegisteredPaymentUrl"
                   :disabled="formDisabled"
                   hreflang="en-us"
                   target="_blank"
                   class="tfb-button-min background-red">Guest Payment Site</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2 class="tfb-title-text-small tfb-title-text-bold text-darker-blue">
          Other Ways to Pay
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3 class="tfb-title-text-small text-darker-blue">By Mail:</h3>
        <p>
          Check or money-order policy payments should be mailed to our Dallas
          payment center with your billing statement remittance stub:
        </p>
        <blockquote>
          <address id="paymentCenterAddress">
            <span class="font-weight-bold">{{billingAddress.name}}</span><br />
            {{billingAddress.addressLineOne}}<br />
            {{billingAddress.addressLineTwo}}<br />
          </address>
        </blockquote>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3 class="tfb-title-text-small text-darker-blue">By Phone:</h3>
        <p>
          Call
          <a v-bind:title="ivrPhone.display"
             id="ivrPhone"
             v-bind:href="ivrPhone.link">
            {{ivrPhone.display}}
          </a> to make your policy payment 24 hours a day/7 days a week.
          Processing fees ($3.95) will apply.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3 class="tfb-title-text-small text-darker-blue">
          By Automatic Payment &mdash; AssurancePay<sup>&reg;</sup>:
        </h3>
        <p>
          Save time, postage, and service fees! Schedule automatic recurring payments from
          your checking or savings account. Log in and click on the
          &ldquo;Schedule Recurring Payments&rdquo; link on your Account Summary page next to your
          policy. NO service or processing fees; not available on all policy lines.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3 class="tfb-title-text-small text-darker-blue">In Person:</h3>
        <p>
          Stop by your local
          <router-link :to="{ name: 'countyLookup'}"
                       hreflang="en-us">
            Texas Farm Bureau County Office
          </router-link>
          to make a check or money order payment with no processing fees.
          You may also make an online credit, debit, or checking/savings account payment.
          Processing fees ($3.95) will apply.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="fill-height">
          <v-card-text>
            <faqs faq-keyword="payments"></faqs>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbMetaMixin from '@/mixins/metaInfo';
  import Faqs from '@/views/content/components/FAQs.vue';

  export default {
    name: 'PaymentOptions',
    mixins: [tfbDataMixin, tfbMetaMixin],
    components: {
      Faqs,
    },
    data() {
      return {
        formDisabled: false,
      };
    },
    computed: {
      metaPageTitle() {
        return 'Payment Options';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/payments/options`;
      },
      nonRegisteredPaymentUrl() {
        try {
          return this.$store.getters['app/nonRegisteredPaymentUrl'];
        } catch (error) {
          return '';
        }
      },
      paymentWordingDisplay() {
        try {
          return this.$store.getters['app/paymentWordingDisplay'];
        } catch (error) {
          return 'N';
        }
      },
    },
    mounted() {
      this.$store.dispatch('app/clearErrorMessage');
      this.$store.dispatch('app/setIsLoading', true);
      this.$store.dispatch('app/doApplicationCheck', { sApplicationName: 'Payments', sTfbId: this.trackingId })
        .then((returnedStatus) => {
          if (returnedStatus !== '') {
            this.formDisabled = true;
            this.$store.dispatch('app/setErrorMessage', returnedStatus);
          }
          this.$store.dispatch('app/setIsLoading', false);
        })
        .catch(() => {
          this.$store.dispatch('app/setIsLoading', false);
        });
    },
    methods: {
      onLoginSubmit() {
        this.$router.push({ name: 'policyholderLogin' });
      },
      getPaymentUrl() {
        this.$store.dispatch('app/getConfiguration', { sSection: 'ExternalUrl', sTitle: 'NonRegisteredPaymentsEncrypted' })
          .then((urlResponse) => {
            if (urlResponse && urlResponse !== '') {
              this.nonRegisteredPaymentUrl = urlResponse;
            } else {
              this.$store.dispatch('app/setErrorMessage', 'Unable to fetch guest payment site url');
            }
            this.$store.dispatch('app/setIsLoading', false);
          })
          .catch((error) => {
            this.$store.dispatch('app/logToULS', {
              logLevel: 'Error',
              logMessage: error,
              fileName: 'PaymentOptions.vue',
              methodName: 'getPaymentUrl-Catch',
              trackerId: this.trackingId,
            });
            this.$store.dispatch('app/setErrorMessage', 'Unable to fetch guest payment site url');
            this.$store.dispatch('app/setIsLoading', false);
          });
      },
    },
  };
</script>
