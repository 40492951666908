<template>
  <v-menu offset-y
          :close-on-content-click="false"
          v-if="showLink">
    <template v-slot:activator="{ on }">
      <!-- I don't know why, but items in here have to be in reverse order -->
      <br />
      <v-icon class="mdi-18px">mdi-open-in-new</v-icon>&nbsp;
      <a v-on="on"
         class="make-payment-link">
        Make a Payment</a>
    </template>
    <v-list dense
            class="make-payment-menu">
      <v-list-item three-line
                   class="make-payment-menu-item">
        <a :href="loggedInLink"
           class="make-payment-item-link"
           target="_blank"
           title="Pay via Checking or Savings Account"
           v-on:click="policyPayment(loggedInLink)">Pay via Checking or Savings Account</a>
        <p class="make-payment-item-text">No processing fees (via My TFBI Account)</p>
      </v-list-item>
      <v-list-item three-line
                   class="make-payment-menu-item">
        <a :href="nonLoggedInLink"
           class="make-payment-item-link"
           target="_blank"
           title="Pay via Credit, Debit, Checking or Savings Account"
           v-on:click="policyPayment(nonLoggedInLink)">Pay via Credit, Debit, Checking or Savings Account</a>
        <p class="make-payment-item-text">Processing fees ($3.95) and other restrictions will apply (via guest payment site)</p>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
  export default {
    name: 'PaymentMenu',
    props: {
      showLink: {
        type: Boolean,
        required: true,
      },
      nonLoggedInLink: {
        type: String,
        required: true,
      },
      loggedInLink: {
        type: String,
        required: true,
      },
      paymentTracking: {
        type: String,
        required: true,
      },
    },
    methods: {
      policyPayment(link) {
        this.$store.dispatch('app/logToULS', {
          logLevel: 'Info',
          logMessage: `Going to: ${link}`,
          fileName: 'LinkList.vue',
          methodName: 'policyPayment-Start',
          trackerId: this.paymentTracking,
        });
      },
    },
  };
</script>
<style lang="scss">
  .make-payment-link {
    text-decoration: none;
    color: #008ce1;
  }

  .make-payment-menu {
    max-width: 400px !important;
  }

  .make-payment-menu-item {
    -webkit-align-items: center;
    align-items: center;
    -webkit-align-self: stretch;
    align-self: stretch;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex: 1 1;
    flex: 1 1;
    overflow: hidden;
    padding: 8px 16px;
    min-height: 10px !important;
  }

    .make-payment-menu-item > :not(:last-child) {
      margin-bottom: 2px;
    }

  .make-payment-item-link {
    font-size: .8125rem;
    font-weight: 500;
    line-height: 1rem;
    text-overflow: unset !important;
    white-space: normal !important;
    overflow: visible !important;
    flex: 1 1 100%;
    align-self: center;
  }

  .make-payment-item-text {
    font-size: .8125rem;
    font-weight: 500;
    line-height: 1rem;
    text-overflow: unset !important;
    white-space: normal !important;
    overflow: visible !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    flex: 1 1 100%;
    color: rgba(0,0,0,.6);
  }
</style>
