<template>
  <v-dialog v-model="contact"
            persistent
            max-width="600px">
    <v-card>
      <v-card-title class="background-darker-blue">
        <h3 class="tfb-title-text-small">Talk to an Agent</h3>
      </v-card-title>
      <ValidationObserver ref="obv" v-slot="{ passes }">
        <v-form ref="contactForm"
                @submit.stop.prevent="passes(onSubmit)">
          <v-card-text>
            <v-container v-if="!requestSubmitted"
                         class="py-0 tfb-component-container">
              <v-row class="tfb-notification"
                     role="alert"
                     v-show="errorMessage && errorMessage !== null">
                <v-col cols="12"
                       class="tfb-notification-notice">
                  <span v-html="errorMessage"></span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"
                       class="py-0">
                  <h4 class="tfb-title-text-smaller font-weight-bold text-blue">
                    So that we can better serve you at your convenience, please let us know below how you would like to be contacted.
                  </h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"
                       class="py-0">
                  <ValidationProvider tag="div"
                                      :bails="false"
                                      rules="required"
                                      name="contact method"
                                      v-slot="{ errors, valid, required }">
                    <v-radio-group v-model="contactType"
                                   class="no-margin-radio"
                                   hide-details="auto"
                                   :error-messages="errors"
                                   :success="valid">
                      <v-radio v-for="option in contactOptions"
                               :key="option.Id"
                               :label="option.DisplayText"
                               v-bind:value="option.Value">
                      </v-radio>
                      <template v-slot:append>
                        <v-icon>mdi-asterisk</v-icon>
                      </template>
                    </v-radio-group>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row v-if="contactType === 'EMAIL'">
                <v-col cols="12">
                  <text-input rules="required|email|min:3|max:250"
                              autocomplete="home email"
                              minlength="3"
                              maxlength="250"
                              label="Email Address"
                              v-model="contactEmail"
                              type="email"></text-input>
                </v-col>
              </v-row>
              <v-row v-if="contactType === 'EMAIL'">
                <v-col cols="12"
                       class="py-0">
                  <v-checkbox v-model="copyEmail"
                              hide-details="auto"
                              type="checkbox">
                    <template v-slot:label>
                      <span>
                        Send a copy to your email address?
                      </span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row v-if="contactType === 'CALL'">
                <v-col cols="12">
                  <text-input rules="required|phone_number|min:10|max:12"
                              autocomplete="home tel"
                              minlength="10"
                              maxlength="12"
                              label="Phone Number"
                              v-model="contactPhone"
                              v-mask="'###-###-####'"
                              type="tel"></text-input>
                </v-col>
              </v-row>
              <v-row v-if="contactType === 'CALL'">
                <v-col cols="12">
                  <select-input rules="required"
                                label="Best Time to Call"
                                :items="contactTimeOptions"
                                item-text="DisplayText"
                                item-value="Value"
                                v-model="contactTime"></select-input>
                </v-col>
              </v-row>
              <v-row v-if="contactType !== null">
                <v-col cols="12">
                  <text-input rules="min:2|max:250"
                              minlength="2"
                              maxlength="250"
                              label="Preferred Agent Name"
                              hint="If you already have a preferred agent, enter the name here."
                              v-model="requestedAgent"
                              autocapitalize="words"></text-input>
                </v-col>
              </v-row>
            </v-container>
            <v-container v-if="requestSubmitted"
                         class="py-0">
              <v-row>
                <v-col cols="12">
                  <h3>Thank you! We have received your request. An agent will contact you shortly.</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  You requested:
                  <span v-if="contactType === 'CALL'">
                    Have an agent call me, between {{contactTime}} ({{contactPhone}})<br />
                  </span>
                  <span v-if="contactType === 'EMAIL'">
                    Have an agent email me ({{contactEmail}})<br />
                  </span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="tfb-button background-red"
                   @click="onClose">Close</v-btn>
            <v-btn v-if="!requestSubmitted"
                   class="tfb-button background-red"
                   type="submit">Submit</v-btn>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
      <v-overlay absolute
                 :value="saveOverlay">
        <v-progress-circular indeterminate
                             size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </v-dialog>
</template>
<script>
  import { sync } from 'vuex-pathify';
  import { mask } from 'vue-the-mask';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import tfbHelperMixin from '@/mixins/helpers';
  import { genericErrorMessage } from '@/mixins/errorMessages';
/* eslint object-shorthand: 0 */

  export default {
    name: 'RateQuoteContact',
    mixins: [tfbHelperMixin],
    directives: { mask },
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    props: {
      inputEmail: {
        type: String,
        required: true,
      },
      inputPhone: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        errorMessage: null,
        saveOverlay: false,
        requestSubmitted: false,
        contactOptions: [{ Id: 0, DisplayText: 'Email Me', Value: 'EMAIL' }, { Id: 1, DisplayText: 'Call Me', Value: 'CALL' }],
        contactTimeOptions: [
          { Id: 0, DisplayText: '7 am - 9 am', Value: '7a-9a' },
          { Id: 1, DisplayText: '9 am - 12 pm', Value: '9a-12p' },
          { Id: 2, DisplayText: '12 pm - 3 pm', Value: '12p-3p' },
          { Id: 3, DisplayText: '3 pm - 5 pm', Value: '3p-5p' },
          { Id: 4, DisplayText: '5 pm - 7 pm', Value: '5p-7p' },
          { Id: 5, DisplayText: '7 pm - 9 pm', Value: '7p-9p' },
        ],
        contactType: null,
        contactTime: null,
        contactPhone: null,
        contactEmail: null,
        copyEmail: false,
        requestedAgent: null,
      };
    },
    computed: {
      agreementNumber() {
        try {
          return this.$store.getters['quotes/agreementNumber'];
        } catch (error) {
          return '';
        }
      },
      subAgreementNumber() {
        try {
          return this.$store.getters['quotes/subAgreementNumber'];
        } catch (error) {
          return '';
        }
      },
      contact: sync('quotes/contact'),
    },
    watch: {
      inputEmail(val) {
        this.contactEmail = val;
      },
    },
    methods: {
      onSubmit() {
        this.saveOverlay = true;
        this.errorMessage = null;
        this.requestSubmitted = false;
        this.$store.dispatch('quotes/requestContact', {
          sAgreementNumber: this.agreementNumber,
          sContactMethod: this.contactTime === null ? this.contactType : this.contactTime,
          sEmailAddress: this.contactEmail,
          sPhoneNumber: this.contactPhone,
          sPreferredAgent: this.requestedAgent,
          sSendCopy: this.copyEmail,
          sSubAgreementNumber: this.subAgreementNumber,
          sTfbId: this.trackingId,
        })
          .then((contactResponse) => {
            if (contactResponse.ErrorMessage && contactResponse.ErrorMessage !== null && contactResponse.ErrorMessage !== '') {
              this.errorMessage = contactResponse.ErrorMessage;
            } else {
              this.requestSubmitted = true;
            }
            this.saveOverlay = false;
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            if (error.toString().indexOf('status code 500') >= 0) {
              // General server error
              this.errorMessage = genericErrorMessage;
            } else {
              this.errorMessage = error.toString();
            }
            this.saveOverlay = false;
          });
      },
      onClose() {
        this.contactPhone = this.inputPhone;
        this.contactTime = null;
        this.copyEmail = false;
        this.contactEmail = this.inputEmail;
        this.requestedAgent = null;
        this.contactType = null;
        this.requestSubmitted = false;
        this.contact = false;
      },
    },
    mounted() {
      this.contactPhone = this.inputPhone;
      this.contactEmail = this.inputEmail;
    },
  };
</script>
<style lang="scss">

</style>
