<template>
  <div id="main">
    <v-container fluid
                 class="tfb-container event-holder"
                 v-if="blogLoading || (!blogLoading && blogPosts.length > 0)">
      <v-row class="my-6">
        <v-col cols="12"
               sm="6"
               class="align-center text-darker-blue">
          <router-link :to="{ name: 'blogListing' }"
                       hreflang="en-us"
                       class="blog-listing-link">
            <h2 class="tfb-title-text-medium">Blog</h2>
          </router-link>
          <span class="tfb-title-text">From gardening to weathering storms, improving your home to exploring unknown roads, caring for your family to cooking for your friends.</span>
        </v-col>
      </v-row>
      <v-row v-if="!blogLoading && blogPosts.length > 0"
             class="row-event">
        <v-col cols="12"
               md="4"
               v-for="post in blogPosts"
               v-bind:key="post.id"
               class="box">
          <div class="holder">
            <router-link :to="{ name: 'blogPost', params: { category: kebabify(post.urlCategory), title: kebabify(post.url) } }"
                         :title="post.title"
                         hreflang="en-us"
                         class="link-stretched">
            </router-link>
            <v-card height="100%">
              <v-img height="200px"
                     :src="post.headerImage"
                     class="img-box">
              </v-img>
              <v-card-subtitle class="pb-0">
                <router-link class="category-link"
                             hreflang="en-us"
                             v-if="!post.subcategory || post.subcategory === null || post.subcategory === ''"
                             :to="{ name: 'blogFeedCategory', params: { category: kebabify(post.category) } }">
                  {{ post.category }}
                </router-link>
                <router-link v-else
                             class="category-link"
                             hreflang="en-us"
                             :to="{ name: 'blogFeedSubCategory',
                             params: { category: kebabify(post.category), subcategory: kebabify(post.subcategory) } }">
                  {{post.subcategory}}
                </router-link>
              </v-card-subtitle>
              <v-card-text class="content-block">
                <h2 class="title-post">{{ post.title }}</h2>
                <p>
                  {{ post.blurb }}
                </p>
              </v-card-text>
            </v-card>
          </div>
          <script type="application/ld+json" v-html="post.jsonld"></script>
        </v-col>
      </v-row>
      <v-row v-if="!blogLoading && blogPosts.length > 0">
        <v-col cols="12"
               sm="6"
               class="align-center">
          <v-btn :to="{ name: 'blog'}"
                 class="tfb-button-min background-red">Read More Articles</v-btn>
        </v-col>
      </v-row>
      <v-row v-if="blogLoading">
        <v-col cols="12"
               md="4"
               v-for="i in 3"
               :key="i">
          <v-skeleton-loader class="mx-auto"
                             max-width="300"
                             type="card"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'BlogFeed',
    mixins: [tfbHelperMixin],
    data() {
      return {
        blogLoading: true,
        blogPosts: [],
      };
    },
    methods: {
      loadBlogPosts() {
        this.$store.dispatch('app/doApplicationCheck', { sApplicationName: 'Blog', sTfbId: this.trackingId })
          .then((returnedStatus) => {
            if (returnedStatus === '') {
              this.blogLoading = true;
              this.$store.dispatch('blog/getPostListing', { sTop: 6, sTfbId: this.trackingId })
                .then((foundPosts) => {
                  this.blogLoading = false;
                  this.blogPosts = foundPosts;
                })
                .catch(() => {
                  this.blogLoading = false;
                });
            } else {
              this.blogLoading = false;
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error);
            this.blogLoading = false;
          });
      },
    },
    created() {
      this.loadBlogPosts();
    },
  };
</script>
<style lang="scss">

  #main {
    background-color: $tfb-lightest-gray !important;
  }

  .slide .v-image .v-image__image {
    background-position-y: top !important;
  }

  .img-post .v-responsive__sizer {
    padding-bottom: 100% !important;
  }

  a.blog-listing-link,
  a:hover.blog-listing-link,
  a:active.blog-listing-link,
  a:visited.blog-listing-link {
    color: $tfb-darker-blue !important;
    text-decoration: none !important;
  }
</style>
