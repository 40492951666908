<template>
  <div>
    <div class="background-darker-blue py-12">
      <h1 class="tfb-title-text-large text-center">Auto Quote</h1>
      <h2 class="tfb-title-text-smaller text-center">
        Let&rsquo;s see if we can save you some money.
      </h2>
      <v-container class="tfb-smallest-container tfb-quote-box pa-5 my-8">
        <v-row>
          <v-col cols="12"
                 class="py-1">
            <h3 class="tfb-title-text-small-bold text-darkest-blue">I&rsquo;d like an auto quote.</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12"
                 class="py-1">
            <p>
              Information from outside sources such as driving records, prior insurance, claim history, and credit history may be used in calculating this quote.
              A third party may be used to calculate your insurance score. Information you provide will be shared with outside parties that perform these services on our behalf.
              We value your privacy. Please review our <router-link :to="{ name: 'privacy'}"
                                                                    hreflang="en-us">Privacy Notice</router-link>.
            </p>
            <p>By clicking &ldquo;start your quote&rdquo; and proceeding with this quote, you authorize us to obtain consumer reports for each individual you list in the quote.</p>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-col cols="12"
                 sm="6"
                 class="float-right">
            <span role="alert"
                  v-show="!hasCaptcha"
                  class="text-darkest-red">Please validate you are not a bot.</span>
            <vue-recaptcha ref="recaptcha"
                           @verify="onVerify"
                           @expired="onExpired"
                           :sitekey="googleReCaptchaKey"></vue-recaptcha>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="12"
                 sm="6">
            <v-btn block
                   @click="onCreate"
                   :disabled="formDisabled"
                   class="tfb-button background-red float-right">
              Start your quote
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="tfb-smallest-container tfb-quote-box pa-5 mb-10">
        <v-row>
          <v-col cols="12"
                 class="py-1">
            <h3 class="tfb-title-text-small-bold text-darkest-blue">Already have a quote started?</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12"
                 class="py-1">
            <p>
              Retrieve your saved quote by entering your email address and the password you created when you started your estimate.
            </p>
          </v-col>
        </v-row>
        <ValidationObserver ref="obv" v-slot="{ passes }">
          <v-form @submit.stop.prevent="passes(onLogin)">
            <v-row>
              <v-col cols="12"
                     class="py-1">
                <text-input rules="required|email|valid_domain|min:3|max:250"
                            autocomplete="home email"
                            minlength="3"
                            maxlength="250"
                            label="Email Address"
                            v-model="emailAddress"
                            type="email"></text-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                     class="py-1">
                <text-input rules="required|min:4|max:25"
                            minlength="4"
                            maxlength="25"
                            label="Password"
                            v-model="passWord"
                            type="password"></text-input>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12"
                     sm="6">
                <v-btn block
                       :disabled="formDisabled"
                       type="submit"
                       class="tfb-button background-red">
                  Continue your quote
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </ValidationObserver>
      </v-container>
    </div>
    <div class="background-lightest-gray">
      <!--<quote-process></quote-process>-->
      <v-container class="pa-10">
        <v-row>
          <v-col>
            <v-card class="fill-height tfb-claim-card">
              <v-card-text>
                <faqs faq-keyword="quote"></faqs>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card class="fill-height tfb-claim-card">
              <v-card-text class="event-holder row-event box category-list-holder">
                <p class="tfb-title-text-smaller font-weight-bold text-uppercase text-blue">
                  Our Services
                </p>
                <div class="holder">
                  <ul class="category-list">
                    <li v-for="post in blogPosts"
                        v-bind:key="post.id">
                      <router-link :to="{ name: 'blogPost',
                         params: { category: kebabify(post.urlCategory),
                         title: kebabify(post.url) } }"
                                   class="link-stretched"
                                   hreflang="en-us">
                      </router-link>
                      <v-img :src="post.headerImage"
                             class="img-post"></v-img>
                      <div class="title-container">
                        <router-link class="category-link"
                                     :to="{ name: 'blogFeedCategory', params: { category: kebabify(post.category) } }"
                                     v-if="!post.subcategory || post.subcategory === null || post.subcategory === ''"
                                     hreflang="en-us">
                          {{ post.category }}
                        </router-link>
                        <router-link v-else
                                     class="category-link"
                                     :to="{ name: 'blogFeedSubCategory',
                             params: { category: kebabify(post.category), subcategory: kebabify(post.subcategory) } }"
                                     hreflang="en-us">
                          {{post.subcategory}}
                        </router-link>
                        <strong class="title-post" style="overflow-wrap: break-word;">{{ post.title }}</strong>
                      </div>
                      <script type="application/ld+json" v-html="post.jsonld">
                      </script>
                    </li>
                  </ul>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <meet-agents></meet-agents>
    </div>
  </div>
</template>
<script>
  import DOMPurify from 'dompurify';
  import { ValidationObserver } from 'vee-validate';
  import VueRecaptcha from 'vue-recaptcha';
  import Faqs from '@/views/content/components/FAQs.vue';
  import MeetAgents from '@/views/agents/components/MeetAgents.vue';
  // import QuoteProcess from './components/QuoteProcess.vue';
  import tfbHelperMixin from '@/mixins/helpers';
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbMetaMixin from '@/mixins/metaInfo';
  import { genericErrorMessage } from '@/mixins/errorMessages';

  export default {
    name: 'AutoQuoteEntry',
    mixins: [tfbHelperMixin, tfbDataMixin, tfbMetaMixin],
    components: {
      VueRecaptcha,
      ValidationObserver,
      // QuoteProcess,
      Faqs,
      MeetAgents,
    },
    data() {
      return {
        formDisabled: false,
        hasCaptcha: true,
        dialog: false,
        captchaText: '',
        emailAddress: '',
        passWord: '',
        blogLoading: true,
        blogPosts: [],
      };
    },
    computed: {
      metaDescription() {
        return 'Looking for an auto insurance quote? Use our online tool to learn how Texas Farm Bureau Insurance can help provide coverage for your precious ride.';
      },
      metaPageTitle() {
        return 'Find an Auto Quote';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/quotes/auto`;
      },
      googleReCaptchaKey() {
        return process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY;
      },
      storedZipCode() {
        return this.$store.state.location.zipCode;
      },
      isTimedOut() {
        try {
          return this.$store.getters['quotes/timeout'];
        } catch (error) {
          return false;
        }
      },
    },
    methods: {
      loadBlogPosts() {
        this.blogLoading = true;
        this.blogPosts = [];
        this.fetchKeywordPosts()
          .then((foundPosts) => {
            this.blogPosts = foundPosts;
            if (this.blogPosts.length < 4) {
              this.fetchAdditionalPosts();
            }
            this.blogLoading = false;
          });
      },
      fetchKeywordPosts() {
        return new Promise((resolve) => {
          this.$store.dispatch('blog/getPostsByTags', {
            sKeywords: ['quote', 'rate', 'auto'], sTop: 4, sTfbId: this.trackingId,
          })
            .then((foundPosts) => {
              resolve(foundPosts);
            })
            .catch(() => {
              // Do nothing
              resolve([]);
            });
        });
      },
      fetchAdditionalPosts() {
        return new Promise((resolve) => {
          if (this.blogPosts.length < 4) {
            this.$store.dispatch('blog/getPostsByTag', {
              sKeyword: 'insurance', sTop: 4, sTfbId: this.trackingId,
            })
              .then((foundPosts) => {
                for (let f = 0; f < foundPosts.length; f += 1) {
                  if (this.blogPosts.length < 4) {
                    this.blogPosts.push(foundPosts[f]);
                  }
                }
              })
              .catch(() => {
                // Do nothing
              });
          }
          resolve();
        });
      },
      onCaptchaSubmit() {
        this.hasCaptcha = true;
        this.$refs.invisibleRecaptcha.execute();
      },
      onVerify(response) {
        this.hasCaptcha = true;
        this.captchaText = response;
      },
      onExpired() {
        this.hasCaptcha = false;
        this.captchaText = '';
        this.$refs.recaptcha.reset();
      },
      onLogin() {
        this.$store.dispatch('app/clearErrorMessage');
        this.$store.commit('quotes/SET_TIMEOUT', false);
        this.$store.dispatch('quotes/clearToken');
        this.$store.dispatch('app/setIsLoading', true);
        this.$store.dispatch('quotes/getExistingQuote', {
          sEmailAddress: DOMPurify.sanitize(this.emailAddress),
          sPassword: DOMPurify.sanitize(this.passWord),
          sTfbId: this.trackingId,
        })
          .then((quoteReturn) => {
            if (quoteReturn) {
              this.$router.push({ name: 'quoteAboutYou' });
            } else {
              this.$store.dispatch('app/setErrorMessage', genericErrorMessage);
              this.$store.dispatch('app/setIsLoading', false);
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
            this.$store.dispatch('app/setIsLoading', false);
          });
      },
      onCreate() {
        this.$store.dispatch('app/clearErrorMessage');
        this.$store.commit('quotes/SET_TIMEOUT', false);
        this.$store.dispatch('quotes/clearToken');
        this.$store.dispatch('app/setIsLoading', true);

        if (!this.hasCaptcha || this.captchaText === '') {
          this.onExpired();
          this.$store.dispatch('app/setIsLoading', false);
          return;
        }

        this.$store.dispatch('quotes/requestToken', {
          sQuoteToken: this.captchaText,
          sTfbId: this.trackingId,
        })
          .then(() => {
            const location = { Address2: '', State: 'TX', ZipCode: '' };
            if (this.storedZipCode) {
              location.ZipCode = this.storedZipCode;
            }

            this.$store.dispatch('quotes/setMaxFutureEffectiveDayLimit');
            this.$store.dispatch('quotes/setMaxPastEffectiveDayLimit');

            this.$store.commit('quotes/SET_QUOTE', {
              DeathIndemnity: [],
              GaragingLocations: [location],
              Operators: [{ DriverSequenceNumber: '1', RelationshipToInsuredCode: '1', DateOfBirth: '' }],
              Quote: {
                AgreementNumber: '',
                EffectiveDate: this.parseDate(new Date().toISOString()),
                OwnershipCode: 'I',
                SelectedCoverageLevel: '',
                SubAgreementNumber: '',
                TrackingId: this.trackingId,
              },
              Vehicles: [],
            });
            this.$router.push({ name: 'quoteAboutYou' });
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
            this.$store.dispatch('app/setIsLoading', false);
          });
      },
    },
    mounted() {
      this.$store.dispatch('app/clearErrorMessage');
      this.$store.dispatch('app/setIsLoading', true);
      this.$store.dispatch('quotes/clearToken');
      this.$store.commit('quotes/CLEAR_QUOTE');
      this.$store.commit('quotes/RESET_STATE');
      this.loadBlogPosts();
      this.$store.dispatch('app/doApplicationCheck', { sApplicationName: 'RateQuote', sTfbId: this.trackingId })
        .then((returnedStatus) => {
          if (returnedStatus !== '') {
            this.formDisabled = true;
            this.$store.dispatch('app/setErrorMessage', returnedStatus);
          } else if (this.isTimedOut) {
            this.$store.dispatch('app/setErrorMessage', 'Your session has timed out. Please log in into your quote to continue or start a new one.');
          }
          this.$store.dispatch('location/setLocation');
          this.$store.dispatch('app/setIsLoading', false);
        })
        .catch((error) => {
          this.$store.dispatch('app/setErrorMessage', error.toString());
          this.$store.dispatch('app/setIsLoading', false);
        });
    },
  };
</script>
<style lang="scss">

  .tfb-quote-box {
    background-color: $white;
    color: $black;
  }
</style>
