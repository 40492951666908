<template>
  <div class="background-darker-blue py-12">
    <h2 class="tfb-title-text-large text-center">Vehicles</h2>
    <v-container fluid
                 class="tfb-smallest-container tfb-quote-box pa-5 my-8">
      <v-row v-if="vehicleList && vehicleList.length > 1 && vehicle && vehicle != null">
        <v-col cols="12">
          <v-btn @click="onDelete()"
                 :disabled="formDisabled"
                 class="tfb-button-min background-red">
            Remove Vehicle
          </v-btn>
        </v-col>
      </v-row>
      <required-fields-row></required-fields-row>
      <ValidationObserver ref="obv" v-slot="{ passes }">
        <v-form ref="vehicleForm"
                v-if="vehicle && vehicle != null"
                @submit.stop.prevent="passes(onSubmit)">
          <!-- VIN -->
          <v-row v-if="enterByVin">
            <v-col cols="12">
              <keep-alive>
                <ValidationProvider tag="div"
                                    :bails="false"
                                    rules="required|min:17|max:17|alpha_num|vin_valid"
                                    mode="lazy"
                                    name="vin"
                                    vid="vin"
                                    v-slot="{ errors, valid, required }">
                  <v-text-field outlined
                                hide-details="auto"
                                v-model="vehicle.VIN"
                                :error-messages="errors"
                                :success="valid"
                                label="VIN"
                                autocapitalize="characters"
                                minlength="17"
                                maxlength="17">
                    <template v-slot:append>
                      <v-icon>mdi-asterisk</v-icon>
                    </template>
                  </v-text-field>
                </ValidationProvider>
              </keep-alive>
            </v-col>
          </v-row>
          <!-- Vehicle Year -->
          <v-row v-if="!enterByVin">
            <v-col cols="12">
              <select-input rules="required"
                            label="Vehicle Year"
                            :items="vehicleYears"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="vehicle.Year"
                            @change="getVehicleMakes()"
                            :disabled="yearSelectionDisabled"></select-input>
            </v-col>
          </v-row>
          <!-- Vehicle Make -->
          <v-row v-if="!enterByVin">
            <v-col cols="12">
              <select-input rules="required"
                            label="Vehicle Make"
                            :items="vehicleMakes"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="vehicle.Make"
                            @change="getVehicleModels()"
                            :disabled="makeSelectionDisabled"></select-input>
            </v-col>
          </v-row>
          <!-- Vehicle Model -->
          <v-row v-if="!enterByVin">
            <v-col cols="12">
              <select-input rules="required"
                            label="Vehicle Model"
                            :items="vehicleModels"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="vehicle.Model"
                            @change="getVehicleBodyStyles()"
                            :disabled="modelSelectionDisabled"></select-input>
            </v-col>
          </v-row>
          <!-- Vehicle Body Style -->
          <v-row v-if="!enterByVin">
            <v-col cols="12">
              <select-input rules="required"
                            label="Vehicle Body Style"
                            :items="vehicleBodyStyles"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="vehicle.VehicleBodyStyle"
                            @change="setVehicleDetail()"
                            :disabled="styleSelectionDisabled"></select-input>
            </v-col>
          </v-row>
          <!-- Vehicle Entry Swap -->
          <v-row>
            <v-col cols="12">
              <a href=""
                 v-on:click.prevent="swapEntry()">
                <span v-if="enterByVin">Enter vehicle year, make, and model</span>
                <span v-else>Enter VIN for vehicle information</span>
              </a><br />
            </v-col>
          </v-row>
          <!-- Owner of this vehicle -->
          <v-row>
            <v-col cols="12">
              <select-input rules="required"
                            label="Owner of this Vehicle"
                            :items="ownerList"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="vehicle.TitledTo"></select-input>
            </v-col>
          </v-row>
          <!-- Primary Driver of this vehicle -->
          <v-row>
            <v-col cols="12">
              <select-input rules="required"
                            label="Primary Driver of this Vehicle"
                            :items="operatorList"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="vehicle.PrincipalOperator"></select-input>
            </v-col>
          </v-row>
          <!-- Primary use of this vehicle - 14081 -->
          <v-row>
            <v-col cols="12">
              <select-input rules="required"
                            label="Primary Use of this Vehicle"
                            :items="primaryUseOptions"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="vehicle.PrimaryVehicleUse"
                            :disabled="primaryUseDisabled"
                            vid="primaryuse"></select-input>
            </v-col>
          </v-row>
          <!-- Days driven to work/school -->
          <v-row v-if="isUsedForWorkSchool">
            <v-col cols="12">
              <select-input rules="required|numeric"
                            label="Days Driven to Work/School"
                            :items="daysPerWeekOptions"
                            v-model="vehicle.DaysPerWeekDriven"></select-input>
            </v-col>
          </v-row>
          <!-- Garaged On Farm - 14099 -->
          <v-row v-if="isUsedForFarmRanch">
            <v-col cols="12">
              <select-input rules="required"
                            label="Is this vehicle garaged on a farm?"
                            :items="yesNoOptions"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="vehicle.GaragedOnFarm"></select-input>
            </v-col>
          </v-row>
          <!-- Number of Owned Acres -->
          <v-row v-if="isUsedForFarmRanch">
            <v-col cols="12">
              <text-input rules="required|numeric|min:1|max:7"
                          minlength="1"
                          maxlength="7"
                          label="Number of Owned Acres"
                          v-model="vehicle.NumberOfOwnedAcres"
                          type="number"></text-input>
            </v-col>
          </v-row>
          <!-- Number of Rented Acres -->
          <v-row v-if="isUsedForFarmRanch">
            <v-col cols="12">
              <text-input rules="required|numeric|min:1|max:7"
                          minlength="1"
                          maxlength="7"
                          label="Number of Rented Acres"
                          v-model="vehicle.NumberOfRentedAcres"
                          type="number"></text-input>
            </v-col>
          </v-row>
          <!-- Annual Mileage -->
          <v-row>
            <v-col cols="12">
              <text-input rules="required|numeric|min:1|max:7"
                          minlength="1"
                          maxlength="7"
                          label="Annual Mileage"
                          v-model="vehicle.AnnualMileage"
                          type="number"></text-input>
            </v-col>
          </v-row>
          <!-- Daily Mileage -->
          <v-row>
            <v-col cols="12">
              <text-input rules="required|numeric|min:1|max:7"
                          minlength="1"
                          maxlength="7"
                          label="Miles Driven Per Day"
                          v-model="vehicle.DailyMileage"
                          type="number"></text-input>
            </v-col>
          </v-row>
          <!-- Garaging location of this vehicle -->
          <v-row>
            <v-col cols="12">
              <select-input rules="required"
                            label="Location Where Vehicle is Kept Overnight"
                            :items="garagingLocations"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="vehicle.GaragingLocationSequenceNumber"
                            vid="garaging"></select-input>
            </v-col>
          </v-row>
          <!-- Address Line One -->
          <v-row v-if="isNewLocation">
            <v-col cols="12">
              <text-input rules="required|min:2|max:250"
                          minlength="2"
                          maxlength="250"
                          label="Address Line 1 Where Vehicle is Kept Overnight"
                          v-model="vehicle.GaragingLocation.Address1"
                          autocomplete="shipping address-line1"
                          autocapitalize="words"></text-input>
            </v-col>
          </v-row>
          <!-- Address Line Two -->
          <v-row v-if="isNewLocation">
            <v-col cols="12">
              <text-input rules="min:2|max:250"
                          minlength="2"
                          maxlength="250"
                          label="Address Line 2 Where Vehicle is Kept Overnight"
                          v-model="vehicle.GaragingLocation.Address2"
                          autocomplete="shipping address-line2"
                          autocapitalize="words"></text-input>
            </v-col>
          </v-row>
          <!-- City -->
          <v-row v-if="isNewLocation">
            <v-col cols="12">
              <text-input rules="required|min:2|max:250"
                          minlength="2"
                          maxlength="250"
                          label="City Where Vehicle is Kept Overnight"
                          v-model="vehicle.GaragingLocation.City"
                          autocomplete="shipping address-level2"
                          autocapitalize="words"></text-input>
            </v-col>
          </v-row>
          <!-- Zip Code -->
          <v-row v-if="isNewLocation">
            <v-col cols="12">
              <text-input rules="required|numeric|min:5|max:5"
                          minlength="5"
                          maxlength="5"
                          label="ZIP Code Where Vehicle is Kept Overnight"
                          v-model="vehicle.GaragingLocation.ZipCode"
                          type="number"
                          autocomplete="shipping postal-code"></text-input>
            </v-col>
          </v-row>
          <!-- County where vehicle is kept -->
          <v-row v-if="isNewLocation">
            <v-col cols="12">
              <select-input rules="required"
                            label="County Where Vehicle is Kept Overnight"
                            :items="countyOptions"
                            item-text="CountyName"
                            item-value="CountyCode"
                            v-model="vehicle.GaragingLocation.LocationCounty"></select-input>
            </v-col>
          </v-row>
          <!-- Safety -->
          <v-row>
            <v-col cols="12">
              <select-input rules=""
                            label="Safety Features/Options"
                            :items="safetyOptions"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="vehicle.PassiveRestraintCode"></select-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn @click="passes(onAddSubmit)"
                     :disabled="formDisabled"
                     class="tfb-button-min background-red float-right">
                Add Another Vehicle
              </v-btn>
              <br /><br />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   sm="6"
                   order="2"
                   order-sm="1">
              <v-btn @click="onBack"
                     v-bind:class="[ !isMobile ? 'float-right' : '', 'tfb-button-min', 'background-red' ]">
                <v-icon>mdi-chevron-left</v-icon>
                Go Back
              </v-btn>
            </v-col>
            <v-col cols="12"
                   sm="6"
                   order="1"
                   order-sm="2">
              <v-btn type="submit"
                     :disabled="formDisabled"
                     class="tfb-button-min background-red float-right">
                Continue
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
    </v-container>
  </div>
</template>
<script>
  import { mask } from 'vue-the-mask';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import tfbHelperMixin from '@/mixins/helpers';
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbMetaMixin from '@/mixins/metaInfo';
  import { genericErrorMessage } from '@/mixins/errorMessages';
  import RequiredFieldsRow from '@/views/layout/components/RequiredFieldsRow.vue';
/* eslint prefer-destructuring: 0 */
/* eslint object-shorthand: 0 */

  export default {
    name: 'AutoQuoteVehicles',
    mixins: [tfbHelperMixin, tfbDataMixin, tfbMetaMixin],
    directives: { mask },
    components: {
      ValidationObserver,
      ValidationProvider,
      RequiredFieldsRow,
    },
    data() {
      return {
        formDisabled: false,
        quoteDetails: null,
        vehicle: null,
        vehicleList: null,
        garagingLocations: [],
        countyOptions: null,
        primaryUseOptions: null,
        safetyOptions: null,
        enterByVin: true,
        isValidVin: false,
        ownerList: [],
        operatorList: [],
        daysPerWeekOptions: ['0', '1', '2', '3', '4', '5', '6', '7'],
        yesNoOptions: [{ Value: 'Y', DisplayText: 'Yes' }, { Value: 'N', DisplayText: 'No' }],
        vehicleYears: [],
        vehicleMakes: [],
        vehicleModels: [],
        vehicleBodyStyles: [],
        otherOption: { Value: '0', DisplayText: 'Other' },
        yearSelectionDisabled: true,
        makeSelectionDisabled: true,
        modelSelectionDisabled: true,
        styleSelectionDisabled: true,
        primaryUseDisabled: false,
        isMobile: this.$vuetify.breakpoint.smAndDown,
      };
    },
    computed: {
      metaDescription() {
        return 'Looking for an auto insurance quote? Use our online tool to learn how Texas Farm Bureau Insurance can help provide coverage for your precious ride.';
      },
      metaPageTitle() {
        return 'Vehicles | Auto Rate Quote';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/quotes/auto/vehicles`;
      },
      agreementNumber() {
        try {
          return this.$store.getters['quotes/agreementNumber'];
        } catch (error) {
          return '';
        }
      },
      subAgreementNumber() {
        try {
          return this.$store.getters['quotes/subAgreementNumber'];
        } catch (error) {
          return '';
        }
      },
      quoteData() {
        try {
          return this.$store.getters['quotes/quote'];
        } catch (error) {
          return null;
        }
      },
      isUsingVin() {
        return this.enterByVin;
      },
      isUsedForFarmRanch() {
        return (this.vehicle && this.vehicle.PrimaryVehicleUse && this.vehicle.PrimaryVehicleUse === '4') || false;
      },
      isUsedForWorkSchool() {
        return (this.vehicle && this.vehicle.PrimaryVehicleUse && this.vehicle.PrimaryVehicleUse === '2') || false;
      },
      isNewLocation() {
        return (this.vehicle && this.vehicle.GaragingLocationSequenceNumber && this.vehicle.GaragingLocationSequenceNumber !== '' && this.vehicle.GaragingLocationSequenceNumber !== '1') || false;
      },
    },
    methods: {
      getCountyList() {
        this.$store.dispatch('quotes/getCountyList', { sTfbId: this.trackingId })
          .then((countyListResponse) => {
            this.countyOptions = countyListResponse;
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
      getPrimaryUses() {
        this.$store.dispatch('quotes/getPrimaryUses', { sTfbId: this.trackingId })
          .then((primaryUseResponse) => {
            this.primaryUseOptions = primaryUseResponse;
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
      getAntiTheftOptions() {
        this.$store.dispatch('quotes/getAntiTheftOptions', { sTfbId: this.trackingId })
          .then((antiTheftResponse) => {
            this.antiTheftOptions = antiTheftResponse;
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
      getSafetyOptions() {
        this.$store.dispatch('quotes/getSafetyOptions', { sTfbId: this.trackingId })
          .then((safetyResponse) => {
            this.safetyOptions = safetyResponse;
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
      getVehicleYears() {
        this.vehicleYears = [];
        this.vehicleMakes = [];
        this.vehicleModels = [];
        this.vehicleBodyStyles = [];
        this.$store.dispatch('quotes/getVehicleYears', { sAgreementNumber: this.agreementNumber, sSubAgreementNumber: this.subAgreementNumber, sTfbId: this.trackingId })
          .then((yearListResponse) => {
            this.yearSelectionDisabled = false;
            this.vehicleYears = yearListResponse;

            if (this.vehicle.Year && this.vehicle.Year !== null && this.vehicle.Year !== '') {
              this.getVehicleMakes();
            }
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
      getVehicleMakes() {
        if (this.vehicle.Year && this.vehicle.Year !== null && this.vehicle.Year !== '') {
          this.vehicleMakes = [];
          this.vehicleModels = [];
          this.vehicleBodyStyles = [];
          this.$store.dispatch('app/clearErrorMessage');
          this.$store.dispatch('quotes/getVehicleMakesByYear', {
            sVehicleYear: this.vehicle.Year,
            sAgreementNumber: this.agreementNumber,
            sSubAgreementNumber: this.subAgreementNumber,
            sTfbId: this.trackingId,
          })
            .then((makeListResponse) => {
              this.makeSelectionDisabled = false;
              this.vehicleMakes = makeListResponse;

              if (this.vehicle.Make && this.vehicle.Make !== null && this.vehicle.Make !== '') {
                this.getVehicleModels();
              }
            })
            .catch((error) => {
              if (error.toString().indexOf('status code 401') >= 0) {
                // Session has timed out
                this.$store.commit('quotes/SET_TIMEOUT', true);
                this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
              }
              this.$store.dispatch('app/setErrorMessage', error.toString());
            });
        }
      },
      getVehicleModels() {
        if (this.vehicle.Make && this.vehicle.Make !== null && this.vehicle.Make !== '') {
          this.vehicleModels = [];
          this.vehicleBodyStyles = [];
          this.$store.dispatch('app/clearErrorMessage');
          this.$store.dispatch('quotes/getVehicleModelsByMakeYear', {
            sVehicleYear: this.vehicle.Year,
            sVehicleMake: this.vehicle.Make,
            sAgreementNumber: this.agreementNumber,
            sSubAgreementNumber: this.subAgreementNumber,
            sTfbId: this.trackingId,
          })
            .then((modelListResponse) => {
              this.modelSelectionDisabled = false;
              this.vehicleModels = modelListResponse;

              if (this.vehicle.Model && this.vehicle.Model !== null && this.vehicle.Model !== '') {
                this.getVehicleBodyStyles();
              }
            })
            .catch((error) => {
              if (error.toString().indexOf('status code 401') >= 0) {
                // Session has timed out
                this.$store.commit('quotes/SET_TIMEOUT', true);
                this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
              }
              this.$store.dispatch('app/setErrorMessage', error.toString());
            });
        }
      },
      getVehicleBodyStyles() {
        if (this.vehicle.Model && this.vehicle.Model !== null && this.vehicle.Model !== '') {
          this.vehicleBodyStyles = [];
          this.$store.dispatch('app/clearErrorMessage');
          this.$store.dispatch('quotes/getVehicleBodyStylesByYearMakeModel', {
            sVehicleYear: this.vehicle.Year,
            sVehicleMake: this.vehicle.Make,
            sVehicleModel: this.vehicle.Model,
            sAgreementNumber: this.agreementNumber,
            sSubAgreementNumber: this.subAgreementNumber,
            sTfbId: this.trackingId,
          })
            .then((styleListResponse) => {
              this.styleSelectionDisabled = false;
              this.vehicleBodyStyles = styleListResponse;

              if (this.vehicle.VehicleBodyStyle && this.vehicle.VehicleBodyStyle !== null && this.vehicle.VehicleBodyStyle !== '') {
                this.setVehicleDetail();
              }
            })
            .catch((error) => {
              if (error.toString().indexOf('status code 401') >= 0) {
                // Session has timed out
                this.$store.commit('quotes/SET_TIMEOUT', true);
                this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
              }
              this.$store.dispatch('app/setErrorMessage', error.toString());
            });
        }
      },
      swapEntry() {
        this.enterByVin = !this.enterByVin;

        if (!this.enterByVin) {
          this.vehicle.VIN = '';
        }
      },
      setVehicleDetail() {
        this.vehicle.Description = `${this.vehicle.Year} ${this.vehicle.Make} ${this.vehicle.Model}`;
      },
      setupQuote() {
        if (this.quoteData) {
          this.quoteDetails = this.quoteData.Quote;
          if (this.quoteDetails && this.quoteDetails !== null && this.quoteDetails.AgreementNumber !== null && this.quoteDetails.AgreementNumber !== '') {
            this.getCountyList();
            this.setupVehicle(this.$route.params.id);
          } else {
            // No saved quote information, so go back to home
            this.$router.push({ name: 'quoteAboutYou' });
          }
        } else {
          // No saved quote information, so go back to home
          this.$store.commit('quotes/SET_TIMEOUT', true);
          this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
        }
      },
      buildGaragingLocations(quote) {
        if (quote) {
          this.garagingLocations = [];
          for (let g = 0; g < quote.GaragingLocations.length; g += 1) {
            const foundLocation = quote.GaragingLocations[g];

            if (foundLocation) {
              foundLocation.LocationAddress = this.buildSingleLineAddress(foundLocation.Address1, foundLocation.Address2, null, foundLocation.City,
                foundLocation.State, foundLocation.ZipCode, null, null);
              this.garagingLocations.push({ Value: foundLocation.LocationSequenceNumber, DisplayText: foundLocation.LocationAddress });
            }
          }
          this.garagingLocations.push(this.otherOption);
        }
      },
      buildOperators(quote) {
        if (quote) {
          this.ownerList = [];
          this.operatorList = [];
          for (let o = 0; o < quote.Operators.length; o += 1) {
            const foundOperator = quote.Operators[o];

            if (foundOperator) {
              this.ownerList.push({ Value: foundOperator.DriverSequenceNumber, DisplayText: `${foundOperator.FirstName} ${foundOperator.LastName}` });
              this.operatorList.push({ Value: foundOperator.DriverSequenceNumber, DisplayText: `${foundOperator.FirstName} ${foundOperator.LastName}` });
            }
          }
          this.ownerList.push(this.otherOption);
        }
      },
      setupVehicle(vehicleId) {
        this.isValidVin = false;

        if (this.quoteData) {
          this.vehicleList = this.quoteData.Vehicles;
          this.buildGaragingLocations(this.quoteData);
          this.buildOperators(this.quoteData);
        }

        if (this.vehicleList && this.vehicleList.length > 0) {
          // Have vehicles, try to find the one wanted
          if (vehicleId && vehicleId === 'new') {
            // Adding a new vehicle
            this.vehicle = {
              VIN: null, Year: null, Make: null, Model: null, Description: '', GaragingLocation: {},
            };
            // Add it to the vehicle list
            this.vehicleList.push(this.vehicle);
          } else if (vehicleId && vehicleId !== '0') {
            // Try to find the one wanted
            for (let v = 0; v < this.vehicleList.length; v += 1) {
              const foundVehicle = this.vehicleList[v];

              if (foundVehicle && foundVehicle.VehicleSequenceNumber === vehicleId) {
                this.vehicle = foundVehicle;
                this.vehicle.GaragingLocation = {};
              }
            }
          }

          if (this.vehicle === null) {
            // Default to the first vehicle
            this.vehicle = this.vehicleList[0];
            this.vehicle.GaragingLocation = {};
          }
        } else {
          // No vehicles have been entered, so build an empty vehicle object
          this.vehicle = {
            VIN: null, Year: null, Make: null, Model: null, Description: '', GaragingLocation: {},
          };
          // Add it to the vehicle list
          this.vehicleList.push(this.vehicle);
        }

        if (this.vehicle.VIN !== null && this.vehicle.Year !== null) {
          if (this.vehicle.VIN === '') {
            this.enterByVin = false;
          } else {
            this.enterByVin = true;
            this.isValidVin = true;
          }
        }

        this.getVehicleYears();
        this.getSafetyOptions();
        this.getPrimaryUses();

        // If this is not an individual quote, set the primary use and don't allow changes
        if (this.quoteData.Quote.OwnershipCode !== 'I') {
          this.primaryUseDisabled = true;
          this.vehicle.PrimaryVehicleUse = '3';
          this.vehicle.DaysPerWeekDriven = '0';
        }

        if (this.vehicle.GaragingLocationSequenceNumber && this.vehicle.GaragingLocationSequenceNumber !== null) {
          for (let g = 0; g < this.quoteData.GaragingLocations.length; g += 1) {
            const foundLocation = this.quoteData.GaragingLocations[g];

            if (foundLocation && foundLocation.LocationSequenceNumber === this.vehicle.GaragingLocationSequenceNumber) {
              this.vehicle.GaragingLocation.Address1 = foundLocation.Address1;
              this.vehicle.GaragingLocation.Address2 = foundLocation.Address2;
              this.vehicle.GaragingLocation.City = foundLocation.City;
              this.vehicle.GaragingLocation.State = foundLocation.State;
              this.vehicle.GaragingLocation.ZipCode = foundLocation.ZipCode;
              this.vehicle.GaragingLocation.LocationCounty = foundLocation.LocationCounty;
            }
          }
        }
      },
      onBack() {
        this.$store.dispatch('app/clearErrorMessage');
        this.$router.push({ name: 'quoteAboutYou' });
      },
      onDelete() {
        if (this.vehicle.VehicleSequenceNumber) {
          this.$store.dispatch('app/clearErrorMessage');
          // Remove the selected vehicle
          this.$store.dispatch('quotes/deleteVehicle', {
            sAgreementNumber: this.agreementNumber,
            sSubAgreementNumber: this.subAgreementNumber,
            sVehicleSequenceNumber: this.vehicle.VehicleSequenceNumber,
            sTfbId: this.trackingId,
          })
            .then((quoteReturn) => {
              if (quoteReturn) {
                if (this.quoteData) {
                  this.vehicleList = this.quoteData.Vehicles;
                  // Go back to the first vehicle
                  this.vehicle = this.quoteData.Vehicles[0];
                  this.$router.push({ name: 'quoteVehicle' });
                }
              } else {
                this.$store.dispatch('app/setErrorMessage', genericErrorMessage);
                this.$store.dispatch('app/setIsLoading', false);
              }
            })
            .catch((error) => {
              if (error.toString().indexOf('code 401') >= 0) {
                // Session has timed out
                this.$store.commit('quotes/SET_TIMEOUT', true);
                this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
              }
              this.$store.dispatch('app/setErrorMessage', error.toString());
              this.$store.dispatch('app/setIsLoading', false);
            });
        } else {
          // Remove the last vehicle
          this.vehicleList.pop();

          if (this.quoteData) {
            // Go back to the first vehicle
            this.vehicle = this.quoteData.Vehicles[0];
            this.$router.push({ name: 'quoteVehicle' });
          }
        }
        this.scrollToTop();
      },
      onSubmit() {
        this.saveVehicle(false);
      },
      onAddSubmit() {
        this.saveVehicle(true);
      },
      saveVehicle(addNew) {
        this.$store.dispatch('app/clearErrorMessage');
        this.$store.dispatch('app/setIsLoading', true);

        // Is this an add or an update?
        if (this.vehicle.VehicleSequenceNumber) {
          // Update the existing vehicle
          this.$store.dispatch('quotes/updateVehicle', {
            sQuote: this.quoteDetails,
            sVehicle: this.vehicle,
            sTfbId: this.trackingId,
          })
            .then((quoteReturn) => {
              if (quoteReturn) {
                if (addNew) {
                  // They want to add another vehicle
                  this.$refs.vehicleForm.reset();
                  this.$refs.obv.reset();
                  this.setupVehicle('new');
                  this.scrollToTop();
                  this.$store.dispatch('app/setIsLoading', false);
                } else {
                  // They want to continue on
                  this.$router.push({ name: 'quoteDriver' });
                }
              } else {
                this.$store.dispatch('app/setErrorMessage', genericErrorMessage);
                this.$store.dispatch('app/setIsLoading', false);
              }
            })
            .catch((error) => {
              if (error.toString().indexOf('code 401') >= 0) {
                // Session has timed out
                this.$store.commit('quotes/SET_TIMEOUT', true);
                this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
              }
              this.$store.dispatch('app/setErrorMessage', error.toString());
              this.$store.dispatch('app/setIsLoading', false);
            });
        } else {
          // Add the new vehicle
          this.$store.dispatch('quotes/addNewVehicle', {
            sQuote: this.quoteDetails,
            sVehicle: this.vehicle,
            sTfbId: this.trackingId,
          })
            .then((quoteReturn) => {
              if (quoteReturn) {
                if (addNew) {
                  // They want to add another vehicle
                  this.$refs.vehicleForm.reset();
                  this.setupVehicle('new');
                  this.scrollToTop();
                  this.$store.dispatch('app/setIsLoading', false);
                } else {
                  // They want to continue on
                  this.$router.push({ name: 'quoteDriver' });
                }
              } else {
                this.$store.dispatch('app/setErrorMessage', genericErrorMessage);
                this.$store.dispatch('app/setIsLoading', false);
              }
            })
            .catch((error) => {
              if (error.toString().indexOf('code 401') >= 0) {
                // Session has timed out
                this.$store.commit('quotes/SET_TIMEOUT', true);
                this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
              }
              this.$store.dispatch('app/setErrorMessage', error.toString());
              this.$store.dispatch('app/setIsLoading', false);
            });
        }
      },
    },
    watch: {
      $route: 'setupQuote',
    },
    async mounted() {
      this.$store.dispatch('app/clearErrorMessage');
      this.$store.dispatch('app/setIsLoading', true);
      this.setupQuote();
      this.$store.dispatch('app/setIsLoading', false);
    },
  };
</script>
<style lang="scss">


  .tfb-quote-box {
    background-color: $white;
    color: $black;
  }
</style>
