<template>
    <div class="find-agent-google-map">
      <GmapMap :options="mapConfig"
               :center="{ lat: 31.5248446, lng: -97.2181 }"
               ref="mapRef"
               class="google-map">
        <gmap-info-window :options="infoOptions"
                          :position="infoWindowPos"
                          :opened="infoWindowOpen"
                          @closeclick="infoWindowOpen=false">
        </gmap-info-window>
        <GmapMarker :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :clickable="true"
                    :draggable="false"
                    :icon="m.icon"
                    @click="toggleInfoWindow(m,index)" />
      </GmapMap>
    </div>
</template>
<script>
  import {
    mapSettings, defaultCenter,
  } from '@/mixins/googleSettings';

  export default {
    name: 'AgentMap',
    props: {
      markers: {
        type: Array,
        required: true,
      },
      infoWindowOpen: {
        type: Boolean,
        required: true,
        default: true,
      },
    },
    data() {
      return {
        currentMidx: null,
        selectedMarker: null,
      };
    },
    computed: {
      mapConfig() {
        return {
          ...mapSettings,
          center: this.mapCenter,
        };
      },
      mapCenter() {
        // eslint-disable-next-line no-nested-ternary
        return this.markers.length > 0 ? this.selectedMarker !== null ? this.markers[this.selectedMarker].position : this.markers[0].position : defaultCenter;
      },
      infoWindowPos() {
        // eslint-disable-next-line no-nested-ternary
        return this.markers.length > 0 ? this.selectedMarker !== null ? this.markers[this.selectedMarker].position : this.markers[0].position : null;
      },
      infoOptions() {
        if (this.markers.length > 0) {
          if (this.selectedMarker !== null) {
            return {
              content: this.markers[this.selectedMarker].infoText,
              pixelOffset: {
                width: 0,
                height: -35,
              },
            };
          }
          return {
            content: this.markers[0].infoText,
            pixelOffset: {
              width: 0,
              height: -35,
            },
          };
        }

        return {
          content: '',
          pixelOffset: {
            width: 0,
            height: -35,
          },
        };
      },
    },
    methods: {
      toggleInfoWindow(marker, idx) {
        console.log(marker);
        console.log(idx);
        this.selectedMarker = idx;
        // this.infoWindowPos = marker.position;
        // this.infoOptions.content = marker.infoText;
        // check if its the same marker that was selected if yes toggle
        if (this.currentMidx === idx) {
          this.infoWindowOpen = !this.infoWindowOpen;
        } else {
          // if different marker set infowindow to open and reset current marker index
          this.infoWindowOpen = true;
          this.currentMidx = idx;
        }
      },
    },
  };
</script>
<style lang="scss">
  .find-agent-google-map {
    height: 100%;
    width: 100%;
  }

  .google-map {
    min-height: 500px;
    max-height: 1000px;
    width: 100%;
  }

  .gm-style-iw-d {
    color: $black;
  }

</style>
