<template>
  <v-container fluid
               class="tfb-smaller-container pb-10">
    <v-row class="my-1">
      <v-col cols="12">
        <h1 class="tfb-title-text-medium text-darker-blue text-center">A Joint Privacy Notice</h1>
      </v-col>
    </v-row>
    <v-row class="my-1">
      <v-col cols="12">
          <span class="font-weight-bold">On behalf of:</span> Texas Farm Bureau Mutual Insurance
          Company, Texas Farm Bureau Premium Finance Company, Texas Farm Bureau Underwriters,
          Texas Farm Bureau Casualty Insurance Company, and Farm Bureau County Mutual Insurance
          Company of Texas. When you see the words <span class="font-italic">we</span>,
          <span class="font-italic">us</span>, or <span class="font-italic">our</span> within
          this <span class="font-italic">Joint Privacy Notice</span>, you should understand
          that those words include Texas Farm Bureau Mutual Insurance Company, Texas Farm Bureau
          Premium Finance Company, Texas Farm Bureau Underwriters, Texas Farm Bureau Casualty
          Insurance Company, and Farm Bureau County Mutual Insurance Company of Texas.
      </v-col>
    </v-row>
    <v-row class="my-1">
      <v-col cols="12">
        <v-expansion-panels accordion
                            flat>
          <v-expansion-panel>
            <v-expansion-panel-header class="show-hide-link">
              <span class="text-blue">Our Commitment to Safeguarding Your Privacy</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              <p>
                This <span class="font-italic">Joint Privacy Notice</span>
                is being provided to you to inform you of our practices and
                procedures regarding the sharing of
                <span class="font-italic">nonpublic personal information</span>.
                Nonpublic personal information is
                <span class="font-italic">personally identifiable financial information</span>
                about you, your family, or your household that we may
                obtain directly from you, that we may obtain as a result of our business
                dealings with you, or that we may obtain from any other source.
              </p>
              <p>
                We do not engage in the practice of disclosing your nonpublic personal
                information to nonaffiliated third parties other than, as necessary, to provide
                quality insurance and financial services to our customers. For instance, we do
                not sell your name, address or telephone number to telemarketers or to direct
                mail solicitors. We do not engage in &ldquo;list-selling,&rdquo; and we do not offer your
                personal information to publishing houses, retailers, or coupon companies.
              </p>
              <p>
                During the continuation of our customer relationship with you, we will also
                provide you with a copy of this Joint Privacy Notice at least once during
                each calendar year as part of a regular mailing, such as within a billing or
                renewal notice or with a policy <span class="font-italic">and</span> at any
                time that you request in writing a copy of this Joint Privacy Notice.
                Further, because Federal and state laws, regulations and business practices
                can change at any time, we may revise this
                <span class="font-italic">Joint Privacy Notice</span> accordingly. Once you
                cease to be a policyholder, we will stop sending you annual privacy notices.
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="show-hide-link">
              <span class="text-blue">Information We May Collect and Use</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              <p>
                During the course of reviewing your application for insurance coverage and
                deciding whether or not to underwrite the insurance policy for which you have
                applied, and, if we issue you a policy, during the course of providing services
                to you as required by the policy, we collect and evaluate information from the
                following sources:
              </p>
              <p>
                <ul>
                  <li>Information we receive from you on applications or other forms;</li>
                  <li>Information about your transactions with us, our affiliates or others;</li>
                  <li>Information we receive from consumer reporting agencies; and</li>
                  <li>Information we receive from a state or federal agency.</li>
                </ul>
              </p>
              <p>
                It is important for us to collect and use this information to properly provide,
                administer, and perform services on your behalf. We could not provide optimum
                service to you without collecting and using this information.
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="show-hide-link">
              <span class="text-blue">Information We May Disclose</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              <p>
                We regard all of your personal information as confidential. Therefore, we do
                not disclose any nonpublic personal information about our customers or former
                customers to anyone except as permitted by law. In the course of conducting our
                business dealings, we may disclose to other non-affiliated parties certain
                information we have about you. These disclosures are only made for our everyday
                business purposes, such as to process account transactions, maintain accounts,
                service accounts, respond to court orders and authorized legal investigations,
                and to comply with mandatory governmental data requests in accordance with
                applicable laws, and may include disclosures to reinsurers, administrators,
                service providers, consultants and regulatory or governmental authorities.
                Furthermore, certain disclosures of information will be made to your Farm Bureau
                insurance agent and any Farm Bureau personnel authorized to have access to your
                personal information for the purpose of administering your business and providing
                services to you.
              </p>
              <p>
                We may disclose the following categories of information to companies that
                perform services on our behalf or to other financial institutions with whom
                we have joint marketing agreements such as the Southern Farm Bureau Life
                Insurance Company and the Farm Bureau Bank:
              </p>
              <p>
                <ul>
                  <li>
                    Information we receive from you on applications or other forms, such as
                    your name, address, social security number or County Farm Bureau membership
                    number;
                  </li>
                  <li>
                    Information about your transactions with us, our affiliates or others,
                    such as your policy coverage, premium, loss history and payment history;
                    and
                  </li>
                  <li>
                    Information we receive from a consumer reporting agency, such as your
                    creditworthiness and credit history.
                  </li>
                </ul>
              </p>
              <p>
                Those entities with whom we share personal information are required to
                maintain the confidentiality of that information. We do not authorize these
                parties to use or disclose your personal information for any purpose other
                than for the express purpose of performing work on our behalf or as required
                or permitted by law.
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="show-hide-link">
              <span class="text-blue">How We Maintain The Confidentiality And Security Of Your Information</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              <p>
                We carefully restrict access to nonpublic personal information to our
                employees, our independent contractor insurance agents, our service contract
                providers, and our affiliates and subsidiaries. The right of our employees, our
                independent contractor insurance agents, our service contract providers, and our
                affiliates and subsidiaries to further disclose and use the information is
                limited by our employee handbook, agent&rsquo;s manual, applicable law, and
                non-disclosure agreements where appropriate. We maintain physical, electronic,
                and procedural safeguards that comply with federal and state law to guard your
                nonpublic personal information.
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row class="my-1">
      <v-col cols="12">
        <h2 class="tfb-title-text-small text-darker-blue text-uppercase">
          Conclusion:
        </h2>
        If you have any questions or comments concerning this <span class="font-italic">Joint Privacy Notice</span> or our
        privacy standards and procedures, then please write us at {{poBoxAddress}}.
        Our functional regulator is the Texas Department of Insurance.
        If we cannot resolve your concerns or answer your questions, feel free to contact
        our regulator.
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbMetaMixin from '@/mixins/metaInfo';

  export default {
    name: 'JointPrivacy',
    mixins: [tfbDataMixin, tfbMetaMixin],
    computed: {
      metaDescription() {
        return 'With Texas Farm Bureau Insurance, your information is secure. Review our Privacy Policy here.';
      },
      metaPageTitle() {
        return 'Joint Privacy Notice';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/privacy`;
      },
    },
    mounted() {
      this.$store.dispatch('app/setIsLoading', false);
    },
    created() {
      this.$store.dispatch('app/setIsLoading', true);
    },
  };
</script>
