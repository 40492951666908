<template>
  <div>
    <main id="main-loading"
          role="main"
          v-if="blogLoading">
      <v-skeleton-loader class="mx-auto"
                         max-width="1024"
                         type="card"></v-skeleton-loader>
    </main>
    <main id="main"
          role="main"
          v-if="post !== null && post.id !== 0 && !blogLoading">
      <div class="visual-block"
           :style="getBackgroundImage(post.headerImage)" />
      <div class="main-holder pb-4">
        <div class="blog-container">
          <div id="blogContent">
            <article class="article">
              <router-link class="category-link"
                           :to="{ name: 'blogFeedCategory', params: { category: kebabify(post.category) } }"
                           hreflang="en-us">
                {{post.category}}
              </router-link>
              <span v-if="post.subcategory && post.subcategory !== null && post.subcategory !== ''"
                    class="category-text">
                &nbsp;&nbsp;/&nbsp;&nbsp;
              </span>
              <router-link v-if="post.subcategory && post.subcategory !== null && post.subcategory !== ''"
                           class="category-link"
                           :to="{ name: 'blogFeedSubCategory',
                             params: { category: kebabify(post.category), subcategory: kebabify(post.subcategory) } }"
                           hreflang="en-us">
                {{post.subcategory}}
              </router-link>
              <h1 class="title-post">
                {{post.title}}
              </h1>
              <div class="author-box">
                <strong v-if="post.author !== null && post.author !== ''">
                  By {{post.author}} |
                </strong>
                <strong v-if="post.illustrator && post.illustrator !== null && post.illustrator !== ''">
                  Illustrations By {{post.illustrator}} |
                </strong>
                <strong class="date">
                  {{post.publishDate | formatBlogDate}}
                </strong>
                <!-- Top AddThis -->
                <div class="social-top-post addthis_inline_share_toolbox"></div>
              </div>
              <!-- Blog Post Text -->
              <span id="article-text"
                    class="article-text"
                    v-html="post.text"></span>
              <!-- Bottom AddThis -->
              <div class="social-bottom-post addthis_inline_share_toolbox"></div>
              <!-- Bottom Related Posts -->
              <sidebar :category="post.category"
                       :subcategory="post.subcategory"
                       displayLocation="bottom"
                       :currentPostId="post.id"></sidebar>
              <!-- Pagination -->
              <pagination :id="post.id"
                          :date="post.publishDate"></pagination>
              <script type="application/ld+json" v-html="post.jsonld">
              </script>
            </article>
          </div>
          <sidebar :category="post.category"
                   :subcategory="post.subcategory"
                   displayLocation="side"
                   :currentPostId="post.id"></sidebar>
        </div>
      </div>
    </main>
    <main id="main-not-found"
          role="main"
          v-if="(post === null || post.id === 0) && !blogLoading">
      <div class="error404 visual-block"
           :style="getBackgroundImage('')" />
      <div class="main-holder pb-4">
        <div class="blog-container">
          <div id="blogContent">
            <article class="article">
              <h1 class="title-post">
                Post Not Found
              </h1>
              <!-- Blog Post Text -->
              <span id="article-text">
                Sorry, we cannot find the post you requested.
              </span>
              <!-- Bottom Related Posts -->
              <sidebar :category="articleSection"
                       subcategory=""
                       displayLocation="bottom"
                       :currentPostId="post.id"></sidebar>
            </article>
          </div>
          <sidebar :category="articleSection"
                   subcategory=""
                   displayLocation="side"
                   :currentPostId="post.id"></sidebar>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';
  import tfbMetaMixin from '@/mixins/metaInfo';
  import Sidebar from './post/Sidebar.vue';
  import Pagination from './post/Pagination.vue';
  /* eslint no-nested-ternary: 0 */

  export default {
    name: 'BlogPost',
    mixins: [tfbHelperMixin, tfbMetaMixin],
    components: {
      Sidebar,
      Pagination,
    },
    data() {
      return {
        post: { id: 0 },
        previousPost: null,
        nextPost: null,
        blogLoading: true,
        publicId: 'ra-60de1cac66d15172', // 'ra-576c17231c32082d',
        cdn: '//s7.addthis.com/js/300/addthis_widget.js',
        async: true,
      };
    },
    computed: {
      metaType() {
        return 'article';
      },
      metaDescription() {
        return this.post && this.post.id !== 0
          ? (this.post.seodescription && this.post.seodescription !== null && this.post.seodescription !== '' ? this.post.seodescription : this.post.blurb)
          : 'Texas Heritage for Living';
      },
      metaPageTitle() {
        return this.post && this.post.seotitle && this.post.seotitle !== '' ? this.post.seotitle
          : (this.post && this.post.title && this.post.title !== '' ? this.post.title : 'Texas Heritage for Living');
      },
      metaUrl() {
        return this.post && this.post.id !== 0 ? this.post.fullUrl
          : `${process.env.VUE_APP_ROOT_URI}/blog/${this.$route.params.category}/${this.$route.params.title}`;
      },
      headerImage() {
        return this.post && this.post.id !== 0 ? this.post.headerImage : '';
      },
      articleModifiedTime() {
        return this.post && this.post.id !== 0 ? this.post.modifiedDate : '';
      },
      articlePublishTime() {
        return this.post && this.post.id !== 0 ? this.post.publishDate : '';
      },
      articleSection() {
        return this.post && this.post.id !== 0 ? this.post.category : this.$route.params.category;
      },
      articleTags() {
        return this.post && this.post.id !== 0 ? this.post.tags : [];
      },
      metaNoIndex() {
        return this.post && this.post.id === 0 && this.post.title === 'Post Not Found' ? 'TRUE' : '';
      },
    },
    methods: {
      getPost() {
        this.$store.dispatch('app/setIsLoading', true);
        this.blogLoading = true;
        this.post = null;
        this.$store.dispatch('blog/getPostByUrl', { sUrl: this.$route.params.title, sCategory: this.$route.params.category, sTfbId: this.trackingId })
          .then((postResponse) => {
            this.post = postResponse;
            this.blogLoading = false;
            this.$store.dispatch('app/setIsLoading', false);
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
            this.blogLoading = false;
            this.$store.dispatch('app/setIsLoading', false);
          });
      },
    },
    watch: {
      $route: 'getPost',
    },
    created() {
      this.$store.dispatch('app/clearErrorMessage');
      this.getPost();
    },
    mounted() {
      if (process.browser) {
        if (document.getElementById('addthis-share') === null) {
          const el = document.createElement('script');
          el.setAttribute('id', 'addthis-share');
          el.setAttribute('type', 'text/javascript');
          el.setAttribute('src', `${this.cdn}#pubid=${this.publicId}`);
          el.setAttribute('async', this.async);
          document.body.appendChild(el);
        }
      }
    },
  };
</script>
<style lang="scss">
  #main {
    background-color: $tfb-lightest-gray !important;
  }

    .article-text a,
    .article-text a:visited,
    .article-text a:active,
    .article-text a:hover
    .article-text p a,
    .article-text p a:visited,
    .article-text p a:active,
    .article-text p a:hover {
      text-decoration: none;
      -webkit-transition: color .2s;
      transition: color .2s;
      font-weight: 700 !important;
    }
  </style>
