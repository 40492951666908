<template>
  <div>
    <v-container fluid
                 class="tfb-container">
      <v-row class="my-1">
        <v-col class="align-center">
          <h1 class="tfb-title-text-medium text-darker-blue">Find a Sales Manager</h1>
        </v-col>
      </v-row>
      <!--<v-row class="my-12">
    <v-col>
      <span class="text-darker-blue">
        TODO: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi bibendum enim ac
        mollis varius. Etiam gravida sapien ac risus pharetra sagittis. Aenean iaculis
        porta faucibus. Nullam tincidunt arcu est, sit amet cursus lectus finibus sed.
        Quisque sed erat vitae mi sollicitudin laoreet a tristique lorem. Nullam feugiat
        dui sed tellus luctus sagittis sed ac urna.
      </span>
    </v-col>
  </v-row>-->
      <v-row>
        <v-col cols="12"
               md="6"
               class="text-center">
          <v-select outlined
                    solo
                    id="contact-subject-quote"
                    name="contact-subject-quote"
                    label="County"
                    :items="countyOptions"
                    @change="findDsm"
                    v-model="countyName"
                    item-text="_countyName"
                    item-value="_countyName">
          </v-select>
        </v-col>
      </v-row>
      <v-row v-if="countyName && countyName !== null">
        <v-col cols="12"
               sm="3">
          <v-skeleton-loader :loading="dsmLoading"
                             type="image"
                             class="mx-auto">
            <v-img v-if="foundDsm && foundDsm !== null && foundDsm.code !== ''"
                   :src="foundDsm.photo"
                   class="rounded-0 agent-photo mx-auto"
                   :alt="foundDsm.name"
                   itemprop="image">
              <template v-slot:placeholder>
                <v-img :src="defaultAgentImage"
                       alt="No Photo Available"
                       itemprop="image"
                       class="rounded-0 agent-photo mx-auto"></v-img>
              </template>
            </v-img>
            <v-img v-else
                   :src="defaultAgentImage"
                   class="rounded-0 agent-photo mx-auto"></v-img>
          </v-skeleton-loader>
        </v-col>
        <v-col cols="12"
               sm="9">
          <v-skeleton-loader :loading="dsmLoading"
                             type="paragraph"
                             class="mx-auto">
            <div v-if="foundDsm && foundDsm !== null">
              <h2 class="tfb-title-text-small text-uppercase font-weight-bold">
                Contact Information for {{ countyName }} County
              </h2>
              <h3 class="tfb-title-text-smaller text-uppercase font-weight-bold"
                  itemprop="name">
                {{foundDsm.name}}
              </h3>
              <h4 v-if="foundDsm.code !== ''"
                  class="tfb-header-text-small text-uppercase font-weight-bold">District Sales Manager</h4>
              <span class="tfb-header-text-small text-uppercase font-weight-bold">
                Telephone:
              </span> <a v-bind:href="foundDsm.phoneNumber | linkFormatPhone"
                 hreflang="en-us"
                 itemprop="telephone">
                {{foundDsm.phoneNumber | formatPhone}}
              </a><br />
              <span class="tfb-header-text-small text-uppercase font-weight-bold">
                Email:
              </span> <a v-bind:href="foundDsm.emailAddress | linkFormatEmail"
                 hreflang="en-us"
                 class="text-lowercase"
                 itemprop="email">
                {{foundDsm.emailAddress}}
              </a><br />
            </div>
            <div v-else>
              <span class="tfb-title-text-smaller">
                Unable to locate District Sales Manager for this county.
              </span>
            </div>
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  /* eslint no-underscore-dangle: 0 */
  import { dispatch } from 'vuex-pathify';
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbMetaMixin from '@/mixins/metaInfo';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'FindASalesManager',
    mixins: [tfbDataMixin, tfbMetaMixin, tfbHelperMixin],
    data() {
      return {
        foundDsm: null,
        countyName: '',
        countyOptions: [],
        dsmLoading: false,
      };
    },
    computed: {
      metaPageTitle() {
        return 'Find a Sales Manager';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/careers/find-a-sales-manager`;
      },
    },
    methods: {
      getCountyList() {
        this.$store.dispatch('agentcounty/getCountyList')
          .then((response) => {
            if (response && response.data && response.data._counties) {
              this.countyOptions = response.data._counties;
            }
            dispatch('app/setIsLoading', false);
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error.toString());
            dispatch('app/setIsLoading', false);
          });
      },
      findDsm() {
        this.dsmLoading = true;
        this.$store.dispatch('agentcounty/getSalesManager', { sCountyName: this.countyName, sTfbId: this.trackingId })
          .then((response) => {
            if (response) {
              if (response.errorMessage === null) {
                this.foundDsm = response;
              } else {
                dispatch('app/setErrorMessage', response);
              }
            }
            this.dsmLoading = false;
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error.toString());
            this.dsmLoading = false;
          });
      },
    },
    created() {
      dispatch('app/clearErrorMessage');
      dispatch('app/setIsLoading', true);
      this.getCountyList();
    },
  };
</script>
