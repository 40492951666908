<template>
  <div id="make-payment">
    <v-container>
      <v-row no-gutters>
        <v-col>
            <v-img max-height="85"
                   alt="Texas Farm Bureau Insurance"
                   itemprop="logo"
                   v-bind:src="imageRootPath +'tfb-logo.jpg'"
                   contain></v-img>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <strong>Status:</strong> {{pageStatus}}
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  // eslint-disable-next-line no-unused-vars
  import { format, parseISO } from 'date-fns';
  import { insuredAccessOnlyErrorMessage } from '@/mixins/errorMessages';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'LoggedInPayment',
    props: {
      policyNumber: {
        type: String,
        required: true,
      },
      policyType: {
        type: String,
        required: true,
      },
    },
    mixins: [tfbHelperMixin],
    data() {
      return {
        pageStatus: 'Redirecting to payment site (no processing fees will be charged)...',
      };
    },
    methods: {
      getPaymentUrl() {
        return new Promise((resolve, reject) => {
          dispatch('app/getConfiguration', { sSection: 'ExternalUrl', sTitle: 'RegisteredPaymentsEncrypted' })
            .then((urlResponse) => {
              this.$store.dispatch('app/logToULS', {
                logLevel: 'Info',
                logMessage: JSON.stringify(urlResponse),
                fileName: 'LoggedInPayment.vue',
                methodName: 'getPaymentUrl-UrlResponse',
                trackerId: this.trackingId,
              });
              if (urlResponse && urlResponse !== '') {
                resolve(urlResponse);
              } else {
                reject(new Error('Payment processor site url not found'));
              }
            })
            .catch((error) => {
              this.$store.dispatch('app/logToULS', {
                logLevel: 'Error',
                logMessage: error,
                fileName: 'LoggedInPayment.vue',
                methodName: 'getPaymentUrl-Catch',
                trackerId: this.trackingId,
              });
              reject(new Error('Unable to fetch payment processor site url'));
            });
        });
      },
    },
    mounted() {
      dispatch('app/clearErrorMessage');
      dispatch('app/setIsLoading', true);
      this.$store.dispatch('app/logToULS', {
        logLevel: 'Info',
        logMessage: `${this.policyNumber.toUpperCase()}-${this.policyType.toUpperCase()}`,
        fileName: 'LoggedInPayment.vue',
        methodName: 'mounted-Start',
        trackerId: this.trackingId,
      });
      this.$store.dispatch('app/doApplicationCheck', { sApplicationName: 'Payments', sTfbId: this.trackingId })
        .then((returnedStatus) => {
          if (returnedStatus === '') {
            if (!this.isMemberRole) {
              this.pageStatus = insuredAccessOnlyErrorMessage;
              dispatch('app/setIsLoading', false);
            } else {
              this.getPaymentUrl()
                .then((urlResponse) => {
                  let paymentParams;
                  const form = document.createElement('form');
                  form.method = 'post';
                  form.action = urlResponse;
                  dispatch('payments/getEncryptPaymentSummary', { sPolicyNumber: this.policyNumber.toUpperCase(), sPolicyType: this.policyType.toUpperCase(), sTfbId: this.trackingId })
                    .then((paymentResponse) => {
                      if (paymentResponse && paymentResponse !== '') {
                        try {
                          paymentParams = [
                            { name: 'PolicyNumber', value: paymentResponse.RequestSummary.PolicyNumber },
                            { name: 'LineOfBusiness', value: paymentResponse.LineOfBusiness },
                            { name: 'MemberNumber', value: paymentResponse.MemberNumber },
                            { name: 'MinimumAmount', value: paymentResponse.MinimumAmount },
                            { name: 'MaximumAmount', value: paymentResponse.MaximumAmount },
                            { name: 'PastDueAmount', value: paymentResponse.PastDueAmount },
                            { name: 'ReturnItemAmount', value: paymentResponse.ReturnItemAmount },
                            { name: 'PolicyName', value: paymentResponse.PolicyName },
                            { name: 'PaymentRestriction', value: paymentResponse.PaymentRestriction },
                            { name: 'DueDate', value: paymentResponse.DueDate },
                            { name: 'ResponseTimestamp', value: paymentResponse.ResponseTimestamp },
                          ];

                          for (let key = 0; key < paymentParams.length; key += 1) {
                            // console.log(encryptParams[key]);
                            const hiddenField = document.createElement('input');
                            hiddenField.type = 'hidden';
                            hiddenField.name = paymentParams[key].name;
                            hiddenField.value = paymentParams[key].value;

                            form.appendChild(hiddenField);
                          }

                          this.$store.dispatch('app/logToULS', {
                            logLevel: 'Info',
                            logMessage: JSON.stringify(paymentParams),
                            fileName: 'LoggedInPayment.vue',
                            methodName: 'encryptRedirect-Start',
                            trackerId: this.trackingId,
                          });

                          document.body.appendChild(form);
                          form.submit();
                        } catch (error) {
                          this.pageStatus = 'Error redirecting to payment processor site. Please try again.';
                          this.$store.dispatch('app/logToULS', {
                            logLevel: 'Error',
                            logMessage: error,
                            fileName: 'LoggedInPayment.vue',
                            methodName: 'encryptRedirect-Catch',
                            trackerId: this.trackingId,
                          });
                          this.$store.dispatch('app/setIsLoading', false);
                        }
                      } else {
                        this.pageStatus = 'Unable to locate policy payment information. Please try again.';
                        dispatch('app/setIsLoading', false);
                      }
                    })
                    .catch((error) => {
                      this.$store.dispatch('app/logToULS', {
                        logLevel: 'Error',
                        logMessage: error,
                        fileName: 'LoggedInPayment.vue',
                        methodName: 'getPaymentSummary-Catch',
                        trackerId: this.trackingId,
                      });
                      if (error.toString().indexOf('Request failed with status code 401') >= 0) {
                        this.pageStatus = 'Session is no longer valid. Please close this window and log in again.';
                      } else {
                        this.pageStatus = error.toString();
                      }
                      dispatch('app/setIsLoading', false);
                    });
                });
            }
          } else {
            this.pageStatus = returnedStatus;
            dispatch('app/setIsLoading', false);
          }
        })
        .catch((error) => {
          this.$store.dispatch('app/logToULS', {
            logLevel: 'Error',
            logMessage: error,
            fileName: 'LoggedInPayment.vue',
            methodName: 'doApplicationCheck-Catch',
            trackerId: this.trackingId,
          });
          this.pageStatus = error.toString();
          dispatch('app/setIsLoading', false);
        });
    },
  };
</script>
<style lang="scss">

</style>
