<template>
  <span>
    <!--<span class="font-italic text-caption print-date-display print-date-white-display">
    {{printingDate}}
  </span>
  <span class="on-print font-italic text-caption print-date-display">
    Print Date: {{printingDate}}
  </span>-->
  </span>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'PrintDate',
    mixins: [tfbHelperMixin],
    computed: {
      printingDate() {
        return new Date().toISOString();
      },
    },
  };
</script>
<style lang="scss">
  .print-date-display {
    font-family: 'BS-light', $body-font-family;
  }
  .print-date-white-display {
    color: $tfb-off-white;
  }
</style>
