<template>
  <v-row no-gutters
         class="pagin-holder">
    <v-col cols="6"
           v-if="previousPost !== null"
           class="pagin-icon">
      <router-link :to="{ name: 'blogPost',
                         params: { category: kebabify(previousPost.urlCategory),
                         title: kebabify(previousPost.url) } }"
                   hreflang="en-us">
        <v-icon>mdi-arrow-left-bold</v-icon> {{previousPost.title}}
      </router-link>
    </v-col>
    <v-col cols="6"
           v-if="nextPost !== null"
           class="next">
      <router-link :to="{ name: 'blogPost',
                         params: { category: kebabify(nextPost.urlCategory),
                         title: kebabify(nextPost.url) } }"
                   hreflang="en-us">
        {{nextPost.title}} <v-icon>mdi-arrow-right-bold</v-icon>
      </router-link>
    </v-col>
  </v-row>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'BlogPost',
    mixins: [tfbHelperMixin],
    props: {
      id: {
        type: Number,
        required: true,
      },
      date: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        previousPost: null,
        nextPost: null,
      };
    },
    methods: {
      getPosts() {
        this.$store.dispatch('blog/getNextPost', { sId: this.id, sDate: this.date, sTfbId: this.trackingId })
          .then((nextPostResponse) => {
            this.nextPost = nextPostResponse;
          })
          .catch(() => {
            // Do nothing
          });
        this.$store.dispatch('blog/getPreviousPost', { sId: this.id, sDate: this.date, sTfbId: this.trackingId })
          .then((previousPostResponse) => {
            this.previousPost = previousPostResponse;
          })
          .catch(() => {
            // Do nothing
          });
      },
    },
    watch: {
      $route: 'getPosts',
    },
    created() {
      this.getPosts();
    },
  };
</script>
<style lang="scss" scoped>

  .pagin-holder {
    overflow: hidden;
    padding-top: 18px;
    padding-bottom: 6px;
    border-top: 1px solid $tfb-off-white;
  }

    .pagin-holder a {
      /*float: left;*/
      text-transform: uppercase;
      text-decoration: none;
      color: $tfb-blue;
      font-size: 12px;
      font-weight: 700;
      position: relative;
      /*padding-left: 30px;*/
      line-height: 100%;
      -webkit-transition: color .2s;
      transition: color .2s;
      /*letter-spacing: .5px;*/
      /*width: 48%;*/
      font-family: 'BS-bold', $body-font-family;
    }

      .pagin-holder a:hover {
        text-decoration: none;
        color: $tfb-darker-blue;
      }

    .pagin-holder .pagin-icon a i {
      color: $tfb-gray !important;
    }

      .pagin-holder .pagin-icon i:hover,
      .pagin-holder .pagin-icon a:hover {
        color: $tfb-darker-blue !important;
        text-decoration: none !important;
      }

    .pagin-holder .prev {
      text-align: left;
      /*padding: 0 30px 0 0;*/
      float: left;
    }

      .pagin-holder .prev i {
        left: 0;
        right: auto;
      }

    .pagin-holder .next {
      text-align: right;
      /*padding: 0 30px 0 0;*/
      float: right
    }

      .pagin-holder .next i {
        left: auto;
        right: 0
      }

  @media print {
    .pagin-holder {
      display: none !important
    }
  }
  a.prev > i:hover,
  a.next > i:hover {
    color: $tfb-darkest-blue;
    text-decoration: none !important;
  }
</style>
