import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import Header from '../views/layout/Header.vue';
import HeaderNotification from '../views/layout/HeaderNotification.vue';
import Footer from '../views/layout/Footer.vue';
import Content from '../views/layout/MainContent.vue';
import Services from '../views/services/ServiceInformation.vue';
import BlogMain from '../views/blog/BlogMain.vue';
import BlogPost from '../views/blog/BlogPost.vue';
import BlogListing from '../views/blog/BlogListing.vue';
import PageNotFound from '../views/content/PageNotFound.vue';
import HomeHeader from '../views/content/Home.vue';
import AboutUs from '../views/content/AboutUs.vue';
import FindASalesManager from '../views/content/FindASalesManager.vue';
import Careers from '../views/content/Careers.vue';
import Search from '../views/content/SearchResults.vue';
import ContactUs from '../views/content/ContactUs.vue';
import TermsConditions from '../views/content/TermsConditions.vue';
import JointPrivacy from '../views/content/JointPrivacy.vue';
import DMCA from '../views/content/DMCA.vue';
import RequiredNotices from '../views/content/RequiredNotices.vue';
import FindAnAgent from '../views/agents/FindAnAgent.vue';
import AgentDetails from '../views/agents/AgentDetails.vue';
import AgentListing from '../views/agents/AgentListing.vue';
import CountyDetails from '../views/agents/CountyDetails.vue';
import CountyListing from '../views/agents/CountyListing.vue';
import ClaimMain from '../views/claims/ClaimMain.vue';
import ClaimReport from '../views/claims/ClaimReport.vue';
import ClaimReportConfirm from '../views/claims/ClaimReportConfirm.vue';
import ViewClaim from '../views/claims/ViewClaim.vue';
import PaymentOptions from '../views/payments/PaymentOptions.vue';
import LoggedInPayment from '../views/payments/LoggedInPayment.vue';
import ChangeEbill from '../views/payments/ChangeEbill.vue';
import PremiumFinancePayment from '../views/payments/PremiumFinancePayment.vue';
import RecurringPayments from '../views/payments/RecurringPayments.vue';
import AccountBill from '../views/payments/ChangeAccountBill.vue';
import PolicyHolderLayout from '../views/policyholder/Layout.vue';
import Login from '../views/policyholder/Login.vue';
import Register from '../views/policyholder/Register.vue';
import AgentAccess from '../views/policyholder/AgentAccess.vue';
import ForgotPassword from '../views/policyholder/ForgotPassword.vue';
import EmailVerification from '../views/policyholder/checks/EmailVerification.vue';
import IdConversion from '../views/policyholder/checks/IdConversion.vue';
import ResetPassword from '../views/policyholder/checks/ResetPassword.vue';
import AccountSummary from '../views/policyholder/AccountSummary.vue';
import PolicyDetails from '../views/policyholder/PolicyDetails.vue';
import AccountManagement from '../views/policyholder/AccountManagement.vue';
import AccountMemberships from '../views/policyholder/management/Memberships.vue';
import EmailVerificationComplete from '../views/policyholder/management/EmailVerificationComplete.vue';
import ChangePaperless from '../views/policyholder/policy/ChangePolicyPaperless.vue';
import QuoteLayout from '../views/ratequote/QuoteLayout.vue';
import AutoQuoteEntry from '../views/ratequote/AutoQuoteEntry.vue';
import AutoQuoteAboutYou from '../views/ratequote/AboutYou.vue';
import AutoQuoteVehicles from '../views/ratequote/Vehicles.vue';
import AutoQuoteDrivers from '../views/ratequote/Drivers.vue';
import AutoQuoteCoverages from '../views/ratequote/Coverages.vue';
import AutoQuoteDisclaimer from '../views/ratequote/Disclaimer.vue';
import AutoQuoteSummary from '../views/ratequote/Summary.vue';

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
Vue.use(VueRouter);

const routes = [
  {
    path: '/payments/:policyType/:policyNumber',
    name: 'loggedInPayment',
    components: {
      content: LoggedInPayment,
    },
    props: { content: true },
  },
  {
    path: '/',
    components: {
      header: Header,
      notification: HeaderNotification,
      content: Content,
      footer: Footer,
    },
    children: [
      {
        path: '',
        name: 'home',
        components: {
          default: HomeHeader,
        },
      },
      {
        path: 'about-us',
        name: 'about',
        components: {
          default: AboutUs,
        },
      },
      {
        path: 'blog',
        name: 'blog',
        components: {
          default: BlogMain,
        },
      },
      {
        path: 'blog/listing',
        name: 'blogListing',
        components: {
          default: BlogListing,
        },
      },
      {
        path: 'blog/category/:category',
        name: 'blogFeedCategory',
        components: {
          default: BlogMain,
        },
      },
      {
        path: 'blog/category/:category/:subcategory',
        name: 'blogFeedSubCategory',
        components: {
          default: BlogMain,
        },
      },
      {
        path: 'blog/:category/:title',
        name: 'blogPost',
        components: {
          default: BlogPost,
        },
      },
      {
        path: 'blog/:category/:subcategory/:title',
        name: 'blogPostFull',
        components: {
          default: BlogPost,
        },
      },
      {
        path: 'claims',
        name: 'claims',
        components: {
          default: ClaimMain,
        },
        alias: [
          'claims/file-a-claim',
        ],
      },
      {
        path: 'claims/:number/:type',
        name: 'claimsReport',
        components: {
          default: ClaimReport,
        },
      },
      {
        path: 'claims/confirm',
        name: 'claimsReportConfirm',
        components: {
          default: ClaimReportConfirm,
        },
      },
      {
        path: 'contact-us',
        name: 'contact',
        components: {
          default: ContactUs,
        },
      },
      {
        path: 'careers',
        name: 'careers',
        components: {
          default: Careers,
        },
      },
      {
        path: 'careers/find-a-sales-manager',
        name: 'salesManager',
        components: {
          default: FindASalesManager,
        },
      },
      {
        path: 'dmca',
        name: 'dmca',
        components: {
          default: DMCA,
        },
      },
      {
        path: 'privacy',
        name: 'privacy',
        components: {
          default: JointPrivacy,
        },
      },
      {
        path: 'terms-conditions',
        name: 'termsConditions',
        components: {
          default: TermsConditions,
        },
      },
      {
        path: 'required-notices',
        name: 'requiredNotices',
        components: {
          default: RequiredNotices,
        },
      },
      {
        path: 'search',
        name: 'searchResults',
        components: {
          default: Search,
        },
      },
      {
        path: 'payments/options',
        name: 'paymentOptions',
        components: {
          default: PaymentOptions,
        },
        alias: [
          'payments',
        ],
      },
      {
        path: 'county/listing',
        name: 'countyListing',
        components: {
          default: CountyListing,
        },
      },
      {
        path: 'county/lookup',
        name: 'countyLookup',
        components: {
          default: FindAnAgent,
        },
        alias: [
          'county',
          'county/locate',
          'county/undefined',
          'agent/locate',
          'agent/lookup',
          'insurance/agent',
        ],
      },
      {
        path: 'county/details/:county/:branch?',
        components: {
          default: CountyDetails,
        },
        alias: [
          'agent/locate/:county',
          'county/locate/:county',
          'county/locate/:county/:branch',
        ],
      },
      {
        path: 'insurance/agent/listing',
        name: 'agentListing',
        components: {
          default: AgentListing,
        },
      },
      {
        path: 'insurance/agent/:county/:name/:id(\\d+)',
        name: 'insuranceAgentFull',
        components: {
          default: AgentDetails,
        },
        alias: [
          'insurance/agent/:county/:id(\\d+)/:name',
        ],
      },
      {
        path: 'insurance/agent/:id(\\d+)',
        name: 'insuranceAgent',
        components: {
          default: AgentDetails,
        },
        alias: [
          'agent/details/:id',
          'agent/registration/:id',
          'agentreg/:id',
        ],
      },
      {
        path: 'insurance/agent/:name',
        components: {
          default: AgentDetails,
        },
      },
      {
        path: 'services/personal/health',
        name: 'insuranceHeath',
        components: {
          default: Services,
        },
      },
      {
        path: 'insurance',
        name: 'insuranceAlias',
        components: {
          default: Services,
        },
        alias: [
          'services',
        ],
      },
      {
        path: 'insurance/:type',
        name: 'insurance',
        components: {
          default: Services,
        },
        alias: [
          'services/:type',
        ],
      },
      {
        path: 'insurance/:parent/:type',
        name: 'insuranceSubType',
        components: {
          default: Services,
        },
        alias: [
          'services/:parent/:type',
        ],
      },
      {
        path: 'insurance/:parent/:type/:detail',
        name: 'insuranceDetail',
        components: {
          default: Services,
        },
        alias: [
          'services/:parent/:type/:detail',
        ],
      },
      {
        path: 'quotes/:type',
        components: {
          default: QuoteLayout,
        },
        children: [
          {
            path: '',
            name: 'quoteEntry',
            components: {
              default: AutoQuoteEntry,
            },
          },
          {
            path: 'about-you',
            name: 'quoteAboutYou',
            components: {
              default: AutoQuoteAboutYou,
            },
          },
          {
            path: 'vehicles',
            name: 'quoteVehicle',
            components: {
              default: AutoQuoteVehicles,
            },
          },
          {
            path: 'vehicles/:id',
            name: 'quoteVehicleId',
            components: {
              default: AutoQuoteVehicles,
            },
          },
          {
            path: 'drivers',
            name: 'quoteDriver',
            components: {
              default: AutoQuoteDrivers,
            },
          },
          {
            path: 'drivers/:id',
            name: 'quoteDriverId',
            components: {
              default: AutoQuoteDrivers,
            },
          },
          {
            path: 'coverage',
            name: 'quoteCoverage',
            components: {
              default: AutoQuoteCoverages,
            },
          },
          {
            path: 'disclaimer',
            name: 'quoteDisclaimer',
            components: {
              default: AutoQuoteDisclaimer,
            },
          },
          {
            path: 'summary',
            name: 'quoteSummary',
            components: {
              default: AutoQuoteSummary,
            },
          },
        ],
      },
      {
        path: 'policyholder',
        components: {
          default: PolicyHolderLayout,
        },
        children: [
          {
            path: '',
            name: 'policyholderLogin',
            components: {
              default: Login,
            },
            alias: [
              'login',
              'account-login',
            ],
          },
          {
            path: 'agent-login',
            name: 'policyholderAgentAccess',
            components: {
              default: AgentAccess,
            },
          },
          {
            path: 'register',
            name: 'policyholderRegister',
            components: {
              default: Register,
            },
            alias: [
              'registration',
            ],
          },
          {
            path: 'verify-email',
            name: 'policyholderEmailVerification',
            components: {
              default: EmailVerification,
            },
          },
          {
            path: 'forgot-password',
            name: 'policyholderForgotPassword',
            components: {
              default: ForgotPassword,
            },
          },
          {
            path: 'id-conversion',
            name: 'policyholderIdConversion',
            components: {
              default: IdConversion,
            },
          },
          {
            path: 'account-summary',
            name: 'policyholderAccountSummary',
            components: {
              default: AccountSummary,
            },
          },
          {
            path: 'policy/paperless',
            name: 'policyholderChangePaperless',
            components: {
              default: ChangePaperless,
            },
          },
          {
            path: 'policy/:policyType/:policyNumber',
            name: 'policyholderPolicyDetail',
            components: {
              default: PolicyDetails,
            },
            props: { default: true },
          },
          {
            path: 'claims/file',
            name: 'policyholderClaims',
            components: {
              default: ClaimMain,
            },
          },
          {
            path: 'claims/file/:number/:type',
            name: 'policyholderClaimsReport',
            components: {
              default: ClaimReport,
            },
          },
          {
            path: 'claims/view',
            name: 'policyholderViewClaims',
            components: {
              default: ViewClaim,
            },
          },
          {
            path: 'manage-account',
            name: 'policyholderAccountManagement',
            components: {
              default: AccountManagement,
            },
          },
          {
            path: 'manage-account/password-reset',
            name: 'policyholderResetPassword',
            components: {
              default: ResetPassword,
            },
          },
          {
            path: 'manage-account/validate-email',
            name: 'policyholderEmailVerificationComplete',
            components: {
              default: EmailVerificationComplete,
            },
          },
          {
            path: 'membership/:changeType',
            name: 'policyholderManageMemberships',
            components: {
              default: AccountMemberships,
            },
            props: { default: true },
          },
          {
            path: 'payments/premium-finance',
            name: 'policyholderPFPayment',
            components: {
              default: PremiumFinancePayment,
            },
          },
          {
            path: 'payments/account-bill/enroll',
            name: 'policyholderAccountBill',
            components: {
              default: AccountBill,
            },
            alias: [
              'payments/account-bill',
            ],
          },
          {
            path: 'payments/ebill/confirm',
            name: 'policyholderChangeEbill',
            components: {
              default: ChangeEbill,
            },
          },
          {
            path: 'payments/recurring-payments/:changeType',
            name: 'policyholderRecurringPayments',
            components: {
              default: RecurringPayments,
            },
            props: { default: true },
          },
        ],
      },
       {
        path: '*',
        components: {
          default: PageNotFound,
        },
       },
    ],
  },
  // {
  //  path: '/contact',
  //  name: 'contact',
  //  // route level code-splitting
  //  // this generates a separate chunk (about.[hash].js) for this route
  //  // which is lazy-loaded when the route is visited.
  //  component: () => import(/* webpackChunkName: "about" */ '../views/content/AboutUs.vue'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        // , offset: { x: 0, y: 10 }
      };
    }

    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
});

export default router;
