<template>
  <div id="policyholder-layout"
       class="policyholder-layout">
    <policyholder-header></policyholder-header>
    <router-view></router-view>
  </div>
</template>
<script>
  import PolicyholderHeader from './header/PolicyHolderHeader.vue';

export default {
  name: 'PolicyHolderLayout',
  components: {
    PolicyholderHeader,
  },
};
</script>
<style lang="scss">
  .policyholder-layout{
    background-color: $tfb-off-white;
  }
</style>
