<template>
  <v-card class="fill-height tfb-claim-numbers-card">
    <v-card-title>
      <p class="tfb-title-text font-weight-bold text-uppercase text-blue">
        Claims Assistance
      </p>
    </v-card-title>
    <v-card-text>
      <v-container fluid
                   class="pa-0">
        <v-row>
          <v-col cols="12">
            <span class="font-weight-bold text-black">
              {{ claimsReportingCenter.title }}:
            </span>
            <a :href="claimsReportingCenter.phoneLink">{{ claimsReportingCenter.phoneDisplay }}</a>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="font-weight-bold text-black">
              {{ wacoClaims.title }}:
            </span>
            <a :href="wacoClaims.phoneLink">{{ wacoClaims.phoneDisplay }}</a>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="font-weight-bold text-black">
              {{ roadsideAssistance.title }}:
            </span>
            <a :href="roadsideAssistance.phoneLink">{{ roadsideAssistance.phoneDisplay }}</a>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="font-weight-bold text-black">
              {{ wrightFlood.title }}:
            </span>
            <a :href="wrightFlood.phoneLink">{{ wrightFlood.phoneDisplay }}</a>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="font-weight-bold text-black">
              {{ texasWindstorm.title }}:
            </span>
            <a :href="texasWindstorm.phoneLink">{{ texasWindstorm.phoneDisplay }}</a>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
  import tfbDataMixin from '@/mixins/tfbData';

  export default {
    name: 'ClaimsNumbers',
    mixins: [tfbDataMixin],
  };
</script>
<style lang="scss">

  .tfb-claim-numbers-card {
    max-width: 600px !important;
    min-height: 250px;
    margin-left: auto;
    margin-right: auto;
  }

    .tfb-claim-numbers-card .v-card__title {
      padding-bottom: 5px;
    }
</style>
