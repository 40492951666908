<template>
  <v-container class="align-center mt-6">
    <img class="tfb-page-divider mx-auto"
         v-bind:src="imageRootPath + 'divider-star.png'"
         alt="Page Divider" />
  </v-container>
</template>
<script>
  export default {
    name: 'StarDivider',
    computed: {
      imageRootPath() {
        return `${process.env.VUE_APP_ROOT_URI}/publishingimages/`;
      },
    },
  };
</script>
<style lang="scss">
  .tfb-page-divider {
    width: 100%;
    max-width: 992px;
  }
</style>
