<template>
  <v-fab-transition>
    <v-btn v-show="showChatIndicator"
           :style="{ bottom: '5px' }"
           aria-label="Live Chat"
           bottom
           color="background-blue"
           x-large
           fixed
           right
           title="Live Chat"
           @click="showChatWindow"
           class="no-print tfb-btn-chat pa-4">
      <v-icon left>mdi-chat-outline</v-icon>
      Live Chat
    </v-btn>
  </v-fab-transition>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'LiveChat',
    mixins: [tfbHelperMixin],
    data() {
      return {
        chatEnabledIndicator: false,
      };
    },
    computed: {
      showChatIndicator() {
        return this.isLoggedIn && this.$store.state.app.liveChatEnabled === 'Y';
      },
    },
    methods: {
      showChatWindow() {
        this.$router.push({ hash: '' });
        this.$vuetify.goTo(0);
      },
    },
  };
</script>

<style lang="scss">
  .tfb-btn-chat {
    border-radius: 0.25rem;
    z-index: 4;
  }
</style>
