<template>
  <v-container fluid
               id="quote-layout"
               class="background-darker-blue quote-layout">
    <v-row no-gutters>
      <v-col cols="12"
             :sm="isHomePage ? 12 : 3"
             order="1"
             order-sm="2"
             class="background-lightest-gray no-print"
             v-if="!isHomePage">
        <progress-sidebar></progress-sidebar>
      </v-col>
      <v-col cols="12"
             :sm="isHomePage ? 12 : 9"
             order="2"
             order-sm="1">
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import ProgressSidebar from './sidebar/ProgressSidebar.vue';

  export default {
    name: 'QuoteLayout',
    components: {
      ProgressSidebar,
    },
    computed: {
      agreementNumber() {
        try {
          return this.$store.getters['quotes/agreementNumber'];
        } catch (error) {
          return '0';
        }
      },
      isHomePage() {
        try {
          return this.$route.name === 'quoteEntry';
        } catch (error) {
          return false;
        }
      },
    },
  };
</script>
<style lang="scss">

  .quote-layout {
    padding: 0px;
    margin: 0px;
  }
</style>
