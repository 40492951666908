<template>
  <v-row v-if="showClaimPoint">
    <v-col cols="12"
           class="align-center text-red">
      If damage is limited to glass, <a v-on:click.prevent="redirectToClaimPoint">click here</a> to report a glass-only claim.
      <br/><br/>
    </v-col>
  </v-row>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import { insuredAccessOnlyErrorMessage } from '@/mixins/errorMessages';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'ClaimPointLink',
    props: {
      showClaimPoint: {
        type: Boolean,
        required: true,
      },
      policyNumber: {
        type: String,
        required: true,
      },
      insuredFirstName: {
        type: String,
        required: false,
      },
      insuredLastName: {
        type: String,
        required: false,
      },
      insuredName: {
        type: String,
        required: false,
      },
      dateOfLoss: {
        type: String,
        required: true,
      },
    },
    computed: {
      claimPointUrl() {
        try {
          return this.$store.getters['app/claimPointUrl'];
        } catch (error) {
          return null;
        }
      },
    },
    mixins: [tfbHelperMixin],
    methods: {
      redirectToClaimPoint() {
        dispatch('app/clearErrorMessage');
        dispatch('app/setIsLoading', true);
        if (!this.isMemberRole) {
          dispatch('app/setErrorMessage', insuredAccessOnlyErrorMessage);
          dispatch('app/setIsLoading', false);
        } else {
          let claimParams;
          const form = document.createElement('form');
          form.method = 'post';
          form.action = this.claimPointUrl;
          form.name = 'lynxForm';
          form.target = '_blank';
          dispatch('claims/getClaimPointSignedXml', {
            sRecipient: this.claimPointUrl,
            sPolicyNumber: this.policyNumber,
            sDateOfLoss: this.dateOfLoss,
            sLastName: this.insuredLastName,
            sFirstName: this.insuredFirstName,
            sInsuredName: this.insuredName,
            sTfbId: this.trackingId,
          })
            .then((signatureResponse) => {
              if (signatureResponse && signatureResponse !== '') {
                try {
                  claimParams = [
                    { name: 'hdnSignedXml', value: signatureResponse },
                  ];

                  for (let key = 0; key < claimParams.length; key += 1) {
                    // console.log(encryptParams[key]);
                    const hiddenField = document.createElement('input');
                    hiddenField.type = 'hidden';
                    hiddenField.name = claimParams[key].name;
                    hiddenField.value = claimParams[key].value;

                    form.appendChild(hiddenField);
                  }

                  document.body.appendChild(form);
                  form.submit();
                  dispatch('app/setIsLoading', false);
                  setTimeout(() => {
                    document.body.removeChild(form); // On modern browsers you can use `form.remove();`
                  }, 100);
                } catch (error) {
                  dispatch('app/setErrorMessage', 'Error redirecting to ClaimPoint site. Please try again.');
                  this.$store.dispatch('app/logToULS', {
                    logLevel: 'Error',
                    logMessage: error,
                    fileName: 'ClaimPointLink.vue',
                    methodName: 'redirect-Catch',
                    trackerId: this.trackingId,
                  });
                  this.$store.dispatch('app/setIsLoading', false);
                }
              } else {
                dispatch('app/setErrorMessage', 'Unable to redirect to ClaimPoint site. Please try again.');
                dispatch('app/setIsLoading', false);
              }
            })
            .catch((error) => {
              this.$store.dispatch('app/logToULS', {
                logLevel: 'Error',
                logMessage: error,
                fileName: 'ClaimPointLink.vue',
                methodName: 'getSignedXml-Catch',
                trackerId: this.trackingId,
              });
              if (error.toString().indexOf('Request failed with status code 401') >= 0) {
                dispatch('app/setErrorMessage', 'Session is no longer valid. Please close this window and log in again.');
              } else {
                dispatch('app/setErrorMessage', error.toString());
              }
              dispatch('app/setIsLoading', false);
            });
        }
      },
    },
  };
</script>
<style lang="scss">

</style>
