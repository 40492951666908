<template>
  <div class="background-white pa-5">
    <v-container fluid
                 class="tfb-container">
      <v-row>
        <v-col cols="12">
          <h2 class="tfb-title-text-bold text-blue text-center">
            Get the Drive'n Save App Today and Start Saving!
          </h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"
               sm="6"
               order="last"
               order-sm="first">
          <img alt="Drive'n Save"
               id="drive-n-save-header-image"
               v-bind:src="driveNSaveHeader" />
        </v-col>
        <v-col cols="12"
               sm="6"
               order="first"
               order-sm="last"
               class="pl-sm-6">
          <p class="text-body-1">
            An initial discount is available for participants who enroll in the program.
            Additional discounts are available based on the driving score or participants.
            Driving score is calculated on factors including:
          </p>
          <ul class="text-body-1">
            <li>Low mileage</li>
            <li>Time of day</li>
            <li>Speed</li>
            <li>Sudden breaking</li>
            <li>Distracted driving (cell phone use)</li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  export default {
    name: 'DriveNSaveSection',
    computed: {
      driveNSaveHeader() {
        return `${process.env.VUE_APP_ROOT_URI}/publishingimages/drive-n-save-header.jpg`;
      },
    },
  };
</script>
<style lang="scss">

</style>
