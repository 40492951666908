<template>
  <div class="background-darker-blue py-12">
    <h2 class="tfb-title-text-large text-center">Drivers</h2>
    <v-container fluid
                 class="tfb-smallest-container tfb-quote-box pa-5 my-8">
      <v-row v-show="driverList && driverList.length > 1 && driver && driver != null && !showSpouseRequired && !isPrimary">
        <v-col cols="12">
          <v-btn @click="onDelete()"
                 :disabled="formDisabled"
                 class="tfb-button-min background-red">
            Remove Driver
          </v-btn>
          <br /><br />
        </v-col>
      </v-row>
      <v-row v-show="showSpouseRequired"
             class="spouse-notification"
             role="alert">
        <v-col cols="12"
               class="spouse-notification-notice">
          <h5>Spouse is required to be added as an additional driver, even if they do not drive.</h5>
        </v-col>
      </v-row>
      <required-fields-row></required-fields-row>
      <ValidationObserver ref="obv" v-slot="{ passes }">
        <v-form ref="driverForm"
                v-if="driver && driver != null"
                @submit.stop.prevent="passes(onSubmit)">
          <!-- First Name -->
          <v-row>
            <v-col cols="12">
              <text-input autocapitalize="words"
                          autocomplete="shipping given-name"
                          :disabled="isPrimary"
                          label="First Name"
                          minlength="2"
                          maxlength="25"
                          name="fname"
                          rules="required|min:2|max:25|name"
                          v-model="driver.FirstName"></text-input>
            </v-col>
          </v-row>
          <!-- Last Name -->
          <v-row>
            <v-col cols="12">
              <text-input autocapitalize="words"
                          autocomplete="shipping given-name"
                          :disabled="isPrimary"
                          label="Last Name"
                          minlength="2"
                          maxlength="25"
                          name="lname"
                          rules="required|min:2|max:25|name"
                          v-model="driver.LastName"></text-input>
            </v-col>
          </v-row>
          <!-- Gender -->
          <v-row>
            <v-col cols="12">
              <select-input autocomplete="sex"
                            rules="required"
                            label="Gender"
                            :items="genderOptions"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="driver.Gender"
                            :disabled="isPrimary"></select-input>
            </v-col>
          </v-row>
          <!-- Date of Birth -->
          <v-row>
            <v-col cols="12">
              <v-menu ref="birthDateMenu"
                      v-model="birthDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider tag="div"
                                      :bails="false"
                                      rules="required"
                                      name="Date of Birth"
                                      v-slot="{ errors, valid }"
                                      vid="birth">
                    <v-text-field outlined
                                  required
                                  readonly
                                  hide-details="auto"
                                  :error-messages="errors"
                                  :success="valid"
                                  v-model="dateOfBirth"
                                  v-mask="'##/##/####'"
                                  label="Date of Birth"
                                  @change="driver.DateOfBirth = parseDate(dateOfBirth)"
                                  v-bind="attrs"
                                  v-on="on">
                      <template v-slot:append>
                        <v-icon>mdi-calendar</v-icon>
                        <v-icon>mdi-asterisk</v-icon>
                      </template>
                    </v-text-field>
                  </ValidationProvider>
                </template>
                <v-date-picker ref="picker"
                               v-model="driver.DateOfBirth"
                               :active-picker.sync="dateOfBirthActivePicker"
                               color="background-blue"
                               no-title
                               @input="birthDateMenu = false"
                               :max="maxDateOfBirth"
                               min="1920-01-01"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <!-- Relation to you -->
          <v-row v-show="!isPrimary">
            <v-col cols="12">
              <select-input rules="required"
                            label="Relation to You"
                            :items="relationshipOptions"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="driver.RelationshipToInsuredCode"
                            :disabled="isPrimarySpouse && driver.RelationshipToInsuredCode && driver.RelationshipToInsuredCode !== null && driver.RelationshipToInsuredCode !== ''"></select-input>
            </v-col>
          </v-row>
          <!-- Marital Status -->
          <v-row>
            <v-col cols="12">
              <select-input rules="required|alpha"
                            label="Marital Status"
                            :items="maritalOptions"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="driver.MaritalStatusCode"
                            :disabled="isPrimarySpouse && driver.MaritalStatusCode && driver.MaritalStatusCode !== null && driver.MaritalStatusCode !== ''"></select-input>
            </v-col>
          </v-row>
          <!-- Occupation -->
          <v-row>
            <v-col cols="12">
              <select-input rules="required|numeric"
                            label="Occupation"
                            :items="occupationOptions"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="driver.OccupationCode"
                            vid="occupation"></select-input>
            </v-col>
          </v-row>
          <!-- Academic Achievement -->
          <v-row v-show="driver.OccupationCode === '24'">
            <v-col cols="12">
              <select-input rules="required_if:occupation,24|alpha"
                            label="Grade Point Average"
                            :items="gpaOptions"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="driver.AcademicAchievement"
                            vid="gpa"></select-input>
            </v-col>
          </v-row>
          <!-- Designated Insured -->
          <v-row>
            <v-col cols="12">
              <select-input rules="required|alpha"
                            label="Select for Death Indemnity"
                            :items="yesNoOptions"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="driver.IncludeAsDI"
                            name="Death Indemnity"></select-input>
            </v-col>
          </v-row>
          <!-- Current driver's license -->
          <v-row>
            <v-col cols="12">
              <select-input rules="required|alpha"
                            label="Current Driver's License?"
                            :items="yesNoOptions"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="driver.OperatorStatusIndicator"
                            vid="currentLicense"
                            name="Current License"></select-input>
            </v-col>
          </v-row>
          <!-- Reason for no license -->
          <v-row v-show="driver.OperatorStatusIndicator === 'N'">
            <v-col cols="12">
              <select-input rules="required_if:currentLicense,N|alpha"
                            label="Reason for No Current Driver's License"
                            :items="noLicenseOptions"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="driver.OperatorStatusCode"
                            vid="licenseReason"></select-input>
            </v-col>
          </v-row>
          <!-- Drivers license number -->
          <v-row v-show="driver.OperatorStatusIndicator === 'Y'">
            <v-col cols="12">
              <text-input rules="required_if:currentLicense,Y|min:4|max:18"
                          label="Driver's License Number"
                          autocapitalize="characters"
                          minlength="4"
                          maxlength="18"
                          v-model="driver.DriversLicenseNumber"></text-input>
            </v-col>
          </v-row>
          <!-- Issuing State -->
          <v-row v-show="driver.OperatorStatusIndicator === 'Y'">
            <v-col cols="12">
              <select-input rules="required_if:currentLicense,Y|alpha"
                            label="Issuing State"
                            :items="stateOptions"
                            item-text="StateName"
                            item-value="StateCode"
                            v-model="driver.DriversLicenseState"
                            vid="licenseState"></select-input>
            </v-col>
          </v-row>
          <!-- Primary vehicle -->
          <v-row v-show="driver.OperatorStatusIndicator === 'Y'">
            <v-col cols="12">
              <select-input rules="required_if:currentLicense,Y|numeric"
                            label="Vehicle Primarily Driven"
                            :items="vehicleList"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="driver.VehicleNumber"></select-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h4 class="header-text text-red">Answer the following for {{driver.FirstName}}...</h4>
            </v-col>
          </v-row>
          <!-- Accidents -->
          <v-row>
            <v-col cols="12"
                   md="8">
              <label>Had any accidents in the last 3 years?</label>
            </v-col>
            <v-col cols="12"
                   md="4">
              <radio-group-input rules="required"
                                 name="number of accidents"
                                 :options="yesNoOptions"
                                 v-model="driver.HasAccident"></radio-group-input>
            </v-col>
          </v-row>
          <!-- Accident Information -->
          <v-row v-if="driver.HasAccident === 'Y' && driver.Accidents.length <= 0">
            <v-col cols="12">
              At least one accident is required
            </v-col>
          </v-row>
          <div v-if="driver.HasAccident === 'Y' && driver.Accidents.length > 0">
            <accident-details v-for="accident in driver.Accidents"
                              v-bind:key="accident.Id"
                              :accident="accident"
                              v-bind.sync="accident"
                              v-on:remove-accident="removeAccident($event)"></accident-details>
          </div>
          <v-row v-if="driver.HasAccident === 'Y' && driver.Accidents.length <= 3">
            <v-col cols="12">
              <v-btn @click="addAccident()"
                     class="float-right tfb-button-min background-red">
                Add Accident
              </v-btn>
            </v-col>
          </v-row>
          <!-- Violations -->
          <v-row>
            <v-col cols="12"
                   md="8">
              <label>Had any moving violations in the last 3 years?</label>
            </v-col>
            <v-col cols="12"
                   md="4">
              <radio-group-input rules="required"
                                 name="number of violations"
                                 :options="yesNoOptions"
                                 v-model="driver.HasViolation"></radio-group-input>
            </v-col>
          </v-row>
          <!-- Violation Information -->
          <v-row v-if="driver.HasViolation === 'Y' && driver.Violations.length <= 0">
            <v-col cols="12">
              At least one violation is required
            </v-col>
          </v-row>
          <div v-if="driver.HasViolation === 'Y' && driver.Violations.length > 0">
            <violation-details v-for="violation in driver.Violations"
                               v-bind:key="violation.Id"
                               :violation="violation"
                               v-bind.sync="violation"
                               v-on:remove-violation="removeViolation($event)"></violation-details>
          </div>
          <v-row v-if="driver.HasViolation === 'Y' && driver.Violations.length <= 3">
            <v-col cols="12">
              <v-btn @click="addViolation()"
                     class="float-right tfb-button-min background-red">
                Add Violation
              </v-btn>
            </v-col>
          </v-row>
          <!-- Driver Training 14033 -->
          <v-row v-if="showDriverTraining">
            <v-col cols="12"
                   md="8">
              <label>Taken a driver training course within the last 3 years?</label>
            </v-col>
            <v-col cols="12"
                   md="4">
              <radio-group-input rules="required"
                                 name="driver training"
                                 :options="yesNoOptions"
                                 v-model="driver.DriverTraining"></radio-group-input>
            </v-col>
          </v-row>
          <!-- Defensive Driving 14025 -->
          <v-row v-if="effectiveDateCheck">
            <v-col cols="12"
                   md="8">
              <label>Taken a defensive driving course within the last 3 years?</label>
            </v-col>
            <v-col cols="12"
                   md="4">
              <radio-group-input rules="required"
                                 name="defensive driving"
                                 :options="yesNoOptions"
                                 v-model="driver.DefensiveDriving"></radio-group-input>
            </v-col>
          </v-row>
          <!-- Have TFB Property Policy  -->
          <v-row>
            <v-col cols="12"
                   md="8">
              <label>Currently have or plan to purchase a Homeowners or Texas AgAdvantage policy with us?</label>
            </v-col>
            <v-col cols="12"
                   md="4">
              <radio-group-input rules="required"
                                 name="current Homeowners or Texas AgAdvantage policy"
                                 :options="hasTfbOptions"
                                 v-model="quoteDetails.HasCurrentTFBPolicy"
                                 vid="hasPolicy"></radio-group-input>
            </v-col>
          </v-row>
          <!-- Current TFB Member Number -->
          <v-row v-if="quoteDetails.HasCurrentTFBPolicy === 'P'">
            <v-col cols="12">
              <text-input rules="min:4|max:6"
                          label="Current Member Number"
                          autocapitalize="characters"
                          minlength="4"
                          maxlength="6"
                          v-model="driver.MemberNumber"></text-input>
            </v-col>
          </v-row>
          <!-- Drive N' Save Enrollment -->
          <v-row v-if="driveNSaveCheck === 'Y'">
            <v-col cols="12"
                   md="8">
              <label>Are you interested in using our Drive&rsquo;n Save mobile app?<v-icon x-small>mdi-numeric-1</v-icon></label>
            </v-col>
            <v-col cols="12"
                   md="4">
              <radio-group-input rules="required"
                                 name="Drive N Save"
                                 :options="yesNoOptions"
                                 v-model="driver.DriveNSaveEnrollment"></radio-group-input>
            </v-col>
            <v-col cols="12" class="tfb-header-text-small">
              <v-icon x-small>mdi-numeric-1</v-icon> Drive&rsquo;n Save is a mobile app that rewards you for good driving behavior with a discount on your policy premium.
              Your answer to this question helps us create your custom quote.
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <!-- Number of Claims  -->
              <select-input rules="required|numeric"
                            label="How many Auto claims were made in the last 3 years?"
                            name="number of claims"
                            :items="claimCountOptions"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="driver.ClaimCount"></select-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn @click="passes(onAddSubmit)"
                     :disabled="formDisabled"
                     class="tfb-button-min background-red float-right">
                Add Another Driver
              </v-btn>
              <br /><br />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   sm="6"
                   order="2"
                   order-sm="1">
              <v-btn @click="onBack"
                     v-bind:class="[ !isMobile ? 'float-right' : '', 'tfb-button-min', 'background-red' ]">
                <v-icon>mdi-chevron-left</v-icon>
                Go Back
              </v-btn>
            </v-col>
            <v-col cols="12"
                   sm="6"
                   order="1"
                   order-sm="2">
              <v-btn type="submit"
                     :disabled="formDisabled"
                     class="tfb-button-min background-red float-right">
                Continue
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
    </v-container>
  </div>
</template>
<script>
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { mask } from 'vue-the-mask';
  import {
    subYears, compareAsc, differenceInYears, parseISO,
  } from 'date-fns';
  import tfbHelperMixin from '@/mixins/helpers';
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbMetaMixin from '@/mixins/metaInfo';
  import { genericErrorMessage } from '@/mixins/errorMessages';
  import RequiredFieldsRow from '@/views/layout/components/RequiredFieldsRow.vue';
  import AccidentDetails from './components/Accident.vue';
  import ViolationDetails from './components/Violation.vue';
/* eslint prefer-destructuring: 0 */
/* eslint object-shorthand: 0 */
/* eslint no-mixed-operators: 0 */
/* eslint no-param-reassign: 0 */
  /* eslint no-unused-vars: 0 */

  export default {
    name: 'AutoQuoteDrivers',
    mixins: [tfbHelperMixin, tfbDataMixin, tfbMetaMixin],
    components: {
      ValidationObserver,
      ValidationProvider,
      RequiredFieldsRow,
      AccidentDetails,
      ViolationDetails,
    },
    directives: { mask },
    data() {
      return {
        formDisabled: false,
        quoteDetails: null,
        driver: null,
        driverList: null,
        vehicleList: null,
        stateOptions: null,
        relationshipOptions: null,
        occupationOptions: null,
        claimCountOptions: ['0', '1', '2', '3', '4', '5', '6', '7'],
        genderOptions: [
          { Id: 0, DisplayText: 'Female', Value: 'F' },
          { Id: 1, DisplayText: 'Male', Value: 'M' },
        ],
        gpaOptions: [
          { Id: 0, DisplayText: '3.0 or greater', Value: 'Y' },
          { Id: 1, DisplayText: '2.9 or lower', Value: 'N' },
        ],
        maritalOptions: [
          { Id: 0, DisplayText: 'Single', Value: 'S' },
          { Id: 1, DisplayText: 'Married', Value: 'M' },
          { Id: 2, DisplayText: 'Divorced', Value: 'D' },
          { Id: 3, DisplayText: 'Legally Separated', Value: 'X' },
          { Id: 4, DisplayText: 'Widowed', Value: 'W' },
        ],
        noLicenseOptions: [
          { Id: 0, DisplayText: 'Deployed Military', Value: 'M' },
          { Id: 1, DisplayText: 'Non Driver', Value: 'N' },
          { Id: 2, DisplayText: 'Permit Driver', Value: 'P' },
          { Id: 3, DisplayText: 'Excluded Driver', Value: 'E' },
        ],
        yesNoOptions: [{ Id: 0, DisplayText: 'Yes', Value: 'Y' }, { Id: 1, DisplayText: 'No', Value: 'N' }],
        hasTfbOptions: [{ Id: 0, DisplayText: 'Yes', Value: 'P' }, { Id: 1, DisplayText: 'No', Value: 'N' }],
        otherOption: { DisplayText: 'Other', Value: '0' },
        dateOfBirthActivePicker: null,
        birthDateMenu: false,
        isMobile: this.$vuetify.breakpoint.smAndDown,
        showSpouseRequired: false,
      };
    },
    computed: {
      metaDescription() {
        return 'Looking for an auto insurance quote? Use our online tool to learn how Texas Farm Bureau Insurance can help provide coverage for your precious ride.';
      },
      metaPageTitle() {
        return 'Drivers | Auto Rate Quote';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/quotes/auto/drivers`;
      },
      agreementNumber() {
        try {
          return this.$store.getters['quotes/agreementNumber'];
        } catch (error) {
          return '';
        }
      },
      subAgreementNumber() {
        try {
          return this.$store.getters['quotes/subAgreementNumber'];
        } catch (error) {
          return '';
        }
      },
      effectiveDateCheck() {
        try {
          const foundEffectiveDate = this.$store.getters['quotes/effectiveDate'];
          const result = compareAsc(this.parseDateAsDate(foundEffectiveDate), this.parseDateAsDate('2022-01-01'));

          // Is the effective date before the maximum effective date?
          return result < 0;
        } catch (error) {
          return false;
        }
      },
      quoteData() {
        try {
          return this.$store.getters['quotes/quote'];
        } catch (error) {
          return null;
        }
      },
      driveNSaveCheck() {
        if (!this.$store.state.app.driveNSaveEnabled) {
          this.$store.dispatch('app/setVisitor');
        }
        return this.$store.state.app.driveNSaveEnabled;
      },
      isPrimary() {
        return this.driver && this.driver.RelationshipToInsuredCode === '1';
      },
      isPrimarySpouse() {
        return this.driver && this.driver.RelationshipToInsuredCode === '2';
      },
      spouseRequired() {
        if (this.quoteDetails.OwnershipCode === 'I' && this.driver.RelationshipToInsuredCode === '1' && this.driver.MaritalStatusCode === 'M') {
          // Required to put spouse as a driver; check if there are already other drivers
          if (this.driverList.length > 1) {
            // See if the spouse has already been added
            for (let d = 0; d < this.driverList.length; d += 1) {
              const foundDriver = this.driverList[d];

              // Is this the Spouse?
              if (foundDriver && foundDriver.RelationshipToInsuredCode === '2') {
                // Already have the spouse; no need to create it again
                return false;
              }
            }

            // None of the existing drivers are the spouse; need to add
            return true;
          }

          // Only one driver, so we need to add the spouse
          return true;
        }

        // Not required to put spouse as driver
        return false;
      },
      maxDateOfBirth() {
        return this.parseDate(subYears(new Date(), 14).toISOString());
      },
      dateOfBirth() {
        return this.formatDate(this.driver.DateOfBirth);
      },
      showDriverTraining() {
        if (this.driver.DateOfBirth && this.driver.DateOfBirth !== null && this.driver.DateOfBirth !== '' && this.driver.DateOfBirth.length === 10) {
          // See if this driver is over 25
          const driverAge = differenceInYears(new Date(), parseISO(this.driver.DateOfBirth));
          return driverAge < 25;
        }
        return true;
      },
    },
    methods: {
      getOccupations() {
        this.$store.dispatch('quotes/getOccupations', { sTfbId: this.trackingId })
          .then((occupationResponse) => {
            this.occupationOptions = occupationResponse;
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
      getStates() {
        this.$store.dispatch('app/getStateList', { sTfbId: this.trackingId })
          .then((stateResponse) => {
            this.stateOptions = stateResponse;
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
      getRelationships() {
        this.$store.dispatch('quotes/getRelationships', { sTfbId: this.trackingId })
          .then((relationshipResponse) => {
            this.relationshipOptions = relationshipResponse;
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
      buildVehicles(quote) {
        if (quote) {
          this.vehicleList = [];

          if (quote.Vehicles && quote.Vehicles.length > 0) {
            if (!quote.Vehicles[0].Year || quote.Vehicles[0].Year === '' || quote.Vehicles[0].Year === null) {
              // No saved vehicle information, so send them back
              this.$router.push({ name: 'quoteVehicle' });
            }
          } else {
            // No saved vehicle information, so send them back
            this.$router.push({ name: 'quoteVehicle' });
          }

          for (let v = 0; v < quote.Vehicles.length; v += 1) {
            const foundVehicle = quote.Vehicles[v];

            if (foundVehicle) {
              this.vehicleList.push({ Value: foundVehicle.VehicleSequenceNumber, DisplayText: foundVehicle.Description });
            }
          }
          this.vehicleList.push(this.otherOption);
        }
      },
      setupQuote() {
        if (this.quoteData) {
          this.quoteDetails = this.quoteData.Quote;

          this.driverList = this.quoteData.Operators;
          this.buildVehicles(this.quoteData);

          this.getOccupations();
          this.getRelationships();
          this.getStates();
          this.setupDriver(this.$route.params.id, false);
        } else {
          // No saved quote information, so go back to home
          this.$store.commit('quotes/SET_TIMEOUT', true);
          this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
        }
      },
      setupDriver(driverId, isSpouse) {
        // Reset
        this.driver = null;
        this.dateOfBirthDriver = null;
        this.quoteDetails = null;
        this.showSpouseRequired = isSpouse;
        this.quoteDetails = this.quoteData.Quote;

        if (driverId && driverId === 'new') {
          // Adding a new driver
          this.driver = {
            Accidents: [],
            Violations: [],
            MaritalStatusCode: isSpouse ? 'M' : '',
            RelationshipToInsuredCode: isSpouse ? '2' : '',
            DriversLicenseState: 'TX',
          };

          // Add it to the driver list
          this.quoteData.Operators.push(this.driver);
          this.driverList = this.quoteData.Operators;
        } else if (driverId && driverId !== '0') {
          // Try to find the driver wanted
          for (let d = 0; d < this.driverList.length; d += 1) {
            const foundDriver = this.driverList[d];

            if (foundDriver && foundDriver.DriverSequenceNumber === driverId) {
              this.driver = foundDriver;
            }
          }
        }

        if (this.driver === null) {
          // Default to the first driver
          this.driver = this.driverList[0];
        }

        if (this.driver) {
          if (this.driver.DriversLicenseNumber && this.driver.DriversLicenseNumber.indexOf('0000000') !== -1) {
            this.driver.DriversLicenseNumber = '';
          }

          if (this.driver.RelationshipToInsuredCode === undefined || this.driver.RelationshipToInsuredCode === null || this.driver.RelationshipToInsuredCode !== '1') {
            // Hide the "Self" option in Relationships; Hide the "Spouse" option if applicable
            const relationshipArray = [];
            if (this.relationshipOptions && this.relationshipOptions.length > 0) {
              for (let r = 0; r < this.relationshipOptions.length; r += 1) {
                const foundRelationship = this.relationshipOptions[r];

                if (foundRelationship && this.isPrimarySpouse && foundRelationship.Value === '2') {
                  relationshipArray.push(foundRelationship);
                } else if (foundRelationship && foundRelationship.Value !== '1') {
                  relationshipArray.push(foundRelationship);
                }
              }
            }
            if (relationshipArray.length > 0) {
              this.relationshipOptions = relationshipArray;
            }
          }
        }
      },
      addAccident() {
        const accidentId = this.driver.Accidents.length + 1;
        this.driver.Accidents.push({
          Id: accidentId || '0',
          AccidentAmount: '0',
          AtFaultIndicator: '',
          AccidentTypeCode: '',
          AccidentDate: '',
        });
        this.driver.HasAccident = 'Y';
      },
      removeAccident(accidentId) {
        const holdAccidents = [];
        for (let a = 0; a < this.driver.Accidents.length; a += 1) {
          const foundAccident = this.driver.Accidents[a];

          if (foundAccident.Id !== accidentId) {
            holdAccidents.push(foundAccident);
          }
        }

        this.driver.Accidents = holdAccidents;

        if (holdAccidents.length <= 0) {
          this.driver.HasAccident = 'N';
        } else {
          this.driver.HasAccident = 'Y';
        }
      },
      addViolation() {
        const violationId = this.driver.Violations.length + 1;
        this.driver.Violations.push({
          Id: violationId || '0',
          ViolationTypeCode: '',
          ViolationDate: '',
        });
        this.driver.HasViolation = 'Y';
      },
      removeViolation(violationId) {
        const holdViolations = [];
        for (let v = 0; v < this.driver.Violations.length; v += 1) {
          const foundViolation = this.driver.Violations[v];

          if (foundViolation.Id !== violationId) {
            holdViolations.push(foundViolation);
          }
        }

        this.driver.Violations = holdViolations;

        if (holdViolations.length <= 0) {
          this.driver.HasViolation = 'N';
        } else {
          this.driver.HasViolation = 'Y';
        }
      },
      onBack() {
        this.$store.dispatch('app/clearErrorMessage');
        this.$router.push({ name: 'quoteVehicle' });
      },
      onDelete() {
        if (this.driver.DriverSequenceNumber) {
          this.$store.dispatch('app/clearErrorMessage');
          // Remove the selected driver
          this.$store.dispatch('quotes/deleteDriver', {
            sAgreementNumber: this.agreementNumber,
            sSubAgreementNumber: this.subAgreementNumber,
            sDriverSequenceNumber: this.driver.DriverSequenceNumber,
            sTfbId: this.trackingId,
          })
            .then((quoteReturn) => {
              if (quoteReturn) {
                if (this.quoteData) {
                  this.driverList = this.quoteData.Operators;
                  // Go back to the first driver
                  this.driver = this.quoteData.Operators[0];
                  this.$router.push({ name: 'quoteDriver' });
                }
              } else {
                this.$store.dispatch('app/setErrorMessage', genericErrorMessage);
                this.$store.dispatch('app/setIsLoading', false);
              }
            })
            .catch((error) => {
              if (error.toString().indexOf('status code 401') >= 0) {
                // Session has timed out
                this.$store.commit('quotes/SET_TIMEOUT', true);
                this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
              }
              this.$store.dispatch('app/setErrorMessage', error.toString());
              this.$store.dispatch('app/setIsLoading', false);
            });
        } else {
          // Remove the last driver
          this.driverList.pop();

          if (this.quoteData) {
            // Go back to the first driver
            this.driver = this.quoteData.Operators[0];
            this.$router.push({ name: 'quoteDriver' });
          }
        }
        this.scrollToTop();
      },
      onSubmit() {
        this.saveDriver(false);
      },
      onAddSubmit() {
        this.saveDriver(true);
      },
      saveDriver(addNew) {
        this.$store.dispatch('app/clearErrorMessage');
        this.$store.dispatch('app/setIsLoading', true);

        if (this.driver.OperatorStatusIndicator === 'Y') {
          this.driver.OperatorStatusCode = 'A';
        }

        let driverMethod = 'quotes/addNewDriver';

        if (this.driver.DriverSequenceNumber && this.driver.DriverSequenceNumber !== null && this.driver.DriverSequenceNumber) {
          driverMethod = 'quotes/updateDriver';
        }

        // Add/update the driver information
        this.$store.dispatch(driverMethod, {
          sQuote: this.quoteDetails,
          sDriver: this.driver,
          sTfbId: this.trackingId,
        })
          .then((quoteReturn) => {
            if (quoteReturn) {
              if (this.spouseRequired) {
                // Required to add their spouse
                this.$refs.driverForm.reset();
                this.$refs.obv.reset();
                this.setupDriver('new', true);
                this.scrollToTop();
                this.$store.dispatch('app/setIsLoading', false);
              } else if (addNew) {
                // They want to add another driver or Required to add their spouse
                this.$refs.driverForm.reset();
                this.$refs.obv.reset();
                this.setupDriver('new', false);
                this.scrollToTop();
                this.$store.dispatch('app/setIsLoading', false);
              } else {
                // They want to continue on
                this.$router.push({ name: 'quoteCoverage' });
              }
            } else {
              this.$store.dispatch('app/setErrorMessage', genericErrorMessage);
              this.$store.dispatch('app/setIsLoading', false);
            }
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            // (29023) Operator No. is assigned as the principal operator of more than one vehicle  used for work or school over 50% of the time. (4458-15)
            // (2404) Cannot deselect if driver is principal operator on a vehicle (4681-60)
            if (error.ErrorMessage === 'Error deleting driver information: (2404) Cannot deselect if driver is principal operator on a vehicle (4681-60)') {
              this.$store.dispatch('app/setErrorMessage', 'Drivers must be removed as the primary operator of a vehicle before they can be removed as a driver.'
                + 'Please visit the Vehicles page to reassign the primary operators.');
              this.$store.dispatch('app/setIsLoading', false);
            } else {
              this.$store.dispatch('app/setErrorMessage', error.toString());
              this.$store.dispatch('app/setIsLoading', false);
            }
          });
      },
    },
    watch: {
      $route: 'setupQuote',
      birthDateMenu(val) {
        setTimeout(() => { (this.dateOfBirthActivePicker = 'YEAR'); });
      },
    },
    mounted() {
      this.$store.dispatch('app/clearErrorMessage');
      this.$store.dispatch('app/setIsLoading', true);
      this.setupQuote();
      this.$store.dispatch('app/setIsLoading', false);
    },
  };
</script>
<style lang="scss">

  .tfb-quote-box {
    background-color: $white;
    color: $black;
  }

  .spouse-notification {
    color: #e6e6e6 !important;
    background-color: $tfb-blue !important;
    border-color: $tfb-darkest-blue !important;
    vertical-align: middle;
    margin: 0px;
    padding: 0px;
    margin-bottom: 2px;
    border: 1px solid transparent;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  .spouse-notification-notice {
    color: #e6e6e6 !important;
    background-color: $tfb-blue !important;
    border-color: $tfb-darkest-blue !important;
    padding: 5px;
    vertical-align: middle;
    margin: 0px;
    width: 100%;
  }
</style>
