<template>
  <div id="contact-us">
    <div class="background-lightest-gray">
      <v-container fluid
                   class="tfb-container pa-10">
        <v-row>
          <v-col>
            <v-card class="fill-height tfb-contact-card">
              <v-card-title>
                <p class="tfb-title-text font-weight-bold text-uppercase text-blue">
                  Pay Your Bill
                </p>
              </v-card-title>
              <v-card-text>
                <span class="font-weight-bold text-black">
                  Pay by Mail:
                </span>
                <address id="billingAddress">
                  <span class="font-weight-bold">{{billingAddress.name}}</span><br />
                  {{billingAddress.addressLineOne}}<br />
                  {{billingAddress.addressLineTwo}}<br />
                </address><br />
                <br />
                <span class="font-weight-bold text-black">
                  Pay by Phone:
                </span>
                <a :href="ivrPhone.link">{{ ivrPhone.display }}</a><br />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <claims-numbers></claims-numbers>
          </v-col>
          <v-col>
            <v-card class="fill-height tfb-contact-card">
              <v-card-title>
                <p class="tfb-title-text font-weight-bold text-uppercase text-blue">
                  Customer Service
                </p>
              </v-card-title>
              <v-card-text>
                <address id="corporateOfficeAddress">
                  <span class="font-weight-bold">{{corporateOfficeAddress.name}}</span><br />
                  {{corporateOfficeAddress.addressLineOne}}<br />
                  {{corporateOfficeAddress.addressLineTwo}}<br />
                </address><br />
                <br />
                <span class="font-weight-bold text-black">
                  Customer Service:
                </span>
                <a :href="contactUsPhone.link">{{ contactUsPhone.display }}</a><br />
                <br /><br />
                <span class="font-weight-bold text-black">
                  Banks, Lenders & Financial Institutions:
                </span>
                <a href="https://www.lenderdock.com"
                   hreflang="en-us"
                   title="LenderDock.com"
                   rel="noopener"
                   target="_blank">LenderDock.com</a><br />
                <br /><br />
                <span class="font-weight-bold text-black">
                  {{ fraudHotline.title }}:
                </span>
                <a :href="fraudHotline.phoneLink">{{ fraudHotline.phoneDisplay }}</a><br />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <meet-agents></meet-agents>
    </div>
    <div class="background-darker-blue py-10">
      <h1 class="tfb-title-text-large text-center">Email Us</h1>
      <p class="tfb-title-text-smaller text-center"
         v-show="!submitted">
        Please fill out the form below and one of our representatives will
        be in touch.
      </p>
      <v-container class="tfb-contact-box"
                   v-show="!submitted">
        <required-fields-row></required-fields-row>
        <ValidationObserver ref="obv" v-slot="{ handleSubmit }">
          <v-form @submit.stop.prevent="handleSubmit(onSubmit)">
            <v-row>
              <v-col cols="12"
                     md="6">
                <text-input rules="required|name|min:2|max:25"
                            minlength="2"
                            maxlength="25"
                            label="First Name"
                            v-model="firstName"
                            autocomplete="shipping given-name"
                            autocapitalize="words"></text-input>
              </v-col>
              <v-col cols="12"
                     md="6">
                <text-input rules="required|name|min:2|max:25"
                            minlength="2"
                            maxlength="25"
                            label="Last Name"
                            v-model="lastName"
                            autocomplete="shipping family-name"
                            autocapitalize="words"></text-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                     md="6">
                <text-input rules="required|email|valid_domain|min:3|max:250"
                            autocomplete="home email"
                            minlength="3"
                            maxlength="250"
                            label="Email"
                            v-model="email"
                            type="email"></text-input>
              </v-col>
              <v-col cols="12"
                     md="6">
                <text-input rules="required|phone_number|min:10|max:12"
                            autocomplete="home tel"
                            minlength="10"
                            maxlength="12"
                            label="Phone"
                            v-model="phone"
                            v-mask="'###-###-####'"
                            type="tel"></text-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                     md="6">
                <text-input rules="alpha_num|min:4|max:6"
                            label="Member Number"
                            autocapitalize="characters"
                            minlength="4"
                            maxlength="6"
                            v-model="memberNumber"></text-input>
              </v-col>
              <v-col cols="12"
                     md="6"
                     v-if="isLoggedIn && policyList">
                <select-input rules="numeric|min:6|max:10"
                              label="Policy Number"
                              :items="policyList"
                              item-text="PolicyNumber"
                              item-value="PolicyNumber"
                              v-model="policyNumber"></select-input>
              </v-col>
              <v-col cols="12"
                     md="6"
                     v-else>
                <text-input rules="numeric|min:6|max:10"
                            label="Policy Number"
                            autocapitalize="characters"
                            minlength="6"
                            maxlength="10"
                            v-model="policyNumber"></text-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <select-input rules="required|max:10"
                              label="Subject"
                              :items="subjectOptions"
                              v-model="subject"></select-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <select-input rules="required|max:100"
                              label="Subject Detail"
                              :items="billingOptions"
                              v-model="subjectDetail"
                              v-if="subject === 'Billing'"></select-input>
                <select-input rules="required|max:100"
                              label="Subject Detail"
                              :items="claimsOptions"
                              v-model="subjectDetail"
                              v-if="subject === 'Claims'"></select-input>
                <select-input rules="required|max:100"
                              label="Subject Detail"
                              :items="paymentsOptions"
                              v-model="subjectDetail"
                              v-if="subject === 'Payments'"></select-input>
                <select-input rules="required|max:100"
                              label="Subject Detail"
                              :items="policyOptions"
                              v-model="subjectDetail"
                              v-if="subject === 'Policy'"></select-input>
                <select-input rules="required|max:100"
                              label="Subject Detail"
                              :items="technicalOptions"
                              v-model="subjectDetail"
                              v-if="subject === 'Technical'"></select-input>
                <select-input rules="required|max:100"
                              label="Residing County Name"
                              :items="countyOptions"
                              item-text="_countyName"
                              item-value="_countyName"
                              v-model="subjectDetail"
                              v-if="subject === 'Quote'"></select-input>
              </v-col>
            </v-row>
            <v-row v-if="subjectDetail === 'Cancellation'">
              <v-col cols="12">
                <v-menu ref="cancelEffectiveDateMenu"
                        v-model="cancelEffectiveDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px">
                  <template v-slot:activator="{ on }">
                    <ValidationProvider tag="div"
                                        :bails="false"
                                        rules="required"
                                        name="Cancellation Effective Date"
                                        v-slot="{ errors, valid }"
                                        vid="cancelEffective">
                      <v-text-field outlined
                                    required
                                    readonly
                                    hide-details="auto"
                                    :error-messages="errors"
                                    :success="valid"
                                    v-model="cancelEffectiveDate"
                                    label="Cancellation Effective Date"
                                    v-on="on">
                        <template v-slot:append>
                          <v-icon>mdi-calendar</v-icon>
                          <v-icon>mdi-asterisk</v-icon>
                        </template>
                      </v-text-field>
                    </ValidationProvider>
                  </template>
                  <v-date-picker v-model="cancelEffectiveDate"
                                 color="background-blue"
                                 no-title
                                 :min="minCancellationDate"
                                 @input="cancelEffectiveDateMenu = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <text-area-input rules="required|all_allowed|min:4|max:1000"
                                 label="Message"
                                 autocapitalize="sentences"
                                 minlength="4"
                                 maxlength="1000"
                                 :counter="1000"
                                 v-model="message"></text-area-input>
              </v-col>
            </v-row>
            <!-- REMOVED - Mimecast blocks sending the email to the requestor
            <v-row class="my-0">
              <v-col cols="12"
                     class="py-0">
                <v-checkbox v-model="emailCopy"
                            class="my-0"
                            label="Send a copy to your email address?">
                </v-checkbox>
              </v-col>
            </v-row>-->
            <v-row justify="end">
              <v-col cols="12"
                     sm="6"
                     offset-sm="6">
                <span role="alert"
                      v-show="!hasCaptcha"
                      class="text-darkest-red">Please validate you are not a bot.</span>
                <vue-recaptcha ref="recaptcha"
                               @verify="onVerify"
                               @expired="onExpired"
                               sitekey="6LdNyB8TAAAAAMAzsnuftXFam0KZpOLfO_h8QKya"></vue-recaptcha>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                     sm="6"
                     offset-sm="6">
                <v-btn block
                       type="submit"
                       class="tfb-button background-red">
                  Submit
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </ValidationObserver>
      </v-container>
      <v-container class="tfb-contact-box"
                   v-show="submitted">
        <v-row>
          <v-col>
            <span class="tfb-title-text-smaller text-darkest-blue">Thank you!</span>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
  /* eslint no-underscore-dangle: 0 */
  import { dispatch } from 'vuex-pathify';
  import { mask } from 'vue-the-mask';
  import DOMPurify from 'dompurify';
  import VueRecaptcha from 'vue-recaptcha';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import MeetAgents from '@/views/agents/components/MeetAgents.vue';
  import ClaimsNumbers from '@/views/claims/components/ClaimsNumbers.vue';
  import RequiredFieldsRow from '@/views/layout/components/RequiredFieldsRow.vue';
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbMetaMixin from '@/mixins/metaInfo';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'ContactUs',
    mixins: [tfbDataMixin, tfbMetaMixin, tfbHelperMixin],
    components: {
      VueRecaptcha,
      ValidationObserver,
      ValidationProvider,
      MeetAgents,
      ClaimsNumbers,
      RequiredFieldsRow,
    },
    directives: { mask },
    data() {
      return {
        submitted: false,
        hasCaptcha: true,
        captchaText: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        memberNumber: '',
        policyNumber: '',
        subject: null,
        subjectDetail: null,
        message: '',
        emailCopy: false,
        countyOptions: null,
        cancelEffectiveDateMenu: false,
        cancelEffectiveDate: null,
        subjectOptions: [
          'Quote',
          'Policy',
          'Billing',
          'Claims',
          'Payments',
          'Marketing',
          'Technical',
          'Other',
        ],
        billingOptions: [
          'Address Change',
          'AssurancePay/Recurring Payments',
          'E-bill/Paperless Billing',
          'Payments',
          'Premiums',
          'Other',
        ],
        claimsOptions: [
          'Claim Status',
          'Reporting a Claim',
          'Other',
        ],
        paymentsOptions: [
          'AssurancePay/Recurring Payments',
          'E-bill/Paperless Billing',
          'Other',
        ],
        policyOptions: [
          'Address Change',
          'Cancellation',
          'Coverage Change',
          'Paperless Notifications',
          'Premiums',
          'Other',
        ],
        technicalOptions: [
          'Account Information',
          'Logging In',
          'Policy Information',
          'Registering',
          'Resetting Password',
          'Other',
        ],
      };
    },
    watch: {
      subject() {
        this.subjectDetail = null;
        this.cancelEffectiveDate = null;
        this.$refs.obv.validate();
      },
    },
    computed: {
      metaPageTitle() {
        return 'Contact Us';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/contact-us`;
      },
      policyList() {
        try {
          return this.$store.getters['policy/accountSummary'];
        } catch (error) {
          return null;
        }
      },
      agreementNumber() {
        try {
          return this.$store.getters['quotes/agreementNumber'];
        } catch (error) {
          return '';
        }
      },
      minCancellationDate() {
        return this.parseDate(new Date().toISOString());
      },
    },
    methods: {
      getCountyList() {
        this.$store.dispatch('agentcounty/getCountyList')
          .then((response) => {
            if (response && response.data && response.data._counties) {
              this.countyOptions = response.data._counties;
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/logToULS', {
              logLevel: 'Error',
              logMessage: error,
              fileName: 'ContactUs.vue',
              methodName: 'getCountyList-Catch',
              trackerId: this.trackingId,
            });
          });
      },
      getWebmasterEmail() {
        // Get the ContactUs email address
        this.$store.dispatch('app/getConfiguration', { sSection: 'Email', sTitle: 'ContactUs' })
          .then((response) => {
            if (response && response !== '') {
              this.webmasterMail = response;
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/logToULS', {
              logLevel: 'Error',
              logMessage: error,
              fileName: 'ContactUs.vue',
              methodName: 'getConfiguration-Catch',
              trackerId: this.trackingId,
            });
          });
      },
      getMemberInformation() {
        const includedToken = this.$route.query.access_token;
        this.$store.dispatch('member/validateLogin', { sToken: includedToken, sTfbId: this.trackingId })
          .then((loginValid) => {
            if (loginValid) {
              this.memberNumber = this.$store.getters['member/memberNumber'];
              this.email = this.$store.getters['member/emailAddress'];
              this.firstName = this.$store.getters['member/memberFirstName'];
              this.lastName = this.$store.getters['member/memberLastName'];
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
      onCaptchaSubmit() {
        this.hasCaptcha = true;
        this.$refs.invisibleRecaptcha.execute();
      },
      onVerify(response) {
        this.hasCaptcha = true;
        this.captchaText = response;
        // console.log(`Verify: ${response}`);
      },
      onExpired() {
        this.hasCaptcha = false;
        this.captchaText = '';
        this.$refs.recaptcha.reset();
        // console.log('Expired');
      },
      onSubmit() {
        if (!this.hasCaptcha || this.captchaText === '') {
          this.onExpired();
          this.$store.dispatch('app/setIsLoading', false);
          return;
        }

        if (this.captchaText.length > 0) {
          dispatch('app/setIsLoading', true);

          // Build the subject line
          let subjectText = `${DOMPurify.sanitize(this.subject)}`;
          if (this.subjectDetail) {
            subjectText += ` - ${DOMPurify.sanitize(this.subjectDetail)}`;
          }

          // Build the message text
          let messageText = `<table><tr><td><b>My comment is regarding:</b></td><td>${subjectText}</td></tr><tr><td colspan='2'></td></tr>`;
          messageText += '<tr><td colspan=\'2\'><b>Contact Information:</b></td></tr><tr><td><b>Name:</b></td>';
          messageText += `<td align='left'>${DOMPurify.sanitize(this.firstName)} ${DOMPurify.sanitize(this.lastName)}</td></tr>`;
          messageText += `<tr><td><b>Email:</b></td><td align='left'>${DOMPurify.sanitize(this.email)}</td></tr>`;
          messageText += `<tr><td><b>Phone Number:</b></td><td align='left'>${DOMPurify.sanitize(this.phoneNumber)}</td></tr>`;
          messageText += `<tr><td><b>Member Number:</b></td><td align='left'>${(this.memberNumber ? DOMPurify.sanitize(this.memberNumber) : '')}</td></tr>`;
          messageText += `<tr><td><b>Policy Number:</b></td><td align='left'>${(this.policyNumber ? DOMPurify.sanitize(this.policyNumber) : '')}</td></tr>`;

          if (this.agreementNumber && this.agreementNumber !== null && this.agreementNumber !== '') {
            messageText += `<tr><td><b>Quote Number:</b></td><td align='left'>${this.agreementNumber}</td></tr>`;
          }

          if (this.cancelEffectiveDate && this.cancelEffectiveDate !== null && this.cancelEffectiveDate !== '') {
            messageText += `<tr><td><b>Cancellation Effective Date:</b></td><td align='left'>${this.cancelEffectiveDate}</td></tr>`;
          }

          messageText += `<tr><td colspan='2'></td></tr><tr><td colspan='2'><b>Message:</b></td></tr><tr><td colspan='2'>${DOMPurify.sanitize(this.message)}</td></tr>`;
          messageText += '<tr><td colspan=\'2\'></td></tr></table>';

          // Send the message
          this.$store.dispatch('app/sendMail', {
            sBCC: '',
            sBody: messageText,
            sCC: this.emailCopy ? DOMPurify.sanitize(this.email) : '',
            sFrom: DOMPurify.sanitize(this.email),
            sSubject: `Email from ContactUs Page - ${subjectText}`,
            sTo: this.webmasterMail,
            sToken: this.captchaText,
          })
            .then((response) => {
              if (response && response.data) {
                const emailResponse = response.data;
                if (!emailResponse.ErrorMessage || emailResponse.ErrorMessage === '') {
                  this.submitted = true;
                } else {
                  dispatch('app/setErrorMessage', emailResponse.ErrorMessage.toString());
                  this.onExpired();
                }
              }
              dispatch('app/setIsLoading', false);
            })
            .catch((error) => {
              dispatch('app/setErrorMessage', error.toString());
              dispatch('app/setIsLoading', false);
            });
        } else {
          this.onExpired();
        }
      },
    },
    created() {
      dispatch('app/clearErrorMessage');
      dispatch('app/setIsLoading', true);
      this.getCountyList();
      this.getWebmasterEmail();
      if (this.isLoggedIn) {
        this.getMemberInformation();
      }
      dispatch('app/setIsLoading', false);
    },
  };
</script>
<style lang="scss">

  .tfb-contact-box {
    background: $white;
    padding: 20px;
    max-width: 1000px;
    margin-bottom: 100px;
    color: $tfb-darkest-blue;
  }

  .tfb-contact-card {
    max-width: 600px !important;
    min-height: 250px;
    margin-left: auto;
    margin-right: auto;
  }
</style>
