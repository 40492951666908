<template>
  <v-container fluid
               class="tfb-smaller-container tfb-component-container">
    <v-row>
      <v-col cols="12">
        <v-container fluid
                     class="drop-shadow background-white px-md-10 pb-5">
          <v-row>
            <v-col cols="12">
              <span class="tfb-title-text-small text-uppercase">
                Additional Memberships
              </span>
            </v-col>
          </v-row>
          <v-row v-if="memberList && memberList.length > 1">
            <v-col cols="8"
                   sm="4"
                   class="font-weight-bold">
              Current Membership
            </v-col>
            <v-col cols="4">
              {{memberNumber}}
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12">
              <span class="text-blue font-weight-bold">
                Have more than one Texas Farm Bureau membership?<br />
              </span>
              Now you can access all of your memberships with one email address. Click below to associate another membership with this account.
            </v-col>
          </v-row>
          <ValidationObserver ref="obv">
            <v-row v-if="memberList && memberList.length > 1">
              <v-col cols="12"
                     sm="4"
                     class="font-weight-bold">
                Additional Memberships
              </v-col>
              <v-col cols="12"
                     sm="4">
                <select-input rules="required"
                              label="Member Number"
                              :items="filteredMemberList"
                              item-text="Nbr"
                              item-value="Nbr"
                              v-model="selectedMembership"
                              return-object></select-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                     sm="6"
                     md="4">
                <v-btn block
                       :disabled="formDisabled"
                       @click="addMember()"
                       class="tfb-button background-red">Add Membership</v-btn>
              </v-col>
              <v-col cols="12"
                     sm="6"
                     md="4"
                     v-if="selectedMembership && selectedMembership !== ''">
                <v-btn block
                       :disabled="formDisabled"
                       @click="switchMember()"
                       class="tfb-button background-red">Switch To This Membership</v-btn>
              </v-col>
              <v-col cols="12"
                     sm="6"
                     md="4"
                     v-if="selectedMembership && selectedMembership !== ''">
                <v-btn block
                       :disabled="formDisabled"
                       @click="removeMember()"
                       class="tfb-button background-red">Remove This Membership</v-btn>
              </v-col>
            </v-row>
          </ValidationObserver>
          <v-overlay absolute
                     :value="saveOverlay">
            <v-progress-circular indeterminate
                                 size="64"></v-progress-circular>
          </v-overlay>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import { ValidationObserver } from 'vee-validate';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'MembershipChanges',
    mixins: [tfbHelperMixin],
    components: {
      ValidationObserver,
    },
    data() {
      return {
        saveOverlay: false,
        formDisabled: false,
        selectedMembership: null,
        emailSuccess: false,
        verificationSuccess: false,
        passwordSuccess: false,
      };
    },
    computed: {
      memberList() {
        try {
          return this.$store.getters['member/memberList'];
        } catch (error) {
          return null;
        }
      },
      memberNumber() {
        try {
          return this.$store.getters['member/memberNumber'];
        } catch (error) {
          return '';
        }
      },
      filteredMemberList() {
        let search = null;
        if (this.memberNumber) {
          search = this.memberNumber;
        }
        if (!search) return this.memberList;
        if (!this.memberList) return this.memberList;
        return this.memberList.filter(c => c.Nbr.toUpperCase().indexOf(search) < 0);
      },
    },
    methods: {
      addMember() {
        this.$router.push({ name: 'policyholderManageMemberships', params: { changeType: 'add' } });
      },
      switchMember() {
        if (this.selectedMembership !== null) {
          dispatch('app/clearErrorMessage');
          this.saveOverlay = true;

          dispatch('member/switchMembership', { sMemberNumber: this.selectedMembership.Nbr, sMemberId: this.selectedMembership.Id, sTfbId: this.trackingId })
            .then((switchResult) => {
              if (switchResult) {
                // Redirect where needed
                this.doRedirect();
              }
            })
            .catch((error) => {
              dispatch('app/setErrorMessage', error.toString());
              this.saveOverlay = false;
            });
        }
      },
      removeMember() {
        if (this.selectedMembership !== null) {
          this.saveOverlay = true;
          this.$store.commit('member/SET_REMOVE_MEMBER_REQUEST', { MemberNumber: this.selectedMembership.Nbr, MemberId: this.selectedMembership.Id });
          this.$router.push({ name: 'policyholderManageMemberships', params: { changeType: 'remove' } });
          this.saveOverlay = false;
        }
      },
    },
    mounted() {
      this.selectedCommunication = this.communicationIndicator;

      if (!this.isMemberRole) {
        // Not the member, don't allow changes
        this.formDisabled = true;
      }
    },
  };
</script>
<style lang="scss">

</style>
