import Vue from 'vue';
import axios from 'axios';
import _ from 'lodash';
import helpers from '../../mixins/helpers';
import { genericErrorMessage } from '../../mixins/errorMessages';

const compAutoLookupServiceUrl = `${process.env.VUE_APP_WEB_URI}/services/TFBIC.Services.RESTCompAuto.Lookup/REST_CompAutoLookup.svc`;
const holLookupServiceUrl = `${process.env.VUE_APP_WEB_URI}/services/TFBIC.Services.RESTHOL.Lookup`;
const pa6LookupServiceUrl = `${process.env.VUE_APP_WEB_URI}/services/TFBIC.Services.RESTPA6.Lookup`;
const policyLookupServiceUrl = `${process.env.VUE_APP_WEB_URI}/services/TFBIC.Services.RESTPolicy.Lookup`;
const premiumFinanceLookupServiceUrl = `${process.env.VUE_APP_WEB_URI}/services/TFBIC.Services.RESTPremiumFinance.Lookup/REST_PremiumFinanceLookup.svc`;
const standardFireLookupServiceUrl = `${process.env.VUE_APP_WEB_URI}/services/TFBIC.Services.RESTStandardFire.Lookup`;
const texasAgLookupServiceUrl = `${process.env.VUE_APP_WEB_URI}/services/TFBIC.Services.RESTTexasAg.Lookup/REST_TexasAgLookup.svc`;
const majescoLookupServiceUrl = `${process.env.VUE_APP_WEB_URI}/services/TFBIC.Services.RESTMajesco.Lookup`;

const tokenName = process.env.VUE_APP_WEB_ENV !== 'PROD' ? `tfb_${process.env.VUE_APP_WEB_ENV}_token` : 'tfb_token';

const config = {
  crossdomain: true,
  withCredentials: true,
  headers: { 'Access-Control-Allow-Origin': '*', Accept: 'application/json; odata=verbose', 'Content-Type': 'application/json; odata=verbose' },
};

/**
 * summaryTransform
 * @param {object} accountSummaryData JSON object of policy data
 * @returns {object} JSON object of formatted policy data
 */
function summaryTransform(accountSummary, context, tfbId) {
  try {
    const returnSummary = [];

    if (accountSummary && !accountSummary.ErrorMessage && accountSummary.Policies) {
      for (let i = 0; i < accountSummary.Policies.length; i += 1) {
        const policy = accountSummary.Policies[i];
        if (policy.PolicyStatus === 'ACTIVE') {
          if (policy.PolicyType === 'SM') {
            policy.DetailPath = 'six-month';
          } else if (policy.PolicyType === 'CA') {
            policy.DetailPath = 'commercial-auto';
          } else if (policy.PolicyType === 'PR') {
            policy.DetailPath = 'premium-finance';
          } else if (policy.PolicyType === 'SF') {
            policy.DetailPath = 'standard-fire';
          } else if (policy.PolicyType === 'PF') {
            policy.DetailPath = 'inland-marine';
          } else if (policy.PolicyType === 'AA' || policy.PolicyType === 'AT') {
            policy.DetailPath = 'texas-agadvantage';
          } else if (policy.PolicyType === 'HT' || policy.PolicyType === 'TT') {
            policy.DetailPath = 'homeowners';
          } else if (policy.PolicyType === 'UM' && policy.PolicySubType === '1') {
            policy.DetailPath = 'umbrella';
          } else if (policy.PolicyType === 'TC' && policy.PolicySubType === '1') {
            policy.DetailPath = 'commercial-package';
          } else if (policy.PolicyType === 'CF' && policy.PolicySubType === '1') {
            policy.DetailPath = 'commercial-fire';
          // } else if (policy.PolicyType === 'CP' && policy.PolicySubType === '1') {
          //  policy.DetailPath = 'comprehensive-personal';
          } else if (policy.PolicyType === 'CP' && policy.PolicySubType === '2') {
            policy.DetailPath = 'general-liability';
          } else if (policy.PolicyType === 'CP' && policy.PolicySubType === '3') {
            policy.DetailPath = 'farm-liability';
          } else {
            policy.DetailPath = ' ';
          }
        } else {
          policy.DetailPath = ' ';
        }

        if (policy.PolicyType === 'CA' || policy.PolicyType === 'SM') {
          policy.IconPath = `${process.env.VUE_APP_ROOT_URI}/publishingimages/policy-icons/auto.png`;
        } else if (policy.PolicyType === 'HT' || policy.PolicyType === 'TT') {
          policy.IconPath = `${process.env.VUE_APP_ROOT_URI}/publishingimages/policy-icons/home.png`;
        } else if (policy.PolicyType === 'AA' || policy.PolicyType === 'AT' || policy.PolicyType === 'FT' || (policy.PolicyType === 'CP' && policy.PolicySubType === '3')) {
          policy.IconPath = `${process.env.VUE_APP_ROOT_URI}/publishingimages/policy-icons/farm-ranch.png`;
        } else if (policy.PolicyType === 'LI') {
          policy.IconPath = `${process.env.VUE_APP_ROOT_URI}/publishingimages/policy-icons/life.png`;
        } else if (policy.PolicyType === 'CF' || policy.PolicyType === 'CI' || (policy.PolicyType === 'CP' && policy.PolicySubType === '4') || policy.PolicyType === 'TC') {
          policy.IconPath = `${process.env.VUE_APP_ROOT_URI}/publishingimages/policy-icons/commercial.png`;
        } else if (policy.PolicyType === 'CP') {
          policy.IconPath = `${process.env.VUE_APP_ROOT_URI}/publishingimages/policy-icons/comprehensive-personal.png`;
        } else if (policy.PolicyType === 'CT') {
          policy.IconPath = `${process.env.VUE_APP_ROOT_URI}/publishingimages/policy-icons/chicken-turkey.png`;
        } else if (policy.PolicyType === 'MP') {
          policy.IconPath = `${process.env.VUE_APP_ROOT_URI}/publishingimages/policy-icons/multi-peril.png`;
        } else if (policy.PolicyType === 'PF' && policy.PolicySubType === '1') {
          policy.IconPath = `${process.env.VUE_APP_ROOT_URI}/publishingimages/policy-icons/inland-marine.png`;
        } else if (policy.PolicyType === 'PF') {
          policy.IconPath = `${process.env.VUE_APP_ROOT_URI}/publishingimages/policy-icons/personal-floater.png`;
        } else if (policy.PolicyType === 'SB') {
          policy.IconPath = `${process.env.VUE_APP_ROOT_URI}/publishingimages/policy-icons/school-bus.png`;
        } else if (policy.PolicyType === 'SH') {
          policy.IconPath = `${process.env.VUE_APP_ROOT_URI}/publishingimages/policy-icons/rural-health.png`;
        } else if (policy.PolicyType === 'SF') {
          policy.IconPath = `${process.env.VUE_APP_ROOT_URI}/publishingimages/policy-icons/standard-fire.png`;
        } else if (policy.PolicyType === 'ST') {
          policy.IconPath = `${process.env.VUE_APP_ROOT_URI}/publishingimages/policy-icons/short-term.png`;
        } else if (policy.PolicyType === 'UM') {
          policy.IconPath = `${process.env.VUE_APP_ROOT_URI}/publishingimages/policy-icons/umbrella.png`;
        } else {
          policy.IconPath = `${process.env.VUE_APP_ROOT_URI}/publishingimages/policy-icons/misc.png`;
        }

        if (policy.PolicyType === 'AA' || policy.PolicyType === 'AT') {
          policy.PolicyDescription = `${policy.PolicyDescription} &reg;`;
        }

        if (policy.PolicyType === 'AA' || policy.PolicyType === 'AT' || policy.PolicyType === 'HT' || policy.PolicyType === 'PF' || policy.PolicyType === 'SF'
          || policy.PolicyType === 'TT' || (policy.PolicyType === 'UM' && policy.PolicySubType === '1') || (policy.PolicyType === 'TC' && policy.PolicySubType === '1')
          || (policy.PolicyType === 'CF' && policy.PolicySubType === '1') || (policy.PolicyType === 'CP' && policy.PolicySubType === '3')) {
          policy.PropertyLocation = ' ';
        }

        if (policy.PolicyRecurringStatus === 'ACT' || policy.PolicyRecurringStatus === 'RAC' || policy.PolicyRecurringStatus === 'SNT'
          || policy.PolicyRecurringStatus === 'PNE' || policy.PolicyRecurringStatus === 'PNS') {
          policy.CurrentRecurring = 'CHANGE';
        } else {
          policy.CurrentRecurring = ' ';

          if (policy.PolicyRecurringFlag === 'Y' || policy.LaurusFlag === 'Y') {
            policy.CurrentRecurring = 'SIGNUP';
          }
        }

        returnSummary.push(policy);
      }
    }

    return returnSummary;
  } catch (error) {
    context.dispatch('app/logToULS', {
      logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'policy.js', methodName: 'summaryTransform', trackerId: tfbId,
    }, { root: true });
    return accountSummary;
  }
}

/**
 * premiumFinanceTransform
 * @param {object} data - JSON object of policy details data
 * @returns {object} - JSON object of formatted policy details data
 */
function premiumFinanceTransform(policyDetails, context, summaryDetails, tfbId) {
  try {
    const policyReturn = summaryDetails;

    if (policyDetails) {
      if (policyDetails.PremiumFinanceContract && policyDetails.PremiumFinanceContract.length > 0) {
        policyReturn.ContractNumber = summaryDetails.ContractNumber;
        policyReturn.ContractNumberAbbr = policyDetails.PremiumFinanceContract[0].ContractNumber;
        policyReturn.ContractDate = policyDetails.PremiumFinanceContract[0].ContractDate;
        policyReturn.DownPayment = policyDetails.PremiumFinanceContract[0].DownPayment;
        policyReturn.DueDate = policyDetails.PremiumFinanceContract[0].DueDate;
        policyReturn.FinanceCharge = policyDetails.PremiumFinanceContract[0].FinanceCharge;
        policyReturn.MonthlyPayment = policyDetails.PremiumFinanceContract[0].MonthlyPayment;
        policyReturn.TotalAmountDue = policyDetails.PremiumFinanceContract[0].TotalAmountDue;
        policyReturn.TotalPremium = policyDetails.PremiumFinanceContract[0].TotalPremium;
        policyReturn.MemberNumber = policyDetails.PremiumFinanceContract[0].MemberNumber;
      }

      if (policyDetails.PremiumFinancePayments) {
        policyReturn.Payments = policyDetails.PremiumFinancePayments;
      } else {
        policyReturn.Payments = [];
      }

      if (policyDetails.PremiumFinancePolicyInformation) {
        policyReturn.AssociatedPolicyNumber = policyDetails.PremiumFinancePolicyInformation.PolicyNumber;
      }
    }

    return policyReturn;
  } catch (error) {
    context.dispatch('app/logToULS', {
      logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'policy.js', methodName: 'premiumFinanceTransform', trackerId: tfbId,
    }, { root: true });

    return policyDetails;
  }
}

/**
 * policyHolderAutoTransform - PA6
 * @param {object} data - JSON object of policy details data
 * @returns {object} - JSON object of formatted policy details data
 */
function policyHolderAutoTransform(policyDetails, context, summaryDetails, tfbId) {
  try {
    const policyReturn = summaryDetails;

    if (policyDetails) {
      if (policyDetails.InsuredAddress) {
        policyReturn.PolicyAddress = policyDetails.InsuredAddress;
      } else if (policyDetails.PolicyAddress) {
        policyReturn.PolicyAddress = policyDetails.PolicyAddress;
      }

      if (policyDetails.EffectiveDate && policyDetails.EffectiveDate !== '') {
        policyReturn.EffectiveDate = policyDetails.EffectiveDate;
      }

      if (policyDetails.ExpirationDate && policyDetails.ExpirationDate !== '') {
        policyReturn.ExpirationDate = policyDetails.ExpirationDate;
      }

      if (policyDetails.FutureEffectiveDate) {
        policyReturn.FutureEffectiveDate = policyDetails.FutureEffectiveDate;
      }

      if (policyDetails.FutureExpirationDate) {
        policyReturn.FutureExpirationDate = policyDetails.FutureExpirationDate;
      }

      if (policyReturn.PolicyAddress) {
        policyReturn.FormattedPolicyAddress = helpers.methods.buildAddress(policyReturn.PolicyAddress.Address1,
          policyReturn.PolicyAddress.Address2, policyReturn.PolicyAddress.Address3,
          policyReturn.PolicyAddress.City, policyReturn.PolicyAddress.State,
          policyReturn.PolicyAddress.ZipCode, policyReturn.PolicyAddress.ZipCode4, policyReturn.PolicyAddress.ZipCode2);
      }

      if (policyDetails.WritingCompany) {
        policyReturn.WritingCompany = policyDetails.WritingCompany;
      } else if (policyDetails.WritingCompanyName) {
        policyReturn.WritingCompany = policyDetails.WritingCompanyName;
      }

      if (policyDetails.NamedInsuredOne) { policyReturn.InsuredNames = policyDetails.NamedInsuredOne; }

      if (policyDetails.NamedInsuredTwo) { policyReturn.InsuredNames += `<br />${policyDetails.NamedInsuredTwo}`; }

      if (policyDetails.PolicyBilling) {
        policyReturn.Adjustments = policyDetails.PolicyBilling.PremiumAdjustment;
        policyReturn.BilledPremiumAmount = policyDetails.PolicyBilling.BilledPremiumAmount;
        policyReturn.PreviousDebitCredit = policyDetails.PolicyBilling.PreviousDebitCredit;
        policyReturn.TermPremiumAmount = policyDetails.PolicyBilling.GrossPremiumAmount;
        policyReturn.TheftFee = policyDetails.PolicyBilling.TheftFee;
        policyReturn.TotalDiscounts = policyDetails.PolicyBilling.TotalDiscounts;

        if (policyDetails.PolicyBilling.PaymentHistory && policyDetails.PolicyBilling.PaymentHistory.length > 0) {
          const sortedPayments = _.orderBy(policyDetails.PolicyBilling.PaymentHistory, ['PaymentDate'], ['desc']);
          policyReturn.Payments = sortedPayments;
        }
      }

      // Do we have drivers?
      if (policyDetails.CoveredDrivers) {
        for (let d = 0; d < policyDetails.CoveredDrivers.length; d += 1) {
          const driver = policyDetails.CoveredDrivers[d];
          if (driver) {
            if (driver.DriversLicenseNumber) {
              driver.MaskedDriversLicenseNumber = helpers.methods.maskString(driver.DriversLicenseNumber, '4', 'X');
            } else {
              driver.MaskedDriversLicenseNumber = 'N/A';
            }
          }
        }
      }

      // Do we have vehicles?
      if (policyDetails.Vehicles) {
        // Loop through the vehicles
        for (let v = 0; v < policyDetails.Vehicles.length; v += 1) {
          const vehicle = policyDetails.Vehicles[v];
          // Do we have a vehicle with a lienholder?
          if (vehicle && vehicle.LienHolders && vehicle.LienHolders.length > 0) {
            for (let l = 0; l < vehicle.LienHolders.length; l += 1) {
              const lienholder = vehicle.LienHolders[l];
              // Do we have a vehicle with a lienholder?
              if (lienholder && lienholder.Address && lienholder.Address.City) {
                // Build the coverage section name
                lienholder.LienHolderAddress = helpers.methods.buildSingleLineAddress(lienholder.Address.Address1, lienholder.Address.Address2, lienholder.Address.Address3,
                  lienholder.Address.City, lienholder.Address.State, lienholder.Address.ZipCode, lienholder.Address.ZipCode4, null);
              } else if (lienholder && lienholder.Address) {
                // Build the coverage section name
                lienholder.LienHolderAddress = lienholder.Address;
              } else {
                lienholder.LienHolderAddress = '';
              }
            }
          }
        }
      }

      policyReturn.PolicyAddress = policyDetails.PolicyAddress;
      policyReturn.CoveredDrivers = policyDetails.CoveredDrivers;
      policyReturn.ExcludedDrivers = policyDetails.ExcludedDrivers;
      policyReturn.Vehicles = policyDetails.Vehicles;
    }

    return policyReturn;
  } catch (error) {
    context.dispatch('app/logToULS', {
      logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'policy.js', methodName: 'policyHolderAutoTransform', trackerId: tfbId,
    }, { root: true });
    return policyDetails;
  }
}

/**
 * policyHolderPropertyTransform - HOL, STF/INM, TAA
 * @param {object} data - JSON object of policy details data
 * @returns {object} - JSON object of formatted policy details data
 */
function policyHolderPropertyTransform(policyDetails, context, summaryDetails, tfbId) {
  try {
    const policyDiscounts = [];
    const policyEndorsements = [];
    const policyMortgagees = [];
    const policySections = [];
    const policyReturn = summaryDetails;

    if (policyDetails) {
      policyReturn.InsuredNames = policyDetails.NamedInsuredOne;
      policyReturn.LocationConstruction = policyDetails.PropertyConstruction;
      policyReturn.PropertyLocation = policyDetails.PropertyLocation;
      policyReturn.WritingCompany = policyDetails.WritingCompanyName;

      if (policyDetails.PolicyAddress) {
        policyReturn.PolicyAddress = policyDetails.PolicyAddress;
      } else if (policyDetails.InsuredAddress) {
        policyReturn.PolicyAddress = policyDetails.InsuredAddress;
      }

      if (policyReturn.PolicyAddress) {
        policyReturn.FormattedPolicyAddress = helpers.methods.buildAddress(policyReturn.PolicyAddress.Address1,
          policyReturn.PolicyAddress.Address2, policyReturn.PolicyAddress.Address3,
          policyReturn.PolicyAddress.City, policyReturn.PolicyAddress.State,
          policyReturn.PolicyAddress.ZipCode, policyReturn.PolicyAddress.ZipCode4, policyReturn.PolicyAddress.ZipCode2);
      }

      if (policyDetails.NamedInsuredTwo) {
        policyReturn.InsuredNames += `<br />${policyDetails.NamedInsuredTwo}`;
      }

      if (policyDetails.PolicyForm) {
        policyReturn.PolicyForm = policyDetails.PolicyForm.replace('&amp;amp;', '&');
      }

      if (policyDetails.PropertyInformation && (policyReturn.PropertyLocation === null || policyReturn.PropertyLocation === '' || policyReturn.PropertyLocation === ' ')) {
        policyReturn.PropertyLocation = policyDetails.PropertyInformation.LocationOfProperty;
        policyReturn.LocationConstruction = policyDetails.PropertyInformation.Construction;
      }

      if (policyDetails.PolicyBilling) {
        policyReturn.Adjustments = policyDetails.PolicyBilling.PremiumAdjustment;
        policyReturn.BilledPremiumAmount = policyDetails.PolicyBilling.BilledPremiumAmount;
        policyReturn.PreviousDebitCredit = policyDetails.PolicyBilling.PreviousDebitCredit;
        policyReturn.TheftFee = policyDetails.PolicyBilling.TheftFee;

        if (policyDetails.PolicyBilling.TotalDiscounts) {
          policyReturn.TotalDiscounts = policyDetails.PolicyBilling.TotalDiscounts;
        } else {
          // eslint-disable-next-line no-lonely-if
          if (policyDetails.PolicyBilling.GrossPremiumAmount) {
            policyReturn.TotalDiscounts = policyDetails.PolicyBilling.BilledPremiumAmount - policyDetails.PolicyBilling.GrossPremiumAmount;
          } else if (policyDetails.PolicyBilling.TermPremiumAmount) {
            policyReturn.TotalDiscounts = policyDetails.PolicyBilling.BilledPremiumAmount - policyDetails.PolicyBilling.TermPremiumAmount;
          }
        }

        if (policyDetails.PolicyBilling.GrossPremiumAmount) {
          policyReturn.TermPremiumAmount = policyDetails.PolicyBilling.GrossPremiumAmount;
        } else {
          policyReturn.TermPremiumAmount = policyDetails.PolicyBilling.TermPremiumAmount;
        }

        if (policyDetails.PolicyBilling.PaymentHistory && policyDetails.PolicyBilling.PaymentHistory.length > 0) {
          for (let p = 0; p < policyDetails.PolicyBilling.PaymentHistory.length; p += 1) {
            const payment = policyDetails.PolicyBilling.PaymentHistory[p];
            if (payment && payment.PaymentDate && payment.PaymentDate !== null && payment.PaymentDate !== '') {
              const paymentDate = payment.PaymentDate.split('-');
              if (paymentDate.length > 2) {
                if (paymentDate[2].length > 2) {
                  // Last part is a year, so we have to rearrange the date parts
                  payment.PaymentDate = `${paymentDate[2]}-${paymentDate[0]}-${paymentDate[1]}`;
                }
              }
            }
          }

          policyReturn.Payments = policyDetails.PolicyBilling.PaymentHistory;
        } else {
          policyReturn.Payments = [];
        }
      }

      if (policyDetails.BillingInformation) {
        policyReturn.Adjustments = policyDetails.BillingInformation.PremiumAdjustment;
        policyReturn.BilledPremiumAmount = policyDetails.BillingInformation.BilledPremiumAmount;
        policyReturn.PreviousDebitCredit = policyDetails.BillingInformation.PreviousDebitCredit;
        policyReturn.NextPaymentDueDate = policyDetails.BillingInformation.NextPaymentDueDate;
        policyReturn.TermPremiumAmount = policyDetails.BillingInformation.TermPremiumAmount;
        policyReturn.TheftFee = policyDetails.BillingInformation.TheftFee;
        policyReturn.TotalDiscounts = policyDetails.BillingInformation.TotalDiscounts;

        if (policyDetails.BillingInformation.PaymentHistory && policyDetails.BillingInformation.PaymentHistory.length > 0) {
          for (let p = 0; p < policyDetails.BillingInformation.PaymentHistory.length; p += 1) {
            const payment = policyDetails.BillingInformation.PaymentHistory[p];
            if (payment && payment.PaymentDate && payment.PaymentDate !== null && payment.PaymentDate !== '') {
              const paymentDate = payment.PaymentDate.split('-');
              if (paymentDate.length > 2) {
                if (paymentDate[2].length > 2) {
                  // Last part is a year, so we have to rearrange the date parts
                  payment.PaymentDate = `${paymentDate[2]}-${paymentDate[0]}-${paymentDate[1]}`;
                }
              }
            }
          }
          policyReturn.Payments = policyDetails.BillingInformation.PaymentHistory;
        } else {
          policyReturn.Payments = [];
        }
      }

      if (policyDetails.Locations) {
        for (let l = 0; l < policyDetails.Locations.length; l += 1) {
          const location = policyDetails.Locations[l];
          if (location && location.AddressLineOne && location.AddressLineOne !== null) {
            location.LocationAddress = helpers.methods.buildSingleLineAddress(location.AddressLineOne, location.AddressLineTwo, null, location.AddressCity,
              location.AddressState, location.AddressZipCode, location.AddressZipCodePlusFour, location.AddressZipCodePlusTwo);
          }
        }

        policyReturn.Locations = policyDetails.Locations;
      }

      if (policyDetails.Sections && policyDetails.Sections.length > 0) {
        for (let s = 0; s < policyDetails.Sections.length; s += 1) {
          const section = policyDetails.Sections[s];
          if (section) {
            if (section.Coverages && section.Coverages.length > 0) {
              for (let c = 0; c < section.Coverages.length; c += 1) {
                const coverage = section.Coverages[c];
                if (coverage) {
                  if (coverage.Limit) {
                    if (coverage.Limit.indexOf('/') !== -1) {
                      const splitCoverage = coverage.Limit.split('/');

                      if (splitCoverage.length > 1) {
                        coverage.Limit = `${helpers.methods.formatMoney(splitCoverage[0], 0)}/${helpers.methods.formatMoney(splitCoverage[1], 0)}`;
                      }
                    } else {
                      coverage.Limit = helpers.methods.formatMoney(coverage.Limit, 0);
                    }
                  }
                  if (coverage.Name) {
                    coverage.Name = _.replace(coverage.Name, '&amp;', '&');
                  }
                  if (coverage.Premium) {
                    coverage.Premium = helpers.methods.formatMoney(coverage.Premium, 0);
                  }
                }
              }
            }

            if (section.Deductibles && section.Deductibles.length > 0) {
              for (let d = 0; d < section.Deductibles.length; d += 1) {
                const deductible = section.Deductibles[d];
                if (deductible) {
                  if (deductible.Name) {
                    deductible.Name = _.replace(deductible.Name, '&amp;', '&');
                  }
                }
              }
            }

            policySections.push(section);

            if (section.Discounts) {
              for (let d = 0; d < section.Discounts.length; d += 1) {
                const discount = section.Discounts[d];
                if (discount && policyDiscounts.indexOf(discount.Name) === -1) {
                  policyDiscounts.push(discount);
                }
              }
            }

            if (section.Endorsements) {
              for (let e = 0; e < section.Endorsements.length; e += 1) {
                const endorsement = section.Endorsements[e];
                if (endorsement && policyEndorsements.indexOf(endorsement.Id) === -1) {
                  policyEndorsements.push(endorsement);
                }
              }
            }

            if (section.Mortgagees) {
              for (let m = 0; m < section.Mortgagees.length; m += 1) {
                const mortgagee = section.Mortgagees[m];
                if (mortgagee) {
                  policyMortgagees.push(mortgagee);
                }
              }
            }
          }
        }
      }

      // Sort the accumulated discounts and set them to the policy level Discounts
      if (policyDiscounts.length > 0) {
        policyReturn.Discounts = _.sortBy(policyDiscounts, ['Name']);
      } else {
        policyReturn.Discounts = policyDiscounts;
      }

      // Sort the accumulated endorsements and set them to the policy level Endorsements
      if (policyEndorsements.length > 0) {
        policyReturn.Endorsements = _.sortBy(policyEndorsements, ['Id']);
      } else {
        policyReturn.Endorsements = policyEndorsements;
      }

      // Sort the accumulated mortgagees and set them to the policy level Mortgagees
      if (policyMortgagees.length > 0) {
        policyReturn.Mortgagees = _.sortBy(policyMortgagees, ['Name']);
      } else {
        policyReturn.Mortgagees = policyMortgagees;
      }

      // Set the accumulated sections to the policy level Sections
      policyReturn.Sections = policySections;
    }

    return policyReturn;
  } catch (error) {
    context.dispatch('app/logToULS', {
      logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'policy.js', methodName: 'policyHolderPropertyTransform', trackerId: tfbId,
    }, { root: true });

    return policyDetails;
  }
}

/**
 * policyDetailsTransform - CompAuto, SF/PF, Majesco
 * @param {object} data - JSON object of policy details data
 * @returns {object} - JSON object of formatted policy details data
 */
function policyDetailsTransform(policyDetails, context, summaryDetails, tfbId) {
  const policyReturn = summaryDetails;

  try {
    const policyEndorsements = [];
    const policyDiscounts = [];
    const policyMortgagees = [];
    const policyCoverages = [];
    const policySecIIICoverages = [];
    const policySecIVCoverages = [];

    if (policyDetails) {
      policyReturn.ExcludedDrivers = policyDetails.ExcludedDrivers;
      policyReturn.DIFamilyMember = policyDetails.DIFamilyMember;
      policyReturn.PolicyForm = policyDetails.Form;
      policyReturn.PerilsInsuredAgainst = policyDetails.PerilsInsuredAgainst;
      policyReturn.UnderlyingAutoPolicies = policyDetails.UnderlyingAutoPolicies;
      policyReturn.UnderlyingPropertyPolicies = policyDetails.UnderlyingPropertyPolicies;
      policyReturn.Locations = policyDetails.Locations;

      if (policyDetails.Members) {
        policyReturn.NamedInsureds = [];
        policyReturn.NamedInsureds.push(policyDetails.Members.NamedInsured);

        if (policyReturn.Members.SecondNamedInsured) {
          policyReturn.NamedInsureds.push(policyDetails.Members.SecondNamedInsured);
        }
      }

      if (policyDetails.NamedInsureds) {
        if (policyDetails.NamedInsureds[0] && policyDetails.NamedInsureds[0].MailingAddress) {
          policyReturn.PolicyAddress = policyDetails.NamedInsureds[0].MailingAddress;
        } else if (policyDetails.NamedInsureds.NamedInsured && policyDetails.NamedInsureds.NamedInsured.MailingAddress) {
          policyReturn.PolicyAddress = policyDetails.NamedInsureds.NamedInsured.MailingAddress;
        }

        for (let i = 0; i < policyDetails.NamedInsureds.length; i += 1) {
          const name = policyDetails.NamedInsureds[i];
          if (name) {
            if (name.CombinedFullName && i === 0) {
              policyReturn.InsuredNames = `${name.CombinedFullName}<br />`;
            } else if (name.FirstName && i === 0) {
              policyReturn.InsuredNames = `${name.FirstName} ${name.LastName}<br />`;
            } else if (name.ConcatenatedName && i === 0) {
              policyReturn.InsuredNames = `${name.CombinedFullName}<br />`;
            } else if (name.CombinedFullName) {
              policyReturn.InsuredNames += `${name.CombinedFullName}<br />`;
            } else if (name.FirstName) {
              policyReturn.InsuredNames += `${name.FirstName} ${name.LastName}<br />`;
            } else if (name.ConcatenatedName) {
              policyReturn.InsuredNames += `${name.CombinedFullName}<br />`;
            } else {
              policyReturn.InsuredNames += `${name}<br />`;
            }
          }
        }
      }

      if (policyDetails.PolicyAddress) {
        policyReturn.PolicyAddress = policyDetails.PolicyAddress;
      } else if (policyDetails.InsuredAddress) {
        policyReturn.PolicyAddress = policyDetails.InsuredAddress;
      } else if (policyDetails.MailingAddress) {
        policyReturn.PolicyAddress = policyDetails.MailingAddress;
      }

      if (policyReturn.PolicyAddress) {
        policyReturn.FormattedPolicyAddress = helpers.methods.buildAddress(policyReturn.PolicyAddress.Address1,
          policyReturn.PolicyAddress.Address2, policyReturn.PolicyAddress.Address3,
          policyReturn.PolicyAddress.City, policyReturn.PolicyAddress.State,
          policyReturn.PolicyAddress.ZipCode, policyReturn.PolicyAddress.ZipCode4, policyReturn.PolicyAddress.ZipCode2);
      }

      if (policyDetails.NamedInsured1) { policyReturn.InsuredNames = policyDetails.NamedInsured1; }

      if (policyDetails.NamedInsured2) { policyReturn.InsuredNames += `<br />${policyDetails.NamedInsured2}`; }

      if (policyDetails.NamedInsuredOne) { policyReturn.InsuredNames = policyDetails.NamedInsuredOne; }

      if (policyDetails.NamedInsuredTwo) { policyReturn.InsuredNames += `<br />${policyDetails.NamedInsuredTwo}`; }

      if (policyDetails.PolicyDescription) {
        policyReturn.PolicyDescription = policyDetails.PolicyDescription;
      }

      if (policyDetails.WritingCompany) {
        policyReturn.WritingCompany = policyDetails.WritingCompany;
      } else if (policyDetails.WritingCompanyName) {
        policyReturn.WritingCompany = policyDetails.WritingCompanyName;
      }

      if (policyDetails.BillingInformation || policyDetails.PolicyBilling) {
        const policyBilling = (policyDetails.PolicyBilling) ? policyDetails.PolicyBilling : policyDetails.BillingInformation;

        if (!policyBilling.TermPremiumAmount) {
          if (policyDetails.AgreementInformation && policyDetails.AgreementInformation.ActualPremiumAmount) {
            policyReturn.TermPremiumAmount = policyReturn.AgreementInformation.ActualPremiumAmount;
          }
        } else {
          policyReturn.TermPremiumAmount = policyBilling.TermPremiumAmount;
        }

        if (!policyBilling.BilledPremiumAmount) {
          if (policyDetails.AgreementInformation && policyDetails.AgreementInformation.PolicyPremiumAmount) {
            policyReturn.BilledPremiumAmount = policyReturn.AgreementInformation.PolicyPremiumAmount;
          }
        } else {
          policyReturn.BilledPremiumAmount = policyBilling.BilledPremiumAmount;
        }

        if (!policyBilling.PremiumAdjustment) {
          if (policyDetails.AgreementInformation && policyDetails.AgreementInformation.AdjustmentToMinimum) {
            policyReturn.Adjustments = policyReturn.AgreementInformation.AdjustmentToMinimum;
          }
        } else {
          policyReturn.Adjustments = policyBilling.PremiumAdjustment;
        }

        policyReturn.PreviousDebitCredit = policyBilling.PreviousDebitCredit;
        policyReturn.NextPaymentDueDate = policyBilling.NextPaymentDueDate;
        policyReturn.TheftFee = policyBilling.TheftFee;
        policyReturn.TotalDiscounts = policyBilling.TotalDiscounts;

        if (policyBilling.PaymentHistory && policyBilling.PaymentHistory.length > 0) {
          for (let p = 0; p < policyBilling.PaymentHistory.length; p += 1) {
            const payment = policyBilling.PaymentHistory[p];
            if (payment && payment.PaymentDate && payment.PaymentDate !== null && payment.PaymentDate !== '') {
              const paymentDate = payment.PaymentDate.split('-');
              if (paymentDate.length > 2) {
                if (paymentDate[2].length > 2) {
                  // Last part is a year, so we have to rearrange the date parts
                  payment.PaymentDate = `${paymentDate[2]}-${paymentDate[0]}-${paymentDate[1]}`;
                }
              }
            }
          }
          policyReturn.Payments = policyBilling.PaymentHistory;
        } else {
          policyReturn.Payments = [];
        }
      }

      if (policyDetails.TheftFee && (!policyReturn.TheftFee || policyReturn.TheftFee === '0' || policyReturn.TheftFee === '')) {
        policyReturn.TheftFee = policyDetails.TheftFee;
      }

      if (policyDetails.PolicyLimit) {
        policyReturn.PolicyLimit = policyDetails.PolicyLimit;
      }

      if (policyDetails.PremiumAmount && (policyReturn.TermPremiumAmount === '0' || policyReturn.TermPremiumAmount === '')) {
        policyReturn.TermPremiumAmount = policyDetails.PremiumAmount;
      }

      if (policyDetails.Endorsements) {
        for (let e = 0; e < policyDetails.Endorsements.length; e += 1) {
          const endorsement = policyDetails.Endorsements[e];
          if (endorsement && endorsement.Number && policyEndorsements.indexOf(endorsement.Number) === -1) {
            policyEndorsements.push({
              Id: endorsement.Number, Name: (endorsement.Description || 'Unavailable'), Premium: endorsement.Premium, Limit: endorsement.LimitsOfLiability,
            });
          }
        }
      }

      if (policyDetails.Discounts) {
        for (let d = 0; d < policyDetails.Discounts.length; d += 1) {
          const discount = policyDetails.Discounts[d];
          if (discount) {
            if (discount.Description) {
              discount.Description = discount.Description.replace('Sec 1 And 2 ', '');
            }

            if (policyDiscounts.indexOf(discount.Description) === -1 && discount.DiscountAmount !== '' && discount.DiscountAmount !== '0') {
              policyDiscounts.push({ Name: discount.Description, DiscountAmount: discount.DiscountAmount, PremiumAdjustment: discount.PremiumAdjustment });
            }
          }
        }
      }

      if (policyDetails.SectionIProperty) {
        if (policyDetails.SectionIProperty.Discounts) {
          for (let d = 0; d < policyDetails.SectionIProperty.Discounts.length; d += 1) {
            const discount = policyDetails.SectionIProperty.Discounts[d];
            if (discount) {
              if (discount.DiscountAmount) {
                if (discount.DiscountAmount !== '') {
                  if (discount.DiscountAmount.indexOf('%') === -1) {
                    discount.DiscountAmount = `${(Number(discount.DiscountAmount) * 100)}%`;
                  }
                } else if (discount.PremiumAdjustment) {
                  discount.DiscountAmount = discount.PremiumAdjustment.toString();
                }
              }

              if (policyDiscounts.indexOf(discount.Description) === -1 && discount.DiscountAmount !== '' && discount.DiscountAmount !== '0') {
                policyDiscounts.push({ Description: discount.Description, DiscountAmount: discount.DiscountAmount, PremiumAdjustment: discount.PremiumAdjustment });
              }
            }
          }
        }
      }

      if (policyDetails.PremisesInformation) {
        policyReturn.Construction = policyDetails.PremisesInformation.Construction;
        policyReturn.PropertyLocation = policyDetails.PremisesInformation.LocationOfProperty;

        if (policyDetails.PremisesInformation.InterestedThirdParty) {
          for (let m = 0; m < policyDetails.PremisesInformation.InterestedThirdParty.length; m += 1) {
            const mortgagee = policyDetails.PremisesInformation.InterestedThirdParty[m];
            if (mortgagee && mortgagee.Name && policyMortgagees.indexOf(mortgagee.Name) === -1 && policyMortgagees.indexOf(mortgagee.LoanNumber) === -1) {
              policyMortgagees.push({
                Name: mortgagee.Name, AdditionalName: mortgagee.AdditionalName, LoanNumber: mortgagee.LoanNumber, Address: mortgagee.Address,
              });
            }
          }
        }
      }

      if (policyDetails.PropertyInformation) {
        policyReturn.Construction = policyDetails.PropertyInformation.Construction;
        policyReturn.PropertyLocation = policyDetails.PropertyInformation.LocationOfProperty;

        if (policyDetails.PropertyInformation.Mortgagees) {
          for (let m = 0; m < policyDetails.PropertyInformation.Mortgagees.length; m += 1) {
            const mortgagee = policyDetails.PropertyInformation.Mortgagees[m];
            if (mortgagee && mortgagee.Name && policyMortgagees.indexOf(mortgagee.Name) === -1 && policyMortgagees.indexOf(mortgagee.LoanNumber) === -1) {
              policyMortgagees.push({
                Name: mortgagee.Name, AdditionalName: mortgagee.AdditionalName, LoanNumber: mortgagee.LoanNumber, Address: mortgagee.Address,
              });
            }
          }
        }
      }

      if (policyDetails.FarmLocations) {
        for (let l = 0; l < policyDetails.FarmLocations.length; l += 1) {
          const location = policyDetails.FarmLocations[l];
          if (location) {
            if (location.Dwellings) {
              for (let d = 0; d < location.Dwellings.length; d += 1) {
                const dwelling = location.Dwellings[d];
                if (dwelling) {
                  if (dwelling.Type && dwelling.Type.toUpperCase() === 'MAIN DWELLING') {
                    policyReturn.PropertyConstruction = dwelling.Construction;

                    if (dwelling.Address) {
                      policyReturn.PropertyLocation = helpers.methods.buildSingleLineAddress(dwelling.Address.Address1, dwelling.Address.Address2, dwelling.Address.Address3, dwelling.Address.City,
                        dwelling.Address.State, dwelling.Address.ZipCode, dwelling.Address.ZipCode4, dwelling.Address.ZipCode2);
                    }

                    if (dwelling.Mortgagees) {
                      for (let m = 0; m < dwelling.Mortgagees.length; m += 1) {
                        const mortgagee = dwelling.Mortgagees[m];
                        if (mortgagee && policyMortgagees.indexOf(mortgagee.Name) === -1 && policyMortgagees.indexOf(mortgagee.LoanNumber) === -1) {
                          policyMortgagees.push({
                            Name: mortgagee.Name, AdditionalName: mortgagee.AdditionalName, LoanNumber: mortgagee.LoanNumber, Address: mortgagee.Address,
                          });
                        }
                      }
                    } else if (dwelling.InterestedThirdParty) {
                      for (let m = 0; m < dwelling.InterestedThirdParty.length; m += 1) {
                        const mortgagee = dwelling.InterestedThirdParty[m];
                        if (mortgagee && policyMortgagees.indexOf(mortgagee.Name) === -1 && policyMortgagees.indexOf(mortgagee.LoanNumber) === -1) {
                          policyMortgagees.push({
                            Name: mortgagee.Name, AdditionalName: mortgagee.AdditionalName, LoanNumber: mortgagee.LoanNumber, Address: mortgagee.Address,
                          });
                        }
                      }
                    }

                    // TAA Section I
                    if (dwelling.Coverages) {
                      for (let c = 0; c < dwelling.Coverages.length; c += 1) {
                        const coverage = dwelling.Coverages[c];
                        if (coverage) {
                          policyCoverages.push({ Description: coverage.Description, AmountDescription: coverage.AmountDescription, Limit: coverage.Limit });
                        }
                      }
                    }

                    if (dwelling.Discounts) {
                      for (let s = 0; s < dwelling.Discounts.length; s += 1) {
                        const discount = dwelling.Discounts[s];
                        if (discount) {
                          if (discount.DiscountAmount) {
                            if (discount.DiscountAmount !== '') {
                              if (discount.DiscountAmount.indexOf('%') === -1) {
                                discount.DiscountAmount = `${(Number(discount.DiscountAmount) * 100)}%`;
                              }
                            } else if (discount.PremiumAdjustment) {
                              discount.DiscountAmount = discount.PremiumAdjustment.toString();
                            }
                          }

                          if (policyDiscounts.indexOf(discount.Description) === -1 && discount.DiscountAmount !== '' && discount.DiscountAmount !== '0') {
                            // New discount
                            policyDiscounts.push({ Name: discount.Description, DiscountAmount: discount.DiscountAmount, PremiumAdjustment: discount.PremiumAdjustment });
                          }
                        }
                      }
                    }

                    if (policyDetails.PolicyType === 'AA' || policyDetails.PolicyType === 'AT') {
                      if (dwelling.Endorsements) {
                        for (let e = 0; e < dwelling.Endorsements.length; e += 1) {
                          const endorsement = dwelling.Endorsements[e];
                          if (endorsement && endorsement.Number) {
                            if (endorsement.Number.indexOf('TC-') !== -1) {
                              endorsement.Number = endorsement.Number.replace('TC-', 'TAA-');
                            }

                            if (policyEndorsements.indexOf(endorsement.Number) === -1) {
                              // New endorsement
                              policyEndorsements.push({
                                Id: endorsement.Number, Name: (endorsement.ItemDescription || 'Unavailable'), Premium: endorsement.Premium, Limit: endorsement.LimitsOfLiability,
                              });
                            }
                          }
                        }
                      }
                    }
                  } else {
                    // TAA Section III
                    policySecIIICoverages.push({ ItemNumber: dwelling.ItemNumber, Description: dwelling.Type, CoverageAmount: dwelling.Limit });

                    if (dwelling.Mortgagees) {
                      for (let m = 0; m < dwelling.Mortgagees.length; m += 1) {
                        const mortgagee = dwelling.Mortgagees[m];
                        if (mortgagee && policyMortgagees.indexOf(mortgagee.Name) === -1 && policyMortgagees.indexOf(mortgagee.LoanNumber) === -1) {
                          policyMortgagees.push({
                            Name: mortgagee.Name, AdditionalName: mortgagee.AdditionalName, LoanNumber: mortgagee.LoanNumber, Address: mortgagee.Address,
                          });
                        }
                      }
                    }
                  }
                }
              }
            }

            if (location.Barns) {
              for (let b = 0; b < location.Barns.length; b += 1) {
                const barn = location.Barns[b];
                if (barn) {
                  policySecIIICoverages.push({ ItemNumber: barn.ItemNumber, Description: barn.TypeOfUse, CoverageAmount: barn.CoverageAmount });

                  if (barn.Mortgagees) {
                    for (let m = 0; m < barn.Mortgagees.length; m += 1) {
                      const mortgagee = barn.Mortgagees[m];
                      if (mortgagee && policyMortgagees.indexOf(mortgagee.Name) === -1 && policyMortgagees.indexOf(mortgagee.LoanNumber) === -1) {
                        policyMortgagees.push({
                          Name: mortgagee.Name, AdditionalName: mortgagee.AdditionalName, LoanNumber: mortgagee.LoanNumber, Address: mortgagee.Address,
                        });
                      }
                    }
                  }
                }
              }
            }

            if (location.BarnContents) {
              for (let c = 0; c < location.BarnContents.length; c += 1) {
                const barnContents = location.BarnContents[c];
                if (barnContents) {
                  if (!barnContents.Description) {
                    barnContents.Description = barnContents.FarmBuildingContentsType;
                  }

                  policySecIIICoverages.push({ ItemNumber: barnContents.ItemNumber, Description: barnContents.Description, CoverageAmount: barnContents.CoverageAmount });
                }
              }
            }

            if (location.LDW) {
              for (let w = 0; w < location.LDW.length; w += 1) {
                const ldw = location.LDW[w];
                if (ldw) {
                  policyCoverages.push({ Description: ldw.Description, AmountDescription: '', Limit: ldw.MedicalPayments });
                }
              }
            }

            if (location.OTR) {
              for (let o = 0; o < location.OTR.length; o += 1) {
                const otr = location.OTR[o];
                if (otr) {
                  policySecIIICoverages.push({ ItemNumber: otr.ItemNumber, Description: otr.Description, CoverageAmount: otr.CoverageAmount });
                }
              }
            }
          }
        }
      }

      if (policyDetails.Coverages) {
        for (let c = 0; c < policyDetails.Coverages.length; c += 1) {
          const coverage = policyDetails.Coverages[c];
          if (coverage) {
            // Handle Majesco policy coverages
            if (policyDetails.PolicyType === 'CF' || policyDetails.PolicyType === 'CP' || policyDetails.PolicyType === 'TC') {
              coverage.LocationAddress = helpers.methods.buildSingleLineAddress(coverage.AddressLineOne, coverage.AddressLineTwo, null, coverage.AddressCity,
                coverage.AddressState, coverage.AddressZipCode, coverage.AddressZipCodePlusFour, coverage.AddressZipCodePlusTwo);
            } else {
              if (coverage.Limit && coverage.Limit.indexOf('/') !== -1) {
                // Split limit and format pieces
                const splitLimit = coverage.Limit.split('/');
                coverage.Limit = `${helpers.methods.formatMoney(splitLimit[0].toString(), 0)}/${helpers.methods.formatMoney(splitLimit[1].toString(), 0)}`;
              }
              if (coverage.LossPayee) {
                for (let m = 0; m < coverage.LossPayee.length; m += 1) {
                  const mortgagee = coverage.LossPayee[m];
                  if (mortgagee && mortgagee.Name && policyMortgagees.indexOf(mortgagee.Name) === -1 && policyMortgagees.indexOf(mortgagee.LoanNumber) === -1) {
                    policyMortgagees.push({
                      Name: mortgagee.Name, AdditionalName: mortgagee.AdditionalName, LoanNumber: mortgagee.LoanNumber, Address: mortgagee.Address,
                    });
                  }
                }
              }

              policyCoverages.push({
                Description: coverage.Description, ItemNumberDescription: coverage.ItemNumberDescription, AmountDescription: coverage.AmountDescription, Limit: coverage.Limit,
              });
            }
          }
        }
      }

      // TAA Section I & II
      if (policyDetails.BusinessPursuits) {
        for (let b = 0; b < policyDetails.BusinessPursuits.length; b += 1) {
          const businessPursuits = policyDetails.BusinessPursuits[b];
          if (businessPursuits && businessPursuits.Coverages) {
            for (let c = 0; c < businessPursuits.Coverages.length; c += 1) {
              const coverage = businessPursuits.Coverages[c];
              if (coverage) {
                policyCoverages.push({ Description: coverage.Description, AmountDescription: coverage.AmountDescription, Limit: coverage.Limit });
              }
            }
          }
        }
      }

      if (policyDetails.NonFarmLocations) {
        for (let n = 0; n < policyDetails.NonFarmLocations.length; n += 1) {
          const nonFarmLocations = policyDetails.NonFarmLocations[n];
          if (nonFarmLocations && nonFarmLocations.FLI) {
            for (let f = 0; f < nonFarmLocations.FLI.length; f += 1) {
              const fli = nonFarmLocations.FLI[f];
              if (fli && fli.Coverages) {
                for (let c = 0; c < fli.Coverages.length; c += 1) {
                  const coverage = fli.Coverages[c];
                  policyCoverages.push({ Description: coverage.Description, AmountDescription: coverage.AmountDescription, Limit: coverage.Limit });
                }
              }
            }
          }
        }
      }

      if (policyDetails.Watercraft) {
        for (let w = 0; w < policyDetails.Watercraft.length; w += 1) {
          const watercraft = policyDetails.Watercraft[w];
          if (watercraft && watercraft.Coverages) {
            for (let c = 0; c < watercraft.Coverages.length; c += 1) {
              const coverage = watercraft.Coverages[c];
              if (coverage) {
                policyCoverages.push({ Description: coverage.Description, AmountDescription: coverage.AmountDescription, Limit: coverage.Limit });
              }
            }
          }
        }
      }

      // TAA Section IV
      if (policyDetails.FarmEquipment) {
        for (let f = 0; f < policyDetails.FarmEquipment.length; f += 1) {
          const farmEquipment = policyDetails.FarmEquipment[f];
          if (farmEquipment && farmEquipment.CoverageAmount !== 'INACTIVE') {
            if (farmEquipment.Year) {
              policySecIVCoverages.push({
                ItemNumber: farmEquipment.ItemNumber, Description: `${farmEquipment.Year} ${farmEquipment.Make} ${farmEquipment.Model}`, CoverageAmount: farmEquipment.CoverageAmount,
              });
            } else {
              policySecIVCoverages.push({ ItemNumber: farmEquipment.ItemNumber, Description: farmEquipment.Description, CoverageAmount: farmEquipment.CoverageAmount });
            }

            if (farmEquipment.Mortgagees) {
              for (let m = 0; m < farmEquipment.Mortgagees.length; m += 1) {
                const mortgagee = farmEquipment.Mortgagees[m];
                if (mortgagee && policyMortgagees.indexOf(mortgagee.Name) === -1 && policyMortgagees.indexOf(mortgagee.LoanNumber) === -1) {
                  policyMortgagees.push({
                    Name: mortgagee.Name, AdditionalName: mortgagee.AdditionalName, LoanNumber: mortgagee.LoanNumber, Address: mortgagee.Address,
                  });
                }
              }
            }
          }
        }
      }

      if (policyDetails.SpecialtyEquipment) {
        for (let s = 0; s < policyDetails.SpecialtyEquipment.length; s += 1) {
          const specialtyEquipment = policyDetails.SpecialtyEquipment[s];
          if (specialtyEquipment) {
            policySecIVCoverages.push({ ItemNumber: specialtyEquipment.ItemNumber, Description: specialtyEquipment.Description, CoverageAmount: specialtyEquipment.CoverageAmount });
          }
        }
      }

      if (policyDetails.PivotSprinklers) {
        for (let p = 0; p < policyDetails.PivotSprinklers.length; p += 1) {
          const pivotSprinklers = policyDetails.PivotSprinklers[p];
          if (pivotSprinklers) {
            policySecIVCoverages.push({ ItemNumber: pivotSprinklers.ItemNumber, Description: pivotSprinklers.Description, CoverageAmount: pivotSprinklers.CoverageAmount });
          }
        }
      }

      if (policyDetails.Irrigation) {
        for (let i = 0; i < policyDetails.Irrigation.length; i += 1) {
          const irrigation = policyDetails.Irrigation[i];
          if (irrigation) {
            policySecIVCoverages.push({ ItemNumber: irrigation.ItemNumber, Description: irrigation.Description, CoverageAmount: irrigation.CoverageAmount });
          }
        }
      }

      // CPP/GL Locations
      if (policyDetails.Locations && policyDetails.PolicySubType !== '3') {
        for (let l = 0; l < policyDetails.Locations.length; l += 1) {
          const location = policyDetails.Locations[l];
          if (location && location.AddressLineOne && location.AddressLineOne !== null) {
            location.LocationAddress = helpers.methods.buildSingleLineAddress(location.AddressLineOne, location.AddressLineTwo, null, location.AddressCity,
              location.AddressState, location.AddressZipCode, location.AddressZipCodePlusFour, location.AddressZipCodePlusTwo);
          }
        }

        policyReturn.Locations = policyDetails.Locations;
      }

      // Do we have drivers?
      if (policyDetails.CoveredDrivers) {
        for (let d = 0; d < policyDetails.CoveredDrivers.length; d += 1) {
          const driver = policyDetails.CoveredDrivers[d];
          if (driver) {
            if (driver.FirstName && driver.LastName) {
              driver.Name = `${driver.FirstName.trim()} ${driver.LastName.trim()}`;
            } else {
              driver.Name = 'Unknown';
            }
            if (driver.DateOfBirth) {
              driver.DateOfBirth = helpers.methods.formatYearDate(driver.DateOfBirth);
            } else {
              driver.DateOfBirth = 'N/A';
            }
            if (driver.DriversLicenseNumber) {
              driver.MaskedDriversLicenseNumber = helpers.methods.maskString(driver.DriversLicenseNumber, '4', 'X');
            } else {
              driver.MaskedDriversLicenseNumber = 'N/A';
            }
          }
        }

        policyReturn.CoveredDrivers = policyDetails.CoveredDrivers;
      }

      // Do we have vehicles?
      if (policyDetails.Vehicles) {
        // Loop through the vehicles
        for (let v = 0; v < policyDetails.Vehicles.length; v += 1) {
          const vehicle = policyDetails.Vehicles[v];
          const filteredVehicleCoverages = [];

          // Do we have a vehicle?
          if (vehicle) {
            // Calculate the vehicle premium by adding the total coverage premium to the total discounts
            if (vehicle.TotalCoveragePremium && vehicle.TotalDiscounts && !_.isNaN(vehicle.TotalCoveragePremium) && !_.isNaN(vehicle.TotalDiscounts)) {
              vehicle.CurrentVehiclePremium = (Number(vehicle.TotalCoveragePremium) + Number(vehicle.TotalDiscounts));
            }

            // Do we have a Class Code?
            if (vehicle.ClassCode) {
              // Have a Class Code; do we have a Vehicle Class Code?
              if (!vehicle.VehicleClass) {
                // No Vehicle Class Code; set Vehicle Class Code to Class Code
                vehicle.VehicleClass = vehicle.ClassCode;
              }
              // else - we have both so all is well
            } else if (vehicle.VehicleClass) {
              // Have Vehicle Class Code; set Class Code to Vehicle Class Code
              vehicle.ClassCode = vehicle.VehicleClass;
            }

            if (vehicle.LienHolders && vehicle.LienHolders.length > 0) {
              for (let l = 0; l < vehicle.LienHolders.length; l += 1) {
                const lienholder = vehicle.LienHolders[l];
                // Do we have a vehicle with a lienholder?
                if (lienholder && lienholder.Address && lienholder.Address.City) {
                  // Build the coverage section name
                  lienholder.LienHolderAddress = helpers.methods.buildSingleLineAddress(lienholder.Address.Address1, lienholder.Address.Address2, lienholder.Address.Address3,
                    lienholder.Address.City, lienholder.Address.State, lienholder.Address.ZipCode, lienholder.Address.ZipCode4, null);
                } else if (lienholder && lienholder.Address) {
                  // Build the coverage section name
                  lienholder.LienHolderAddress = lienholder.Address;
                } else {
                  lienholder.LienHolderAddress = '';
                }
              }
            }

            // Is this a Legacy Six Month policy?
            if (policyDetails.PolicyType === 'SM' && policyDetails.PolicySubType === '0') {
              if (vehicle.Coverages) {
                for (let c = 0; c < vehicle.Coverages.length; c += 1) {
                  const coverage = vehicle.Coverages[c];
                  if (coverage) {
                    coverage.CoverageTypeDisplay = coverage.CoverageType;
                    coverage.CoverageLimitDisplay = coverage.Limit;
                    filteredVehicleCoverages.push(coverage);
                  }
                }

                vehicle.Coverages = filteredVehicleCoverages;
              }
            }

            // Do we have vehicle discounts?
            if (vehicle.Discounts) {
              let vehicleDiscounts = '';
              const cleanedVehicleDiscounts = [];

              for (let d = 0; d < vehicle.Discounts.length; d += 1) {
                const discount = vehicle.Discounts[d];
                if (discount && vehicleDiscounts.indexOf(discount.DiscountType) === -1) {
                  vehicleDiscounts += `,${discount.DiscountType}`;

                  // Clean up the wording to match the Vehicle level field name
                  let cleanedDiscountType = helpers.methods.replaceAll(discount.DiscountType, '-', '');
                  cleanedDiscountType = helpers.methods.replaceAll(cleanedDiscountType, ' ', '');
                  cleanedDiscountType = helpers.methods.replaceAll(cleanedDiscountType, '5064', '50To64');
                  cleanedDiscountType = helpers.methods.replaceAll(cleanedDiscountType, 'Restraints', 'Restraint');
                  cleanedDiscountType = helpers.methods.replaceAll(cleanedDiscountType, 'Acheivement', 'Achievement');
                  cleanedDiscountType = `${cleanedDiscountType}Description`;

                  discount.DescriptionDisplay = vehicle[cleanedDiscountType];
                  if (discount.DiscountAmount) {
                    if (discount.DiscountAmount !== '') {
                      if (discount.DiscountAmount.indexOf('%') === -1) {
                        discount.DiscountAmount = `${(Number(discount.DiscountAmount) * 100)}%`;
                      }
                    } else if (discount.PremiumAdjustment) {
                      discount.DiscountAmount = helpers.methods.formatMoney(discount.PremiumAdjustment.toString(), 2);
                    }
                  }

                  cleanedVehicleDiscounts.push(discount);
                }
              }

              vehicle.Discounts = cleanedVehicleDiscounts;
            }

            // Does this vehicle have endorsements?
            if (!vehicle.Endorsements || vehicle.Endorsements.length <= 0) {
              // No vehicle endorsements; set vehicle endorsements to policy endorsements
              vehicle.Endorsements = policyDetails.Endorsements;
            }
          }
        }

        policyReturn.Vehicles = policyDetails.Vehicles;
      }

      // Sort the accumulated discounts and set them to the policy level Discounts
      if (policyDiscounts.length > 0) {
        policyReturn.Discounts = _.sortBy(policyDiscounts, ['Name']);
      } else {
        policyReturn.Discounts = policyDiscounts;
      }

      // Sort the accumulated endorsements and set them to the policy level Endorsements
      if (policyEndorsements.length > 0) {
        policyReturn.Endorsements = _.sortBy(policyEndorsements, ['Id']);
      } else {
        policyReturn.Endorsements = policyEndorsements;
      }

      if ((policyDetails.PolicyType === 'CF' || policyDetails.PolicyType === 'CP' || policyDetails.PolicyType === 'TC') && policyDetails.PolicySubType !== '3') {
        policyReturn.Coverages = policyDetails.Coverages;
      } else {
        // Set the accumulated coverages to the policy level coverages
        policyReturn.Coverages = policyCoverages;
      }

      // Set the accumulated coverages to the policy level coverages
      policyReturn.SectionIIICoverages = policySecIIICoverages;

      // Set the accumulated coverages to the policy level coverages
      policyReturn.SectionIVCoverages = policySecIVCoverages;

      // Set the accumulated mortgagees to the policy level Mortgagees
      policyReturn.Mortgagees = policyMortgagees;
    }

    // console.log(policyReturn);
    return policyReturn;
  } catch (error) {
    context.dispatch('app/logToULS', {
      logLevel: 'Error', logMessage: error, fileName: 'policy.js', methodName: 'policyDetailsTransform', trackerId: tfbId,
    }, { root: true });
    return policyReturn;
  }
}

function getDefaultState() {
  return {
    policyNumber: '',
    accountSummary: [],
    paperlessRequest: null,
  };
}

// mutations
const mutations = {
  SET_ACCOUNT_SUMMARY(state, value) {
    const parsed = JSON.stringify(value);
    sessionStorage.setItem('tfb_summary', parsed);

    state.accountSummary = value;
  },
  CLEAR_ACCOUNT_SUMMARY(state) {
    sessionStorage.removeItem('tfb_summary');

    state.accountSummary = [];
  },
  SET_PAPERLESS_REQUEST(state, value) {
    state.paperlessRequest = value;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

// getters
const getters = {
  accountSummary(state) {
    if (!state.accountSummary || state.accountSummary === null || state.accountSummary.length === 0) {
      const tfbSummary = sessionStorage.getItem('tfb_summary');
      if (tfbSummary !== null) {
        const tfbParsedSummary = JSON.parse(tfbSummary);
        state.accountSummary = tfbParsedSummary;
      }
    }

    return state.accountSummary;
  },
  paperlessRequest(state) {
    return state.paperlessRequest;
  },
};

// state
const state = getDefaultState();

// actions
const actions = {
  getAccountSummary(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'policy.js', methodName: 'getAccountSummary-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    if (state.accountSummary && state.accountSummary.length > 0) {
      return state.accountSummary;
    }

    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${policyLookupServiceUrl}/Account/Summary/${sRequest.sMemberNumber.trim()}?access_token=${ssoToken}`, config)
        .then((policyResult) => {
          if (policyResult && policyResult.data) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(policyResult.data), fileName: 'policy.js', methodName: 'getAccountSummary-Result', trackerId: sRequest.sTfbId,
            }, { root: true });
            if (policyResult.data.ErrorMessage === null || policyResult.data.ErrorMessage === '') {
              const accountSummary = summaryTransform(policyResult.data, context, sRequest.sTfbId);
              context.commit('SET_ACCOUNT_SUMMARY', accountSummary);
              resolve(accountSummary);
            } else {
              reject(new Error(policyResult.data.ErrorMessage));
            }
          }

          reject(new Error(genericErrorMessage));
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'policy.js', methodName: 'getAccountSummary-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getPolicyDetails(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'policy.js', methodName: 'getPolicyDetails-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      let policyDetail = null;
      const tfbSummary = sessionStorage.getItem('tfb_summary');

      if (tfbSummary !== null) {
        const tfbParsedSummary = JSON.parse(tfbSummary);
        for (let i = 0; i < tfbParsedSummary.length; i += 1) {
          const policy = tfbParsedSummary[i];

          if (policy.PolicyNumber === sRequest.sPolicyNumber) {
            policyDetail = policy;
          }
        }
      }

      if (policyDetail !== null) {
        if (sRequest.sPolicyType === 'six-month') {
          context.dispatch('getPA6PolicyInformation', {
            sPolicyDetail: policyDetail, sPolicyNumber: sRequest.sPolicyNumber, sTfbId: sRequest.sTfbId,
          })
            .then((policyResult) => {
              resolve(policyResult);
            })
            .catch((error) => {
              reject(error);
            });
        } else if (sRequest.sPolicyType === 'commercial-auto') {
          context.dispatch('getCompAutoPolicyInformation', {
            sPolicyDetail: policyDetail, sPolicyNumber: sRequest.sPolicyNumber, sTfbId: sRequest.sTfbId,
          })
            .then((policyResult) => {
              resolve(policyResult);
            })
            .catch((error) => {
              reject(error);
            });
        } else if (sRequest.sPolicyType === 'premium-finance') {
          context.dispatch('getPremiumFinanceInformation', {
            sPolicyDetail: policyDetail, sContractNumber: sRequest.sPolicyNumber, sTfbId: sRequest.sTfbId,
          })
            .then((policyResult) => {
              resolve(policyResult);
            })
            .catch((error) => {
              reject(error);
            });
        } else if (sRequest.sPolicyType === 'standard-fire' || sRequest.sPolicyType === 'inland-marine') {
          context.dispatch('getSFPolicyInformation', {
            sPolicyDetail: policyDetail, sPolicyNumber: sRequest.sPolicyNumber, sPolicyType: policyDetail.PolicyType, sPolicySubType: policyDetail.PolicySubType, sTfbId: sRequest.sTfbId,
          })
            .then((policyResult) => {
              resolve(policyResult);
            })
            .catch((error) => {
              reject(error);
            });
        } else if (sRequest.sPolicyType === 'texas-agadvantage') {
          context.dispatch('getTAAPolicyInformation', {
            sPolicyDetail: policyDetail, sPolicyNumber: sRequest.sPolicyNumber, sPolicyType: policyDetail.PolicyType, sPolicySubType: policyDetail.PolicySubType, sTfbId: sRequest.sTfbId,
          })
            .then((policyResult) => {
              resolve(policyResult);
            })
            .catch((error) => {
              reject(error);
            });
        } else if (sRequest.sPolicyType === 'homeowners') {
          context.dispatch('getHOLPolicyInformation', {
            sPolicyDetail: policyDetail, sPolicyNumber: sRequest.sPolicyNumber, sPolicyType: policyDetail.PolicyType, sPolicySubType: policyDetail.PolicySubType, sTfbId: sRequest.sTfbId,
          })
            .then((policyResult) => {
              resolve(policyResult);
            })
            .catch((error) => {
              reject(error);
            });
        } else if (sRequest.sPolicyType === 'umbrella' || sRequest.sPolicyType === 'commercial-package' || sRequest.sPolicyType === 'commercial-fire'
          || sRequest.sPolicyType === 'general-liability' || sRequest.sPolicyType === 'farm-liability') {
          context.dispatch('getMajescoPolicyInformation', {
            sPolicyDetail: policyDetail, sPolicyNumber: sRequest.sPolicyNumber, sPolicyType: policyDetail.PolicyType, sPolicySubType: policyDetail.PolicySubType, sTfbId: sRequest.sTfbId,
          })
            .then((policyResult) => {
              resolve(policyResult);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: `Unknown policy type: ${sRequest.sPolicyType}`, fileName: 'policy.js', methodName: 'getPolicyDetails-UnknownType', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(new Error(`Unknown policy type: ${sRequest.sPolicyType}`));
        }
      } else {
        context.dispatch('app/logToULS', {
          logLevel: 'Error', logMessage: 'No policy details found', fileName: 'policy.js', methodName: 'getPolicyDetails-NoDetails', trackerId: sRequest.sTfbId,
        }, { root: true });
        reject(new Error('No policy details found'));
      }
    });
  },
  getCompAutoPolicyInformation(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'policy.js', methodName: 'getCompAutoPolicyInformation-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${compAutoLookupServiceUrl}/Policy?PolicyNumber=${sRequest.sPolicyNumber}&PolicyType=CA&PolicySubType=0`, config)
        .then((policyResult) => {
          if (policyResult && policyResult.data) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(policyResult.data), fileName: 'policy.js', methodName: 'getCompAutoPolicyInformation-Result', trackerId: sRequest.sTfbId,
            }, { root: true });
            resolve(policyDetailsTransform(policyResult.data, context, sRequest.sPolicyDetail, sRequest.sTfbId));
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'policy.js', methodName: 'getCompAutoPolicyInformation-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getHOLPolicyInformation(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'policy.js', methodName: 'getHOLPolicyInformation-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${holLookupServiceUrl}/PolicyHolder/${sRequest.sPolicyNumber}/${sRequest.sPolicyType}/${sRequest.sPolicySubType}`, config)
        .then((policyResult) => {
          if (policyResult && policyResult.data) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(policyResult.data), fileName: 'policy.js', methodName: 'getHOLPolicyInformation-Result', trackerId: sRequest.sTfbId,
            }, { root: true });
            resolve(policyHolderPropertyTransform(policyResult.data, context, sRequest.sPolicyDetail, sRequest.sTfbId));
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'policy.js', methodName: 'getHOLPolicyInformation-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getPremiumFinanceInformation(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'policy.js', methodName: 'getPremiumFinanceInformation-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${premiumFinanceLookupServiceUrl}/PremiumFinance/Info/${sRequest.sContractNumber}`, config)
        .then((policyResult) => {
          if (policyResult && policyResult.data) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(policyResult.data), fileName: 'policy.js', methodName: 'getPremiumFinanceInformation-Result', trackerId: sRequest.sTfbId,
            }, { root: true });
            if (policyResult.data.ErrorMessage === null || policyResult.data.ErrorMessage === '') {
              resolve(premiumFinanceTransform(policyResult.data, context, sRequest.sPolicyDetail, sRequest.sTfbId));
            } else {
              reject(new Error(policyResult.data.ErrorMessage));
            }
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'policy.js', methodName: 'getPremiumFinanceInformation-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getSFPolicyInformation(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'policy.js', methodName: 'getSFPolicyInformation-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;

      let url = standardFireLookupServiceUrl;
      if (sRequest.sPolicyType === 'PF' && sRequest.sPolicySubType === '0') {
        url += `/inland-marine/${sRequest.sPolicyNumber}/${sRequest.sPolicyType}/${sRequest.sPolicySubType}`;
      } else if (sRequest.sPolicyType === 'PF' && sRequest.sPolicySubType === '1') {
        url += `/inland-marine/policyholder/${sRequest.sPolicyNumber}/${sRequest.sPolicyType}/${sRequest.sPolicySubType}`;
      } else if (sRequest.sPolicyType === 'SF' && sRequest.sPolicySubType === '0') {
        url += `/standard-fire/${sRequest.sPolicyNumber}/${sRequest.sPolicyType}/${sRequest.sPolicySubType}`;
      } else if (sRequest.sPolicyType === 'SF' && sRequest.sPolicySubType === '1') {
        url += `/standard-fire/policyholder/${sRequest.sPolicyNumber}/${sRequest.sPolicyType}/${sRequest.sPolicySubType}`;
      }

      axios.get(url, config)
        .then((policyResult) => {
          if (policyResult && policyResult.data) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(policyResult.data), fileName: 'policy.js', methodName: 'getSFPolicyInformation-Result', trackerId: sRequest.sTfbId,
            }, { root: true });
            if (!policyResult.data.ErrorMessage || policyResult.data.ErrorMessage === null || policyResult.data.ErrorMessage === '') {
              if (sRequest.sPolicySubType === '0') {
                resolve(policyDetailsTransform(policyResult.data, context, sRequest.sPolicyDetail, sRequest.sTfbId));
              } else {
                resolve(policyHolderPropertyTransform(policyResult.data, context, sRequest.sPolicyDetail, sRequest.sTfbId));
              }
            } else {
              reject(new Error(policyResult.data.ErrorMessage));
            }
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'policy.js', methodName: 'getSFPolicyInformation-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getPA6PolicyInformation(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'policy.js', methodName: 'getPA6PolicyInformation-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${pa6LookupServiceUrl}/policyholder/${sRequest.sPolicyNumber}`, config)
        .then((policyResult) => {
          if (policyResult && policyResult.data) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(policyResult.data), fileName: 'policy.js', methodName: 'getPA6PolicyInformation-Result', trackerId: sRequest.sTfbId,
            }, { root: true });
            resolve(policyHolderAutoTransform(policyResult.data, context, sRequest.sPolicyDetail, sRequest.sTfbId));
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'policy.js', methodName: 'getPA6PolicyInformation-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getTAAPolicyInformation(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'policy.js', methodName: 'getTAAPolicyInformation-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;
      axios.get(`${texasAgLookupServiceUrl}/PolicyHolder?PolicyNumber=${sRequest.sPolicyNumber}&PolicyType=${sRequest.sPolicyType}&PolicySubType=${sRequest.sPolicySubType}`, config)
        .then((policyResult) => {
          if (policyResult && policyResult.data) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(policyResult.data), fileName: 'policy.js', methodName: 'getTAAPolicyInformation-Result', trackerId: sRequest.sTfbId,
            }, { root: true });
            resolve(policyHolderPropertyTransform(policyResult.data, context, sRequest.sPolicyDetail, sRequest.sTfbId));
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'policy.js', methodName: 'getTAAPolicyInformation-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getMajescoPolicyInformation(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'policy.js', methodName: 'getMajescoPolicyInformation-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;

      let url = majescoLookupServiceUrl;
      if (sRequest.sPolicyType === 'UM') {
        url += `/umbrella/policyholder/${sRequest.sPolicyNumber}`;
      } else if (sRequest.sPolicyType === 'TC') {
        url += `/commercial-package/policyholder/${sRequest.sPolicyNumber}`;
      } else if (sRequest.sPolicyType === 'CF') {
        url += `/commercial-property/policyholder/${sRequest.sPolicyNumber}`;
      } else if (sRequest.sPolicyType === 'CP' && sRequest.sPolicySubType === '2') {
        url += `/general-liability/policyholder/${sRequest.sPolicyNumber}`;
      } else if (sRequest.sPolicyType === 'CP' && sRequest.sPolicySubType === '3') {
        url += `/farm-liability/policyholder/${sRequest.sPolicyNumber}`;
      }

      axios.get(url, config)
        .then((policyResult) => {
          if (policyResult && policyResult.data) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(policyResult.data), fileName: 'policy.js', methodName: 'getMajescoPolicyInformation-Result', trackerId: sRequest.sTfbId,
            }, { root: true });
            resolve(policyDetailsTransform(policyResult.data, context, sRequest.sPolicyDetail, sRequest.sTfbId));
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'policy.js', methodName: 'getMajescoPolicyInformation-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getPaperlessInformation(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'policy.js', methodName: 'getPaperlessInformation-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;

      axios.post(`${policyLookupServiceUrl}/paperless/lookup`, { MemberNumber: sRequest.sMemberNumber, PolicyNumber: sRequest.sPolicyNumber, PolicyType: sRequest.sPolicyType }, config)
        .then((policyResult) => {
          if (policyResult && policyResult.data) {
            context.dispatch('app/logToULS', {
              logLevel: 'Info', logMessage: JSON.stringify(policyResult.data), fileName: 'policy.js', methodName: 'getPaperlessInformation-Result', trackerId: sRequest.sTfbId,
            }, { root: true });
            resolve(policyResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'policy.js', methodName: 'getPaperlessInformation-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  setupPaperless(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'policy.js', methodName: 'setupPaperless-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;

      const changeRequest = [];
      for (let i = 0; i < sRequest.sPolicyList.length; i += 1) {
        const policy = sRequest.sPolicyList[i];
        changeRequest.push({
          MemberEmailAddress: sRequest.sEmailAddress,
          MemberPhoneNumber: '',
          MemberId: sRequest.sMemberId,
          MemberInitials: policy.MemberInitials,
          MemberNumber: sRequest.sMemberNumber,
          PolicyNumber: policy.PolicyNumber,
          PolicyType: policy.PolicyType,
          MemberEnrollmentType: 'EML',
        });
      }
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: JSON.stringify(changeRequest), fileName: 'policy.js', methodName: 'setupPaperless-SendRequest', trackerId: sRequest.sTfbId,
      }, { root: true });

      axios.post(`${policyLookupServiceUrl}/paperless/enroll`, changeRequest, config)
        .then((setupResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(setupResult), fileName: 'policy.js', methodName: 'setupPaperless-Result', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (setupResult && setupResult.data) {
            resolve(setupResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'policy.js', methodName: 'setupPaperless-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  removePaperless(context, sRequest) {
    context.dispatch('app/logToULS', {
      logLevel: 'Info', logMessage: JSON.stringify(sRequest), fileName: 'policy.js', methodName: 'removePaperless-Start', trackerId: sRequest.sTfbId,
    }, { root: true });
    return new Promise((resolve, reject) => {
      const ssoToken = Vue.$cookies.get(tokenName);
      config.headers.Authorization = `Bearer ${ssoToken}`;

      const changeRequest = [];
      for (let i = 0; i < sRequest.sPolicyList.length; i += 1) {
        const policy = sRequest.sPolicyList[i];
        changeRequest.push({
          PolicyNumber: policy.PolicyNumber,
          PolicyType: policy.PolicyType,
          MemberNumber: sRequest.sMemberNumber,
          MemberId: sRequest.sMemberId,
          MemberInitials: policy.MemberInitials,
          RemovalType: 'EML',
        });
      }
      context.dispatch('app/logToULS', {
        logLevel: 'Info', logMessage: JSON.stringify(changeRequest), fileName: 'policy.js', methodName: 'removePaperless-Sending', trackerId: sRequest.sTfbId,
      }, { root: true });

      axios.post(`${policyLookupServiceUrl}/paperless/unenroll`, changeRequest, config)
        .then((removeResult) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Info', logMessage: JSON.stringify(removeResult), fileName: 'policy.js', methodName: 'removeremovePaperlessEbillInformation-Result', trackerId: sRequest.sTfbId,
          }, { root: true });
          if (removeResult && removeResult.data) {
            resolve(removeResult.data);
          } else {
            reject(new Error(genericErrorMessage));
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'policy.js', methodName: 'removePaperless-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
