<template>
  <div class="background-darker-blue py-12">
    <h2 class="tfb-title-text-large text-center text-white">Quote Summary</h2>
    <v-container fluid
                 class="tfb-smallest-container tfb-quote-box background-white pa-5 my-8">
      <v-row>
        <v-col cols="12">
          <h4 class="tfb-title-text-smaller font-weight-bold text-blue">Quote ID Number: {{agreementNumber}}</h4>
          <h4 class="tfb-title-text-smaller font-weight-bold text-blue">Requested Effective Date: {{formattedEffectiveDate}}</h4>
          <h4 class="tfb-title-text-smaller font-weight-bold text-blue only-on-print">Quote Date: {{quoteDate}}</h4>
        </v-col>
      </v-row>
      <v-row v-if="quoteLoading">
        <v-col cols="12">
          <v-skeleton-loader ref="skeleton"
                             type="article"
                             class="mx-auto"></v-skeleton-loader>
        </v-col>
      </v-row>
      <div v-if="!quoteLoading && totalPremium">
        <v-row class="no-print">
          <v-col cols="12"
                 sm="4">
            <v-btn @click="printQuote()"
                   class="tfb-button-min background-red float-right">Print This Quote</v-btn>
          </v-col>
          <v-col cols="12"
                 sm="4">
            <v-btn @click.stop="email = true"
                   class="tfb-button-min background-red float-right">Email This Quote</v-btn>
          </v-col>
          <v-col cols="12"
                 sm="4">
            <v-btn @click.stop="contact = true"
                   class="tfb-button-min background-red float-right">Request Contact</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-alert prominent
                     class="background-blue"
                     border="bottom"
                     elevation="8"
                     rounded>
              <v-row>
                <v-col cols="12"
                       md="7">
                  <h3 class="tfb-title-text-small font-weight-bold">Your Six Month Premium is:</h3>
                </v-col>
                <v-col cols="12"
                       md="5"
                       class="final-quote-amount">
                  <h3 class="tfb-title-text-small font-weight-bold">{{totalPremium}}</h3>
                  <h4 class="tfb-title-text-smaller" v-show="monthlyPremium">{{monthlyPremium}} per month</h4>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
        </v-row>
        <div v-for="vehicle in vehiclePremiumList"
             :key="vehicle.VehicleSequenceNumber"
             class="py-4">
          <v-row>
            <v-col cols="12"
                   class="tfb-title-text-smaller font-weight-bold text-blue py-0">
              {{vehicle.VehicleDescription}}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   class="text-body-2 py-0">
              {{vehicle.VehiclePremium}} / {{vehicle.VehicleMonthPremium}} per month
            </v-col>
          </v-row>
          <v-data-table :headers="vehicleHeaders"
                        :items="vehicle.VehiclePremiums"
                        dense
                        disable-filtering
                        disable-pagination
                        disable-sort
                        hide-default-footer>
            <template v-slot:item.CoverageText="{ item }">
              <span class="font-weight-bold text-left">{{ item.CoverageText }}</span>
            </template>
          </v-data-table>
        </div>
        <v-row>
          <v-col cols="12"
                 class="pb-0 mb-n2">
            <h4 class="tfb-title-text-smaller font-weight-bold text-blue">Liability</h4>
          </v-col>
        </v-row>
        <v-data-table :headers="liabilityHeaders"
                      :items="liabilityCoverages"
                      dense
                      disable-filtering
                      disable-pagination
                      disable-sort
                      hide-default-footer>
          <template v-slot:item.CoverageText="{ item }">
            <span class="font-weight-bold text-left">{{ item.CoverageText }}</span>
          </template>
        </v-data-table>
        <div v-show="discounts && discounts.length > 0"
             class="pb-6 pt-4">
          <v-row>
            <v-col cols="12"
                   class="pb-0">
              <h4 class="tfb-title-text-smaller font-weight-bold text-blue">Discounts</h4>
            </v-col>
          </v-row>
          <v-row v-for="discount in discounts"
                 :key="discount.id">
            <v-col cols="12"
                   class="py-0 mx-4 text-body-2">
              {{discount}}
            </v-col>
          </v-row>
        </div>
        <v-row class="no-print">
          <v-col cols="12"
                 sm="4">
            <v-btn @click="printQuote()"
                   class="tfb-button-min background-red float-right">Print This Quote</v-btn>
          </v-col>
          <v-col cols="12"
                 sm="4">
            <v-btn @click.stop="email = true"
                   class="tfb-button-min background-red float-right">Email This Quote</v-btn>
          </v-col>
          <v-col cols="12"
                 sm="4">
            <v-btn @click.stop="contact = true"
                   class="tfb-button-min background-red float-right">Request Contact</v-btn>
          </v-col>
        </v-row>
        <v-row class="only-on-print">
          <v-col cols="12"
                 class="tfb-header-text-small">
            This quote summary is intended for informational purposes only. This is not a policy
             contract or binder of coverage. All coverages and rates are subject to normal
             underwriting guidelines and review. After notice to the applicant, consumer reports
             were obtained to help generate this quote.
          </v-col>
        </v-row>
      </div>
      <div v-if="!quoteLoading && !totalPremium">
        <v-row>
          <v-col cols="12">
            Unable to load quote information.
          </v-col>
        </v-row>
      </div>
      <contact-agent v-if="quoteData && quoteData.Quote"
                     :input-email="quoteData.Quote.EmailAddress"
                     :input-phone="quoteData.Quote.PhoneNumber"></contact-agent>
      <quote-email v-if="quoteData && quoteData.Quote"
                   :input-email="quoteData.Quote.EmailAddress"></quote-email>
    </v-container>
  </div>
</template>
<script>
  import { sync } from 'vuex-pathify';
  import tfbHelperMixin from '@/mixins/helpers';
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbMetaMixin from '@/mixins/metaInfo';
  import ContactAgent from './components/ContactAgent.vue';
  import QuoteEmail from './components/QuoteEmail.vue';
  /* eslint no-nested-ternary: 0 */

  export default {
    name: 'AutoQuoteSummary',
    mixins: [tfbHelperMixin, tfbDataMixin, tfbMetaMixin],
    components: {
      ContactAgent,
      QuoteEmail,
    },
    data() {
      return {
        formDisabled: false,
        quoteLoading: false,
        quoteDetails: null,
        vehicleList: null,
        formattedEffectiveDate: null,
        quoteDate: null,
        vehiclePremiumList: [],
        liabilityCoverages: [],
        discounts: null,
        rentalLimit: null,
        roadsideLimit: null,
        medPIP: null,
        monthlyPremium: null,
        totalPremium: null,
        liabilityHeaders: [
          { text: '', value: 'CoverageText' },
          { text: 'Limitations', value: 'Limit' },
        ],
        vehicleHeaders: [
          { text: '', value: 'CoverageText' },
          { text: 'Limitations', value: 'Limit' },
          { text: 'Deductible', value: 'Deductible' },
        ],
      };
    },
    computed: {
      metaDescription() {
        return 'Looking for an auto insurance quote? Use our online tool to learn how Texas Farm Bureau Insurance can help provide coverage for your precious ride.';
      },
      metaPageTitle() {
        return 'Quote Summary | Auto Rate Quote';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/quotes/auto/summary`;
      },
      agreementNumber() {
        try {
          return this.$store.getters['quotes/agreementNumber'];
        } catch (error) {
          return '';
        }
      },
      subAgreementNumber() {
        try {
          return this.$store.getters['quotes/subAgreementNumber'];
        } catch (error) {
          return '';
        }
      },
      quoteData() {
        try {
          return this.$store.getters['quotes/quote'];
        } catch (error) {
          return null;
        }
      },
      contact: sync('quotes/contact'),
      email: sync('quotes/email'),
    },
    methods: {
      getRateQuote() {
        this.$store.dispatch('quotes/getRateQuote', { sAgreementNumber: this.agreementNumber, sSubAgreementNumber: this.subAgreementNumber, sTfbId: this.trackingId })
          .then((quoteResponse) => {
            const declineCoverage = 'I decline this coverage';
            this.vehiclePremiumList = [];
            this.liabilityCoverages = [];
            this.totalPremium = this.formatMoney(quoteResponse.TotalQuote, 2);
            this.monthlyPremium = this.formatMoney(quoteResponse.MonthlyAmountDue, 2);
            this.discounts = quoteResponse.Discounts;

            if (this.quoteDetails.BodilyInjuryLimit && this.quoteDetails.BodilyInjuryLimit !== '' && this.quoteDetails.BodilyInjuryLimit !== '0') {
              const splitBI = this.quoteDetails.BodilyInjuryLimit.split('/');
              const fullBiPerson = `${splitBI[0].toString()}000`;
              const fullBiAccident = `${splitBI[1].toString()}000`;
              this.liabilityCoverages.push({
                CoverageText: 'Bodily Injury Liability',
                Limit: `${this.formatMoney(fullBiPerson, 0)} each person / ${this.formatMoney(fullBiAccident, 0)} each accident`,
              });
            } else {
              this.liabilityCoverages.push({
                CoverageText: 'Bodily Injury Liability',
                Limit: declineCoverage,
              });
            }

            if (this.quoteDetails.PropertyDamageLimit && this.quoteDetails.PropertyDamageLimit !== '' && this.quoteDetails.PropertyDamageLimit !== '0') {
              const fullPropertyDamage = `${this.quoteDetails.PropertyDamageLimit}000`;
              this.liabilityCoverages.push({
                CoverageText: 'Property Damage Liability',
                Limit: `${this.formatMoney(fullPropertyDamage, 0)} each accident`,
              });
            } else {
              this.liabilityCoverages.push({
                CoverageText: 'Property Damage Liability',
                Limit: declineCoverage,
              });
            }

            if (this.quoteDetails.MedicalPIPLimit) {
              switch (this.quoteDetails.MedicalPIPLimit) {
                case '5000PIP':
                  this.medPIP = '$5,000 PIP';
                  break;
                case '2500PIP':
                  this.medPIP = '$2,500 PIP';
                  break;
                case '10000PIP':
                  this.medPIP = '$10,000 PIP';
                  break;
                case '5000MED':
                  this.medPIP = '$5,000 MED';
                  break;
                default:
                  this.medPIP = declineCoverage;
                  break;
              }
            } else {
              this.medPIP = declineCoverage;
            }

            this.liabilityCoverages.push({
              CoverageText: 'Personal Injury Protection or Medical',
              Limit: this.medPIP,
            });

            if (this.quoteDetails.UninsuredBodilyInjuryLimit && this.quoteDetails.UninsuredBodilyInjuryLimit !== '' && this.quoteDetails.UninsuredBodilyInjuryLimit !== '0') {
              const umSplitBI = this.quoteDetails.UninsuredBodilyInjuryLimit.split('/');
              const fullUmBiPerson = `${umSplitBI[0].toString()}000`;
              const fullUmBiAccident = `${umSplitBI[1].toString()}000`;
              this.liabilityCoverages.push({
                CoverageText: 'Uninsured/Underinsured Motorist Bodily Injury Liability',
                Limit: `${this.formatMoney(fullUmBiPerson, 0)} each person / ${this.formatMoney(fullUmBiAccident, 0)} each accident`,
              });
            } else {
              this.liabilityCoverages.push({
                CoverageText: 'Uninsured/Underinsured Motorist Bodily Injury Liability',
                Limit: declineCoverage,
              });
            }

            if (this.quoteDetails.UninsuredPropertyDamageLimit && this.quoteDetails.UninsuredPropertyDamageLimit !== '' && this.quoteDetails.UninsuredPropertyDamageLimit !== '0') {
              const fullUmPropertyDamage = `${this.quoteDetails.UninsuredPropertyDamageLimit}000`;
              this.liabilityCoverages.push({
                CoverageText: 'Uninsured/Underinsured Motorist Property Damage Liability',
                Limit: `${this.formatMoney(fullUmPropertyDamage, 0)} each accident`,
              });
            } else {
              this.liabilityCoverages.push({
                CoverageText: 'Uninsured/Underinsured Motorist Property Damage Liability',
                Limit: declineCoverage,
              });
            }

            if (this.quoteData.DeathIndemnity && this.quoteData.DeathIndemnity.length > 0) {
              this.liabilityCoverages.push({
                CoverageText: 'Death Indemnity',
                Limit: '$5,000 per designated person list',
              });
            }

            if (this.quoteDetails.RentalReimbursementLimit && this.quoteDetails.RentalReimbursementLimit !== '' && this.quoteDetails.RentalReimbursementLimit !== '0') {
              const splitRental = this.quoteDetails.RentalReimbursementLimit.split('/');
              this.rentalLimit = `${this.formatMoney(splitRental[0].toString(), 0)} per day, up to ${this.formatMoney(splitRental[1].toString(), 0)} per claim`;
            }

            if (this.quoteDetails.RoadsideAssistanceLimit && this.quoteDetails.RoadsideAssistanceLimit !== '' && this.quoteDetails.RoadsideAssistanceLimit !== '0') {
              this.roadsideLimit = `${this.formatMoney(this.quoteDetails.RoadsideAssistanceLimit, 0)} per disablement`;
            }

            for (let v = 0; v < this.vehicleList.length; v += 1) {
              const foundVehicle = this.vehicleList[v];

              for (let p = 0; p < quoteResponse.VehiclePremiums.length; p += 1) {
                const foundPremium = quoteResponse.VehiclePremiums[p];

                if (foundVehicle.VehicleSequenceNumber === foundPremium.VehicleSequenceNumber) {
                  const vehiclePremiums = [];
                  vehiclePremiums.push({
                    CoverageText: 'Comprehensive Coverage',
                    Limit: 'Actual cash value',
                    Deductible: (foundVehicle.ComprehensiveDeductibleCode !== ''
                      ? (foundVehicle.ComprehensiveDeductibleCode === 'FULL' ? 'No deductible' : `${this.formatMoney(foundVehicle.ComprehensiveDeductibleCode, 0)} deductible`)
                      : declineCoverage),
                    Premium: (foundPremium.ComprehensivePremium ? this.formatMoney(foundPremium.ComprehensivePremium.toString(), 2) : this.formatMoney('0', 2)),
                  });
                  vehiclePremiums.push({
                    CoverageText: 'Collision Coverage',
                    Limit: 'Actual cash value',
                    Deductible: (foundVehicle.CollisionDeductibleCode !== '' ? `${this.formatMoney(foundVehicle.CollisionDeductibleCode, 0)} deductible` : declineCoverage),
                    Premium: (foundPremium.CollisionPremium ? this.formatMoney(foundPremium.CollisionPremium.toString(), 2) : this.formatMoney('0', 2)),
                  });
                  vehiclePremiums.push({
                    CoverageText: 'Rental Car Reimbursement',
                    Limit: (foundVehicle.IncludeRentalReimbursement === 'Y' ? this.rentalLimit : declineCoverage),
                    Deductible: '',
                    Premium: (foundPremium.RentalPremium ? this.formatMoney(foundPremium.RentalPremium, 2) : this.formatMoney('0', 2)),
                  });
                  vehiclePremiums.push({
                    CoverageText: 'Roadside Assistance',
                    Limit: (foundVehicle.IncludeRoadsideAssistance === 'Y' ? this.roadsideLimit : declineCoverage),
                    Deductible: '',
                    Premium: (foundPremium.RoadsidePremium ? this.formatMoney(foundPremium.RoadsidePremium, 2) : this.formatMoney('0', 2)),
                  });
                  this.vehiclePremiumList.push({
                    VehicleSequenceNumber: foundVehicle.VehicleSequenceNumber,
                    VehicleDescription: foundVehicle.Description,
                    VehiclePremium: this.formatMoney(foundPremium.VehicleTotalPremium.toString(), 2),
                    VehicleMonthPremium: this.formatMoney((Number(foundPremium.VehicleTotalPremium) / 6).toString(), 2),
                    VehiclePremiums: vehiclePremiums,
                  });
                }
              }
            }

            this.quoteDetails.CompletedQuoteIndicator = 'Y';
            this.quoteLoading = false;
          })
          .catch((error) => {
            if (error.toString().indexOf('code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
            this.quoteLoading = false;
          });
      },
      printQuote() {
        window.print();
      },
    },
    async mounted() {
      this.$store.dispatch('app/clearErrorMessage');
      this.$store.dispatch('app/setIsLoading', true);
      if (this.quoteData) {
        this.quoteLoading = true;
        this.vehicleList = this.quoteData.Vehicles;
        this.quoteDetails = this.quoteData.Quote;
        this.formattedEffectiveDate = this.formatDate(this.quoteDetails.EffectiveDate);
        this.quoteDate = this.formatDate(new Date().toISOString());

        this.getRateQuote();
      } else {
        // No saved quote information, so go back to home
        this.$store.commit('quotes/SET_TIMEOUT', true);
        this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
      }
      this.$store.dispatch('app/setIsLoading', false);
    },
  };
</script>
<style lang="scss">
  .tfb-quote-box {
    background-color: $white !important;
    color: $black !important;
  }
</style>
