var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"post-sidebar"}},[(_vm.displayLocation === 'side' && _vm.posts.length > 0)?_c('aside',{attrs:{"id":"sidebar"}},_vm._l((_vm.posts),function(post){return _c('section',{key:post.id,staticClass:"post"},[_c('router-link',{staticClass:"link-stretched",attrs:{"to":{ name: 'blogPost',
                       params: { category: _vm.kebabify(post.urlCategory),
                       title: _vm.kebabify(post.url) } },"hreflang":"en-us"}}),_c('div',{staticClass:"image-block"},[_c('router-link',{attrs:{"to":{ name: 'blogPost',
                       params: { category: _vm.kebabify(post.urlCategory),
                       title: _vm.kebabify(post.url) } },"hreflang":"en-us"}},[_c('v-img',{attrs:{"height":"200px","src":post.headerImage}})],1)],1),_c('div',{staticClass:"holder-text"},[_c('router-link',{staticClass:"category-link",attrs:{"to":{ name: 'blogFeedCategory',
                       params: { category: _vm.kebabify(post.category) } },"hreflang":"en-us"}},[_vm._v(" "+_vm._s(post.category)+" ")]),(post.subcategory && post.subcategory !== null && post.subcategory !== '')?_c('span',{staticClass:"category-text"},[_vm._v("   /   ")]):_vm._e(),(post.subcategory && post.subcategory !== null && post.subcategory !== '')?_c('router-link',{staticClass:"category-link",attrs:{"to":{ name: 'blogFeedSubCategory',
                           params: { category: _vm.kebabify(post.category), subcategory: _vm.kebabify(post.subcategory) } },"hreflang":"en-us"}},[_vm._v(" "+_vm._s(post.subcategory)+" ")]):_vm._e(),_c('h3',{staticClass:"title-post"},[_vm._v(" "+_vm._s(post.title)+" ")])],1)],1)}),0):_vm._e(),(_vm.displayLocation === 'bottom' && _vm.posts.length > 0)?_c('div',{staticClass:"more-posts"},[_vm._m(0),_c('div',{staticClass:"article-post"},_vm._l((_vm.posts),function(post){return _c('div',{key:post.id,staticClass:"post-item"},[_c('div',{staticClass:"img-post"},[_c('router-link',{attrs:{"to":{ name: 'blogPost',
                       params: { category: _vm.kebabify(post.urlCategory),
                       title: _vm.kebabify(post.url) } },"hreflang":"en-us"}},[_c('v-img',{attrs:{"height":"200px","src":post.headerImage}})],1)],1),_c('strong',[_c('router-link',{staticClass:"post-title",attrs:{"to":{ name: 'blogPost',
                       params: { category: _vm.kebabify(post.urlCategory),
                       title: _vm.kebabify(post.url) } },"hreflang":"en-us"}},[_vm._v(" "+_vm._s(post.title)+" ")])],1)])}),0)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"strip-holder"},[_c('span',{staticClass:"band-strip"},[_c('strong',{staticClass:"title-strip"},[_vm._v(" Related Articles ")])])])
}]

export { render, staticRenderFns }