<template>
  <div class="tfb-fixed-wrapper tfb-faq-wrapper"
       itemscope
       itemtype="https://schema.org/FAQPage">
    <p class="tfb-title-text-smaller font-weight-bold text-uppercase text-blue tfb-faq-title">
      {{sectionTitle}}
    </p>
    <div v-if="faqs === null || faqs.length <= 0"
         class="tfb-faq-title">
      Have a question?
      <router-link :to="{ name: 'contact'}">
        Contact us!
      </router-link>
    </div>
    <div v-if="faqs !== null && faqs.length > 0">
      <v-expansion-panels accordion
                          flat
                          active-class="tfb-faq-active">
        <v-expansion-panel v-for="question in faqs"
                           v-bind:key="question.ID"
                           itemscope
                           itemprop="mainEntity"
                           itemtype="https://schema.org/Question">
          <v-expansion-panel-header itemprop="name">
            <template v-slot:default="{ open }">
              <span v-bind:class="[ open ? 'font-weight-bold' : '' ]">{{question.Title}}</span>
            </template>
            <template v-slot:actions>
              <v-icon>$expand</v-icon>
            </template>
          </v-expansion-panel-header>
      <v-expansion-panel-content itemscope
                                 itemprop="acceptedAnswer"
                                 itemtype="https://schema.org/Answer"
                                 class="tfb-faq-answer-wrapper">
        <span v-html="question.FAQAnswer.replace(/(http&#58;\/\/tfbtel\.)/igm, 'tel:').replace(/(http:\/\/tfbtel\.)/igm, 'tel:')"
              itemprop="text"
              class="tfb-faq-answer-text"></span>
      </v-expansion-panel-content>
      </v-expansion-panel>
      </v-expansion-panels>
</div>
  </div>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'Faqs',
    mixins: [tfbHelperMixin],
    props: {
      faqKeyword: {
        type: String,
        required: true,
      },
      faqTitle: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        faqs: null,
      };
    },
    computed: {
      sectionTitle() {
        return this.faqTitle && this.faqTitle !== null && this.faqTitle !== '' ? this.faqTitle : 'Frequently Asked Questions';
      },
    },
    mounted() {
      this.fetchFaqs();
    },
    watch: {
      faqKeyword: 'fetchFaqs',
    },
    methods: {
      fetchFaqs() {
        this.faqs = null;
        this.$store.dispatch('app/getFaqsByKeyword', { sKeyword: this.faqKeyword, sTfbId: this.trackingId })
          .then((response) => {
            this.faqs = response;
          })
          .catch(() => {
            // Don't do anything
          });
      },
    },
  };
</script>
<style lang="scss">

  .tfb-faq-wrapper {
    overflow-y: scroll;
    scrollbar-color: $tfb-darkest-blue $tfb-off-white;
    scrollbar-width: thin;
  }

    .tfb-faq-wrapper::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: $tfb-off-white;
    }

    .tfb-faq-wrapper::-webkit-scrollbar {
      width: 6px;
      background-color: $tfb-off-white;
    }

    .tfb-faq-wrapper::-webkit-scrollbar-thumb {
      background-color: $tfb-darkest-blue;
    }

  .tfb-faq-title {
    padding-left: 24px;
  }
  .tfb-faq-active{
    border: solid 1px $black;
  }
  .tfb-faq-answer-wrapper {
    padding-top: 20px;
    background-color: $tfb-off-white;
  }

  .tfb-faq-answer-text {
    font-family: $body-font-family;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
  }

  @media (min-width: 1200px) {
    .tfb-faq-wrapper {
      padding-left: 50px;
    }
  }
</style>
