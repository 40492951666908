<template>
  <div id="start-claim"
       class="background-darker-blue tfb-claim">
    <h1 class="tfb-title-text-large text-center">
      File A Claim
    </h1>
    <v-container class="tfb-smallest-container tfb-claim-box">
      <ValidationObserver ref="obv">
        <v-form ref="form"
                lazy-validation
                @submit.stop.prevent="onSubmit"
                v-if="claim">
          <!-- ClaimPoint link -->
          <claim-point-link :showClaimPoint="showClaimPoint"
                            :policyNumber="claim.policyNumber"
                            :insuredName="primaryInsured.name.displayName"
                            :dateOfLoss="loss.date"></claim-point-link>
          <!-- Policyholder Information -->
          <span class="tfb-title-text-small text-darkest-blue text-uppercase">
            Policy Holder Information
          </span>
          <v-row>
            <v-col cols="12"
                   sm="5">
              <span class="tfb-title-text text-darkest-blue font-weight-bold">
                Policy Number:
              </span>
            </v-col>
            <v-col cols="12"
                   sm="7">
              {{claim.policyNumber}}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   sm="5">
              <span class="tfb-title-text text-darkest-blue font-weight-bold">
                Insured Name:
              </span>
            </v-col>
            <v-col cols="12"
                   sm="7">
              {{primaryInsured.name.displayName}}
            </v-col>
          </v-row>
          <!-- Preferred Contact Phone Number -->
          <v-row>
            <v-col cols="12">
              <text-input rules="required_if:prefType|phone_number|min:10|max:12"
                          autocomplete="home tel"
                          minlength="10"
                          maxlength="12"
                          label="Preferred Contact Phone Number"
                          v-model="preferredPhoneEntry"
                          v-mask="'###-###-####'"
                          type="tel"
                          vid="prefPhone"></text-input>
            </v-col>
          </v-row>
          <!-- Preferred Contact Phone Number Type -->
          <v-row>
            <v-col cols="12">
              <select-input rules="required_if:prefPhone"
                            label="Preferred Contact Phone Number Type"
                            :items="phoneTypes"
                            item-text="usageTypeName"
                            item-value="usageTypeCode"
                            v-model="preferredPhoneTypeEntry"
                            vid="prefType"></select-input>
            </v-col>
          </v-row>
          <!-- Reporter Information -->
          <v-row>
            <v-col cols="12">
              <span class="tfb-title-text-small text-darkest-blue text-uppercase">
                Reporter Information
              </span>
            </v-col>
          </v-row>
          <required-fields-row></required-fields-row>
          <!-- Reporter Name -->
          <v-row>
            <v-col cols="12">
              <text-input rules="required|name|min:2|max:60"
                          minlength="2"
                          maxlength="60"
                          autocomplete="shipping given-name"
                          autocapitalize="words"
                          label="Name"
                          v-model="reporter.name"
                          vid="reportName"></text-input>
            </v-col>
          </v-row>
          <!-- On Behalf Of -->
          <v-row v-if="!isMemberRole">
            <v-col cols="12">
              <text-input rules="required|name|min:2|max:60"
                          minlength="2"
                          maxlength="60"
                          autocomplete="shipping given-name"
                          autocapitalize="words"
                          label="On Behalf Of"
                          v-model="reporter.onBehalfOf"
                          vid="behalfName"></text-input>
            </v-col>
          </v-row>
          <!-- Reporter Type -->
          <v-row>
            <v-col cols="12">
              <select-input rules="required"
                            label="Type"
                            :items="reporterTypes"
                            item-text="Key"
                            item-value="Value"
                            v-model="reporter.type"
                            vid="reportType"
                            :disabled="!isMemberRole"></select-input>
            </v-col>
          </v-row>
          <!-- Reporter Phone Number -->
          <v-row>
            <v-col cols="12">
              <text-input rules="required|phone_number|min:10|max:12"
                          autocomplete="home tel"
                          minlength="10"
                          maxlength="12"
                          label="Phone Number"
                          v-model="reporter.phoneNumber"
                          v-mask="'###-###-####'"
                          type="tel"
                          vid="reportPhone"></text-input>
            </v-col>
          </v-row>
          <!-- Reporter Phone Number Type -->
          <v-row>
            <v-col cols="12">
              <select-input rules="required"
                            label="Phone Number Type"
                            :items="phoneTypes"
                            item-text="usageTypeName"
                            item-value="usageTypeCode"
                            v-model="reporter.phoneType"
                            vid="reportPhoneType"></select-input>
            </v-col>
          </v-row>
          <!-- Email Address -->
          <v-row>
            <v-col cols="12">
              <text-input rules="email|valid_domain|min:3|max:250"
                          autocomplete="home email"
                          minlength="3"
                          maxlength="250"
                          label="Email Address"
                          v-model="reporter.emailAddress"
                          type="email"
                          vid="reportEmail"></text-input>
            </v-col>
          </v-row>
          <!-- Loss/Damage Information -->
          <v-row>
            <v-col cols="12">
              <span class="tfb-title-text-small text-darkest-blue text-uppercase">
                Loss/Damage Information
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   sm="5">
              <span class="tfb-title-text text-darkest-blue font-weight-bold">
                Date of Loss:
              </span>
            </v-col>
            <v-col cols="12"
                   sm="7">
              {{dateOfLossFormatted}}
            </v-col>
          </v-row>
          <!-- Time of Loss -->
          <v-row>
            <v-col cols="12">
              <v-menu :close-on-content-click="false"
                      :nudge-right="40"
                      max-width="290px"
                      min-width="290px"
                      offset-y
                      ref="menu"
                      :return-value.sync="timeOfLoss"
                      transition="scale-transition"
                      v-model="timeMenu">
                <template v-slot:activator="{ on }">
                  <ValidationProvider tag="div"
                                      :bails="false"
                                      rules="required"
                                      name="Time of Loss"
                                      v-slot="{ errors, valid }"
                                      vid="lossTime">
                    <v-text-field outlined
                                  required
                                  hide-details="auto"
                                  :error-messages="errors"
                                  :success="valid"
                                  v-model="timeOfLossFormatted"
                                  readonly
                                  label="Time of Loss"
                                  v-on="on">
                      <template v-slot:append>
                        <v-icon>mdi-clock-outline</v-icon>
                        <v-icon>mdi-asterisk</v-icon>
                      </template>
                    </v-text-field>
                  </ValidationProvider>
                </template>
                <v-time-picker ampm-in-title
                               @click:minute="$refs.menu.save(timeOfLoss)"
                               full-width
                               v-if="timeMenu"
                               format="ampm"
                               v-model="timeOfLoss">
                </v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <!-- Type of Loss -->
          <v-row>
            <v-col cols="12">
              <select-input rules="required"
                            label="Type of Loss"
                            :items="filteredLossTypes"
                            item-text="name"
                            item-value="name"
                            v-model="loss.type"
                            vid="lossType"></select-input>
            </v-col>
          </v-row>
          <!-- Additional Description of Loss -->
          <v-row>
            <v-col cols="12">
              <text-area-input rules="required|all_allowed|max:1000"
                               label="Additional Description of Loss"
                               autocapitalize="sentences"
                               autocomplete="off"
                               maxlength="1000"
                               :counter="1000"
                               v-model="loss.description"
                               vid="lossDescription"></text-area-input>
            </v-col>
          </v-row>
          <!-- Loss Location -->
          <v-row>
            <v-col cols="12">
              <text-input rules="required|min:3|max:40"
                          minlength="3"
                          maxlength="40"
                          autocomplete="off"
                          label="Location"
                          v-model="loss.location"
                          vid="lossLocation"></text-input>
            </v-col>
          </v-row>
          <!-- Loss City -->
          <v-row>
            <v-col cols="12">
              <text-input rules="required|min:3|max:25"
                          minlength="3"
                          maxlength="25"
                          autocapitalize="words"
                          autocomplete="shipping address-level2"
                          label="City"
                          v-model="loss.city"
                          vid="lossCity"></text-input>
            </v-col>
          </v-row>
          <!-- County -->
          <v-row>
            <v-col cols="12">
              <select-input label="County"
                            :items="countyList"
                            item-text="CountyName"
                            item-value="CountyName"
                            v-model="loss.county"
                            vid="lossCounty"></select-input>
            </v-col>
          </v-row>
          <!-- State -->
          <v-row>
            <v-col cols="12">
              <select-input rules="required|alpha|min:2|max:2"
                            label="State"
                            :items="stateList"
                            item-text="StateName"
                            item-value="StateCode"
                            v-model="loss.state"
                            vid="lossState"></select-input>
            </v-col>
          </v-row>
          <v-row v-if="claim.destination === 1">
            <v-col cols="12">
              <text-input rules="all_allowed|min:2|max:40"
                          minlength="2"
                          maxlength="40"
                          autocapitalize="words"
                          autocomplete="off"
                          label="Police Department"
                          v-model="loss.policeDepartment"></text-input>
            </v-col>
          </v-row>
          <v-row v-if="claim.destination === 1">
            <v-col cols="12">
              <text-input rules="all_allowed|min:2|max:25"
                          minlength="2"
                          maxlength="25"
                          autocapitalize="words"
                          autocomplete="off"
                          label="Police Case Number"
                          v-model="loss.policeCaseNumber"></text-input>
            </v-col>
          </v-row>
          <!-- Auto Claim Entry Screen -->
          <div v-if="claim.type === 0">
            <!-- Insured Information -->
            <insured-details v-for="insured in insureds"
                             v-bind:key="insured.Id"
                             :insured="insured"
                             :drivers="driverList"
                             :vehicles="vehicleList"
                             v-bind.sync="insured"
                             v-on:remove-insured="removeInsured($event)"></insured-details>
            <v-row>
              <v-col cols="12"
                     md="8">
                <label>
                  Was another party involved?
                </label>
              </v-col>
              <v-col cols="12"
                     md="4">
                <radio-group-input rules="required"
                                   name="other party"
                                   :options="yesNoOptions"
                                   v-model="hasThirdParty"
                                   vid="thirdParty"></radio-group-input>
              </v-col>
            </v-row>
            <!-- Third Party Information -->
            <div v-if="hasThirdParty === 'Y'">
              <v-row>
                <v-col cols="12">
                  <span class="tfb-title-text-small text-darkest-blue text-uppercase">
                    Other Party Information
                  </span>
                </v-col>
              </v-row>
              <third-party-details v-for="thirdParty in thirdParties"
                                   v-bind:key="thirdParty.Id"
                                   :third-party="thirdParty"
                                   v-bind.sync="thirdParty"
                                   v-on:remove-third-party="removeThirdParty($event)"></third-party-details>
            </div>
          </div>
          <!-- Photo upload -->
          <v-row>
            <v-col cols="12">
              <span class="tfb-title-text-small text-darkest-blue text-uppercase">
                Photos
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-file-input accept="image/*"
                            :clearable="false"
                            counter
                            label="Select Photos"
                            multiple
                            placeholder="Select Photos"
                            prepend-icon="mdi-paperclip"
                            :rules="fileRules"
                            show-size
                            v-model="files">
                <template v-slot:selection="{ index, text }">
                  <v-chip @click:close="deleteChip(index)"
                          close
                          color="primary"
                          label
                          small>
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn block
                     :disabled="formDisabled"
                     class="tfb-button background-red"
                     type="submit">
                Submit Claim
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-row v-else>
          <v-col>
            Unable to locate policy information. Please try again later.
          </v-col>
        </v-row>
      </ValidationObserver>
    </v-container>
  </div>
</template>
<script>
  /* eslint dot-notation: 0 */
  import { orderBy } from 'lodash';
  import { dispatch, get } from 'vuex-pathify';
  import { mask } from 'vue-the-mask';
  import { format } from 'date-fns';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import tfbHelperMixin from '@/mixins/helpers';
  import tfbMetaMixin from '@/mixins/metaInfo';
  import RequiredFieldsRow from '@/views/layout/components/RequiredFieldsRow.vue';
  import ClaimPointLink from './components/ClaimPointLink.vue';
  import InsuredDetails from './components/Insured.vue';
  import ThirdPartyDetails from './components/ThirdParty.vue';

  export default {
    name: 'ClaimReport',
    mixins: [tfbMetaMixin, tfbHelperMixin],
    components: {
      ValidationObserver,
      ValidationProvider,
      RequiredFieldsRow,
      ClaimPointLink,
      InsuredDetails,
      ThirdPartyDetails,
    },
    directives: { mask },
    data() {
      return {
        formDisabled: false,
        timeMenu: false,
        files: [],
        preferredPhoneEntry: undefined,
        preferredPhoneTypeEntry: undefined,
        hasThirdParty: 'N',
        phoneTypes: [],
        reporterTypes: [],
        lossTypes: [],
        countyList: [],
        stateList: [],
        claim: null,
        primaryInsured: null,
        reporter: null,
        loss: null,
        insureds: [],
        thirdParties: [],
        timeOfLoss: format(new Date(), 'kk:mm:ss'),
        timeOfLossFormatted: this.formatTime(new Date().toISOString()),
        yesNoOptions: [{ Id: 0, DisplayText: 'Yes', Value: 'Y' }, { Id: 1, DisplayText: 'No', Value: 'N' }],
        fileRules: [
          files => !files || !files.some(file => file.size > 10000000) || 'File size should be less than 10 MB!',
        ],
        // TODO: validation on fields relying on other fields (DL entered, must enter DL state; etc.)
      };
    },
    computed: {
      metaDescription() {
        return 'Complete your Texas Farm Bureau Insurance claim.';
      },
      metaPageTitle() {
        if (this.$store.state.claims.claim.type === 0) {
          return 'Auto Claim';
        }
        return 'Property Claim';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/claims`;
      },
      ...get('claims', { driverList: 'drivers', vehicleList: 'vehicles' }),
      filteredLossTypes() {
        let search = null;
        if (this.claim && this.claim.policyType) {
          if (this.claim.policyType.toLowerCase().trim() === 'sm') {
            search = 'pa';
          } else if (this.claim.policyType.toLowerCase().trim() === 'ca') {
            search = 'ca';
          }
        }
        if (!search) return this.lossTypes;
        if (!this.lossTypes || this.lossTypes === null || this.lossTypes.length <= 0) return this.lossTypes;
        return this.lossTypes.filter(c => c.lob.toLowerCase().indexOf(search) > -1);
      },
      isAutoClaim() {
        return this.$store.state.claims.claim.type === 0;
      },
      dateOfLossFormatted() {
        if (this.loss && this.loss !== null) {
          return this.formatDate(this.loss.date);
        }

        return this.formatDate(new Date().toISOString());
      },
      showClaimPoint() {
        try {
          if (this.$store.getters['app/claimPointEnabled'] === 'Y' && this.claim && this.claim.policyType && this.claim.policyType.toLowerCase().trim() === 'sm' && this.claim.policySubtype === '1') {
            return true;
          }
          return false;
        } catch (error) {
          return false;
        }
      },
    },
    watch: {
      timeOfLoss(val) {
        let lossTimeStamp = new Date();
        if (this.loss && this.loss !== null) {
          lossTimeStamp = `${this.loss.date}T${val}:00`;
        } else {
          lossTimeStamp = `${this.parseDate(new Date().toISOString())}T${val}:00`;
        }
        this.timeOfLossFormatted = this.formatTime(lossTimeStamp);
        this.loss.time = `${val}:00.0`;
      },
      hasThirdParty(val) {
        if (val === 'N') {
          this.thirdParties = [];
        } else {
          this.thirdParties = [{
            driver: {
              address: {},
              injuryInd: null,
              name: {},
            },
            vehicle: {
              currentLocation: null,
              displayName: null,
              isDriveable: null,
              ownerInformation: {},
            },
          }];
        }
      },
    },
    created() {
      dispatch('app/clearErrorMessage');
      dispatch('app/setIsLoading', true);
      this.getLossTypes();
      this.getPhoneTypes();
      this.getReporterTypes();
      this.getCountyList();
      this.getStateList();
      this.getClaimInformation();
    },
    methods: {
      async getClaimInformation() {
        this.claim = this.$store.state.claims.claim;
        this.primaryInsured = this.$store.state.claims.primaryInsured;
        this.reporter = this.$store.state.claims.reporter;
        this.loss = this.$store.state.claims.loss;
        this.insureds = this.$store.state.claims.insureds;
        this.thirdParties = this.$store.state.claims.thirdParties;
        if (this.claim && this.claim.policyNumber !== null && this.claim.policyNumber !== '') {
          // Is this a logged in user?
          if (this.isLoggedIn) {
            // Is this the member or an agent/secretary/employee?
            if (this.isMemberRole) {
              // Member is filling out the form
              this.reporter.name = this.primaryInsured.name.displayName;
              this.reporter.type = 'Insured';
              this.reporter.phoneNumber = this.removePhoneFormatting(this.primaryInsured.phone);
              this.reporter.phoneType = this.primaryInsured.phoneType;
              this.reporter.emailAddress = this.primaryInsured.emailAddress;
            } else {
              // Agent/secretary/employee is filling out the form
              this.reporter.name = this.$store.state.member.memberName;
              this.reporter.onBehalfOf = this.primaryInsured.name.displayName;
              this.reporter.type = 'Insureds Agent';
              this.reporter.phoneType = 'busn_phn';
              // if (this.$store.state.member.memberName && this.$store.state.member.memberName !== null && this.$store.state.member.memberName !== '') {
              //  this.reporter.name = this.$store.state.member.memberName;
              // }
              if (this.$store.state.member.emailAddress && this.$store.state.member.emailAddress !== null && this.$store.state.member.emailAddress !== '') {
                this.reporter.emailAddress = this.$store.state.member.emailAddress;
              }
            }
          }
          this.loss.time = format(new Date(), 'kk:mm:ss');
          dispatch('app/setIsLoading', false);
        } else {
          dispatch('app/setErrorMessage', 'Unable to locate policy information');
          dispatch('app/setIsLoading', false);
          this.$router.push({ name: 'claims' });
        }
      },
      getPhoneTypes() {
        // Get the phone type information
        dispatch('claims/getPhoneTypes')
          .then((phoneTypeList) => {
            if (phoneTypeList && phoneTypeList.length > 0) {
              this.phoneTypes = phoneTypeList;
            }
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error.toString());
          });
      },
      getReporterTypes() {
        // Get the reporter type information
        dispatch('claims/getReporterTypes')
          .then((reporterTypeList) => {
            if (reporterTypeList && reporterTypeList.length > 0) {
              this.reporterTypes = reporterTypeList;
            }
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error.toString());
          });
      },
      getCountyList() {
        // Get the county information
        dispatch('claims/getCountyList')
          .then((countyListResponse) => {
            if (countyListResponse && countyListResponse.length > 0) {
              this.countyList = orderBy(countyListResponse, ['CountyName'], ['asc']);
            }
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error.toString());
          });
      },
      getStateList() {
        // Get the state information
        dispatch('app/getStateList', { sTfbId: this.trackingId })
          .then((stateListResponse) => {
            if (stateListResponse && stateListResponse.length > 0) {
              this.stateList = stateListResponse;
            }
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error.toString());
          });
      },
      getLossTypes() {
        // Get the loss type information
        dispatch('claims/getLossTypes')
          .then((lossTypeList) => {
            if (lossTypeList && lossTypeList.length > 0) {
              this.lossTypes = lossTypeList;
            }
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error.toString());
          });
      },
      deleteChip(index) {
        // Prompt here with text if required
        this.files.splice(index, 1);
      },
      onSubmit() {
        dispatch('app/clearErrorMessage');
        this.formDisabled = true;
        this.$refs.form.validate();
        this.$refs.obv.validate().then((success) => {
          if (!success) {
            this.formDisabled = false;
            setTimeout(() => {
              const errors = Object.entries(this.$refs.obv.errors)
                .map(([key, value]) => ({ key, value }))
                .filter(error => error['value'].length);
              this.$refs.obv.refs[errors[0]['key']].$el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });
            }, 100);
          } else {
            dispatch('app/setIsLoading', true);
            this.formDisabled = true;
            if (this.files && this.files.length > 0) {
              this.claim.hasPhotos = 'Y';
            } else {
              this.claim.hasPhotos = 'N';
            }
            dispatch('claims/submitClaim', {
              preferredPhone: this.preferredPhoneEntry,
              preferredPhoneType: this.preferredPhoneTypeEntry,
              claim: this.claim,
              reporter: this.reporter,
              loss: this.loss,
              insureds: this.insureds,
              thirdParties: this.thirdParties,
              files: this.files,
              sTfbId: this.trackingId,
            })
              .then((claimResponse) => {
                if (claimResponse && claimResponse.data && claimResponse.data.SubmissionStatus === 'SUCCESS') {
                  // Go to confirmation screen
                  this.$router.push({ name: 'claimsReportConfirm' });
                } else {
                  this.formDisabled = false;
                  dispatch('app/setErrorMessage', { ErrorMessage: 'Error submitting claim information. Please try again later.' });
                }
                dispatch('app/setIsLoading', false);
              })
              .catch((error) => {
                dispatch('app/setIsLoading', false);
                this.formDisabled = false;
                dispatch('app/setErrorMessage', error.toString());
              });
          }
        });
      },
    },
  };
</script>
<style lang="scss">

  .tfb-claim {
    padding-top: 50px;
    padding-bottom: 100px;
  }

  .tfb-claim-box {
    background-color: $white;
    padding: 20px;
    color: $tfb-darkest-blue;
  }
</style>
