<template>
  <div class="background-darker-blue py-12">
    <h2 class="tfb-title-text-large text-center">About You</h2>
    <v-container fluid
                 class="tfb-smallest-container tfb-quote-box pa-5 my-8">
      <v-row v-if="agreementNumber === ''">
        <v-col cols="12"
               class="text-body-2">
          Already filled this out? <router-link :to="{ name: 'quoteEntry', params: { type: 'auto' }}">Retrieve your saved quote.</router-link>
        </v-col>
      </v-row>
      <required-fields-row></required-fields-row>
      <ValidationObserver ref="obv" v-slot="{ handleSubmit }">
        <v-form role="form"
                name="aboutYouForm"
                ref="aboutYouForm"
                @submit.stop.prevent="handleSubmit(onSubmit)"
                v-if="primaryDriver !== null && primaryGaragingLocation !== null && quoteDetails !== null">
          <!-- First Name -->
          <v-row>
            <v-col cols="12">
              <text-input rules="required|name|min:2|max:25"
                          minlength="2"
                          maxlength="25"
                          label="First Name"
                          v-model="primaryDriver.FirstName"
                          autocomplete="shipping given-name"
                          autocapitalize="words"></text-input>
            </v-col>
          </v-row>
          <!-- Last Name -->
          <v-row>
            <v-col cols="12">
              <text-input rules="required|name|min:2|max:25"
                          minlength="2"
                          maxlength="25"
                          label="Last Name"
                          v-model="primaryDriver.LastName"
                          autocomplete="shipping family-name"
                          autocapitalize="words"></text-input>
            </v-col>
          </v-row>
          <!-- Address Line One -->
          <v-row>
            <v-col cols="12">
              <text-input rules="required|min:2|max:250"
                          minlength="2"
                          maxlength="250"
                          label="Physical Address 1"
                          v-model="primaryGaragingLocation.Address1"
                          autocomplete="shipping address-line1"
                          autocapitalize="words"></text-input>
            </v-col>
          </v-row>
          <!-- Address Line Two -->
          <v-row>
            <v-col cols="12">
              <text-input rules="min:2|max:250"
                          minlength="2"
                          maxlength="250"
                          label="Physical Address 2"
                          v-model="primaryGaragingLocation.Address2"
                          autocomplete="shipping address-line2"
                          autocapitalize="words"></text-input>
            </v-col>
          </v-row>
          <!-- City -->
          <v-row>
            <v-col cols="12">
              <text-input rules="required|min:2|max:250"
                          minlength="2"
                          maxlength="250"
                          label="Physical City"
                          v-model="primaryGaragingLocation.City"
                          autocomplete="shipping address-level2"
                          autocapitalize="words"></text-input>
            </v-col>
          </v-row>
          <!-- Zip Code -->
          <v-row>
            <v-col cols="12">
              <text-input rules="required|numeric|min:5|max:5"
                          minlength="5"
                          maxlength="5"
                          label="Physical ZIP Code"
                          v-model="primaryGaragingLocation.ZipCode"
                          type="number"
                          autocomplete="shipping postal-code"></text-input>
            </v-col>
          </v-row>
          <!-- County -->
          <v-row v-if="countyOptions !== null">
            <v-col cols="12">
              <select-input rules="required"
                            label="Physical County"
                            :items="countyOptions"
                            item-text="CountyName"
                            item-value="CountyCode"
                            autocomplete="home address-level2"
                            v-model="primaryGaragingLocation.LocationCounty"></select-input>
            </v-col>
          </v-row>
          <!-- Gender -->
          <v-row>
            <v-col cols="12">
              <select-input autocomplete="sex"
                            rules="required|alpha"
                            label="Gender"
                            :items="genderOptions"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="primaryDriver.Gender"></select-input>
            </v-col>
          </v-row>
          <!-- Birth Date -->
          <v-row>
            <v-col cols="12">
              <v-menu ref="birthDateMenu"
                      v-model="birthDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider tag="div"
                                      :bails="false"
                                      rules="required"
                                      name="Date of Birth"
                                      v-slot="{ errors, valid }"
                                      vid="birth">
                    <v-text-field autocomplete="bday"
                                  outlined
                                  required
                                  readonly
                                  hide-details="auto"
                                  :error-messages="errors"
                                  :success="valid"
                                  v-model="dateOfBirth"
                                  v-mask="'##/##/####'"
                                  label="Date of Birth"
                                  @change="primaryDriver.DateOfBirth = parseDate(dateOfBirth)"
                                  v-bind="attrs"
                                  v-on="on">
                      <template v-slot:append>
                        <v-icon>mdi-calendar</v-icon>
                        <v-icon>mdi-asterisk</v-icon>
                      </template>
                    </v-text-field>
                  </ValidationProvider>
                </template>
                <v-date-picker ref="picker"
                               v-model="primaryDriver.DateOfBirth"
                               :active-picker.sync="dateOfBirthActivePicker"
                               color="background-blue"
                               no-title
                               @input="birthDateMenu = false"
                               :max="maxDateOfBirth"
                               min="1920-01-01"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <!-- Phone Number -->
          <v-row>
            <v-col cols="12">
              <text-input rules="required|phone_number|min:10|max:12"
                          autocomplete="home tel-national"
                          minlength="10"
                          maxlength="12"
                          label="Phone Number"
                          v-model="quoteDetails.PhoneNumber"
                          v-mask="'###-###-####'"
                          type="tel"></text-input>
            </v-col>
          </v-row>
          <!-- Quote Type -->
          <v-row>
            <v-col cols="12">
              <select-input rules="required"
                            label="What type of auto quote is this?"
                            :items="ownerOptions"
                            item-text="DisplayText"
                            item-value="Value"
                            v-model="quoteDetails.OwnershipCode"
                            disabled></select-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   class="py-0">
              <i class="text-body-2">
                We currently only offer Individual / Personal Auto Quotes online. Please contact
                <router-link :to="{ name: 'countyLookup'}">your local agent</router-link> for additional quotes.
              </i>
            </v-col>
          </v-row>
          <!-- Quote Start Date -->
          <v-row>
            <v-col cols="12">
              <v-menu ref="effectiveDateMenu"
                      v-model="effectiveDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px">
                <template v-slot:activator="{ on }">
                  <ValidationProvider tag="div"
                                      :bails="false"
                                      rules="required"
                                      name="Quote Start Date"
                                      v-slot="{ errors, valid }"
                                      vid="effective">
                    <v-text-field outlined
                                  required
                                  readonly
                                  hide-details="auto"
                                  :error-messages="errors"
                                  :success="valid"
                                  v-model="effectiveDate"
                                  label="When should coverage begin?"
                                  v-on="on">
                      <template v-slot:append>
                        <v-icon>mdi-calendar</v-icon>
                        <v-icon>mdi-asterisk</v-icon>
                      </template>
                    </v-text-field>
                  </ValidationProvider>
                </template>
                <v-date-picker v-model="quoteDetails.EffectiveDate"
                               color="background-blue"
                               no-title
                               :min="minEffectiveDate"
                               :max="maxEffectiveDate"
                               @input="effectiveDateMenu = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   class="pb-0">
              <h4 class="text-red">If you would like to save your quote, please enter your email address and password.</h4>
              <i class="text-body-2">You will be able to retrieve your quote for up to 30 days when you return and sign in with your email address and password.</i>
            </v-col>
          </v-row>
          <!-- Email Address -->
          <v-row>
            <v-col cols="12">
              <text-input rules="required_if:crqpassword|email|valid_domain|min:3|max:250"
                          autocomplete="home email"
                          minlength="3"
                          maxlength="250"
                          label="Email Address"
                          v-model="quoteDetails.EmailAddress"
                          type="email"></text-input>
            </v-col>
          </v-row>
          <!-- Password -->
          <v-row>
            <v-col cols="12">
              <text-input rules="confirmed:confirmation|min:4|max:25"
                          minlength="4"
                          maxlength="25"
                          label="Password"
                          v-model="passWord"
                          type="password"
                          vid="crqpassword"></text-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <text-input rules="min:4|max:25"
                          minlength="4"
                          maxlength="25"
                          label="Confirm Password"
                          v-model="confirmPassWord"
                          type="password"
                          vid="confirmation"></text-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   sm="6"
                   order="2"
                   order-sm="1">
              <v-btn @click="onCancel"
                     v-bind:class="[ !isMobile ? 'float-right' : '', 'tfb-button-min', 'background-red' ]">
                <v-icon>mdi-chevron-left</v-icon>
                Go Back
              </v-btn>
            </v-col>
            <v-col cols="12"
                   sm="6"
                   order="1"
                   order-sm="2">
              <v-btn type="submit"
                     :disabled="formDisabled"
                     class="tfb-button-min background-red float-right">
                Continue
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
    </v-container>
  </div>
</template>
<script>
  import { addDays, subDays, subYears } from 'date-fns';
  import { mask } from 'vue-the-mask';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import tfbHelperMixin from '@/mixins/helpers';
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbMetaMixin from '@/mixins/metaInfo';
  import { genericErrorMessage } from '@/mixins/errorMessages';
  import RequiredFieldsRow from '@/views/layout/components/RequiredFieldsRow.vue';
  /* eslint prefer-destructuring: 0 */
  /* eslint object-shorthand: 0 */
  /* eslint no-unused-vars: 0 */

  export default {
    name: 'AutoQuoteAboutYou',
    mixins: [tfbHelperMixin, tfbDataMixin, tfbMetaMixin],
    directives: { mask },
    components: {
      ValidationObserver,
      ValidationProvider,
      RequiredFieldsRow,
    },
    data() {
      return {
        formDisabled: false,
        quoteDetails: null,
        primaryDriver: null,
        primaryGaragingLocation: null,
        countyOptions: null,
        genderOptions: [
          { DisplayText: 'Female', Value: 'F' },
          { DisplayText: 'Male', Value: 'M' },
        ],
        ownerOptions: [
          { DisplayText: 'Individual / Personal', Value: 'I' },
          { DisplayText: 'Corporation', Value: 'C' },
          { DisplayText: 'Joint Venture', Value: 'J' },
          { DisplayText: 'Partnership', Value: 'P' },
        ],
        dateOfBirthActivePicker: null,
        birthDateMenu: false,
        effectiveDateMenu: false,
        passWord: null,
        confirmPassWord: null,
        isMobile: this.$vuetify.breakpoint.smAndDown,
      };
    },
    computed: {
      metaDescription() {
        return 'Looking for an auto insurance quote? Use our online tool to learn how Texas Farm Bureau Insurance can help provide coverage for your precious ride.';
      },
      metaPageTitle() {
        return 'About You | Auto Rate Quote';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/quotes/auto/about-you`;
      },
      agreementNumber() {
        try {
          return this.$store.getters['quotes/agreementNumber'];
        } catch (error) {
          return '';
        }
      },
      subAgreementNumber() {
        try {
          return this.$store.getters['quotes/subAgreementNumber'];
        } catch (error) {
          return '';
        }
      },
      existingQuote() {
        try {
          if (this.agreementNumber && this.agreementNumber !== '') {
            return true;
          }
          return false;
        } catch (error) {
          return false;
        }
      },
      minEffectiveDate() {
        const minLimit = this.$store.getters['quotes/maxPastEffectiveDayLimit'];

        if (minLimit !== null) {
          return this.parseDate(subDays(new Date(), minLimit).toISOString());
        }

        return this.parseDate(subDays(new Date(), 30).toISOString());
      },
      maxEffectiveDate() {
        const maxLimit = this.$store.getters['quotes/maxFutureEffectiveDayLimit'];

        if (maxLimit !== null) {
          return this.parseDate(addDays(new Date(), maxLimit).toISOString());
        }

        return this.parseDate(addDays(new Date(), 60).toISOString());
      },
      effectiveDate() {
        return this.formatDate(this.quoteDetails.EffectiveDate);
      },
      maxDateOfBirth() {
        return this.parseDate(subYears(new Date(), 14).toISOString());
      },
      dateOfBirth() {
        return this.formatDate(this.primaryDriver.DateOfBirth);
      },
    },
    watch: {
      birthDateMenu(val) {
        setTimeout(() => { (this.dateOfBirthActivePicker = 'YEAR'); });
      },
    },
    methods: {
      getCountyList() {
        this.$store.dispatch('quotes/getCountyList', { sTfbId: this.trackingId })
          .then((countyListResponse) => {
            this.countyOptions = countyListResponse;
          })
          .catch((error) => {
            if (error.toString().indexOf('code 401') >= 0) {
              // Session has timed out
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
      onCancel() {
        this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
      },
      onSubmit() {
        this.$store.dispatch('app/clearErrorMessage');

        this.$store.dispatch('app/setIsLoading', true);

        // Is this an add or an update?
        if (this.agreementNumber === null || this.agreementNumber === '') {
          // Create a new quote
          this.$store.dispatch('quotes/addNewQuote', {
            sQuote: this.quoteDetails,
            sDriver: this.primaryDriver,
            sLocation: this.primaryGaragingLocation,
            sPassword: this.passWord,
            sTfbId: this.trackingId,
          })
            .then((quoteReturn) => {
              if (quoteReturn) {
                this.$store.dispatch('app/logToULS', {
                  logLevel: 'Info',
                  logMessage: quoteReturn,
                  fileName: 'AboutYou.vue',
                  methodName: 'onSubmit-NewToVehicle',
                  trackerId: this.trackingId,
                });
                this.$router.push({ name: 'quoteVehicle' });
              } else {
                this.$store.dispatch('app/setErrorMessage', genericErrorMessage);
                this.$store.dispatch('app/setIsLoading', false);
              }
            })
            .catch((error) => {
              if (error.toString().indexOf('code 401') >= 0) {
                // Session has timed out
                this.$store.commit('quotes/SET_TIMEOUT', true);
                this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
              }
              this.$store.dispatch('app/setErrorMessage', error.toString());
              this.$store.dispatch('app/setIsLoading', false);
            });
        } else {
          // Update the existing quote
          this.$store.dispatch('quotes/updateQuote', {
            sQuote: this.quoteDetails,
            sLocation: this.primaryGaragingLocation,
            sDriver: this.primaryDriver,
            sTfbId: this.trackingId,
          })
            .then((quoteReturn) => {
              if (quoteReturn) {
                this.$store.dispatch('app/logToULS', {
                  logLevel: 'Info',
                  logMessage: quoteReturn,
                  fileName: 'AboutYou.vue',
                  methodName: 'onSubmit-UpdateToVehicle',
                  trackerId: this.trackingId,
                });
                this.$router.push({ name: 'quoteVehicle' });
              } else {
                this.$store.dispatch('app/setErrorMessage', genericErrorMessage);
                this.$store.dispatch('app/setIsLoading', false);
              }
            })
            .catch((error) => {
              if (error.toString().indexOf('code 401') >= 0) {
                // Session has timed out
                this.$store.commit('quotes/SET_TIMEOUT', true);
                this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
              }
              this.$store.dispatch('app/setErrorMessage', error.toString());
              this.$store.dispatch('app/setIsLoading', false);
            });
        }
      },
    },
    async mounted() {
      this.$store.dispatch('app/clearErrorMessage');
      this.$store.dispatch('app/setIsLoading', true);
      await this.$store.dispatch('app/doApplicationCheck', { sApplicationName: 'RateQuote', sTfbId: this.trackingId })
        .then((returnedStatus) => {
          if (returnedStatus !== '') {
            this.formDisabled = true;
            this.$store.dispatch('app/setErrorMessage', returnedStatus);
          } else {
            const quote = this.$store.getters['quotes/quote'];
            if (quote) {
              this.primaryDriver = quote.Operators[0];
              this.quoteDetails = quote.Quote;
              this.primaryGaragingLocation = quote.GaragingLocations[0];
              this.quoteDetails.EffectiveDate = this.parseDate(this.quoteDetails.EffectiveDate);
              this.getCountyList();
            } else {
              // No saved quote information, so go back to home
              this.$store.commit('quotes/SET_TIMEOUT', true);
              this.$router.push({ name: 'quoteEntry', params: { type: 'auto' } });
            }
          }
          this.$store.dispatch('app/setIsLoading', false);
        })
        .catch((error) => {
          this.$store.dispatch('app/setErrorMessage', error.toString());
          this.$store.dispatch('app/setIsLoading', false);
        });
    },
  };
</script>
<style lang="scss">

  .tfb-quote-box {
    background-color: $white;
    color: $black;
  }
</style>
