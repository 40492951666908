<template>
  <v-dialog v-model="lenderdock"
            max-width="500px">
    <v-card>
      <v-card-text>
        <v-container class="tfb-component-container">
          <v-row>
            <v-col cols="12"
                   class="py-5">
              Banks, lenders, and financial institutions can now access real-time policy data without carrier intervention reducing lender
              transaction time from as much as 20 minutes to just seconds via <a href="https://www.lenderdock.com" title="LenderDock.com" target="_blank">LenderDock.com</a>.
              <a href="https://www.lenderdock.com" title="LenderDock.com" target="_blank">LenderDock.com</a> is a simple, free, self-service
              system, written exclusively for lenders. Lenders can verify coverage, download EOIs, certificates and other important policy
              documents, and correct lender information directly. Save valuable time and effort and register today! Registration is simple
              and if you ever need assistance, <a href="https://www.lenderdock.com" title="LenderDock.com" target="_blank">LenderDock.com</a>
              representatives are available to chat with you online. To register and start
              self-serving lender data today, visit <a href="https://www.lenderdock.com" title="LenderDock.com" target="_blank">LenderDock.com</a>.
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="tfb-card-footer text-center">
        <v-btn class="tfb-button-min background-red"
               href="https://www.lenderdock.com"
               target="_blank"
               type="submit">LenderDock</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import { sync } from 'vuex-pathify';

  export default {
    name: 'LenderDock',
    computed: {
      lenderdock: sync('app/lenderdock'),
    },
  };
</script>
<style lang="scss">

</style>
