import axios from 'axios';
import { apiKey } from '../../mixins/googleSettings';

// Vue.http.options.credentials = true;

// Vue.http.options.xhr = {
//  withCredentials: true,
// };
// Vue.http.options.emulateJSON = true;
// Vue.http.options.emulateHTTP = true;
// Vue.http.options.crossOrigin = true;

// Vue.http.headers.common['Access-Control-Allow-Origin'] = '*';
// Vue.http.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
// Vue.http.headers.common['Accept'] = 'application/json, text/plain, */*';
// Vue.http.headers.common['Access-Control-Allow-Headers'] =
// 'Origin, Accept, Content-Type, Authorization, Access-Control-Allow-Origin';

const config = {
  crossdomain: true,
  // withCredentials: true,
  // headers: { 'Access-Control-Allow-Origin': '*', Accept: 'application/json; odata=verbose' },
};

// getters
const getters = {
  storedZipCode: state => state.zipCode,
};

// mutations
const mutations = {
  SET_GEOLOCATION_STATE(state, changedGeoLocation) {
    state.geoLocationEnabled = changedGeoLocation;
  },
  SET_ZIPCODE(state, changedZipCode) {
    state.zipCode = changedZipCode;
  },
  SET_LATITUDE(state, changedLatitude) {
    state.latitude = changedLatitude;
  },
  SET_LONGITUDE(state, changedLongitude) {
    state.longitude = changedLongitude;
  },
  SET_COUNTYNAME(state, changedCountyName) {
    state.countyName = changedCountyName;
  },
};

// actions
const actions = {
  async setLocation(context) {
    // console.log('in setLocation');
    await context.dispatch('setLatLong');

    if (context.state.geoLocationEnabled) {
      await context.dispatch('setCountyName');
      await context.dispatch('setZipCode');
    }
  },
  async setLatLong(context) {
    // console.log('in setLatLong');
    return new Promise((resolve, reject) => {
      // do we support geolocation
      if (!('geolocation' in navigator)) {
        // console.log('Geolocation is not available.');
        context.commit('SET_GEOLOCATION_STATE', false);
        reject();
      }
      navigator.geolocation.getCurrentPosition((pos) => {
        // console.log(pos);
        context.commit('SET_GEOLOCATION_STATE', true);
        context.commit('SET_LATITUDE', pos.coords.latitude);
        context.commit('SET_LONGITUDE', pos.coords.longitude);
        resolve();
      }, (err) => {
        console.log(err);
        context.commit('SET_GEOLOCATION_STATE', false);
        reject();
      });
    });
  },
  async setZipCode(context) {
    // console.log('in setZipCode');
    await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${context.state.latitude},${context.state.longitude}&key=${apiKey}`, config)
      .then((response) => {
        // Get the current zip code from the returned address
        for (let i = 0; i < response.data.results[0].address_components.length; i += 1) {
          const item = response.data.results[0].address_components[i];
          if (item.types[0] === 'postal_code') {
            context.commit('SET_ZIPCODE', item.long_name);
          }
        }
      });
  },
  async setCountyName(context) {
    // console.log('in setCountyName');
    await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${context.state.latitude},${context.state.longitude}&key=${apiKey}`, config)
      .then((response) => {
        for (let i = 0; i < response.data.results[0].address_components.length; i += 1) {
          const item = response.data.results[0].address_components[i];
          if (item.types[0] === 'administrative_area_level_2') {
            context.commit('SET_COUNTYNAME', item.long_name.replace(' County', ''));
          }
        }
      });
  },
  async getAddress(context, sRequest) {
    return axios
      .get(`https://maps.googleapis.com/maps/api/geocode/json?address=${sRequest.sAddress}&key=${apiKey}`, config);
  },
  async getLocation() {
    // *****
    // Success
    // {
    //  "location": {
    //    "lat": 51.0,
    //      "lng": -0.1
    //  },
    //  "accuracy": 1200.4
    // }
    // *****
    // Error
    // {
    //  "error": {
    //    "errors": [
    //      {
    //        "domain": "global",
    //        "reason": "parseError",
    //        "message": "Parse Error",
    //      }
    //    ],
    //      "code": 400,
    //        "message": "Parse Error"
    //  }
    // }
    return axios
      .get(`https://www.googleapis.com/geolocation/v1/geolocate?key=${apiKey}`, config);
  },
};

// state
const state = {
  zipCode: null,
  countyName: null,
  latitude: null,
  longitude: null,
  geoLocationEnabled: false,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
