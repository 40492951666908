<template>
  <div v-if="isLoggedIn">
    <idle-notification v-if="isAppIdle"></idle-notification>
    <v-container fluid
                 class="tfb-container">
      <v-row>
        <v-col cols="12"
               md="7">
          <greeting></greeting>
          <span class="text-uppercase" v-if="$vuetify.breakpoint.smAndDown">
            Texas Farm Bureau Member: {{memberNumber}}<br />
          </span>
        </v-col>
        <v-col cols="12"
               md="5">
          <p class="text-uppercase text-right" v-if="$vuetify.breakpoint.mdAndUp">
            Texas Farm Bureau Member: {{memberNumber}}<br />
          </p>
          <agent-info v-if="$vuetify.breakpoint.mdAndUp"></agent-info>
        </v-col>
      </v-row>
    </v-container>
    <v-tabs v-model="tab"
            class="tfb-ph-header"
            :vertical="$vuetify.breakpoint.smAndDown"
            :centered="$vuetify.breakpoint.mdAndUp">
      <v-tab v-bind:class="{ 'v-tab--active': isPolicyActive }"
             :to="{ name: 'policyholderAccountSummary' }">
        Policies
      </v-tab>
      <v-tab v-bind:class="{ 'v-tab--active': isFileClaimActive }"
             :to="{ name: 'claims' }">
        File a Claim
      </v-tab>
      <v-tab v-bind:class="{ 'v-tab--active': isViewClaimActive }"
             :to="{ name: 'policyholderViewClaims' }">
        View a Claim
      </v-tab>
      <v-tab v-bind:class="{ 'v-tab--active': isSettingsActive }"
             :to="{ name: 'policyholderAccountManagement' }">
        Manage My Account
      </v-tab>
    </v-tabs>
    <div>
      <agent-info v-if="$vuetify.breakpoint.smAndDown"></agent-info>
    </div>
  </div>
</template>
<script>
  import Greeting from './Greeting.vue';
  import AgentInfo from './AgentInfo.vue';
  import IdleNotification from '../components/IdleNotification.vue';

  export default {
    name: 'PolicyHolderHeader',
    components: {
      IdleNotification,
      Greeting,
      AgentInfo,
    },
    data() {
      return {
        tab: null,
        isMobile: this.$vuetify.breakpoint.smAndDown,
        isPolicyActive: false,
        isFileClaimActive: false,
        isViewClaimActive: false,
        isSettingsActive: false,
      };
    },
    computed: {
      isLoggedIn() {
        try {
          return this.$store.getters['member/loggedInStatus'];
        } catch (error) {
          return false;
        }
      },
      memberNumber() {
        return this.$store.getters['member/memberNumber'];
      },
    },
    watch: {
      $route: 'setTab',
    },
    mounted() {
      this.setTab();
    },
    methods: {
      setTab() {
        this.isPolicyActive = false;
        this.isFileClaimActive = false;
        this.isViewClaimActive = false;
        this.isSettingsActive = false;

        if (this.$route.name === 'policyholderAccountSummary' || this.$route.name === 'policyholderPolicyDetail') {
          this.isPolicyActive = true;
        } else if (this.$route.name === 'policyholderClaims' || this.$route.name === 'policyholderClaimsReport') {
          this.isFileClaimActive = true;
        } else if (this.$route.name === 'policyholderViewClaims') {
          this.isViewClaimActive = true;
        } else {
          this.isSettingsActive = true;
        }
      },
    },
  };
</script>
<style lang="scss">
  .tfb-ph-header.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
  .tfb-ph-header.theme--light.v-tabs > .v-tabs-bar {
    background: $tfb-gray;
    color: $white;
  }

  .tfb-ph-header.v-tabs--vertical > .v-tabs-bar .v-tab {
    text-align: left;
  justify-content: flex-start;
  }

  .tfb-ph-header.v-tabs-slider-wrapper {
    visibility: hidden;
  }

  .tfb-ph-header a.v-tab--active.v-tab.v-tab--active {
    background: $tfb-lighter-gray;
    color: $white;
  }
</style>
