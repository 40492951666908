<template>
  <div class="background-darker-blue py-12">
    <p class="tfb-title-text-large text-center">Disclaimer</p>
    <v-container fluid
                 class="tfb-smallest-container tfb-quote-box pa-5 my-8">
      <v-row>
        <v-col cols="12"
               class="py-1">
          <h2 class="text-red text-center">** NOTICE **</h2>
          <p>THIS QUOTE IS NOT AN OFFER OR AGREEMENT TO PROVIDE COVERAGE. ACCEPTABILITY IS BASED ON CURRENT UNDERWRITING GUIDELINES.</p>
          <p>A VALID TEXAS FARM BUREAU MEMBERSHIP IS REQUIRED TO APPLY FOR AND MAINTAIN INSURANCE COVERAGE</p>
          <p>
            The premium amount is ONLY AN ESTIMATE and is based on the information you provided. The actual premium amount may change
            based on one or more factors including, but not limited to:
            <ul>
              <li>Additional information provided by you</li>
              <li>
                Information from a loss report, credit report, motor vehicle records, and/or other consumer reports
                <ul>
                  <li>Note: Favorable information from these reports can result in a lower premium rate than estimated here.</li>
                </ul>
              </li>
              <li>Underwriting guidelines</li>
              <li>The effective date of the policy</li>
              <li>Discounts for which you may be eligible</li>
              <li>Rate Adjustments</li>
              <li>Other rating guidelines</li>
            </ul>
          </p>
          <p>
            You may be contacted by a local Texas Farm Bureau Insurance agent regarding this auto insurance quote.
          </p>
          <p>
            If you have read the above disclaimer and would like to receive your Free Rate Quote, click the <strong>"Accept"</strong> button below.
            Otherwise, click the <strong>"Cancel"</strong> button.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"
               sm="6"
               order="2"
               order-sm="1">
          <v-btn @click="onBack"
                 v-bind:class="[ !isMobile ? 'float-right' : '', 'tfb-button-min', 'background-red' ]">
            <v-icon>mdi-chevron-left</v-icon>
            Cancel
          </v-btn>
        </v-col>
        <v-col cols="12"
               sm="6"
               order="1"
               order-sm="2">
          <v-btn @click="onSubmit"
                 class="tfb-button-min background-red float-right">
            Accept
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbMetaMixin from '@/mixins/metaInfo';

  export default {
    name: 'AutoQuoteDisclaimer',
    mixins: [tfbHelperMixin, tfbDataMixin, tfbMetaMixin],
    data() {
      return {
        isMobile: this.$vuetify.breakpoint.smAndDown,
      };
    },
    computed: {
      metaDescription() {
        return 'Looking for an auto insurance quote? Use our online tool to learn how Texas Farm Bureau Insurance can help provide coverage for your precious ride.';
      },
      metaPageTitle() {
        return 'Disclaimer | Auto Rate Quote';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/quotes/disclaimer`;
      },
    },
    methods: {
      onSubmit() {
        this.$router.push({ name: 'quoteSummary' });
      },
      onBack() {
        this.$store.dispatch('app/clearErrorMessage');
        this.$router.push({ name: 'quoteCoverage' });
      },
    },
    mounted() {
      this.$store.dispatch('app/clearErrorMessage');
      this.$store.dispatch('app/setIsLoading', false);
    },
  };
</script>
<style lang="scss">

  .tfb-quote-box {
    background-color: $white;
    color: $black;
  }
</style>
