<template>
  <div id="policyholder-agent"
       class="policyholder-agent no-print">
    <v-container v-if="agentLoading">
      <v-row>
        <v-col cols="3"
               align-self="center">
          <v-skeleton-loader :loading="agentLoading"
                             type="image"
                             class="mx-auto">
          </v-skeleton-loader>
        </v-col>
        <v-col cols="9"
               align-self="center">
          <span class="tfb-title-text-smaller text-darker-blue">
            My Agent
          </span>
          <v-skeleton-loader :loading="agentLoading"
                             type="paragraph"
                             class="mx-auto">
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="agentDetails"
                 itemprop="employee"
                 itemscope
                 itemtype="http://schema.org/employee">
      <v-row no-gutters>
        <v-col cols="3"
               align-self="center">
          <v-img :src="agentDetails.photo"
                 class="rounded-0 agent-photo"
                 itemprop="image">
            <template v-slot:placeholder>
              <v-img :src="defaultAgentImage"
                     itemprop="image"
                     class="rounded-0 agent-photo"></v-img>
            </template>
          </v-img>
        </v-col>
        <v-col cols="9"
               align-self="center"
               class="px-2">
          <span class="tfb-title-text-small text-darker-blue">
            My Agent
          </span><br />
          <span class="tfb-title-text-smaller font-weight-bold text-blue"
                itemprop="familyName">
            {{agentDetails.name}}
          </span><br />
          <span itemprop="telephone">
            {{agentDetails.phoneNumber | formatPhone}}
          </span><br />
          <span class="text-lowercase"
                itemprop="email">{{agentDetails.emailAddress}}</span><br />
          <router-link :to="{ name: 'insuranceAgent', params: { id: agentDetails.code }}">
            Visit Agent Page
          </router-link>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="!agentDetails && !agentLoading">
      <v-row>
        <v-col cols="3"
               align-self="center">
          <v-img :src="defaultAgentImage">
          </v-img>
        </v-col>
        <v-col cols="9"
               align-self="center">
          <span class="tfb-title-text-smaller text-darker-blue">
            My Agent
          </span><br />
          <span class="tfb-title-text font-weight-bold text-blue">
            Unable to locate agent information
          </span><br />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import tfbHelperMixin from '@/mixins/helpers';
  import tfbDataMixin from '@/mixins/tfbData';

export default {
    name: 'PolicyHolderHeaderAgent',
    mixins: [tfbHelperMixin, tfbDataMixin],
  data() {
    return {
      userInfo: null,
      agentLoading: true,
      agentDetails: null,
    };
  },
  computed: {
    agentCode() {
      try {
        return this.$store.getters['member/agentNumber'];
      } catch (error) {
        return '';
      }
    },
    },
    watch: {
      agentCode() {
        this.fetchAgent();
      },
    },
    mounted() {
      this.fetchAgent();
    },
    methods: {
      fetchAgent() {
        if (this.agentCode && this.agentCode !== null && this.agentCode !== '' && this.agentCode !== '99997') {
          dispatch('agentcounty/getAgentDetails', { sAgentCode: this.agentCode, sDistance: 0, sTfbId: this.trackingId })
            .then((detailResponse) => {
              if (detailResponse) {
                this.agentDetails = detailResponse;
              } else {
                this.agentDetails = null;
              }
              this.agentLoading = false;
            })
            .catch(() => {
              this.agentDetails = null;
              this.agentLoading = false;
            });
        } else {
          this.agentDetails = null;
          this.agentLoading = false;
        }
      },
    },
};
</script>
<style lang="scss">
  .policyholder-agent {
    background: $white;
  }
</style>
