<template>
  <v-row>
    <v-col cols="12"
           class="pt-0 text-caption text-right"
           align-self="start">
      <v-icon x-small
              class="text-red">mdi-asterisk</v-icon>
      &mdash; Required Fields
    </v-col>
  </v-row>
</template>
<script>
  export default {
    name: 'RequiredFieldsRow',
  };
</script>
<style lang="scss">
</style>
