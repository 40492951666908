<template>
  <v-row tabindex="0"
         ref="website-error"
         id="website-error"
         class="tfb-notification"
         role="alert"
         v-show="errorMessage && errorMessage !== ''">
    <v-col cols="12"
           class="tfb-notification-notice">
      <span v-html="errorMessage"></span>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'ErrorMessage',
  computed: {
    errorMessage() {
      return this.$store.state.app.errorMessage;
    },
  },
  watch: {
    errorMessage() {
      this.$vuetify.goTo(0);
      this.$refs['website-error'].focus();
    },
  },
};
</script>
<style lang="scss">
  .tfb-notification {
    color: #e6e6e6 !important;
    background-color: $tfb-red !important;
    border-color: $tfb-darkest-red !important;
    vertical-align: middle;
    margin: 0px;
    padding: 0px;
    margin-bottom: 2px;
    border: 1px solid transparent;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  .tfb-notification-notice {
    color: #e6e6e6 !important;
    background-color: $tfb-red !important;
    border-color: $tfb-darkest-red !important;
    padding: 5px;
    vertical-align: middle;
    margin: 0px;
    width: 100%;
  }

    .tfb-notification-notice a,
    .tfb-notification-notice a:hover,
    .tfb-notification-notice a:visited,
    .tfb-notification-notice a:active {
      color: #e6e6e6 !important;
      text-decoration: underline !important;
    }

    .tfb-notification-notice hr {
      border-top-color: $tfb-darkest-red;
    }
</style>
