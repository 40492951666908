<template>
  <div>
    <v-dialog v-model="showDialog"
              max-width="290">
      <v-card>
        <v-card-title class="headline">Existing Claim</v-card-title>
        <v-card-text>
          A claim has already been reported within the last 24 hours for this policy.
          Do you want to create another?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="onDuplicateClaim(false)"
                 class="tfb-button background-red">
            No
          </v-btn>
          <v-btn @click="onDuplicateClaim(true)"
                 class="tfb-button background-red">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  export default {
    name: 'RecentClaim',
    props: {
      showDialog: {
        type: Boolean,
        required: true,
      },
    },
    methods: {
      onDuplicateClaim(selection) {
        this.showDialog = false;
        if (selection) {
          this.$router.push({ name: 'claimsReport', params: { number: this.$store.state.claims.claim.policyNumber, type: this.$store.state.claims.claim.type } });
        }
      },
    },
  };
  </script>
<style lang="scss">

</style>
