<template>
  <div id="tfb-service">
    <div v-if="serviceItem !== null"
         id="tfb-service-header">
      <div class="tfb-header-photo"
           :style="getHeaderBackgroundImage(imageRootPath + serviceItem.HeaderFileName)" />
      <v-container fluid
                   class="tfb-container">
        <v-row class="tfb-service-quote-display">
          <v-col cols="12"
                 md="5"
                 class="ml-auto tfb-header-overlay">
            <v-card flat
                    tile
                    class="py-2">
              <v-card-text class="align-center">
                <h2 class="tfb-title-text-medium text-uppercase text-center">
                  {{ serviceItem.Title }} Quote
                </h2>
                {{ serviceItem.TagLine }}
                <br /><br />
                <v-btn :to="{ name: 'quoteEntry', params: { type: serviceItem.ServiceType }}"
                       hreflang="en-us"
                       v-if="serviceItem.AllowQuotes"
                       class="tfb-quote-button-min background-red">Let's Begin</v-btn>
                <v-btn :to="{ name: 'countyLookup' }"
                       hreflang="en-us"
                       v-if="!serviceItem.AllowQuotes"
                       class="tfb-quote-button-min background-red">Find a Local Agent</v-btn>
              </v-card-text>
              <v-card-actions v-if="serviceItem.AllowQuotes"
                              class="tfb-card-footer">
                <router-link :to="{ name: 'quoteEntry', params: { type: serviceItem.ServiceType }}"
                             hreflang="en-us">
                  Retrieve Saved Quote
                </router-link> |
                <router-link :to="{ name: 'countyLookup'}"
                             hreflang="en-us">Find an Agent</router-link>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="pb-5">
          <v-col cols="12"
                 class="text-center">
            <h1 class="tfb-title-text-large tfb-darkest-blue--text">
              {{ serviceItem.Title }} Insurance
            </h1>
            <h2 class="tfb-title-text">
              {{ serviceItem.HeaderText }}
            </h2>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container v-if="serviceItem !== null && serviceItem.ServiceCopy !== null && serviceItem.ServiceCopy !== ''"
                 class="tfb-container mt-12">
      <span v-html="serviceItem.ServiceCopy"></span>
    </v-container>
    <service-coverages :service="serviceType"
                       :service-item="serviceItem"></service-coverages>
    <jd-power-banner v-if="serviceType === 'auto'"
                     banner-type="text"></jd-power-banner>
    <star-divider></star-divider>
    <blog v-if="serviceItem"
          :header="serviceItem.GuideTitle"
          :sub-header="serviceItem.GuideHeader"
          :keywords="blogKeywords"></blog>
    <service-discounts :service="serviceType"
                       :service-item="serviceItem"></service-discounts>
    <drive-n-save-section v-if="serviceType === 'auto'"></drive-n-save-section>
    <v-container fluid
                 class="pa-0">
      <v-row no-gutters>
        <v-col cols="12"
               md="6"
               id="tfb-service-faqs">
          <faqs :faq-keyword="serviceType"></faqs>
        </v-col>
        <v-col cols="12"
               md="6">
          <videos :video-array="serviceVideos"></videos>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import JdPowerBanner from '@/views/layout/components/JDPowerBanner.vue';
  import StarDivider from '@/views/layout/components/StarDivider.vue';
  import ServiceCoverages from '@/views/services/coverages/ServiceCoverages.vue';
  import ServiceDiscounts from '@/views/services/coverages/ServiceDiscounts.vue';
  import DriveNSaveSection from '@/views/services/components/DriveNSaveSection.vue';
  import Blog from '@/views/blog/components/BlogCards.vue';
  import Videos from '@/views/content/components/Video.vue';
  import Faqs from '@/views/content/components/FAQs.vue';
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbMetaMixin from '@/mixins/metaInfo';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'ServiceInformation',
    mixins: [tfbDataMixin, tfbMetaMixin, tfbHelperMixin],
    data() {
      return {
        serviceItem: null,
        serviceVideos: [],
        blogKeywords: [],
      };
    },
    components: {
      ServiceCoverages,
      ServiceDiscounts,
      DriveNSaveSection,
      JdPowerBanner,
      StarDivider,
      Videos,
      Blog,
      Faqs,
    },
    computed: {
      headerImage() {
        return this.serviceItem ? `${this.imageRootPath}${this.serviceItem.HeaderFileName}` : null;
      },
      metaDescription() {
        return this.serviceItem ? this.serviceItem.SeoDescription : null;
      },
      metaKeywords() {
        return this.serviceItem ? this.serviceItem.SeoKeywords : null;
      },
      metaPageTitle() {
        return this.serviceItem ? `${this.serviceItem.Title} Insurance` : this.siteName;
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/insurance/${this.$route.params.type}`;
      },
      serviceType() {
        if (this.$route.params.type === 'undefined') {
          return 'auto';
        }
        return this.$route.params.type;
      },
    },
    created() {
      this.$store.dispatch('app/clearErrorMessage');
      this.startDataFetch();
    },
    watch: {
      $route: 'startDataFetch',
    },
    methods: {
      startDataFetch() {
        this.serviceItem = null;
        this.$store.dispatch('app/setIsLoading', true);
        this.getServiceData();
        this.getServiceVideos();
      },
      getServiceData() {
        this.$store.dispatch('services/getServiceInformation', { sServiceType: this.serviceType, sTfbId: this.trackingId })
          .then((response) => {
            this.serviceItem = response;
            this.blogKeywords = [];

            if (this.serviceItem.TaxKeyword && this.serviceItem.TaxKeyword.results && this.serviceItem.TaxKeyword.results.length > 0) {
              for (let t = 0; t < this.serviceItem.TaxKeyword.results.length; t += 1) {
                this.blogKeywords.push(this.serviceItem.TaxKeyword.results[t].Label);
              }
            }
            this.$meta().refresh();
            this.$store.dispatch('app/setIsLoading', false);
          })
          .catch((error) => {
            this.$store.dispatch('app/setIsLoading', false);
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
      getServiceVideos() {
        this.$store.dispatch('app/getVideosByPage', { sPage: this.serviceType, sTfbId: this.trackingId })
          .then((response) => {
            this.serviceVideos = response;
          })
          .catch((error) => {
            this.$store.dispatch('app/setIsLoading', false);
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
    },
  };
</script>
<style lang="scss">

  #tfb-service {
    background-color: $tfb-lightest-gray !important;
  }

  #tfb-service-header,
  #tfb-service-faqs {
    background-color: $white !important;
  }

  @media (min-width: 960px) {
    .tfb-service-quote-display {
      min-height: 650px;
    }
  }

  .discount-button {
    font-family: 'BS-light', $body-font-family !important;
    font-weight: 200 !important;
    font-style: normal;
    color: $tfb-darker-blue !important;
    font-size: 1.25rem !important;
    background-color: $white !important;
    width: 220px !important;
    text-align: left;
    border-color: $tfb-darker-blue !important;
  }

    .discount-button:hover {
      background-color: $tfb-blue;
      color: $white;
      border-color: $tfb-darker-blue;
    }

  .discount-button-icon {
    background-color: $tfb-blue !important;
    border: 4px solid $tfb-darker-blue !important;
  }

  .discount-button-icon-bak {
    background-color: $tfb-blue !important;
    border-radius: 50% !important;
    padding: 10px !important;
    border: 4px solid $tfb-darker-blue !important;
    margin-right: 5px !important;
    margin-left: -1px !important;
  }
</style>
