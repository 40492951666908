<template>
  <div class="my-12"
       v-if="serviceCoverages !== null && serviceCoverages.length > 0">
    <div class="text-center">
      <h2 class="tfb-title-text font-weight-bold text-darker-blue text-uppercase">
        {{ serviceItem.CoverageHeader }}
      </h2>
    </div>
    <v-container fill-height
                 v-bind:class="[ (serviceCoverages && serviceCoverages[0] && serviceCoverages[0].Coverages && serviceCoverages[0].Coverages.length <= 3) ? 'tfb-container' : 'tfb-larger-container' ]"
                 v-for="coverageGroup in serviceCoverages"
                 v-bind:key="coverageGroup.Name">
      <!-- For each group in serviceCoverage, output name if not null or empty, then output coverages -->
      <v-row v-if="coverageGroup.Name !== null && coverageGroup.Name !== '' && coverageGroup.Name !== 'None'"
             class="text-center">
        <v-col>
          <h3 class="tfb-title-text font-weight-bold text-darker-blue text-uppercase">
            {{coverageGroup.Name}}
          </h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="coverage in coverageGroup.Coverages"
               v-bind:key="coverage.id">
          <coverage-card :coverage="coverage"
                         :fixed-width="coverageGroup.Coverages.length > 5"></coverage-card>
        </v-col>
        <v-col v-if="serviceOptionalCoverages && serviceOptionalCoverages.length > 0">
          <v-card class="service-coverage-card">
            <v-card-text>
              <h3 class="font-weight-bold text-darker-blue tfb-title-text-smaller text-uppercase">
                Optional Coverages
              </h3>
              <v-list dense>
                <v-list-item v-for="(coverage, i) in serviceOptionalCoverages"
                             :key="i">
                  <optional-coverage-item :coverage="coverage"></optional-coverage-item>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import { sync } from 'vuex-pathify';
  import CoverageCard from '@/views/services/coverages/CoverageCard.vue';
  import OptionalCoverageItem from '@/views/services/coverages/OptionalCoverageItem.vue';

  export default {
    name: 'ServiceCoverages',
    props: {
      service: {
        type: String,
        required: true,
      },
      serviceItem: {
        type: Object,
        required: true,
      },
    },
    components: {
      CoverageCard,
      OptionalCoverageItem,
    },
    data() {
      return {
        serviceCoverages: null,
        serviceOptionalCoverages: null,
      };
    },
    computed: {
      imageRootPath() {
        return `${process.env.VUE_APP_ROOT_URI}/publishingimages/`;
      },
      modal: sync('app/modal'),
    },
    watch: {
      $route: 'startDataFetch',
    },
    methods: {
      startDataFetch() {
        this.getCoverageData();
        this.getOptionalCoverageData();
      },
      getCoverageData() {
        this.$store.dispatch('services/getServiceCoverages', { sServiceType: this.service, sTfbId: this.trackingId })
          .then((coverageResponse) => {
            this.serviceCoverages = coverageResponse;
          })
          .catch((error) => {
            this.$store.dispatch('app/setIsLoading', false);
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
      getOptionalCoverageData() {
        this.$store.dispatch('services/getServiceOptionalCoverages', { sServiceType: this.service, sTfbId: this.trackingId })
          .then((optionalCoverageResponse) => {
            this.serviceOptionalCoverages = optionalCoverageResponse;
          })
          .catch((error) => {
            this.$store.dispatch('app/setIsLoading', false);
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
    },
    created() {
      this.startDataFetch();
    },
  };
</script>
<style lang="scss">
</style>
