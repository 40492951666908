<template>
  <v-container fluid
               class="tfb-smaller-container">
    <v-row class="my-12">
      <v-col cols="12">
        <span class="tfb-title-text-medium text-darker-blue text-center">Page Not Found</span>
      </v-col>
    </v-row>
    <v-row class="my-12">
      <v-col cols="12">
        <h2>We're sorry, the page you're looking for cannot be found.</h2><br />
        <p>
          We recently updated our website, which may mean old bookmarks or favorites
          will not work. Please update your bookmarks or favorites to avoid this page
          in the future.
        </p>
        <p>
          <router-link :to="{ name: 'home' }"
                       hreflang="en-us">
            Continue to the Texas Farm Bureau Insurance home page
          </router-link>
          <v-icon>mdi-chevron-right</v-icon>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import tfbMetaMixin from '@/mixins/metaInfo';

  export default {
    name: 'PageNotFound',
    mixins: [tfbMetaMixin],
    computed: {
      metaPageTitle() {
        return 'Page Not Found';
      },
      metaUrl() {
        return `${process.env.VUE_APP_ROOT_URI}/not-found`;
      },
    },
    mounted() {
      this.$store.dispatch('app/setIsLoading', false);
    },
    created() {
      this.$store.dispatch('app/setIsLoading', true);
    },
  };
</script>
