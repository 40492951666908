import 'core-js/stable';
import 'core-js/es/object';
import 'core-js/es/array';
import 'intersection-observer';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import IdleVue from 'idle-vue';
import GmapVue from 'gmap-vue';
import App from './App.vue';
import store from './store/index';
import router from './router';
import './mixins/validations';
import CheckboxInput from './views/layout/components/CheckboxInput.vue';
import RadioGroupInput from './views/layout/components/RadioGroupInput.vue';
import SelectInput from './views/layout/components/SelectInput.vue';
import TextAreaInput from './views/layout/components/TextAreaInput.vue';
import TextInput from './views/layout/components/TextInput.vue';
import ErrorMessage from './views/layout/components/ErrorMessage.vue';
import vuetify from './plugins/vuetify';
import { apiKey } from './mixins/googleSettings';
import './styles/_wordpress.scss';
import './styles/_blog.scss';
import './styles/_overrides.scss';
import './styles/custom.scss';

const eventsHub = new Vue();

Vue.use(VueCookies);
Vue.$cookies.config('4h', '', '.txfb-ins.com', true);
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 1140000, // (19 * 60000)
  startAtIdle: false,
});

Vue.use(GmapVue, {
  load: {
    key: apiKey,
    libraries: 'places',
  },
  installComponents: true,
});

Vue.component('CheckboxInput', CheckboxInput);
Vue.component('RadioGroupInput', RadioGroupInput);
Vue.component('SelectInput', SelectInput);
Vue.component('TextAreaInput', TextAreaInput);
Vue.component('TextInput', TextInput);
Vue.component('ErrorMessage', ErrorMessage);

Vue.config.productionTip = false;

// Assign a handler for uncaught errors during component render function and watchers.
//  The handler gets called with the error and the Vue instance.
// Vue.config.errorHandler captures the errors that are specific to Vue instances.
//  It would not be able to capture the errors which are outside of Vue instances such as
//  utils files, services etc.
// err: complete error trace, contains the message and error stack
// vm: Vue component/instance in which error is occurred
// info: Vue specific error information such as lifecycle hooks, events etc.
// Vue.config.errorHandler = function (err, vm, info) {
//  // handle error
//  // `info` is a Vue-specific error info, e.g. which lifecycle hook
//  // the error was found in. Only available in 2.2.0+
//  console.log(`Error: ${err.toString()}\nInfo: ${info}`);
// };

// Assign a custom handler for runtime Vue warnings. Note this only works during
//  development and is ignored in production.
// Vue.config.warnHandler = function (msg, vm, trace) {
//  // `trace` is the component hierarchy trace
//  console.log(`Warn: ${msg}\nTrace: ${trace}`);
// };

// eslint-disable-next-line no-new
new Vue({
  el: '#root',
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
