const apiKey = 'AIzaSyD_z4PPuUe3rJuAfBYTf7h0I5OoWppnuXE';

const COLORS = {
  POINT: '#323232',
  POINT_FILL: '#008ce1',
  POINT_FILL_SECONDARY: '#d70019',
};

const defaultMarkerIcon = {
  path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
  strokeWeight: 1,
  strokeColor: COLORS.POINT,
  fillColor: COLORS.POINT_FILL,
  fillOpacity: 1,
  scale: 1,
};

const secondaryMarkerIcon = {
  path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
  strokeWeight: 1,
  strokeColor: COLORS.POINT,
  fillColor: COLORS.POINT_FILL_SECONDARY,
  fillOpacity: 1,
  scale: 1,
};

const defaultMarker = {
  infoText: 'Texas Farm Bureau Home Office',
  position: { lat: 31.5248446, lng: -97.2181 },
  visibility: false,
  icon: secondaryMarkerIcon,
};

const defaultCenter = { lat: 31.5248446, lng: -97.2181 };

const mapSettings = {
  clickableIcons: true,
  streetViewControl: false,
  panControlOptions: false,
  gestureHandling: 'cooperative',
  mapTypeControl: false,
  zoomControlOptions: {
    style: 'SMALL',
  },
  zoom: 11,
  minZoom: 2,
  maxZoom: 15,
};

export {
  apiKey, mapSettings, defaultMarkerIcon, secondaryMarkerIcon, defaultMarker, defaultCenter,
};
