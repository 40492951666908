<template>
  <div id="blogListing">
    <h1 class="tfb-title-text-large text-center">Blog Index</h1>
    <v-container fluid>
      <v-row v-if="!blogLoading && blogPosts.length > 0">
        <v-col cols="12"
               v-for="post in blogPosts"
             v-bind:key="post.id">
          <a :href="post.fullUrl"
             hreflang="en-us"
             :aria-label="post.title">{{post.fullUrl}}</a>
        </v-col>
      </v-row>
      <v-row v-if="!blogLoading && blogPosts.length <= 0">
        No Blog Posts Found!
      </v-row>
      <v-row v-if="blogLoading">
        <v-col cols="12"
             v-for="i in 3"
             :key="i">
          <v-skeleton-loader class="mx-auto"
                             max-width="300"
                             type="list-item"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';
  import tfbMetaMixin from '@/mixins/metaInfo';

  export default {
    name: 'BlogListing',
    mixins: [tfbHelperMixin, tfbMetaMixin],
    data() {
      return {
        blogLoading: true,
        moreDisabled: false,
        blogPosts: [],
      };
    },
    computed: {
      metaPageTitle() {
        return 'Blog Index';
      },
      metaDescription() {
        return 'Looking for a specific article from the Texas Farm Bureau Insurance blog? Visit our blog index to find what you need.';
      },
    },
    methods: {
      loadBlogPosts() {
        this.blogLoading = true;
        this.$store.dispatch('blog/getPostListing', { sTop: 100, sTfbId: this.trackingId })
          .then((foundPosts) => {
            this.blogLoading = false;
            this.blogPosts = foundPosts;
            this.loadMorePosts();
          })
          .catch(() => {
            this.moreDisabled = true;
            this.blogLoading = false;
          });
      },
      loadMorePosts() {
        this.$store.dispatch('blog/getMorePostListing', { sTfbId: this.trackingId })
          .then((moreFoundPosts) => {
            if (!moreFoundPosts || moreFoundPosts === null || moreFoundPosts.length <= 0) {
              this.moreDisabled = true;
            } else {
              const tempPosts = this.blogPosts.concat(moreFoundPosts);
              this.blogPosts = tempPosts;
              this.loadMorePosts();
            }
          })
          .catch((error) => {
            this.moreDisabled = true;
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
    },
    created() {
      this.loadBlogPosts();
    },
  };
</script>
<style lang="scss">

</style>
