<template>
  <v-container fluid
               class="align-center"
               id="blog-cards">
    <v-row v-if="blogLoading || (!blogLoading && blogPosts.length > 0)">
      <v-col cols="12">
        <h2 v-bind:class="[ subHeader === '' ? 'text-white' : 'text-darker-blue', subHeader === '' ? 'text-uppercase' : '', 'tfb-title-text-medium' ]">
          {{ header }}
        </h2>
      </v-col>
    </v-row>
    <v-row v-if="subHeader !== '' && (blogLoading || (!blogLoading && blogPosts.length > 0))">
      <v-col cols="12"
             class="tfb-title-text text-darker-blue mb-8">
        {{ subHeader }}
      </v-col>
    </v-row>
    <v-row v-if="!blogLoading && blogPosts.length > 0">
      <v-col cols="12"
             md="3"
             v-for="post in blogPosts"
             v-bind:key="post.id">
        <v-card class="fill-height">
          <v-img height="200px"
                 :src="post.headerImage"
                 class="img-box">
          </v-img>
          <v-card-text class="tfb-blog-card-text">
            <span class="title-post">{{ post.seotitle }}</span>
          </v-card-text>
          <v-card-actions class="tfb-card-footer text-center">
            <v-hover>
              <v-btn slot-scope="{ hover }"
                     outlined
                     :to="{ name: 'blogPost', params: { category: kebabify(post.urlCategory), title: kebabify(post.url) } }"
                     hreflang="en-us"
                     :class="`${hover? 'tfb-service-button-hover': ''} tfb-service-button mx-auto`">Read Article</v-btn>
            </v-hover>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="blogLoading">
      <v-col cols="12"
             md="3"
             v-for="i in 4"
             :key="i">
        <v-skeleton-loader class="mx-auto"
                           max-width="300"
                           type="card"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { Array } from 'core-js';
  import tfbHelperMixin from '@/mixins/helpers';

  export default {
    name: 'BlogCards',
    mixins: [tfbHelperMixin],
    props: {
      header: {
        type: String,
        required: true,
      },
      subHeader: {
        type: String,
        required: true,
      },
      keywords: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        blogLoading: true,
        blogPosts: [],
      };
    },
    methods: {
      loadBlogPosts() {
        this.$store.dispatch('app/doApplicationCheck', { sApplicationName: 'Blog', sTfbId: this.trackingId })
          .then((returnedStatus) => {
            if (returnedStatus === '') {
              this.blogLoading = true;
              this.blogPosts = [];
              this.fetchKeywordPosts()
                .then((foundPosts) => {
                  this.blogPosts = foundPosts;
                  if (this.blogPosts.length < 4) {
                    this.fetchAdditionalPosts();
                  }
                  this.blogLoading = false;
                });
            } else {
              this.blogLoading = false;
            }
          })
          .catch((error) => {
            this.$store.dispatch('app/setErrorMessage', error);
            this.blogLoading = false;
          });
      },
      fetchKeywordPosts() {
        return new Promise((resolve) => {
          this.$store.dispatch('blog/getPostsByTags', {
            sKeywords: this.keywords, sTop: 4, sTfbId: this.trackingId,
          })
            .then((foundPosts) => {
              resolve(foundPosts);
            })
            .catch(() => {
              // Do nothing
              resolve([]);
            });
        });
      },
      fetchAdditionalPosts() {
        return new Promise((resolve) => {
          if (this.blogPosts.length < 4) {
            this.$store.dispatch('blog/getPostsByTag', {
              sKeyword: 'insurance', sTop: 4, sTfbId: this.trackingId,
            })
              .then((foundPosts) => {
                for (let f = 0; f < foundPosts.length; f += 1) {
                  if (this.blogPosts.length < 4) {
                    this.blogPosts.push(foundPosts[f]);
                  }
                }
              })
              .catch(() => {
                // Do nothing
              });
          }
          resolve();
        });
      },
    },
    watch: {
      $route: 'loadBlogPosts',
    },
    created() {
      this.loadBlogPosts();
    },
  };
</script>
<style lang="scss">

  #blog-cards {
    background-color: transparent !important;
  }

  .blog-cards-title {
    max-height: 78px;
  }

  .tfb-blog-card-text.v-card__text {
    height: 123px;
    overflow: hidden;
  }
</style>
