<template>
  <div id="header-navigation">
    <blue-navigation></blue-navigation>
    <div>
      <v-container fluid
                   class="tfb-container">
        <!-- Begin Navigation -->
        <v-row no-gutters
               align="center"
               class="tfb-navbar">
          <v-col class="header-logo-col">
            <router-link :to="{ name: 'home' }">
              <v-img max-height="85"
                     alt="Texas Farm Bureau Insurance"
                     class="tfb-logo"
                     itemprop="logo"
                     v-bind:src="imageRootPath +'tfb-logo.jpg'"
                     contain></v-img>
            </router-link>
          </v-col>
          <v-col v-for="link in headerLinks"
                 v-bind:key="link.id"
                 :class="link.id === 0 || link.id === 4 ? 'header-drop-down-col' : 'header-mid-col'">
            <span v-if="link.children">
              <v-menu open-on-hover
                      right
                      bottom>
                <template v-slot:activator="{ on }">
                  <a href="#"
                     v-on="on"
                     class="tfb-navbar-navlink">{{link.text}}</a>
                  <v-icon>mdi-chevron-down</v-icon>
                </template>
                <v-list>
                  <v-list-item v-for="childLink in link.children"
                               v-bind:key="childLink.id">
                    <router-link :to="{ name: childLink.to, params: { type: childLink.param }}"
                                 hreflang="en-us"
                                 class="tfb-navbar-navlink">{{childLink.text}}</router-link>
                  </v-list-item>
                </v-list>
              </v-menu>
            </span>
            <span v-else>
              <span v-if="link.param != ''">
                <router-link :to="{ name: link.to, params: { type: link.param }}"
                             hreflang="en-us"
                             class="tfb-navbar-navlink">{{link.text}}</router-link>
              </span>
              <span v-else>
                <router-link :to="{ name: link.to }"
                             hreflang="en-us"
                             class="tfb-navbar-navlink">{{link.text}}</router-link>
              </span>
            </span>
          </v-col>
          <v-col>
            <a :href="primaryPhone.link"
               hreflang="en-us"
               class="ml-auto tfb-navbar-text">
              {{primaryPhone.display}}
            </a>
          </v-col>
          <v-col class="header-login-col">
            <span class="ml-auto">
              <v-icon color="tfb-blue"
                      @click="accountSummary"
                      aria-label="Go to Account Summary">mdi-account</v-icon>
              <a v-on:click.prevent="logout"
                 class="tfb-navbar-navlink login-text"
                 v-if="isLoggedIn">LOG OUT</a>
              <router-link :to="{ name: 'policyholderLogin' }"
                           hreflang="en-us"
                           class="tfb-navbar-navlink login-text"
                           v-else>LOG IN</router-link>
            </span>
          </v-col>
        </v-row>
        <!-- End Navigation -->
      </v-container>
    </div>
  </div>
</template>
<script>
  import { dispatch, sync } from 'vuex-pathify';
  import tfbDataMixin from '@/mixins/tfbData';
  import tfbHelperMixin from '@/mixins/helpers';
  import BlueNavigation from './QuickLinksHeaderNavigation.vue';

  export default {
    name: 'Navigation',
    mixins: [tfbDataMixin, tfbHelperMixin],
    components: {
      BlueNavigation,
    },
    computed: {
      headerLinks() {
        return [
          {
            id: 0,
            text: 'Insurance',
            to: '',
            param: '',
            children: [
              {
                text: 'Auto',
                to: 'insurance',
                param: 'auto',
              },
              {
                text: 'Home',
                to: 'insurance',
                param: 'home',
              },
              {
                text: 'Farm & Ranch',
                to: 'insurance',
                param: 'farm-ranch',
              },
              {
                text: 'Commercial',
                to: 'insurance',
                param: 'commercial',
              },
              {
                text: 'Life',
                to: 'insurance',
                param: 'life',
              },
            ],
          },
          {
            id: 1,
            text: 'Quote',
            to: 'quoteEntry',
            param: 'auto',
            children: null,
          },
          {
            id: 2,
            text: 'Claims',
            to: 'claims',
            param: '',
            children: null,
          },
          {
            id: 3,
            text: 'About',
            to: 'about',
            param: '',
            children: null,
          },
          {
            id: 4,
            text: 'Contact',
            to: 'contact',
            param: '',
            children: null,
          },
        ];
      },
      drawer: sync('app/drawer'),
    },
    mounted() {
      if (!this.$store.state.app.tfbId) {
        dispatch('app/setVisitor');
      }
    },
    methods: {
      logout() {
        dispatch('app/setIsLoading', true);
        dispatch('member/doLogout')
          .then(() => {
            dispatch('app/setIsLoading', false);
            // Go to home page
            this.$router.push({ name: 'policyholderLogin' });
          })
          .catch((error) => {
            dispatch('app/setIsLoading', false);
            dispatch('app/setErrorMessage', error.toString());
          });
      },
      accountSummary() {
        if (this.isLoggedIn) {
          // Redirect where needed
          this.doRedirect();
        } else {
          this.$router.push({ name: 'policyholderLogin' });
        }
      },
    },
  };
</script>
<style lang="scss">

  .tfb-navbar {
    width: 100%;
    background-color: $white !important;
    box-shadow: none !important;
  }

  a:hover.tfb-navbar-navlink {
    font-family: 'BS-light', $body-font-family;
    font-weight: 900;
    color: $tfb-darker-gray;
    text-decoration: underline;
  }

  .tfb-navbar-navlink,
  a:visited.tfb-navbar-navlink,
  a.tfb-navbar-navlink {
    font-family: 'BS-light', $body-font-family;
    font-weight: 900;
    color: $tfb-darker-gray;
    text-decoration: none;
  }

  .login-text {
    padding-left: .5em;
  }
  .tfb-navbar-text,
  a:visited.tfb-navbar-text,
  a.tfb-navbar-text {
    font-family: 'BS-bold', 'BS-light', $body-font-family;
    color: $tfb-darker-gray;
    text-decoration: none;
  }

  a:hover.tfb-navbar-text {
    font-family: 'BS-bold', 'BS-light', $body-font-family;
    font-weight: 900;
    color: $tfb-darker-gray;
    text-decoration: underline;
  }

  .tfb-small-navbar-text {
    font-family: $body-font-family;
    font-weight: 400;
    font-size: 0.75rem !important;
    line-height: 0.75rem !important;
    margin: 0px !important;
  }

  .header-login-col {
    min-width: 110px;
    max-width: 110px;
  }

  @media (min-width: 961px) {
    .header-logo-col {
      max-width: 242px;
    }
  }

  .header-drop-down-col {
    min-width: 116px;
  }

  .header-mid-col {
    max-width: 120px;
  }
</style>
